<template>
    <div class="topbar-nav header navbar" role="banner">
        <nav class="topbar">
            <ul class="list-unstyled menu-categories" id="topAccordion">
                <main-menu-sub-item v-for="(subItem, index) in routes"
                                    :key="index"
                                    :level="0"
                                    :item="subItem"></main-menu-sub-item>
            </ul>
        </nav>
    </div>
</template>

<script>

import store from "@/store";
import {mainMenuRoutes} from "@/routes/mainMenuRoutes.js";
import MainMenuSubItem from "@/components/menus/MainMenuSubItem.vue";

export default {
    name: 'main-menu',
    components: {MainMenuSubItem},
    data() {
        return {
            routes: mainMenuRoutes
        }
    },
    computed: {
        loggedIn() {
            return store.state.loggedIn;
        }
    },
    methods: {},
    beforeUnmount() {
    },
    created() {
    }

}
</script>

<style scoped lang="scss">


</style>
