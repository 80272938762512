<template>
    <div v-show="twiggleFlag" class="form-group">
        <Form ref="_form" :validationSchema="validationSchema"
              @submit="validate">
            <div class="form-group ">
                <div class="form-group ">
                    <label class="font-size-14">{{ $t('SHOW-ITEMS') }} </label>
                    <input
                        v-model="model.ShowItems"
                        type="number"
                        min="0"
                        class="form-control col-2"
                        name="ShowItems" required placeholder="..."
                    />
                </div>
               
            </div>
            <button ref="silentSubmit" type="submit" class="btn-silent-submit"/>
        </Form>
        <b-alert
            v-model="error"
            variant="danger"
            class="error-alert"
        >
            <div class="error-alert-wrap"><font-awesome-icon icon="fas fa-exclamation-triangle" class="error-icon" /> {{ errorMessage }}</div>
        </b-alert>
    </div>
</template>

<script>
import {mutate} from "@/utils/mutate";
import {Form} from 'vee-validate';
import * as yup from "yup";

export default {
    name: "MatchBoxForm",
    data() {
        return {
            validationSchema: yup.object({
                ShowItems: yup.number().min(0),
            }),
            model: {
                PkBoxMatch: null,
                FkDashboardConfig: null,
                ShowItems: 0,
            },
            error: false,
            errorMessage: '',
            twiggleFlag: true
        }
    },
    components: {Form},
    watch: {
        model: {
            handler(model) {
                this.$emit('input', model);
            },
            deep: true
        }
    },
    computed: {},
    methods: {
        twiggle() {
            this.twiggleFlag = false;
            this.$nextTick(() => {
                this.twiggleFlag = true;
            })

        },
        getTranslation(value) {
            return this.trans(value)
        },
        setError(errorMessage) {
            if (errorMessage) {
                this.error = true;
                this.errorMessage = this.trans(errorMessage);
            } else {
                this.error = false;
                this.errorMessage = '';
            }
        },
        reset() {
            this.setError();
            this.model = {
                PkBoxMatch: null,
                FkDashboardConfig: null,
                ShowItems: 0,
            };
            this.error = false;
        },

        getData(wrapped = false) {
            return wrapped ? {BoxMatch: mutate(this.model)} : mutate(this.model);
        },

        _set(d = {}) {

            this.model = {
                ...this.model,
                PkBoxMatch: d.PkBoxMatch,
                FkDashboardConfig: d.FkDashboardConfig,
                ShowItems: d.ShowItems,
            }

            this.setError(null)
            this.twiggle();

        },
        setData(data = {}) {

            if (data.BoxMatch != null)
                this._set(data.BoxMatch)
            else
                this._set(data)


        },
        validate() {

            return Promise.resolve(true);
        }
    }
}
</script>

<style scoped>

</style>

