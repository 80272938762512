<template>
    <div class="comp-wrap">
        <div class="container">

            <!-- Outer Row -->
            <div class="row justify-content-center">

                <div class="col-xl-10 col-lg-12 col-md-9">

                    <div class="card o-hidden shadow-lg my-5">
                        <div class="card-body p-0">
                            <!-- Nested Row within Card Body -->
                            <div class="row">
                                <div class="col-lg-6 d-none d-lg-block ">
                                    <div class="compLogo">
                                        <img src="../../assets/images/logo-jobineo.png"/>
                                    </div>
                                    <div v-show="waiting" class="spinner-container">
                                        <div class="spinner-wrap">
                                            <font-awesome-icon icon="fas fa-spin fa-circle-notch"/>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-lg-6">
                                    <div class="p-5"
                                         style="padding-top: 90px!important;padding-left:2rem!important;padding-right:2rem!important">
                                        <div class="text-center">
                                            <h1 class="h4 text-gray-900 mb-4">{{ $t('MFA-VALIDATION') }}</h1>
                                            <div class="help-text">
                                                <template>
                                                    {{ $t('INSERT-MFA-VALIDATION-CODE') }}
                                                </template>
                                            </div>
                                        </div>
                                        <div>
                                            <Form ref="valForm" :validationSchema="validationSchema"
                                                  @submit="validate">

                                                <div class="row">
                                                    <div class="form-group  col-12 ">

                                                        <base-input
                                                            v-model="model.MFACode"
                                                            type="text"
                                                            obj-name="MFACode"
                                                            :label="trans('MFA-VALIDATION-CODE')"
                                                            attached-class="form-control"
                                                            :required="true"
                                                            addon-left-icon="fas fa-key"
                                                            :placeholder="trans('MFA-VALIDATION-CODE')"
                                                            name="MFACode"
                                                            ref="MFACode"/>
                                                    </div>
                                                </div>
                                                <div class="text-danger text-center">{{ errorMessage }}</div>
                                                <br/>
                                                <WaitingButton
                                                    size="sm"
                                                    icon="fas fa-paper-plane"
                                                    class="float-right btn-mint input-rounded"
                                                    :waiting="waiting"
                                                    native-type="submit">
                                                    {{ $t('CHECK-CODE') }}
                                                </WaitingButton>
                                                <div class="clearfix"></div>
                                                <button ref="silentSubmit" type="submit" class="btn-silent-submit"/>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * View: EnterNewPassword
 * Path: /EnterNewPassword/:PkUser/:HashCode
 * A view to enter a new password after password forgotten
 * This is a public landing page
 *
 */
import {restUri} from "@/data/restPaths";
import WaitingButton from "../../components/WaitingButton.vue";
import router from "@/router";
import {Form} from 'vee-validate';

import * as yup from "yup";

export default {
    name: "MFACheck",
    components: {WaitingButton, Form},
    data() {
        return {
            validationSchema: yup.object({
                MFACode: yup.string().required(this.trans("REQUIRED-MESSAGE"))
            }),
            model: {
                MFACode: null
            },
            waiting: false,
            error: false,
            errorMessage: ''
        }
    },
    methods: {

        validate() {
            return this.submitMFACode();

        },
        submitMFACode() {
            this.waiting = true;
            const PkUser = this.$store.state.PkUserForAllowedRoutes;

            this.error = false;
            this.errorMessage = '';

            this.axios.post(restUri.CHECK_MFA, {
                FkUser: PkUser,
                MFACode: this.model.MFACode
            }).then(result => {

                this.waiting = false;
                let data = result.data;
                if (data.IsError) {
                    this.error = true;
                    this.errorMessage = this.trans(data.ErrorMessage);
                } else {
                    this.errorMessage = '';
                    this.confirmed = true;
                    this.$store.state.User = data.User;
                    this.$store.state.NeedToValidateMFA = false;

                    this.$store.dispatch('setDataAfterMFA', data).then(result => {
                        this.$router.push('/');
                    }).catch(result => {
                        this.error = true;
                        this.errorMessage = this.trans('ERROR');
                    })

                }

            }).catch(result => {

                this.waiting = false;
                let data = result.data;
                if (data.IsError) {
                    this.error = true;
                    this.errorMessage = this.trans(data.ErrorMessage);
                }
            });
        }
    },


}
</script>

<style scoped>

.comp-wrap {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 11;
    top: 0;
    left: 0;
}

.comp-wrap > .container {
    display: flex;
    height: 100vh;
    width: 100vw;
}

.comp-wrap > .container > .row {
    align-content: center;
    flex-direction: row;
    flex-grow: 1;
}

.compLogo {
    position: relative;
    display: block;
    padding: 0;
    margin: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.compLogo > img {
    width: 85%;
    margin-left: 50px;
    margin-top: 135px;

}

.spinner-container {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.spinner-wrap {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: nowrap;
    padding-left: 1.7rem;
    padding-bottom: 1.5rem;

}

.spinner-wrap > i {
    font-size: 4em;
    color: #ffffff;
}

.h-spacer button {
    color: yellow;
    padding: 0.75rem 1.0rem;
}

.help-text {
    font-size: 12px;
    text-align: left;
    margin-bottom: 10px;
}

.text-danger {
    color: #E7515A !important;
    font-size: 1.3em;
}

.text-success {
    color: #9acccb !important;
    font-size: 1.5em;
}

</style>
