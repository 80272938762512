<template>
    <div v-show="twiggleFlag" class="form-group">
        <Form ref="_form" :validationSchema="validationSchema"
              @submit="validate">
            <div class="form-group ">
                <div class="form-group ">
                    <label class="font-size-14">{{ $t('DAYS-NEXT') }} </label>
                    <input
                        v-model="model.DaysNext"
                        type="number"
                        min="0"
                        class="form-control col-2"
                        name="daysNext" required placeholder="..."
                    />
                </div>
            </div>
            <button ref="silentSubmit" type="submit" class="btn-silent-submit"/>
        </Form>
        <b-alert
            v-model="error"
            variant="danger"
            class="error-alert"
        >
            <div class="error-alert-wrap"><font-awesome-icon icon="fas fa-exclamation-triangle" class="error-icon" /> {{ errorMessage }}</div>
        </b-alert>
    </div>
</template>

<script>
import {mutate} from "@/utils/mutate.js";
import * as yup from "yup";
import {Form} from 'vee-validate';

export default {
    name: "AppointmentBoxForm",
    data() {
        return {
            validationSchema: yup.object({
                DaysNext: yup.number().min(1),
            }),
            model: {
                PkBoxAppointment: null,
                FkDashboardConfig: null,
                DaysNext: 1,
            },
            error: false,
            errorMessage: '',
            twiggleFlag: true
        }
    },
    components: {Form},
    watch: {
        model: {
            handler(model) {
                this.$emit('input', model);
            },
            deep: true
        }
    },
    computed: {},
    methods: {
        twiggle() {
            this.twiggleFlag = false;
            this.$nextTick(() => {
                this.twiggleFlag = true;
            })

        },
        setError(errorMessage) {
            if (errorMessage) {
                this.error = true;
                this.errorMessage = this.trans(errorMessage);
            } else {
                this.error = false;
                this.errorMessage = '';
            }
        },
        reset() {
            this.setError();

            this.model = {
                PkBoxAppointment: null,
                FkDashboardConfig: null,
                DaysNext: 1
            }

            this.error = false;
        },

        getData(wrapped = false) {
            return wrapped ? {BoxAppointment: mutate(this.model)} : mutate(this.model);
        },

        _set(d = {}) {
            this.model = {
                ...this.model,
                PkBoxAppointment: d.PkBoxAppointment,
                FkDashboardConfig: d.FkDashboardConfig,
                DaysNext: d.DaysNext
            }

            this.setError(null)
            this.twiggle();

        },
        setData(data = {}) {

            if (data.BoxAppointment != null)
                this._set(data.BoxAppointment)
            else
                this._set(data)


        },
        validate() {
            
            return Promise.resolve(true);
        }
    }
}
</script>

<style scoped>

</style>

