<template>
  <div class="page-section">
    <div class="datenschutz">
      <div class="container">
        <div class="headline-box-green">{{ trans('DATA-PROTECTION') }}</div>
        <div class="datenschutz-flex">
          <div class="datenschutz-text-box">
            <div class="headline">1. Datenschutz auf einen Blick</div>
            <br/>
            <div class="headline">Allgemeine Hinweise</div>
            <div class="text">
              Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
              passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
              persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie
              unserer unter diesem Text aufgeführten Datenschutzerklärung.

            </div>
          </div>
          <div class="datenschutz-text-box">
            <div class="headline">Datenerfassung auf dieser Website</div>
            <div class="text">
              Wer ist verantwortlich für die Datenerfassung auf dieser Website?
              Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können
              Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.
            </div>
          </div>
          <div class="datenschutz-text-box">
            <div class="headline">Wie erfassen wir Ihre Daten?</div>
            <div class="text">
              Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um
              Daten handeln, die Sie in ein Kontaktformular eingeben. Andere Daten werden automatisch oder nach Ihrer
              Einwilligung beim Besuch der Website durch unsere IT- Systeme erfasst. Das sind vor allem technische Daten
              (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
              automatisch, sobald Sie diese Website betreten.
            </div>
          </div>
          <div class="datenschutz-text-box">
            <div class="headline">Wofür nutzen wir Ihre Daten?</div>
            <div class="text">
              Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere
              Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
            </div>
          </div>
          <div class="datenschutz-text-box">
            <div class="headline">Welche Rechte haben Sie bezüglich Ihrer Daten?</div>
            <div class="text">
              Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
              gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
              Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
              können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter
              bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des
              Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
              Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.
            </div>
          </div>

          <div class="datenschutz-text-box">
            <div class="headline">2. Hosting</div>
            <div class="text">
              Wir hosten die Inhalte unserer Website bei folgendem Anbieter:<br/>
              Strato<br/>
              Anbieter ist die Strato AG, Otto-Ostrowski-Straße 7, 10249 Berlin (nachfolgend „Strato“). Wenn Sie unsere
              Website besuchen, erfasst Strato verschiedene Logfiles inklusive Ihrer IP-Adressen. Weitere Informationen
              entnehmen Sie der Datenschutzerklärung von Strato: https://www.strato.de/datenschutz/.
              Die Verwendung von Strato erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes
              Interesse an einer möglichst zuverlässigen Darstellung unserer Website. Sofern eine entsprechende
              Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit.
              a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf
              Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die
              Einwilligung ist jederzeit widerrufbar.
            </div>
          </div>
          <div class="datenschutz-text-box">
            <div class="headline">Auftragsverarbeitung</div>
            <div class="text">
              Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes
              geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
              gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen
              und unter Einhaltung der DSGVO verarbeitet.
            </div>
          </div>
          <div class="datenschutz-text-box">
            <div class="headline">3. Allgemeine Hinweise und Pflichtinformationen Datenschutz</div>
            <div class="text">
              Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
              personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser
              Datenschutzerklärung.<br/><br/>
              Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene
              Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende
              Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch,
              wie und zu welchem Zweck das geschieht.
              Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail)
              Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
              nicht möglich.

            </div>
          </div>
          <div class="datenschutz-text-box">
            <div class="headline">Hinweis zur verantwortlichen Stelle</div>
            <div class="text">
              Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
              <br/><br/>
              Jennifer Schiradin, Rüthstr. 9, 65189 Wiesbaden<br/>
              Telefon: 01632061628 <br/>
              E-Mail: hi@jobineo.de<br/>
              <br/>
              Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen
              über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o.
              Ä.) entscheidet.
            </div>
          </div>
          <div class="datenschutz-text-box">
            <div class="headline">Speicherdauer</div>
            <div class="text">
              Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben
              Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein
              berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden
              Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer
              personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im
              letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.
            </div>
          </div>
          <div class="datenschutz-text-box">
            <div class="headline">Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website
            </div>
            <div class="text">
              Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf
              Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien
              nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdrücklichen Einwilligung in die
              Übertragung personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage
              von Art. 49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf
              Informationen in Ihr Endgerät (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt die
              Datenverarbeitung zusätzlich auf Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit
              widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen
              erforderlich, verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren
              verarbeiten wir Ihre Daten, sofern diese zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind
              auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf Grundlage unseres
              berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. Über die jeweils im Einzelfall
              einschlägigen Rechtsgrundlagen wird in den folgenden Absätzen dieser Datenschutzerklärung informiert.
            </div>
          </div>
          <div class="datenschutz-text-box">
            <div class="headline">Widerruf Ihrer Einwilligung zur Datenverarbeitung</div>
            <div class="text">
              Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können
              eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf
              erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
            </div>
          </div>
          <div class="datenschutz-text-box">
            <div class="headline">Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen
              Direktwerbung (Art. 21 DSGVO)
            </div>
            <div class="text">
              WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE
              JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG
              IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN
              GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE
              DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN
              DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE
              VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG
              DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS.
              1 DSGVO).
              WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT,
              JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
              DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN
              VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM
              ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
            </div>
          </div>
          <div class="datenschutz-text-box">
            <div class="headline">Beschwerderecht bei der zuständigen Aufsichtsbehörde</div>
            <div class="text">
              Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
              Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
              oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger
              verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
            </div>
          </div>
          <div class="datenschutz-text-box">
            <div class="headline">Recht auf Datenübertragbarkeit</div>
            <div class="text">
              Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
              automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
              aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
              verlangen, erfolgt dies nur, soweit es technisch machbar ist.
            </div>
          </div>
          <div class="datenschutz-text-box">
            <div class="headline">Auskunft, Löschung und Berichtigung</div>
            <div class="text">
              Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche
              Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
              Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu
              weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.
            </div>
          </div>
          <div class="datenschutz-text-box">
            <div class="headline">Recht auf Einschränkung der Verarbeitung</div>
            <div class="text">
              Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
              Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
              folgenden Fällen:<br/>
              · Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir
              in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
              Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Wenn die Verarbeitung Ihrer
              personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der Löschung die
              Einschränkung der Datenverarbeitung verlangen.<br/>
              · Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung
              oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die
              Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.<br/>
              · Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren
              und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen,
              haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
              verlangen.<br/><br/>

              Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von
              ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
              Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen
              Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines
              Mitgliedstaats verarbeitet werden.
            </div>
          </div>
          <div class="datenschutz-text-box">
            <div class="headline">4. Datenerfassung auf dieser Website Cookies</div>
            <div class="text">
              Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Datenpakete und richten auf
              Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung
              (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies
              werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät
              gespeichert, bis Sie diese selbst löschen oder eine automatische Löschung durch Ihren Webbrowser
              erfolgt.
              Cookies können von uns (First-Party-Cookies) oder von Drittunternehmen stammen (sog. Third-Party-
              Cookies). Third-Party-Cookies ermöglichen die Einbindung bestimmter Dienstleistungen von Drittunternehmen
              innerhalb von Webseiten (z. B. Cookies zur Abwicklung von Zahlungsdienstleistungen).
              Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte
              Webseitenfunktionen ohne diese nicht funktionieren würden (z. B. die Warenkorbfunktion oder die Anzeige
              von Videos). Andere Cookies können zur Auswertung des Nutzerverhaltens oder zu Werbezwecken verwendet
              werden.
              Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs, zur Bereitstellung bestimmter,
              von Ihnen erwünschter Funktionen (z. B. für die Warenkorbfunktion) oder zur Optimierung der Website (z.
              B. Cookies zur Messung des Webpublikums) erforderlich sind (notwendige Cookies), werden auf Grundlage von
              Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der
              Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von notwendigen Cookies zur technisch
              fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung von
              Cookies und vergleichbaren Wiedererkennungstechnologien abgefragt wurde, erfolgt die Verarbeitung
              ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG); die
              Einwilligung ist jederzeit widerrufbar.
              Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und
              Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell
              ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der
              Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
              Welche Cookies und Dienste auf dieser Website eingesetzt werden, können Sie dieser Datenschutzerklärung
              entnehmen.
            </div>
          </div>
          <div class="datenschutz-text-box">
            <div class="headline">5. Newsletter</div>
            <br>
            <div class="headline">Newsletterdaten</div>
            <div class="text">
              Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten (Erinnerung an den Start der
              Anmeldung), benötigen wir von Ihnen eine E- Mail-Adresse sowie Informationen, welche uns die
              Überprüfung gestatten, dass Sie der Inhaber der angegebenen E-Mail-Adresse sind und mit dem Empfang des
              Newsletters einverstanden sind. Weitere Daten werden nicht bzw. nur auf freiwilliger Basis erhoben. Diese
              Daten verwenden wir ausschließlich für den Versand der angeforderten Informationen und geben diese nicht
              an Dritte weiter.<br/>
              Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten erfolgt ausschließlich auf
              Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur Speicherung der
              Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters können Sie jederzeit
              widerrufen, etwa über den „Austragen“-Link im Newsletter. Die Rechtmäßigkeit der bereits erfolgten
              Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.<br/><br/>

              Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer
              Austragung aus dem Newsletter bei uns bzw. dem Newsletterdiensteanbieter gespeichert und nach der
              Abbestellung des Newsletters oder nach Zweckfortfall aus der Newsletterverteilerliste gelöscht. Wir
              behalten uns vor, E-Mail-Adressen aus unserem Newsletterverteiler nach eigenem Ermessen im Rahmen unseres
              berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO zu löschen oder zu sperren. <br>
              Daten, die zu anderen Zwecken bei uns gespeichert wurden, bleiben hiervon unberührt. <br>
              Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre E-Mail-Adresse bei uns bzw. dem
              Newsletterdiensteanbieter ggf. in einer Blacklist gespeichert, sofern dies zur Verhinderung künftiger
              Mailings erforderlich ist. Die Daten aus der Blacklist werden nur für diesen Zweck verwendet und nicht
              mit anderen Daten zusammengeführt. Dies dient sowohl Ihrem Interesse als auch unserem Interesse an der
              Einhaltung der gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes Interesse im Sinne des
              Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in der Blacklist ist zeitlich nicht befristet. Sie können
              der Speicherung widersprechen, sofern Ihre Interessen unser berechtigtes Interesse überwiegen. <br>
              Auf dieser Seite besteht der Newsletter darin, dass Sie an den Start der Anmeldung erinnert werden. Sie
              bekommen also lediglich eine E-Mail, die Sie darüber in Kenntnis setzt, dass Sie sich nun anmelden können.
              <br> <br>

              Wir verwenden den Google-Dienst reCaptcha, um festzustellen, ob ein Mensch oder ein Computer eine
              bestimmte Eingabe in unserem Kontakt- oder Newsletter-Formular macht. Google prüft anhand folgender Daten,
              ob Sie ein Mensch oder ein Computer sind: IP-Adresse des verwendeten Endgeräts, die Webseite, die Sie bei
              uns besuchen und auf der das Captcha eingebunden ist, das Datum und die Dauer des Besuchs, die
              Erkennungsdaten des verwendeten Browser- und Betriebssystem-Typs, Google-Account, wenn Sie bei Google
              eingeloggt sind, Mausbewegungen auf den reCaptcha-Flächen sowie Aufgaben, bei denen Sie Bilder
              identifizieren müssen. Rechtsgrundlage für die beschriebene Datenverarbeitung ist Art. 6 Abs. 1 lit. f
              Datenschutz-Grundverordnung. Es besteht ein berechtigtes Interesse auf unserer Seite an dieser
              Datenverarbeitung, die Sicherheit unserer Webseite zu gewährleisten und uns vor automatisierten Eingaben
              (Angriffen) zu schützen.
            </div>
          </div>
          <div class="datenschutz-text-box">
            <div class="headline">CleverReach</div>
            <div class="text">
              Diese Website nutzt CleverReach für den Versand von Newslettern. Anbieter ist die CleverReach GmbH & Co.
              KG, Mühlenstr. 43, 26180 Rastede. CleverReach ist ein Dienst, mit dem der Newsletterversand organisiert
              und analysiert werden kann. Die von Ihnen zwecks Newsletterbezug eingegebenen Daten (z.B. E-Mail-Adresse)
              werden auf den Servern von CleverReach in Deutschland bzw. Irland gespeichert. <br>
              Unsere mit CleverReach versandten Newsletter ermöglichen uns die Analyse des Verhaltens der
              Newsletterempfänger. Hierbei kann u. a. analysiert werden, wie viele Empfänger die Newsletternachricht
              geöffnet haben und wie oft welcher Link im Newsletter angeklickt wurde. Mit Hilfe des sogenannten
              Conversion-Trackings kann außerdem analysiert werden, ob nach Anklicken des Links im Newsletter eine vorab
              definierte Aktion (z.B. Kauf eines Produkts auf unserer Website) erfolgt ist. Weitere Informationen zur
              Datenanalyse durch CleverReach-Newsletter erhalten Sie unter:
              https://www.cleverreach.com/de/funktionen/reporting-und-tracking/. <br>
              Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können
              diese Einwilligung jederzeit widerrufen, indem Sie den Newsletter abbestellen. Die Rechtmäßigkeit der
              bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt. <br>
              Wenn Sie keine Analyse durch CleverReach wollen, müssen Sie den Newsletter abbestellen. Hierfür stellen
              wir in jeder Newsletternachricht einen entsprechenden Link zur Verfügung. Des Weiteren können Sie den
              Newsletter auch direkt auf der Website abbestellen. <br>
              Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer
              Austragung aus dem Newsletter gespeichert und nach der Abbestellung des Newsletters sowohl von unseren
              Servern als auch von den Servern von CleverReach gelöscht. Daten, die zu anderen Zwecken bei uns
              gespeichert wurden bleiben hiervon unberührt. <br>
              Näheres entnehmen Sie den Datenschutzbestimmungen von CleverReach unter:
              https://www.cleverreach.com/de/datenschutz/. <br>
              Abschluss eines Vertrags über Auftragsverarbeitung <br> <br>
              Wir haben mit CleverReach einen Vertrag zur Auftragsverarbeitung abgeschlossen und setzen die strengen
              Vorgaben der deutschen Datenschutzbehörden bei der Nutzung von CleverReach vollständig um. <br> <br>
              <div class="headline">Quelle:</div>
              <div class="headline">https://www.e-recht24.de</div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer></site-footer>
  </div>
</template>

<script>

import router from "@/router";
import SiteFooter from "@/components/SiteFooter.vue";

export default {
  name: "DataProtection.vue",
  components: {SiteFooter},

  methods: {
    toStudent() {
      router.push("/LandingPageStudent")
    },
    register() {
      router.push('/RegisterWizard');
    }
  }
}
</script>

<style scoped src="@/scss/jobineo/output.css">
a:hover {
  cursor: pointer !important;
}
</style>
