<template>
    <div class="form auth-boxed">
        <div class="form-container outer">
            <div class="form-form">
                <div class="form-form-wrap">
                    <div class="form-container">
                        <div class="form-content">
                            <h1 class="">{{ trans('REGISTER-STUDENT-TEXT') }}</h1>
                            <p class="signup-link register">{{ trans('ALREADY-ACCOUNT') }}
                                <router-link to="/Login">{{ trans('SIGN-IN') }}</router-link>
                            </p>
                            <p class="">{{ trans('REGISTER-STUDENT-TEXT-2') }}</p>
                            <Form class="text-start" ref="valForm" :validationSchema="validationSchema"
                                  @submit="validate">
                                <div class="form">
                                    <div class="row flex">
                                        <div class="form-group col-6">
                                            <div id="email-field" class="field-wrapper input">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="feather feather-at-sign register"
                                                >
                                                    <circle cx="12" cy="12" r="4"></circle>
                                                    <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path>
                                                </svg>
                                                <base-input
                                                    v-model="model.Email"
                                                    type="Email"
                                                    obj-name="Email"
                                                    :required="true"
                                                    :label="trans('EMAIL')"
                                                    attached-class="form-control"
                                                    :placeholder="trans('EMAIL')"
                                                    name="Email"
                                                    ref="Email"/>
                                            </div>
                                        </div>
                                        <div class="form-group col-6">
                                            <div id="email-repeat-field" class="field-wrapper input">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="feather feather-at-sign register"
                                                >
                                                    <circle cx="12" cy="12" r="4"></circle>
                                                    <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path>
                                                </svg>
                                                <base-input
                                                    v-model="model.EmailRepeat"
                                                    type="EmailRepeat"
                                                    :required="true"
                                                    obj-name="EmailRepeat"
                                                    :label="trans('EMAIL-REPEAT')"
                                                    attached-class="form-control"
                                                    :placeholder="trans('EMAIL-REPEAT')"
                                                    name="EmailRepeat"
                                                    ref="EmailRepeat"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row flex">
                                        <div class="form-group col-6">
                                            <div id="password-field" class="field-wrapper input mb-2">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="feather feather-lock"
                                                >
                                                    <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                                    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                                </svg>
                                                <base-input
                                                    v-model="model.Password"
                                                    :type="pwd_type"
                                                    obj-name="Password"
                                                    autocomplete="on"
                                                    :required="true"
                                                    :max-length="PasswordMaxLength"
                                                    :min-length="PasswordMinLength"
                                                    :regex="PasswordRegex"
                                                    :help-text="passwordHelp"
                                                    :label="trans('PASSWORD')"
                                                    attached-class="form-control"
                                                    :placeholder="trans('PASSWORD')"
                                                    name="Password"
                                                    ref="Password"/>
                                                <svg
                                                    @click="set_pwd_type"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    id="toggle-password"
                                                    class="feather feather-eye"
                                                >
                                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                    <circle cx="12" cy="12" r="3"></circle>
                                                </svg>
                                            </div>
                                        </div>
                                        <div class="form-group col-6">
                                            <div id="password-field" class="field-wrapper input mb-2">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="feather feather-lock"
                                                >
                                                    <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                                    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                                </svg>
                                                <base-input
                                                    v-model="model.PasswordRepeat"
                                                    :type="pwd_type"
                                                    autocomplete="on"
                                                    obj-name="PasswordRepeat"
                                                    :label="trans('PASSWORD-REPEAT')"
                                                    attached-class="form-control"
                                                    :required="true"
                                                    :placeholder="trans('PASSWORD-REPEAT')"
                                                    name="PasswordRepeat"
                                                    ref="PasswordRepeat"/>
                                                <svg
                                                    @click="set_pwd_type"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    id="toggle-password"
                                                    class="feather feather-eye"
                                                >
                                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                    <circle cx="12" cy="12" r="3"></circle>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row flex">
                                        <div class="form-group col-6">
                                            <div id="saluation-field" class="field-wrapper input">
                                                <salutations-drop-down ref="Salutation"
                                                                       v-model="model.Salutation"
                                                                       :required="true"
                                                                       :has-null-value="true"
                                                                       :null-value-text="trans('SELECT-SALUTATION')"
                                                                       :label="trans('SALUTATION')"
                                                                       attached-class="form-control"
                                                                       name="Salutation"/>
                                            </div>
                                        </div>
                                        <div class="form-group col-6">
                                            <div id="nameaddition-field" class="field-wrapper input">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                     stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                     class="feather feather-user">
                                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                    <circle cx="12" cy="7" r="4"></circle>
                                                </svg>
                                                <base-input
                                                    v-model="model.NameAddition"
                                                    type="text"
                                                    obj-name="NameAddition"
                                                    :label="trans('NAME-ADDITION')"
                                                    attached-class="form-control"
                                                    :placeholder="trans('NAME-ADDITION')"
                                                    name="NameAddition"
                                                    ref="NameAddition"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row flex">
                                        <div class="form-group col-6">
                                            <div id="firstname-field" class="field-wrapper input">

                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                     stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                     class="feather feather-user">
                                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                    <circle cx="12" cy="7" r="4"></circle>
                                                </svg>
                                                <base-input
                                                    v-model="model.FirstName"
                                                    type="text"
                                                    obj-name="FirstName"
                                                    :label="trans('FIRSTNAME')"
                                                    :required="true"
                                                    attached-class="form-control"
                                                    :placeholder="trans('FIRSTNAME')"
                                                    name="FirstName"
                                                    ref="FirstName"/>
                                            </div>
                                        </div>
                                        <div class="form-group col-6">
                                            <div id="lastname-field" class="field-wrapper input">

                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                     stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                     class="feather feather-user">
                                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                    <circle cx="12" cy="7" r="4"></circle>
                                                </svg>
                                                <base-input
                                                    v-model="model.LastName"
                                                    type="text"
                                                    obj-name="LastName"
                                                    :label="trans('LASTNAME')"
                                                    attached-class="form-control"
                                                    :required="true"
                                                    :placeholder="trans('LASTNAME')"
                                                    name="LastName"
                                                    ref="LastName"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row flex">
                                        <div class="form-group col-6">
                                            <div id="firstname-field" class="field-wrapper input">
                                                <base-datetime
                                                    :label="trans('BIRTHDATE')"
                                                    :required="true"
                                                    obj-name="BirthDate"
                                                    name="BirthDate"
                                                    :max-date="new Date()"
                                                    :value="model.BirthDate"
                                                    :onchange="BirthDateChange"
                                                    :place-holder="trans('PLEASE-SELECT-DATE')"
                                                    date-time-type="date"
                                                    :date-time-format="DateTimeFormat"
                                                    :date-time-format-value="DateTimeFormatValue"
                                                ></base-datetime>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-6">
                                    </div>
                                    <div class="terms_condition mb-4">
                                        <div class="checkbox-outline-primary custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input"
                                                   v-model="this.model.AGBSAccepted"
                                                   id="chkTerms"/>
                                            <label class="custom-control-label"
                                                   for="chkTerms">{{ trans('AGREE-TERMS') }}
                                                <router-link to="/DataProtection">{{ trans('DATA-PROTECTION') }}
                                                </router-link>
                                                {{ trans('AGREE-TERMS-BETWEEN') }}
                                                <router-link to="/AGB"> {{ trans('AGB-STUDENT') }}</router-link>
                                                {{ trans('AGREE-TERMS-END') }}<b
                                                    class="required-asterix">
                                                    *</b></label>
                                        </div>
                                    </div>
                                    <div class="terms_condition mb-4">
                                        <div class="checkbox-outline-primary custom-control custom-checkbox">
                                            <base-checkbox class="custom-control-input"
                                                           id="Over18OrAllowed"
                                                           :checked="model.Over16"
                                                           @input="setOver16"
                                                           :label="$t('OVER-16')"/>

                                        </div>
                                    </div>
                                    <div class="row flex" v-if="model.Over16!==true">
                                        <p class="" style="color:red;">{{ trans('NOT-16-TEXT') }}</p>
                                        <div class="form-group col-6">
                                            <div id="email-field" class="field-wrapper input">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="feather feather-at-sign register"
                                                >
                                                    <circle cx="12" cy="12" r="4"></circle>
                                                    <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path>
                                                </svg>
                                                <base-input
                                                    v-model="model.ParentEmail"
                                                    type="Email"
                                                    obj-name="ParentEmail"
                                                    :required="true"
                                                    :label="trans('PARENT-EMAIL')"
                                                    attached-class="form-control"
                                                    placeholder="..."
                                                    name="ParentEmail"
                                                    ref="ParentEmail"/>
                                            </div>
                                        </div>
                                        <div class="form-group col-6">
                                            <div id="email-repeat-field" class="field-wrapper input">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="feather feather-at-sign register"
                                                >
                                                    <circle cx="12" cy="12" r="4"></circle>
                                                    <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path>
                                                </svg>
                                                <base-input
                                                    v-model="model.ParentEmailRepeat"
                                                    type="Email"
                                                    :required="true"
                                                    obj-name="ParentEmailRepeat"
                                                    :label="trans('PARENT-EMAIL-REPEAT')"
                                                    attached-class="form-control"
                                                    placeholder="..."
                                                    name="ParentEmailRepeat"
                                                    ref="ParentEmailRepeat"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="terms_condition mb-4">
                                        <div class="checkbox-outline-primary custom-control custom-checkbox">
                                            <base-checkbox class="custom-control-input"
                                                           id="AcceptedAgreement"
                                                           :required="true"
                                                           :checked="model.AcceptedAgreement"
                                                           @input="setAcceptedAgreement"
                                                           :label="$t('STUDENT-REGISTER-CHECK') + 'datenschutz@jobineo.de'"/>

                                        </div>
                                    </div>
                                    <div class="d-sm-flex justify-content-between margin-top-30">
                                        <div class="field-wrapper">
                                            <button type="submit" class="btn input-rounding btn-primary"
                                                    :disabled="this.waitFinalizing">{{
                                                    trans('REGISTER')
                                                }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                            <div v-if="error" class="error-alert-wrap">
                                <font-awesome-icon icon="fas fa-exclamation-triangle" class="error-icon"/>
                                {{ trans(errorMessage) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref} from 'vue';
import '@/scss/jobineo/sass/authentication/auth-boxed.scss';

const pwd_type = ref('password');

const set_pwd_type = () => {
    if (pwd_type.value === 'password') {
        pwd_type.value = 'text';
    } else {
        pwd_type.value = 'password';
    }
};
</script>

<script>
import {getConfig} from "@/jobineo";
import router from "@/router";
import SalutationsDropDown from '@/components/dropdowns/SalutationsDropdown.vue'
import {Form} from 'vee-validate';

import * as yup from "yup";
import {useCookies} from "vue3-cookies";
import store from "@/store";
import {REGISTER_TYPES} from "@/data/RegisterTypes";

const {cookies} = useCookies();
export default {
    name: "RegisterStudent",
    data() {
        return {
            validationSchema: yup.object({
                FirstName: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                LastName: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                Email: yup.string().required(this.trans("REQUIRED-MESSAGE")).email(this.trans("NO-EMAIL")),
                EmailRepeat: yup.string().required(this.trans("REQUIRED-MESSAGE")).email(this.trans("NO-EMAIL")),
                Password: yup.string().required(this.trans("REQUIRED-MESSAGE")).min(8, this.trans("MIN-LENGTH-START|" + 8 + "|MIN-LENGTH-END")).max(20, this.trans("MAX-LENGTH-START|" + 20 + "|MAX-LENGTH-END")),
                PasswordRepeat: yup.string().required(this.trans("REQUIRED-MESSAGE")).min(8, this.trans("MIN-LENGTH-START|" + 8 + "|MIN-LENGTH-END")).max(20, this.trans("MAX-LENGTH-START|" + 20 + "|MAX-LENGTH-END"))
            }),
            model: {
                Email: '',
                Password: '',
                PasswordRepeat: '',
                RegisterType: REGISTER_TYPES.STUDENT,
                AGBSAccepted: null,
                DSGVOAccepted: null,
                LanguageId: 'de',
                TempLink: '',
                NameAddition: '',
                Salutation: null,
                FirstName: '',
                LastName: '',
                BirthDate: null,
                Over16: false,
                ParentEmail: '',
                ParentEmailRepeat: '',
                TempLinkParent: '',
                AcceptedAgreement: false
            },
            waitFinalizing: false,
            error: false,
            errorMessage: ''
        };
    },
    computed: {
        DateTimeFormat() {
            return "d.m.Y";
        },
        DateTimeFormatValue() {
            return "Y-m-dT00:00:00.000\\Z";
        },
        passwordHelp() {
            let text = this.$t('HLP-PASSWORD') + "\n";
            if (this.$store.state.SysSettings != null) {
                let sys = this.$store.state.SysSettings;
                if (sys.PasswordMinChar > 0) {
                    text += this.$t('PW-MIN') + sys.PasswordMinChar + this.$t('PW-MIN-CHAR') + ";\n";
                }

                if (sys.PasswordMaxChar > 0) {
                    text += this.$t('PW-MAX') + sys.PasswordMaxChar + this.$t('PW-MAX-CHAR') + ";\n";
                }

                if (sys.PasswordNeedSymbol > 0) {
                    text += this.$t('PW-MIN') + sys.PasswordNeedSymbol + this.$t('PW-MIN-SYM') + ";\n";
                }

                if (sys.PasswordMaxRepeatChar > 0) {
                    text += this.$t('PW-MAX') + sys.PasswordMaxRepeatChar + this.$t('PW-MAX-SAME') + ";\n";
                }

                if (sys.PasswordNeedNumeric > 0) {
                    text += this.$t('PW-MIN') + sys.PasswordNeedNumeric + this.$t('PW-MIN-NUMERIC') + ";\n";
                }

                if (sys.PasswordNeedLowerCase > 0) {
                    text += this.$t('PW-MIN') + sys.PasswordNeedLowerCase + this.$t('PW-MIN-LOWER') + ";\n";
                }

                if (sys.PasswordNeedUpperCase > 0) {
                    text += this.$t('PW-MIN') + sys.PasswordNeedUpperCase + this.$t('PW-MIN-UPPER') + ";\n";
                }

            }

            return text
        },
        PasswordMaxLength() {
            if (this.$store.state.SysSettings != null) {
                let sys = this.$store.state.SysSettings;
                return sys.PasswordMaxChar;
            }
            return null
        },
        PasswordMinLength() {
            if (this.$store.state.SysSettings != null) {
                let sys = this.$store.state.SysSettings;
                return sys.PasswordMinChar;
            }
            return null
        },
        PasswordRegex() {
            let regexText = '';

            // '^'
            if (this.$store.state.SysSettings != null) {
                let sys = this.$store.state.SysSettings;
                let praefix = '';
                if (sys.PasswordNeedSymbol > 0) {
                    praefix = '^'
                    regexText += '(?=.*?[@;:_,.\\-+*~§$%&=#?!]{' + sys.PasswordNeedSymbol + ',})';
                }

                if (sys.PasswordNeedNumeric > 0) {
                    praefix = '^'
                    regexText += '(?=.*?[0-9]{' + sys.PasswordNeedNumeric + ',})';
                }

                if (sys.PasswordNeedLowerCase > 0) {
                    praefix = '^';
                    regexText += '(?=.*?[a-z]{' + sys.PasswordNeedLowerCase + ',})';
                }

                if (sys.PasswordNeedUpperCase > 0) {
                    praefix = '^';
                    regexText += '(?=.*?[A-Z]{' + sys.PasswordNeedUpperCase + ',})';
                }

                regexText = praefix + regexText;

            }
            return regexText;
        }
    },
    methods: {
        salutationSelect(value) {
            this.model.Salutation = value;
        },
        setOver16(value) {
            this.model.Over16 = value.target.checked;
        },
        setAcceptedAgreement(value) {
            this.model.AcceptedAgreement = value.target.checked;
        },
        BirthDateChange(newDate) {
            this.model.BirthDate = newDate;
        },
        validate() {
            let passwordMatch = (!this.model.Password && this.model.PasswordRepeat) || this.model.Password === this.model.PasswordRepeat,
                emailMatch = (!this.model.Email && this.model.EmailRepeat) || this.model.Email === this.model.EmailRepeat,
                emailNotDuplicated = this.model.Over16 === true || (this.model.Email !== this.model.ParentEmail),
                emailParentMatch = this.model.Over16 === true || ((!this.model.ParentEmail && this.model.ParentEmailRepeat) || this.model.ParentEmail === this.model.ParentEmailRepeat);
            this.error = false;
            this.errorMessage = null;

            const regexPassword = this.PasswordRegex;

            let reg = new RegExp(regexPassword)

            const regExResult = reg.test(this.model.Password);

            if (!this.model.BirthDate) {
                this.$refs.BirthDate.setError('REQUIRED');
                return Promise.resolve(false);
            }

            this.error = !passwordMatch || !emailNotDuplicated || !emailParentMatch || !emailMatch || !regExResult || !this.model.AGBSAccepted || !this.model.AcceptedAgreement;
            this.errorMessage = this.model.AGBSAccepted ? "" : this.trans('HAS-TO-CHECK-AGB-DSGVO');
            this.errorMessage = !this.model.AcceptedAgreement ? this.errorMessage + " " + this.trans('HAS-TO-CHECK-AGREEMENT') : this.errorMessage;
            this.errorMessage = !this.model.Over16 && !this.model.ParentEmail ? this.errorMessage + " " + this.trans('HAS-TO-CHECK-OVER-18') : this.errorMessage;
            this.errorMessage = passwordMatch ? this.errorMessage : this.errorMessage + " " + this.trans('PASSWORD-NO-MATCH');
            this.errorMessage = emailMatch ? this.errorMessage : this.errorMessage + " " + this.trans('EMAIL-NO-MATCH');
            this.errorMessage = emailParentMatch ? this.errorMessage : this.errorMessage + " " + this.trans('EMAIL-PARENT-NO-MATCH');
            this.errorMessage = emailNotDuplicated ? this.errorMessage : this.errorMessage + " " + this.trans('EMAIL-DUPLICATED');
            this.errorMessage = regExResult ? this.errorMessage : this.errorMessage + " " + this.trans('PASSWORD-REGEX');
            this.errorMessage = this.model.BirthDate ? this.errorMessage : this.errorMessage + " " + this.trans('BIRTHDATE-EMPTY');
            this.errorMessage = this.model.Salutation ? this.errorMessage : this.errorMessage + " " + this.trans('SALUTATION-EMPTY');
            if (this.error) {
                this.error = true;
                return Promise.resolve(false);
            }

            this.$refs.valForm.validate().then(result => {

                if (result.valid) {
                    this.onComplete();
                }
            })

        },
        onComplete() {
            this.waitFinalizing = true;
            this.$store.dispatch('registerAccount', this.model)
                .then(retVal => {

                    let response = retVal.data;

                    this.waitFinalizing = false;

                    if (response.IsError) {
                        this.error = true;
                        this.errorMessage = this.trans(response.ErrorMessage);
                        return false;
                    } else {
                        this.error = false;
                        this.errorMessage = "";

                        this.$store.state.loggedIn = false;
                        cookies.remove('rememberId');
                        cookies.remove('loggedIn');
                        cookies.remove('loggedInSessionId');
                        store.commit('logOff');

                        this.model = {};
                        router.push('/RegisterSuccess');

                    }
                })
                .then(data => {
                    if (data && !data.IsError) {
                        router.push('/');
                    } else if (data) {
                        this.error = true;
                        this.errorMessage = this.trans(data.ErrorMessage);
                    }
                }).catch(result => {
                this.waitFinalizing = false;
                let data = result;
                if (data.IsError) {
                    this.error = true;
                    this.errorMessage = this.trans(data.ErrorMessage);
                }
            });
        }
    }
}
</script>

<style scoped>
.form-form {
    max-width: 100%;
}

.form-form-wrap {
    max-width: 900px !important;
}


</style>
