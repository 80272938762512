export const APPOINTMENT_TYPES = {
    INTERVIEW_ONLINE: 1,
    INTERVIEW_ON_SITE: 2,
    APTITUDE_TEST_ONLINE: 3,
    APTITUDE_TEST_ON_SITE: 4,
}


export const NOTIFICATION_DELAY = {
    NO_NOTIFICATION: 0,
    AT_START: 1,
    AT_5: 2,
    AT_10: 3,
    AT_15: 4,
    AT_30: 5,
    AT_60: 6,
    AT_120: 7,
    AT_180: 8,
    AT_1_DAY: 9,
    AT_2_DAY: 10,
    AT_3_DAY: 11,
    AT_4_DAY: 12,
    AT_1_WEEK: 13,
    AT_2_WEEK: 14
}

export const NOTIFICATION_DELAY_DROPDOWN = [
    {
        label: 'NOTIFICATION_DELAY_NO_NOTIFICATION',
        value: NOTIFICATION_DELAY.NO_NOTIFICATION
    },

    {
        label: 'NOTIFICATION_DELAY_AT_START',
        value: NOTIFICATION_DELAY.AT_START
    },
    {
        label: 'NOTIFICATION_DELAY_AT_5',
        value: NOTIFICATION_DELAY.AT_5
    },
    {
        label: 'NOTIFICATION_DELAY_AT_10',
        value: NOTIFICATION_DELAY.AT_10
    },
    {
        label: 'NOTIFICATION_DELAY_AT_15',
        value: NOTIFICATION_DELAY.AT_15
    },
    {
        label: 'NOTIFICATION_DELAY_AT_30',
        value: NOTIFICATION_DELAY.AT_30
    },
    {
        label: 'NOTIFICATION_DELAY_AT_60',
        value: NOTIFICATION_DELAY.AT_60
    },
    {
        label: 'NOTIFICATION_DELAY_AT_120',
        value: NOTIFICATION_DELAY.AT_120
    },
    {
        label: 'NOTIFICATION_DELAY_AT_180',
        value: NOTIFICATION_DELAY.AT_180
    },
    {
        label: 'NOTIFICATION_DELAY_AT_1_DAY',
        value: NOTIFICATION_DELAY.AT_1_DAY
    },
    {
        label: 'NOTIFICATION_DELAY_AT_2_DAY',
        value: NOTIFICATION_DELAY.AT_2_DAY
    },
    {
        label: 'NOTIFICATION_DELAY_AT_3_DAY',
        value: NOTIFICATION_DELAY.AT_3_DAY
    },
    {
        label: 'NOTIFICATION_DELAY_AT_1_WEEK',
        value: NOTIFICATION_DELAY.AT_1_WEEK
    },
    {
        label: 'NOTIFICATION_DELAY_AT_2_WEEK',
        value: NOTIFICATION_DELAY.AT_2_WEEK
    }
]

export const USER_COMMITMENT = {
    UNCOMMITTED: null,
    DECLINED: false,
    COMMITTED: true
}


export const USER_COMMITMENT_DROPDOWN = [
    {
        label: 'USER_COMMITMENT_UNCOMMITTED',
        value: USER_COMMITMENT.UNCOMMITTED
    },

    {
        label: 'USER_COMMITMENT_DECLINED',
        value: USER_COMMITMENT.DECLINED
    },
    {
        label: 'USER_COMMITMENT_COMMITTED',
        value: USER_COMMITMENT.COMMITTED
    }
]


export const APPOINTMENT_TYPES_DROPDOWN = [
    {
        label: 'INTERVIEW-ONLINE',
        value: APPOINTMENT_TYPES.INTERVIEW_ONLINE
    },

    {
        label: 'INTERVIEW-ON-SITE',
        value: APPOINTMENT_TYPES.INTERVIEW_ON_SITE
    },
    {
        label: 'APTITUDE-TEST-ONLINE',
        value: APPOINTMENT_TYPES.APTITUDE_TEST_ONLINE
    },
    {
        label: 'APTITUDE-TEST-ON-SITE',
        value: APPOINTMENT_TYPES.APTITUDE_TEST_ON_SITE
    }
]
