<template>
    <div class="comp-wrap">
        <div class="container">

            <!-- Outer Row -->
            <div class="row justify-content-center">

                <div class="col-xl-10 col-lg-12 col-md-9">

                    <div class="card o-hidden  shadow-lg my-5">
                        <div class="card-body p-0">
                            <!-- Nested Row within Card Body -->
                            <div class="row">
                                <div class="col-lg-6 d-none d-lg-block ">
                                    <div class="compLogo">
                                        <img src="../../assets/images/logo-jobineo.png"/>
                                    </div>

                                    <div v-show="waiting" class="spinner-container">
                                        <div class="spinner-wrap">
                                            <font-awesome-icon icon="fas fa-spin fa-circle-notch"/>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-lg-6">


                                    <div class="p-5" style="padding-top: 90px!important;">

                                        <div class="text-center">
                                            <h1 class="h4 text-gray-900 mb-4">{{ $t('FORGOT-PASSWORD') }}</h1>
                                            <div :class="{'help-text':!confirmed}">
                                                <template v-if="!confirmed">
                                                    {{ $t('PASSWORD-FORGOTTEN-DESC') }}
                                                </template>
                                                <template v-else>
                                                    <br/><br/> <br/><br/>
                                                    <span class="text-success">
                                                    {{ $t('PASSWORD-RESET-DESC') }}
                                                    </span>
                                                    <br/><br/>
                                                </template>
                                            </div>

                                        </div>

                                        <div v-if="!confirmed">
                                            <Form ref="valForm" :validationSchema="validationSchema"
                                                  @submit="validate">

                                                <div class="row">
                                                    <div class="form-group col-12">
                                                        <base-input
                                                            v-model="model.Email"
                                                            type="text"
                                                            obj-name="Email"
                                                            :label="trans('EMAIL')"
                                                            attached-class="form-control"
                                                            :required="true"
                                                            :placeholder="trans('EMAIL')"
                                                            name="Email"
                                                            ref="Email"/>
                                                    </div>
                                                </div>

                                                <div class="text-danger text-center">{{ errorMessage }}</div>

                                                <br/>

                                                <base-button type="button"
                                                             class="btn  btn-sm btn-red float-left"
                                                             @click="cancel">
                                                    <font-awesome-icon icon="fas fa-times" style="margin-right: 8px;"/>
                                                    {{ $t('CANCEL') }}
                                                </base-button>

                                                <WaitingButton
                                                    size="sm"
                                                    icon="fas fa-paper-plane"
                                                    class="float-right btn-mint input-rounded"
                                                    :waiting="waiting"
                                                    native-type="submit">
                                                    {{ $t('SEND') }}
                                                </WaitingButton>

                                                <div class="clearfix"></div>
                                                <button ref="silentSubmit" type="submit" class="btn-silent-submit"/>

                                            </Form>
                                        </div>

                                        <div v-if="confirmed" class="text-center">
                                            <router-link to="/Login">{{ $t('CONTINUE-LOGIN') }}
                                                <font-awesome-icon icon="fas fa-arrow-right"/>
                                            </router-link>
                                            <br/>
                                            <a @click="reset($event)" href="">{{ $t('RE-ENTER') }}
                                                <font-awesome-icon
                                                    icon="fas fa-undo-alt"/>
                                            </a>


                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</template>

<script>
/**
 * View: PasswordForgotten
 * Path: /PasswordForgotten
 * A view to enter username and email for achieving an email to reset the password
 * This is a public view
 *
 */
import {restUri} from "@/data/restPaths";
import WaitingButton from "../../components/WaitingButton.vue";
import {mutate} from "@/utils/mutate";
import {landingPages} from "@/data/landingPages";
import {getConfig} from "@/jobineo";
import {silentEvent} from "@/utils/silentEvent";
import router from "@/router";
import {Form} from 'vee-validate';

import * as yup from "yup";


export default {
    name: "PasswordForgotten",
    components: {WaitingButton, Form},
    data() {
        return {
            validationSchema: yup.object({
                Email: yup.string().required(this.trans("REQUIRED-MESSAGE")).email(this.trans("NO-EMAIL"))
            }),
            model: {
                Email: null
            },

            waiting: false,
            confirmed: false,
            error: false,
            errorMessage: ''
        }
    },
    methods: {
        validate() {

            this.error = false;
            this.errorMessage = null;

            if (this.error) {
                this.error = true;
                return Promise.resolve(false);
            }

            this.$refs.valForm.validate().then(result => {

                if (result.valid) {
                    this.onUpdate();
                }
            })

        },
        reset(e = false) {

            if (e)
                silentEvent(e);

            this.model = {
                Email: null
            };
            this.confirmed = false;
            this.error = false;
            this.errorMessage = '';
        },

        cancel() {
            router.push('/Login');
        },
        onUpdate() {

            this.waiting = true;

            const baseUrl = getConfig('baseUrl');

            this.axios.post(restUri.RESET_PASSWORD,
                mutate(this.model, {URL: baseUrl + landingPages.ENTER_NEW_PASSWORD})
            ).then(result => {
                this.waiting = false;
                let data = result.data;
                if (data.IsError) {
                    this.errorMessage = this.trans(data.ErrorMessage);
                } else {
                    this.errorMessage = '';
                    this.confirmed = true;
                }

            }).catch(result => {
                this.waiting = false;
                let data = result.data;
                if (data.IsError) {
                    this.errorMessage = this.trans(data.ErrorMessage);
                }
            });

        }
    }

}
</script>

<style scoped>


.comp-wrap {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 11;
    top: 0;
    left: 0;
}

.comp-wrap > .container {
    display: flex;
    height: 100vh;
    width: 100vw;
}

.comp-wrap > .container > .row {
    align-content: center;
    flex-direction: row;
    flex-grow: 1;
}

.compLogo {
    position: relative;
    display: block;
    padding: 0;
    margin: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.compLogo > img {
    width: 85%;
    margin-left: 50px;
    margin-top: 150px;

}

.spinner-container {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.spinner-wrap {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: nowrap;
    padding-left: 1.7rem;
    padding-bottom: 1.5rem;

}

.spinner-wrap > i {
    font-size: 4em;
    color: #ffffff;
}

.h-spacer button {
    color: yellow;
    padding: 0.75rem 1.0rem;
}

.help-text {
    font-size: 12px;
    text-align: left;
    margin-bottom: 10px;
}

.text-danger {
    color: #E7515A !important;
    font-size: 1.3em;
}

.text-success {
    color: #9acccb !important;
    font-size: 1.5em;
}

</style>
