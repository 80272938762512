/**
 * Mutations may be arrow function because they are pure functions
 */

/**
 * This sub-module includes all mutations for:
 * manipulating system related data
 */

import {mergeDeep} from "@/utils/mergeDeep";
import defaultState from '../../vuex/defaultState.js'
import {mutate} from "@/utils/mutate";
import {isObject} from '@/utils/isObject';
import i18n from '../../i18n';

export const setLayout = (state, payload) => {
    state.layout = payload;
}
export const toggleSideBar = (state, value) => {
    state.is_show_sidebar = value;
}
export const toggleSearch = (state, value) => {
    state.is_show_search = value;
}
export const toggleLocale = (state, value) => {
    value = value || 'de';
    i18n.global.locale = value;
    localStorage.setItem('i18n_locale', value);
    state.locale = value;
}

export const toggleDarkMode = (state, value) => {
    //light|dark|system
    value = value || 'dark';
    localStorage.setItem('dark_mode', value);
    state.dark_mode = value;
    if (value == 'light') {
        state.is_dark_mode = 0;
    } else if (value == 'dark') {
        state.is_dark_mode = 1;
    } else if (value == 'purple') {
        state.is_dark_mode = 2;
    }

    if (state.is_dark_mode == 1) {
        document.querySelector('body').classList.add('dark');
        document.querySelector('body').classList.remove('purple');
    } else if (state.is_dark_mode == 2) {
        document.querySelector('body').classList.remove('dark');
        document.querySelector('body').classList.add('purple');
    } else {
        document.querySelector('body').classList.remove('dark');
        document.querySelector('body').classList.remove('purple');
    }
}

export const toggleMenuStyle = (state, value) => {
    //horizontal|vertical|collapsible-vertical
    value = value || '';
    localStorage.setItem('menu_style', value);
    state.menu_style = value;
    if (!value || value === 'vertical') {
        state.is_show_sidebar = true;
    } else if (value === 'collapsible-vertical') {
        state.is_show_sidebar = false;
    }
}

export const toggleLayoutStyle = (state, value) => {
    //boxed-layout|large-boxed-layout|full
    value = value || '';
    localStorage.setItem('layout_style', value);
    state.layout_style = value;
}


export const setSysSettings = (state, data = false) => {
    state.SysSettings = data;
}

export const setMessages = (state, data = false) => {
    state.UserMessages = data.MessagesList;
}

export const setCompanyLicenses = (state, data = false) => {

    state.CompanyData.CompanyLicenseList = data.CompanyLicensesList;
}
export const setUser = (state, data = false) => {
    state.User = data;
}


export const setSalutations = (state, data = false) => {
    state.Lookups.SalutationsLookup = data.LookupList;
}

export const setJobAdTypes = (state, data = false) => {
    state.Lookups.JobAdTypesLookup = data.LookupList;
}

export const setSalaryTypes = (state, data = false) => {
    state.Lookups.SalaryTypesLookup = data.LookupList;
}

export const setLocationTypes = (state, data = false) => {
    state.Lookups.LocationTypesLookup = data.LookupList;
}

export const setAppointmentTypes = (state, data = false) => {
    state.Lookups.AppointmentTypesLookup = data.LookupList;
}

export const setNotificationTypes = (state, data = false) => {
    state.Lookups.NotificationTypesLookup = data.LookupList;
}

export const setDriverLicenses = (state, data = false) => {
    state.Lookups.DriverLicensesLookup = data.LookupList;
}

export const setGraduations = (state, data = false) => {
    state.Lookups.GraduationsLookup = data.LookupList;
}

export const setJobFields = (state, data = false) => {
    state.Lookups.JobFieldsLookup = data.LookupList;
}

export const setPaymentIntervals = (state, data = false) => {
    state.Lookups.PaymentIntervalsLookup = data.LookupList;
}

export const setStudentQuestions = (state, data = false) => {
    state.Lookups.StudentQuestionsLookup = data.LookupList;
}

export const setCompanyQuestions = (state, data = false) => {
    state.Lookups.CompanyQuestionsLookup = data.LookupList;
}

export const setStudyFields = (state, data = false) => {
    state.Lookups.StudyFieldsLookup = data.LookupList;
}


export const setCompanySizes = (state, data = false) => {
    state.Lookups.CompanySizesLookup = data.LookupList;
}

export const setJobs = (state, data = false) => {
    state.Lookups.JobsLookup = data.LookupList;
}


export const setCountries = (state, data = false) => {

    state.Lookups.CountriesLookup = data.LookupList;
}

export const logOff = (state) => {

    Object.assign(state, defaultState)
    // console.log(state)
    //
    // Object.keys(state).forEach(key => {
    //
    //     if (defaultState.hasOwnProperty(key)) {
    //
    //         if (isObject(defaultState[key])) {
    //
    //             state[key] = mutate(defaultState[key])
    //
    //         } else {
    //             state[key] = defaultState[key];
    //         }
    //     }
    //
    //
    // });
    //
    //
    // state.didLogOff = false
    // state.System = {}
    // state.loggedIn = false
    // state.PkUserForAllowedRoutes = null
    // state.HasToCheckAGB = false
    // state.NeedToValidateMFA = false
    // state.IsEdgeOrIE = false
    // state.ComponentRendered = []
    //
    // state.requestMerge = {
    //     "RequestUserID": null,
    //     "JWTToken": null,
    //     "LanguageKey": 'de-DE',
    //     "UserName": null
    // };
    // state.User = null
    // state.SysSettings = null
    // state.UserNotificationConfig = []
    // state.UserNotificationSettings = {}
    // state.UserNotifications = []
    // state.UserMessages = []
    // state.UserNotificationsAll = []
    //
    // state.Dashboard.Config.BoxNotification = null
    // state.Dashboard.Config.DashboardConfigsAll = []
    // state.Dashboard.Config.DashboardConfigsOdd = []
    // state.Dashboard.Config.DashboardConfigsEven = []
    //
    // state.MasterData.MessagesLookup = {}
    //
    //
    // state.Lookups.SalutationsLookup = {}
    // state.Lookups.CountriesLookup = {}
    //
    // state.Lookups.JobAdTypesLookup = {}
    // state.Lookups.SalaryTypesLookup = {}
    // state.Lookups.LocationTypesLookup = {}
    // state.Lookups.AppointmentTypesLookup = {}
    // state.Lookups.DriverLicensesLookup = {}
    // state.Lookups.GraduationsLookup = {}
    // state.Lookups.JobFieldsLookup = {}
    // state.Lookups.JobsLookup = {}
    // state.Lookups.CompanySizesLookup = {}
    // state.Lookups.PaymentIntervalsLookup = {}
    // state.Lookups.StudentQuestionsLookup = {}
    // state.Lookups.CompanyQuestionsLookup = {}
    // state.Lookups.StudyFieldsLookup = {}
    //
    // state.Lookups.LookupDataList = []
    // state.Lookups.LookupGroupList = []
    // state.Lookups.LookupGroupLookUp = {}
    // state.Lookups.LookupDataLookup = {}
    // state.Lookups.ListGroup2List = {}
    // state.Lookups.ListLookUp = {}

    state.didLogOff = true;

}

export const didLogOff = (state, val = false) => {
    state.didLogOff = !!val;
}


export const mergeSystem = (state, data = {}) => {
    state.System = mergeDeep(state.System, data);
}

export const setRequestMerge = (state, requestMerge = {}) => {
    state.requestMerge = mutate(requestMerge, {LanguageKey: 'de-DE'});
}

export const replaceRequestMergeSessionID = (state, sessionID = {}) => {
    state.requestMerge.SessionID = sessionID;
}

export const setEntryPath = (state, path = '/') => {
    state.entryPath = path;
}

