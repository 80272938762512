import Store from '../store.js';

/**
 * creates a restBody for RESTful requests in the desired format.
 * You just pass data which means the real body you want to send.
 * Data will be extended by additional important properties for
 * registered users such as session id etc.
 *
 * Usage:

 restBody({ PkUser: "........."  });

 returns :

 {
        request:
                {
                "PkUser" : "...",
                "DbServerName": "....",
                "DbName":"...",
                "RequestUserID"  :"...",
                "SessionID": "...",
                "LanguageKey": 'de-DE'
                }
    }
 *
 * @param data
 * @returns {{request: any}}
 */
export function restBody(data={}){

    return Object.assign({}, Store.state.requestMerge, data);
}
