<template>
    <!-- Modal -->
    <div ref="modalCalendar"
         class="modal fade template-modal"
         tabindex="-1"
         role="dialog"
         id="calendarEntryModal"
         aria-hidden="true">
        <div class="modal-dialog modal-xxl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <font-awesome-icon icon="fas fa-edit" class="edit-icon"/> &nbsp;
                        <font-awesome-icon icon="fas fa-gear"/> &nbsp;
                        {{ headerText }}
                    </h5>
                    <button type="button" data-dismiss="modal" ref="closeBtn" id="closeBtn" data-bs-dismiss="modal"
                            aria-label="Close"
                            class="btn-close">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="tab-content" id="simpletabContent">
                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <CalendarEntryForm
                                ref="createForm"
                                :no-title="true"
                                :edit-mode="EditMode"
                                :is-active="IsActive"
                                :is-past="IsPast"
                                namespace="Create Form: "
                            ></CalendarEntryForm>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <div class="footer-inner">

                        <base-button
                            type="yellow" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close"
                            native-type="button"
                            class="btn btn-yellow input-rounded float-left">
                            <font-awesome-icon icon="fas fa-times"/>
                            {{ $t('CANCEL') }}
                        </base-button>


                        <base-button native-type="button"
                                     type="danger"
                                     v-if="EditMode&&!IsPast"
                                     @click="deleteApp"
                                     class="btn btn-red input-rounded margin-left-10 float-left">
                            <font-awesome-icon icon="fa fa-trash"/>
                            {{ $t('DELETE') }}
                        </base-button>
                        <base-button
                            @click="downloadICS"
                            type="mint"
                            v-if="EditMode"
                            native-type="button"
                            class="btn btn-sm  btn-mint float-left">
                            <font-awesome-icon icon="fas fa-calendar-plus"/>
                            {{ $t('DOWNLOAD-ICS') }}
                        </base-button>

                        <WaitingButton
                            size="sm"
                            icon="fas fa-check"
                            class="float-right btn btn-mint input-rounded"
                            :waiting="isSaving"
                            @click="save">
                            {{ $t('SAVE') }}
                        </WaitingButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import $ from 'jquery';
import WaitingButton from "@/components/WaitingButton.vue";
import CalendarEntryForm from "./CalendarEntryForm";
import {APPOINTMENT_TYPES} from '@/data/appointment';
import {saveAs} from 'file-saver'
import {convertDateTimeToString, getNowUTC} from "@/utils/convertDateTime";
import moment from 'moment'
import {CREATE} from "@/data/cud";

export default {
    name: "CalendarEntryCreateModal",
    components: {CalendarEntryForm, WaitingButton},
    props: ['appointmentController'],
    data() {
        return {
            isSaving: false,
            error: false,
            errorMessage: null,
            Appointment: null,
            AppointmentType: null
        }
    },
    watch: {},
    computed: {
        controller() {
            return this.$props.appointmentController ? this.$props.appointmentController : {};
        },
        headerText() {

            let addOn = this.Appointment != null && this.Appointment.Subject != null ? ' (' + this.Appointment.Subject + ')' : ''

            if (this.EditMode)
                return this.trans('EDIT-APPOINTMENT') + addOn
            else
                return this.trans('CREATE-APPOINTMENT') + addOn

        },
        ActiveAppointment() {
            return this.Appointment != null ? this.Appointment : null;
        },
        CurrentAppointmentType() {
            return this.AppointmentType;
        },
        EditMode() {
            if (this.ActiveAppointment == null)
                return false;
            return this.ActiveAppointment != null && this.ActiveAppointment.TrackingState != CREATE;
        },
        IsPast() {
            if (this.ActiveAppointment == null)
                return false;
            let now = getNowUTC();

            return this.ActiveAppointment != null && this.ActiveAppointment.PkAppointment != null && (moment(this.ActiveAppointment.StartDate) < now) && (moment(this.ActiveAppointment.EndDate) < now)
        },
        IsActive() {
            if (this.ActiveAppointment == null)
                return false;

            let now = getNowUTC();
            return this.ActiveAppointment != null && this.ActiveAppointment.PkAppointment != null && (moment(this.ActiveAppointment.StartDate) < now) && (moment(this.ActiveAppointment.EndDate) > now)
        }
    },
    methods: {
        twiggle() {
        },
        close(e) {
            this.showModal = false;
        },
        open(appointment) {
            this.Appointment = null;
            this.Appointment = appointment;
            this.AppointmentType = this.Appointment.AppType;

            const createForm = this.$refs.createForm;
            createForm.setData(appointment);

        },
        reset() {

            const createForm = this.$refs.createForm;
            createForm.setError();
            createForm.reset();

        },
        downloadICS() {


            let start = this.Appointment.StartDate.replace('.', '').replace('-', '').replace(':', '').replace('.', '').replace('-', '').replace(':', '').replace('.', '').replace('-', '').replace(':', '').replace('Z', '');
            let end = this.Appointment.EndDate.replace('.', '').replace('-', '').replace(':', '').replace('.', '').replace('-', '').replace(':', '').replace('.', '').replace('-', '').replace(':', '').replace('Z', '');


            let now_date = new Date();
            const UID = this.Appointment.PkAppointment;

            let now_year = (`0000${(now_date.getFullYear().toString())}`).slice(-4)
            let now_month = (`00${((now_date.getMonth() + 1).toString())}`).slice(-2)
            let now_day = (`00${((now_date.getDate()).toString())}`).slice(-2)
            let now_hours = (`00${(now_date.getHours().toString())}`).slice(-2)
            let now_minutes = (`00${(now_date.getMinutes().toString())}`).slice(-2)
            let now_seconds = (`00${(now_date.getSeconds().toString())}`).slice(-2)


            let now_time = `T${now_hours}${now_minutes}${now_seconds}`

            let now = now_year + now_month + now_day + now_time

            const Event = `BEGIN:VCALENDAR
  PRODID:-//HRFNxt
  VERSION:2.0
  METHOD:PUBLISH
  BEGIN:VEVENT
  UID:${UID}
  DESCRIPTION:${this.Appointment.Body}
  X-ALT-DESC;FMTTYPE=text/html:${this.Appointment.Body}
  DTSTAMP:${now},
  DTSTART:${start}
  DTEND:${end}
  SUMMARY;LANGUAGE=de-DE:${this.Appointment.Subject}
  BEGIN:VALARM
  TRIGGER:-PT15M
  ACTION:DISPLAY
  DESCRIPTION:Reminder
  END:VALARM
  END:VEVENT
  END:VCALENDAR`


            let blob = new Blob([Event], {type: "text/x-vCalendar;charset=utf-8"});
            saveAs(blob, `${this.Appointment.Subject}.ics`);

        },
        deleteApp() {

            if (!this.EditMode)
                return;

            new window.Swal({
                title: this.trans('DELETE'),
                text: this.trans('DELETE-APPOINTMENT'),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.trans('NO'),
                confirmButtonText: this.trans('YES'),
                padding: '2em'
            }).then(doDelete => {
                if (doDelete.isConfirmed) {
                    this.controller.delete(this.Appointment).then((response) => {
                        if (response.IsError) {

                            this.$notify(
                                {
                                    title: this.trans('ERROR'),
                                    text: this.trans(response.ErrorMessage),
                                    type: 'error',
                                });

                            const createForm = this.$refs.createForm;
                            createForm.setError(response.ErrorMessage);
                        } else {
                            this.close();
                            this.$emit('deleted', true);
                            this.$notify(
                                {
                                    title: this.trans('SUCCESS'),
                                    text: this.trans('DATA-SAVED'),
                                    type: 'success'
                                });
                        }
                    });
                }
            });

        },
        save() {

            this.isSaving = true;
            this.$refs.createForm.setError();
            const createForm = this.$refs.createForm;

            createForm.validate()
                .then(doSave => {
                    if (!doSave) {
                        this.isSaving = false;
                        return;
                    }

                    let data = createForm.getData();

                    if (data.PkAppointment != null) {
                        this.controller.update(data).then((response) => {
                            this.isSaving = false;

                            if (response.IsError) {

                                this.$notify(
                                    {
                                        title: this.trans('ERROR'),
                                        text: this.trans(response.ErrorMessage),
                                        type: 'error',
                                    });


                                this.$refs.createForm.setError(response.ErrorMessage);
                                return;
                            }
                            createForm.reset();

                            this.close();
                            this.$emit('created', true);
                            this.$notify(
                                {
                                    title: this.trans('SUCCESS'),
                                    text: this.trans('DATA-SAVED'),
                                    type: 'success'
                                });
                        }).catch(() => {
                            this.isSaving = false;
                        })
                    } else {
                        this.controller.create(data).then((response) => {
                            this.isSaving = false;

                            if (response.IsError) {
                                this.$notify(
                                    {
                                        title: this.trans('ERROR'),
                                        text: this.trans(response.ErrorMessage),
                                        type: 'error',
                                    });
                                this.$refs.createForm.setError(response.ErrorMessage);

                                return;
                            }
                            createForm.reset();

                            this.close();
                            this.$emit('created', true);
                            this.$notify(
                                {
                                    title: this.trans('SUCCESS'),
                                    text: this.trans('DATA-SAVED'),
                                    type: 'success'
                                });

                        }).catch(() => {
                            this.isSaving = false;
                        })
                    }
                })

        },

    }
}
</script>

<style scoped>
.template-modal {
    z-index: 9999;
}


.footer-inner {
    display: block;
    width: 100%;
}

.parent-selection-header > .badge {
    font-size: 14px;
}


.edit-icon {
    color: #2E63BF;
}

</style>
