<template>
    <div v-show="twiggleFlag" class="form-group">
        <Form ref="_form" :validationSchema="validationSchema"
              @submit="validate">
            <div class="form-group flex" v-for="(config) in DashboardConfigs" v-bind:key="config.PkDashboardConfig">
                <div class="checkbox-outline-primary custom-control custom-checkbox">

                    <base-checkbox
                        :id="'isVisible_' + config.PkDashboardConfig"
                        v-model="config.IsVisible"
                        class="custom-control-input"
                        :checked="config.IsVisible"
                        :label="getLabel(config.BoxType)"
                    />

                </div>
            </div>
            <button ref="silentSubmit" type="submit" class="btn-silent-submit"/>
        </Form>
        <b-alert
            v-model="error"
            variant="danger"
            class="error-alert"
        >
            <div class="error-alert-wrap"><font-awesome-icon icon="fas fa-exclamation-triangle" class="error-icon" /> {{ errorMessage }}</div>
        </b-alert>
    </div>
</template>

<script>
import {DASHBOARD_BOX_TYPES} from "@/data/dashboard";
import {Form} from 'vee-validate';
import * as yup from "yup";

export default {
    name: "GeneralBoxForm",
    data() {
        return {
            validationSchema: yup.object({}),
            Configs: [],
            OldConfigs: [],
            error: false,
            errorMessage: '',
            twiggleFlag: true
        }
    },
    components: {Form},
    watch: {
        model: {
            handler(model) {
                this.$emit('input', model);
            },
            deep: true
        }
    },
    computed: {
        DashboardConfigs() {
            return this.Configs;
        }
    },
    methods: {
        twiggle() {
            this.twiggleFlag = false;
            this.$nextTick(() => {
                this.twiggleFlag = true;
            })

        },
        getTranslation(value) {
            return this.trans(value)
        },
        setError(errorMessage) {
            if (errorMessage) {
                this.error = true;
                this.errorMessage = this.trans(errorMessage);
            } else {
                this.error = false;
                this.errorMessage = '';
            }
        },
        getLabel(boxType) {
            switch (boxType) {
                case DASHBOARD_BOX_TYPES.NotificationBox:
                    return this.trans('NOTIFICATIONS')
                case DASHBOARD_BOX_TYPES.MessageBox:
                    return this.trans('MESSAGES')
                case DASHBOARD_BOX_TYPES.MatchBox:
                    return this.trans('MATCHES')
                case DASHBOARD_BOX_TYPES.AppointmentBox:
                    return this.trans('APPOINTMENTS')
            }
        },
        reset() {
            this.setError();
            this.Configs = [...this.OldConfigs];
            this.error = false;
        },

        getData() {
            return this.Configs;
        },

        _set(d = []) {

            this.Configs = d;

            this.OldConfigs = [...d];

            this.setError(null)
            this.twiggle();

        },
        setData(data = []) {
            this._set(data)
        },
        validate() {

            return Promise.resolve(true);
        }
    }
}
</script>

<style scoped>
.flex {
    display: flex;
    margin-right: 40px;
}

.combobox-margin {
    margin-right: 50px;
    width: calc(100vw - 5px);
}

</style>

