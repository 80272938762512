<template>
  <div class="comp-wrap">
    <div class="container">

      <!-- Outer Row -->
      <div class="row justify-content-center">

        <div class="col-xl-10 col-lg-12 col-md-9">

          <div class="card o-hidden border-0 shadow-lg my-5">
            <div class="card-body p-0">
              <!-- Nested Row within Card Body -->
              <div class="row">
                <div class="col-lg-6 d-none d-lg-block ">
                  <div class="compLogo">
                    <img src="../../assets/jobineo_logo.png"/>
                  </div>
                  <div v-show="waiting" class="spinner-container">
                    <div class="spinner-wrap">

                      <font-awesome-icon icon="fas fa-spin fa-circle-notch"/>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="p-5" style="padding-top: 90px!important;padding-bottom: 90px!important;">
                    <div class="text-center">
                      <h1 class="h4 text-gray-900 mb-4">{{ $t('ACCEPT-AGB-DSGVO') }}</h1>
                      <div class="help-text">
                        <template>
                          {{ $t('PLEASE-ACCEPT-AGB-DSGVO') }}
                        </template>
                      </div>
                    </div>
                    <div>
                      <ValidationObserver ref="observer" v-slot="{ validate }">
                        <form ref="_form" @submit.prevent="validate().then(onUpdate)">
                          <div class="row flex">
                            <div class="form-group col-5">
                              <base-checkbox
                                id="2"
                                :required="true"
                                :checked="model.AGBSAccepted"

                                @input="setAGB"
                                :label="$t('AGBS-ACCEPT')"
                              />
                            </div>
                          </div>
                          <div class="row flex">
                            <div class="form-group col-5">
                              <base-checkbox
                                id="agbAccept"
                                @input="setDSGVO"
                                :required="true"
                                :checked="model.DSGVOAccepted"
                                :label="$t('DSGVO-ACCEPT')"
                              />
                            </div>
                          </div>
                          <div class="text-center">
                            <a class="small"
                               href="#/DataProtection" target="_blank">{{ trans('DATA-PROTECTION') }}</a>
                          </div>
                          <div class="text-center" style="margin-bottom: 50px!important;">
                            <a class="small"
                               href="#/AGB" target="_blank">{{ trans('AGB') }}</a>

                          </div>
                          <WaitingButton
                            size="sm"
                            variant="success"
                            icon="fas fa-check"
                            class="float-right input-rounded"
                            :waiting="waiting"
                            @click="$refs.silentSubmit.click()">
                            {{ $t('UPDATE') }}
                          </WaitingButton>
                          <div>
                            <b-alert
                              v-model="error"
                              variant="danger"
                              class="error-alert"
                            >
                              <div class="error-alert-wrap"><font-awesome-icon icon="fas fa-exclamation-triangle" class="error-icon" /> {{ errorMessage }}
                              </div>
                            </b-alert>
                          </div>
                          <button ref="silentSubmit" type="submit" class="btn-silent-submit"/>
                        </form>
                      </ValidationObserver>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * View: EnterNewPassword
 * Path: /EnterNewPassword/:PkUser/:HashCode
 * A view to enter a new password after password forgotten
 * This is a public landing page
 *
 */
import {restUri} from "@/data/restPaths";
import WaitingButton from "../../components/WaitingButton.vue";

export default {
  name: "AGBReaccept",
  components: {WaitingButton},
  data() {
    return {
      model: {
        AGBSAccepted: false,
        DSGVOAccepted: false
      },
      waiting: false,
      confirmed: false,
      error: false,
      errorMessage: '',
      regenerated: false
    }
  },
  methods: {
    validate(tab) {

      if (this.model.AGBAccepted == false || this.model.DSGVOAccepted == false) {
        return Promise.resolve(false)
      } else {
        return Promise.resolve(true);
      }
    },
    setAGB(value) {
      this.model.AGBSAccepted = value;
      this.error = false;
    },
    setDSGVO(value) {
      this.model.DSGVOAccepted = value;
      this.error = false;
    },
    onUpdate(checked) {
      if (!checked)
        return;

      let form = this.$refs._form;


      if (form.checkValidity() === false) {
        this.$refs.silentSubmit.click();
        return false;
      }

      return this.submitIt();

    },
    submitIt() {
      this.waiting = true;
      const PkUser = this.$store.state.PkUserForAllowedRoutes;

      this.error = false;
      this.errorMessage = '';

      this.axios.post(restUri.REACCEPT_AGB_DSGVO, {
        PkUser,
        "AGBAccepted": this.model.AGBSAccepted,
        "DSGVOAccepted": this.model.DSGVOAccepted
      }).then(result => {
        this.waiting = false;
        let data = result.data;
        if (data.IsError) {
          this.error = true;
          this.errorMessage = this.trans(data.ErrorMessage);
        } else {

          this.$store.state.User = data.User;
          this.errorMessage = '';
          this.$router.push('/Login');
        }

      }).catch(result => {
        this.waiting = false;
        let data = result.data;
        if (data.IsError) {
          this.error = true;
          this.errorMessage = this.trans(data.ErrorMessage);
        }
      });
    }
  },


}
</script>

<style scoped>

.comp-wrap {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 11;
  top: 0;
  left: 0;
}

.comp-wrap > .container {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.comp-wrap > .container > .row {
  align-content: center;
  flex-direction: row;
  flex-grow: 1;
}

.compLogo {
  position: relative;
  display: block;
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.compLogo > img {
  width: 85%;
  margin-left: 50px;
  margin-top: 140px;

}

.spinner-container {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.spinner-wrap {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: nowrap;
  padding-left: 1.7rem;
  padding-bottom: 1.5rem;

}

.spinner-wrap > i {
  font-size: 4em;
  color: #ffffff;
}

.h-spacer button {
  color: yellow;
  padding: 0.75rem 1.0rem;
}

.help-text {
  font-size: 12px;
  text-align: left;
  margin-bottom: 10px;
}
.text-danger {
    color: #E7515A !important;
    font-size: 1.3em;
}

.text-success {
    color: #9acccb !important;
    font-size: 1.5em;
}

</style>
