<template>
    <div class="page-section">
        <div class="header-section unternehmen">
            <div class="container mobile">
                <div class="grid">
                    <div class="text-box">
                        <h1>
                            <div class="headline-box-unternehmen">Aussuchen statt selber suchen.</div>
                        </h1>
                        <div class="text-white-unternehmen">
                            <p>
                                Warum teure Stellenanzeigen schalten und auf Bewerbungen hoffen,
                                wenn auf JoBiNeo Suchende nur einen Klick entfernt sind?
                            </p>
                        </div>
                        <div class="text-white flex-col">
                            <ul class="ul-flex">
                                <li class="li-flex">
                                    <i class="icon-checkmark"></i>
                                    <p class="text-checkmark-white">Praktikum</p>
                                </li>
                                <li class="li-flex">
                                    <i class="icon-checkmark"></i>
                                    <p class="text-checkmark-white">Ausbildung</p>
                                </li>
                                <li class="li-flex">
                                    <i class="icon-checkmark"></i>
                                    <p class="text-checkmark-white">Duales Studium</p>
                                </li>
                                <li class="li-flex">
                                    <i class="icon-checkmark"></i>
                                    <p class="text-checkmark-white">Berufseinstieg</p>
                                </li>
                            </ul>
                        </div>
                        <a>
                            <button class="button-yellow" @click="register">Kostenlos anmelden</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-section-1">
            <div class="container">
                <img
                    id="yellow-box-unternehmen"
                    class="yellow-box-unternehmen"
                    src="../../assets/images/yellow-box-unternehmen.png"
                    alt=""/>
                <div class="headline-box-black">
                    <div>Wie funktioniert JoBiNeo?</div>
                </div>
                <div class="grid">

                    <div class="card card-1">
                        <img class="step" src="../../assets/images/1.png" alt=""/>
                        <img class="animated-gif" src="../../assets/images/laptop.gif" alt=""/>
                        <p class="card-text">
                            Kostenlos registrieren und Unternehmen verifizieren lassen.
                        </p>
                    </div>
                    <div class="card card-2">
                        <img class="step" src="../../assets/images/2.png" alt=""/>
                        <img class="animated-gif" src="../../assets/images/user.gif" alt=""/>
                        <p class="card-text">
                            Unternehmensprofil anlegen und sich modern und zielgruppengerecht
                            mit Video etc. präsentieren.
                        </p>
                    </div>
                    <div class="card card-3">
                        <img class="step" src="../../assets/images/3.png" alt=""/>
                        <img class="animated-gif" src="../../assets/images/rocket.gif" alt=""/>
                        <p class="card-text">
                            Geeignete Bewerber:innen finden, gefunden werden und
                            direkt zum Vorstellungs-gespräch / Test einladen.
                        </p>
                    </div>
                    <div class="text-full">
                        JoBiNeo ist keine klassische Jobbörse, auf der
                        man Stellenanzeigen schaltet und auf Bewerbungen wartet.
                        Herkömmliche Recruitingmethoden funktionieren bei den
                        Jugendlichen nicht mehr. Schüler:innen und Berufsanfänger:innen
                        wollen auch nicht etliche Karriereportale durchsuchen.
                        Auf JoBiNeo legen sie einmalig digitale Bewerbungsprofile
                        mit ihren Berufswünschen, Vorstellungsvideos & Fähigkeiten
                        an, nach denen Unternehmen mit individuellen Kriterien
                        suchen können. Bewerbung funktioniert mit unserem
                        Matchingsystem ganz einfach per Klick in beide Richtungen.
                        Unternehmen können passende Bewerber:innen unkompliziert
                        kontaktieren und/oder direkt per
                        Klick zum Gespräch / Eignungstest einladen.
                    </div>
                </div>
            </div>
        </div>
        <div class="content-section-2">
            <div class="container">
                <div class="headline-box-black">
                    <div>Die Vorteile auf einen Blick</div>
                </div>
                <div class="grid">
                    <div class="checkmark-box-left">
                        <div class="flex-col-text">
                            <div class="flex-row">
                                <i class="icon-checkmark-blue"></i>
                                <p class="text-checkmark">Keine Kosten mehr für Stellenanzeigen und keine
                                    Streuverluste</p>
                            </div>
                            <div class="flex-row">
                                <i class="icon-checkmark-blue"></i>
                                <p class="text-checkmark">Keine unnötigen Vorstellungsgespräche oder Sichtung
                                    langweiliger
                                    Bewerbungsunterlagen</p>
                            </div>
                            <div class="flex-row">
                                <i class="icon-checkmark-blue"></i>
                                <p class="text-checkmark">
                                    Jederzeit Zugriff auf eine Vielzahl passender und interessierter Bewerber:innen mit
                                    direkter
                                    Kontaktmöglichkeit
                                </p>
                            </div>
                            <div class="flex-row">
                                <i class="icon-checkmark-blue"></i>
                                <p class="text-checkmark">
                                    Keine Flut von ungeeigneten Bewerbungen
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="checkmark-box-right">
                        <div class="flex-col-text">
                            <div class="flex-row">
                                <i class="icon-checkmark-blue"></i>
                                <p class="text-checkmark">Zielgruppen gerechte Unternehmenspräsentation mit Video</p>
                            </div>
                            <div class="flex-row">
                                <i class="icon-checkmark-blue"></i>
                                <p class="text-checkmark">Erster Eindruck schon vor dem persönlichen Kennenlernen durch
                                    z.B.
                                    Bewerbungsvideos</p>
                            </div>
                            <div class="flex-row">
                                <i class="icon-checkmark-blue"></i>
                                <p class="text-checkmark">
                                    Schneller Ersatz bei spontanen Kündigungen z.B. in der Probezeit
                                </p>
                            </div>
                            <div class="flex-row">
                                <i class="icon-checkmark-blue"></i>
                                <p class="text-checkmark">
                                    (Über-)regionale, kriteriengeleitete Suche
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="yellow-box" src="../../assets/images/yellow-box-2.png" alt=""/>
            </div>
        </div>
        <div class="content-section-3">
            <div class="container">
                <div class="headline-box-green">
                    <div>Unsere Mission</div>
                </div>
                <div class="grid">
                    <div class="text-full-green">
                        HEUTE HIER. MORGEN IN DER AUSBILDUNG. ÜBERMORGEN IM JOB.
                    </div>
                    <div class="text-full-white-strong">
                        Wir vereinfachen den Bewerbungsprozess und das Recruiting
                        von Schüler:innen und Berufsanfänger:innen und erleichtern
                        damit den Weg in die
                        Ausbildung und anschließend den Übergang in den ersten Job.
                    </div>
                    <div class="text-full-white">
                        Wir möchten gemeinsam mit Ihnen dafür sorgen,
                        dass das System „Berufsausbildung“ in Deutschland
                        attraktiv und zukunftsfähig bleibt. Revolutionieren
                        wir den Ausbildungs- bzw. Arbeitsmarkt, indem die
                        Bewerbung moderner, einfacher und deutschlandweit
                        möglich wird. Besonders wichtig ist uns dabei,
                        dass der Mensch mit seiner Persönlichkeit wieder in den Fokus rückt.
                    </div>
                </div>
                <div class="button-box">
                    <a>
                        <button class="button-yellow" @click="register">Kostenlos anmelden</button>
                    </a>
                </div>
            </div>
        </div>
        <div class="content-section-4">
            <div class="container">
                <div class="headline-box-black">
                    <div>Schon vom JoBiNeo Karriere-Podcast gehört?</div>
                </div>
                <div class="grid">
                    <div class="text-full-black-podcast">
                        <p>
                            “Yeah, Schule / Ausbildung bald geschafft und dann? Im Podcast
                            stelle ich dir alle möglichen Berufe und Möglichkeiten vor und
                            beantworte alle möglichen Fragen rund um Karriere, Bewerbung,
                            Arbeitsrecht uvm. Azubis, Unternehmen und Ausgelernte erzählen
                            hier gnadenlos ehrlich von ihrem Arbeitsalltag.”
                        </p>
                        <div class="button-box">
                            <a href="https://anchor.fm/jobineo" target="_blank">
                                <button class="button-yellow">Zum Podcast</button>
                            </a>
                        </div>
                    </div>
                    <img
                        class="podcast-image"
                        src="../../assets/images/podcast-cover.jpg"
                        alt=""/>
                </div>
                <img
                    class="question-box"
                    src="../../assets/images/question-box.svg"
                    alt=""/>
            </div>
        </div>
        <div class="content-section-5">
            <div class="container">
                <div class="headline-box-black">
                    <div>FAQ</div>
                </div>
                <div class="grid">
                    <div class="accordion" role="tablist">
                        <b-accordion>
                            <b-accordion-item title="Ist JoBiNeo kostenlos?">
                                <p style="color:black;"> Für Schüler:innen ist JoBiNeo immer kostenlos.
                                    Für Unternehmen in der Basisversion ebenfalls.
                                    Sie können kostenlos ein Unternehmensprofil anlegen
                                    und eine einfache Stellenanzeige schalten. Sofern Sie
                                    aktiv Interessierte suchen und kontaktieren
                                    möchten, bietet JoBiNeo diverse Pakete mit vielen Premiumfunktionen an.</p>
                            </b-accordion-item>

                            <b-accordion-item title="Was macht JoBiNeo so
                  einzigartig und zum „Must have“?">
                                <p style="color:black;">
                                    Hunderttausende Stellen und Ausbildungsplätze bleiben
                                    in Deutschland unbesetzt. Herkömmliche Stellenanzeigen
                                    und Recruiting -Prozesse funktionieren nicht mehr, die Zahl an
                                    Bewerbungen geht stetig zurück. Genau das macht JoBiNeo anders:<br/><br/>

                                    Hier schalten Sie nicht nur eine Stellenanzeige und
                                    warten auf Bewerbungen, sondern können aktiv interessierte
                                    Bewerber:innen mit Ihren individuellen Filterkriterien durchsuchen.
                                    Während eine gewöhnliche Stellenanzeige viele Streuverluste hat,
                                    erreichen Sie auf JoBiNeo direkt die für Sie relevante Zielgruppe. <br/><br/>

                                    JoBiNeo ist modern und wurde gemeinsam mit der GenZ
                                    entwickelt. Die Gründerin ist selbst Berufsschullehrerin
                                    und damit stetig im unmittelbaren Kontakt mit der Zielgruppe.
                                    Dadurch, dass Suchende sich mit einem Klick bewerben können,
                                    ist es super einfach und damit genau das, was sich die GenZ wünscht.
                                    Sie wollen nicht hunderte Karriere -Seiten durchsuchen oder etliche
                                    Bewerbungen schreiben. Viele wissen nicht einmal genau,
                                    was sie beruflich machen möchten. Hier können sie sich
                                    von Ihrer Kontaktaufnahme inspirieren lassen und das sogar
                                    deutschlandweit. Gerade Schüler:innen suchen potenzielle
                                    Unternehmen aus, die sie aus ihrem direkten Umkreis kennen.
                                    Hier finden Sie jetzt auch Bewerber:innen, auf deren Suchradar
                                    Sie bisher noch nicht erschienen sind. <br/><br/>

                                    Viele Azubis werden nach dem 3. Ausbildungsjahr
                                    nicht übernommen oder möchten den Betrieb für die erste
                                    Festanstellung wechseln. Die Suche nach Stellen für
                                    Berufseinsteiger:innen ohne Berufserfahrung ist schwierig.
                                    JoBiNeo hat sich auf den Bereich Ausbildung und Berufseinstieg
                                    spezialisiert, hier finden Unternehmen gezielt ihre zukünftigen Fachkräfte.
                                </p></b-accordion-item>

                            <b-accordion-item title="Für welche Unternehmen ist
                  JoBiNeo geeignet?">
                                <p style="color:black;">
                                    Für alle Unternehmen, die ein Praktikumsplatz,
                                    einen Ausbildungsplatz, ein duales Studium oder eine andere
                                    freie Stelle für Berufseinsteiger:innen besetzen möchten.
                                </p></b-accordion-item>
                            <b-accordion-item title="Arbeitet JoBiNeo DSGVO konform?">
                                <p style="color:black;">
                                    Ja. Unser Web-Hoster ist STRATO, mit zwei ISO 27001
                                    zertifizierten Rechenzentren in Berlin und Karlsruhe.
                                    Wir arbeiten zu 100 % DSGVO konform und geben keine Daten
                                    an unberechtigte Dritte weiter. Durch anonymisierte Profile
                                    und unser Matching-System, müssen Schüler:innen
                                    der Weitergabe zum Zweck der Bewerbung jedes Mal aktiv zustimmen.
                                </p></b-accordion-item>
                            <b-accordion-item title="Können wir uns an der Weiterentwicklung von JoBiNeo beteiligen?">
                                <p style="color:black;">
                                    JoBiNeos Karriere beginnt gerade erst. Wir arbeiten
                                    täglich daran, dass viele Bewerber:innen und Unternehmen
                                    JoBiNeo für ihren Bewerbungsprozess nutzen. Wir haben sehr
                                    viele weitere spannende Ideen und Weiterentwicklungsmöglichkeiten
                                    für die Zukunft. Über ein Feedback und weitere Vorschläge
                                    per E-Mail oder Whatsapp freuen wir uns trotzdem!
                                </p></b-accordion-item>
                        </b-accordion>
                    </div>

                </div>
            </div>
        </div>
        <div id="cs6" class="content-section-6">
            <div class="container">
                <div class="headline-box-green">
                    <div>KEINE LUST MEHR AUF BEWERBUNGEN ZU WARTEN?</div>
                </div>
                <div class="grid">
                    <div class="text-full-white-strong-italic">
                        <i
                        >Herzlich Willkommen auf der vermutlich “coolsten” Bewerbungsplattform überhaupt!
                        </i>
                    </div>
                </div>
            </div>
        </div>
        <site-footer></site-footer>
    </div>
</template>

<script>

import router from "@/router";
import SiteFooter from "@/components/SiteFooter.vue";

export default {
    name: 'Landing-Page-Company',
    components: {SiteFooter},
    props: {},
    data() {
        return {}
    },
    methods: {
        toStudent() {
            router.push("/LandingPageStudent")
        },
        register() {
            router.push('/RegisterWizard');
        }
    }
}
</script>


<style scoped src="@/scss/jobineo/output.css">

a:hover {
    cursor: pointer !important;
}

:root {
    --dl-color-gray-500: #595959;
    --dl-color-gray-700: #999999;
    --dl-color-gray-900: #D9D9D9;
    --dl-size-size-large: 144px;
    --dl-size-size-small: 48px;
    --dl-color-danger-300: #A22020;
    --dl-color-danger-500: #BF2626;
    --dl-color-danger-700: #E14747;
    --dl-color-gray-black: #000000;
    --dl-color-gray-white: #FFFFFF;
    --dl-size-size-medium: 96px;
    --dl-size-size-xlarge: 192px;
    --dl-size-size-xsmall: 16px;
    --dl-space-space-unit: 16px;
    --dl-color-default-wei: rgba(255, 255, 255, 1);
    --dl-color-orange-gelb: rgba(255, 191, 13, 1);
    --dl-color-primary-100: #003EB3;
    --dl-color-primary-300: #0074F0;
    --dl-color-primary-500: #14A9FF;
    --dl-color-primary-700: #85DCFF;
    --dl-color-success-300: #199033;
    --dl-color-success-500: #32A94C;
    --dl-color-success-700: #4CC366;
    --dl-size-size-xxlarge: 288px;
    --dl-color-default-mint: rgba(154, 204, 203, 1);
    --dl-size-size-maxwidth: 1400px;
    --dl-radius-radius-round: 50%;
    --dl-color-default-schwrz: rgba(0, 0, 0, 1);
    --dl-space-space-halfunit: 8px;
    --dl-space-space-sixunits: 96px;
    --dl-space-space-twounits: 32px;
    --dl-color-default-mintgrn: rgba(154, 204, 203, 1);
    --dl-color-default-schwarz: rgba(0, 0, 0, 1);
    --dl-radius-radius-radius2: 2px;
    --dl-radius-radius-radius4: 4px;
    --dl-radius-radius-radius8: 8px;
    --dl-space-space-fiveunits: 80px;
    --dl-space-space-fourunits: 64px;
    --dl-space-space-threeunits: 48px;
    --dl-color-default-orangegelb: rgba(255, 185, 42, 1);
    --dl-space-space-oneandhalfunits: 24px;
}
</style>
