<template>
    <div class="dropdown nav-item notification-dropdown btn-group">
        <a href="javascript:;" id="ddlmsg" data-bs-toggle="dropdown" aria-expanded="false"
           class="btn dropdown-toggle btn-icon-only nav-link">
            <font-awesome-icon v-if="hasMessages" icon="fas fa-envelope"
                               class="notifications-active"></font-awesome-icon>
            <font-awesome-icon v-if="!hasMessages" icon="fas fa-envelope-open"></font-awesome-icon>
            <span v-if="hasMessages" class="badge badge-danger counter">{{ messagesCount }}</span>
        </a>
        <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="ddlmsg">
            <template v-if="!hasMessages">
                <li role="presentation">
                    <a href="javascript:;" class="dropdown-item">
                        <div class="media server-log">
                            <div class="media-aside align-self-start">
                                <font-awesome-icon icon="fa fa-check" class="success"/>
                            </div>
                            <div class="media-body">
                                <div class="data-info">
                                    <h6 class="">{{
                                            $t('NO-NOTIFICATION')
                                        }}</h6>
                                </div>
                            </div>
                        </div>
                    </a>
                </li>
            </template>
            <template v-for="(message, idx) in messages" :key="idx">
                <li role="presentation" @click="notified(message)">
                    <a href="javascript:;" class="dropdown-item">
                        <div class="media server-log">
                            <div class="media-aside align-self-start">
                                <font-awesome-icon icon="fas fa-envelope"/>
                            </div>
                            <div class="media-body">
                                <div class="data-info">
                                    <h5 class="usr-name">{{ GetUserName(message) }}</h5>
                                    <p class="msg-title">{{ trans(message.Title) }}</p>
                                    <p class="msg-title">{{ trans(message.Message) }}</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
import {BPopover} from "bootstrap-vue-next";
import router from "@/router";
import store from "@/store";
import {REGISTER_TYPES} from "@/data/RegisterTypes";
import {SENDER_TYPES} from "@/data/senderTypes";

export default {
    name: 'message-menu',
    data() {
        return {
            polling: null
        }
    },
    directives: {
        'b-popover': BPopover
    },
    computed: {

        IsStudent() {
            return store.state.RegisterType === REGISTER_TYPES.STUDENT
        },
        hasMessages() {
            return (this.messages != null && (Array.isArray(this.messages) && this.messages.length > 0));
        },
        messages() {
            return this.$store.state.UserMessages != null && Array.isArray(this.$store.state.UserMessages) ? this.$store.state.UserMessages : [];
        },
        messagesCount() {
            return this.$store.state.UserMessages != null && Array.isArray(this.$store.state.UserMessages) ? this.$store.state.UserMessages.length : '';
        },
    },
    methods: {
        GetUserName(message) {

            if (message.SenderType == SENDER_TYPES.JOBINEO)
                return "JoBiNeo"

            if (this.IsStudent)
                return message.CompanyUserName;
            else
                return message.StudentName;
        },
        getTrans(message) {
            return this.trans(message)
        },
        pollData() {
            this.polling = setInterval(() => {
                this.$store.dispatch('getMessages')
            }, 30000);

            this.$store.dispatch('getMessages');
        },
        notified(message) {
            if (!message) return;
            this.$store.dispatch('setMessageNotified', message).then((result) => {
                router.push("/UserAccount/Messages/")
                this.$store.dispatch('getMessages').then(result => {
                })
            });
        }
    },
    beforeUnmount() {
        clearInterval(this.polling);
    },
    created() {
        this.pollData();
    }

}
</script>

<style scoped>
.success {
    color: #9acccb;
}

.button__badge {
    background-color: #fa3e3e;
    border-radius: 2px;
    color: white;
    padding: 1px 3px;
    font-size: 10px;
    position: absolute; /* Position the badge within the relatively positioned button */
    top: 3px;
    right: 0;
}

.notifications-active {
    color: rgba(255, 255, 255, 1) !important;
}


</style>
