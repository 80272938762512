<template>
    <div class="section-heading">
        <router-link to="/" class="no-decoration gray-color">
            <font-awesome-icon icon="fas fa-home"/>
            {{ $t('DASHBOARD') }}
        </router-link>
        <router-link to="/SearchView" class="no-decoration breadcrumb-decorate-gray">
            <font-awesome-icon icon="fas fa-angle-right"/> &nbsp;
            <font-awesome-icon icon="fas fa-magnifying-glass"/>
            {{ $t('SEARCH') }}
        </router-link>
        <span class="no-decoration breadcrumb-decorate-yellow">
                <font-awesome-icon icon="fas fa-angle-right"/> &nbsp;
                <font-awesome-icon icon="fas fa-building"/>
                 {{ trans('COMPANY') }} {{ GetCompanyName }}
            </span>
    </div>
    <div class="layout-px-spacing">
        <div class="row layout-spacing">
            <!-- Content -->
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 layout-top-spacing">
                <div class="my-search layout-spacing">
                    <div class="panel">
                        <div class="panel-body">
                            <div class="text-center user-info">
                                <img
                                    :src="selectedImage != null ? selectedImage :  require('@/assets/images/empty-profile.jpg')"
                                    alt="avatar" class="col-lg-12 col-md-12 col-sm-12 col-12 profile-picture-office"/>
                                <h5 style="font-weight:700;">{{ CompanyObj.CompanyName }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 layout-top-spacing">
                <div class="user-profile layout-spacing">
                    <div class="panel">
                        <div class="panel-body">
                            <div class="user-info-list">
                                <div class="">
                                    <ul class="contacts-block list-unstyled">
                                        <li class="contacts-block__item">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                style="height:18px;"
                                                class="feather feather-map-pin"
                                            >
                                                <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                                <circle cx="12" cy="10" r="3"></circle>
                                            </svg
                                            >
                                            {{ CompanyObj.Street }} {{ CompanyObj.StreetNr }}
                                        </li>
                                        <li class="contacts-block__item">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                style="height:18px;"
                                                class="feather feather-map-pin"
                                            >
                                                <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                                <circle cx="12" cy="10" r="3"></circle>
                                            </svg
                                            >
                                            {{ CompanyObj.PostCode }} {{ CompanyObj.City }}
                                        </li>
                                        <li class="contacts-block__item" v-if="CompanyObj.MemberCount">
                                            <font-awesome-icon style="height:18px;"
                                                               icon="fs fa-users"></font-awesome-icon>
                                            {{ trans('MEMBER-COUNT') }} {{ CompanyObj.MemberCount }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-7 col-sm-12 layout-top-spacing">
                <div class="col-lg-12 col-sm-12 col-12" v-if="PaginatedList!= null && PaginatedList.length >0">
                    <div class="row layout-top-spacing">
                        <h3 class="">{{ trans('OFFICES') }}</h3>
                        <div id="basic" class="col-lg-12 col-sm-12 col-12 layout-spacing">
                            <div class="custom-table">
                                <template v-for="(item, index) in PaginatedList" :key="index">
                                    <div class="statbox panel box box-shadow">
                                        <div class="tree-header">
                                            <div class="row">
                                                <div class="col-lg-10">
                                                    <div class="row">
                                                        <div class="form-group col-lg-12 ">
                                                            <label class="font-size-14 input-label"
                                                                   style="cursor: pointer;margin-left:15px;"
                                                                   @click.prevent="openProfileOffice(item)">
                                                                <font-awesome-icon
                                                                    icon="fas fa-building"
                                                                    style="margin-right: 20px;"></font-awesome-icon>
                                                                {{ item.OfficeName }} - {{ item.PostCode }} {{
                                                                    item.City
                                                                }}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <div class="paginating-container pagination-solid flex-column align-items-center mt-4">
                                    <ul role="menubar" aria-disabled="false" aria-label="Pagination"
                                        class="pagination rounded b-pagination">
                                        <template v-for="(item, index) in CountPages" :key="index">
                                            <li role="presentation" class="page-item"
                                                :class="(CurrentPageIndex===(item.PageNo -1)?' active':'')">
                                                <button role="menuitemradio" @click="nextPage(item.PageNo)"
                                                        type="button"
                                                        :aria-checked="CurrentPageIndex===(item.PageNo -1)"
                                                        :aria-posinset="item.PageNo" :aria-setsize="CountPages.length"
                                                        tabindex="-1" class="page-link">
                                                    {{ item.PageNo }}
                                                </button>
                                            </li>
                                        </template>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import '@/scss/jobineo/sass/scrollspyNav.scss';
import '@/scss/jobineo/sass/users/user-profile.scss';
import '@/scss/jobineo/sass/components/tabs-accordian/custom-tabs.scss';
import {cutDateString} from "@/utils/convertDateTime";
import {OfficeController} from "@/controllers/office.controller";
import router from "@/router";
import {CompanyController} from "@/controllers/company.controller";

export default {
    name: "CompanyProfilePublicView.vue",
    components: {},
    data() {
        return {
            officeController: new OfficeController(this),
            controller: new CompanyController(this),
            CurrentPageIndex: 0,
            selectedImage: null,
            ProfileObj: null,
            FkCompany: null,
        }
    },
    computed: {
        GetCompanyName() {
            return this.ProfileObj != null ? this.ProfileObj.CompanyName : ''
        },
        PaginatedList() {
            if (this.OfficeList.length <= 0)
                return [];
            return this.OfficeList.slice(this.indexStart, this.indexEnd);
        },
        CountPages() {
            if (this.OfficeList == null || this.OfficeList.length <= 0)
                return [];

            let pages = [];
            for (let t = 0, l = Math.ceil(this.OfficeList.length / 5); t < l; t++) {
                pages.push({PageNo: t + 1})
            }
            return pages;
        },
        indexStart() {
            return (this.CurrentPageIndex) * 5;
        },
        indexEnd() {
            return (this.indexStart) + 5;
        },
        CompanyObj() {
            return this.ProfileObj != null ? this.ProfileObj : {}
        },
        OfficeList() {
            return this.CompanyObj != null && this.CompanyObj.OfficeList != null && Array.isArray(this.CompanyObj.OfficeList) && this.CompanyObj.OfficeList.length > 0 ? this.CompanyObj.OfficeList : []
        },
    },
    methods: {
        GetDate(value) {
            if (value === null)
                return '';

            return cutDateString(value)
        },
        nextPage(no) {
            this.CurrentPageIndex = no - 1;
        },
        openProfileOffice(row) {
            router.push("/OfficeProfilePublicView/" + row.FkCompany + "/" + row.PkOffice);
        },
        init() {
            this.controller.readPublicView(this.FkCompany).then(response => {
                this.ProfileObj = response.CompanyProfile;

                if (this.ProfileObj != null) {

                    this.controller.GetProfilePictureCompany(this.FkCompany).then(result => {

                        if (result.data != null && result.data.size > 0)
                            this.selectedImage = URL.createObjectURL(new Blob([result.data]));


                    })
                }
            })
        }
    },
    activated() {
        this.FkCompany = this.$route.params.FkCompany;
        if (this.FkCompany == null) {
            return;
        }
        this.init();
    }
}
</script>

<style scoped lang="scss">

.td-bio {
    color: #000000;
}

.td-width {
    min-width: 200px;
}

.dark {
    .td-bio {
        color: #ffffff;
    }

}

.purple {
    .td-bio {
        color: #ffffff;
    }

}

.borderline {
    border: 1px solid #9acccb;
    padding: 5px;
    border-radius: 26px;
    color: #9acccb !important;
}

.header-cv {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 800;
    margin-top: 30px;
}


.videoPlayer {
    max-height: 200px;
    max-width: 320px;
}

.headerIcons {
    display: flex;
}

.iconMargin {
    margin-left: 5px;
}

.popOverText {
    color: white;
    font-size: 14px;
    font-weight: 500;
}

.dark {
    --popper-theme-background-color: #333333;
    --popper-theme-background-color-hover: #333333;
    --popper-theme-text-color: white;
    --popper-theme-border-width: 0px;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 15px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

</style>
