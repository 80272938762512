import Controller from "../controller.js";
import {restUri} from "@/data/restPaths";
import {mutate} from "@/utils/mutate";
import {CREATE, DELETE, UPDATE} from "@/data/cud";
import axios from "axios";
import {restBody} from "@/utils/restBody";
import store from "@/store";


export class InvoiceController extends Controller {

    constructor(root) {
        super({
            root,
            path: {
                CUD: restUri.GET_INVOICES,
                GET: restUri.GET_INVOICES
            }
        })
    }

    read() {

        return super.read({
            log: false,
            body: {FkCompany: store.state.FkCompany}
        }).then(data => {
            if (!data.IsError) {
                return data;
            } else return data
        });
    }


}

