import moment from "moment";

export function convertDateTimeToString(dateString) {
    moment.locale('de');
    let momentDate = moment(dateString + " Z", "DD.MM.YYYY HH:mm:ss Z");

    return new Date(momentDate).toISOString();
}

export function formatDate(dateValue) {

    if (dateValue == null || dateValue == 'undefined')
        return null;

    return moment(dateValue, "DD.MM.YYYY").toLocaleString();


}

export function formatDateTime(dateValue) {

    if (dateValue == null || dateValue == 'undefined')
        return null;

    return moment(dateValue, "DD.MM.YYYY HH:mm:ss");


}

export function convertTimeForDisplay(dateString) {
    moment.locale('de');
    let momentDate = moment(dateString + " Z", "DD.MM.YYYY HH:mm:ss Z");

    let value = new Date(momentDate).toISOString()
    value = value.toString().replace(/.*T/, '');
    let values = value.split(":");
    return values[0] + ":" + values[1];
}

export function correctDateTime(dateString) {

    if (dateString != null && dateString != 'undefined') {

        if (dateString.indexOf('Z') !== -1)
            return dateString
        else
            return dateString + '.000Z';
    } else
        return dateString
}

export function convertDateTimeForDisplay(dateString) {
    moment.locale('de');
    let momentDate = moment(dateString + " Z", "DD.MM.YYYY HH:mm:ss Z");

    let value = new Date(momentDate).toISOString()

    let valueDate = value.toString().replace(/T.*/, '');
    let valuesDate = valueDate.split("-");
    let valueTime = value.toString().replace(/.*T/, '');
    let valuesTime = valueTime.split(":");
    return valuesDate[2] + "." + valuesDate[1] + "." + valuesDate[0] + " " + valuesTime[0] + ":" + valuesTime[1];
}


export function cutDateString(dateString) {
    if (dateString == null || dateString == "undefined")
        return '';


    let valueDate = dateString.toString().replace(/T.*/, '');
    let valuesDate = valueDate.split("-");
    return valuesDate[2] + "." + valuesDate[1] + "." + valuesDate[0];
}

export function getDateStringMonthYear(dateString) {
    if (dateString == null || dateString == "undefined")
        return '';


    let valueDate = dateString.toString().replace(/T.*/, '');
    let valuesDate = valueDate.split("-");
    return valuesDate[1] + "." + valuesDate[0];
}


export function dateStringForFileName(dateString) {
    if (dateString == null || dateString == "undefined")
        return '';


    let valueDate = dateString.toString().replace(/T.*/, '');
    let valuesDate = valueDate.split("-");
    return valuesDate[0] + valuesDate[1] + valuesDate[2];
}

export function cutDateTimeString(dateString) {
    if (dateString == null || dateString == "undefined")
        return '';


    let valueDate = dateString.toString().replace(/T.*/, '');
    let valuesDate = valueDate.split("-");
    let valueTime = dateString.toString().replace(/.*T/, '');
    let valuesTime = valueTime.split(":");
    return valuesDate[2] + "." + valuesDate[1] + "." + valuesDate[0] + " " + valuesTime[0] + ":" + valuesTime[1];

}

export function convertDateForDisplay(dateString) {

    if (dateString == null)
        return null

    moment.locale('de');
    let momentDate = moment(dateString + " Z", "DD.MM.YYYY HH:mm:ss Z");

    let value = new Date(momentDate).toISOString()

    let valueDate = value.toString().replace(/T.*/, '');
    let valuesDate = valueDate.split("-");
    return valuesDate[2] + "." + valuesDate[1] + "." + valuesDate[0];
}

export function convertDateTimeToWCFDateTime(dateObj) {
    dateObj = dateObj.toString().replace(/\+.*/, '+01:00');
    return '\/Date(' + Date.parse(dateObj) + ')\/'
}

export function getNowUTC() {
    let check = moment().toISOString();
    
    return check;
}
