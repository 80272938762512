/**
 * This is the default state.
 * it includes the state structure for initial usage
 *
 */
export default {
    entryPath: '/',
    didLogOff: false,
    System: {},
    loggedIn: false,
    RegisterType: null,
    PkUserForAllowedRoutes: null,
    HasToCheckAGB: false,
    NeedToValidateMFA: false,
    IsEdgeOrIE: false,
    layout: 'app',
    is_show_sidebar: true,
    is_show_search: false,
    is_dark_mode: 1,
    dark_mode: 'dark',
    locale: null,
    ProfilePictureContent:null,
    menu_style: 'horizontal',
    layout_style: 'full',
    ComponentRendered: [],
    countryList: [
        {code: 'en', name: 'ENGLISH'},
        {code: 'de', name: 'GERMAN'},
    ],
    requestMerge: {
        "RequestUserID": null,
        "JWTToken": null,
        "LanguageKey": 'de-DE',
        "UserName": null
    },
    User: null,
    PkStudentProfile: null,
    FkCompany:null,
    FkOffice:null,
    SysSettings: null,
    UserNotificationConfig: [],
    UserNotificationSettings: {},
    UserNotifications: [],
    UserMessages: [],
    UserNotificationsAll: [],
    CompanyData: {
        CompanyLicenseList: [],
        MaxUsers:0,
        CurrentUsers:0,
        MaxOffices:0,
        CurrentOffices:0,
        MaxJobAds:0,
        CurrentJobAds:0,
        MaxMatches:0,
        CurrentMatches:0,
        InfiniteMatches:false
    },
    MyStudentProfile:[],
    Dashboard: {
        Config: {
            BoxRecievedRequest: null,
            BoxAppointment: null,
            BoxMatch: null,
            BoxMyRequest: null,
            DashboardConfigsAll: [],
        },
        Data: {
            DashboardSmallBoxes: {
                ProfileLikes: 0,
                ProfileCount: 0,
                MatchCount: 0,
                OpenRequestsCount: 0,
                UserStudentCount: 0
            },
            OpenAppointments: [],
            RecievedRequests: [],
            MyRequests: [],
            Matches: [],
        }
    },
    MasterData: {
        MessagesLookup: {}
    },
    Appointments: {
        AppointmentList: [],
        CalendarList: []
    },
    SystemData: {},
    Lookups: {
        SalutationsLookup: {},
        CountriesLookup: {},
        JobAdTypesLookup: {},
        SalaryTypesLookup: {},
        LocationTypesLookup: {},
        AppointmentTypesLookup: {},
        DriverLicensesLookup: {},
        GraduationsLookup: {},
        JobFieldsLookup: {},
        JobsLookup: {},
        CompanySizesLookup: {},
        PaymentIntervalsLookup: {},
        StudentQuestionsLookup: {},
        CompanyQuestionsLookup: {},
        StudyFieldsLookup: {},
        LookupDataList: [],
        LookupGroupList: [],
        LookupGroupLookUp: {},
        LookupDataLookup: {},
        ListGroup2List: {},
        ListLookUp: {},
    }
}
