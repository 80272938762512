<template>
    <div class="layout-px-spacing apps-calendar">
        <div class="section-heading">
            <router-link to="/" class="no-decoration gray-color">
                <font-awesome-icon icon="fas fa-home"/>
                {{ $t('DASHBOARD') }}
            </router-link>
            <span class="no-decoration breadcrumb-decorate-yellow">
                <font-awesome-icon icon="fas fa-angle-right"/> &nbsp;
                <font-awesome-icon icon="fas fa-calendar"/>
                {{ trans('CALENDAR') }}
            </span>
        </div>
        <div class="row layout-top-spacing" id="cancel-row">
            <div class="col-xl-12 col-lg-12 col-md-12">
                <div class="statbox panel box box-shadow">
                    <div class="panel-body">
                        <div class="calendar-upper-section">
                            <div class="row">
                                <div class="col-md-8 col-12">
                                    <div class="labels text-md-start text-center">
                                        <p class="label label-white">{{ trans("INTERVIEW-ONLINE") }}</p>
                                        <p class="label label-red">{{ trans("INTERVIEW-ON-SITE") }}</p>
                                        <p class="label label-mint">{{ trans("APTITUDE-TEST-ONLINE") }}</p>
                                        <p class="label label-yellow">{{ trans("APTITUDE-TEST-ON-SITE") }}</p>
                                    </div>
                                </div>
                                <div class="col-md-4 col-12" v-if="!isStudent">
                                    <form class="form-horizontal mt-md-0 mt-3 text-md-end text-center">
                                        <button type="button" class="btn btn-yellow"
                                                @click="addEntry({start: new Date(), end: new Date()})">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-calendar me-2"
                                            >
                                                <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                                <line x1="16" y1="2" x2="16" y2="6"></line>
                                                <line x1="8" y1="2" x2="8" y2="6"></line>
                                                <line x1="3" y1="10" x2="21" y2="10"></line>
                                            </svg>
                                            {{ trans('ADD-APPOINTMENT') }}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <FullCalendar ref="fullCalendar" :options="options">
                            <template v-slot:eventContent="arg">
                                <div class="fc-event-main-frame">
                                    <div class="fc-event-time">{{ arg.timeText }}</div>
                                    <div class="fc-event-title-container">
                                        <div class="fc-event-title fc-sticky">{{ arg.event.title }}</div>
                                    </div>

                                    <div class="calendar-tlp">
                                        <div class="p-2 bg-dark text-white text-start text-wrap">
                                            {{ arg.timeText + ' : ' + arg.event.title }}
                                        </div>
                                        <div class="p-2 text-start text-wrap">
                                            {{ arg.event.extendedProps ? arg.event.extendedProps.description : '' }}
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </FullCalendar>
                    </div>
                </div>
            </div>

            <!-- The Modal -->


            <CalendarEntryCreateModal ref="createModal"
                                      @created="created"
                                      @deleted="deleted"
                                      :appointment-controller="controller">
            </CalendarEntryCreateModal>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import '@/scss/jobineo/sass/apps/calendar.scss';

import FullCalendar from '@fullcalendar/vue3';

//flatpickr
import 'flatpickr/dist/flatpickr.css';
import '@/scss/jobineo/sass/forms/custom-flatpickr.css';
import CalendarEntryCreateModal from "@/views/appointments/CalendarEntryCreateModal.vue";
import {Draggable} from "@fullcalendar/interaction";

const defaultParams = ref({id: null, title: '', start: '', end: '', description: '', className: 'bg-primary'});
const params = ref(JSON.parse(JSON.stringify(defaultParams.value)));


let calendarEntryModal = ref(null);
const fullCalendar = ref(null);

onMounted(() => {
    initPopup();
    setup_draggable();
});

const setup_draggable = () => {
    new Draggable(document.querySelector('.fc-view'), {
        itemSelector: '.fc-event',
        eventData: function (eventEl) {
            let event = {
                title: eventEl.innerText,
                duration: '01:00',
            };
            return event;
        },
    });
};

const initPopup = () => {
    calendarEntryModal = new window.bootstrap.Modal(document.getElementById('calendarEntryModal'));
};

</script>

<script>
import store from "@/store";
import {REGISTER_TYPES} from "@/data/RegisterTypes";
import {AppointmentController} from "@/controllers/appointment.controller";
import {convertDateTimeToString} from "@/utils/convertDateTime";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import deLocale from "@fullcalendar/core/locales/de";
import enLocale from "@fullcalendar/core/locales/en-gb";
import {uuid} from "vue-uuid";
import lodash from "lodash";
import {CREATE} from "@/data/cud";

export default {
    name: "calendar-view",
    data() {
        return {
            controller: new AppointmentController(this),
            isUpdating: false,
            selectedPkCalendar: null,
            calendarStartDate: null,
            calendarEndDate: null,
            SelectedItem: null,
            dragPk: null,
            dragEvent: null,
            dragStartDate: null,
            dragEndDate: null,
            resizePk: null,
            resizeStartDate: null,
            resizeEndDate: null,
            resizeEvent: null,
            loading: false,
            options: {
                initialView: 'dayGridMonth',
                plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
                headerToolbar: {
                    start: 'prev,next today',
                    center: 'title',
                    end: 'dayGridMonth,timeGridWeek,timeGridDay',
                },
                editable: true,
                droppable: true,
                dayMaxEvents: true,
                selectable: true,
                eventClick: this.editEntry,
                eventMouseEnter: this.event_mouse_hover,
                eventMouseLeave: this.event_mouse_leave,
                eventResize: this.resizeEntry,
                eventDrop: this.dragEntry,
                locales: [deLocale, enLocale],
                select: this.addEntry,
                eventTimeFormat: { // like '14:30:00'
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                },
                timeZone: 'locale',
                weekNumbers: true,
                events: [],
                views: {
                    month: {
                        // name of view
                        titleFormat: {
                            month: 'long',
                            year: 'numeric'
                        }
                        // other view-specific options here
                    },
                    week: {
                        titleFormat: {
                            month: 'long',
                            day: '2-digit',
                            year: 'numeric'
                        }
                    },
                    day: {
                        titleFormat: {
                            day: '2-digit',
                            month: 'long',
                            year: 'numeric'
                        }
                    }
                }
            }
        }
    },
    computed: {

        locale() {
            if (this.$i18n.locale == null || typeof this.$i18n.locale === 'undefined')
                return 'de';
            return this.$i18n.locale.substring(0, 2);
        },
        isStudent() {
            return store.state.RegisterType === REGISTER_TYPES.STUDENT;
        },
    },
    methods: {
        event_mouse_hover(data) {
            let element = data.el.querySelector('.calendar-tlp');
            element.classList.add('d-block');
        },
        event_mouse_leave(data) {
            let element = data.el.querySelector('.calendar-tlp');
            element.classList.remove('d-block');
        },
        created() {
            this.reload();
        },
        deleted() {
            this.reload();
        },
        addEntry({start, end}) {
            if (store.state.RegisterType === REGISTER_TYPES.STUDENT)
                return;

            let s = (new Date(start.toString()));
            s.setHours(s.getHours() - 2);
            let e = (new Date(end.toString()));
            e.setHours(e.getHours() - 2);


            let startDate = s.toISOString(),
                endDate = e.toISOString(),
                appointment = {
                    PkAppointment: uuid.v4(),
                    StartDate: startDate,
                    EndDate: endDate,
                    AppType: null,
                    Subject: null,
                    TrackingState: CREATE,
                    Body: null,
                    FkUserCreate: this.$store.state.User != null ? this.$store.state.User.PkUser : null
                };

            this.$refs.createModal.reset();
            this.$refs.createModal.open(appointment);

            let modal = new window.bootstrap.Modal(document.getElementById('calendarEntryModal'));
            modal.show();

        },
        editEntry(info) {

            if (store.state.RegisterType === REGISTER_TYPES.STUDENT)
                return;

            let pk = info.event.extendedProps.pk
            if (this.$store.state.Appointments.CalendarList != null && this.$store.state.Appointments.CalendarList !== "undefined") {
                let app = lodash.filter(this.$store.state.Appointments.CalendarList, x => x.PkAppointment === pk);
                if (app.length > 0) {

                    this.$refs.createModal.reset();
                    this.$refs.createModal.open(app[0]);

                    let modal = new window.bootstrap.Modal(document.getElementById('calendarEntryModal'));
                    modal.show();
                }
            }


        },
        dragEntry(info) {

            if (store.state.RegisterType === REGISTER_TYPES.STUDENT)
                return;

            let pk = this.dragPk,
                appointment = this.$store.state.Appointments.AppointmensLookup[pk];
            appointment.StartDate = info.event.start;
            appointment.EndDate = info.event.end;
            this.controller.checkSave(appointment, true).then(result => {
                if (result.IsError) {

                    appointment.StartDate = this.dragStartDate;
                    appointment.EndDate = this.dragEndDate;

                    info.revert();

                    this.flashMessage.error(
                        {
                            title: this.trans('ERROR'),
                            message: this.trans(result.ErrorMessage),
                            time: 4000
                        });

                } else {

                    this.controller.update(appointment).then(result => {

                        if (result.IsError) {

                            appointment.StartDate = this.dragStartDate;
                            appointment.EndDate = this.dragEndDate;

                            info.revert();

                            this.flashMessage.error(
                                {
                                    title: this.trans('ERROR'),
                                    message: this.trans(result.ErrorMessage),
                                    time: 4000
                                });
                        } else {
                            this.flashMessage.success(
                                {
                                    title: this.trans('SUCCESS'),
                                    message: this.trans('DATA-SAVED'),
                                    time: 4000
                                });
                        }
                    })

                }
            });


            this.dragPk = null;
            this.dragStartDate = null;
            this.dragEndDate = null;
            this.dragEvent = null;

        },
        resizeEntry(info) {

            if (store.state.RegisterType === REGISTER_TYPES.STUDENT)
                return;

            let pk = this.resizePk,
                appointment = this.$store.state.Appointments.AppointmensLookup[pk];
            appointment.StartDate = info.event.start;
            appointment.EndDate = info.event.end;

            this.controller.checkSave(appointment, true).then(result => {
                if (result.IsError) {
                    appointment.StartDate = this.resizeStartDate;
                    appointment.EndDate = this.resizeEndDate;

                    info.revert();

                    this.flashMessage.error(
                        {
                            title: this.trans('ERROR'),
                            message: this.trans(result.ErrorMessage),
                            time: 4000
                        });

                } else {
                    this.controller.update(appointment).then(result => {

                        if (result.IsError) {

                            appointment.StartDate = this.dragStartDate;
                            appointment.EndDate = this.dragEndDate;

                            info.revert();

                            this.flashMessage.error(
                                {
                                    title: this.trans('ERROR'),
                                    message: this.trans(result.ErrorMessage),
                                    time: 4000
                                });
                        } else {
                            this.flashMessage.success(
                                {
                                    title: this.trans('SUCCESS'),
                                    message: this.trans('DATA-SAVED'),
                                    time: 4000
                                });
                        }
                    })

                }
            });
            this.resizePk = null;
            this.resizeStartDate = null;
            this.resizeEndDate = null;
            this.resizeEvent = null;
        },
        dragEntryStart(info) {


            let startDate = (new Date(info.event.start.toString())).toISOString(),
                endDate = (new Date(info.event.end.toString())).toISOString(),
                pk = info.event.extendedProps.pk;

            this.dragEvent = info.event;
            this.dragPk = pk;
            this.dragStartDate = startDate;
            this.dragEndDate = endDate;

        },
        resizeEntryStart(info) {
            let startDate = (new Date(info.event.start.toString())).toISOString(),
                endDate = (new Date(info.event.end.toString())).toISOString(),
                pk = info.event.extendedProps.pk;

            this.resizeEvent = info.event;
            this.resizePk = pk;
            this.resizeStartDate = startDate;
            this.resizeEndDate = endDate;
        },
        reload() {

            this.controller.read().then(result => {
                this.loading = false;
                this.$nextTick(() => {
                    this.twiggle();
                })
            });
        },
        twiggle() {
            const selectedPkCalendar = this.selectedPkCalendar;
            this.selectedPkCalendar = null;
            this.selectedPkCalendar = selectedPkCalendar;
        },
    },
    activated() {
        this.loading = true;
        this.controller.read().then(result => {
            this.loading = false;
            this.$nextTick(() => {
                this.twiggle();
            })
        });
    }
}
</script>

<style lang="scss">


</style>
