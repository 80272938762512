import Controller from "../controller.js";
import {restUri} from "@/data/restPaths";
import {mutate} from "@/utils/mutate";
import {CREATE, DELETE, UPDATE} from "@/data/cud";
import axios from "axios";
import {restBody} from "@/utils/restBody";
import store from "@/store";


export class CompanyController extends Controller {

    constructor(root) {
        super({
            root,
            path: {
                CUD: restUri.COMPANY_CUD,
                GET: restUri.GET_COMPANY
            }
        })
    }

    read() {

        return super.read({
            log: false,
            body: {FkCompany: store.state.FkCompany}
        }).then(data => {
            if (!data.IsError) {
                return data;
            } else return data
        });
    }

    readPublicView(fkCompany) {

        return axios.post(restUri.GET_COMPANY_PROFILE, restBody({
                FkCompany: fkCompany
            })
        ).then(result => {
            if (!result.data.IsError) {
                return result.data;
            } else return result.data
        });
    }

    update(Company = {}) {

        let body = {
            Company: mutate(
                {TrackingState: UPDATE},
                Company,
            )
        };

        return super.update({body})
            .then(result => {

                if (!result.IsError) {
                }

                return result;
            }).catch(result => {
                return result.data;
            })
    }


    GetProfilePictureCompany(fkCompany) {
        return axios.post(restUri.DOWNLOAD_PROFILE_PICTURE_COMPANY, restBody({
                PkCompany: fkCompany
            }),
            {
                responseType: 'blob'
            }
        ).then(result => {
            return result;
        }).catch(result => {
            return result;
        });

    }
}

