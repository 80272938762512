import {flattenRoutePathTree} from "@/utils/flattenRoutePathTree";
import {searchInObjectArray} from "@/utils/searchInObjectArray";
import {traverseRouteTree} from "@/utils/traverseRouteTree";


import LandingPageStudent from "@/views/landingpages/LandingPageStudent.vue";
import LandingPageCompany from "@/views/landingpages/LandingPageCompany.vue";
import RegisterWizard from "@/views/register/RegisterWizard.vue";
import Pricing from "@/views/pricing/Pricing.vue";
import Imprint from "@/views/login/Imprint.vue";
import AGB from "@/views/login/AGB.vue";
import DataProtection from "@/views/login/DataProtection.vue";
import {LOGIN_STATE} from "@/data/LoginStates";
import {CHECK_REGISTER_TYPES} from "@/data/RegisterTypes";
import ComingSoon from "@/views/CommingSoon/ComingSoon.vue";

let configRootNode = {
    name: 'j-logoff',
    path: '/',
    title: 'MENU',
    CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
    ignore: true,
    setDivider: false,
    checkLoginState: LOGIN_STATE.IGNORE,
    CheckIfProd: false,
    children: [
        {
            path: 'LandingPageStudent',
            name: 'landing-page-student',
            title: 'FOR-STUDENT',
            CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
            icon: 'fas fa-user',
            setDivider: false,
            component: LandingPageStudent,
            checkLoginState: LOGIN_STATE.LOGGED_OFF,
            CheckIfProd: false
        },
        {
            path: 'LandingPageCompany',
            name: 'landing-page-company',
            title: 'FOR-COMPANY',
            CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
            setDivider: true,
            icon: 'fas fa-building',
            component: LandingPageCompany,
            checkLoginState: LOGIN_STATE.LOGGED_OFF,
            CheckIfProd: false
        },
        {
            path: 'Pricing',
            name: 'pricing',
            title: 'PRICING',
            CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
            icon: 'fas fa-euro-sign',
            component: Pricing,
            setDivider: true,
            checkLoginState: LOGIN_STATE.LOGGED_OFF,
            CheckIfProd: false
        },
        {
            path: 'RoadMap',
            name: 'RoadMap',
            title: 'JOBINEO-ROADMAP',
            CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
            icon: 'fas fa-map-location-dot',
            component: ComingSoon,
            setDivider: true,
            checkLoginState: LOGIN_STATE.IGNORE,
            CheckIfProd: false
        },
        {
            path: 'Imprint',
            name: 'Imprint',
            setDivider: false,
            CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
            title: 'IMPRINT',
            icon: 'fas fa-stamp',
            component: Imprint,
            checkLoginState: LOGIN_STATE.IGNORE,
            CheckIfProd: false
        },
        {
            path: 'AGB',
            name: 'AGB',
            title: 'AGB',
            icon: 'fas fa-file-lines',
            CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
            setDivider: false,
            component: AGB,
            checkLoginState: LOGIN_STATE.IGNORE,
            CheckIfProd: false
        },
        {
            path: 'DataProtection',
            name: 'DataProtection',
            CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
            title: 'DATA-PROTECTION',
            setDivider: false,
            icon: 'fas fa-shield',
            component: DataProtection,
            checkLoginState: LOGIN_STATE.IGNORE,
            CheckIfProd: false
        }
    ]
};


let configMenuTopLevel = {
    name: 'j-logoff',
    path: '/',
    title: 'MENU',
    CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
    ignore: true,
    CheckIfProd: false,
    children: [
        {
            path: 'LandingPageStudent',
            name: 'landing-page-student',
            title: 'FOR-STUDENT',
            icon: 'fas fa-user',
            CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
            checkLoginState: LOGIN_STATE.LOGGED_OFF,
            setDivider: false,
            component: LandingPageStudent,
            CheckIfProd: false
        },
        {
            path: 'LandingPageCompany',
            name: 'landing-page-company',
            title: 'FOR-COMPANY',
            CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
            checkLoginState: LOGIN_STATE.LOGGED_OFF,
            icon: 'fas fa-building',
            setDivider: true,
            component: LandingPageCompany,
            CheckIfProd: false
        },
        {
            path: 'Pricing',
            name: 'pricing',
            title: 'PRICING',
            CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
            checkLoginState: LOGIN_STATE.LOGGED_OFF,
            icon: 'fas fa-euro-sign',
            component: Pricing,
            CheckIfProd: false,
            setDivider: true,
        },
        {
            path: 'RoadMap',
            name: 'RoadMap',
            title: 'JOBINEO-ROADMAP',
            CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
            icon: 'fas fa-map-location-dot',
            component: ComingSoon,
            setDivider: true,
            checkLoginState: LOGIN_STATE.IGNORE,
            CheckIfProd: false
        },
        {
            path: 'Imprint',
            name: 'Imprint',
            title: 'IMPRINT',
            icon: 'fas fa-stamp',
            component: Imprint,
            CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
            checkLoginState: LOGIN_STATE.IGNORE,
            setDivider: false,
            CheckIfProd: false
        },
        {
            path: 'AGB',
            name: 'AGB',
            title: 'AGB',
            icon: 'fas fa-file-lines',
            setDivider: false,
            component: AGB,
            CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
            checkLoginState: LOGIN_STATE.IGNORE,
            CheckIfProd: false
        },
        {
            path: 'DataProtection',
            name: 'DataProtection',
            title: 'DATA-PROTECTION',
            icon: 'fas fa-shield',
            setDivider: false,
            component: DataProtection,
            CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
            checkLoginState: LOGIN_STATE.IGNORE,
            CheckIfProd: false
        }
    ]
};


traverseRouteTree(configRootNode);

let flatten = flattenRoutePathTree([configRootNode]),
    temp = flatten.slice().reverse();

temp.pop();
temp = temp.reverse().map(item => {
    item.level = item.level != null ? item.level - 1 : null;
    return item;
});

const routes = [configRootNode],
    flattenConfigPathTreeWithoutRoot = temp;

export {configRootNode};
export default routes;

export {flattenConfigPathTreeWithoutRoot};


traverseRouteTree(configMenuTopLevel);

let flatten2 = flattenRoutePathTree([configMenuTopLevel]),
    temp2 = flatten2.slice().reverse();

temp2.pop();
temp2 = temp2.reverse().map(item => {
    item.level = item.level != null ? item.level - 1 : null;
    return item;
});

const routes2 = [configMenuTopLevel],
    flattenConfigMenuTopLevelPathTreeWithoutRoot = temp2;

export {configMenuTopLevel};

export {flattenConfigMenuTopLevelPathTreeWithoutRoot};


export const getByName = (name = null) => {

    let match = searchInObjectArray(routes, (obj) => {
        return obj.name === name;
    });

    return match ? Object.assign({}, match) : null;

}
