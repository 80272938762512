<template>
    <div class="panel-heading">
        <div class="row">
            <div class="col-xl-10 col-md-10 col-sm-10 col-10">
                <h4>{{ trans('JOB-AD') }}</h4>
            </div>
            <div class="col-xl-10 col-md-10 col-sm-10 col-10">
                <base-button @click="openJobAd" native-type="button"
                             class="btn btn-yellow btn-sm float-right">
                    <font-awesome-icon icon="fas fa-eye" style="margin-right:10px;"/>
                    {{ trans('JOB-AD-PROFILE') }}
                </base-button>
            </div>
        </div>
    </div>
    <div class="panel-body">
        <Form ref="valForm" :validationSchema="validationSchema" @submit="validate">
            <div class="row">
                <div class="col-lg-12">
                    <div class="upload mt-4 pe-md-4">
                        <input ref="fl_profile" type="file" class="d-none"
                               accept="image/*" @change="change_file"/>
                        <img
                            :src="selectedImage != null ? selectedImage :  require('@/assets/images/empty-profile.jpg')"
                            alt="profile" class="profile-preview-office profile-picture-office"
                            @click="$refs.fl_profile.click()"/>
                        <p><a href="javascript:;" class="mt-2"
                              @click="$refs.fl_profile.click()">{{
                                trans('UPLOAD-PICTURE-JOB-AD')
                            }}</a></p>
                    </div>
                </div>
            </div>
            <div class="row" v-if="isLicensedVideo">
                <div class="form-group col-lg-12">
                    <div class="mt-4">
                        <input ref="jobAdVideo" type="file" class="d-none"
                               accept="video/*" @change="uploadVideo"/>
                        <video class="videoPlayer" id="video-preview" controls
                               controlsList="nodownload">
                        </video>
                        <p>
                            <base-button
                                native-type="button"
                                class="btn btn-yellow  margin-top-30"
                                @click="$refs.jobAdVideo.click()"
                            >
                                <font-awesome-icon icon="fas fa-plus-circle"/>
                                &nbsp;{{ $t('UPLOAD-VIDEO') }}
                            </base-button>
                            <base-button
                                native-type="button"
                                class="btn btn-yellow  margin-top-30"
                                @click="removeVideo"
                            >
                                <font-awesome-icon icon="fas fa-trash"/>
                                &nbsp;{{ $t('REMOVE-VIDEO') }}
                            </base-button>
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-5">
                    <office-dropdown
                        v-model="model.FkOffice"
                        :items="OfficeList"
                        obj-name="FkOffice"
                        :required="true"
                        :label="trans('OFFICE')"
                        attached-class="form-control"
                        :has-null-value="true"
                        :null-value-text="trans('SELECT-OFFICE')"
                        name="FkOffice"
                        ref="FkOffice"/>
                </div>
                <div class="col-md-5">
                    <div class="form-group">
                        <data-dropdown ref="AdType"
                                       v-model="model.AdType"
                                       :label="trans('AD-TYPE')"
                                       :required="true"
                                       :no-check-icons="true"
                                       :items="AdTypeList"
                                       :null-value-text="trans('SELECT-AD-TYPE')"
                                       :has-null-value="true"
                                       attached-class="form-control"
                                       name="AdType"/>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-5">
                    <div class="form-group">
                        <data-dropdown ref="JobFieldId"
                                       v-model="model.JobFieldId"
                                       :label="trans(GetStudyJobText)"
                                       :no-check-icons="true"
                                       :disabled="model.AdType==null"
                                       :required="true"
                                       :items="JobFieldIdList"
                                       :null-value-text="trans(GetStudyJobSelectText)"
                                       :has-null-value="true"
                                       attached-class="form-control"
                                       name="JobFieldId"/>
                    </div>
                </div>
                <div class="col-md-5" v-if="ShowJobField">
                    <div class="form-group">

                        <data-dropdown ref="JobId"
                                       v-model="model.JobId"
                                       :label="trans('JOB-ID')"
                                       :no-check-icons="true"
                                       :required="true"
                                       :disabled="model.AdType==null"
                                       :items="JobIdList"
                                       :null-value-text="trans('SELECT-JOB-ID')"
                                       :has-null-value="true"
                                       attached-class="form-control"
                                       name="JobId"/>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-5">
                    <base-input ref="AdName"
                                v-model="model.AdName"
                                :label="trans('JOB-AD-NAME')"
                                type="text"
                                :required="true"
                                obj-name="AdName"
                                :max-length="150"
                                attached-class="form-control" placeholder="..."/>
                </div>
                <div class="form-group col-lg-5">
                    <data-dropdown ref="QualificationId"
                                   v-model="model.QualificationId"
                                   :label="trans('JOB-AD-QUALIFICATION')"
                                   :no-check-icons="true"
                                   :required="false"
                                   :items="QualificationList"
                                   :null-value-text="trans('SELECT-JOB-AD-QUALIFICATION')"
                                   :has-null-value="true"
                                   attached-class="form-control"
                                   name="QualificationId"/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-5">
                    <base-datetime
                        :label="trans('AD-START-DATE')"
                        :required="true"
                        ref="StartDate"
                        obj-name="StartDate"
                        name="StartDate"
                        :value="model.StartDate"
                        :enable-time="false"
                        :onchange="StartDateChange"
                        :place-holder="trans('PLEASE-SELECT-DATE')"
                        date-time-type="date"
                        :date-time-format="DateTimeFormat"
                        :date-time-format-value="DateTimeFormatValue"
                    ></base-datetime>
                </div>
                <div class="form-group col-lg-5">
                    <base-datetime
                        :label="trans('AD-END-DATE')"
                        :required="false"
                        ref="EndDate"
                        obj-name="EndDate"
                        name="EndDate"
                        :value="model.EndDate"
                        :enable-time="false"
                        :onchange="EndDateChange"
                        :place-holder="trans('PLEASE-SELECT-DATE')"
                        date-time-type="date"
                        :date-time-format="DateTimeFormat"
                        :date-time-format-value="DateTimeFormatValue"
                    ></base-datetime>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-5">
                    <base-input ref="Duration"
                                v-model="model.Duration"
                                :label="trans('DURATION')"
                                :max="100"
                                :step="0.5"
                                :min="0.5"
                                type="number"
                                :required="true"
                                obj-name="Duration"
                                attached-class="form-control" placeholder="..."/>
                </div>
                <div class="form-group col-lg-5">
                    <base-input ref="FreePositions"
                                v-model="model.FreePositions"
                                :label="trans('FREE-POSITIONS')"
                                :max="100"
                                :step="1.0"
                                :min="1.0"
                                type="number"
                                :required="true"
                                obj-name="FreePositions"
                                attached-class="form-control" placeholder="..."/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-5">
                    <data-dropdown ref="SalaryType"
                                   v-model="model.SalaryType"
                                   :label="trans('SALARY-TYPE')"
                                   :required="false"
                                   :no-check-icons="true"
                                   :items="SalaryTypeList"
                                   :null-value-text="trans('SELECT-SALARY-TYPE')"
                                   :has-null-value="true"
                                   attached-class="form-control"
                                   name="SalaryType"/>
                </div>
                <div class="form-group col-lg-5">
                    <base-input ref="Salary"
                                v-model="model.Salary"
                                :label="trans('SALARY')"
                                :max="500000"
                                :step="1.0"
                                :min="1.0"
                                type="number"
                                :required="false"
                                obj-name="Salary"
                                attached-class="form-control" placeholder="..."/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-5">
                    <base-datetime
                        :label="trans('ENTRY-DATE')"
                        :required="false"
                        ref="EntryDate"
                        obj-name="EntryDate"
                        name="EntryDate"
                        :value="model.EntryDate"
                        :enable-time="false"
                        :onchange="EntryDateChange"
                        :place-holder="trans('PLEASE-SELECT-DATE')"
                        date-time-type="date"
                        :date-time-format="DateTimeFormat"
                        :date-time-format-value="DateTimeFormatValue"
                    ></base-datetime>
                </div>
                <div class="form-group col-lg-5">
                    <data-dropdown ref="LocationType"
                                   v-model="model.LocationType"
                                   :label="trans('LOCATION-TYPE')"
                                   :required="false"
                                   :no-check-icons="true"
                                   :items="LocationTypeList"
                                   :null-value-text="trans('SELECT-LOCATION-TYPE')"
                                   :has-null-value="true"
                                   attached-class="form-control"
                                   name="LocationType"/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-5">
                    <base-datetime
                        :label="trans('DEAD-LINE')"
                        :required="false"
                        ref="DeadLine"
                        obj-name="DeadLine"
                        name="DeadLine"
                        :value="model.DeadLine"
                        :enable-time="false"
                        :onchange="DeadLineChange"
                        :place-holder="trans('PLEASE-SELECT-DATE')"
                        date-time-type="date"
                        :date-time-format="DateTimeFormat"
                        :date-time-format-value="DateTimeFormatValue"
                    ></base-datetime>
                </div>
                <div class="form-group col-lg-5">
                    <div class="terms_condition mb-4">
                        <div class="checkbox-outline-primary custom-control custom-checkbox">

                            <base-checkbox
                                id="FullTime"
                                :required="false"
                                class="custom-control-input"
                                :checked="model.FullTime"
                                @input="setFullTime"
                                :label="trans('FULL-TIME')"/>

                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-12">
                    <base-text-area
                        v-model="model.AdText"
                        :required="true"
                        obj-name="AdText"
                        :label="trans('AD-TEXT')"
                        attached-class="form-control"
                        placeholder="..."
                        name="AdText"
                        ref="AdText"/>
                </div>
            </div>
            <div class="row">

            </div>
            <div class="statbox panel box box-shadow layout-top-spacing">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                            <h4>{{ trans('AD-CONTACT') }}</h4>
                        </div>
                    </div>
                </div>
                <div class="panel-body">
                    <div class="row">
                        <div class="form-group col-lg-3">
                            <salutations-drop-down ref="Salutation"
                                                   v-model="model.Salutation"
                                                   :required="true"
                                                   :has-null-value="true"
                                                   :null-value-text="trans('SELECT-SALUTATION')"
                                                   :label="trans('SALUTATION')"
                                                   attached-class="form-control"
                                                   name="Salutation"/>
                        </div>
                        <div class="form-group col-lg-3">
                            <base-input
                                v-model="model.NameAddition"
                                type="text"
                                obj-name="NameAddition"
                                :label="trans('NAME-ADDITION')"
                                attached-class="form-control"
                                :placeholder="trans('NAME-ADDITION')"
                                name="NameAddition"
                                ref="NameAddition"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-3">
                            <base-input ref="FirstName"
                                        v-model="model.FirstName"
                                        :label="trans('FIRSTNAME')"
                                        type="text"
                                        :max-length="50"
                                        attached-class="form-control"
                                        obj-name="FirstName" :required="true" placeholder="..."/>
                        </div>
                        <div class="form-group col-lg-3">
                            <base-input ref="LastName"
                                        v-model="model.LastName"
                                        type="text"
                                        :max-length="10"
                                        :required="true"
                                        :label="trans('LASTNAME')"
                                        attached-class="form-control"
                                        obj-name="LastName" placeholder="..."/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-4">
                            <base-input ref="Email"
                                        v-model="model.Email"
                                        :label="trans('EMAIL')"
                                        type="text"
                                        :max-length="150"
                                        attached-class="form-control"
                                        obj-name="Email" :required="true" placeholder="..."/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-3">
                            <base-input ref="Phone"
                                        v-model="model.Phone"
                                        :label="trans('PHONE')"
                                        type="text"
                                        :max-length="150"
                                        attached-class="form-control"
                                        obj-name="Phone" placeholder="..."/>
                        </div>
                        <div class="form-group col-lg-3">
                            <base-input ref="Mobile"
                                        v-model="model.Mobile"
                                        type="text"
                                        :max-length="150"

                                        :label="trans('MOBILE')"
                                        attached-class="form-control"
                                        obj-name="Mobile" placeholder="..."/>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="error" class="error-alert-wrap">
                <font-awesome-icon icon="fas fa-exclamation-triangle" class="float-right-margin"/>
                {{ trans(errorMessage) }}
            </div>

            <button ref="silentSubmit" type="submit" class="btn-silent-submit"/>
        </Form>
    </div>
</template>

<script>
import * as yup from 'yup';
import {Form} from 'vee-validate';
import store from "@/store";
import lodash from "lodash";
import {uuid} from "vue-uuid";
import {TRACKING_STATE} from "@/utils/applyTrackingState";
import {JOB_TYPES} from "@/data/jobTypes";
import DataDropdown from "@/components/dropdowns/DataDropdown.vue";
import SalutationsDropDown from "@/components/dropdowns/SalutationsDropdown.vue";
import OfficeDropdown from "@/components/dropdowns/OfficeDropdown.vue";
import BaseTextArea from "@/components/BaseTextArea.vue";
import {OfficeController} from "@/controllers/office.controller";
import {QUALIFICATION_TYPES} from "@/data/qualificationType";
import {isLicensed} from "@/rights";
import {LICENSE_TYPES} from "@/data/licenseTypes";
import router from "@/router";

export default {
    name: "JobAdForm",
    props: ['jobadController'],
    emits: ["update"],
    components: {
        BaseTextArea, OfficeDropdown, SalutationsDropDown, DataDropdown,
        Form
    },
    data() {
        return {
            validationSchema: yup.object({
                AdName: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                FirstName: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                LastName: yup.string().required(this.trans("REQUIRED-MESSAGE")),
            }),
            OfficesList: [],
            officeController: new OfficeController(this),
            Video: null,
            selectedImage: null,
            model: {
                PkJobAd: null,
                FkCompany: store.state.FkCompany,
                FkOffice: store.state.FkOffice,
                AdName: null,
                CreatedDate: null,
                AdType: null,
                Duration: null,
                QualificationId: null,
                FreePositions: null,
                Salary: null,
                StartDate: null,
                EndDate: null,
                IsActive: true,
                LocationType: null,
                EntryDate: null,
                SalaryType: null,
                AdText: null,
                DeadLine: null,
                FullTime: false,
                Salutation: null,
                NameAddition: null,
                FirstName: null,
                LastName: null,
                Phone: null,
                Email: null,
                Mobile: null,
                JobId: null,
                JobFieldId: null,
            },
            error: false,
            errorMessage: ''
        }
    },
    watch: {
        'model.AdType'(newValue, oldValue) {
            if (newValue == null) {
                this.model.JobId = null;
                this.model.JobFieldId = null;
            }
        }
    },
    computed: {
        isLicensedVideo() {
            return this.isLicensed([LICENSE_TYPES.PACKAGE_M, LICENSE_TYPES.PACKAGE_L], false)
        },
        QualificationList() {
            return this.$store.state.Lookups.GraduationsLookup != null && Array.isArray(this.$store.state.Lookups.GraduationsLookup) ? this.$store.state.Lookups.GraduationsLookup : [];
        },
        controller() {
            return this.$props.jobadController != null ? this.$props.jobadController : null;
        },
        ShowJobField() {
            return this.model.AdType == null || this.model.AdType != JOB_TYPES.DUAL_STUDY
        },
        GetStudyJobText() {
            return this.model.AdType == JOB_TYPES.DUAL_STUDY ? 'STUDY-FIELD' : 'JOB-FIELD'
        },
        GetStudyJobSelectText() {
            return this.model.AdType == JOB_TYPES.DUAL_STUDY ? 'SELECT-STUDY-FIELD' : 'SELECT-JOB-FIELD'
        },
        JobTypeList() {
            return this.$store.state.Lookups.JobAdTypesLookup != null && Array.isArray(this.$store.state.Lookups.JobAdTypesLookup) ? this.$store.state.Lookups.JobAdTypesLookup : [];
        },
        JobFieldIdList() {
            if (this.model.AdType === null)
                return [];
            else if (this.model.AdType == JOB_TYPES.DUAL_STUDY)
                return this.$store.state.Lookups.StudyFieldsLookup != null && Array.isArray(this.$store.state.Lookups.StudyFieldsLookup) ? this.$store.state.Lookups.StudyFieldsLookup : [];
            else
                return this.$store.state.Lookups.JobFieldsLookup != null && Array.isArray(this.$store.state.Lookups.JobFieldsLookup) ? this.$store.state.Lookups.JobFieldsLookup : [];
        },
        JobIdList() {
            if (this.model.AdType === null)
                return [];
            else if (this.model.AdType == JOB_TYPES.DUAL_STUDY)
                return this.$store.state.Lookups.StudyFieldsLookup != null && Array.isArray(this.$store.state.Lookups.StudyFieldsLookup) ? this.$store.state.Lookups.StudyFieldsLookup : [];
            else
                return this.$store.state.Lookups.JobsLookup != null && Array.isArray(this.$store.state.Lookups.JobsLookup) ? this.$store.state.Lookups.JobsLookup : [];
        },
        DateTimeFormat() {
            return "d.m.Y";
        },
        DateTimeFormatValue() {
            return "Y-m-dT00:00:00.000\\Z";
        },
        AdTypeList() {
            return store.state.Lookups.JobAdTypesLookup != null && Array.isArray(store.state.Lookups.JobAdTypesLookup) ? store.state.Lookups.JobAdTypesLookup : [];
        },
        LocationTypeList() {
            return store.state.Lookups.LocationTypesLookup != null && Array.isArray(store.state.Lookups.LocationTypesLookup) ? store.state.Lookups.LocationTypesLookup : [];
        },
        SalaryTypeList() {
            return store.state.Lookups.SalaryTypesLookup != null && Array.isArray(store.state.Lookups.SalaryTypesLookup) ? store.state.Lookups.SalaryTypesLookup : [];
        },
        OfficeList() {
            return this.OfficesList != null ? this.OfficesList : [];
        }
    },
    methods: {
        StartDateChange(newDate) {
            this.model.StartDate = newDate;
        },
        openJobAd() {
            router.push("/JobAdProfileView/" + this.model.FkOffice + "/" + this.model.PkJobAd);
        },
        EndDateChange(newDate) {
            this.model.EndDate = newDate;
        },
        EntryDateChange(newDate) {
            this.model.EntryDate = newDate;
        },
        DeadLineChange(newDate) {
            this.model.DeadLine = newDate;
        },
        setFullTime(value) {
            this.model.FullTime = value.target.checked;
        },
        removeVideo() {
            if (!this.isLicensedVideo)
                return;

            let video = document.getElementById('video-preview');
            video.pause();
            video.removeAttribute('src'); // empty source
            video.load();
            this.Video1 = null;
            store.dispatch('uploadVideoFileJobAd', {
                PkCompany: this.model.FkCompany,
                PkJobAd: this.model.PkJobAd,
                OnlyDelete: true
            }).then(res => {
                if (res.IsError) {
                    this.$notify(
                        {
                            title: this.trans('ERROR'),
                            text: this.trans(res.ErrorMessage),
                            type: 'error',
                        });
                }
            });
        },
        uploadVideo() {
            if (!this.isLicensedVideo)
                return;
            if ((event.target.files[0].size / 1024 / 1024) > 20) {
                this.$notify(
                    {
                        title: this.trans('ERROR'),
                        text: this.trans("VIDEO-FILE-SIZE-EXCEED-20"),
                        type: 'error',
                    });
                return;
            }

            this.Video = event.target.files[0];

            this.error = false;
            this.errorMessage = null


            if (!this.Video) {
                event.preventDefault();
                event.target.value = null;
                return;
            }


            store.dispatch('uploadVideoFileJobAd', {
                Video: this.Video,
                PkCompany: this.model.FkCompany,
                PkJobAd: this.model.PkJobAd
            }).then(res => {
                if (res.IsError) {
                    this.$notify(
                        {
                            title: this.trans('ERROR'),
                            text: this.trans(res.ErrorMessage),
                            type: 'error',
                        });
                } else {
                    this.previewVideo();
                }
            });


        },
        previewVideo() {
            if (!this.isLicensedVideo)
                return;
            let video = document.getElementById('video-preview');
            let reader = new FileReader();

            reader.readAsDataURL(this.Video);
            reader.addEventListener('load', function () {
                video.src = reader.result;
            });
        },
        change_file() {

            let file = event.target.files[0];
            this.error = false;
            this.errorMessage = null
            let MAX_FILE_SIZE = 8 * 1024 * 1024;
            let data = this.model;
            let controller = this.controller;
            let selectedImage = this.selectedImage;


            if (!file) {
                event.preventDefault();
                event.target.value = null;
                return;
            }

            if (file.size > MAX_FILE_SIZE) {
                event.preventDefault();
                event.target.value = null;
                this.error = true;
                this.errorMessage = "FILE-TO-BIG"
                return;
            }
            let fileReader = new FileReader();

            fileReader.onloadend = function (e) {
                let arr = (new Uint8Array(e.target.result)).subarray(0, 4);

                let header = "";
                for (let i = 0; i < arr.length; i++) {
                    header += arr[i].toString(16);
                }

                let type = "";
                switch (header) {
                    case "89504e47":
                        type = "image/png";
                        break;
                    case "47494638":
                        type = "image/gif";
                        break;
                    case "ffd8ffe0":
                    case "ffd8ffe1":
                    case "ffd8ffe2":
                    case "ffd8ffe3":
                    case "ffd8ffe8":
                        type = "image/jpeg";
                        break;
                    case "255044462D":
                    case "25504446":
                        type = "pdf";
                        break;
                    default:
                        type = "unknown"; // Or you can use the blob.type as fallback
                        break;
                }

                if (type !== "unknown") {
                    data.FileName = file.name;
                    data.ProfilePicture = file;
                    store.dispatch('uploadProfilePictureJobAd', {
                        ProfilePicture: data.ProfilePicture,
                        PkCompany: data.FkCompany,
                        PkJobAd: data.PkJobAd,
                    }).then(res => {
                        controller.GetProfilePictureJobAd(data.FkCompany, data.PkJobAd).then(result => {
                            if (result.data.size > 0)
                                selectedImage = URL.createObjectURL(new Blob([result.data]));
                            else
                                selectedImage = null;
                        })
                    });

                } else {
                    event.preventDefault();
                    event.target.value = null;

                }
            }

            fileReader.readAsArrayBuffer(file);

        },
        setData(data = {}) {
            this.model = data;
            if (this.model.PkJobAd != null) {
                this.controller.GetProfilePictureJobAd(data.FkCompany, data.PkJobAd).then(result => {

                    if (result.data.size > 0)
                        this.selectedImage = URL.createObjectURL(new Blob([result.data]));
                    else
                        this.selectedImage = null;

                    if (this.isLicensedVideo) {
                        this.controller.GetVideoFileJobAd(data.FkCompany, data.PkJobAd).then(result => {
                            if (result != null) {
                                this.Video = result;
                                this.previewVideo();
                            }
                        })
                    }
                })
            }

        },
        getData() {
            return this.model;
        },
        validate() {
            this.error = false;
            this.errorMessage = '';

            return this.$refs.valForm.validate().then(result => {
                let otherErrors = false;
                if (this.model.AdType == null || this.model.AdType <= 0) {
                    this.$refs.AdType.setError();
                    otherErrors = true;
                }

                if (this.model.StartDate == null) {
                    this.$refs.StartDate.setError("REQUIRED-MESSAGE");
                    otherErrors = true;
                }

                if (this.model.Salutation <= 0) {
                    this.$refs.Salutation.setError();
                    otherErrors = true;
                }

                if (this.model.FkOffice == null) {
                    this.$refs.FkOffice.setError();
                    otherErrors = true;
                }

                if (result.valid && !otherErrors) {
                    this.$emit('update', true)
                } else {

                    for (const key in result.errors) {
                        this.$refs[key].setError();
                    }

                    return Promise.resolve(false);
                }
            })
        },
    },
    beforeUpdate() {
    },
    activated() {
        this.officeController.read().then(result => {
            this.OfficesList = result.OfficesList;
        })
    }
}
</script>

<style scoped>

</style>
