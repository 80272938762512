import {createStore} from 'vuex'
// These is the default (intial) state of the store
import defaultState from './vuex/defaultState.js'
// in actions all sub action functions are provided as object of functions
import * as actions from './vuex/actions.js'
// in mutations all sub mutation functions are provided as object of functions
import * as mutations from './vuex/mutations.js'


// Do a shallow copy of the defaultState. Otherwise we could run in danger
// to mutate default values in the defaultState object. But we need it always for reseting
// the application's state

let defaultStateValues = defaultState;

//let state = Object.assign({}, defaultStateValues);
// Thanks to mr idiot kutyol... i have lost about 4 hours here for checking why god damn the properties are references ....
// DO NEVER USE OBJECT.assign if you not know how this works!
let state = JSON.parse(JSON.stringify(defaultStateValues))

/**
 * We are exporting a new Vuex.Store.
 * Means we can import the store from any other module
 * But in vue components they are already embeded as: this.$store
 */
export default new createStore({
    state: state,
    mutations: mutations,
    actions: actions,
    getters: {
        layout(state) {
            return state.layout;
        },
    }
})
