<template>
    <div class="section-heading">
        <router-link to="/" class="no-decoration gray-color">
            <font-awesome-icon icon="fas fa-home"/>
            {{ $t('DASHBOARD') }}
        </router-link>
        <span class="no-decoration breadcrumb-decorate-yellow">
                <font-awesome-icon icon="fas fa-angle-right"/> &nbsp;
                <font-awesome-icon icon="fas fa-user"/>
                {{ trans('STUDENT-PROFILE') }}
            </span>
    </div>
    <div class="layout-px-spacing">
        <div class="row layout-spacing">
            <!-- Content -->
            <div class="col-xl-4 col-lg-6 col-md-5 col-sm-12 layout-top-spacing">
                <div class="user-profile user-profile-functions layout-spacing">
                    <div class="panel">
                        <div class="panel-body">
                            <div class="d-flex justify-content-between">
                                <h3 class="">{{ trans('FUNCTIONS') }}</h3>
                                <div class="headerIcons">
                                    <PopOver class="dark" arrow hover openDelay="200" closeDelay="100"
                                             placement="right">
                                        <template #content>
                                            <p class="title">{{ trans('SEND-REQUEST') }}</p>
                                            <span
                                                v-for="(line,lineNumber) of trans('SEND-REQUEST-TEXT').split('\n')"
                                                v-bind:key="lineNumber">
                                                  {{ line }}<br/>
                                              </span>
                                        </template>
                                        <a href="javascript:;" class="mt-2 edit-profile iconMargin">
                                            <font-awesome-icon :icon="['fas','puzzle-piece']" @click="CreateMatch"
                                            >
                                            </font-awesome-icon>
                                        </a>
                                    </PopOver>
                                    <PopOver class="dark" arrow hover openDelay="200" closeDelay="100"
                                             placement="right">
                                        <template #content>
                                            <p class="title">{{ trans('LIKE') }}</p>
                                            <span
                                                v-for="(line,lineNumber) of trans('LIKE-CANDIDATE').split('\n')"
                                                v-bind:key="lineNumber">
                                                  {{ line }}<br/>
                                              </span>
                                        </template>
                                        <div>
                                            <a href="javascript:;" class="mt-2 edit-profile-done iconMargin"
                                               v-if="IsLiked">
                                                <font-awesome-icon :icon="['fas','heart']" @click="RemoveLike"
                                                ></font-awesome-icon>
                                            </a>
                                            <a href="javascript:;" class="mt-2 edit-profile iconMargin" v-if="!IsLiked">
                                                <font-awesome-icon :icon="['far','heart']" @click="SetLike"
                                                ></font-awesome-icon>
                                            </a>
                                        </div>
                                    </PopOver>
                                    <PopOver class="dark" arrow hover openDelay="200" closeDelay="100"
                                             placement="right">
                                        <template #content>
                                            <p class="title">{{ trans('FAVOURITE') }}</p>
                                            <span
                                                v-for="(line,lineNumber) of trans('FAVOURITE-CANDIDATE').split('\n')"
                                                v-bind:key="lineNumber">
                                                  {{ line }}<br/>
                                              </span>
                                        </template>
                                        <div>
                                            <a href="javascript:;" class="mt-2 edit-profile-done iconMargin"
                                               v-if="IsFavourite">
                                                <font-awesome-icon :icon="['fas','star']" @click="RemoveFavourite"
                                                ></font-awesome-icon>
                                            </a>
                                            <a href="javascript:;" class="mt-2 edit-profile iconMargin"
                                               v-if="!IsFavourite">
                                                <font-awesome-icon :icon="['far','star']" @click="SetFavourite"
                                                ></font-awesome-icon>
                                            </a>
                                        </div>
                                    </PopOver>
                                    <PopOver class="dark" arrow hover openDelay="200" closeDelay="100"
                                             placement="right">
                                        <template #content>
                                            <p class="title" v-if="IsMatch===true">{{ trans('SEND-MESSAGE') }}</p>
                                            <span
                                                v-for="(line,lineNumber) of trans('SEND-MESSAGE-CANDIDATE').split('\n')"
                                                v-bind:key="lineNumber">
                                                  {{ line }}<br/>
                                              </span>
                                        </template>
                                        <a href="javascript:;" class="mt-2 edit-profile iconMargin"
                                           v-if="IsMatch===true">
                                            <font-awesome-icon :icon="['fas','envelope']" @click="SendMessage"
                                            ></font-awesome-icon>
                                        </a>
                                    </PopOver>
                                    <PopOver class="dark" arrow hover openDelay="200" closeDelay="100"
                                             placement="right">
                                        <template #content>
                                            <p class="title" v-if="IsMatch===true">{{ trans('SEND-APPOINTMENT') }}</p>
                                            <span
                                                v-for="(line,lineNumber) of trans('SEND-APPOINTMENT-CANDIDATE').split('\n')"
                                                v-bind:key="lineNumber">
                                                  {{ line }}<br/>
                                              </span>
                                        </template>
                                        <a href="javascript:;" class="mt-2 edit-profile iconMargin"
                                           v-if="IsMatch===true">
                                            <font-awesome-icon :icon="['fas','calendar']"
                                                               @click="SendAppointment"></font-awesome-icon>
                                        </a>
                                    </PopOver>
                                </div>
                            </div>
                            <div class="user-info-list">
                                <div class="">
                                    <ul class="matching-block mt-4 list-unstyled">
                                        <template v-for="(match,idx) in StudentMatchings" :key="idx">
                                            <li class="contacts-block__item">
                                                {{ match.OfficeName }} - {{ trans(match.AdType) }} {{ match.AdName }}:
                                                {{
                                                    trans(GetMatchingState(match))
                                                }}
                                                <span>
                                                    <PopOver class="dark" arrow hover openDelay="200" closeDelay="100"
                                                             placement="right"
                                                             v-if="IsCompDec===true">
                                        <template #content>
                                            <p class="title">{{ trans('COMP-DECLINED') }}</p>
                                            <span
                                                v-for="(line,lineNumber) of trans('COMP-DECLINED-TEXT').split('\n')"
                                                v-bind:key="lineNumber">
                                                  {{ line }}<br/>
                                              </span>
                                        </template>
                                        <a href="javascript:;" class="mt-2 edit-profile-error iconMargin">
                                            <font-awesome-icon :icon="['fas','xmark']">
                                            </font-awesome-icon>
                                        </a>
                                    </PopOver>
                                    <PopOver class="dark" arrow hover openDelay="200" closeDelay="100"
                                             placement="right"
                                             v-if="IsStudAcc===true">
                                        <template #content>
                                            <p class="title">{{ trans('STUD-ACC') }}</p>
                                            <span
                                                v-for="(line,lineNumber) of trans('STUD-ACC-TEXT').split('\n')"
                                                v-bind:key="lineNumber">
                                                  {{ line }}<br/>
                                              </span>
                                        </template>
                                        <a href="javascript:;" class="mt-2 edit-profile-done iconMargin">
                                            <font-awesome-icon :icon="['fas','question-circle']"
                                                               @click="AcceptMatch(match.PkMatching)">
                                            </font-awesome-icon>
                                        </a>
                                    </PopOver>
                                                    <PopOver class="dark" arrow hover openDelay="200" closeDelay="100"
                                                             placement="right"
                                                             v-if="IsStudAcc===true">
                                        <template #content>
                                            <p class="title">{{ trans('STUD-ACC') }}</p>
                                            <span
                                                v-for="(line,lineNumber) of trans('STUD-ACC-TEXT').split('\n')"
                                                v-bind:key="lineNumber">
                                                  {{ line }}<br/>
                                              </span>
                                        </template>
                                        <a href="javascript:;" class="mt-2 edit-profile-done iconMargin">
                                            <font-awesome-icon :icon="['fas','question-circle']"
                                                               @click="DeclineMatch(match.PkMatching)">
                                            </font-awesome-icon>
                                        </a>
                                    </PopOver>
                                    <PopOver class="dark" arrow hover openDelay="200" closeDelay="100"
                                             placement="right"
                                             v-if="IsStudDec===true">
                                        <template #content>
                                            <p class="title">{{ trans('STUD-DECLINED') }}</p>
                                            <span
                                                v-for="(line,lineNumber) of trans('STUD-DECLINED-TEXT').split('\n')"
                                                v-bind:key="lineNumber">
                                                  {{ line }}<br/>
                                              </span>
                                        </template>
                                        <a href="javascript:;" class="mt-2 edit-profile-error iconMargin">
                                            <font-awesome-icon :icon="['fas','xmark']">
                                            </font-awesome-icon>
                                        </a>
                                    </PopOver>
                                    <PopOver class="dark" arrow hover openDelay="200" closeDelay="100"
                                             placement="right"
                                             v-if="IsCompAcc===true">
                                        <template #content>
                                            <p class="title">{{ trans('COMP-ACC') }}</p>
                                            <span
                                                v-for="(line,lineNumber) of trans('COMP-ACC-TEXT').split('\n')"
                                                v-bind:key="lineNumber">
                                                  {{ line }}<br/>
                                              </span>
                                        </template>
                                        <a href="javascript:;" class="mt-2 edit-profile-done iconMargin">
                                            <font-awesome-icon :icon="['fas','question-circle']"
                                                               @click="RemoveMatch(match.PkMatching)">
                                            </font-awesome-icon>
                                        </a>
                                    </PopOver>
                                    <PopOver class="dark" arrow hover openDelay="200" closeDelay="100"
                                             placement="right"
                                             v-if="IsMatch===true">
                                        <template #content>
                                            <p class="title">{{ trans('MATCH') }}</p>
                                            <span
                                                v-for="(line,lineNumber) of trans('REMOVE-MATCH').split('\n')"
                                                v-bind:key="lineNumber">
                                                  {{ line }}<br/>
                                              </span>
                                        </template>
                                        <a href="javascript:;" class="mt-2 edit-profile-done iconMargin">
                                            <font-awesome-icon :icon="['fas','xmark']"
                                                               @click="RemoveMatch(match.PkMatching)">
                                            </font-awesome-icon>
                                        </a>
                                    </PopOver>
                                                </span>
                                            </li>
                                        </template>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="user-profile layout-spacing">
                    <div class="panel">
                        <div class="panel-body">
                            <div class="d-flex justify-content-between">
                                <h3 class="">{{ trans('PROFILE-DATA') }}</h3>
                                <div class="headerIcons">
                                    <a href="javascript:;" class="mt-2 edit-profile-invisible iconMargin">
                                    </a>
                                </div>
                            </div>
                            <div class="text-center user-info">
                                <img
                                    :src="selectedImage != null ? selectedImage :  require('@/assets/images/empty-profile.jpg')"
                                    alt="avatar" class="profile-picture"/>
                                <p class="" style="color:#9acccb;" v-if="IsMatch===true">{{ MyUser.FirstName }} {{
                                        MyUser.LastName
                                    }}</p>
                            </div>
                            <div class="user-info-list">
                                <div class="">
                                    <ul class="contacts-block list-unstyled">
                                        <li class="contacts-block__item" v-if="MyProfile.BirthDate">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                style="height:18px;"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-calendar"
                                            >
                                                <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                                <line x1="16" y1="2" x2="16" y2="6"></line>
                                                <line x1="8" y1="2" x2="8" y2="6"></line>
                                                <line x1="3" y1="10" x2="21" y2="10"></line>
                                            </svg
                                            >
                                            {{ GetBirthDate }}
                                        </li>
                                        <li class="contacts-block__item" v-if="GetAddress">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                style="height:18px;"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-map-pin"
                                            >
                                                <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                                <circle cx="12" cy="10" r="3"></circle>
                                            </svg
                                            >
                                            {{ GetAddress }}
                                        </li>
                                        <li class="contacts-block__item" v-if="MyUser.Email&&IsMatch===true">
                                            <a href="mailto:example@mail.com"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    style="height:18px;"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="feather feather-mail"
                                                >
                                                    <path
                                                        d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                                    <polyline points="22,6 12,13 2,6"></polyline>
                                                </svg
                                                >
                                                {{ MyUser.Email }}</a
                                            >
                                        </li>
                                        <li class="contacts-block__item" v-if="MyProfile.Phone&&IsMatch===true">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 width="24"
                                                 height="24"
                                                 viewBox="0 0 24 24"
                                                 fill="none"
                                                 style="height:18px;"
                                                 stroke="currentColor"
                                                 stroke-width="2"
                                                 stroke-linecap="round"
                                                 stroke-linejoin="round"
                                                 class="feather feather-phone">
                                                <path
                                                    d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/>
                                            </svg>
                                            {{ MyProfile.Phone }}
                                        </li>
                                        <li class="contacts-block__item" v-if="MyProfile.Mobile&&IsMatch===true">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 width="24"
                                                 height="24"
                                                 viewBox="0 0 24 24"
                                                 fill="none"
                                                 style="height:18px;"
                                                 stroke="currentColor"
                                                 stroke-width="2"
                                                 stroke-linecap="round"
                                                 stroke-linejoin="round"
                                                 class="feather feather-smartphone">
                                                <rect x="5" y="2" width="14" height="20" rx="2" ry="2"/>
                                                <line x1="12" y1="18" x2="12.01" y2="18"/>
                                            </svg>
                                            {{ MyProfile.Mobile }}
                                        </li>
                                        <li class="contacts-block__item" v-if="GetReadyToMove">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                style="height:18px;"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-map-pin"
                                            >
                                                <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                                <circle cx="12" cy="10" r="3"></circle>
                                            </svg
                                            >
                                            {{ GetReadyToMove }}
                                        </li>
                                        <li class="contacts-block__item" v-if="GetCarAvailable">
                                            <font-awesome-icon style="height:18px;" icon="fa-car"></font-awesome-icon>
                                            {{ GetCarAvailable }}
                                        </li>
                                        <li class="contacts-block__item" v-if="GetHighestGraduationText">
                                            <font-awesome-icon style="height:18px;"
                                                               icon="fa-graduation-cap"></font-awesome-icon>
                                            {{ trans(GetHighestGraduationText) }} {{ GetGraduationEndDate }}
                                        </li>
                                        <li class="contacts-block__item" v-if="GetAverageText">
                                            <font-awesome-icon style="height:18px;"
                                                               icon="fa-ranking-star"></font-awesome-icon>
                                            {{ GetAverageText }} {{ trans('LAST-CERTIFICATE') }}
                                        </li>
                                        <li class="contacts-block__item">
                                            <ul class="list-inline">
                                                <li class="list-inline-item"
                                                    v-if="MyProfile.FacebookProfile&&IsMatch===true">
                                                    <div class="social-icon">
                                                        <a :href="MyProfile.FacebookProfile"
                                                           style="margin-top:3px;"
                                                        >
                                                            <font-awesome-icon style="height:18px;"
                                                                               icon="fa-brands fa-facebook"></font-awesome-icon>
                                                        </a>
                                                    </div>
                                                </li>
                                                <li class="list-inline-item"
                                                    v-if="MyProfile.XingProfile&&IsMatch===true">
                                                    <div class="social-icon">
                                                        <a :href="MyProfile.XingProfile"
                                                           style="margin-top:3px;"
                                                        >
                                                            <font-awesome-icon style="height:18px;"
                                                                               icon="fa-brands fa-xing"></font-awesome-icon>
                                                        </a>
                                                    </div>
                                                </li>
                                                <li class="list-inline-item"
                                                    v-if="MyProfile.InstagramProfile&&IsMatch===true">
                                                    <div class="social-icon">
                                                        <a :href="MyProfile.InstagramProfile"
                                                           style="margin-top:3px;"
                                                        >
                                                            <font-awesome-icon style="height:18px;"
                                                                               icon="fa-brands fa-instagram"></font-awesome-icon>
                                                        </a>

                                                    </div>
                                                </li>
                                                <li class="list-inline-item"
                                                    v-if="MyProfile.LinkedInProfile&&IsMatch===true">
                                                    <div class="social-icon">
                                                        <a :href="MyProfile.LinkedInProfile"
                                                           style="margin-top:3px;"
                                                        >
                                                            <font-awesome-icon style="height:18px;"
                                                                               icon="fa-brands fa-linkedin"></font-awesome-icon>
                                                        </a>
                                                    </div>
                                                </li>
                                                <li class="list-inline-item"
                                                    v-if="MyProfile.TikTiokProfile&&IsMatch===true">
                                                    <div class="social-icon">
                                                        <a :href="MyProfile.TikTiokProfile"
                                                           style="margin-top:3px;"
                                                        >
                                                            <font-awesome-icon style="height:18px;"
                                                                               icon="fa-brands fa-tiktok"></font-awesome-icon>
                                                        </a>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="knowledge layout-spacing">
                    <div class="panel">
                        <div class="panel-body">
                            <h3 class="">{{ trans('KNOWLEDGE') }}</h3>
                            <h4 class="header-cv">{{ trans('SCHOOL-STRENGTH') }}</h4>
                            <div class="item-timeline">
                                <div class="t-text">
                                    <template v-for="(skill,idx) in StudentSchoolSkillsList" :key="idx">
                                        <p>{{ trans(GetSchoolSkillIDText(skill.SkillId)) }}</p>
                                    </template>
                                </div>
                            </div>
                            <h4 class="header-cv">{{ trans('LANGUAGES') }}</h4>
                            <div class="item-timeline">
                                <div class="t-text">
                                    <template v-for="(lang,idx) in StudentLanguagesList" :key="idx">
                                        <p>{{ lang.Language }} - {{ trans(GetLanguageSkillLevelText(lang.Skill)) }}</p>
                                    </template>
                                </div>
                            </div>
                            <h4 class="header-cv">{{ trans('OTHER-QUALI') }}</h4>
                            <div class="skills">
                                <template v-for="(qual,idx) in StudentQualificationList" :key="idx">

                                    <div class="progress br-30" min="0">
                                        <div role="progressbar" aria-valuemin="0" aria-valuemax="100"
                                             :aria-valuenow="qual.QualificationLevel"
                                             class="progress-bar bg-primary"
                                             :style="{width: qual.QualificationLevel+'%'}">
                                            <div class="progress-title"><span>{{
                                                    qual.QualificationName
                                                }}</span><span>{{ qual.QualificationLevel }}</span></div>
                                        </div>
                                    </div>

                                </template>
                            </div>
                            <h4 class="header-cv">{{ trans('SUCCESSES') }}</h4>
                            <div class="item-timeline">
                                <div class="t-text">
                                    <template v-for="(item,idx) in StudentSuccessesList" :key="idx">
                                        <p>{{ item.Name }}</p>
                                    </template>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="attachments layout-spacing" v-if="true==false">
                    <div class="panel">
                        <div class="panel-body">
                            <h3 class="">{{ trans('ATTACHMENTS') }}</h3>
                            <div class="item-timeline">
                                <div class="t-text">
                                    <template v-for="(cv,idx) in StudentCvListWE" :key="idx">
                                        <p>{{ cv.Name }}</p>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-xl-8 col-lg-6 col-md-7 col-sm-12 layout-top-spacing">
                <div class="my-search layout-spacing">
                    <div class="panel">
                        <div class="panel-body">
                            <h3 class="">{{ trans('MY-SEARCH') }}</h3>
                            <div class="panel-body underline-content tabs">
                                <ul class="nav nav-tabs mb-3" id="lineTab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="underline-home-tab" data-bs-toggle="tab"
                                           href="#underline-home" role="tab" aria-controls="underline-home"
                                           aria-selected="true">
                                            {{ trans("VOCATIONAL-TRAINING") }}
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="underline-profile-tab" data-bs-toggle="tab"
                                           href="#underline-profile" role="tab" aria-controls="underline-profile"
                                           aria-selected="false"
                                        >
                                            {{ trans("DUAL-STUDY") }}
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="underline-contact-tab" data-bs-toggle="tab"
                                           href="#underline-contact" role="tab" aria-controls="underline-contact"
                                           aria-selected="false"
                                        >
                                            {{ trans("INTERN-SHIP") }}
                                        </a>
                                    </li>
                                </ul>

                                <div class="tab-content" id="lineTabContent-3">
                                    <div class="tab-pane fade show active" id="underline-home" role="tabpanel"
                                         aria-labelledby="underline-home-tab">
                                        <h5 style="font-weight:700;">{{ trans('JOB') }}</h5>
                                        <div class="bubble-div">
                                            <template v-for="(item,idx) in StudentJobSelectionListVTJob" :key="idx">
                                                <span class="bubbles" v-if="item.JobId!=null">{{
                                                        GetJobText(item.JobId)
                                                    }}</span>
                                            </template>
                                            <span class="bubbles" v-if="StudentJobSelectionListVTJob.length == 0">{{
                                                    trans('NO-DATA')
                                                }}</span>
                                        </div>
                                        <h5 style="font-weight:700;">{{ trans('JOB-FIELD') }}</h5>
                                        <div class="bubble-div">
                                            <template v-for="(item,idx) in StudentJobSelectionListVTJobField"
                                                      :key="idx">
                                                <span class="bubbles" v-if="item.JobFieldId!=null">{{
                                                        GetJobFieldText(item.JobFieldId)
                                                    }}</span>
                                            </template>
                                            <span class="bubbles" v-if="StudentJobSelectionListVTJobField.length == 0">{{
                                                    trans('NO-DATA')
                                                }}</span>
                                        </div>

                                    </div>
                                    <div class="tab-pane fade" id="underline-profile" role="tabpanel"
                                         aria-labelledby="underline-profile-tab">
                                        <h5 style="font-weight:700;">{{ trans('STUDY-FIELD') }}</h5>
                                        <div class="bubble-div">
                                            <template v-for="(item,idx) in StudentJobSelectionListDS" :key="idx">
                                                <span class="bubbles" v-if="item.JobFieldId!=null">{{
                                                        GetStudyText(item.JobFieldId)
                                                    }}</span>
                                            </template>
                                            <span class="bubbles" v-if="StudentJobSelectionListDS.length == 0">{{
                                                    trans('NO-DATA')
                                                }}</span>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="underline-contact" role="tabpanel"
                                         aria-labelledby="underline-contact-tab">
                                        <h5 style="font-weight:700;">{{ trans('JOB') }}</h5>
                                        <div class="bubble-div">
                                            <template v-for="(item,idx) in StudentJobSelectionListISJob" :key="idx">
                                                <span class="bubbles" v-if="item.JobId!=null">{{
                                                        GetJobText(item.JobId)
                                                    }}</span>
                                            </template>
                                            <span class="bubbles" v-if="StudentJobSelectionListISJob.length == 0">{{
                                                    trans('NO-DATA')
                                                }}</span>
                                        </div>
                                        <h5 style="font-weight:700;">{{ trans('JOB-FIELD') }}</h5>
                                        <div class="bubble-div">
                                            <template v-for="(item,idx) in StudentJobSelectionListISJobField"
                                                      :key="idx">
                                                <span class="bubbles" v-if="item.JobFieldId!=null">{{
                                                        GetJobFieldText(item.JobFieldId)
                                                    }}</span>
                                            </template>
                                            <span class="bubbles" v-if="StudentJobSelectionListISJobField.length == 0">{{
                                                    trans('NO-DATA')
                                                }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="bubble-yellow-div">
                                            
                                                <span class="bubbles-yellow">{{
                                                        GetAvailableFromText()
                                                    }}</span>
                                <span class="bubbles-yellow">{{
                                        trans(GetChangeCompanyText())
                                    }}</span>
                                <span class="bubbles-yellow">{{
                                        trans(GetRehearsalText())
                                    }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="bio layout-spacing">
                    <div class="panel">
                        <div class="panel-body">
                            <h3 class="">{{ trans('ABOUT-ME') }}</h3>
                            <span class="popOverText"
                                  v-for="(line,lineNumber) of AboutMeText.split('\n')"
                                  v-bind:key="lineNumber">
                        {{ line }}<br/>
                            </span>
                            <h4 class="header-cv">{{ trans('MY-TOP-5') }}</h4>
                            <div class="bubble-div">
                                <template v-for="(item,idx) in StudentStrengthList" :key="idx">
                                    <span class="bubbles">{{ item.Name }}</span>
                                </template>
                            </div>
                            <h4 class="header-cv">{{ trans('HOBBIES') }}</h4>
                            <div class="bubble-div">
                                <template v-for="(item,idx) in StudentHobbiesList" :key="idx">
                                    <span class="bubbles">{{ item.Name }}</span>
                                </template>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="jobineo-questions layout-spacing">
                    <div class="panel">
                        <div class="panel-body">
                            <h3 class="">{{ trans('JOBINEO-QUESTIONS') }}</h3>
                            <div class="bio-skill-box">
                                <div class="row">
                                    <div class="col-12 col-xl-4 col-lg-12 mb-xl-5 mb-5">
                                        <div class="b-questions">

                                            <div class="">
                                                <font-awesome-icon icon="fa-file-text"
                                                                   v-if="Video1==null"></font-awesome-icon>
                                                <font-awesome-icon icon="fa-camera"
                                                                   v-if="Video1!=null"></font-awesome-icon>
                                                <p>{{
                                                        trans(GetJobineoQuestion(StudentPersonality.JobineoQuestion1))
                                                    }}</p>
                                                <p>-</p>
                                                <p v-if="StudentPersonality.JobineoAnswer1">
                                                    {{ StudentPersonality.JobineoAnswer1 }}
                                                </p>
                                                <p v-show="Video1!=null">
                                                    <video class="videoPlayer" id="video-preview1" controls
                                                           controlsList="nodownload">
                                                    </video>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 col-xl-4 col-lg-12 mb-xl-5 mb-5">
                                        <div class="b-questions">

                                            <div class="">
                                                <font-awesome-icon icon="fa-file-text"
                                                                   v-if="Video2==null"></font-awesome-icon>
                                                <font-awesome-icon icon="fa-camera"
                                                                   v-if="Video2!=null"></font-awesome-icon>
                                                <p>{{
                                                        trans(GetJobineoQuestion(StudentPersonality.JobineoQuestion2))
                                                    }}</p>
                                                <p>-</p>
                                                <p v-if="StudentPersonality.JobineoAnswer2">
                                                    {{ StudentPersonality.JobineoAnswer2 }}
                                                </p>
                                                <p v-show="Video2!=null">
                                                    <video class="videoPlayer" id="video-preview2" controls
                                                           controlsList="nodownload">
                                                    </video>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 col-xl-4 col-lg-12 mb-xl-0 mb-5">
                                        <div class="b-questions">
                                            <span class="">
                                                <font-awesome-icon icon="fa-file-text"
                                                                   v-if="Video3==null"></font-awesome-icon>
                                                <font-awesome-icon icon="fa-camera"
                                                                   v-if="Video3!=null"></font-awesome-icon>
                                                <p>{{
                                                        trans(GetJobineoQuestion(StudentPersonality.JobineoQuestion3))
                                                    }}</p>
                                                <p>-</p>
                                                <p v-show="StudentPersonality.JobineoAnswer3">
                                                    {{ StudentPersonality.JobineoAnswer3 }}
                                                </p>
                                                <p v-if="Video3!=null">
                                                    <video class="videoPlayer" id="video-preview3" controls
                                                           controlsList="nodownload">
                                                    </video>
                                                </p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cv layout-spacing">
                    <div class="education layout-spacing">
                        <div class="panel">
                            <div class="panel-body">
                                <h3 class="">{{ trans('CV') }}</h3>
                                <h4 class="header-cv">{{ trans('WORK-EXPERIENCE') }}</h4>
                                <div class="timeline-alter">
                                    <template v-for="(cv,idx) in StudentCvListWE" :key="idx">
                                        <div class="item-timeline">
                                            <div class="t-meta-date">
                                                <p class="borderline">{{ GetDateString(cv) }}</p>
                                            </div>
                                            <div class="t-dot"></div>
                                            <div class="t-text">
                                                <p>{{ cv.Name }}</p>
                                                <p>{{ cv.JobDescription }}</p>
                                                <p>{{ cv.City }}</p>
                                                <p>{{ cv.Tasks }}</p>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <h4 class="header-cv">{{ trans('SCHOOL-EDUCATION') }}</h4>
                                <div class="timeline-alter">
                                    <template v-for="(cv,idx) in StudentCvListSC" :key="idx">
                                        <div class="item-timeline">
                                            <div class="t-meta-date">
                                                <p class="borderline">{{ GetDateString(cv) }}</p>
                                            </div>
                                            <div class="t-dot"></div>
                                            <div class="t-text">
                                                <p>{{ cv.Name }}</p>
                                                <p>{{ cv.JobDescription }}</p>
                                                <p>{{ cv.City }}</p>
                                                <p>{{ cv.Tasks }}</p>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <h4 class="header-cv">{{ trans('SOCIAL') }}</h4>
                                <div class="timeline-alter">
                                    <template v-for="(cv,idx) in StudentCvListSO" :key="idx">
                                        <div class="item-timeline">
                                            <div class="t-meta-date">
                                                <p class="borderline">{{ GetDateString(cv) }}</p>
                                            </div>
                                            <div class="t-dot"></div>
                                            <div class="t-text">
                                                <p>{{ cv.Name }}</p>
                                                <p>{{ cv.JobDescription }}</p>
                                                <p>{{ cv.City }}</p>
                                                <p>{{ cv.Tasks }}</p>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import '@/scss/jobineo/sass/scrollspyNav.scss';
import '@/scss/jobineo/sass/users/user-profile.scss';
import '@/scss/jobineo/sass/components/tabs-accordian/custom-tabs.scss';
import store from "@/store";
import lodash from "lodash";
import moment from "moment";
import {ProfileController} from "@/controllers/profile.controller";
import {cutDateString, getDateStringMonthYear} from "@/utils/convertDateTime";
import {SCHOOL_SKILLS_DROPDOWN} from "@/data/schoolSkills";
import {LANGUAGES_SKILLS_DROPDOWN} from "@/data/languages";
import {JOB_TYPES} from "@/data/jobTypes";
import {restUri} from "@/data/restPaths";
import {CompanyController} from "@/controllers/company.controller";
import {JobAdController} from "@/controllers/jobad.controller";
import {mutate} from "@/utils/mutate";
import {MATCHING_TYPES} from "@/data/matchingTypes";
import {MatchingController} from "@/controllers/matching.controller";
import router from "@/router";

export default {
    name: "StudentProfilePublicView.vue",
    components: {},
    data() {
        return {
            controller: new ProfileController(this),
            matchingController: new MatchingController(this),
            selectedImage: null,
            ProfileObj: null,
            Video1: null,
            Video2: null,
            Video3: null,
            FkUser: null,
        }
    },
    computed: {
        AboutMeText() {
            return this.StudentPersonality != null && this.StudentPersonality.AboutMe != null ? this.StudentPersonality.AboutMe : ""
        },
        IsFavourite() {
            return this.ProfileObj != null && this.ProfileObj.CurrentFavourite != null && this.ProfileObj.CurrentFavourite.IsFavourite == true ? true : false;
        },
        IsMatch() {

            let bothAcc = lodash.filter(this.StudentMatchings, x => x.CompanyAccepted == true && x.StudentAccepted == true);
            if (bothAcc != null && bothAcc.length > 0)
                return true;

            return false;
        },
        IsStudDec() {

            let studDec = lodash.filter(this.StudentMatchings, x => x.CompanyAccepted == true && x.StudentAccepted == false);

            if (studDec != null && studDec.length > 0)
                return true;

            return false;
        },
        IsCompDec() {

            let compDec = lodash.filter(this.StudentMatchings, x => x.CompanyAccepted == false && x.StudentAccepted == true);

            if (compDec != null && compDec.length > 0)
                return true;

            return false;
        },
        IsStudAcc() {

            let studAcc = lodash.filter(this.StudentMatchings, x => x.CompanyAccepted == null && x.StudentAccepted == true);
            if (studAcc != null && studAcc.length > 0)
                return true;

            return false;
        },
        IsCompAcc() {

            let compAcc = lodash.filter(this.StudentMatchings, x => x.CompanyAccepted == true && x.StudentAccepted == null);
            if (compAcc != null && compAcc.length > 0)
                return true;

            return false;
        },
        IsLiked() {
            return this.ProfileObj != null && this.ProfileObj.CurrentFavourite != null && this.ProfileObj.CurrentFavourite.IsLike == true ? true : false;
        },
        MyProfile() {
            return this.ProfileObj != null ? this.ProfileObj : {}
        },
        JobFieldIdList() {
            return this.$store.state.Lookups.JobFieldsLookup != null && Array.isArray(this.$store.state.Lookups.JobFieldsLookup) ? this.$store.state.Lookups.JobFieldsLookup : [];
        },
        JobIdList() {
            return this.$store.state.Lookups.JobsLookup != null && Array.isArray(this.$store.state.Lookups.JobsLookup) ? this.$store.state.Lookups.JobsLookup : [];
        },
        StudyIdList() {
            return this.$store.state.Lookups.StudyFieldsLookup != null && Array.isArray(this.$store.state.Lookups.StudyFieldsLookup) ? this.$store.state.Lookups.StudyFieldsLookup : [];
        },
        MyUser() {
            return this.MyProfile != null && this.MyProfile.CurrentUser != null ? this.MyProfile.CurrentUser : {}
        },
        StudentGraduation() {
            return this.MyProfile != null && this.MyProfile.StudentGraduation != null ? this.MyProfile.StudentGraduation : {}
        },
        StudentMatchings() {
            return this.MyProfile != null && this.MyProfile.Matchings != null && Array.isArray(this.MyProfile.Matchings) ? this.MyProfile.Matchings : []
        },
        StudentPersonality() {
            return this.MyProfile != null && this.MyProfile.StudentPersonality != null ? this.MyProfile.StudentPersonality : {}
        },
        StudentCvListSC() {
            return this.MyProfile != null && this.MyProfile.StudentCvListSC != null && Array.isArray(this.MyProfile.StudentCvListSC) && this.MyProfile.StudentCvListSC.length > 0 ? this.MyProfile.StudentCvListSC : []
        },
        StudentCvListSO() {
            return this.MyProfile != null && this.MyProfile.StudentCvListSO != null && Array.isArray(this.MyProfile.StudentCvListSO) && this.MyProfile.StudentCvListSO.length > 0 ? this.MyProfile.StudentCvListSO : []
        },
        StudentCvListWE() {
            return this.MyProfile != null && this.MyProfile.StudentCvListWE != null && Array.isArray(this.MyProfile.StudentCvListWE) && this.MyProfile.StudentCvListWE.length > 0 ? this.MyProfile.StudentCvListWE : []
        },
        StudentDriverLicenseList() {
            return this.MyProfile != null && this.MyProfile.StudentDriverLicenseList != null && Array.isArray(this.MyProfile.StudentDriverLicenseList) && this.MyProfile.StudentDriverLicenseList.length > 0 ? this.MyProfile.StudentDriverLicenseList : []
        },
        StudentHobbiesList() {
            return this.MyProfile != null && this.MyProfile.StudentHobbiesList != null && Array.isArray(this.MyProfile.StudentHobbiesList) && this.MyProfile.StudentHobbiesList.length > 0 ? this.MyProfile.StudentHobbiesList : []
        },
        StudentLanguagesList() {
            return this.MyProfile != null && this.MyProfile.StudentLanguagesList != null && Array.isArray(this.MyProfile.StudentLanguagesList) && this.MyProfile.StudentLanguagesList.length > 0 ? this.MyProfile.StudentLanguagesList : []
        },
        StudentSchoolSkillsList() {
            return this.MyProfile != null && this.MyProfile.StudentSchoolSkillsList != null && Array.isArray(this.MyProfile.StudentSchoolSkillsList) && this.MyProfile.StudentSchoolSkillsList.length > 0 ? this.MyProfile.StudentSchoolSkillsList : []
        },
        StudentSuccessesList() {
            return this.MyProfile != null && this.MyProfile.StudentSuccessesList != null && Array.isArray(this.MyProfile.StudentSuccessesList) && this.MyProfile.StudentSuccessesList.length > 0 ? this.MyProfile.StudentSuccessesList : []
        },
        StudentCertificatesList() {
            return this.MyProfile != null && this.MyProfile.StudentCertificatesList != null && Array.isArray(this.MyProfile.StudentCertificatesList) && this.MyProfile.StudentCertificatesList.length > 0 ? this.MyProfile.StudentCertificatesList : []
        },
        StudentJobSelectionListDS() {
            if (this.MyProfile != null && this.MyProfile.StudentJobSelectionList != null && Array.isArray(this.MyProfile.StudentJobSelectionList) && this.MyProfile.StudentJobSelectionList.length > 0) {
                let items = lodash.filter(this.MyProfile.StudentJobSelectionList, x => x.JobType == JOB_TYPES.DUAL_STUDY)
                if (items != null && items.length > 0) {
                    return items;
                } else
                    return [];
            } else {
                return [];
            }
        },
        StudentJobSelectionListISJob() {
            if (this.MyProfile != null && this.MyProfile.StudentJobSelectionList != null && Array.isArray(this.MyProfile.StudentJobSelectionList) && this.MyProfile.StudentJobSelectionList.length > 0) {
                let items = lodash.filter(this.MyProfile.StudentJobSelectionList, x => x.JobType == JOB_TYPES.INTERN_SHIP && x.JobId != null)
                if (items != null && items.length > 0) {
                    return items;
                } else
                    return [];
            } else {
                return [];
            }
        },
        StudentJobSelectionListISJobField() {
            if (this.MyProfile != null && this.MyProfile.StudentJobSelectionList != null && Array.isArray(this.MyProfile.StudentJobSelectionList) && this.MyProfile.StudentJobSelectionList.length > 0) {
                let items = lodash.filter(this.MyProfile.StudentJobSelectionList, x => x.JobType == JOB_TYPES.INTERN_SHIP && x.JobFieldId != null)
                if (items != null && items.length > 0) {
                    return items;
                } else
                    return [];
            } else {
                return [];
            }
        },
        StudentJobSelectionListVTJobField() {
            if (this.MyProfile != null && this.MyProfile.StudentJobSelectionList != null && Array.isArray(this.MyProfile.StudentJobSelectionList) && this.MyProfile.StudentJobSelectionList.length > 0) {
                let items = lodash.filter(this.MyProfile.StudentJobSelectionList, x => x.JobType == JOB_TYPES.VOCATIONAL_TRAINING && x.JobFieldId != null)
                if (items != null && items.length > 0) {
                    return items;
                } else
                    return [];
            } else {
                return [];
            }
        },
        StudentJobSelectionListVTJob() {
            if (this.MyProfile != null && this.MyProfile.StudentJobSelectionList != null && Array.isArray(this.MyProfile.StudentJobSelectionList) && this.MyProfile.StudentJobSelectionList.length > 0) {
                let items = lodash.filter(this.MyProfile.StudentJobSelectionList, x => x.JobType == JOB_TYPES.VOCATIONAL_TRAINING && x.JobId != null)
                if (items != null && items.length > 0) {
                    return items;
                } else
                    return [];
            } else {
                return [];
            }
        },
        StudentQualificationList() {
            return this.MyProfile != null && this.MyProfile.StudentQualificationList != null && Array.isArray(this.MyProfile.StudentQualificationList) && this.MyProfile.StudentQualificationList.length > 0 ? this.MyProfile.StudentQualificationList : []
        },
        StudentStrengthList() {
            return this.MyProfile != null && this.MyProfile.StudentStrengthList != null && Array.isArray(this.MyProfile.StudentStrengthList) && this.MyProfile.StudentStrengthList.length > 0 ? this.MyProfile.StudentStrengthList : [{Name: 'Hohe Belastbarkeit'}, {Name: 'Kommunikationsfähigkeit'}, {Name: 'Teamfähig'}]
        },
        GetAddress() {

            return this.MyProfile.PostCode != null ? (this.MyProfile.PostCode + ' ' + this.MyProfile.City) + '(' + this.GetSearchRadius + ')' : ''
        },
        GetBirthDate() {
            return cutDateString(this.MyProfile.BirthDate)
        },
        GetAvailableDate() {
            let text = '';

            if (this.MyProfile.AvailableFrom) {
                text = cutDateString(this.MyProfile.AvailableFrom)
            } else {
                text = this.trans('AVAILABLE-NOW')
            }

            return text;
        },
        GetReadyToMove() {
            let text = '';

            if (this.MyProfile.ReadyToMove) {
                text = this.trans('READY-TO-MOVE-YES')
            } else {
                text = this.trans('READY-TO-MOVE-NO')
            }

            return text;
        },
        GetAverageText() {
            if (this.MyProfile != null && this.MyProfile.StudentGraduation != null && this.MyProfile.StudentGraduation.Average != null) {
                return this.MyProfile.StudentGraduation.Average
            }
            return ''
        },
        GetGraduationEndDate() {
            if (this.MyProfile != null && this.MyProfile.StudentGraduation != null && this.MyProfile.StudentGraduation.EndDate != null) {
                if (moment(this.MyProfile.StudentGraduation.EndDate).isBefore(new Date()))
                    return "(" + this.GetDate(this.MyProfile.StudentGraduation.EndDate) + ")";
                else
                    return "(~ " + this.GetDate(this.MyProfile.StudentGraduation.EndDate) + ")";
            } else {
                return "(" + this.trans('EXPECTED') + ")"
            }
        },
        GetHighestGraduationText() {
            let text = '';
            if (this.MyProfile != null && this.MyProfile.StudentGraduation != null && this.MyProfile.StudentGraduation.HighestGraduation != null) {
                if (this.$store.state.Lookups.GraduationsLookup != null && Array.isArray(this.$store.state.Lookups.GraduationsLookup)) {
                    let items = lodash.filter(this.$store.state.Lookups.GraduationsLookup, x => x.DataKey == this.MyProfile.StudentGraduation.HighestGraduation);
                    if (items != null && items.length > 0) {
                        return items[0].DataValue;
                    }
                }
            }

            return text;
        },
        GetCarAvailable() {
            let text = '';

            if (this.MyProfile.CarAvailable) {
                text = this.trans('CAR-YES')
            } else {
                text = this.trans('CAR-NO')
            }

            return text;
        },
        GetSearchRadius() {
            let text = this.MyProfile.Radius != null ? this.MyProfile.Radius : 0;

            return text + ' Km';
        },

    },
    methods: {
        GetStrength(value) {
            return ''
        },
        GetLanguageSkillLevelText(id) {
            var items = lodash.filter(LANGUAGES_SKILLS_DROPDOWN, x => x.DataKey == id);
            if (items != null && items.length > 0) {
                return items[0].DataValue;
            } else {
                return ''
            }
        },
        GetSchoolSkillIDText(id) {
            var items = lodash.filter(SCHOOL_SKILLS_DROPDOWN, x => x.DataKey == id);
            if (items != null && items.length > 0) {
                return items[0].DataValue;
            } else {
                return ''
            }
        },
        GetDateString(cv) {
            if (cv === null)
                return '';

            return getDateStringMonthYear(cv.StartDate) + ' - ' + getDateStringMonthYear(cv.EndDate)
        },
        GetDate(value) {
            if (value === null)
                return '';

            return cutDateString(value)
        },
        GetJobineoQuestion(value) {
            let list = this.$store.state.Lookups.StudentQuestionsLookup != null && Array.isArray(this.$store.state.Lookups.StudentQuestionsLookup) ? this.$store.state.Lookups.StudentQuestionsLookup : []
            let items = lodash.filter(list, x => x.DataKey == value);
            if (items != null && items.length > 0) {
                return items[0].DataValue;
            } else {
                return ''
            }
        },
        previewVideo1() {

            let video = document.getElementById('video-preview1');
            if (video != null) {

                let reader = new FileReader();

                reader.readAsDataURL(this.Video1);
                reader.addEventListener('load', function () {
                    video.src = reader.result;
                });

            }
        },
        previewVideo2() {
            let video = document.getElementById('video-preview2');
            if (video != null) {
                let reader = new FileReader();

                reader.readAsDataURL(this.Video2);
                reader.addEventListener('load', function () {
                    video.src = reader.result;
                });
            }
        },
        previewVideo3() {
            let video = document.getElementById('video-preview3');
            if (video != null) {
                let reader = new FileReader();

                reader.readAsDataURL(this.Video3);
                reader.addEventListener('load', function () {
                    video.src = reader.result;
                });
            }
        },
        GetStudyText(id) {
            let items = lodash.filter(this.$store.state.Lookups.StudyFieldsLookup, x => x.DataKey == id);
            if (items != null && items.length > 0)
                return items[0].DataValue
            else return ''
        },
        GetJobFieldText(id) {
            let items = lodash.filter(this.$store.state.Lookups.JobFieldsLookup, x => x.DataKey == id);
            if (items != null && items.length > 0)
                return items[0].DataValue
            else return ''
        },
        GetJobText(id) {
            let items = lodash.filter(this.$store.state.Lookups.JobsLookup, x => x.DataKey == id);
            if (items != null && items.length > 0)
                return items[0].DataValue
            else return ''
        },
        GetRehearsalText() {
            if (this.MyProfile.Rehearsal == true)
                return 'REHEARSAL-YES'
            else
                return 'REHEARSAL-NO'
        },
        GetChangeCompanyText() {
            if (this.MyProfile.ChangeCompany == true)
                return 'CHANGE-COMPANY-YES'
            else
                return 'CHANGE-COMPANY-NO'
        },
        GetAvailableFromText() {
            if (this.MyProfile.AvailableFrom != null) {
                return this.trans('AV-FROM') + ' ' + this.GetDate(this.MyProfile.AvailableFrom)
            } else {
                return 'NO-DATA'
            }
        },
        GetMatchingState(match) {
            if (match.StudentAccepted == true && match.CompanyAccepted == null) {
                return 'MATCH-PENDING-COMPANY'
            } else if (match.StudentAccepted == null && match.CompanyAccepted == true) {
                return 'MATCH-PENDING-STUDENT'
            } else if (match.StudentAccepted == false && match.CompanyAccepted == true) {
                return 'MATCH-DECLINED-STUDENT'
            } else if (match.StudentAccepted == true && match.CompanyAccepted == false) {
                return 'MATCH-DECLINED-STUDENT'
            } else if (match.StudentAccepted == true && match.CompanyAccepted == true && match.IsValid == true) {
                return 'MATCHED'
            } else {
                return 'MATCH-UNKNOWN'
            }
        },
        SetFavourite() {
            if (this.ProfileObj != null && this.ProfileObj.PkStudentProfile != null)
                this.controller.SetProfileFavourite(this.ProfileObj.PkStudentProfile, true, null).then(result => {
                    if (result.data.IsError) {
                        this.$notify(
                            {
                                title: this.trans('ERROR'),
                                text: this.trans(result.data.ErrorMessage),
                                type: 'error',
                            });
                    } else {
                        this.$notify(
                            {
                                title: this.trans('SUCCESS'),
                                text: this.trans('DATA-SAVED'),
                                type: 'success'
                            });
                        this.controller.readPublicView(this.FkUser, true).then(result => {
                            this.ProfileObj = result.StudentProfile;
                        })
                    }
                })
        },
        RemoveFavourite() {
            if (this.ProfileObj != null && this.ProfileObj.PkStudentProfile != null)
                this.controller.SetProfileFavourite(this.ProfileObj.PkStudentProfile, false, null).then(result => {
                    if (result.data.IsError) {
                        this.$notify(
                            {
                                title: this.trans('ERROR'),
                                text: this.trans(result.data.ErrorMessage),
                                type: 'error',
                            });
                    } else {
                        this.$notify(
                            {
                                title: this.trans('SUCCESS'),
                                text: this.trans('DATA-SAVED'),
                                type: 'success'
                            });
                        this.controller.readPublicView(this.FkUser, true).then(result => {
                            this.ProfileObj = result.StudentProfile;
                        })
                    }
                })
        },
        RemoveMatch(fkMatch) {
            if (fkMatch != null) {

                new window.Swal({
                    title: this.trans('REMOVE-MATCH'),
                    text: this.trans('REALLY-REMOVE-MATCH'),
                    icon: 'warning',
                    showCancelButton: true,
                    cancelButtonText: this.trans('NO'),
                    confirmButtonText: this.trans('REMOVE-MATCH'),
                    iconColor: '#ce6f74',
                    padding: '2em'
                }).then(saResult => {
                    if (saResult.isConfirmed) {

                        this.matchingController.DeclineOrRemoveMatch(fkMatch, true, false, true).then(response => {
                            if (response.IsError) {
                                this.error = true;
                                this.errorMessage = response.ErrorMessage;
                                this.$notify(
                                    {
                                        title: this.trans('ERROR'),
                                        text: this.trans(response.ErrorMessage),
                                        type: 'error',
                                    });
                            } else {
                                this.$notify(
                                    {
                                        title: this.trans('SUCCESS'),
                                        text: this.trans('DATA-SAVED'),
                                        type: 'success'
                                    });

                                this.init();
                            }
                        })
                    }
                })

            }
        },
        AcceptMatch(fkMatch) {
            if (fkMatch != null) {

                new window.Swal({
                    title: this.trans('ACCEPT-MATCH'),
                    text: this.trans('REALLY-ACCEPT-MATCH'),
                    icon: 'warning',
                    showCancelButton: true,
                    cancelButtonText: this.trans('NO'),
                    confirmButtonText: this.trans('ACCEPT-MATCH'),
                    iconColor: '#ce6f74',
                    padding: '2em'
                }).then(saResult => {
                    if (saResult.isConfirmed) {

                        this.matchingController.AcceptMatch(fkMatch, true).then(response => {
                            if (response.IsError) {
                                this.error = true;
                                this.errorMessage = response.ErrorMessage;
                                this.$notify(
                                    {
                                        title: this.trans('ERROR'),
                                        text: this.trans(response.ErrorMessage),
                                        type: 'error',
                                    });
                            } else {
                                this.$notify(
                                    {
                                        title: this.trans('SUCCESS'),
                                        text: this.trans('DATA-SAVED'),
                                        type: 'success'
                                    });

                                this.init();
                            }
                        })
                    }
                })

            }
        },
        DeclineMatch(fkMatch) {

            if (fkMatch != null) {

                new window.Swal({
                    title: this.trans('DECLINE-MATCH'),
                    text: this.trans('REALLY-DECLINE-MATCH'),
                    icon: 'warning',
                    showCancelButton: true,
                    cancelButtonText: this.trans('NO'),
                    confirmButtonText: this.trans('DECLINE-MATCH'),
                    iconColor: '#ce6f74',
                    padding: '2em'
                }).then(saResult => {
                    if (saResult.isConfirmed) {

                        this.matchingController.DeclineOrRemoveMatch(fkMatch, true, true, false).then(response => {
                            if (response.IsError) {
                                this.error = true;
                                this.errorMessage = response.ErrorMessage;
                                this.$notify(
                                    {
                                        title: this.trans('ERROR'),
                                        text: this.trans(response.ErrorMessage),
                                        type: 'error',
                                    });
                            } else {
                                this.$notify(
                                    {
                                        title: this.trans('SUCCESS'),
                                        text: this.trans('DATA-SAVED'),
                                        type: 'success'
                                    });

                                this.init();
                            }
                        })
                    }
                })

            }
        },
        CreateMatch() {
            if (this.ProfileObj != null && this.ProfileObj.PkStudentProfile != null) {
                let jobAdController = new JobAdController(this);
                jobAdController.read(true, this.ProfileObj.PkStudentProfile).then(response => {
                    if (!response.IsError) {

                        let options = {};
                        let deepObject = {}

                        response.JobAdsList.forEach(item => {
                            deepObject[item.PkJobAd] = item.AdName
                        })

                        options[this.trans('JOB-ADS')] = deepObject

                        new window.Swal({
                            title: this.trans('SEND-REQUEST'),
                            text: this.trans('REALLY-SEND-REQUEST'),
                            icon: 'warning',
                            input: 'select',
                            inputOptions: deepObject,
                            inputValidator: (value) => {
                                if (!value) {
                                    return this.trans('NO-JOB-AD-SELECTED')
                                }
                            },
                            inputPlaceholder: this.trans('SELECT-JOB-AD'),
                            showCancelButton: true,
                            cancelButtonText: this.trans('NO'),
                            confirmButtonText: this.trans('SEND-REQUEST'),
                            iconColor: '#ce6f74',
                            padding: '2em'
                        }).then(saResult => {
                            if (saResult.isConfirmed) {

                                this.matchingController.SendMatchRequest(this.ProfileObj.PkStudentProfile, saResult.value, true).then(response => {
                                    if (response.IsError) {
                                        this.error = true;
                                        this.errorMessage = response.ErrorMessage;
                                        this.$notify(
                                            {
                                                title: this.trans('ERROR'),
                                                text: this.trans(response.ErrorMessage),
                                                type: 'error',
                                            });
                                    } else {
                                        this.$notify(
                                            {
                                                title: this.trans('SUCCESS'),
                                                text: this.trans('DATA-SAVED'),
                                                type: 'success'
                                            });

                                        this.init();
                                    }
                                })
                            }
                        })
                    } else {
                        this.$notify(
                            {
                                title: this.trans('ERROR'),
                                text: this.trans(response.ErrorMessage),
                                type: 'error',
                            });
                    }
                })

            }
        },
        SetLike() {
            if (this.ProfileObj != null && this.ProfileObj.PkStudentProfile != null)
                this.controller.SetProfileFavourite(this.ProfileObj.PkStudentProfile, null, true).then(result => {
                    if (result.data.IsError) {
                        this.$notify(
                            {
                                title: this.trans('ERROR'),
                                text: this.trans(result.data.ErrorMessage),
                                type: 'error',
                            });
                    } else {
                        this.$notify(
                            {
                                title: this.trans('SUCCESS'),
                                text: this.trans('DATA-SAVED'),
                                type: 'success'
                            });
                        this.controller.readPublicView(this.FkUser, true).then(result => {
                            this.ProfileObj = result.StudentProfile;
                        })
                    }
                })
        },
        RemoveLike() {
            if (this.ProfileObj != null && this.ProfileObj.PkStudentProfile != null)
                this.controller.SetProfileFavourite(this.ProfileObj.PkStudentProfile, null, false).then(result => {
                    if (result.data.IsError) {
                        this.$notify(
                            {
                                title: this.trans('ERROR'),
                                text: this.trans(result.data.ErrorMessage),
                                type: 'error',
                            });
                    } else {
                        this.$notify(
                            {
                                title: this.trans('SUCCESS'),
                                text: this.trans('DATA-SAVED'),
                                type: 'success'
                            });
                        this.controller.readPublicView(this.FkUser, true).then(result => {
                            this.ProfileObj = result.StudentProfile;
                        })
                    }
                })
        },
        SendMessage() {
            router.push("/UserAccount/Messages/")
        },
        SendAppointment() {

        },
        init() {
            this.controller.readPublicView(this.FkUser).then(response => {
                this.ProfileObj = response.StudentProfile;

                if (this.ProfileObj != null) {

                    this.controller.GetProfilePicturePublic(this.ProfileObj.FkUser).then(result => {

                        if (result.data != null && result.data.size > 0)
                            this.selectedImage = URL.createObjectURL(new Blob([result.data]));

                        this.controller.GetVideoFile(this.ProfileObj.FkUser, 1).then(result => {
                            if (result != null && result.size > 0) {
                                this.Video1 = result;
                                this.previewVideo1();
                            }
                        })
                        this.controller.GetVideoFile(this.ProfileObj.FkUser, 2).then(result => {
                            if (result != null && result.size > 0) {
                                this.Video2 = result;
                                this.previewVideo2();
                            }
                        })
                        this.controller.GetVideoFile(this.ProfileObj.FkUser, 3).then(result => {
                            if (result != null && result.size > 0) {
                                this.Video3 = result;
                                this.previewVideo3();
                            }
                        })
                    })
                }
            })
        }
    },
    activated() {
        this.FkUser = this.$route.params.FkUser;
        if (this.FkUser == null) {
            return;
        }
        this.init();
    }
}
</script>

<style scoped lang="scss">

.td-bio {
    color: #000000;
}

.td-width {
    min-width: 200px;
}

.dark {
    .td-bio {
        color: #ffffff;
    }

}

.purple {
    .td-bio {
        color: #ffffff;
    }

}

.borderline {
    border: 1px solid #9acccb;
    padding: 5px;
    border-radius: 26px;
    color: #9acccb !important;
}

.header-cv {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 800;
    margin-top: 30px;
}


.videoPlayer {
    max-height: 200px;
    max-width: 320px;
}

.headerIcons {
    display: flex;
}

.iconMargin {
    margin-left: 5px;
}

.popOverText {
    color: white;
    font-size: 14px;
    font-weight: 500;
}

.dark {
    --popper-theme-background-color: #333333;
    --popper-theme-background-color-hover: #333333;
    --popper-theme-text-color: white;
    --popper-theme-border-width: 0px;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 15px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

</style>
