<template>
  <div class="card" :class="[type && `card-${type}`]">
    <div class="card-image" v-if="$slots.image">
      <slot name="image"></slot>
    </div>
    <div
      class="card-header"
      v-if="$slots.header || title"
      :class="headerClasses"
    >
      <slot name="header">
        <h4 class="card-title">{{ title }}</h4>
        <p class="card-category" v-if="subTitle">{{ subTitle }}</p>
      </slot>
    </div>
    <div class="card-body" v-if="$slots.default" :class="bodyClasses">
      <div class="ribbon-container" v-if="hasRibbonFlag==true">
        <div class="ribbon-flag">{{ trans('MOST-POPULAR') }}</div>
      </div>
      <slot></slot>
    </div>
    <div class="card-image" v-if="$slots['image-bottom']">
      <slot name="image-bottom"></slot>
    </div>
    <slot name="raw-content"></slot>
    <div class="card-footer center-items" :class="footerClasses" v-if="$slots.footer">
      <hr v-if="showFooterLine"/>
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'card-comp',
  props: {
    title: {
      type: String,
      description: 'Card title'
    },
    subTitle: {
      type: String,
      description: 'Card subtitle'
    },
    hasRibbonFlag: {
      type: Boolean
    },
    type: {
      type: String,
      description: 'Card type (e.g primary/danger etc)'
    },
    showFooterLine: {
      type: Boolean,
      default: false
    },
    headerClasses: {
      type: [String, Object, Array],
      description: 'Card header css classes'
    },
    bodyClasses: {
      type: [String, Object, Array],
      description: 'Card body css classes'
    },
    footerClasses: {
      type: [String, Object, Array],
      description: 'Card footer css classes'
    }
  }
};
</script>
<style scoped lang="scss">
//@import "../../scss/sass/black-dashboard-pro";

.ribbon-container {
  position: absolute;
  left: 0;
  top: -3px;
  width: 100%;
  height: 120px;
  overflow: hidden;
}

.ribbon-flag {
  background: radial-gradient(#313030, #515152);
  color: white;
  position: absolute;
  display: block;
  top: 15px;
  right: -45px;
  width: 170px;
  padding: 12px 20px 8px;
  margin: 0;
  transform: rotate(45deg);
  height: 50px;
  z-index: 2;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  text-align: center;
}

.card-footer {
  background: transparent !important;
  border: none !important;
}

.card-body {
  padding: 0 !important;
  //overflow: hidden !important;
}

</style>
