<template>
    <div class="layout-top-spacing">
        <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
            <div class="panel br-6 p-0">
                <div class="custom-table dashboard-list">
                    <v-client-table :data="items" :columns="columns" :options="table_option">
                        <template #AdType="props">
                            <div>{{ trans(props.row.AdType) }}</div>
                        </template>
                        <template #FirstName="props">
                            <div>{{ props.row.FirstName }}</div>
                        </template>
                        <template #LastName="props">
                            <div>{{ props.row.LastName }}</div>
                        </template>
                        <template #CreatedDate="props">
                            <div>{{ getDate(props.row) }}</div>
                        </template>
                        <template #actions="props">
                            <div class="dropdown btn-group">
                                <a href="javascript:;" id="ddlRevenue" class="btn dropdown-toggle btn-icon-only"
                                   data-bs-toggle="dropdown" aria-expanded="false">
                                    <font-awesome-icon icon="fas fa-ellipsis"></font-awesome-icon>
                                </a>
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="ddlRevenue">
                                    <li v-if="IsCompany"><a href="javascript:;" class="dropdown-item btn-icon-only"
                                                            @click="openStudent(props.row)">
                                        <font-awesome-icon icon="fas fa-building" class=" dashboard-icon"
                                                           data-bs-toggle="modal" data-bs-target="#configBox"
                                        ></font-awesome-icon>
                                        <span style="padding-top:4px;"> {{ trans('OPEN-STUDENT') }}</span>
                                    </a></li>
                                    <li v-if="!IsCompany"><a href="javascript:;" class="dropdown-item btn-icon-only"
                                                             @click="openJobAd(props.row)">
                                        <font-awesome-icon icon="fas fa-suitcase" class="dashboard-icon"
                                        ></font-awesome-icon>
                                        {{ trans('OPEN-JOB-AD') }}
                                    </a></li>
                                    <li><a href="javascript:;" class="dropdown-item btn-icon-only"
                                           @click="cancelMatch(props.row)">
                                        <font-awesome-icon icon="fas fa-times" class="dashboard-icon"
                                        ></font-awesome-icon>
                                        {{ trans('CANCEL-MATCH') }}
                                    </a></li>
                                </ul>
                            </div>
                        </template>
                    </v-client-table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import {convertDateTimeForDisplay, cutDateString} from "@/utils/convertDateTime";
import router from "@/router";

export default {
    components: {},
    emits: ['update'],
    props: ['items', 'hasEdit', 'isCompany'],
    data() {
        return {};
    },
    computed: {
        columns() {
            if (this.IsCompany) {
                return ['AdType', 'AdName', 'FirstName', 'LastName', 'CreatedDate', 'actions'];
            } else {
                return ['AdType', 'AdName', 'OfficeName', 'CreatedDate', 'actions'];
            }
        },
        IsCompany() {
            return this.$props.isCompany != null && this.$props.isCompany == true ? true : false
        },
        countText() {
            return '{from}' + this.trans('FROM') + '{to}' + this.trans('TO') + '{count}'
        },
        searchPattern() {
            return this.trans('SEARCH')
        },
        resultText() {
            return this.trans('RESULTS')
        },
        noDataText() {
            return this.trans('NO-DATA-AVAILABLE')
        },
        table_option() {
            if (this.IsCompany) {
                return {
                    perPage: 10,
                    headings: {
                        AdType: this.trans('AD-TYPE'),
                        AdName: this.trans('AD-NAME'),
                        FirstName: this.trans('FIRSTNAME'),
                        LastName: this.trans('LASTNAME'),
                        CreatedDate: this.trans('DATE'),
                        actions: this.trans('ACTIONS')
                    },
                    filterable: false,
                    perPageValues: [5, 10, 20, 50],
                    skin: 'table table-hover',
                    columnsClasses: {actions: 'actions text-center'},
                    sortable: [],
                    pagination: {nav: 'scroll', chunk: 5},
                    texts: {
                        count: this.countText,
                        noResults: this.noDataText,
                        filterPlaceholder: this.searchPattern,
                        limit: this.resultText,
                    },
                    resizableColumns: false,
                }
            } else {
                return {
                    perPage: 10,
                    headings: {
                        AdType: this.trans('AD-TYPE'),
                        AdName: this.trans('AD-NAME'),
                        OfficeName: this.trans('OFFICE-NAME'),
                        CreatedDate: this.trans('DATE'),
                        actions: this.trans('ACTIONS')
                    },
                    filterable: false,
                    perPageValues: [5, 10, 20, 50],
                    skin: 'table table-hover',
                    columnsClasses: {actions: 'actions text-center'},
                    sortable: [],
                    pagination: {nav: 'scroll', chunk: 5},
                    texts: {
                        count: this.countText,
                        noResults: this.noDataText,
                        filterPlaceholder: this.searchPattern,
                        limit: this.resultText,
                    },
                    resizableColumns: false,
                }
            }
        }
    },
    methods: {
        getDate(item) {
            return cutDateString(item.CreatedDate)
        },
        openStudent(row) {
            router.push("/StudentProfilePublicView/" + row.FkStudentProfile)
        },
        openJobAd(row) {
            router.push("/JobAdProfilePublicView/" + row.FkCompany + "/" + row.FkOffice + "/" + row.FkJobAd);
        },
        cancelMatch(row) {

            if (row.PkMatching != null) {

                new window.Swal({
                    title: this.trans('REMOVE-MATCH'),
                    text: this.trans('REALLY-REMOVE-MATCH'),
                    icon: 'warning',
                    showCancelButton: true,
                    cancelButtonText: this.trans('NO'),
                    confirmButtonText: this.trans('REMOVE-MATCH'),
                    iconColor: '#ce6f74',
                    padding: '2em'
                }).then(saResult => {
                    if (saResult.isConfirmed) {

                        this.matchingController.DeclineOrRemoveMatch(row.PkMatching, true, false, true).then(response => {
                            if (response.IsError) {
                                this.error = true;
                                this.errorMessage = response.ErrorMessage;
                                this.$notify(
                                    {
                                        title: this.trans('ERROR'),
                                        text: this.trans(response.ErrorMessage),
                                        type: 'error',
                                    });
                            } else {
                                this.$notify(
                                    {
                                        title: this.trans('SUCCESS'),
                                        text: this.trans('DATA-SAVED'),
                                        type: 'success'
                                    });

                                this.$emit('update');

                            }
                        })
                    }
                })

            }
        }
    }
};
</script>
<style lang="scss">
.dashboard-list {
    .text-muted {
        color: rgba(255, 255, 255, 0.8) !important;
    }

    .table {
        color: #fff !important;
    }

    p {
        margin-bottom: 0.5rem;
    }


    .table-responsive {
        min-height: 170px !important;
    }
}

</style>

