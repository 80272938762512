import {APPOINTMENT_TYPES} from "@/data/appointment";


export const languages = [
    "de-DE",
    "en-GB",
    "en-US"

]


export const LANGUAGE_SKILLS = {
    POOR: 1,
    MEDIUM: 2,
    GOOD: 3,
    SAFE: 4,
    NATIVE: 5
}


// 1	Deutsch
// 2	Englisch
// 3	Spanisch
// 4	Polnisch
// 5	Russisch
// 6	Italienisch


export const LANGUAGES_DROPDOWN = [
    {
        DataKey: 'de',
        DataValue: 'DE'
    },

    {
        DataKey: 'en',
        DataValue: 'EN'
    }
]

export const LANGUAGES_SKILLS_DROPDOWN = [
    {
        DataKey: LANGUAGE_SKILLS.POOR,
        DataValue: 'LANGUAGE-POOR'
    },
    {
        DataKey: LANGUAGE_SKILLS.MEDIUM,
        DataValue: 'LANGUAGE-MEDIUM'
    },
    {
        DataKey: LANGUAGE_SKILLS.GOOD,
        DataValue: 'LANGUAGE-GOOD'
    },
    {
        DataKey: LANGUAGE_SKILLS.SAFE,
        DataValue: 'LANGUAGE-SAFE'
    },
    {
        DataKey: LANGUAGE_SKILLS.NATIVE,
        DataValue: 'LANGUAGE-NATIVE'
    },
]
