import Controller from "../controller.js";
import {restUri} from "@/data/restPaths";
import {mutate} from "@/utils/mutate";
import {CREATE, DELETE, UPDATE} from "@/data/cud";
import axios from "axios";
import {restBody} from "@/utils/restBody";
import store from "@/store";
import {uuid} from "vue-uuid";


export class OfficeController extends Controller {

    constructor(root) {
        super({
            root,
            path: {
                CUD: restUri.OFFICE_CUD,
                GET: restUri.GET_OFFICES
            }
        })
    }

    read() {

        return super.read({
            log: false,
            body: {FkCompany: store.state.FkCompany}
        }).then(data => {
            if (!data.IsError) {
                return data;
            } else return data
        });
    }

    readPublicView(fkCompany, fkOffice) {

        return axios.post(restUri.GET_OFFICE_PROFILE, restBody({
                FkCompany: fkCompany,
                FkOffice: fkOffice,
            })
        ).then(result => {
            if (!result.data.IsError) {
                return result.data;
            } else return result.data
        });
    }

    SetProfileFavourite(fkOffice, IsFavourite = null, IsLiked = null) {
        return axios.post(restUri.SET_OFFICE_JOBAD_FAVOURITE, restBody({
                FkOffice: fkOffice,
                IsFavourite: IsFavourite,
                IsLike: IsLiked
            })
        ).then(result => {
            return result;
        }).catch(result => {
            return result;
        });

    }

    create(Office = {}) {
        Office.PkOffice = uuid.v4();
        Office.OfficeProfile.PkOfficeProfile = uuid.v4();
        Office.OfficeProfile.FkOffice = Office.PkOffice;
        Office.OfficeProfile.FkCompany = store.state.FkCompany;
        Office.OfficeProfile.TrackingState = CREATE
        Office.FkCompany = store.state.FkCompany;
        Office.JobSelectionList = []
        Office.OfficeBenefitList = []
        Office.OfficeKeywordList = []
        Office.OfficeContactList = []

        let body = {
            Office: mutate(
                {TrackingState: CREATE},
                Office,
            )
        };

        return super.create({body})
            .then(result => {

                if (!result.IsError) {
                }

                return result;
            }).catch(result => {
                return result.data;
            })
    }

    update(Office = {}) {

        let body = {
            Office: mutate(
                {TrackingState: UPDATE},
                Office,
            )
        };

        return super.update({body})
            .then(result => {

                if (!result.IsError) {
                }

                return result;
            }).catch(result => {
                return result.data;
            })
    }

    delete(Office = {}) {

        let body = {
            Office: mutate(
                {TrackingState: DELETE},
                Office,
            )
        };

        return super.delete({body})
            .then(result => {

                if (!result.IsError) {
                }

                return result;
            }).catch(result => {
                return result.data;
            })
    }

    GetProfilePictureOffice(fkCompany, fkOffice) {
        return axios.post(restUri.DOWNLOAD_PROFILE_PICTURE_OFFICE, restBody({
                PkCompany: fkCompany,
                PkOffice: fkOffice,
            }),
            {
                responseType: 'blob'
            }
        ).then(result => {
            return result;
        }).catch(result => {
            return result;
        });

    }

    GetProfilePictureOfficePublic(fkCompany, fkOffice) {
        return axios.post(restUri.DOWNLOAD_PROFILE_PICTURE_OFFICE, restBody({
                PkCompany: fkCompany,
                PkOffice: fkOffice,
            }),
            {
                responseType: 'blob'
            }
        ).then(result => {
            return result;
        }).catch(result => {
            return result;
        });

    }

    GetVideoFileOffice(fkCompany, fkOffice, videoNo) {
        return axios.post(restUri.DOWNLOAD_VIDEO_FILE_OFFICE, restBody({
                PkCompany: fkCompany,
                PkOffice: fkOffice,
                VideoNo: videoNo
            }),
            {
                responseType: 'blob'
            }
        ).then(result => {
            return result.data;
        }).catch(result => {
            return result;
        });

    }

}

