import {isObject} from './isObject.js';


export function searchInObjectArray(objectArray = [], matchClosure = (obj = {}) => {
  return true;
}, childrensPropertyName = 'children', maxRecursion = -1) {
  if (!Array.isArray(objectArray) || !matchClosure instanceof Function)
    return new Error('utils/searchInObjectArray: objectArray is not an Array or no matchClosure is not a function!');
  let match = null,
    children = [],
    t = 0, l = objectArray.length;

  for (; t < l; t++) {
    if (!isObject(objectArray[t]))
      continue;

    if (matchClosure(objectArray[t])) {
      match = objectArray[t];
      break;
    }

    if (objectArray[t][childrensPropertyName] != null && Array.isArray(objectArray[t][childrensPropertyName]))
      children.push(objectArray[t][childrensPropertyName]);

  }

  if (match || !maxRecursion--)
    return match;


  let s = 0,
    childrenLength = children.length

  for (; s < childrenLength; s++) {
    match = searchInObjectArray(children[s], matchClosure, childrensPropertyName, maxRecursion);
    if (match)
      break;
  }
  return match;


}
