import Controller from "../controller.js";
import {restUri} from "@/data/restPaths";
import {mutate} from "@/utils/mutate";
import {CREATE, DELETE, UPDATE} from "@/data/cud";
import axios from "axios";
import {restBody} from "@/utils/restBody";
import store from "@/store";


export class FavouriteController extends Controller {

    constructor(root) {
        super({
            root,
            path: {
                CUD: restUri.REMOVE_FAVOURITE,
                GET: restUri.GET_FAVOURITES
            }
        })
    }

    init() {
    }

    read() {
        const fkUser = this.store.state.User != null ? this.store.state.User.PkUser : null;
        return super.read({
            log: false,
            body: {FkUser: fkUser},
        }).then(data => {
            if (!data.IsError) {
                return data;
            } else return data
        });
    }

    RemoveFavourite(fkStudentProfile = null, fkOffice = null, fkJobAd = null) {
        return axios.post(restUri.REMOVE_FAVOURITE, restBody({
                FkStudentProfile: fkStudentProfile,
                FkOffice: fkOffice,
                FkJobAd: fkJobAd,
                IsFavourite: false
            })
        ).then(result => {
            if (!result.data.IsError) {
                return this.read();
            }
            
            return result;
        }).catch(result => {
            return result;
        });

    }


}

