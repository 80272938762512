<template>
    <div class="layout-px-spacing">
        <div class="chat-section layout-top-spacing">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12">
                    <div class="chat-system">
                        <div class="hamburger" @click="is_show_user_menu = !is_show_user_menu">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-menu mail-menu d-lg-none"
                            >
                                <line x1="3" y1="12" x2="21" y2="12"></line>
                                <line x1="3" y1="6" x2="21" y2="6"></line>
                                <line x1="3" y1="18" x2="21" y2="18"></line>
                            </svg>
                        </div>
                        <div class="user-list-box" :class="{ 'user-list-box-show': is_show_user_menu }">
                            <div class="search">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-search"
                                >
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                </svg>
                                <input type="text" v-model.trim="SearchText" class="form-control"
                                       @keyup="searchMessages()" :placeholder="trans('SEARCH')"/>
                            </div>
                            <perfect-scrollbar class="people"
                                               :options="{ wheelSpeed: 0.5, swipeEasing: !0, minScrollbarLength: 40, maxScrollbarLength: 300, suppressScrollX: true }">
                                <div
                                    v-for="(msg, index) in SearchedMainMessagesList"
                                    class="person"
                                    :key="index"
                                    :class="{ active: SelectedMessageItem && SelectedMessageItem.PkMessage == msg.PkMessage }"
                                    @click="selectMessage(msg)"
                                >
                                    <div class="user-info">
                                        <div class="f-head">
                                            <img
                                                :ref="'pic_'+msg.PkMessage"
                                                :src="require('@/assets/images/empty-profile.jpg')"
                                                alt="avatar"/>
                                            {{ getProfilePicture(msg, false) }}
                                        </div>
                                        <div class="f-body">
                                            <div class="meta-info">
                                                <span class="user-name"
                                                      :class="{ 'text-primary': SelectedMessageItem && SelectedMessageItem.PkMessage == msg.PkMessage }">
                                                    {{ GetName(msg) }}
                                                </span>
                                                <span class="user-meta-time"
                                                      :class="{ 'text-primary': SelectedMessageItem && SelectedMessageItem.PkMessage == msg.PkMessage }">{{
                                                        GetDate(msg.CreatedDate)
                                                    }}</span>
                                            </div>
                                            <span class="preview">{{ trans(msg.Message) }}</span>
                                        </div>
                                    </div>
                                </div>
                            </perfect-scrollbar>
                        </div>
                        <div class="chat-box" :class="{ active: SelectedMessageItem }">
                            <div class="chat-not-selected" :class="{ 'd-none': SelectedMessageItem }">
                                <p>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-message-square"
                                    >
                                        <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                                    </svg>
                                    {{ trans('CLICK-MESSAGE') }}
                                </p>
                            </div>

                            <div v-if="SelectedMessageItem!=null" class="chat-box-inner h-100">
                                <div class="chat-meta-user chat-active">
                                    <div class="current-chat-user-name">
                                        <span class="f-head">
                                            <img :ref="'pic2_'+SelectedMessageItem.PkMessage"
                                                 :src="currentProfilePic"
                                                 alt="dynamic-image"/>

                                            <span class="name"> {{ GetName(SelectedMessageItem) }}</span>
                                        </span>
                                    </div>
                                </div>

                                <perfect-scrollbar
                                    class="chat-conversation-box"
                                    :options="{ wheelSpeed: 0.5, swipeEasing: !0, minScrollbarLength: 40, maxScrollbarLength: 300, suppressScrollX: true }"
                                >
                                    <div id="chat-conversation-box-scroll" class="chat-conversation-box-scroll">
                                        <div class="chat active-chat">


                                            <template v-for="(message, index) in SubMessageList"
                                                      :key="'msg' + index">
                                                <div class="conversation-start">
                                                    <span>{{ GetDate(message.CreatedDate) }}</span>
                                                </div>
                                                <div class="conversation-start" v-if="IsJobineoMessage(message)">
                                                    <span>{{ trans(message.Message) }}</span>
                                                </div>
                                                <div class="bubble" v-else
                                                     :class="[MyMessage(message) == true ? 'me' : 'you']">
                                                    {{ trans(message.Message) }}
                                                    <p class="float-right" v-if="MyMessage(message) == false">
                                                        <font-awesome-icon icon="fa-check"
                                                                           style="color:#9acccb;margin-left:8px;"
                                                                           v-if="IsNotified(message)"></font-awesome-icon>
                                                        <font-awesome-icon icon="fa-check"
                                                                           style="margin-left:8px;"
                                                                           v-if="!IsNotified(message)"></font-awesome-icon>
                                                    </p>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </perfect-scrollbar>
                                <div class="chat-footer chat-active ">
                                    <div class="row">
                                        <div class="chat-input col-lg-10 col-sm-10 col-xl-10 col-md-10">
                                            <div class="chat-form">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="feather feather-message-square"
                                                    @click="sendMessage"
                                                >
                                                    <path
                                                        d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                                                </svg>
                                                <textarea type="text"
                                                          v-model="MessageText"
                                                          class="mail-write-box form-control"
                                                          :placeholder="trans('MESSAGE')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-sm-2 col-xl-2 col-md-2 mt-2">
                                            <base-button @click="sendMessage" native-type="button"
                                                         class="btn btn-mint btn-sm float-right">
                                                <font-awesome-icon icon="fas fa-envelope" style="margin-right:10px;"/>
                                                {{ trans('SEND-MESSAGE') }}
                                            </base-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {MessagesController} from "@/controllers/messages.controller";
import '@/scss/jobineo/sass/apps/chat.scss';
import store from "@/store";
import {ProfileController} from "@/controllers/profile.controller";
import lodash from "lodash";
import {cutDateTimeString} from "@/utils/convertDateTime";
import {SENDER_TYPES} from "@/data/senderTypes";
import {uuid} from "vue-uuid";
import {REGISTER_TYPES} from "@/data/RegisterTypes";
import {OfficeController} from "@/controllers/office.controller";


export default {
    name: "MessagesView",
    components: {},
    props: [],
    data() {
        return {
            controller: new MessagesController(this),
            profileController: new ProfileController(this),
            officeController: new OfficeController(this),
            MainMessagesList: [],
            SearchedMainMessagesList: [],
            SubMessageList: [],
            MessagesList: [],
            loading: false,
            SearchText: null,
            selectedMessage: null,
            is_show_user_menu: false,
            MessageText: null,
            currentProfilePic: null,
            ShowDatePara: {
                LastDateTime: null,
                MessageGroup: null
            }
        }
    },
    computed: {
        SelectedMessageItem() {
            return this.selectedMessage;
        }
    },
    methods: {
        searchMessages() {
            if (this.SearchText == null) {
                this.SearchedMainMessagesList = this.MainMessagesList;
                return;
            }

            if (store.state.User != null && store.state.FkCompany != null) {
                let filtered = lodash.filter(this.MainMessagesList, x => x.StudentName.toLowerCase().includes(this.SearchText.toLowerCase()));
                if (filtered != null)
                    this.SearchedMainMessagesList = filtered;
                else
                    this.SearchedMainMessagesList = [];
            } else {
                let filtered = lodash.filter(this.MainMessagesList, x => x.CompanyName.toLowerCase().includes(this.SearchText.toLowerCase()) || x.JobAdName.toLowerCase().includes(this.SearchText.toLowerCase()));
                if (filtered != null)
                    this.SearchedMainMessagesList = filtered;
                else
                    this.SearchedMainMessagesList = [];
            }


        },
        selectMessage(item) {
            this.selectedMessage = item;

            this.controller.readSubMessages(item.FkMessageGroup).then(result => {

                if (!result.IsError) {
                    this.SubMessageList = result.MessagesList;
                }

                this.getProfilePicture(this.selectedMessage, true)


                this.scrollToBottom();
                this.is_show_user_menu = false;

            })


        },
        IsNotified(message) {
            if (message.SenderType == SENDER_TYPES.JOBINEO)
                return true;

            if ((message.SenderType == SENDER_TYPES.COMPANY && message.NotifiedCompany == true) ||
                (message.SenderType == SENDER_TYPES.STUDENT && message.NotifiedStudent == true)) {
                return true;
            }

            return false;
        },
        IsJobineoMessage(message) {
            if (message.SenderType == SENDER_TYPES.JOBINEO)
                return true;
            else
                return false;
        },
        MyMessage(message) {
            if (message.SenderType == SENDER_TYPES.JOBINEO)
                return false;
            else if ((message.SenderType == SENDER_TYPES.COMPANY && message.FkCompany == store.state.FkCompany) || (message.SenderType == SENDER_TYPES.STUDENT && message.FkStudentProfile == store.state.PkStudentProfile))
                return true;
            else
                return false

        },
        getProfilePicture(msg, messagePic) {

            let ref = "pic_";


            if (store.state.RegisterType == REGISTER_TYPES.COMPANY) {
                this.profileController.GetProfilePicturePublic(null, msg.FkStudentProfile).then(result => {
                    if (result.data != null && result.data.size > 0) {
                        if (messagePic == true) {
                            this.currentProfilePic = URL.createObjectURL(new Blob([result.data]));
                        } else {
                            this.$refs[ref + msg.PkMessage][0].src = URL.createObjectURL(new Blob([result.data]));
                        }
                    } else {
                        if (messagePic == true) {
                            this.currentProfilePic = require('@/assets/images/empty-profile.jpg');
                        } else {
                            this.$refs[ref + msg.PkMessage][0].src = require('@/assets/images/empty-profile.jpg');
                        }
                    }
                })
            } else {
                this.officeController.GetProfilePictureOfficePublic(msg.FkCompany, msg.FkOffice).then(result => {
                    if (result.data != null && result.data.size > 0) {
                        if (messagePic == true) {
                            this.currentProfilePic = URL.createObjectURL(new Blob([result.data]));
                        } else {
                            this.$refs[ref + msg.PkMessage][0].src = URL.createObjectURL(new Blob([result.data]));
                        }
                    } else {
                        if (messagePic == true) {
                            this.currentProfilePic = require('@/assets/images/empty-profile.jpg');
                        } else {
                            this.$refs[ref + msg.PkMessage][0].src = require('@/assets/images/empty-profile.jpg');
                        }
                    }
                })
            }

            return "";
        },
        scrollToBottom() {

            setTimeout(() => {
                const element = document.querySelector('.chat-conversation-box');
                element.scrollTop = element.scrollHeight;
                element.behavior = 'smooth';
            });
        },
        GetName(msg) {

            if (store.state.FkCompany != null && msg.FkCompany == store.state.FkCompany) {
                return msg.JobAdName + ' - ' + msg.StudentName;
            } else {
                return msg.CompanyName + ' - ' + msg.JobAdName;
            }
        },
        ShowDate(item) {

            if (this.ShowDatePara.MessageGroup == null) {
                this.ShowDatePara.MessageGroup = item.FkMessageGroup;
                this.ShowDatePara.LastDateTime = item.CreatedDate;
                return true;
            } else if (this.ShowDatePara.MessageGroup != item.FkMessageGroup) {
                this.ShowDatePara.MessageGroup = item.FkMessageGroup;
                this.ShowDatePara.LastDateTime = item.CreatedDate;
                return true;
            } else if (this.ShowDatePara.LastDateTime.slice(0, 10) != item.CreatedDate.slice(0, 10)) {
                this.ShowDatePara.LastDateTime = item.CreatedDate;
                return true;
            }

            this.ShowDatePara.LastDateTime = item.CreatedDate;
            return false;
        },
        GetDate(date) {

            return cutDateTimeString(date)
        },
        sendMessage() {

            let message = {
                PkMessage: uuid.v4(),
                FkStudentProfile: this.selectedMessage.FkStudentProfile,
                FkJobAd: this.selectedMessage.FkJobAd,
                Title: this.selectedMessage.Title,
                Message: this.MessageText,
                SenderType: store.state.RegisterType == REGISTER_TYPES.STUDENT ? SENDER_TYPES.STUDENT : SENDER_TYPES.COMPANY,
                FkUserCompany: store.state.RegisterType == REGISTER_TYPES.COMPANY ? store.state.User.PkUser : null,
                CreatedDate: new Date(),
                FkMessageGroup: this.selectedMessage.FkMessageGroup,
                FkCompany: this.selectedMessage.FkCompany,
                FkOffice: this.selectedMessage.FkOffice,
            };

            this.controller.create(message).then(result => {
                if (result.IsError) {
                    this.$notify(
                        {
                            title: this.trans('ERROR'),
                            text: this.trans(result.ErrorMessage),
                            type: 'error',
                        });
                } else {
                    this.SubMessageList.push(result.Message);
                    this.MessageText = null;
                }
            })

            this.scrollToBottom();
        }
    },
    activated() {

        this.loading = true;

        this.controller.read().then(response => {
            if (!response.IsError) {
                this.MainMessagesList = response.MessagesList;
                this.SearchedMainMessagesList = response.MessagesList;
            }

        }).finally(() => {
            this.loading = false;
        });

    },
}
</script>

<style scoped>

</style>
