export const SALUTATIONS = {
  MR : 1,
  MRS : 2,
  DIVERS : 3
}


export const SALUTATIONS_DROPDOWN = [
  {
    label: 'SALUTATION-MR',
    value: SALUTATIONS.MR
  },
  {
    label: 'SALUTATION-MRS',
    value: SALUTATIONS.MRS
  },
  {
    label: 'SALUTATION-DIVERS',
    value: SALUTATIONS.DIVERS
  }
]

