<template>

    <div>
        <slot name="label">
            <label v-if="label" class="font-size-14 input-label "> {{ label }} <b v-if="required"
                                                                                  class="required-asterix">
                *</b></label>
        </slot>
        <div class="mb-0" :class="{'input-group': hasIcon}">
            <slot name="addonLeft">
        <span v-if="addonLeftIcon" class="input-group-prepend">
          <div class="input-group-text"><i :class="addonLeftIcon"></i></div>
        </span>
            </slot>
            <slot>
                <select

                    class="form-control jobineo-select"
                    v-bind="$attrs"
                    :v-model="value"
                    v-on="listeners"
                    :disabled="disabled"
                    :required="required"
                    aria-describedby="addon-right addon-left"
                    :class="[{
                      'input-group-focus ': focused,
                      'error-border-select ': value < 0 && touched,
                      'success-border-select ': value > 0 && touched,
                      'has-label ': label,
                      'has-icon ': hasIcon,
                      'minimal':true
                    }, attachedClasses]"
                >
                    <option v-if="hasNullValue" :value="null">{{ trans(nullValueText) }}</option>
                    <option v-for="option in items" :key="option.value" :value="option.value">{{
                            trans(option.label)
                        }}
                    </option>
                </select>
                <span v-if="required == true && (value < 0 || value == null)&& touched"
                      class="validation-error">{{ trans("REQUIRED") }}</span>
            </slot>
        </div>
    </div>
</template>
<script>


export default {
    inheritAttrs: false,
    name: 'base-select',
    props: {
        required: Boolean,
        hasNullValue: Boolean,
        items: Object,
        label: {
            type: String,
            description: 'Input label'
        },
        disabled: Boolean,
        nullValueText: {
            type: String

        },
        noCheckIcons: {
            type: Boolean
        },
        attachedClass: {
            type: String,
            default: ''
        },
        error: {
            type: String,
            description: 'Input error',
            default: ''
        },
        value: {
            type: [String, Number],
            description: 'Input value'
        },
        addonRightIcon: {
            type: String,
            description: 'Input icon on the right'
        },
        addonLeftIcon: {
            type: String,
            description: 'Input icon on the left'
        }
    },
    model: {
        prop: 'value',
        event: 'input'
    },
    data() {
        return {
            focused: false,
            touched: false
        };
    },
    watch: {
        value(oldValue, newValue) {
            // Reset the color green and the check icon if he left the field and the data resets
            if (oldValue != newValue && !this.focused)
                this.touched = false;
        }
    },
    computed: {
        appliedRules() {

            let firstRule = true,
                rules = "";

            if (this.$props.required === true) {
                if (!firstRule) {
                    rules = rules + "|";
                }
                rules = rules + "required";
                firstRule = false;
            }

            return rules;
        },
        attachedClasses() {
            return this.$props.attachedClass;
        },
        hasIcon() {
            return this.hasLeftAddon || this.hasRightAddon
        },
        hasLeftAddon() {
            const {addonLeft} = this.$slots;
            return (
                addonLeft !== undefined ||
                this.addonLeftIcon !== undefined
            );
        },
        hasRightAddon() {
            const {addonRight} = this.$slots;
            return (
                addonRight !== undefined ||
                this.addonRightIcon !== undefined
            );
        },
        listeners() {
            return {
                input: this.onInput,
                blur: this.onBlur,
                focus: this.onFocus,
                change: this.onChange,
            };
        }
    },
    methods: {
        onInput(evt) {
            if (!this.touched) {
                this.touched = true;
            }
            this.$emit('input', evt.target.value);
        },
        onChange(evt) {
            if (!this.touched) {
                this.touched = true;
            }
            this.$emit('select', evt.target.value);
        },
        reset() {
            this.touched = false;
            this.focused = false;

            this.$nextTick(() => {
                this.$refs.validator.reset();

            });

        },
        onFocus(evt) {
            this.focused = true;
            this.$emit('focus', evt)
        },
        onBlur(evt) {
            this.focused = false;
            this.$emit('blur', evt)
        }
    }
};
</script>
<style scoped lang="scss">

.number-width {
    max-width: 80px;
}

.postcode-width {
    max-width: 140px;
}

.font-size-14 {
    font-size: 14px !important;

}

</style>
