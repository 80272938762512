<template>
    <div class="comp-wrap">
        <div class="container">
            <!-- Outer Row -->
            <div class="row justify-content-center">
                <div class="col-xl-10 col-lg-12 col-md-9">
                    <div class="card o-hidden  shadow-lg my-5">
                        <div class="card-body p-0">
                            <!-- Nested Row within Card Body -->
                            <div class="row">
                                <div class="col-lg-6 d-none d-lg-block ">
                                    <div class="compLogo">
                                        <img src="../../assets/images/logo-jobineo.png"/>
                                    </div>
                                    <div v-show="waiting" class="spinner-container">
                                        <div class="spinner-wrap">
                                            <font-awesome-icon icon="fas fa-spin fa-circle-notch"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="p-5" style="padding-top: 90px!important;">
                                        <div class="text-center">
                                            <h1 class="h4 text-gray-900 mb-4">{{ $t('NEW-PASSWORD') }}</h1>
                                            <div :class="{'help-text':!confirmed}">
                                                <template v-if="!confirmed">
                                                    {{ $t('NEW-PASSWORD-DESC') }}<br/>
                                                    {{ passwordHelp }}
                                                </template>
                                                <template v-else>
                                                    <br/><br/> <br/><br/>
                                                    <span class="text-success">
                                                    {{ $t('NEW-PASSWORD-SUCCESS') }}
                                                    </span>
                                                    <br/><br/>
                                                </template>
                                            </div>

                                        </div>

                                        <div v-if="!confirmed">
                                            <Form ref="valForm" :validationSchema="validationSchema"
                                                  @submit="validate">

                                                <div class="row">
                                                    <div class="form-group  col-12 ">
                                                        <base-input
                                                            v-model="model.Password"
                                                            type="password"
                                                            obj-name="Password"
                                                            :required="true"
                                                            :max-length="PasswordMaxLength"
                                                            :min-length="PasswordMinLength"
                                                            :regex="PasswordRegex"
                                                            :help-text="passwordHelp"
                                                            :label="trans('PASSWORD')"
                                                            attached-class="form-control"
                                                            :placeholder="trans('PASSWORD')"
                                                            name="Password"
                                                            ref="Password"/>

                                                    </div>
                                                    <div class="form-group col-12">

                                                        <base-input
                                                            v-model="model.PasswordRepeat"
                                                            type="password"
                                                            obj-name="PasswordRepeat"
                                                            :label="trans('PASSWORD-REPEAT')"
                                                            attached-class="form-control"
                                                            :max-length="PasswordMaxLength"
                                                            :min-length="PasswordMinLength"
                                                            :regex="PasswordRegex"
                                                            :check-identical="model.Password"
                                                            :required="true"
                                                            :placeholder="trans('PASSWORD-REPEAT')"
                                                            name="PasswordRepeat"
                                                            ref="PasswordRepeat"/>
                                                    </div>
                                                </div>

                                                <div class="text-danger text-center">{{ errorMessage }}</div>

                                                <br/>

                                                <WaitingButton
                                                    size="sm"
                                                    icon="fas fa-check"
                                                    class="float-right btn-mint input-rounded"
                                                    :waiting="waiting"
                                                    native-type="submit">
                                                    {{ $t('UPDATE') }}
                                                </WaitingButton>

                                                <div class="clearfix"></div>
                                                <button ref="silentSubmit" type="submit" class="btn-silent-submit"/>

                                            </Form>
                                        </div>

                                        <div v-if="confirmed" class="text-center">
                                            <router-link to="/Login">{{ $t('CONTINUE-LOGIN') }}
                                                <font-awesome-icon icon="fas fa-arrow-right"/>
                                            </router-link>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * View: EnterNewPassword
 * Path: /EnterNewPassword/:PkUser/:HashCode
 * A view to enter a new password after password forgotten
 * This is a public landing page
 *
 */
import {restUri} from "@/data/restPaths";
import WaitingButton from "../../components/WaitingButton.vue";
import router from "@/router";
import {Form} from 'vee-validate';
import * as yup from "yup";

export default {
    name: "EnterNewPassword",
    components: {WaitingButton, Form},
    data() {
        return {
            validationSchema: yup.object({
                Password: yup.string().required(this.trans("REQUIRED-MESSAGE")).min(8, this.trans("MIN-LENGTH-START|" + 8 + "|MIN-LENGTH-END")).max(20, this.trans("MAX-LENGTH-START|" + 20 + "|MAX-LENGTH-END")),
                PasswordRepeat: yup.string().required(this.trans("REQUIRED-MESSAGE")).min(8, this.trans("MIN-LENGTH-START|" + 8 + "|MIN-LENGTH-END")).max(20, this.trans("MAX-LENGTH-START|" + 20 + "|MAX-LENGTH-END"))
            }),
            model: {
                Password: null,
                PasswordRepeat: null
            },

            waiting: false,
            confirmed: false,
            error: false,
            errorMessage: ''
        }
    },
    computed: {
        passwordHelp() {
            let text = this.$t('HLP-PASSWORD') + "\n";
            if (this.$store.state.SysSettings != null) {
                let sys = this.$store.state.SysSettings;
                if (sys.PasswordMinChar > 0) {
                    text += this.$t('PW-MIN') + sys.PasswordMinChar + this.$t('PW-MIN-CHAR') + ";\n";
                }

                if (sys.PasswordMaxChar > 0) {
                    text += this.$t('PW-MAX') + sys.PasswordMaxChar + this.$t('PW-MAX-CHAR') + ";\n";
                }

                if (sys.PasswordNeedSymbol > 0) {
                    text += this.$t('PW-MIN') + sys.PasswordNeedSymbol + this.$t('PW-MIN-SYM') + ";\n";
                }

                if (sys.PasswordMaxRepeatChar > 0) {
                    text += this.$t('PW-MAX') + sys.PasswordMaxRepeatChar + this.$t('PW-MAX-SAME') + ";\n";
                }

                if (sys.PasswordNeedNumeric > 0) {
                    text += this.$t('PW-MIN') + sys.PasswordNeedNumeric + this.$t('PW-MIN-NUMERIC') + ";\n";
                }

                if (sys.PasswordNeedLowerCase > 0) {
                    text += this.$t('PW-MIN') + sys.PasswordNeedLowerCase + this.$t('PW-MIN-LOWER') + ";\n";
                }

                if (sys.PasswordNeedUpperCase > 0) {
                    text += this.$t('PW-MIN') + sys.PasswordNeedUpperCase + this.$t('PW-MIN-UPPER') + ";\n";
                }

            }

            return text
        },
        PasswordMaxLength() {
            if (this.$store.state.SysSettings != null) {
                let sys = this.$store.state.SysSettings;
                return sys.PasswordMaxChar;
            }
            return null
        },
        PasswordMinLength() {
            if (this.$store.state.SysSettings != null) {
                let sys = this.$store.state.SysSettings;
                return sys.PasswordMinChar;
            }
            return null
        },
        PasswordRegex() {
            let regexText = '';

            // '^'
            if (this.$store.state.SysSettings != null) {
                let sys = this.$store.state.SysSettings;
                let praefix = '';
                if (sys.PasswordNeedSymbol > 0) {
                    praefix = '^'
                    regexText += '(?=.*?[@;:_,.\\-+*~§$%&=#?!]{' + sys.PasswordNeedSymbol + ',})';
                }

                if (sys.PasswordNeedNumeric > 0) {
                    praefix = '^'
                    regexText += '(?=.*?[0-9]{' + sys.PasswordNeedNumeric + ',})';
                }

                if (sys.PasswordNeedLowerCase > 0) {
                    praefix = '^';
                    regexText += '(?=.*?[a-z]{' + sys.PasswordNeedLowerCase + ',})';
                }

                if (sys.PasswordNeedUpperCase > 0) {
                    praefix = '^';
                    regexText += '(?=.*?[A-Z]{' + sys.PasswordNeedUpperCase + ',})';
                }

                regexText = praefix + regexText;

            }
            return regexText;
        }
    },
    methods: {
        validate() {
            let passwordMatch = (!this.model.Password && this.model.PasswordRepeat) || this.model.Password === this.model.PasswordRepeat;
            this.error = false;
            this.errorMessage = null;

            const regexPassword = this.PasswordRegex;

            let reg = new RegExp(regexPassword)

            const regExResult = reg.test(this.model.Password);

            this.error = !passwordMatch || !regExResult;
            this.errorMessage = passwordMatch ? "" : this.trans('PASSWORD-NO-MATCH');
            this.errorMessage = regExResult ? this.errorMessage : this.errorMessage + this.trans('PASSWORD-REGEX');
            if (this.error) {
                this.error = true;
                return Promise.resolve(false);
            }

            this.$refs.valForm.validate().then(result => {

                if (result.valid) {
                    this.changePassword();
                }
            })

        },
        changePassword() {
            this.waiting = true;
            const PkUser = this.$route.params.PkUser,
                HashCode = this.$route.params.HashCode;

            let
                passwordMatch = (!this.model.Password && this.model.PasswordRepeat) || this.model.Password === this.model.PasswordRepeat;
            this.error = false;
            this.errorMessage = '';


            this.error = !passwordMatch;
            this.errorMessage = passwordMatch ? "" : this.trans('PASSWORD-NO-MATCH');
            if (this.error)
                return Promise.resolve(false);

            this.axios.post(restUri.CHANGE_PASSWORD_FROM_LINK, {
                PkUser,
                Password: this.model.Password,
                HashCode
            }).then(result => {
                this.waiting = false;
                let data = result.data;
                if (data.IsError) {
                    this.errorMessage = this.trans(data.ErrorMessage);
                } else {
                    this.errorMessage = '';
                    this.confirmed = true;
                }

            }).catch(result => {
                this.waiting = false;
                let data = result.data;
                if (data.IsError) {
                    this.errorMessage = this.trans(data.ErrorMessage);
                }
            });
        }
    }

}
</script>

<style scoped>

.comp-wrap {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 11;
    top: 0;
    left: 0;
}

.comp-wrap > .container {
    display: flex;
    height: 100vh;
    width: 100vw;
}

.comp-wrap > .container > .row {
    align-content: center;
    flex-direction: row;
    flex-grow: 1;
}

.compLogo {
    position: relative;
    display: block;
    padding: 0;
    margin: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.compLogo > img {
    width: 85%;
    margin-left: 50px;
    margin-top: 150px;

}

.spinner-container {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.spinner-wrap {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: nowrap;
    padding-left: 1.7rem;
    padding-bottom: 1.5rem;

}

.spinner-wrap > i {
    font-size: 4em;
    color: #ffffff;
}

.h-spacer button {
    color: yellow;
    padding: 0.75rem 1.0rem;
}

.help-text {
    font-size: 12px;
    text-align: left;
    margin-bottom: 10px;
}

.text-danger {
    color: #E7515A !important;
    font-size: 1.3em;
}

.text-success {
    color: #9acccb !important;
    font-size: 1.5em;
}

</style>
