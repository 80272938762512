<template>
    <div class="display-flex">
        <div id="content-wrapper" class="d-flex flex-column" style="width: 100%; padding: 10px;">
            <router-view v-slot="{ Component }">
                <transition name="fade">
                    <keep-alive>
                        <component :is="Component"/>
                    </keep-alive>
                </transition>
            </router-view>
        </div>
    </div>

</template>

<script>

export default {
    name: "UserAccount",
    data() {
        return {}
    },
}
</script>

<style scoped>
</style>
