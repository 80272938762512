<template>

    <div>
        <slot name="label">
            <label v-if="label" class="font-size-14 input-label "> {{ label }} <b v-if="required"
                                                                                  class="required-asterix">
                *</b></label>
        </slot>
        <div class="mb-0" :class="{'input-group': hasIcon}">
            <slot name="addonLeft">
        <span v-if="addonLeftIcon" class="input-group-prepend">
          <div class="input-group-text"><i :class="addonLeftIcon"></i></div>
        </span>
            </slot>
            <slot>
                <select
                    class="form-control jobineo-select "
                    v-bind="$attrs"
                    v-model="model"
                    v-on="listeners"
                    :disabled="disabled"
                    :required="required"
                    aria-describedby="addon-right addon-left"
                    :class="[{
                      'input-group-focus ': focused,
                      'error-border-select ': !(modelValue > 0) && touched,
                      'success-border-select ': modelValue > 0 && touched,
                      'has-label ': label,
                      'has-icon ': hasIcon,
                      'minimal':true
                    }, attachedClasses]"
                >
                    <option v-if="hasNullValue" :value="null" disabled>{{ trans(nullValueText) }}</option>
                    <option v-for="option in options" :key="option.value" :value="option.value">{{
                            trans(option.label)
                        }}
                    </option>
                </select>
                <span v-if="required === true && !(modelValue > 0)&& touched"
                      class="errors">{{ trans("REQUIRED-MESSAGE") }}</span>
            </slot>
        </div>
    </div>
</template>

<script>
import {SALUTATIONS_DROPDOWN} from "@/data/salutations";

export default {
    name: "SalutationsDropdown",
    expose: ['reset', 'setError'],
    props: {
        required: Boolean,
        hasNullValue: Boolean,
        disabled: Boolean,
        items: Array,
        label: {
            type: String,
            description: 'Input label'
        },
        nullValueText: {
            type: String

        },
        attachedClass: {
            type: String,
            default: ''
        },
        error: {
            type: String,
            description: 'Input error',
            default: ''
        },
        modelValue: {
            type: [String, Number],
            description: 'Input value'
        },
        addonRightIcon: {
            type: String,
            description: 'Input icon on the right'
        },
        noCheckIcons: {
            type: Boolean
        },
        addonLeftIcon: {
            type: String,
            description: 'Input icon on the left'
        }
    },
    model: {
        prop: 'modelValue',
        event: 'update:modelValue'
    },
    data() {
        return {
            val: null,
            options: SALUTATIONS_DROPDOWN,
            focused: false,
            touched: false
        }
    },
    computed: {
        model: {
            get() {
                return this.$props.modelValue
            },
            set(v) {
                this.$emit('update:modelValue', v);
            }
        },
        attachedClasses() {
            return this.$props.attachedClass;
        },
        hasIcon() {
            return this.hasLeftAddon || this.hasRightAddon
        },
        hasLeftAddon() {
            const {addonLeft} = this.$slots;
            return (
                addonLeft !== undefined ||
                this.addonLeftIcon !== undefined
            );
        },
        hasRightAddon() {
            const {addonRight} = this.$slots;
            return (
                addonRight !== undefined ||
                this.addonRightIcon !== undefined
            );
        },
        listeners() {
            return {
                input: this.onInput,
                blur: this.onBlur,
                focus: this.onFocus,
                change: this.onChange,
            };
        }
    },
    watch: {
        modelValue(oldValue, newValue) {

            // Reset the color green and the check icon if he left the field and the data resets
            if (oldValue != newValue && !this.focused)
                this.touched = false;
        }
    },
    methods: {
        reset() {
            this.val = null;
            this.touched = false;
        },
        setError() {
            this.val = null;
            this.touched = true;
        },
        onInput(evt) {
            if (!this.touched) {
                this.touched = true;
            }

            this.$emit('update:modelValue', evt.target.value);
        },
        onChange(evt) {
            if (!this.touched) {
                this.touched = true;
            }

            this.$emit('select', evt.target.value);
        },
        onFocus(evt) {
            this.focused = true;
            this.$emit('focus', evt)
        },
        onBlur(evt) {
            this.focused = false;
            this.$emit('blur', evt)
        }
    }
}
</script>

<style scoped lang="scss">
.minimal {
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px,
    5px 5px,
    1px 1.5em;
    background-repeat: no-repeat;
}

.jobineo-select {
    background-color: #ffffff;
}

.purple {

    .jobineo-select {
        background-color: #1b2e4b;
    }

}

.dark {

    .jobineo-select {
        background-color: #ffffff;
        color: #000000;
    }

}

</style>
