<template>
    <div class="container-jobineo">
        <div class="section-heading">
            <router-link to="/" class="no-decoration gray-color">
                <font-awesome-icon icon="fas fa-home"/>
                {{ $t('DASHBOARD') }}
            </router-link>
            <span class="no-decoration breadcrumb-decorate-yellow">
                <font-awesome-icon icon="fas fa-angle-right"/> &nbsp;
                <font-awesome-icon icon="fas fa-magnifying-glass"/>
                {{ trans('SEARCH') }}
            </span>
        </div>
        <div class="header-picture-company" v-if="IsCompany">
            <h1 class="header-text-company">{{ trans('SEARCH-TEXT-COMPANY') }}</h1>
            <div class="row margin-left">
                <div class="col-md-6 col-lg-4 col-sm-12 form-group">
                    <data-dropdown ref="JobType"
                                   v-model="JobType"
                                   :label="trans('JOB-TYPE')"
                                   :required="true"
                                   :no-check-icons="true"
                                   :items="JobTypeList"
                                   :null-value-text="trans('SELECT-JOB-TYPE')"
                                   :has-null-value="true"
                                   attached-class="form-control"
                                   name="JobType"/>
                </div>

                <div class="form-group col-md-6 col-lg-4 col-sm-12">
                    <postcode-dropdown
                        v-model="PostCode"
                        :items="PostCodeList"
                        obj-name="PostCode"
                        :required="true"
                        :label="trans('CITY')"
                        attached-class="form-control"
                        name="PostCode"
                        ref="PostCode"/>
                </div>
            </div>
            <div class="row margin-left">
                <div class="form-group col-md-3 col-lg-2 col-sm-12 mt-btn">
                    <WaitingButton
                        size="sm"
                        icon="fas fa-magnifying-glass"
                        class="float-left btn btn-mint input-rounded "
                        :waiting="loading"
                        @click.prevent="searchNow">
                        {{ $t('SEARCH-START') }}
                    </WaitingButton>
                </div>
                <div class="form-group col-md-3 col-lg-2 col-sm-12 mt-btn">
                    <WaitingButton
                        size="sm"
                        icon="fas fa-trash"
                        class="float-left btn btn-yellow input-rounded "
                        :waiting="loading"
                        @click.prevent="resetSearch">
                        {{ $t('SEARCH-RESET') }}
                    </WaitingButton>
                </div>
            </div>
        </div>
        <div class="header-picture-company-second" v-if="IsCompany">
            <div class="row">
                <div class="col-md-3">
                    <div class="container-jobineo">
                        <div class="row">
                            <div id="basic" class="col-lg-12 col-sm-12 col-12 layout-spacing">
                                <div class="statbox panel box box-shadow search-box">
                                    <div class="custom-table">
                                        <div class="tree-header">
                                            <div class="row">
                                                <span class="upp-case">{{ trans('STUDENTS') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <h3 class="header-text-company-second">{{ trans('SEARCH-TEXT-COMPANY-2') }}</h3>
                </div>
            </div>
        </div>
        <div class="header-picture-student" v-if="!IsCompany">
            <h1 class="header-text-student">{{ trans('SEARCH-TEXT-STUDENT') }}</h1>
            <div class="row margin-left margin-right">
                <div class="col-md-6 col-lg-4 col-sm-12 form-group">
                    <data-dropdown ref="JobType"
                                   v-model="JobType"
                                   :label="trans('JOB-TYPE')"
                                   :required="false"
                                   :no-check-icons="true"
                                   :items="JobTypeList"
                                   :null-value-text="trans('SELECT-JOB-TYPE')"
                                   :has-null-value="true"
                                   attached-class="form-control"
                                   name="JobType"/>
                </div>
                <div class="form-group col-md-6 col-lg-4 col-sm-12">
                    <base-input ref="SearchText"
                                v-model="SearchText"
                                :label="trans('SEARCH-TEXT')"
                                type="text"
                                :required="false"
                                obj-name="SearchText"
                                attached-class="form-control" placeholder="..."/>
                </div>
                <div class="form-group col-md-6 col-lg-4 col-sm-12">
                    <postcode-dropdown
                        v-model="PostCode"
                        :items="PostCodeList"
                        obj-name="PostCode"
                        :required="true"
                        :label="trans('CITY')"
                        attached-class="form-control"
                        name="PostCode"
                        ref="PostCode"/>
                </div>
            </div>
            <div class="row margin-left">
                <div class="form-group col-md-3 col-lg-2 col-sm-12 mt-btn">
                    <WaitingButton
                        size="sm"
                        icon="fas fa-magnifying-glass"
                        class="float-left btn btn-mint input-rounded "
                        :waiting="loading"
                        @click.prevent="searchNow">
                        {{ $t('SEARCH-START') }}
                    </WaitingButton>
                </div>
                <div class="form-group col-md-3 col-lg-2 col-sm-12 mt-btn">
                    <WaitingButton
                        size="sm"
                        icon="fas fa-trash"
                        class="float-left btn btn-yellow input-rounded "
                        :waiting="loading"
                        @click.prevent="resetSearch">
                        {{ $t('SEARCH-RESET') }}
                    </WaitingButton>
                </div>
            </div>
        </div>
        <div class="top-company" v-if="!IsCompany">
            <div class="clearfix underlined-b-10"></div>
            <h2>{{ trans('TOP-COMPANY-TEXT') }}</h2>
            <div class="row">
                <template v-for="(comp, idx) in TopCompanies" :key="idx">
                    <div class="col-lg-2 col-md-2 col-sm-6 col-6 top-company-col"><img
                        :ref="'picComp_'+comp.PkCompany"
                        @click="loadCompany(comp)"
                        :src="require('@/assets/images/empty-profile.jpg')"
                        alt="avatar" class="profile-picture-top companyPic col-lg-12 col-md-12 col-sm-12 col-12"/>
                        <span class="CompanyName"> {{ GetTopCompanyImage(comp) }}</span>
                    </div>
                </template>
            </div>
            <div class="clearfix underlined-b-10"></div>
        </div>
        <div class="header-picture-company-second" v-if="!IsCompany">
            <div class="row">
                <div class="col-md-3">
                    <div class="container-jobineo">
                        <div class="row">
                            <div id="basic" class="col-lg-12 col-sm-12 col-12 layout-spacing">
                                <div class="statbox panel box box-shadow search-box">
                                    <div class="custom-table">
                                        <div class="tree-header">
                                            <div class="row">
                                                <span v-if="SearchType==1" class="upp-case">{{
                                                        trans('COMPANY-SEARCH')
                                                    }}</span>
                                                <span v-if="SearchType==2" class="upp-case">{{
                                                        trans('JOB-AD-SEARCH')
                                                    }}</span>
                                                <span v-if="SearchType==4" class="upp-case">{{
                                                        trans('SCHOOL-SEARCH')
                                                    }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <h3 class="header-text-company-second">{{ trans('SEARCH-TEXT-STUDENT-2') }}</h3>
                </div>
            </div>
        </div>

        <div class="row">

            <div class="col-lg-3">
                <div class="container-jobineo" v-if="!IsCompany">
                    <div class="row">
                        <div id="basic" class="col-lg-12 col-sm-12 col-12 layout-spacing">
                            <div class="statbox panel box box-shadow search-box">
                                <div class="custom-table">
                                    <div class="tree-header">
                                        <h4 class="black">{{ trans('SEARCH-TYPE') }}</h4>
                                        <div class="row">
                                            <div class="form-group col-lg-12 search-box-label">
                                                <div
                                                    class=" radio-classic text-color radio-warning custom-control custom-radio">
                                                    <input type="radio" :value="1" v-model="SearchType"
                                                           class="custom-control-input"
                                                           id="radioCompany" name="radioCompany"/>
                                                    <label class="custom-control-label" for="radioCompany">
                                                        {{ trans('COMPANY-SEARCH') }} </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="form-group col-lg-12 search-box-label">
                                                <div
                                                    class="radio-classic text-color radio-warning custom-control custom-radio">
                                                    <input type="radio" :value="2" v-model="SearchType"
                                                           class="custom-control-input"
                                                           id="radioJobAd" name="radioJobAd"/>
                                                    <label class="custom-control-label" for="radioJobAd">
                                                        {{ trans('SEARCH-JOB-AD') }} </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="form-group col-lg-12 search-box-label">
                                                <div
                                                    class="radio-classic text-color radio-warning custom-control custom-radio">
                                                    <input type="radio" :value="4" v-model="SearchType"
                                                           class="custom-control-input"
                                                           id="radioSchool" name="radioJobAd"/>
                                                    <label class="custom-control-label" for="radioSchool">
                                                        {{ trans('SEARCH-SCHOOL') }} </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-jobineo">
                    <div class="row layout-top-spacing">
                        <div id="basic" class="col-lg-12 col-sm-12 col-12 layout-spacing">
                            <div class="statbox panel box box-shadow search-box">
                                <div class="custom-table">
                                    <div class="tree-header">
                                        <div class="row">

                                            <div class="form-group col-lg-6 search-box-label">
                                                <base-input ref="Radius"
                                                            v-model="Radius"
                                                            :label="trans('SEARCH-RADIUS-KM')"
                                                            type="number"
                                                            :min="0"
                                                            :step="1"
                                                            :required="false"
                                                            obj-name="Radius"
                                                            attached-class="form-control" placeholder="..."/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-jobineo">
                    <div class="row">
                        <div id="basic" class="col-lg-12 col-sm-12 col-12 layout-spacing">
                            <div class="statbox panel box box-shadow search-box">
                                <div class="custom-table">
                                    <div class="tree-header">
                                        <div class="row">

                                            <div class="col-lg-12 form-group search-box-label">
                                                <data-dropdown ref="JobFieldId"
                                                               v-model="JobFieldId"
                                                               :label="trans(GetStudyJobText)"
                                                               :no-check-icons="true"
                                                               :disabled="JobType==null"
                                                               :required="false"
                                                               :items="JobFieldIdList"
                                                               :null-value-text="trans(GetStudyJobSelectText)"
                                                               :has-null-value="true"
                                                               attached-class="form-control"
                                                               name="JobFieldId"/>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-12 form-group search-box-label" v-if="ShowJobField">

                                                <data-dropdown ref="JobId"
                                                               v-model="JobId"
                                                               :label="trans('JOB-ID')"
                                                               :no-check-icons="true"
                                                               :required="false"
                                                               :disabled="JobType==null"
                                                               :items="JobIdList"
                                                               :null-value-text="trans('SELECT-JOB-ID')"
                                                               :has-null-value="true"
                                                               attached-class="form-control"
                                                               name="JobId"/>
                                            </div>

                                        </div>

                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-jobineo" v-if="IsCompany">
                    <div class="row">
                        <div id="basic" class="col-lg-12 col-sm-12 col-12 layout-spacing">
                            <div class="statbox panel box box-shadow search-box">
                                <div class="custom-table">
                                    <div class="tree-header">
                                        <div class="row">
                                            <div class="form-group col-lg-5 search-box-label">
                                                <base-input ref="AverageMark"
                                                            v-model="AverageMark"
                                                            :label="trans('AVERAGE-MARK')"
                                                            type="number"
                                                            :min="0"
                                                            :step="0.5"
                                                            :required="false"
                                                            obj-name="AverageMark"
                                                            attached-class="form-control" placeholder="..."/>
                                            </div>
                                        </div>

                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-jobineo" v-if="IsCompany">
                    <div class="row">
                        <div id="basic" class="col-lg-12 col-sm-12 col-12 layout-spacing">
                            <div class="statbox panel box box-shadow search-box">
                                <div class="custom-table">
                                    <div class="tree-header">
                                        <div class="row">
                                            <div class="form-group col-lg-12 search-box-label">
                                                <data-dropdown ref="QualificationId"
                                                               v-model="QualificationId"
                                                               :label="trans('JOB-AD-QUALIFICATION')"
                                                               :no-check-icons="true"
                                                               :required="false"
                                                               :items="QualificationList"
                                                               :null-value-text="trans('SELECT-JOB-AD-QUALIFICATION')"
                                                               :has-null-value="true"
                                                               attached-class="form-control"
                                                               name="QualificationId"/>
                                            </div>
                                        </div>

                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-jobineo" v-if="IsCompany">
                    <div class="row">
                        <div id="basic" class="col-lg-12 col-sm-12 col-12 layout-spacing">
                            <div class="statbox panel box box-shadow search-box">
                                <div class="custom-table">
                                    <div class="tree-header">
                                        <div class="row">
                                            <div class="form-group col-lg-12 search-box-label">
                                                <base-datetime
                                                    :label="trans('AVAILABLE-FROM')"
                                                    obj-name="AvailableFrom"
                                                    name="AvailableFrom"
                                                    :value="AvailableFrom"
                                                    :onchange="AvailableFromChange"
                                                    :place-holder="trans('PLEASE-SELECT-DATE')"
                                                    date-time-type="date"
                                                    :date-time-format="DateTimeFormat"
                                                    :date-time-format-value="DateTimeFormatValue"
                                                ></base-datetime>
                                            </div>
                                        </div>

                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-jobineo" v-if="IsCompany">
                    <div class="row">
                        <div id="basic" class="col-lg-12 col-sm-12 col-12 layout-spacing">
                            <div class="statbox panel box box-shadow search-box">
                                <div class="custom-table">
                                    <div class="tree-header">
                                        <div class="row">
                                            <div class="form-group col-lg-12 search-box-label">
                                                <div class="margin-top-40">
                                                    <div
                                                        class="checkbox-outline-primary custom-control custom-checkbox">
                                                        <base-checkbox
                                                            id="CarAvailable"
                                                            :required="false"
                                                            class="custom-control-input"
                                                            :checked="CarAvailable"
                                                            v-model="CarAvailable"
                                                            @input="setCarAvailable"
                                                            :label="trans('CAR-AVAILABLE')"/>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-jobineo" v-if="!IsCompany">
                    <div class="row">
                        <div id="basic" class="col-lg-12 col-sm-12 col-12 layout-spacing">
                            <div class="statbox panel box box-shadow search-box">
                                <div class="custom-table">
                                    <div class="tree-header">
                                        <h4 class="black">{{ trans('WORK-TIME') }}</h4>
                                        <div class="row">
                                            <div class="form-group col-lg-12 search-box-label">
                                                <div class="margin-top-40">
                                                    <div
                                                        class="checkbox-outline-primary custom-control custom-checkbox">
                                                        <base-checkbox
                                                            id="FullTimeFull"
                                                            :required="false"
                                                            class="custom-control-input"
                                                            :checked="FullTimeFull"
                                                            v-model="FullTimeFull"
                                                            @input="setFullTimeFull"
                                                            :label="trans('FULL-TIME')"/>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="form-group col-lg-12 search-box-label">
                                                <div class="margin-top-40">
                                                    <div
                                                        class="checkbox-outline-primary custom-control custom-checkbox">
                                                        <base-checkbox
                                                            id="FullTimeNot"
                                                            :required="false"
                                                            class="custom-control-input"
                                                            :checked="FullTimeNot"
                                                            v-model="FullTimeNot"
                                                            @input="setFullTimeNot"
                                                            :label="trans('NOT-FULL-TIME')"/>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-jobineo" v-if="!IsCompany">
                    <div class="row">
                        <div id="basic" class="col-lg-12 col-sm-12 col-12 layout-spacing">
                            <div class="statbox panel box box-shadow search-box">
                                <div class="custom-table">
                                    <div class="tree-header">
                                        <div class="row">
                                            <div class="form-group col-lg-12 search-box-label">
                                                <base-datetime
                                                    :label="trans('ENTRY-DATE')"
                                                    obj-name="EntryDate"
                                                    name="EntryDate"
                                                    :value="EntryDate"
                                                    :onchange="EntryDateChange"
                                                    :place-holder="trans('PLEASE-SELECT-DATE')"
                                                    date-time-type="date"
                                                    :date-time-format="DateTimeFormat"
                                                    :date-time-format-value="DateTimeFormatValue"
                                                ></base-datetime>
                                            </div>
                                        </div>

                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-jobineo" v-if="!IsCompany">
                    <div class="row">
                        <div id="basic" class="col-lg-12 col-sm-12 col-12 layout-spacing">
                            <div class="statbox panel box box-shadow search-box">
                                <div class="custom-table">
                                    <div class="tree-header">
                                        <div class="row">
                                            <div class="form-group col-lg-12 search-box-label">
                                                <base-datetime
                                                    :label="trans('DEAD-LINE')"
                                                    obj-name="DeadLine"
                                                    name="DeadLine"
                                                    :value="DeadLine"
                                                    :onchange="DeadLineChange"
                                                    :place-holder="trans('PLEASE-SELECT-DATE')"
                                                    date-time-type="date"
                                                    :date-time-format="DateTimeFormat"
                                                    :date-time-format-value="DateTimeFormatValue"
                                                ></base-datetime>
                                            </div>
                                        </div>

                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-jobineo" v-if="!IsCompany">
                    <div class="row">
                        <div id="basic" class="col-lg-12 col-sm-12 col-12 layout-spacing">
                            <div class="statbox panel box box-shadow search-box">
                                <div class="custom-table">
                                    <div class="tree-header">
                                        <div class="row">
                                            <div class="form-group col-lg-12 search-box-label">
                                                <data-dropdown ref="SalaryType"
                                                               v-model="SalaryType"
                                                               :label="trans('WISH-SALARY-TYPE')"
                                                               :required="false"
                                                               :no-check-icons="true"
                                                               :items="SalaryTypeList"
                                                               :null-value-text="trans('SELECT-SALARY-TYPE')"
                                                               :has-null-value="true"
                                                               attached-class="form-control"
                                                               name="SalaryType"/>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="form-group col-lg-12 search-box-label">
                                                <base-input ref="MinSalary"
                                                            v-model="MinSalary"
                                                            :label="trans('WISH-SALARY')"
                                                            :max="500000"
                                                            :step="1.0"
                                                            :min="1.0"
                                                            type="number"
                                                            :required="false"
                                                            obj-name="MinSalary"
                                                            attached-class="form-control" placeholder="..."/>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-jobineo" v-if="IsCompany">
                    <div class="row">
                        <div id="basic" class="col-lg-12 col-sm-12 col-12 layout-spacing">
                            <div class="statbox panel box box-shadow search-box">
                                <div class="custom-table">
                                    <div class="tree-header">
                                        <div class="row">
                                            <div class="form-group col-lg-12 search-box-label">
                                                <div class="margin-top-40">
                                                    <div
                                                        class="checkbox-outline-primary custom-control custom-checkbox">
                                                        <base-checkbox
                                                            id="ReadyToMove"
                                                            :required="false"
                                                            class="custom-control-input"
                                                            :checked="ReadyToMove"
                                                            v-model="ReadyToMove"
                                                            @input="setReadyToMove"
                                                            :label="trans('READY-TO-MOVE')"/>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div class="col-lg-9" v-if="PaginatedList!= null && PaginatedList.length >0">
                <div class="row layout-top-spacing">
                    <div id="basic" class="col-lg-12 col-sm-12 col-12 layout-spacing">
                        <h4>{{ SearchList.length }} {{ trans('SEARCH-HITS') }}</h4>
                        <div class="custom-table">
                            <template v-for="(item, index) in PaginatedList" :key="index">
                                <div class="statbox panel box box-shadow">
                                    <div class="custom-table">
                                        <div class="tree-header">
                                            <div class="row" v-if="!IsCompany&&item.PkOffice!= null">
                                                <div class="col-lg-2">
                                                    <div class="text-center user-info">
                                                        <img
                                                            :ref="'picOff_'+item.PkOffice"
                                                            :src="require('@/assets/images/empty-profile.jpg')"
                                                            alt="avatar"
                                                            class="col-lg-12 col-md-12 col-sm-12 col-12"/>
                                                        {{ GetImageOff(item) }}
                                                    </div>
                                                    <div class="mt-4 text-center">
                                                        <WaitingButton
                                                            size="sm"
                                                            icon="fas fa-building"
                                                            class=" btn btn-yellow input-rounded "
                                                            :waiting="loading"
                                                            @click.prevent="openProfileOffice(item)">
                                                            {{ $t('OPEN-PROFILE') }}
                                                        </WaitingButton>
                                                    </div>
                                                </div>
                                                <div class="col-lg-10">
                                                    <div class="row">
                                                        <div class="form-group col-lg-12 ">
                                                            <label class="font-size-14 input-label">
                                                                <font-awesome-icon
                                                                    icon="fas fa-magnifying-glass"></font-awesome-icon>
                                                                {{ item.OfficeName }} - {{
                                                                    GetJobTypesOffice(item)
                                                                }}
                                                            </label>
                                                        </div>

                                                    </div>
                                                    <div class="row">
                                                        <div class="form-group col-lg-12 ">
                                                            <label class="font-size-14 input-label">
                                                                {{ GetJobNamesOffice(item) }}
                                                            </label>
                                                        </div>

                                                    </div>
                                                    <div class="row">
                                                        <div
                                                            class="form-group col-lg-12 font-size-14 input-label search-box-label bubble-div">
                                                            <template
                                                                v-for="(benefit, idx) in item.OfficeBenefitList.slice(0, 3)"
                                                                :key="idx">
                                                            <span class="bubbles">
                                                                   {{ benefit.Benefit }}
                                                                </span>
                                                            </template>
                                                            <template
                                                                v-for="(keyword, idx) in item.OfficeKeywordList.slice(0, 3)"
                                                                :key="idx">
                                                            <span class="bubbles">
                                                                   {{ keyword.Keyword }}
                                                                </span>
                                                            </template>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="form-group col-lg-4 ">
                                                            <label class="font-size-14 input-label">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    stroke-width="2"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    class="feather feather-map-pin"
                                                                >
                                                                    <path
                                                                        d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                                                    <circle cx="12" cy="10" r="3"></circle>
                                                                </svg>
                                                                {{ item.PostCode }} {{ item.City }}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" v-if="!IsCompany&&item.PkJobAd!= null">
                                                <div class="col-lg-2 col-md-2 col-sm-2">
                                                    <div class="text-center user-info">
                                                        <img
                                                            :ref="'picJob_'+item.PkJobAd"
                                                            :src="require('@/assets/images/empty-profile.jpg')"
                                                            alt="avatar"
                                                            class="col-lg-12 col-md-12 col-sm-12 col-xs-12"/>
                                                        {{ GetImageJob(item) }}
                                                    </div>
                                                    <div class="mt-4 text-center">
                                                        <WaitingButton
                                                            size="sm"
                                                            icon="fas fa-user"
                                                            class=" btn btn-yellow input-rounded "
                                                            :waiting="loading"
                                                            @click.prevent="openProfileJobAd(item)">
                                                            {{ $t('OPEN-PROFILE') }}
                                                        </WaitingButton>
                                                    </div>
                                                </div>
                                                <div class="col-lg-10">
                                                    <div class="row">
                                                        <div class="form-group col-lg-12 ">
                                                            <label class="font-size-14 input-label">
                                                                {{ GetJobTypesJobAd(item) }} | {{
                                                                    GetJobNamesJobAd(item)
                                                                }}
                                                            </label>
                                                        </div>

                                                    </div>
                                                    <div class="row">
                                                        <div class="form-group col-lg-12 ">
                                                            <label class="font-size-14 input-label">
                                                                {{ item.AdName }}
                                                            </label>
                                                        </div>

                                                    </div>
                                                    <div class="row">
                                                        <div
                                                            class="form-group col-lg-12 font-size-14 input-label search-box-label bubble-div">
                                                               
                                                            <span class="bubbles"
                                                                  v-if="GetStartDate(item)!==false">
                                                                    {{ GetStartDate(item) }} 
                                                                </span>

                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="form-group col-lg-4 ">
                                                            <label class="font-size-14 input-label">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    stroke-width="2"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    class="feather feather-map-pin"
                                                                >
                                                                    <path
                                                                        d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                                                    <circle cx="12" cy="10" r="3"></circle>
                                                                </svg>
                                                                {{ item.Office.PostCode }} {{ item.Office.City }}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="row" v-if="IsCompany">
                                                <div class="col-lg-2">
                                                    <div class="text-center user-info">
                                                        <img
                                                            :ref="'pic_'+item.PkStudentProfile"
                                                            :src="require('@/assets/images/empty-profile.jpg')"
                                                            alt="avatar"
                                                            class="col-lg-12 col-md-12 col-sm-12 col-12"/>
                                                        {{ GetImage(item) }}
                                                    </div>
                                                    <div class="mt-4 text-center">
                                                        <WaitingButton
                                                            size="sm"
                                                            icon="fas fa-user"
                                                            class=" btn btn-yellow input-rounded "
                                                            :waiting="loading"
                                                            @click.prevent="openProfile(item)">
                                                            {{ $t('OPEN-PROFILE') }}
                                                        </WaitingButton>
                                                    </div>
                                                </div>
                                                <div class="col-lg-10">
                                                    <div class="row">
                                                        <div class="form-group col-lg-12 ">
                                                            <label class="font-size-14 input-label">
                                                                <font-awesome-icon
                                                                    icon="fas fa-magnifying-glass"></font-awesome-icon>
                                                                {{ GetJobTypes(item) }}
                                                            </label>
                                                        </div>

                                                    </div>
                                                    <div class="row">
                                                        <div class="form-group col-lg-12 ">
                                                            <label class="font-size-14 input-label">

                                                                {{ GetJobNames(item) }}
                                                            </label>
                                                        </div>

                                                    </div>
                                                    <div class="row">
                                                        <div
                                                            class="form-group col-lg-12 font-size-14 input-label search-box-label bubble-div">
                                                               
                                                            <span class="bubbles"
                                                                  v-if="GetAvailableFromText(item)!==false">
                                                                    {{ GetAvailableFromText(item) }} 
                                                                </span>
                                                            <span class="bubbles">
                                                                    {{ trans('AVERAGE-MARK') }} {{
                                                                    item.StudentGraduation != null ? item.StudentGraduation.Average : ""
                                                                }}
                                                                </span>
                                                            <span class="bubbles">
                                                                    {{ GetHighestGraduation(item) }}
                                                                </span>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="form-group col-lg-4 ">
                                                            <label class="font-size-14 input-label">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    stroke-width="2"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    class="feather feather-map-pin"
                                                                >
                                                                    <path
                                                                        d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                                                    <circle cx="12" cy="10" r="3"></circle>
                                                                </svg>
                                                                {{ item.PostCode }} {{ item.City }}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </template>

                            <div class="paginating-container pagination-solid flex-column align-items-center mt-4">
                                <ul role="menubar" aria-disabled="false" aria-label="Pagination"
                                    class="pagination rounded b-pagination">
                                    <template v-for="(item, index) in CountPages" :key="index">
                                        <li role="presentation" class="page-item"
                                            :class="(CurrentPageIndex===(item.PageNo -1)?' active':'')">
                                            <button role="menuitemradio" @click="nextPage(item.PageNo)"
                                                    type="button"
                                                    :aria-checked="CurrentPageIndex===(item.PageNo -1)"
                                                    :aria-posinset="item.PageNo" :aria-setsize="CountPages.length"
                                                    tabindex="-1" class="page-link">
                                                {{ item.PageNo }}
                                            </button>
                                        </li>
                                    </template>
                                </ul>
                            </div>

                            <div v-if="error" class="error-alert-wrap">
                                <font-awesome-icon icon="fas fa-exclamation-triangle"
                                                   class="float-right-margin"/>
                                {{ trans(errorMessage) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

import store from "@/store";
import {SearchController} from "@/controllers/search.controller";
import {REGISTER_TYPES} from "@/data/RegisterTypes";
import {SEARCH_TYPES} from "@/data/searchTypes";
import PostcodeDropdown from "@/components/dropdowns/PostCodeDropdown.vue";
import DataDropdown from "@/components/dropdowns/DataDropdown.vue";
import {JOB_TYPES} from "@/data/jobTypes";
import lodash from "lodash";
import WaitingButton from "@/components/WaitingButton.vue";
import router from "@/router";
import {cutDateString} from "@/utils/convertDateTime";
import {ProfileController} from "@/controllers/profile.controller";
import {CompanyController} from "@/controllers/company.controller";
import {JobAdController} from "@/controllers/jobad.controller";
import {OfficeController} from "@/controllers/office.controller";

export default {
    name: "SearchView",
    components: {WaitingButton, DataDropdown, PostcodeDropdown},
    data() {
        return {
            TopCompanies: [],
            CurrentPageIndex: 0,
            Radius: 0,
            JobId: null,
            JobType: null,
            CarAvailable: false,
            ReadyToMove: false,
            JobFieldId: null,
            QualificationId: null,
            AverageMark: null,
            PostCode: null,
            EntryDate: null,
            Fulltime: null,
            DeadLine: null,
            FullTimeNot: false,
            FullTimeFull: false,
            MinSalary: null,
            SalaryType: null,
            AvailableFrom: null,
            SearchCompany: true,
            SearchJobAd: true,
            SysPostCodeList: [],
            SearchText: null,
            controller: new SearchController(this),
            profileController: new ProfileController(this),
            companyController: new CompanyController(this),
            officeController: new OfficeController(this),
            jobAdController: new JobAdController(this),
            SearchList: [],
            selectedItem: null,
            loading: false,
            SearchType: store.state.RegisterType == REGISTER_TYPES.STUDENT ? SEARCH_TYPES.COMPANY : SEARCH_TYPES.STUDENT,
            error: false,
            errorMessage: ''
        }
    },
    watch: {
        JobType(newValue, oldValue) {
            if (newValue == null) {
                this.JobId = null;
                this.JobFieldId = null;
            }
        },
        SearchType(newValue, oldValue) {
            if (newValue != oldValue && (newValue == SEARCH_TYPES.COMPANY || newValue == SEARCH_TYPES.JOB_AD) && oldValue != null) {

                if (this.JobType == null || this.JobType <= 0) {
                    return;
                }

                if (this.PostCode == null || this.PostCode.PostCode == null) {
                    return;
                }
                this.searchNow()
            }
        }
    },
    computed: {
        PaginatedList() {
            if (this.SearchList == null || this.SearchList.length <= 0)
                return [];
            return this.SearchList.slice(this.indexStart, this.indexEnd);
        },
        SalaryTypeList() {
            return store.state.Lookups.SalaryTypesLookup != null && Array.isArray(store.state.Lookups.SalaryTypesLookup) ? store.state.Lookups.SalaryTypesLookup : [];
        },
        indexStart() {
            return (this.CurrentPageIndex) * 10;
        },
        indexEnd() {
            return (this.indexStart) + 10;
        },
        CountPages() {
            if (this.SearchList == null || this.SearchList.length <= 0)
                return [];

            let pages = [];
            for (let t = 0, l = Math.ceil(this.SearchList.length / 10); t < l; t++) {
                pages.push({PageNo: t + 1})
            }
            return pages;
        },
        DateTimeFormat() {
            return "d.m.Y";
        },
        DateTimeFormatValue() {
            return "Y-m-dT00:00:00.000\\Z";
        },
        IsCompany() {
            return this.SearchType == SEARCH_TYPES.STUDENT
        },
        QualificationList() {
            return this.$store.state.Lookups.GraduationsLookup != null && Array.isArray(this.$store.state.Lookups.GraduationsLookup) ? this.$store.state.Lookups.GraduationsLookup : [];
        },
        GetStudyJobText() {
            return this.JobType == JOB_TYPES.DUAL_STUDY ? 'STUDY-FIELD' : 'JOB-FIELD'
        },
        GetStudyJobSelectText() {
            return this.JobType == JOB_TYPES.DUAL_STUDY ? 'SELECT-STUDY-FIELD' : 'SELECT-JOB-FIELD'
        },
        ShowJobField() {
            return this.JobType == null || this.JobType != JOB_TYPES.DUAL_STUDY
        },
        JobTypeList() {
            return this.$store.state.Lookups.JobAdTypesLookup != null && Array.isArray(this.$store.state.Lookups.JobAdTypesLookup) ? this.$store.state.Lookups.JobAdTypesLookup : [];
        },
        JobFieldIdList() {
            if (this.JobType === null)
                return [];
            else if (this.JobType == JOB_TYPES.DUAL_STUDY)
                return this.$store.state.Lookups.StudyFieldsLookup != null && Array.isArray(this.$store.state.Lookups.StudyFieldsLookup) ? this.$store.state.Lookups.StudyFieldsLookup : [];
            else
                return this.$store.state.Lookups.JobFieldsLookup != null && Array.isArray(this.$store.state.Lookups.JobFieldsLookup) ? this.$store.state.Lookups.JobFieldsLookup : [];
        },
        JobIdList() {
            if (this.JobType === null)
                return [];
            else if (this.JobType == JOB_TYPES.DUAL_STUDY)
                return this.$store.state.Lookups.StudyFieldsLookup != null && Array.isArray(this.$store.state.Lookups.StudyFieldsLookup) ? this.$store.state.Lookups.StudyFieldsLookup : [];
            else
                return this.$store.state.Lookups.JobsLookup != null && Array.isArray(this.$store.state.Lookups.JobsLookup) ? this.$store.state.Lookups.JobsLookup : [];
        },
        PostCodeList() {
            return this.SysPostCodeList;
        },
    },
    methods: {
        resetSearch() {
            this.SearchList = [];
            this.Radius = 0
            this.JobId = null
            this.JobType = null
            this.CarAvailable = false
            this.ReadyToMove = false
            this.JobFieldId = null
            this.QualificationId = null
            this.AverageMark = null
            this.PostCode = null
            this.AvailableFrom = null
            this.SearchText = null
            this.EntryDate = null
            this.Fulltime = null
            this.DeadLine = null
            this.FullTimeNot = false
            this.FullTimeFull = false
            this.MinSalary = null
            this.SalaryType = null

        },
        nextPage(no) {
            this.CurrentPageIndex = no - 1;
        },
        searchNow() {


            if ((this.JobType == null || this.JobType <= 0) && this.SearchType == SEARCH_TYPES.JOB_AD) {

                this.$notify(
                    {
                        title: this.trans('ERROR'),
                        text: this.trans('JOB-TYPE-EMPTY'),
                        type: 'error',
                    });
                return;
            }

            if (this.PostCode == null || this.PostCode.PostCode == null) {

                this.$notify(
                    {
                        title: this.trans('ERROR'),
                        text: this.trans('POST-CODE-EMPTY'),
                        type: 'error',
                    });
                return;
            }


            let searchValues = {
                PostCode: this.PostCode.PostCode,
                Radius: this.Radius != null && this.Radius != "" ? this.Radius : 0,
                AvailableFrom: this.AvailableFrom != null && this.AvailableFrom != "" ? this.AvailableFrom : null,
                JobId: this.JobId != null && this.JobId != "" ? this.JobId : null,
                JobType: this.JobType != null && this.JobType != "" ? this.JobType : null,
                CarAvailable: this.CarAvailable,
                ReadyToMove: this.ReadyToMove,
                JobFieldId: this.JobFieldId != null && this.JobFieldId != "" ? this.JobFieldId : null,
                QualificationId: this.QualificationId != null && this.QualificationId != "" ? this.QualificationId : null,
                AverageMark: this.AverageMark != null && this.AverageMark != "" ? this.AverageMark : null,
                SearchText: this.SearchText,
                SearchType: this.SearchType,
                EntryDate: this.EntryDate != null ? this.EntryDate : null,
                Fulltime: this.Fulltime,
                DeadLine: this.DeadLine != null ? this.DeadLine : null,
                MinSalary: this.MinSalary > 0 ? this.MinSalary : null,
                SalaryType: this.SalaryType > 0 ? this.SalaryType : null
            }

            this.controller.read(searchValues).then(response => {

                switch (this.SearchType) {
                    case SEARCH_TYPES.STUDENT:
                        this.SearchList = response.StudentProfiles;
                        break;
                    case SEARCH_TYPES.JOB_AD:
                        this.SearchList = response.JobAds;
                        break;
                    case SEARCH_TYPES.COMPANY:
                        this.SearchList = response.Offices;
                        break;
                }

            });
        },
        GetHighestGraduation(item) {
            if (item.StudentGraduation != null) {

                let items = lodash.filter(this.QualificationList, x => x.DataKey == item.StudentGraduation.HighestGraduation);
                if (items != null && items.length > 0) {
                    return this.trans(items[0].DataValue);
                }

            }
            return "";
        },
        GetDate(value) {
            if (value === null)
                return '';

            return cutDateString(value)
        },
        GetAvailableFromText(item) {
            if (item.AvailableFrom != null) {
                return this.trans('AV-FROM') + ' ' + this.GetDate(item.AvailableFrom)
            } else {
                return false
            }
        },
        GetStartDate(item) {
            if (item.EntryDate != null) {
                return this.trans('AV-FROM') + ' ' + this.GetDate(item.EntryDate)
            } else {
                return false
            }
        },
        GetImage(item) {

            this.profileController.GetProfilePicturePublic(item.FkUser).then(result => {

                if (result.data != null && result.data.size > 0)
                    this.$refs["pic_" + item.PkStudentProfile][0].src = URL.createObjectURL(new Blob([result.data]));
                else {
                    this.$refs["pic_" + item.PkStudentProfile][0].src = require('@/assets/images/empty-profile.jpg');
                }

            })

        },
        GetImageOff(item) {

            this.officeController.GetProfilePictureOfficePublic(item.FkCompany, item.PkOffice).then(result => {

                if (result.data != null && result.data.size > 0)
                    this.$refs["picOff_" + item.PkOffice][0].src = URL.createObjectURL(new Blob([result.data]));
                else {
                    this.$refs["picOff_" + item.PkOffice][0].src = require('@/assets/images/empty-profile.jpg');
                }

            })

        },
        GetImageJob(item) {

            this.jobAdController.GetProfilePictureJobAdPublic(item.FkCompany, item.PkJobAd).then(result => {

                if (result.data != null && result.data.size > 0)
                    this.$refs["picJob_" + item.PkJobAd][0].src = URL.createObjectURL(new Blob([result.data]));
                else {
                    this.$refs["picJob_" + item.PkJobAd][0].src = require('@/assets/images/empty-profile.jpg');
                }

            })

        },
        loadCompany(item) {
            router.push("/CompanyProfilePublicView/" + item.PkCompany)
        },
        GetTopCompanyImage(item) {
            this.companyController.GetProfilePictureCompany(item.PkCompany).then(result => {
                if (result.data != null && result.data.size > 0)
                    this.$refs["picComp_" + item.PkCompany][0].src = URL.createObjectURL(new Blob([result.data]));
                else {
                    this.$refs["picComp_" + item.PkCompany][0].src = require('@/assets/images/empty-profile.jpg');
                }
            })

            return item.CompanyName;
        },
        GetJobNames(item) {
            let text = "";
            item.StudentJobSelectionList.forEach(job => {
                if (job.JobFieldId != null && job.JobType == this.JobType) {
                    let items = lodash.filter(this.$store.state.Lookups.JobFieldsLookup, x => x.DataKey == job.JobFieldId);
                    if (items != null && items.length > 0)
                        text = text + items[0].DataValue + " | ";
                }

                if (job.JobId != null && job.JobType == this.JobType) {
                    let items = lodash.filter(this.$store.state.Lookups.JobsLookup, x => x.DataKey == job.JobId);
                    if (items != null && items.length > 0)
                        text = text + items[0].DataValue + " | ";
                }
            })

            if (text.length > 0 && text.indexOf('|') > 0) {
                text = text.slice(0, text.length - 3);
            }

            return text;

        },
        GetJobNamesJobAd(item) {
            let text = "";

            let items = lodash.filter(this.$store.state.Lookups.JobFieldsLookup, x => x.DataKey == item.JobFieldId);
            if (items != null && items.length > 0)
                text = text + items[0].DataValue + " | ";

            items = lodash.filter(this.$store.state.Lookups.JobsLookup, x => x.DataKey == item.JobId);
            if (items != null && items.length > 0)
                text = text + items[0].DataValue + " | ";

            if (text.length > 0 && text.indexOf('|') > 0) {
                text = text.slice(0, text.length - 3);
            }

            return text;

        },
        GetJobNamesOffice(item) {
            let text = "";
            item.JobSelectionList.forEach(job => {
                if (job.JobFieldId != null && job.JobType == this.JobType) {
                    let items = lodash.filter(this.$store.state.Lookups.JobFieldsLookup, x => x.DataKey == job.JobFieldId);
                    if (items != null && items.length > 0)
                        text = text + items[0].DataValue + " | ";
                }

                if (job.JobId != null && job.JobType == this.JobType) {
                    let items = lodash.filter(this.$store.state.Lookups.JobsLookup, x => x.DataKey == job.JobId);
                    if (items != null && items.length > 0)
                        text = text + items[0].DataValue + " | ";
                }
            })

            if (text.length > 0 && text.indexOf('|') > 0) {
                text = text.slice(0, text.length - 3);
            }


            return text;

        },
        GetJobTypes(item) {
            let text = "";

            var result = lodash.some(item.StudentJobSelectionList, x => x.JobType == JOB_TYPES.MINI_JOB);
            if (result === true) {
                text = text + this.trans('MINI-JOB') + ' | '
            }

            result = lodash.some(item.StudentJobSelectionList, x => x.JobType == JOB_TYPES.VOCATIONAL_TRAINING);
            if (result === true) {
                text = text + this.trans('VOCATIONAL-TRAINING') + ' | '
            }

            result = lodash.some(item.StudentJobSelectionList, x => x.JobType == JOB_TYPES.INTERN_SHIP);
            if (result === true) {
                text = text + this.trans('INTERN-SHIP') + ' | '
            }

            result = lodash.some(item.StudentJobSelectionList, x => x.JobType == JOB_TYPES.CAREER_ENTRY);
            if (result === true) {
                text = text + this.trans('CAREER-ENTRY') + ' | '
            }

            result = lodash.some(item.StudentJobSelectionList, x => x.JobType == JOB_TYPES.DUAL_STUDY);
            if (result === true) {
                text = text + this.trans('DUAL-STUDY') + ' | '
            }

            if (text.length > 0 && text.indexOf('|') > 0) {
                text = text.slice(0, text.length - 3);
            }


            return text;
        },
        GetJobTypesOffice(item) {
            let text = "";

            var result = lodash.some(item.JobSelectionList, x => x.JobType == JOB_TYPES.MINI_JOB);
            if (result === true) {
                text = text + this.trans('MINI-JOB') + ' | '
            }

            result = lodash.some(item.JobSelectionList, x => x.JobType == JOB_TYPES.VOCATIONAL_TRAINING);
            if (result === true) {
                text = text + this.trans('VOCATIONAL-TRAINING') + ' | '
            }

            result = lodash.some(item.JobSelectionList, x => x.JobType == JOB_TYPES.INTERN_SHIP);
            if (result === true) {
                text = text + this.trans('INTERN-SHIP') + ' | '
            }

            result = lodash.some(item.JobSelectionList, x => x.JobType == JOB_TYPES.CAREER_ENTRY);
            if (result === true) {
                text = text + this.trans('CAREER-ENTRY') + ' | '
            }

            result = lodash.some(item.JobSelectionList, x => x.JobType == JOB_TYPES.DUAL_STUDY);
            if (result === true) {
                text = text + this.trans('DUAL-STUDY') + ' | '
            }

            if (text.length > 0 && text.indexOf('|') > 0) {
                text = text.slice(0, text.length - 3);
            }


            return text;
        },
        GetJobTypesJobAd(item) {
            let text = "";

            if (item.AdType == JOB_TYPES.MINI_JOB) {
                text = this.trans('MINI-JOB') + ' | '
            } else if (item.AdType == JOB_TYPES.VOCATIONAL_TRAINING) {
                text = this.trans('VOCATIONAL-TRAINING') + ' | '
            } else if (item.AdType == JOB_TYPES.INTERN_SHIP) {
                text = this.trans('INTERN-SHIP') + ' | '
            } else if (item.AdType == JOB_TYPES.CAREER_ENTRY) {
                text = this.trans('CAREER-ENTRY') + ' | '
            } else if (item.AdType == JOB_TYPES.DUAL_STUDY) {
                text = this.trans('DUAL-STUDY') + ' | '
            }
            return text;
        },
        openProfile(row) {
            router.push("/StudentProfilePublicView/" + row.PkStudentProfile);
        },
        openProfileOffice(row) {
            router.push("/OfficeProfilePublicView/" + row.FkCompany + "/" + row.PkOffice);
        },
        openProfileJobAd(row) {
            router.push("/JobAdProfilePublicView/" + row.FkCompany + "/" + row.FkOffice + "/" + row.PkJobAd);
        },
        setCarAvailable(value) {
            this.CarAvailable = value.target.checked;
        },
        setFullTimeFull(value) {
            this.FullTimeFull = value.target.checked;

            if (this.FullTimeFull == true && this.FullTimeNot == false) {
                this.Fulltime = true;
            } else if (this.FullTimeFull == true && this.FullTimeNot == false) {
                this.Fulltime = false;
            } else {
                this.Fulltime = null;
            }

        },
        setFullTimeNot(value) {
            this.FullTimeNot = value.target.checked;

            if (this.FullTimeFull == true && this.FullTimeNot == false) {
                this.Fulltime = true;
            } else if (this.FullTimeFull == true && this.FullTimeNot == false) {
                this.Fulltime = false;
            } else {
                this.Fulltime = null;
            }

        },
        setReadyToMove(value) {
            this.ReadyToMove = value.target.checked;
        },
        AvailableFromChange(date) {
            this.AvailableFrom = date;
        },
        DeadLineChange(date) {
            this.DeadLine = date;
        },
        EntryDateChange(date) {
            this.EntryDate = date;
        },
        rowClicked(item) {
            this.showEdit = false;
            if (!item) return;
            this.selectedPkOffice = item.PkOffice;
            this.showEdit = true;
            this.selectedOffice = item;
            this.$refs.editOfficeForm.setData(item);
        },
    },

    beforeMount() {
    },
    activated() {
        if (this.SysPostCodeList == null || this.SysPostCodeList.length <= 0) {
            this.controller.readPostCodes().then(result => {

                this.SysPostCodeList = result.PostCodes;

                if (!this.IsCompany) {
                    this.controller.readTopCompanies().then(response => {
                        this.TopCompanies = response.CompanyList
                    })
                }

            })
        }
    },
}
</script>

<style lang="scss" scoped>

.custom-table {
    background: transparent;
}

.tree-header {
    position: relative;
    background-color: transparent;
    padding: 5px 10px 5px 10px;
    font-weight: bold;
    font-size: 20px;
    min-height: 40px;
    box-shadow: -2px 4px 10px -6px rgba(0, 0, 0, 0.45);
    border-bottom: 1px solid #9acccb;
}

.tree-header-label {
    font-size: 16px;
    padding-top: 4px;
    display: flex;
}

p {
    margin-bottom: 0px !important;
}

.no-left-padding > .input {
    padding-left: 0px !important;
}

.header-picture-company {
    min-height: 550px;
    display: grid;
    background-position: center center;
    align-self: center;
    align-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(~@/assets/images/header-bild-unternehmen.jpg);
}

.header-text-company {
    color: #000000;
    margin-left: 40px;
    background: white;
    width: 45%;
    border-radius: 12px;
    font-weight: 900;
    padding-left: 10px;
    letter-spacing: 0;
}

.header-picture-student {
    min-height: 550px;
    display: grid;
    background-position: center center;
    align-self: center;
    align-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(~@/assets/images/header-bild.jpg);
}

.header-text-student {
    color: #000000;
    margin-left: 40px;
    background: white;
    width: 45%;
    border-radius: 12px;
    font-weight: 900;
    padding-left: 10px;
    letter-spacing: 0;
}

.margin-left {
    margin-left: 35px;
}

.margin-right {
    margin-right: 35px;
}

.mt-btn {
    margin-top: 10px;
}

.search-box {
    background: #9acccb !important;
}

.header-picture-company-second {
    display: grid;
    align-self: center;
    align-content: center;
    margin-bottom: 40px;
    margin-top: 20px;
}

.header-picture-Student-second {
    display: grid;
    align-self: center;
    align-content: center;
    margin-bottom: 40px;
    margin-top: 20px;
}

.header-text-company-second {
    font-weight: 800;
    color: #000000
}

.dark,
.purple {
    .header-text-company-second {
        color: #ffffff
    }

    .CompanyName {
        text-align: center;
        color: #ffffff;
    }

}

.upp-case {
    text-transform: uppercase;
    color: #000000;
}


.black {
    color: #000000;
    font-weight: 700;
}

.top-company-col {
    display: grid;
}

.CompanyName {
    text-align: center;
    color: #000000;
    font-weight: 600;
    font-size: 17px;
}


.companyPic {
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid #9acccb;

    &:hover {
        opacity: 0.6;
    }

}

</style>
