

export const CREATE     = 1;

export const UPDATE     = 2;

export const DELETE     = 3;

export const ACTIVATE   = 4;

export const DEACTIVATE = 5;


export default {
    CREATE,
    UPDATE,
    DELETE,
    ACTIVATE,
    DEACTIVATE
}


export const GUID_0 = '00000000-0000-0000-0000-000000000000';
