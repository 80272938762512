<template>
    <div id="footer" class="footer">
        <div class="container">
            <div class="grid footer-flex">
                <div class="jobineo">
                    <p class="headline">{{ trans('JOBINEO') }}</p>
                    <router-link to="/LandingPageStudent">
                        <a class="link"><p style="color:black;">{{ trans('FOR-STUDENT') }}</p></a>
                    </router-link>
                    <router-link to="/LandingPageCompany">
                        <a class="link"><p style="color:black;">{{ trans('FOR-COMPANY') }}</p></a>
                    </router-link>
                </div>
                <div class="footer-contact">
                    <p class="headline">{{ trans('CONTACT') }}</p>
                    <div class="footer-element">
                        <img class="icon-mail-footer" src="../assets/images/icon-mail-footer.svg"/>
                        <a class="link" href="mailto:hi@jobineo.de"><p style="color:black;">hi@jobineo.de</p></a>
                    </div>
                    <div class="footer-element">
                        <img class="icon-whatsapp" src="../assets/images/icon-whatsapp.svg"/>
                        <a class="link" href="tel:+49 163 2061628"><p style="color:black;">+49 163 2061628</p></a>
                    </div>
                </div>
                <div class="social-media">
                    <p class="headline">{{ trans('ALSO-HERE') }}</p>
                    <a href="https://www.instagram.com/jobineo.de/" target="_blank">
                        <img src="../assets/images/icon-instagram.png" alt=""/>
                    </a>
                    <a href="https://open.spotify.com/show/3kRQBD6oMJcgWQOi8LDuoP?si=tSbEWT82QEuopUOSdbLNmw"
                       target="_blank">
                        <img src="../assets/images/icon-spotify.png" alt=""/>
                    </a>
                </div>
            </div>
            <div class="licence-text">
                {{ trans('LICENSE-TEXT-ICONS') }}
            </div>
        </div>
    </div>
    <div class="nav-footer">
        <div class="flex-row-nav">
            <div class="link-area">
                <router-link to="/Imprint">
                    <a>
                        <p>{{ trans('IMPRINT') }}</p>
                    </a>
                </router-link>
                <router-link to="/DataProtection"><a>
                    <p>{{ trans('DATA-PROTECTION') }}</p>
                </a>
                </router-link>
            </div>
            <div class="copyright">
                <img
                    class="icon-copyright"
                    src="../assets/images/icon-copyright.svg"
                    alt=""/>
                <p style="color:#9acccb;">{{ CopyRightText }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Site-Footer",
    props: ["title"],
    computed: {
        CopyRightText() {
            return new Date().getFullYear() + ' ' + this.trans('COPYRIGHT')
        }
    }
}
</script>

<style scoped src="@/scss/jobineo/output.css">

</style>
