<template>
    <!-- Modal -->
    <div ref="modal"
         class="modal fade"
         tabindex="-1"
         role="dialog"
         id="configBox"
         aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <font-awesome-icon icon="fas fa-edit" class="edit-icon"/> &nbsp;
                        <font-awesome-icon icon="fas fa-gear"/> &nbsp;
                        {{ headerText }}
                    </h5>
                    <button type="button" data-dismiss="modal" ref="closeBtn" id="closeBtn" data-bs-dismiss="modal"
                            aria-label="Close"
                            class="btn-close">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="tab-content" id="simpletabContent">
                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <MyRequestsBoxForm v-show="showMyRequestsBox"
                                               ref="myRequestsBox"></MyRequestsBoxForm>
                            <RecievedRequestsBoxForm v-show="showRecievedRequestsBox"
                                                     ref="recievedRequestsBox"></RecievedRequestsBoxForm>
                            <AppointmentBoxForm v-show="showAppointmentBox"
                                                ref="appointmentBox"></AppointmentBoxForm>
                            <MatchBoxForm v-show="showMatchBox"
                                          ref="matchBox"></MatchBoxForm>
                            <GeneralBoxForm v-show="showGeneralBox" ref="generalConfigBox"></GeneralBoxForm>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <div class="footer-inner">

                        <base-button
                            type="yellow" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close"
                            native-type="button"
                            class="btn btn-yellow float-left">
                            <font-awesome-icon icon="fas fa-times"/>
                            {{ $t('CANCEL') }}
                        </base-button>

                        <WaitingButton
                            size="sm"
                            icon="fas fa-check"
                            class="float-right btn btn-mint input-rounded"
                            :waiting="isSaving"
                            @click="save">
                            {{ $t('SAVE') }}
                        </WaitingButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import $ from 'jquery';
import WaitingButton from "@/components/WaitingButton.vue";
import {DASHBOARD_BOX_TYPES} from "@/data/dashboard";
import GeneralBoxForm from "@/views/dashboard/DashboardBoxes/GeneralBoxForm";
import AppointmentBoxForm from "@/views/dashboard/DashboardBoxes/AppointmentBoxForm.vue";
import MatchBoxForm from "@/views/dashboard/DashboardBoxes/MatchBoxForm.vue";
import MyRequestsBoxForm from "@/views/dashboard/DashboardBoxes/MyRequestsBoxForm.vue";
import RecievedRequestsBoxForm from "@/views/dashboard/DashboardBoxes/RecievedRequestsBoxForm.vue";

export default {
    name: "DashboardConfigBoxModal",
    components: {
        RecievedRequestsBoxForm,
        MyRequestsBoxForm,
        MatchBoxForm,
        AppointmentBoxForm,
        GeneralBoxForm, WaitingButton
    },
    props: ['dashboardController'],
    data() {
        return {
            isSaving: false,
            error: false,
            errorMessage: null,
            boxItem: null,
            boxType: null,
            generalConfig: false
        }
    },
    watch: {},
    computed: {
        BoxItem() {
            return this.boxItem;
        },
        BoxType() {
            return this.boxType;
        },
        GeneralConfig() {
            return this.generalConfig;
        },
        controller() {
            return this.$props.dashboardController ? this.$props.dashboardController : {};
        },
        headerText() {
            if (this.GeneralConfig === true) {
                return this.trans('HEADER-GENERAL-DASHBOARD-CONFIG');
            }

            if (this.BoxType == null)
                return '';

            switch (this.BoxType) {
                case DASHBOARD_BOX_TYPES.MyRequests:
                    return this.trans('HEADER-BOX-MY-REQUESTS')
                case DASHBOARD_BOX_TYPES.MatchBox:
                    return this.trans('HEADER-BOX-MATCH')
                case DASHBOARD_BOX_TYPES.RecievedRequests:
                    return this.trans('HEADER-BOX-RECIEVED-REQUESTS')
                case DASHBOARD_BOX_TYPES.AppointmentBox:
                    return this.trans('HEADER-BOX-APPOINTMENT')
                default:
                    return '';
            }
        },
        showMyRequestsBox() {
            return this.GeneralConfig !== true && this.BoxType != null && this.BoxType === DASHBOARD_BOX_TYPES.MyRequests;
        },
        showAppointmentBox() {
            return this.GeneralConfig !== true && this.BoxType != null && this.BoxType === DASHBOARD_BOX_TYPES.AppointmentBox;
        },
        showRecievedRequestsBox() {
            return this.GeneralConfig !== true && this.BoxType != null && this.BoxType === DASHBOARD_BOX_TYPES.RecievedRequests;
        },
        showMatchBox() {
            return this.GeneralConfig !== true && this.BoxType != null && this.BoxType === DASHBOARD_BOX_TYPES.MatchBox;
        },
        showGeneralBox() {
            return this.GeneralConfig === true;
        },
    },
    methods: {
        twiggle() {
        },
        close(e) {
            this.$refs.closeBtn.click();
        },
        open(boxItem, generalConfig = false, boxType = null) {
            this.boxItem = boxItem;
            this.generalConfig = generalConfig;
            this.boxType = boxType;

            if (generalConfig === true) {
                this.$refs.generalConfigBox.setData(this.$store.state.Dashboard.Config.DashboardConfigsAll);
            } else {
                switch (this.BoxType) {
                    case DASHBOARD_BOX_TYPES.MyRequests:
                        this.$refs.myRequestsBox.setData(boxItem);
                        break;
                    case DASHBOARD_BOX_TYPES.MatchBox:
                        this.$refs.matchBox.setData(boxItem);
                        break;
                    case DASHBOARD_BOX_TYPES.AppointmentBox:
                        this.$refs.appointmentBox.setData(boxItem);
                        break;
                    case DASHBOARD_BOX_TYPES.RecievedRequests:
                        this.$refs.recievedRequestsBox.setData(boxItem);
                        break;
                }
            }
        },
        reset() {

        },
        save() {

            this.isSaving = true;
            let currentForm = null;

            if (this.generalConfig === true)
                currentForm = this.$refs.generalConfigBox;
            else {
                switch (this.BoxType) {
                    case DASHBOARD_BOX_TYPES.MyRequests:
                        currentForm = this.$refs.myRequestsBox;
                        break;
                    case DASHBOARD_BOX_TYPES.RecievedRequests:
                        currentForm = this.$refs.recievedRequestsBox;
                        break;
                    case DASHBOARD_BOX_TYPES.AppointmentBox:
                        currentForm = this.$refs.appointmentBox;
                        break;
                    case DASHBOARD_BOX_TYPES.MatchBox:
                        currentForm = this.$refs.matchBox;
                        break;
                }

            }

            if (currentForm == null) {
                this.isSaving = false;
                return;
            }

            currentForm.setError(null);

            currentForm.validate()
                .then(doSave => {

                    if (!doSave) {
                        this.isSaving = false;
                        return;
                    }

                    let data = currentForm.getData();


                    this.controller.update(data, this.generalConfig, this.boxType).then((response) => {
                        this.isSaving = false;

                        if (response.IsError) {
                            currentForm.setError(response.ErrorMessage);
                            this.flashMessage.error(
                                {
                                    title: this.trans('ERROR'),
                                    message: this.trans(response.ErrorMessage),
                                    time: 4000
                                });
                            return;
                        }

                        this.close();
                        this.$emit('updated', true);

                        this.flashMessage.success(
                            {
                                title: this.trans('SUCCESS'),
                                message: this.trans('DATA-SAVED'),
                                time: 4000
                            });

                    }).catch(() => {
                        this.isSaving = false;
                    })
                })


        },

    },
}
</script>

<style scoped>

</style>
