<template>
    <div>
        <!--  BEGIN NAVBAR  -->
        <New-Header></New-Header>
        <!--  END NAVBAR  -->

        <!--  BEGIN MAIN CONTAINER  -->
        <div
            class="main-container"
            id="container"
            :class="[!$store.state.is_show_sidebar ? 'sidebar-closed sbar-open' : '', $store.state.menu_style === 'collapsible-vertical' ? 'collapsible-vertical-mobile' : '']"
        >
            <!--  BEGIN OVERLAY  -->
            <div class="overlay" :class="{ show: !$store.state.is_show_sidebar }"
                 @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)"></div>
            <div class="search-overlay" :class="{ show: $store.state.is_show_search }"
                 @click="$store.commit('toggleSearch', !$store.state.is_show_search)"></div>
            <!-- END OVERLAY -->

            <!--  BEGIN SIDEBAR  -->
            <New-Sidebar></New-Sidebar>
            <!--  END SIDEBAR  -->

            <!--  BEGIN CONTENT AREA  -->
            <div id="content" class="main-content" :class="[{
                      ' main-content-no-nav-bar ': !$store.state.loggedIn}]">
                <template v-if="!loggedIn">
                    <router-view></router-view>
                </template>
                <template v-else>
                    <router-view v-slot="{ Component }">
                        <keep-alive>
                            <component :is="Component" />
                        </keep-alive>
                    </router-view>
                </template>

            </div>
            <!--  END CONTENT AREA  -->

        </div>
    </div>
</template>

<script setup>
import NewHeader from '@/components/layout/header.vue';
import NewSidebar from '@/components/layout/sidebar.vue';
</script>
<script>
import store from "@/store";

export default {
    computed: {
        loggedIn() {
            return store.state.loggedIn;
        },
    }
}
</script>
