export const NotificationBox = 1;
export const AppointmentBox = 2;
export const MessageBox = 3;
export const MatchBox = 4;
export const RecievedRequests = 5;
export const MyRequests = 6;

export const DASHBOARD_BOX_TYPES = {
    NotificationBox,
    AppointmentBox,
    MessageBox,
    MatchBox,
    RecievedRequests,
    MyRequests
}


export const January = 1;
export const February = 2;
export const March = 3;
export const April = 4;
export const May = 5;
export const June = 6;
export const July = 7;
export const August = 8;
export const September = 9;
export const October = 10;
export const November = 11;
export const December = 12;
export const DASHBOARD_BOX_MONTHS = {
    January,
    February,
    March,
    April,
    May,
    June,
    July,
    August,
    September,
    October,
    November,
    December
}

export const DASHBOARD_BOX_MONTHS_DROPDOWN = [
    {
        label: 'DASHBOARD_BOX_MONTHS_JANUARY',
        value: DASHBOARD_BOX_MONTHS.January
    },
    {
        label: 'DASHBOARD_BOX_MONTHS_FEBRUARY',
        value: DASHBOARD_BOX_MONTHS.February
    },
    {
        label: 'DASHBOARD_BOX_MONTHS_MARCH',
        value: DASHBOARD_BOX_MONTHS.March
    },
    {
        label: 'DASHBOARD_BOX_MONTHS_APRIL',
        value: DASHBOARD_BOX_MONTHS.April
    },
    {
        label: 'DASHBOARD_BOX_MONTHS_MAY',
        value: DASHBOARD_BOX_MONTHS.May
    },
    {
        label: 'DASHBOARD_BOX_MONTHS_JUNE',
        value: DASHBOARD_BOX_MONTHS.June
    },
    {
        label: 'DASHBOARD_BOX_MONTHS_JULY',
        value: DASHBOARD_BOX_MONTHS.July
    },
    {
        label: 'DASHBOARD_BOX_MONTHS_AUGUST',
        value: DASHBOARD_BOX_MONTHS.August
    },
    {
        label: 'DASHBOARD_BOX_MONTHS_SEPTEMBER',
        value: DASHBOARD_BOX_MONTHS.September
    },
    {
        label: 'DASHBOARD_BOX_MONTHS_OCTOBER',
        value: DASHBOARD_BOX_MONTHS.October
    },
    {
        label: 'DASHBOARD_BOX_MONTHS_NOVEMBER',
        value: DASHBOARD_BOX_MONTHS.November
    },
    {
        label: 'DASHBOARD_BOX_MONTHS_DECEMBER',
        value: DASHBOARD_BOX_MONTHS.December
    }

]
