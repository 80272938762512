<template>
    <div>
        <div class="container">
            <!-- Outer Row -->
            <div class="row justify-content-center">
                <div class="col-xl-10 col-lg-12 col-md-9">
                    <div class="card o-hidden  shadow-lg my-5">
                        <div class="card-body p-0">
                            <!-- Nested Row within Card Body -->
                            <div class="row">
                                <div class="col-lg-6 d-none d-lg-block ">
                                    <div class="verifyLogo">
                                        <img src="../../assets/images/logo-jobineo.png"/>
                                    </div>
                                    <div v-show="waiting" class="spinner-container">
                                        <div class="spinner-wrap">
                                            <font-awesome-icon icon="fas fa-spin fa-circle-notch"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="p-5" style="padding-top: 130px!important;">
                                        <div class="text-center">
                                            <h1 class="h4 text-gray-900 mb-4">{{ $t('USER-CONFIRM') }}</h1>
                                        </div>
                                        <div class="confirm-icon">
                                            <font-awesome-icon v-if="confirmed&&!reSended"
                                                               icon="fas fa-check"
                                                               class="text-success"/>
                                            <font-awesome-icon v-if="!confirmed&&!reSended"
                                                               icon="fas fa-times"
                                                               class="text-danger"/>
                                            <font-awesome-icon v-if="reSended"
                                                               icon="fas fa-check"
                                                               class="text-success"/>
                                            <div class="text-danger">{{ errorMessage }}</div>
                                        </div>
                                        <hr>
                                        <div v-if="confirmed" class="text-center">
                                            <router-link to="/Login">{{ $t('CONTINUE-LOGIN') }}
                                                <font-awesome-icon icon="fas fa-arrow-right"/>
                                            </router-link>
                                        </div>
                                        <div v-else class="text-center">
                                            <base-button @click="ReSendEmail" native-type="button"
                                                         class="btn btn-mint btn-sm ">
                                                <font-awesome-icon icon="fas fa-envelope" style="margin-right:10px;"/>
                                                {{ trans('RE-SEND-EMAIL') }}
                                            </base-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * View: VerifyUser
 * Path: /VerifyUser/:FkUser/:HashCode
 * A landing page view to verify a user after registration or creation.
 * The appropriate URL will be sent via email.
 * This is a public view
 *
 */
import {restUri} from "@/data/restPaths";
import {landingPages} from "@/data/landingPages";
import {getConfig} from "@/jobineo";

export default {
    name: "VerifyUser",
    data() {
        return {
            FkUser: null,
            waiting: true,
            confirmed: false,
            reSended: false,
            error: false,
            errorMessage: ''
        }
    },
    methods: {
        ReSendEmail() {

            let baseUrl = getConfig('baseUrl')

            this.axios.post(restUri.RE_SEND_EMAIL, {
                FkUser: this.FkUser,
                TempLink: baseUrl + landingPages.VERIFY_USER
            }).then(result => {

                let data = result.data;
                if (data.IsError) {
                    this.errorMessage = this.trans(data.ErrorMessage);


                } else {
                    this.errorMessage = '';
                    this.confirmed = true;
                }
                this.waiting = false;
            }).catch(result => {
                this.waiting = false;
                let data = result.data;
                if (data.IsError) {
                    this.error = true;
                    this.errorMessage = this.trans(data.ErrorMessage);
                }
            });
        }
    },
    beforeMount() {
        this.FkUser = this.$route.params.FkUser
        let HashCode = this.$route.params.HashCode;


        this.axios.post(restUri.CONFIRM_EMAIL_ADDRESS, {
            FkUser: this.FkUser,
            HashCode
        }).then(result => {
            this.waiting = false;
            let data = result.data;
            if (data.IsError) {
                this.errorMessage = this.trans(data.ErrorMessage);


            } else {
                this.errorMessage = '';
                this.confirmed = true;
            }

        }).catch(result => {
            this.waiting = false;
            let data = result.data;
            if (data.IsError) {
                this.error = true;
                this.errorMessage = this.trans(data.ErrorMessage);
            }
        });
    }
}
</script>

<style scoped>

.verify-wrap {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 11;
    top: 0;
    left: 0;
}

.verify-wrap > .container {
    display: flex;
    height: 100vh;
    width: 100vw;
}

.verify-wrap > .container > .row {
    align-content: center;
    flex-direction: row;
    flex-grow: 1;
}

.verifyLogo {
    position: relative;
    display: block;
    padding: 0;
    margin: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.verifyLogo > img {
    width: 85%;
    margin-left: 50px;
    margin-top: 80px;

}

.spinner-container {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.spinner-wrap {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: nowrap;
    padding-left: 1.7rem;
    padding-bottom: 1.5rem;

}

.spinner-wrap > i {
    font-size: 4em;
    color: #1c98d0;
}

.h-spacer button {
    color: yellow;
    padding: 0.75rem 1.0rem;
}

.confirm-icon {
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
}

.confirm-icon > i {
    font-size: 12em;
}


</style>
