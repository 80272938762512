<template>
    <div>
        <Form ref="valForm" :validationSchema="validationSchema">
            <div class="form">
                <div class="terms_condition mb-4">
                    <div class="checkbox-outline-primary custom-control custom-checkbox">
                        <base-checkbox class="custom-control-input"
                                       id="IsSchool"
                                       :required="false"
                                       :checked="IsSchool"
                                       @input="setSchool"
                                       :label="$t('I-AM-SCHOOL')"/>
                    </div>
                </div>
                <div class="row flex">
                    <div class="form-group col-5">
                        <div id="email-field" class="field-wrapper input">
                            <base-input
                                ref="Email"
                                v-model="model.Email"
                                type="email"
                                :no-check-icons="true"
                                obj-name="Email"
                                :label="$t('EMAIL')"
                                :max-length="150"
                                :placeholder="trans('EMAIL')"
                                :email="true"
                                attached-class="form-control"
                                :required="true"/>
                        </div>
                    </div>
                    <div class="form-group col-5">
                        <div id="email-field" class="field-wrapper input">
                            <base-input
                                v-model="model.EmailRepeat"
                                type="email"
                                :no-check-icons="true"
                                obj-name="EmailRepeat"
                                check-identical="Email"
                                :placeholder="trans('EMAIL-REPEAT')"
                                :label="$t('EMAIL-REPEAT')"
                                :max-length="150"
                                :required="true"/>
                        </div>
                    </div>
                </div>
                <div class="row flex">
                    <div class="form-group col-5">
                        <div id="email-field" class="field-wrapper input">
                            <base-input
                                v-model="model.Password"
                                type="password"
                                :help-text="passwordHelp"
                                obj-name="Password"
                                :placeholder="trans('PASSWORD')"
                                :label="$t('PASSWORD')"
                                :no-check-icons="true"
                                :max-length="PasswordMaxLength"
                                :min-length="PasswordMinLength"
                                :regex="PasswordRegex"
                                attached-class="form-control"
                                :required="true"/>
                        </div>
                    </div>
                    <div class="form-group col-5">
                        <div id="email-field" class="field-wrapper input">
                            <base-input
                                v-model="model.PasswordRepeat"
                                type="password"
                                obj-name="PasswordRepeat"
                                :label="$t('PASSWORD-REPEAT')"
                                :max-length="150"
                                :placeholder="trans('PASSWORD-REPEAT')"
                                :no-check-icons="true"
                                check-identical="Password"
                                attached-class="form-control"
                                :required="true"/>
                        </div>
                    </div>
                </div>
                <div class="row flex">
                    <div class="form-group col-5">
                        <div id="saluation-field" class="field-wrapper input">
                            <salutations-drop-down ref="Salutation"
                                                   v-model="model.Salutation"
                                                   :required="true"
                                                   :has-null-value="true"
                                                   :null-value-text="trans('SELECT-SALUTATION')"
                                                   :label="trans('SALUTATION')"
                                                   attached-class="form-control"
                                                   name="Salutation"/>
                        </div>
                    </div>
                    <div class="form-group col-5">
                        <div id="email-field" class="field-wrapper input">
                            <base-input
                                v-model="model.NameAddition"
                                type="text"
                                obj-name="NameAddition"
                                :label="trans('NAME-ADDITION')"
                                attached-class="form-control"
                                :placeholder="trans('NAME-ADDITION')"
                                name="NameAddition"
                                ref="NameAddition"/>
                        </div>
                    </div>
                </div>
                <div class="row flex">
                    <div class="form-group col-5">
                        <div id="email-field" class="field-wrapper input">
                            <base-input
                                v-model="model.FirstName"
                                type="text"
                                obj-name="FirstName"
                                :label="trans('FIRSTNAME')"
                                :required="true"
                                attached-class="form-control"
                                :placeholder="trans('FIRSTNAME')"
                                name="FirstName"
                                ref="FirstName"/>
                        </div>
                    </div>
                    <div class="form-group col-5">
                        <div id="email-field" class="field-wrapper input">
                            <base-input
                                v-model="model.LastName"
                                type="text"
                                obj-name="LastName"
                                :label="trans('LASTNAME')"
                                attached-class="form-control"
                                :required="true"
                                :placeholder="trans('LASTNAME')"
                                name="LastName"
                                ref="LastName"/>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
        <div v-if="error" class="error-alert-wrap">
            <font-awesome-icon icon="fas fa-exclamation-triangle" class="error-icon" />
            {{ trans(errorMessage) }}
        </div>
    </div>

</template>


<script>
import * as yup from 'yup';
import {Form} from 'vee-validate';
import '@/scss/jobineo/sass/authentication/auth-boxed.scss';
import {REGISTER_TYPES} from "@/data/RegisterTypes";
import SalutationsDropDown from '@/components/dropdowns/SalutationsDropdown.vue'

export default {
    name: "FirstStep",
    props: ['root-component'],
    components: {Form, SalutationsDropDown},
    data() {
        return {
            validationSchema: yup.object({
                Email: yup.string().required(this.trans("REQUIRED-MESSAGE")).email(this.trans("NO-EMAIL")),
                EmailRepeat: yup.string().required(this.trans("REQUIRED-MESSAGE")).email(this.trans("NO-EMAIL")),
                Password: yup.string().required(this.trans("REQUIRED-MESSAGE")).min(8, this.trans("MIN-LENGTH-START|" + 8 + "|MIN-LENGTH-END")).max(20, this.trans("MAX-LENGTH-START|" + 20 + "|MAX-LENGTH-END")),
                PasswordRepeat: yup.string().required(this.trans("REQUIRED-MESSAGE")).min(8, this.trans("MIN-LENGTH-START|" + 8 + "|MIN-LENGTH-END")).max(20, this.trans("MAX-LENGTH-START|" + 20 + "|MAX-LENGTH-END")),
            }),
            IsSchool: false,
            model: {
                Email: '',
                Password: '',
                PasswordRepeat: '',
                RegisterType: REGISTER_TYPES.COMPANY,
                AGBSAccepted: null,
                DSGVOAccepted: null,
                LanguageId: 'de',
                TempLink: '',
                CompanyName: '',
                Street: '',
                StreetNr: '',
                PostCode: '',
                City: '',
                Country: null,
                AddressAddition: '',
                OfficeName: '',
                OfficeStreet: '',
                OfficeStreetNr: '',
                OfficePostCode: '',
                OfficeCity: '',
                OfficeCountry: null,
                OfficeAddressAddition: '',
                MemberCount: null,
                NameAddition: '',
                Salutation: null,
                FirstName: '',
                LastName: '',
                Over16: true,
                ParentEmail: null
            },
            error: false,
            errorMessage: ''
        }
    },
    watch: {
        RegisterType(newValue, oldValue) {
            this.model.RegisterType = newValue;
            this.$emit('registerTypeChanged', newValue);

        }
    },
    computed: {
        passwordHelp() {
            let text = this.$t('HLP-PASSWORD') + "\n";
            if (this.$store.state.SysSettings != null) {
                let sys = this.$store.state.SysSettings;
                if (sys.PasswordMinChar > 0) {
                    text += this.$t('PW-MIN') + sys.PasswordMinChar + this.$t('PW-MIN-CHAR') + ";\n";
                }

                if (sys.PasswordMaxChar > 0) {
                    text += this.$t('PW-MAX') + sys.PasswordMaxChar + this.$t('PW-MAX-CHAR') + ";\n";
                }

                if (sys.PasswordNeedSymbol > 0) {
                    text += this.$t('PW-MIN') + sys.PasswordNeedSymbol + this.$t('PW-MIN-SYM') + ";\n";
                }

                if (sys.PasswordMaxRepeatChar > 0) {
                    text += this.$t('PW-MAX') + sys.PasswordMaxRepeatChar + this.$t('PW-MAX-SAME') + ";\n";
                }

                if (sys.PasswordNeedNumeric > 0) {
                    text += this.$t('PW-MIN') + sys.PasswordNeedNumeric + this.$t('PW-MIN-NUMERIC') + ";\n";
                }

                if (sys.PasswordNeedLowerCase > 0) {
                    text += this.$t('PW-MIN') + sys.PasswordNeedLowerCase + this.$t('PW-MIN-LOWER') + ";\n";
                }

                if (sys.PasswordNeedUpperCase > 0) {
                    text += this.$t('PW-MIN') + sys.PasswordNeedUpperCase + this.$t('PW-MIN-UPPER') + ";\n";
                }

            }

            return text
        },
        DateTimeFormat() {
            return "dd.MM.yyyy";
        },
        DateTimeFormatValue() {
            return "yyyy-MM-ddT00:00:00.000Z";
        },
        PasswordMaxLength() {
            if (this.$store.state.SysSettings != null) {
                let sys = this.$store.state.SysSettings;
                return sys.PasswordMaxChar;
            }
            return null
        },
        PasswordMinLength() {
            if (this.$store.state.SysSettings != null) {
                let sys = this.$store.state.SysSettings;
                return sys.PasswordMinChar;
            }
            return null
        },
        PasswordRegex() {
            let regexText = '';

            // '^'
            if (this.$store.state.SysSettings != null) {
                let sys = this.$store.state.SysSettings;
                let praefix = '';
                if (sys.PasswordNeedSymbol > 0) {
                    praefix = '^'
                    regexText += '(?=.*?[@;:_,.\\-+*~§$%&=#?!]{' + sys.PasswordNeedSymbol + ',})';
                }

                if (sys.PasswordNeedNumeric > 0) {
                    praefix = '^'
                    regexText += '(?=.*?[0-9]{' + sys.PasswordNeedNumeric + ',})';
                }

                if (sys.PasswordNeedLowerCase > 0) {
                    praefix = '^';
                    regexText += '(?=.*?[a-z]{' + sys.PasswordNeedLowerCase + ',})';
                }

                if (sys.PasswordNeedUpperCase > 0) {
                    praefix = '^';
                    regexText += '(?=.*?[A-Z]{' + sys.PasswordNeedUpperCase + ',})';
                }

                regexText = praefix + regexText;

            }
            return regexText;
        }
    },
    methods: {
        setSchool(value) {
            this.IsSchool = value.target.checked;

            if (this.IsSchool == true) {
                this.model.RegisterType = 3
            } else {
                this.model.RegisterType = 2
            }
        },
        salutationSelect(value) {
            this.model.Salutation = value;
        },
        submitForm(valid) {
            this.$emit('update', valid)
        },
        reset() {
            this.model.Email = '';
            this.model.EmailRepeat = '';
            this.model.Password = '';
            this.model.PasswordRepeat = '';
            this.model.noPasswordMatch = false;
        },
        validate() {

            let passwordMatch = (!this.model.Password && this.model.PasswordRepeat) || this.model.Password === this.model.PasswordRepeat,
                emailMatch = (!this.model.Email && this.model.EmailRepeat) || this.model.Email === this.model.EmailRepeat;
            this.error = false;
            this.errorMessage = '';


            const regexPassword = this.PasswordRegex;

            let reg = new RegExp(regexPassword)

            const regExResult = reg.test(this.model.Password);


            this.error = !passwordMatch || !emailMatch || !regExResult;
            this.errorMessage = passwordMatch ? "" : this.trans('PASSWORD-NO-MATCH');
            this.errorMessage = emailMatch ? this.errorMessage : this.errorMessage + this.trans('EMAIL-NO-MATCH');
            this.errorMessage = regExResult ? this.errorMessage : this.errorMessage + this.trans('PASSWORD-REGEX');
            if (this.error)
                return Promise.resolve(false);


            return this.$refs.valForm.validate().then(result => {
                if (result.valid) {
                    this.$emit('on-validated', true, this.model);
                    return Promise.resolve(true);
                } else
                    return Promise.resolve(false);
            })

        }

    }
}
</script>

<style scoped>

.flex {
    display: flex;
    margin-right: 40px;
}

.who-am-i {
    display: block;
    margin-top: 40px;
    width: 100%;
    height: 100px;
    clear: both;
    min-height: 150px;

}


</style>
