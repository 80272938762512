import Controller from "../controller.js";
import {restUri} from "@/data/restPaths";
import {mutate} from "@/utils/mutate";
import {CREATE, DELETE, UPDATE} from "@/data/cud";
import axios from "axios";
import {restBody} from "@/utils/restBody";
import store from "@/store";
import {uuid} from "vue-uuid";


export class JobAdController extends Controller {

    constructor(root) {
        super({
            root,
            path: {
                CUD: restUri.JOB_AD_CUD,
                GET: restUri.GET_JOB_ADS
            }
        })
    }

    read(noMatching = false, FkStudentProfile = null) {

        let body = {
            FkCompany: store.state.FkCompany,
            NoMatchings: noMatching,
            FkStudentProfile: FkStudentProfile
        };
        if (store.state.FkOffice != null)
            body = {
                FkCompany: store.state.FkCompany,
                FkOffice: store.state.FkOffice,
                NoMatchings: noMatching,
                FkStudentProfile: FkStudentProfile
            };

        return super.read({
            log: false,
            body: body
        }).then(data => {
            if (!data.IsError) {
                return data;
            } else return data
        });
    }

    create(JobAd = {}) {

        JobAd.CreatedDate = new Date();

        let body = {
            JobAd: mutate(
                {TrackingState: CREATE},
                JobAd,
            )
        };

        return super.create({body})
            .then(result => {

                if (!result.IsError) {
                }

                return result;
            }).catch(result => {
                return result.data;
            })
    }

    update(JobAd = {}) {

        let body = {
            JobAd: mutate(
                {TrackingState: UPDATE},
                JobAd,
            )
        };

        return super.update({body})
            .then(result => {

                if (!result.IsError) {
                }

                return result;
            }).catch(result => {
                return result.data;
            })
    }

    delete(JobAd = {}) {

        let body = {
            JobAd: mutate(
                {TrackingState: DELETE},
                JobAd,
            )
        };

        return super.delete({body})
            .then(result => {

                if (!result.IsError) {
                }

                return result;
            }).catch(result => {
                return result.data;
            })
    }


    readPublicView(fkJobAd) {

        return axios.post(restUri.GET_JOB_AD_PROFILE, restBody({
                FkJobAd: fkJobAd,
            })
        ).then(result => {
            if (!result.data.IsError) {
                return result.data;
            } else return result.data
        });
    }

    SetProfileFavourite(fkJobAd, IsFavourite = null, IsLiked = null) {
        return axios.post(restUri.SET_OFFICE_JOBAD_FAVOURITE, restBody({
                FkJobAd: fkJobAd,
                IsFavourite: IsFavourite,
                IsLike: IsLiked
            })
        ).then(result => {
            return result;
        }).catch(result => {
            return result;
        });

    }

    GetProfilePictureJobAd(fkCompany, fkJobAd) {
        return axios.post(restUri.DOWNLOAD_PROFILE_PICTURE_JOB_AD, restBody({
                PkCompany: fkCompany,
                PkJobAd: fkJobAd
            }),
            {
                responseType: 'blob'
            }
        ).then(result => {
            return result;
        }).catch(result => {
            return result;
        });

    }

    GetProfilePictureJobAdPublic(fkCompany, fkJobAd) {
        return axios.post(restUri.DOWNLOAD_PROFILE_PICTURE_JOB_AD, restBody({
                PkCompany: fkCompany,
                PkJobAd: fkJobAd
            }),
            {
                responseType: 'blob'
            }
        ).then(result => {
            return result;
        }).catch(result => {
            return result;
        });

    }

    GetVideoFileJobAd(fkCompany, fkJobAd) {
        return axios.post(restUri.DOWNLOAD_VIDEO_FILE_JOB_AD, restBody({
                PkCompany: fkCompany,
                PkJobAd: fkJobAd
            }),
            {
                responseType: 'blob'
            }
        ).then(result => {
            return result.data;
        }).catch(result => {
            return result;
        });

    }

}

