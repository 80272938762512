<template>
    <div class="">
        <div class="section-heading">
            <router-link to="Profile" class="no-decoration gray-color">
                <font-awesome-icon icon="fas fa-user"/>
                {{ $t('PROFILE') }}
            </router-link>
            <span class="no-decoration breadcrumb-decorate-yellow">
                <font-awesome-icon icon="fas fa-angle-right"/> &nbsp;
                <font-awesome-icon icon="fas fa-envelope"/>
                {{ $t('USER-NOTIFICATIONS') }}
            </span>
        </div>

        <div class="edit-account-wrap">
            <b-container fluid>
                <b-row>
                    <table class="table table-sm table-striped table-hover table-bordered table-sm">
                        <thead>
                        <tr>
                            <th scope="col" class="col-td">{{ $t('TRIGGER-TYPE') }}
                            </th>
                            <th scope="col" class="col-td">{{ $t('GET-EMAIL') }}
                            </th>
                            <th scope="col" class="col-td">{{ $t('GET-NOTIFICATION') }}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(notify, idx) in notifications" :key="idx">
                            <tr>
                                <td class="col-td">{{ getTriggerTypeName(notify.NotificationType) }}</td>
                                <td class="col-td">
                                    <div class="checkbox-outline-primary custom-control custom-checkbox">
                                        <input type="checkbox"
                                               v-model="notify.GetEmail"
                                               :checked="notify.GetEmail"
                                               class="custom-control-input"
                                               :id="'getemail_'+idx">
                                        <label class="custom-control-label" :for="'getemail_'+idx"></label>
                                    </div>

                                </td>
                                <td class="col-td">
                                    <div class="checkbox-outline-primary custom-control custom-checkbox">
                                        <input type="checkbox"
                                               v-model="notify.GetNotification"
                                               :checked="notify.GetNotification"
                                               class="custom-control-input"
                                               :id="'getnotifiy_'+idx">
                                        <label class="custom-control-label"
                                               :for="'getnotifiy_'+idx"></label>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </b-row>
                <b-row>
                    <b-col>
                        <base-button
                            native-type="button"
                            class="btn btn-mint btn-rounded float-right"
                            @click="save">
                            <font-awesome-icon v-if="!processing" icon="fas fa-check"/>
                            <font-awesome-icon v-if="processing" icon="fas fa-spin fa-circle-notch"/>
                            {{ $t('SAVE') }}
                        </base-button>
                    </b-col>

                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
/**
 * View: EditAccount
 * Path: /UserAccount/EditAccount
 * A sub view for the user account area.
 * Helps editing a user's credentials by the user.
 *
 */

import UsersController from "../../../controllers/users.controller";
import {NOTIFICATION_TYPE_DROPDOWN} from "@/data/notificationTypes";
import NotificationController from "@/controllers/notification.controller";

export default {
    name: "UserNotification",
    data() {
        return {
            controller: new NotificationController(this),
            processing: false,
            notifications: []
        }
    },
    computed: {
        notificationList() {
            return this.notifications;
        }
    },
    methods: {
        save() {

            if (!this.notifications || !Array.isArray(this.notifications) || this.notifications.length <= 0) return;

            this.processing = true;

            this.controller.save(this.notifications).then(
                (data) => {
                    if (!data.IsError) {
                        this.notifications = data.UserNotificationList
                        this.$notify(
                            {
                                title: this.trans('SUCCESS'),
                                text: this.trans('DATA-SAVED'),
                                type: 'success'
                            });

                    } else {
                        this.$notify(
                            {
                                title: this.trans('ERROR'),
                                text: this.trans(data.ErrorMessage),
                                type: 'error'
                            });
                    }

                    this.processing = false;
                }
            );
        },
        getTriggerTypeName(triggerType) {
            let type = NOTIFICATION_TYPE_DROPDOWN.filter(n => n.value == triggerType);
            return type != null && Array.isArray(type) && type.length > 0 ? this.trans(type[0].label) : '';
        }
    },
    activated() {
        this.controller.read().then((result) => {
                if (!result.IsError)
                    this.notifications = result.UserNotificationList
            }
        );
    }
}
</script>

<style lang="scss" scoped>
.no-scroll {
    overflow: hidden;
}

.gray-color {
    color: #858796 !important;
}

.gray-color:hover {
    color: #2E63BF !important;
}

.edit-account-wrap {
    position: absolute;
    display: block;
    width: calc(100% - 32px);
    height: calc(100vh - 106px);
    left: 0;
    margin-top: 10px;
    overflow: auto;
}

.col-td {
    width: 110px;
    text-align: center;
    white-space: nowrap;
}

.col-highlight {
    width: 200px;
    font-weight: bold;
}

</style>
