import {useCookies} from "vue3-cookies";

/**
 * Removes all cookies that ar related to the session and user.
 * It removes following cookies by task:
 * rememberMe : true or false cookie for remember me
 * Client: The short Mandant name for entering it automatically in the login ui
 * Username: The user name for entering it automatically in the login ui
 * rememberId: The session id to remember.
 */
const {cookies} = useCookies();

export function cookiePurge() {
    cookies.remove('rememberMe');
    cookies.remove('loggedIn');
    cookies.remove('loggedInSessionId');
    cookies.remove('Client');
    cookies.remove('Email');
    cookies.remove('rememberId');
}
