<template>
    <div class="tree-header">
        <div class="tree-header-label float-left">
            <p>
                {{ CurrentHeaderText }}
            </p>
        </div>
        <div class="clearfix"></div>
    </div>
</template>

<script>


import lodash from "lodash";
import store from "@/store";

export default {
    components: {},
    name: "LimitHeader",
    props: ['headerType'],
    computed: {
        CurrentHeaderType() {
            return this.$props.headerType ? this.$props.headerType : 0;
        },
        CurrentHeaderText() {
            let text = "";
            switch (this.CurrentHeaderType) {
                case 1:
                    text = this.trans('LIMIT-TEXT-ONE') + store.state.CompanyData.CurrentJobAds + this.trans('LIMIT-FROM') + store.state.CompanyData.MaxJobAds + this.trans('LIMIT-TEXT-TWO-JOB-AD')
                    break;
                case 2:
                    text = this.trans('LIMIT-TEXT-ONE') + store.state.CompanyData.CurrentUsers + this.trans('LIMIT-FROM') + store.state.CompanyData.MaxUsers + this.trans('LIMIT-TEXT-TWO-USERS')
                    break;
                case 3:
                    text = this.trans('LIMIT-TEXT-ONE') + store.state.CompanyData.CurrentOffices + this.trans('LIMIT-FROM') + store.state.CompanyData.MaxOffices + this.trans('LIMIT-TEXT-TWO-OFFICE')
                    break;
                case 4:
                    text = this.trans('LIMIT-TEXT-ONE') + store.state.CompanyData.CurrentMatches + this.trans('LIMIT-FROM') + store.state.CompanyData.MaxMatches + this.trans('LIMIT-TEXT-TWO-MATCHES')
                    break;
            }

            return text;
        }
    },
    data() {
        return {}
    },

    methods: {},
    beforeMount() {
    }
}
</script>

<style scoped>

.tree-header {
    position: relative;
    display: flex;
    background-color: transparent;
    padding: 5px 10px 5px 10px;
    font-weight: bold;
    font-size: 20px;
    min-height: 40px;
    box-shadow: -2px 4px 10px -6px rgba(0, 0, 0, 0.45);
    border-bottom: 1px solid #9acccb;
}

.tree-header-label {
    font-size: 16px;
    padding-top: 4px;
    display: flex;
}

p {
    margin-bottom: 0px !important;
}

.no-left-padding > .input {
    padding-left: 0px !important;
}

</style>
