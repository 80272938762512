<template>
    <div class="panel-heading">
        <div class="row">
            <div class="col-xl-10 col-md-10 col-sm-10 col-10">
                <h4>{{ trans('OFFICE') }}</h4>
            </div>
            <div class="col-xl-10 col-md-10 col-sm-10 col-10">
                <base-button @click="openOffice" native-type="button"
                             class="btn btn-yellow btn-sm float-right">
                    <font-awesome-icon icon="fas fa-eye" style="margin-right:10px;"/>
                    {{ trans('OFFICE-PROFILE') }}
                </base-button>
            </div>
        </div>
    </div>
    <div class="panel-body">
        <Form ref="valForm" :validationSchema="validationSchema" @submit="validate">
            <div class="row">
                <div class="col-lg-12 col-md-4">
                    <div class="upload mt-4 pe-md-4">
                        <input ref="fl_profile" type="file" class="d-none"
                               accept="image/*" @change="change_file"/>
                        <img
                            :src="selectedImage != null ? selectedImage :  require('@/assets/images/empty-profile.jpg')"
                            alt="profile" class="profile-preview-office profile-picture-office"
                            @click="$refs.fl_profile.click()"/>
                        <p><a href="javascript:;" class="mt-2"
                              @click="$refs.fl_profile.click()">{{
                                trans('UPLOAD-PICTURE-OFFICE')
                            }}</a></p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-5">
                    <base-input ref="OfficeName"
                                v-model="model.OfficeName"
                                :label="trans('OFFICE-NAME')"
                                type="text"
                                :required="true"
                                obj-name="OfficeName"
                                :max-length="150"
                                attached-class="form-control" placeholder="..."/>
                </div>
                <div class="form-group col-lg-3" v-if="IsCompany">
                    <member-dropdown ref="MemberCount"
                                     v-model="model.OfficeProfile.MemberCount"
                                     :label="trans('MEMBER-COUNT')"
                                     :items="MemberCountList"
                                     :no-check-icons="true"
                                     :null-value-text="trans('SELECT-MEMBER-COUNT')"
                                     :has-null-value="true"
                                     attached-class="form-control"
                                     name="MemberCount"/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-5">
                    <base-input ref="OfficeWebsite"
                                v-model="model.OfficeProfile.OfficeWebsite"
                                :label="trans('OFFICE-WEB-SITE')"
                                type="text"
                                :required="false"
                                obj-name="OfficeWebsite"
                                :max-length="150"
                                attached-class="form-control" placeholder="..."/>
                </div>
                <div class="form-group col-lg-3" v-if="IsCompany">
                    <base-input ref="TraineePerYear"
                                v-model="model.OfficeProfile.TraineePerYear"
                                :label="trans('TRAINEE-PER-YEAR')"
                                type="number"
                                :required="false"
                                obj-name="TraineePerYear"
                                :min-int="0"
                                attached-class="form-control" placeholder="..."/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-5">
                    <base-input ref="Street"
                                v-model="model.Street"
                                :label="trans('STREET')"
                                type="text"
                                :max-length="50"
                                attached-class="form-control"
                                obj-name="Street" :required="true" placeholder="..."/>
                </div>
                <div class="form-group col-lg-2">
                    <base-input ref="StreetNr"
                                v-model="model.StreetNr"
                                type="text"
                                :max-length="10"
                                :required="true"
                                :label="trans('STREET-NR')"
                                attached-class="form-control"
                                obj-name="StreetNr" placeholder="..."/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-5">
                    <base-input ref="Addition"
                                v-model="model.AddressAddition"
                                :label="trans('ADDITION')"
                                type="text"
                                :max-length="100"
                                attached-class="form-control"
                                obj-name="Addition" placeholder="..."/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-2">
                    <base-input ref="PostCode"
                                v-model="model.PostCode"
                                :label="trans('POSTCODE')"
                                type="text"
                                :max-length="10"
                                attached-class="form-control"
                                obj-name="PostCode" :required="true" placeholder="..."/>
                </div>
                <div class="form-group col-lg-5">
                    <base-input ref="City"
                                v-model="model.City"
                                type="text"
                                :max-length="50"
                                :required="true"
                                :label="trans('CITY')"
                                attached-class="form-control"
                                obj-name="City" placeholder="..."/>
                </div>
            </div>
            <div class="row margin-bottom-15">
                <div class="form-group col-lg-3">
                    <data-dropdown ref="Country"
                                   v-model="model.Country"
                                   :label="trans('COUNTRY')"
                                   :required="true"
                                   :no-check-icons="true"
                                   :items="CountriesList"
                                   :null-value-text="trans('SELECT-COUNTRY')"
                                   :has-null-value="true"
                                   attached-class="form-control"
                                   name="Country"/>
                </div>
            </div>

            <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                <form id="about" class="section about">
                    <div class="info">
                        <h5 class="">{{ trans('ABOUT-ME-OFFICE') }}</h5>
                        <div class="row">
                            <div class="col-md-11 mx-auto">
                                <div class="form-group">
                                    <base-text-area
                                        v-model="model.OfficeProfile.AboutUs"
                                        :required="false"
                                        obj-name="AboutUs"
                                        :label="trans('ABOUT-ME-OFFICE')"
                                        attached-class="form-control"
                                        placeholder="..."
                                        name="AboutUs"
                                        ref="AboutUs"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                <form id="contact" class="section contact">
                    <div class="info">
                        <h5 class="">{{ trans('JOBINEO-QUESTIONS') }}</h5>
                        <div class="row">
                            <div class="col-md-11 mx-auto">
                                <div class="row">
                                    <div class="form-group col-lg-6">
                                        <data-dropdown ref="JobineoQuestion1"
                                                       v-model="model.OfficeProfile.JobineoQuestion1"
                                                       :label="trans('JOBINEO-QUESTION-1')"
                                                       :no-check-icons="true"
                                                       :items="JobineoQuestionList"
                                                       :null-value-text="trans('SELECT-JOBINEO-QUESTION')"
                                                       :has-null-value="true"
                                                       attached-class="form-control"
                                                       name="JobineoQuestion1"/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-lg-6">
                                        <base-text-area
                                            v-model="model.OfficeProfile.JobineoAnswer1"
                                            obj-name="JobineoAnswer1"
                                            :label="trans('JOBINEO-ANSWER-1')"
                                            attached-class="form-control"
                                            placeholder="..."
                                            name="JobineoAnswer1"
                                            ref="JobineoAnswer1"/>

                                    </div>
                                    <div class="form-group col-lg-6" v-if="isLicensedVideo">
                                        <input ref="jobineoQAVideo1" type="file" class="d-none"
                                               accept="video/*" @change="uploadVideo1"/>
                                        <video class="videoPlayer" id="video-preview1" controls
                                               controlsList="nodownload">
                                        </video>
                                        <p>
                                            <base-button
                                                native-type="button"
                                                class="btn btn-yellow  margin-top-30"
                                                @click="$refs.jobineoQAVideo1.click()"
                                            >
                                                <font-awesome-icon icon="fas fa-plus-circle"/>
                                                &nbsp;{{ $t('UPLOAD-VIDEO') }}
                                            </base-button>
                                            <base-button
                                                native-type="button"
                                                class="btn btn-yellow  margin-top-30"
                                                @click="removeVideo1"
                                            >
                                                <font-awesome-icon icon="fas fa-trash"/>
                                                &nbsp;{{ $t('REMOVE-VIDEO') }}
                                            </base-button>
                                        </p>
                                    </div>
                                </div>
                                <div class="clearfix underlined-b-10 margin-top-15"></div>
                                <div class="row">
                                    <div class="form-group col-lg-6">
                                        <data-dropdown ref="JobineoQuestion2"
                                                       v-model="model.OfficeProfile.JobineoQuestion2"
                                                       :label="trans('JOBINEO-QUESTION-2')"
                                                       :no-check-icons="true"
                                                       :items="JobineoQuestionList"
                                                       :null-value-text="trans('SELECT-JOBINEO-QUESTION')"
                                                       :has-null-value="true"
                                                       attached-class="form-control"
                                                       name="JobineoQuestion2"/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-lg-6">
                                        <base-text-area
                                            v-model="model.OfficeProfile.JobineoAnswer2"
                                            obj-name="JobineoAnswer2"
                                            :label="trans('JOBINEO-ANSWER-2')"
                                            attached-class="form-control"
                                            placeholder="..."
                                            name="JobineoAnswer2"
                                            ref="JobineoAnswer2"/>

                                    </div>
                                    <div class="form-group col-lg-6" v-if="isLicensedVideo">
                                        <input ref="jobineoQAVideo2" type="file" class="d-none"
                                               accept="video/*" @change="uploadVideo2"/>
                                        <video class="videoPlayer" id="video-preview2" controls
                                               controlsList="nodownload"/>
                                        <p>
                                            <base-button
                                                native-type="button"
                                                class="btn btn-yellow  margin-top-30"
                                                @click="$refs.jobineoQAVideo2.click()"
                                            >
                                                <font-awesome-icon icon="fas fa-plus-circle"/>
                                                &nbsp;{{ $t('UPLOAD-VIDEO') }}
                                            </base-button>
                                            <base-button
                                                native-type="button"
                                                class="btn btn-yellow  margin-top-30"
                                                @click="removeVideo2"
                                            >
                                                <font-awesome-icon icon="fas fa-trash"/>
                                                &nbsp;{{ $t('REMOVE-VIDEO') }}
                                            </base-button>
                                        </p>
                                    </div>
                                </div>
                                <div class="clearfix underlined-b-10 margin-top-15"></div>
                                <div class="row">
                                    <div class="form-group col-lg-6">
                                        <data-dropdown ref="JobineoQuestion3"
                                                       v-model="model.OfficeProfile.JobineoQuestion3"
                                                       :label="trans('JOBINEO-QUESTION-3')"
                                                       :no-check-icons="true"
                                                       :items="JobineoQuestionList"
                                                       :null-value-text="trans('SELECT-JOBINEO-QUESTION')"
                                                       :has-null-value="true"
                                                       attached-class="form-control"
                                                       name="JobineoQuestion3"/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-lg-6">
                                        <base-text-area
                                            v-model="model.OfficeProfile.JobineoAnswer3"
                                            obj-name="JobineoAnswer3"
                                            :label="trans('JOBINEO-ANSWER-3')"
                                            attached-class="form-control"
                                            placeholder="..."
                                            name="JobineoAnswer3"
                                            ref="JobineoAnswer3"/>
                                    </div>
                                    <div class="form-group col-lg-6" v-if="isLicensedVideo">
                                        <input ref="jobineoQAVideo3" type="file" class="d-none"
                                               accept="video/*" @change="uploadVideo3"/>
                                        <video class="videoPlayer" id="video-preview3" controls
                                               controlsList="nodownload"/>
                                        <p>
                                            <base-button
                                                native-type="button"
                                                class="btn btn-yellow  margin-top-30"
                                                @click="$refs.jobineoQAVideo3.click()"
                                            >
                                                <font-awesome-icon icon="fas fa-plus-circle"/>
                                                &nbsp;{{ $t('UPLOAD-VIDEO') }}
                                            </base-button>
                                            <base-button
                                                native-type="button"
                                                class="btn btn-yellow  margin-top-30"
                                                @click="removeVideo3"
                                            >
                                                <font-awesome-icon icon="fas fa-trash"/>
                                                &nbsp;{{ $t('REMOVE-VIDEO') }}
                                            </base-button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                <form id="social" class="section social">
                    <div class="info">
                        <h5 class="">{{ trans('SOCIAL-NETWORKS') }}</h5>
                        <div class="row">
                            <div class="col-md-11 mx-auto">
                                <div class="row">
                                    <div class="col-md-6 form-group">

                                        <base-input
                                            v-model="model.OfficeProfile.LinkedInProfile"
                                            type="text"
                                            obj-name="LinkedInProfile"
                                            :required="false"
                                            :is-disabled="false"
                                            :label="trans('LINKED-IN')"
                                            attached-class="form-control"
                                            :placeholder="trans('LINKED-IN')"
                                            name="LinkedInProfile"
                                            ref="LinkedInProfile"/>
                                    </div>

                                    <div class="col-md-6 form-group">

                                        <base-input
                                            v-model="model.OfficeProfile.XingProfile"
                                            type="text"
                                            obj-name="XingProfile"
                                            :required="false"
                                            :is-disabled="false"
                                            :label="trans('XING')"
                                            attached-class="form-control"
                                            :placeholder="trans('XING')"
                                            name="XingProfile"
                                            ref="XingProfile"/>

                                    </div>
                                </div>
                            </div>

                            <div class="col-md-11 mx-auto">
                                <div class="row">
                                    <div class="col-md-6 form-group">

                                        <base-input
                                            v-model="model.OfficeProfile.FacebookProfile"
                                            type="text"
                                            obj-name="FacebookProfile"
                                            :required="false"
                                            :is-disabled="false"
                                            :label="trans('FACEBOOK')"
                                            attached-class="form-control"
                                            :placeholder="trans('FACEBOOK')"
                                            name="FacebookProfile"
                                            ref="FacebookProfile"/>

                                    </div>

                                    <div class="col-md-6 form-group">
                                        <base-input
                                            v-model="model.OfficeProfile.InstagramProfile"
                                            type="text"
                                            obj-name="InstagramProfile"
                                            :required="false"
                                            :is-disabled="false"
                                            :label="trans('INSTAGRAM')"
                                            attached-class="form-control"
                                            :placeholder="trans('INSTAGRAM')"
                                            name="InstagramProfile"
                                            ref="InstagramProfile"/>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-11 mx-auto">
                                <div class="row">
                                    <div class="col-md-6 form-group">
                                        <base-input
                                            v-model="model.OfficeProfile.TikTiokProfile"
                                            type="text"
                                            obj-name="TikTiokProfile"
                                            :required="false"
                                            :is-disabled="false"
                                            :label="trans('TIK-TOK')"
                                            attached-class="form-control"
                                            :placeholder="trans('TIK-TOK')"
                                            name="TikTiokProfile"
                                            ref="TikTiokProfile"/>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                <div id="skill" class="section skill">
                    <div class="info">
                        <h5 class="">{{ trans('OFFICE-KEYWORDS') }}</h5>
                        <div class="row progress-bar-section">
                            <div class="col-md-12 mx-auto">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-11 mx-auto">

                                            <div class="row">
                                                <div class="col-md-3 form-group">
                                                    <base-input
                                                        v-model="KeywordName"
                                                        type="text"
                                                        obj-name="KeywordName"
                                                        :required="true"
                                                        :is-disabled="false"
                                                        :label="trans('KEY-WORD')"
                                                        attached-class="form-control"
                                                        placeholder="..."
                                                        name="KeywordName"
                                                        ref="KeywordName"/>
                                                </div>
                                                <div class="col-md-2 form-group">
                                                    <base-button
                                                        native-type="button"
                                                        class="btn btn-yellow  margin-top-30"
                                                        @click="addKeyword"
                                                    >
                                                        <font-awesome-icon icon="fas fa-plus-circle"/>
                                                        &nbsp;{{ $t('ADD-KEY-WORD') }}
                                                    </base-button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-11 mx-auto margin-top-30">
                                <div class="custom-progress top-right progress-up">
                                    <template v-for="(item, idx) in KeywordList" :key="idx">
                                        <p class="skill-name">{{ item.Keyword }}
                                            <font-awesome-icon icon="fas fa-trash"
                                                               style="cursor: pointer;margin-left:25px;"
                                                               @click="removeKeyword(idx, item)"></font-awesome-icon>
                                        </p>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                <div id="skill" class="section skill">
                    <div class="info">
                        <h5 class="">{{ trans('OFFICE-BENEFITS') }}</h5>
                        <div class="row progress-bar-section">
                            <div class="col-md-12 mx-auto">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-11 mx-auto">

                                            <div class="row">
                                                <div class="col-md-3 form-group">
                                                    <base-input
                                                        v-model="BenefitName"
                                                        type="text"
                                                        obj-name="BenefitName"
                                                        :required="true"
                                                        :is-disabled="false"
                                                        :label="trans('BENEFIT')"
                                                        attached-class="form-control"
                                                        placeholder="..."
                                                        name="BenefitName"
                                                        ref="BenefitName"/>
                                                </div>
                                                <div class="col-md-2 form-group">
                                                    <base-button
                                                        native-type="button"
                                                        class="btn btn-yellow  margin-top-30"
                                                        @click="addBenefit"
                                                    >
                                                        <font-awesome-icon icon="fas fa-plus-circle"/>
                                                        &nbsp;{{ $t('ADD-BENEFIT') }}
                                                    </base-button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-11 mx-auto margin-top-30">
                                <div class="custom-progress top-right progress-up">
                                    <template v-for="(item, idx) in BenefitList" :key="idx">
                                        <p class="skill-name">{{ item.Benefit }}
                                            <font-awesome-icon icon="fas fa-trash"
                                                               style="cursor: pointer;margin-left:25px;"
                                                               @click="removeBenefit(idx, item)"></font-awesome-icon>
                                        </p>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing" v-if="IsCompany">
                <form id="edu-experience" class="section edu-experience">
                    <div class="info">
                        <h5 class="">{{ trans('OFFICE-SEARCH') }}</h5>
                        <div class="row">
                            <div class="col-md-12 text-end mb-5">
                                <base-button
                                    native-type="button"
                                    class="btn btn-yellow  margin-top-30"
                                    @click="addMySearch"
                                >
                                    <font-awesome-icon icon="fas fa-plus-circle"/>
                                    &nbsp;{{ $t('ADD-OFFICE-SEARCH') }}
                                </base-button>
                            </div>
                            <div class="col-md-11 mx-auto">
                                <div class="edu-section">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <div class="form-group">
                                                <data-dropdown ref="JobType"
                                                               v-model="JobType"
                                                               :label="trans('JOB-TYPE')"
                                                               :no-check-icons="true"
                                                               :required="true"
                                                               :items="JobTypeList"
                                                               :null-value-text="trans('SELECT-JOB-TYPE')"
                                                               :has-null-value="true"
                                                               attached-class="form-control"
                                                               name="JobType"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-5">
                                            <div class="form-group">
                                                <data-dropdown ref="JobFieldId"
                                                               v-model="JobFieldId"
                                                               :label="trans(GetStudyJobText)"
                                                               :no-check-icons="true"
                                                               :disabled="JobType==null"
                                                               :required="true"
                                                               :items="JobFieldIdList"
                                                               :null-value-text="trans(GetStudyJobSelectText)"
                                                               :has-null-value="true"
                                                               attached-class="form-control"
                                                               name="JobFieldId"/>
                                            </div>
                                        </div>
                                        <div class="col-md-1" v-if="ShowJobField">
                                            <div class="form-group"
                                                 style="text-align: center;margin-top:35px;">
                                                <h4>{{ trans('OR') }}</h4>
                                            </div>
                                        </div>
                                        <div class="col-md-5" v-if="ShowJobField">
                                            <div class="form-group">

                                                <data-dropdown ref="JobId"
                                                               v-model="JobId"
                                                               :label="trans('JOB-ID')"
                                                               :no-check-icons="true"
                                                               :required="true"
                                                               :disabled="JobType==null"
                                                               :items="JobIdList"
                                                               :null-value-text="trans('SELECT-JOB-ID')"
                                                               :has-null-value="true"
                                                               attached-class="form-control"
                                                               name="JobId"/>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix underlined-b-10"></div>
                        <div class="col-md-11 mx-auto margin-top-30">
                            <div class="custom-progress top-right progress-up">
                                <template v-for="(item, idx) in OfficeJobSelectionList" :key="idx">

                                    <p class="skill-name" v-if="item.JobFieldId!=null&&item.JobType==3">
                                        {{ GetStudyText(item.JobFieldId) }}
                                        <font-awesome-icon icon="fas fa-trash"
                                                           style="cursor: pointer;margin-left:25px;"
                                                           @click="removeJobSelection(idx, item)"></font-awesome-icon>
                                    </p>
                                    <p class="skill-name" v-if="item.JobFieldId!=null&&item.JobType!=3">
                                        {{ GetJobFieldText(item.JobFieldId) }}
                                        <font-awesome-icon icon="fas fa-trash"
                                                           style="cursor: pointer;margin-left:25px;"
                                                           @click="removeJobSelection(idx, item)"></font-awesome-icon>
                                    </p>
                                    <p class="skill-name" v-if="item.JobId!=null">{{
                                            GetJobText(item.JobId)
                                        }}
                                        <font-awesome-icon icon="fas fa-trash"
                                                           style="cursor: pointer;margin-left:25px;"
                                                           @click="removeJobSelection(idx, item)"></font-awesome-icon>
                                    </p>
                                </template>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div v-if="error" class="error-alert-wrap">
                <font-awesome-icon icon="fas fa-exclamation-triangle" class="float-right-margin"/>
                {{ trans(errorMessage) }}
            </div>

            <button ref="silentSubmit" type="submit" class="btn-silent-submit"/>
        </Form>
    </div>
</template>

<script>
import DataDropdown from "@/components/dropdowns/DataDropdown.vue";
import * as yup from 'yup';
import {Form} from 'vee-validate';
import MemberDropdown from "@/components/dropdowns/MemberDropdown.vue";
import BaseTextArea from "@/components/BaseTextArea.vue";
import store from "@/store";
import lodash from "lodash";
import {uuid} from "vue-uuid";
import {TRACKING_STATE} from "@/utils/applyTrackingState";
import {JOB_TYPES} from "@/data/jobTypes";
import {LICENSE_TYPES} from "@/data/licenseTypes";
import router from "@/router";
import {REGISTER_TYPES} from "@/data/RegisterTypes";

export default {
    name: "OfficeForm",
    props: ['fkOffice', 'officeController'],
    emits: ["update"],
    components: {
        BaseTextArea,
        MemberDropdown,
        Form, DataDropdown
    },
    data() {
        return {
            validationSchema: yup.object({
                Street: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                StreetNr: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                PostCode: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                City: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                OfficeName: yup.string().required(this.trans("REQUIRED-MESSAGE")),
            }),
            JobId: null,
            JobType: null,
            JobFieldId: null,
            Video1: null,
            Video2: null,
            Video3: null,
            KeywordName: null,
            BenefitName: null,
            selectedImage: null,
            model: {
                OfficeName: '',
                Street: '',
                StreetNr: '',
                PostCode: '',
                City: '',
                Country: null,
                AddressAddition: '',
                OfficeProfile: {
                    PkOfficeProfile: null,
                    FkCompany: null,
                    FkOffice: null,
                    MemberCount: null,
                    FacebookProfile: null,
                    InstagramProfile: null,
                    TikTokProfile: null,
                    LinkedInProfile: null,
                    XingProfile: null,
                    AboutUs: null,
                    JobineoQuestion1: null,
                    JobineoAnswer1: null,
                    JobineoAnswerFile1: null,
                    JobineoQuestion2: null,
                    JobineoAnswer2: null,
                    JobineoAnswerFile2: null,
                    JobineoQuestion3: null,
                    JobineoAnswer3: null,
                    JobineoAnswerFile3: null,
                    OfficeWebsite: null,
                    TraineePerYear: null,
                },
                JobSelectionList: [],
                OfficeBenefitList: [],
                OfficeKeywordList: [],
            },
            error: false,
            errorMessage: ''
        }
    },
    watch: {
        JobId(newValue, oldValue) {
            if (newValue != null) {
                this.JobFieldId = null;
            }
        },
        JobFieldId(newValue, oldValue) {
            if (newValue != null) {
                this.JobId = null;
            }
        },
        JobType(newValue, oldValue) {
            if (newValue == null) {
                this.JobId = null;
                this.JobFieldId = null;
            }
        },

    },
    computed: {
        IsCompany() {
            return store.state.RegisterType == REGISTER_TYPES.COMPANY;
        },
        isLicensedVideo() {
            return this.isLicensed([LICENSE_TYPES.PACKAGE_M, LICENSE_TYPES.PACKAGE_L], false)
        },
        controller() {
            return this.$props.officeController ? this.$props.officeController : {};
        },
        ShowJobField() {
            return this.JobType == null || this.JobType != JOB_TYPES.DUAL_STUDY
        },
        GetStudyJobText() {
            return this.JobType == JOB_TYPES.DUAL_STUDY ? 'STUDY-FIELD' : 'JOB-FIELD'
        },
        GetStudyJobSelectText() {
            return this.JobType == JOB_TYPES.DUAL_STUDY ? 'SELECT-STUDY-FIELD' : 'SELECT-JOB-FIELD'
        },
        JobTypeList() {
            return this.$store.state.Lookups.JobAdTypesLookup != null && Array.isArray(this.$store.state.Lookups.JobAdTypesLookup) ? this.$store.state.Lookups.JobAdTypesLookup : [];
        },
        JobFieldIdList() {
            if (this.JobType === null)
                return [];
            else if (this.JobType == JOB_TYPES.DUAL_STUDY)
                return this.$store.state.Lookups.StudyFieldsLookup != null && Array.isArray(this.$store.state.Lookups.StudyFieldsLookup) ? this.$store.state.Lookups.StudyFieldsLookup : [];
            else
                return this.$store.state.Lookups.JobFieldsLookup != null && Array.isArray(this.$store.state.Lookups.JobFieldsLookup) ? this.$store.state.Lookups.JobFieldsLookup : [];
        },
        JobIdList() {
            if (this.JobType === null)
                return [];
            else if (this.JobType == JOB_TYPES.DUAL_STUDY)
                return this.$store.state.Lookups.StudyFieldsLookup != null && Array.isArray(this.$store.state.Lookups.StudyFieldsLookup) ? this.$store.state.Lookups.StudyFieldsLookup : [];
            else
                return this.$store.state.Lookups.JobsLookup != null && Array.isArray(this.$store.state.Lookups.JobsLookup) ? this.$store.state.Lookups.JobsLookup : [];
        },
        OfficeJobSelectionList() {
            if (this.model.JobSelectionList != null && Array.isArray(this.model.JobSelectionList)) {
                var items = lodash.filter(this.model.JobSelectionList, x => x.TrackingState != TRACKING_STATE.DELETE);
                if (items != null && items.length > 0)
                    return items;
                else
                    return [];
            } else {
                return [];
            }
        },
        CountriesList() {
            return this.$store.state.Lookups.CountriesLookup != null && Array.isArray(this.$store.state.Lookups.CountriesLookup) ? this.$store.state.Lookups.CountriesLookup : [];
        },
        JobineoQuestionList() {
            return this.$store.state.Lookups.CompanyQuestionsLookup != null && Array.isArray(this.$store.state.Lookups.CompanyQuestionsLookup) ? this.$store.state.Lookups.CompanyQuestionsLookup : [];
        },
        MemberCountList() {
            return this.$store.state.Lookups.CompanySizesLookup != null && Array.isArray(this.$store.state.Lookups.CompanySizesLookup) ? this.$store.state.Lookups.CompanySizesLookup : [];
        },
        KeywordList() {
            if (this.model.OfficeKeywordList != null && Array.isArray(this.model.OfficeKeywordList)) {
                var items = lodash.filter(this.model.OfficeKeywordList, x => x.TrackingState != TRACKING_STATE.DELETE);
                if (items != null && items.length > 0)
                    return items;
                else
                    return [];
            } else {
                return [];
            }

        },
        BenefitList() {
            if (this.model.OfficeBenefitList != null && Array.isArray(this.model.OfficeBenefitList)) {
                var items = lodash.filter(this.model.OfficeBenefitList, x => x.TrackingState != TRACKING_STATE.DELETE);
                if (items != null && items.length > 0)
                    return items;
                else
                    return [];
            } else {
                return [];
            }

        },
    },
    methods: {
        GetStudyText(id) {
            let items = lodash.filter(this.$store.state.Lookups.StudyFieldsLookup, x => x.DataKey == id);
            if (items != null && items.length > 0)
                return items[0].DataValue
            else return ''
        },
        GetJobFieldText(id) {
            let items = lodash.filter(this.$store.state.Lookups.JobFieldsLookup, x => x.DataKey == id);
            if (items != null && items.length > 0)
                return items[0].DataValue
            else return ''
        },
        GetJobText(id) {
            let items = lodash.filter(this.$store.state.Lookups.JobsLookup, x => x.DataKey == id);
            if (items != null && items.length > 0)
                return items[0].DataValue
            else return ''
        },
        openOffice() {
            router.push("/OfficeProfileView/" + this.model.PkOffice)
        },
        addMySearch() {
            if (this.JobType == null ||
                (this.JobFieldId == null &&
                    this.JobId == null)) {
                this.$notify(
                    {
                        title: this.trans('ERROR'),
                        text: this.trans("JOB-SELECTION-EMPTY"),
                        type: 'error',
                    });

                return;
            }

            if (this.JobFieldId != null) {
                let lang = lodash.filter(this.model.JobSelectionList, x => x.JobFieldId == this.JobFieldId && x.JobType == this.JobType);
                if (lang != null && lang.length > 0)
                    return;
            }

            if (this.JobId != null) {
                let lang = lodash.filter(this.model.JobSelectionList, x => x.JobId == this.JobId && x.JobType == this.JobType);
                if (lang != null && lang.length > 0)
                    return;
            }

            if (this.model.JobSelectionList == null)
                this.model.JobSelectionList = [];

            this.model.JobSelectionList.push({
                PkOfficeJobSelection: uuid.v4(),
                FkCompany: this.model.FkCompany,
                FkOffice: this.model.PkOffice,
                JobFieldId: this.JobFieldId,
                JobId: this.JobId,
                JobType: this.JobType,
                TrackingState: TRACKING_STATE.CREATE
            });

            this.JobType = null;
            this.JobFieldId = null
            this.JobId = null
        },
        removeJobSelection(id, item) {
            if (item.TrackingState != null && item.TrackingState == TRACKING_STATE.CREATE)
                this.model.JobSelectionList.splice(id, 1);
            else
                item.TrackingState = TRACKING_STATE.DELETE;
        },
        addKeyword() {

            if (this.KeywordName == null) {
                this.$notify(
                    {
                        title: this.trans('ERROR'),
                        text: this.trans("KEYWORD-NAME-EMPTY"),
                        type: 'error',
                    });

                return;
            }


            let lang = lodash.filter(this.model.OfficeKeywordList, x => x.KeywordName == this.KeywordName);
            if (lang != null && lang.length > 0)
                return;

            if (this.model.OfficeKeywordList == null)
                this.model.OfficeKeywordList = [];

            this.model.OfficeKeywordList.push({
                PkOfficeKeyword: uuid.v4(),
                FkCompany: this.model.FkCompany,
                FkOffice: this.model.PkOffice,
                Keyword: this.KeywordName,
                TrackingState: TRACKING_STATE.CREATE
            });

            this.KeywordName = null;
        },
        removeKeyword(id, item) {
            if (item.TrackingState != null && item.TrackingState == TRACKING_STATE.CREATE)
                this.model.OfficeKeywordList.splice(id, 1);
            else
                item.TrackingState = TRACKING_STATE.DELETE;
        },
        addBenefit() {

            if (this.BenefitName == null) {
                this.$notify(
                    {
                        title: this.trans('ERROR'),
                        text: this.trans("BENEFIT-NAME-EMPTY"),
                        type: 'error',
                    });

                return;
            }


            let lang = lodash.filter(this.model.OfficeBenefitList, x => x.BenefitName == this.BenefitName);
            if (lang != null && lang.length > 0)
                return;

            if (this.model.OfficeBenefitList == null)
                this.model.OfficeBenefitList = [];

            this.model.OfficeBenefitList.push({
                PkOfficeBenefit: uuid.v4(),
                FkCompany: this.model.FkCompany,
                FkOffice: this.model.PkOffice,
                Benefit: this.BenefitName,
                TrackingState: TRACKING_STATE.CREATE
            });

            this.BenefitName = null;
        },
        removeBenefit(id, item) {
            if (item.TrackingState != null && item.TrackingState == TRACKING_STATE.CREATE)
                this.model.OfficeBenefitList.splice(id, 1);
            else
                item.TrackingState = TRACKING_STATE.DELETE;
        },
        removeVideo1() {

            if (!this.isLicensedVideo)
                return;

            let video = document.getElementById('video-preview1');
            video.pause();
            video.removeAttribute('src'); // empty source
            video.load();
            this.Video1 = null;
            store.dispatch('uploadVideoFileOffice', {
                PkCompany: this.model.FkCompany,
                PkOffice: this.model.PkOffice,
                VideoNo: 1,
                OnlyDelete: true
            }).then(res => {
                if (res.IsError) {
                    this.$notify(
                        {
                            title: this.trans('ERROR'),
                            text: this.trans(res.ErrorMessage),
                            type: 'error',
                        });
                }
            });
        },
        removeVideo2() {
            if (!this.isLicensedVideo)
                return;
            let video = document.getElementById('video-preview2');
            video.pause();
            video.removeAttribute('src'); // empty source
            video.load();
            this.Video2 = null;
            store.dispatch('uploadVideoFileOffice', {
                PkCompany: this.model.FkCompany,
                PkOffice: this.model.PkOffice,
                VideoNo: 2,
                OnlyDelete: true
            }).then(res => {
                if (res.IsError) {
                    this.$notify(
                        {
                            title: this.trans('ERROR'),
                            text: this.trans(res.ErrorMessage),
                            type: 'error',
                        });
                }
            });
        },
        removeVideo3() {
            if (!this.isLicensedVideo)
                return;
            let video = document.getElementById('video-preview3');
            video.pause();
            video.removeAttribute('src'); // empty source
            video.load();
            this.Video3 = null;
            store.dispatch('uploadVideoFileOffice', {
                PkCompany: this.model.FkCompany,
                PkOffice: this.model.PkOffice,
                VideoNo: 3,
                OnlyDelete: true
            }).then(res => {
                if (res.IsError) {
                    this.$notify(
                        {
                            title: this.trans('ERROR'),
                            text: this.trans(res.ErrorMessage),
                            type: 'error',
                        });
                }
            });
        },
        uploadVideo1() {
            if (!this.isLicensedVideo)
                return;
            if ((event.target.files[0].size / 1024 / 1024) > 20) {
                this.$notify(
                    {
                        title: this.trans('ERROR'),
                        text: this.trans("VIDEO-FILE-SIZE-EXCEED-20"),
                        type: 'error',
                    });
                return;
            }

            this.Video1 = event.target.files[0];

            this.error = false;
            this.errorMessage = null


            if (!this.Video1) {
                event.preventDefault();
                event.target.value = null;
                return;
            }


            store.dispatch('uploadVideoFileOffice', {
                Video: this.Video1,
                PkCompany: this.model.FkCompany,
                PkOffice: this.model.PkOffice,
                VideoNo: 1
            }).then(res => {
                if (res.IsError) {
                    this.$notify(
                        {
                            title: this.trans('ERROR'),
                            text: this.trans(res.ErrorMessage),
                            type: 'error',
                        });
                } else {
                    this.previewVideo1();
                }
            });


        },
        previewVideo1() {
            if (!this.isLicensedVideo)
                return;
            let video = document.getElementById('video-preview1');
            let reader = new FileReader();

            reader.readAsDataURL(this.Video1);
            reader.addEventListener('load', function () {
                video.src = reader.result;
            });
        },
        uploadVideo2() {
            if (!this.isLicensedVideo)
                return;
            if ((event.target.files[0].size / 1024 / 1024) > 20) {
                this.$notify(
                    {
                        title: this.trans('ERROR'),
                        text: this.trans("VIDEO-FILE-SIZE-EXCEED-20"),
                        type: 'error',
                    });
                return;
            }

            this.Video2 = event.target.files[0];

            this.error = false;
            this.errorMessage = null


            if (!this.Video2) {
                event.preventDefault();
                event.target.value = null;
                return;
            }


            store.dispatch('uploadVideoFileOffice', {
                Video: this.Video2,
                PkCompany: this.model.FkCompany,
                PkOffice: this.model.PkOffice,
                VideoNo: 2
            }).then(res => {
                if (res.IsError) {
                    this.$notify(
                        {
                            title: this.trans('ERROR'),
                            text: this.trans(res.ErrorMessage),
                            type: 'error',
                        });
                } else {
                    this.previewVideo2();
                }
            });
        },
        previewVideo2() {
            if (!this.isLicensedVideo)
                return;
            let video = document.getElementById('video-preview2');
            let reader = new FileReader();

            reader.readAsDataURL(this.Video2);
            reader.addEventListener('load', function () {
                video.src = reader.result;
            });
        },
        uploadVideo3() {
            if (!this.isLicensedVideo)
                return;
            if ((event.target.files[0].size / 1024 / 1024) > 20) {
                this.$notify(
                    {
                        title: this.trans('ERROR'),
                        text: this.trans("VIDEO-FILE-SIZE-EXCEED-20"),
                        type: 'error',
                    });
                return;
            }

            this.Video3 = event.target.files[0];

            this.error = false;
            this.errorMessage = null


            if (!this.Video3) {
                event.preventDefault();
                event.target.value = null;
                return;
            }


            store.dispatch('uploadVideoFileOffice', {
                Video: this.Video3,
                PkCompany: this.model.FkCompany,
                PkOffice: this.model.PkOffice,
                VideoNo: 3
            }).then(res => {
                if (res.IsError) {
                    this.$notify(
                        {
                            title: this.trans('ERROR'),
                            text: this.trans(res.ErrorMessage),
                            type: 'error',
                        });
                } else {
                    this.previewVideo3();
                }
            });
        },
        previewVideo3() {
            if (!this.isLicensedVideo)
                return;
            let video = document.getElementById('video-preview3');
            let reader = new FileReader();

            reader.readAsDataURL(this.Video3);
            reader.addEventListener('load', function () {
                video.src = reader.result;
            });
        },
        change_file() {

            let file = event.target.files[0];
            this.error = false;
            this.errorMessage = null
            let MAX_FILE_SIZE = 8 * 1024 * 1024;
            let data = this.model;
            let controller = this.controller;


            if (!file) {
                event.preventDefault();
                event.target.value = null;
                return;
            }

            if (file.size > MAX_FILE_SIZE) {
                event.preventDefault();
                event.target.value = null;
                this.error = true;
                this.errorMessage = "FILE-TO-BIG"
                return;
            }
            let fileReader = new FileReader();

            fileReader.onloadend = function (e) {
                let arr = (new Uint8Array(e.target.result)).subarray(0, 4);

                let header = "";
                for (let i = 0; i < arr.length; i++) {
                    header += arr[i].toString(16);
                }

                let type = "";
                switch (header) {
                    case "89504e47":
                        type = "image/png";
                        break;
                    case "47494638":
                        type = "image/gif";
                        break;
                    case "ffd8ffe0":
                    case "ffd8ffe1":
                    case "ffd8ffe2":
                    case "ffd8ffe3":
                    case "ffd8ffe8":
                        type = "image/jpeg";
                        break;
                    case "255044462D":
                    case "25504446":
                        type = "pdf";
                        break;
                    default:
                        type = "unknown"; // Or you can use the blob.type as fallback
                        break;
                }

                if (type !== "unknown") {
                    data.FileName = file.name;
                    data.ProfilePicture = file;
                    store.dispatch('uploadProfilePictureOffice', {
                        ProfilePicture: data.ProfilePicture,
                        PkOffice: data.PkOffice,
                        PkCompany: data.FkCompany
                    }).then(res => {
                        controller.GetProfilePictureOffice(data.FkCompany, data.PkOffice).then(result => {

                            if (result.data.size > 0)
                                this.selectedImage = URL.createObjectURL(new Blob([result.data]));
                            else
                                this.selectedImage = null;
                        })
                    });

                } else {
                    event.preventDefault();
                    event.target.value = null;

                }
            }.bind(this)

            fileReader.readAsArrayBuffer(file);

        },
        setData(data = {}) {
            this.model = data;
            if (this.model.PkOffice != null) {
                this.controller.GetProfilePictureOffice(data.FkCompany, data.PkOffice).then(result => {

                    if (result.data.size > 0)
                        this.selectedImage = URL.createObjectURL(new Blob([result.data]));
                    else
                        this.selectedImage = null;

                    if (this.isLicensedVideo) {


                        this.controller.GetVideoFileOffice(data.FkCompany, data.PkOffice, 1).then(result => {
                            if (result != null) {
                                this.Video1 = result;
                                this.previewVideo1();
                            }
                        })
                        this.controller.GetVideoFileOffice(data.FkCompany, data.PkOffice, 2).then(result => {
                            if (result != null) {
                                this.Video2 = result;
                                this.previewVideo2();
                            }
                        })
                        this.controller.GetVideoFileOffice(data.FkCompany, data.PkOffice, 3).then(result => {
                            if (result != null) {
                                this.Video3 = result;
                                this.previewVideo3();
                            }
                        })
                    }
                })
            }

        },
        getData() {
            return this.model;
        },
        validate() {
            this.error = false;
            this.errorMessage = '';

            return this.$refs.valForm.validate().then(result => {
                let otherErrors = false;
                if (this.model.Country == null || this.model.Country <= 0) {
                    this.$refs.Country.setError();
                    otherErrors = true;
                }

                if (this.model.Salutation <= 0) {
                    this.$refs.Salutation.setError();
                    otherErrors = true;
                }


                if (result.valid && !otherErrors) {
                    this.$emit('update', true)
                } else {

                    for (const key in result.errors) {
                        this.$refs[key].setError();
                    }

                    return Promise.resolve(false);
                }
            })
        },
    },
    beforeUpdate() {
    },
    mounted() {
    }
}
</script>

<style scoped>

</style>
