/**

 */
const
    RESTServices = '/api/',
    LoginService = RESTServices + 'Login/',
    RegisterService = RESTServices + 'Register/',
    UserService = RESTServices + 'User/',
    PasswordService = RESTServices + 'Password/',
    EmailService = RESTServices + 'Email/',
    CompanyService = RESTServices + 'Company/',
    DashboardService = RESTServices + 'Dashboard/',
    SystemService = RESTServices + 'System/',
    MessagesService = RESTServices + 'Message/',
    PricingService = RESTServices + 'Pricing/',
    StudentService = RESTServices + 'Student/',
    AppointmentService = RESTServices + 'Appointment/',
    MatchingService = RESTServices + 'Matching/',
    FileService = RESTServices + 'File/',
    SearchService = RESTServices + 'Search/',
    FavouriteService = RESTServices + 'Favourite/'

export {
    RESTServices,
    LoginService,
    RegisterService,
    UserService
}


export const restUri = {

    // File based action endpoints. Except UPLOAD_FILE , which requires POST method
    // All other file based endpoints are requiring the GET method.


    // Normal POST and JSON based endpoints

    'LOGIN': LoginService,
    'REGISTER': RegisterService,
    'REGISTER_CHECK_1_PAGE': RegisterService + 'CheckFirstPage',
    'CHANGE_PASSWORD': PasswordService + 'ChangePassword',
    'CHANGE_PASSWORD_FROM_LINK': PasswordService + 'ChangePasswordFromLink',
    'REACCEPT_AGB_DSGVO': LoginService + 'ReacceptAGBDSGVO',
    'CHECK_MFA': LoginService + 'CheckMFA',
    'CONFIRM_EMAIL_ADDRESS': EmailService + 'ConfirmEmailAddress',
    'RE_SEND_EMAIL': EmailService + 'ReSendEmail',
    'RE_SEND_EMAIL_PARENT': EmailService + 'ReSendEmailParent',
    'CONFIRM_PARENT_EMAIL_ADDRESS': EmailService + 'ConfirmParentEmailAddress',
    'RESET_PASSWORD': PasswordService + 'ResetPassword',
    'GET_SYS_SETTINGS': SystemService + 'GetSysSettings',
    'GET_SYSTEM_NOTIFICATIONS': SystemService + 'GetSysNotifications',

    /** PRICING **/
    'GET_PRICINGS': PricingService + 'GetPricingItems',


    /** Favourite **/
    'GET_FAVOURITES': FavouriteService + 'GetFavourites',
    'REMOVE_FAVOURITE': FavouriteService + 'RemoveFavourite',

    /** COMPANY **/
    'GET_COMPANY': CompanyService + 'GetCompany',
    'GET_OFFICE_PROFILE':CompanyService + 'GetOfficeProfile',
    'GET_COMPANY_PROFILE':CompanyService + 'GetCompanyProfile',
    'GET_JOB_AD_PROFILE':CompanyService + 'GetJobAdProfile',
    'GET_OFFICES': CompanyService + 'GetOffices',
    'COMPANY_CUD': CompanyService + 'CompanyCUD',
    'OFFICE_CUD': CompanyService + 'OfficeCUD',
    'GET_COMPANY_LICENSES': CompanyService + 'GetCompanyLicenses',
    'COMPANY_LICENSE_CUD': CompanyService + 'CompanyLicenseCUD',
    'GET_INVOICES': CompanyService + 'GetInvoices',
    'DOWNLOAD_INVOICE': FileService + 'DownloadInvoice',
    'GET_JOB_ADS': CompanyService + 'GetJobAds',
    'JOB_AD_CUD': CompanyService + 'JobAdCUD',
    'GET_LIMITATIONS_AND_LICENSES': CompanyService + 'GetLimitationsAndLicenses',
    'SET_OFFICE_JOBAD_FAVOURITE': CompanyService + 'SetOfficeJobAdFavourite',
    'GET_TOP_COMPANIES': CompanyService + 'GetTopCompanies',

    /** DASHBOARD-FUNCTIONS **/
    'DASHBOARD_CONFIG_CUD': DashboardService + 'DashboardConfigCUD',
    'GET_DASHBOARD_CONFIGS': DashboardService + 'GetDashboardConfig',
    'GET_NOTIFICATIONS': DashboardService + 'GetNotifications',
    'SET_NOTIFIED': DashboardService + 'SetNotified',
    'GET_DASHBOARD_BOXES': DashboardService + 'GetDashboardBoxes',

    /** SEARCH **/
    'GET_POSTCODES': SearchService + 'GetPostCodes',
    'GET_SEARCH_PROFILES': SearchService + 'GetSearchProfiles',

    /** USER-FUNCTIONS **/
    'USER_CUD': UserService + 'UserCUD',
    'GET_USERS': UserService + 'GetUsers',
    'UPLOAD_PROFILE_PICTURE': FileService + 'UploadProfilePicture',
    'DOWNLOAD_PROFILE_PICTURE': FileService + 'DownloadProfilePicture',
    'DOWNLOAD_VIDEO_FILE': FileService + 'DownLoadVideoFile',
    'UPLOAD_VIDEO_FILE': FileService + 'UploadVideoFile',
    'UPLOAD_PROFILE_PICTURE_OFFICE': FileService + 'UploadProfilePictureOffice',
    'DOWNLOAD_PROFILE_PICTURE_OFFICE': FileService + 'DownloadProfilePictureOffice',
    'UPLOAD_PROFILE_PICTURE_COMPANY': FileService + 'UploadProfilePictureCompany',
    'DOWNLOAD_PROFILE_PICTURE_COMPANY': FileService + 'DownloadProfilePictureCompany',
    'DOWNLOAD_VIDEO_FILE_OFFICE': FileService + 'DownLoadVideoFileOffice',
    'UPLOAD_VIDEO_FILE_OFFICE': FileService + 'UploadVideoFileOffice',

    'UPLOAD_PROFILE_PICTURE_JOB_AD': FileService + 'UploadProfilePictureJobAd',
    'DOWNLOAD_PROFILE_PICTURE_JOB_AD': FileService + 'DownloadProfilePictureJobAd',
    'DOWNLOAD_VIDEO_FILE_JOB_AD': FileService + 'DownLoadVideoFileJobAd',
    'UPLOAD_VIDEO_FILE_JOB_AD': FileService + 'UploadVideoFileJobAd',

    'TERMINATE_USER': UserService + 'UserTerminate',
    'GET_USER_NOTIFICATION': UserService + 'GetUserNotification',
    'USER_NOTIFICATION_CUD': UserService + 'UserNotificationCUD',
    'GENERATE_GOOGLE_QR_CODE': UserService + 'GenerateGoogleQRCode',
    'SETUP_MFA': UserService + 'SetupMFA',
    'REMOVE_MFA': UserService + 'RemoveMFA',

    /** System-Functions **/
    'SEND_ERROR_REQUEST': SystemService + 'SendErrorRequest',
    'GET_LOOKUP_GROUPS': SystemService + 'GetLookupGroups',
    'GET_LOOKUP_DATA': SystemService + 'GetLookupDatas',

    /** MESSAGES-FUNCTIONS **/
    'GET_MESSAGES': MessagesService + 'GetMessages',
    'GET_SUB_MESSAGES': MessagesService + 'GetSubMessages',
    'GET_NEW_MESSAGES': MessagesService + 'GetNewMessages',
    'MESSAGES_CUD': MessagesService + 'MessagesCUD',
    'SET_MESSAGE_NOTIFIED': MessagesService + 'SetMessageNotified',

    /** STUDENT SERVICE **/
    'STUDENT_PROFILE_UPDATE': StudentService + 'StudentProfileUpdate',
    'STUDENT_CV_CUD': StudentService + 'StudentCvCUD',
    'GET_STUDENT_PROFILE': StudentService + 'GetStudentProfile',
    'STUDENT_PROFILE_CUD': StudentService + 'StudentProfileCUD',
    'SET_STUDENT_FAVOURITE': StudentService + 'SetFavourite',


    /** APPOINTMENT SERVICE **/
    'GET_OPEN_APPOINTMENTS': AppointmentService + 'GetOpenAppointments',
    'GET_APPOINTMENTS': AppointmentService + 'GetAppointments',
    'APPOINTMENT_CUD': AppointmentService + 'AppointmentCUD',
    'ANSWER_APPOINTMENT_REQUEST': AppointmentService + 'AnswerAppointmentRequest',

    /** MATCHES SERVICE **/
    'GET_MY_REQUESTS': MatchingService + 'GetMyRequests',
    'GET_RECIEVED_REQUESTS': MatchingService + 'GetRecievedRequests',
    'GET_MATCHES': MatchingService + 'GetMatches',
    'SEND_MATCH_REQUEST': MatchingService + 'SendMatchRequest',
    'DECLINE_OR_REMOVE_MATCH': MatchingService + 'DeclineOrRemoveMatch',
    'ACCEPT_MATCH': MatchingService + 'AcceptMatch',

}
