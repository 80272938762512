<template>
    <span>
      <PopOver class="dark" arrow hover openDelay="200" closeDelay="100" placement="right">
        <template #content>
          <p class="title" v-if="popoverTitle">{{ popoverTitle }}</p>
          <span
              v-for="(line,lineNumber) of content.split('\n')"
              v-bind:key="lineNumber">
              {{ line }}<br/>
          </span>
        </template>
        <button
            ref="popoverButton"
            type="button"
            class="btn btn-help  btn-very-small btn-circle margin-left-10"
            :title="popoverTitle"
        ><font-awesome-icon class="help-icon" icon="fas fa-circle-question"/></button>
        </PopOver>
    </span>
</template>

<script>
import $ from 'jquery';

export default {
    name: "HelpButton",
    props: ["title", "content"],
    data() {
        return {}
    },
    computed: {
        popoverTitle: function () {
            return this.$props.title != null ? this.$props.title : this.trans('HELP');
        }
    }
}
</script>

<style scoped>

.margin-left-10 {
    margin-left: 10Px;
}

.dark {
    --popper-theme-background-color: #333333;
    --popper-theme-background-color-hover: #333333;
    --popper-theme-text-color: white;
    --popper-theme-border-width: 0px;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 15px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

:deep(.popper #arrow::before) {
    left: 0px;
}

.btn-very-small {
    padding: 0 !important;
    text-align: center;
    font-size: 11px;
    line-height: 1;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    white-space: pre-wrap;
}

.title {
    font-size: 20px;
    font-weight: bold;
}
</style>
