<template>
    <!-- Modal -->
    <div ref="modal"
         class="modal fade template-modal"
         tabindex="-1"
         role="dialog"
         id="officeModal"
         aria-hidden="true">
        <div class="modal-dialog modal-xxl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title text-success">
                        <font-awesome-icon icon="fas fa-plus-circle" class="text-success"/> &nbsp;
                        <font-awesome-icon icon="fas fa-building" class="text-success"/>
                        <span class="modal-header-text">
                        {{ trans('ADD-OFFICE') }}
                            </span>
                    </h5>
                    <button type="button" data-dismiss="modal" ref="closeBtn" id="closeBtn" data-bs-dismiss="modal"
                            aria-label="Close"
                            class="btn-close">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="tab-content" id="simpletabContent">
                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <NewOfficeForm ref="newOfficeForm"></NewOfficeForm>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="footer-inner">

                        <base-button
                            type="yellow" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close"
                            native-type="button"
                            class="btn btn-yellow input-rounded float-left">
                            <font-awesome-icon icon="fas fa-times"/>
                            {{ $t('CANCEL') }}
                        </base-button>

                        <WaitingButton
                            size="sm"
                            icon="fas fa-check"
                            class="float-right btn btn-mint input-rounded"
                            :waiting="isSaving"
                            @click.prevent="save">
                            {{ $t('SAVE') }}
                        </WaitingButton>


                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

import $ from 'jquery';
import WaitingButton from "../../../components/WaitingButton.vue";

import NewOfficeForm from "./NewOfficeForm.vue";

export default {
    name: "OfficeCreateModal",
    components: {NewOfficeForm, WaitingButton},
    props: ['officeController'],
    data() {
        return {
            isSaving: false,
            error: false,
            errorMessage: null,
            newTabIndex: 0,
            Office: null,
        }
    },
    computed: {
        controller() {
            return this.$props.officeController ? this.$props.officeController : {};
        },
    },
    methods: {
        twiggle() {
        },
        open() {
            this.Office = {
                OfficeName: '',
                Street: '',
                StreetNr: '',
                PostCode: '',
                City: '',
                Country: null,
                AddressAddition: '',
                OfficeProfile: {
                    PkOfficeProfile: null,
                    FkOffice: null,
                    MemberCount: null,
                    FacebookProfile: null,
                    InstagramProfile: null,
                    TikTokProfile: null,
                    LinkedInProfile: null,
                    XingProfile: null,
                    AboutUs: null,
                    JobineoQuestion1: null,
                    JobineoAnswer1: null,
                    JobineoAnswerFile1: null,
                    JobineoQuestion2: null,
                    JobineoAnswer2: null,
                    JobineoAnswerFile2: null,
                    JobineoQuestion3: null,
                    JobineoAnswer3: null,
                    JobineoAnswerFile3: null,
                    OfficeWebsite: null,
                    TraineePerYear: null,
                },
                JobSelectionList: [],
                OfficeBenefitList: [],
                OfficeKeywordList: [],
            };

            const createForm = this.$refs.newOfficeForm;
            createForm.setData(this.Office);

        },
        save() {
            let form = this.$refs.newOfficeForm;

            form.validate().then(result => {
                if (result === true) {
                    let office = form.getData();

                    this.controller.create(office)
                        .then(data => {
                                if (!data.IsError) {
                                    this.$notify(
                                        {
                                            title: this.trans('SUCCESS'),
                                            text: this.trans('DATA-SAVED'),
                                            type: 'success'
                                        });
                                    this.$refs.closeBtn.click();
                                    this.$emit('saved')
                                } else {
                                    form.setError(data.ErrorMessage);
                                    this.$notify(
                                        {
                                            title: this.trans('ERROR'),
                                            text: this.trans(data.ErrorMessage),
                                            type: 'error',
                                        });
                                }

                            }
                        )
                }
            })

        },
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    }

}
</script>

<style scoped>
.template-modal {
    z-index: 9999;
}


.footer-inner {
    display: block;
    width: 100%;
}

.parent-selection-header > .badge {
    font-size: 14px;
}

.tabs-wrap {
    height: 100%;
}

.overflowing {
    overflow-y: auto;
}

.first-tabs-card {
    height: calc(100% - 30px);
    margin: 10px;
}

.modal-header-text {
    vertical-align: 0.8em;
    margin-left: 10px;
}
</style>
