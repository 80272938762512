<template>
    <div class="container-jobineo">
        <div class="section-heading">
            <router-link to="/" class="no-decoration gray-color">
                <font-awesome-icon icon="fas fa-home"/>
                {{ $t('DASHBOARD') }}
            </router-link>
            <span class="no-decoration breadcrumb-decorate-yellow">
                <font-awesome-icon icon="fas fa-angle-right"/> &nbsp;
                <font-awesome-icon icon="fas fa-star"/>
                {{ trans('MY-FAVOURITES') }}
            </span>
        </div>
        <div class="row">
            <div class="col-lg-12" v-if="PaginatedList!= null && PaginatedList.length >0">
                <div class="row layout-top-spacing">
                    <div id="basic" class="col-lg-12 col-sm-12 col-12 layout-spacing">
                        <div class="custom-table">
                            <template v-for="(item, index) in PaginatedList" :key="index">
                                <div class="statbox panel box box-shadow mt-4">
                                    <div class="custom-table">
                                        <div class="tree-header">
                                            <div class="row"
                                                 v-if="!IsCompany&&item.Office!=null&&item.Office.PkOffice!= null">
                                                <div class="col-lg-2">
                                                    <div class="text-center user-info">
                                                        <img
                                                            :ref="'picOff_'+item.Office.PkOffice"
                                                            :src="require('@/assets/images/empty-profile.jpg')"
                                                            alt="avatar"
                                                            style="max-height:200px;max-width:200px;"
                                                            class="col-lg-12 col-md-12 col-sm-12 col-12"/>
                                                        {{ GetImageOff(item.Office) }}
                                                    </div>
                                                    <div class="mt-4 text-center">
                                                        <WaitingButton
                                                            size="sm"
                                                            icon="fas fa-building"
                                                            class=" btn btn-yellow input-rounded "
                                                            :waiting="loading"
                                                            @click.prevent="openProfileOffice(item.Office)">
                                                            {{ $t('OPEN-PROFILE') }}
                                                        </WaitingButton>
                                                    </div>
                                                </div>
                                                <div class="col-lg-10">
                                                    <div class="row">
                                                        <div class="form-group col-lg-12 ">
                                                            <label class="font-size-14 input-label">
                                                                <font-awesome-icon
                                                                    icon="fas fa-magnifying-glass"></font-awesome-icon>
                                                                {{ item.Office.OfficeName }} - {{
                                                                    GetJobTypesOffice(item.Office)
                                                                }}
                                                            </label>
                                                        </div>

                                                    </div>
                                                    <div class="row">
                                                        <div class="form-group col-lg-12 ">
                                                            <label class="font-size-14 input-label">
                                                                {{ GetJobNamesOffice(item.Office) }}
                                                            </label>
                                                        </div>

                                                    </div>
                                                    <div class="row">
                                                        <div
                                                            class="form-group col-lg-12 font-size-14 input-label search-box-label bubble-div">
                                                            <template
                                                                v-for="(benefit, idx) in item.Office.OfficeBenefitList.slice(0, 3)"
                                                                :key="idx">
                                                            <span class="bubbles">
                                                                   {{ benefit.Benefit }}
                                                                </span>
                                                            </template>
                                                            <template
                                                                v-for="(keyword, idx) in item.Office.OfficeKeywordList.slice(0, 3)"
                                                                :key="idx">
                                                            <span class="bubbles">
                                                                   {{ keyword.Keyword }}
                                                                </span>
                                                            </template>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="form-group col-lg-4 ">
                                                            <label class="font-size-14 input-label">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    stroke-width="2"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    class="feather feather-map-pin"
                                                                >
                                                                    <path
                                                                        d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                                                    <circle cx="12" cy="10" r="3"></circle>
                                                                </svg>
                                                                {{ item.Office.PostCode }} {{ item.Office.City }}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row"
                                                 v-if="!IsCompany&&item.JobAd!=null&&item.JobAd.PkJobAd!= null">
                                                <div class="col-lg-2 col-md-2 col-sm-2">
                                                    <div class="text-center user-info">
                                                        <img
                                                            :ref="'picJob_'+item.JobAd.PkJobAd"
                                                            :src="require('@/assets/images/empty-profile.jpg')"
                                                            alt="avatar"
                                                            style="max-height:200px;max-width:200px;"
                                                            class="col-lg-12 col-md-12 col-sm-12 col-xs-12"/>
                                                        {{ GetImageJob(item.JobAd) }}
                                                    </div>
                                                    <div class="mt-4 text-center">
                                                        <WaitingButton
                                                            size="sm"
                                                            icon="fas fa-clipboard"
                                                            class=" btn btn-yellow input-rounded "
                                                            :waiting="loading"
                                                            @click.prevent="openProfileJobAd(item.JobAd)">
                                                            {{ $t('OPEN-PROFILE') }}
                                                        </WaitingButton>
                                                    </div>
                                                </div>
                                                <div class="col-lg-10">
                                                    <div class="row">
                                                        <div class="form-group col-lg-12 ">
                                                            <label class="font-size-14 input-label">
                                                                {{ GetJobTypesJobAd(item.JobAd) }} | {{
                                                                    GetJobNamesJobAd(item.JobAd)
                                                                }}
                                                            </label>
                                                        </div>

                                                    </div>
                                                    <div class="row">
                                                        <div class="form-group col-lg-12 ">
                                                            <label class="font-size-14 input-label">
                                                                {{ item.JobAd.AdName }}
                                                            </label>
                                                        </div>

                                                    </div>
                                                    <div class="row">
                                                        <div
                                                            class="form-group col-lg-12 font-size-14 input-label search-box-label bubble-div">
                                                               
                                                            <span class="bubbles"
                                                                  v-if="GetStartDate(item.JobAd)!==false">
                                                                    {{ GetStartDate(item.JobAd) }} 
                                                                </span>

                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="form-group col-lg-4 ">
                                                            <label class="font-size-14 input-label">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    stroke-width="2"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    class="feather feather-map-pin"
                                                                >
                                                                    <path
                                                                        d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                                                    <circle cx="12" cy="10" r="3"></circle>
                                                                </svg>
                                                                {{ item.JobAd.Office.PostCode }}
                                                                {{ item.JobAd.Office.City }}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="row" v-if="IsCompany">
                                                <div class="col-lg-2">
                                                    <div class="text-center user-info">
                                                        <img
                                                            :ref="'pic_'+item.StudentProfile.PkStudentProfile"
                                                            :src="require('@/assets/images/empty-profile.jpg')"
                                                            alt="avatar"
                                                            style="max-height:200px;max-width:200px;"
                                                            class="col-lg-12 col-md-12 col-sm-12 col-12"/>
                                                        {{ GetImage(item.StudentProfile) }}
                                                    </div>
                                                    <div class="mt-4 text-center">
                                                        <WaitingButton
                                                            size="sm"
                                                            icon="fas fa-user"
                                                            class=" btn btn-yellow input-rounded "
                                                            :waiting="loading"
                                                            @click.prevent="openProfile(item.StudentProfile)">
                                                            {{ $t('OPEN-PROFILE') }}
                                                        </WaitingButton>
                                                    </div>
                                                </div>
                                                <div class="col-lg-10">
                                                    <div class="row">
                                                        <div class="form-group col-lg-12 ">
                                                            <label class="font-size-14 input-label">
                                                                <font-awesome-icon
                                                                    icon="fas fa-magnifying-glass"></font-awesome-icon>
                                                                {{ GetJobTypes(item.StudentProfile) }}
                                                            </label>
                                                        </div>

                                                    </div>
                                                    <div class="row">
                                                        <div class="form-group col-lg-12 ">
                                                            <label class="font-size-14 input-label">

                                                                {{ GetJobNames(item.StudentProfile) }}
                                                            </label>
                                                        </div>

                                                    </div>
                                                    <div class="row">
                                                        <div
                                                            class="form-group col-lg-12 font-size-14 input-label search-box-label bubble-div">
                                                               
                                                            <span class="bubbles"
                                                                  v-if="GetAvailableFromText(item.StudentProfile)!==false">
                                                                    {{ GetAvailableFromText(item.StudentProfile) }} 
                                                                </span>
                                                            <span class="bubbles">
                                                                    {{ trans('AVERAGE-MARK') }} {{
                                                                    item.StudentProfile.StudentGraduation != null ? item.StudentProfile.StudentGraduation.Average : ""
                                                                }}
                                                                </span>
                                                            <span class="bubbles">
                                                                    {{ GetHighestGraduation(item.StudentProfile) }}
                                                                </span>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="form-group col-lg-4 ">
                                                            <label class="font-size-14 input-label">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    stroke-width="2"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    class="feather feather-map-pin"
                                                                >
                                                                    <path
                                                                        d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                                                    <circle cx="12" cy="10" r="3"></circle>
                                                                </svg>
                                                                {{ item.StudentProfile.PostCode }} {{
                                                                    item.StudentProfile.City
                                                                }}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </template>

                            <div class="paginating-container pagination-solid flex-column align-items-center mt-4">
                                <ul role="menubar" aria-disabled="false" aria-label="Pagination"
                                    class="pagination rounded b-pagination">
                                    <template v-for="(item, index) in CountPages" :key="index">
                                        <li role="presentation" class="page-item"
                                            :class="(CurrentPageIndex===(item.PageNo -1)?' active':'')">
                                            <button role="menuitemradio" @click="nextPage(item.PageNo)"
                                                    type="button"
                                                    :aria-checked="CurrentPageIndex===(item.PageNo -1)"
                                                    :aria-posinset="item.PageNo" :aria-setsize="CountPages.length"
                                                    tabindex="-1" class="page-link">
                                                {{ item.PageNo }}
                                            </button>
                                        </li>
                                    </template>
                                </ul>
                            </div>

                            <div v-if="error" class="error-alert-wrap">
                                <font-awesome-icon icon="fas fa-exclamation-triangle"
                                                   class="float-right-margin"/>
                                {{ trans(errorMessage) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="disabled-overlay"></div>
                <div class="disabled-overlay-advice">
                    <div class="center-box">
                                  <span>
                                  <font-awesome-icon icon="fas fa-star"/><br/>
                                  {{ trans('NO-FAVOURITES') }}
                                  </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import store from "@/store";
import {SearchController} from "@/controllers/search.controller";
import {REGISTER_TYPES} from "@/data/RegisterTypes";
import {SEARCH_TYPES} from "@/data/searchTypes";
import {JOB_TYPES} from "@/data/jobTypes";
import lodash from "lodash";
import WaitingButton from "@/components/WaitingButton.vue";
import router from "@/router";
import {cutDateString} from "@/utils/convertDateTime";
import {ProfileController} from "@/controllers/profile.controller";
import {FavouriteController} from "@/controllers/favourite.controller";
import {OfficeController} from "@/controllers/office.controller";
import {JobAdController} from "@/controllers/jobad.controller";

export default {
    name: "SearchView",
    components: {WaitingButton},
    data() {
        return {
            CurrentPageIndex: 0,
            controller: new FavouriteController(this),
            officeController: new OfficeController(this),
            jobAdController: new JobAdController(this),
            profileController: new ProfileController(this),
            SearchList: [],
            loading: false,
            error: false,
            errorMessage: ''
        }
    },
    computed: {
        IsCompany() {
            return store.state.User != null && store.state.User.UserType != REGISTER_TYPES.STUDENT;
        },
        PaginatedList() {
            if (this.SearchList == null || this.SearchList.length <= 0)
                return [];
            return this.SearchList.slice(this.indexStart, this.indexEnd);
        },
        indexStart() {
            return (this.CurrentPageIndex) * 10;
        },
        indexEnd() {
            return (this.indexStart) + 10;
        },
        CountPages() {
            if (this.SearchList == null || this.SearchList.length <= 0)
                return [];

            let pages = [];
            for (let t = 0, l = Math.ceil(this.SearchList.length / 10); t < l; t++) {
                pages.push({PageNo: t + 1})
            }
            return pages;
        },

        QualificationList() {
            return this.$store.state.Lookups.GraduationsLookup != null && Array.isArray(this.$store.state.Lookups.GraduationsLookup) ? this.$store.state.Lookups.GraduationsLookup : [];
        },
    },
    methods: {
        nextPage(no) {
            this.CurrentPageIndex = no - 1;
        },
        GetHighestGraduation(item) {
            if (item.StudentGraduation != null) {

                let items = lodash.filter(this.QualificationList, x => x.DataKey == item.StudentGraduation.HighestGraduation);
                if (items != null && items.length > 0) {
                    return this.trans(items[0].DataValue);
                }

            }
            return "";
        },
        GetDate(value) {
            if (value === null)
                return '';

            return cutDateString(value)
        },
        GetAvailableFromText(item) {
            if (item.AvailableFrom != null) {
                return this.trans('AV-FROM') + ' ' + this.GetDate(item.AvailableFrom)
            } else {
                return false
            }
        },
        GetImage(item) {

            this.profileController.GetProfilePicturePublic(item.FkUser).then(result => {

                if (result.data != null && result.data.size > 0)
                    this.$refs["pic_" + item.PkStudentProfile][0].src = URL.createObjectURL(new Blob([result.data]));
                else {
                    this.$refs["pic_" + item.PkStudentProfile][0].src = require('@/assets/images/empty-profile.jpg');
                }

            })

        },
        GetImageOff(item) {

            this.officeController.GetProfilePictureOfficePublic(item.FkCompany, item.PkOffice).then(result => {

                if (result.data != null && result.data.size > 0)
                    this.$refs["picOff_" + item.PkOffice][0].src = URL.createObjectURL(new Blob([result.data]));
                else {
                    this.$refs["picOff_" + item.PkOffice][0].src = require('@/assets/images/empty-profile.jpg');
                }

            })

        },
        GetImageJob(item) {

            this.jobAdController.GetProfilePictureJobAdPublic(item.FkCompany, item.PkJobAd).then(result => {

                if (result.data != null && result.data.size > 0)
                    this.$refs["picJob_" + item.PkJobAd][0].src = URL.createObjectURL(new Blob([result.data]));
                else {
                    this.$refs["picJob_" + item.PkJobAd][0].src = require('@/assets/images/empty-profile.jpg');
                }

            })

        },
        GetJobNames(item) {
            let text = "";
            item.StudentJobSelectionList.forEach(job => {
                if (job.JobFieldId != null) {
                    let items = lodash.filter(this.$store.state.Lookups.JobFieldsLookup, x => x.DataKey == job.JobFieldId);
                    if (items != null && items.length > 0)
                        text = text + items[0].DataValue + " | ";
                }

                if (job.JobId != null) {
                    let items = lodash.filter(this.$store.state.Lookups.JobsLookup, x => x.DataKey == job.JobId);
                    if (items != null && items.length > 0)
                        text = text + items[0].DataValue + " | ";
                }
            })

            if (text.length > 0) {
                text = text.slice(0, text.length - 3);
            }

            return text;

        },
        GetJobTypes(item) {
            let text = "";

            var result = lodash.some(item.StudentJobSelectionList, x => x.JobType == JOB_TYPES.MINI_JOB);
            if (result === true) {
                text = text + this.trans('MINI-JOB') + ' | '
            }

            result = lodash.some(item.StudentJobSelectionList, x => x.JobType == JOB_TYPES.VOCATIONAL_TRAINING);
            if (result === true) {
                text = text + this.trans('VOCATIONAL-TRAINING') + ' | '
            }

            result = lodash.some(item.StudentJobSelectionList, x => x.JobType == JOB_TYPES.INTERN_SHIP);
            if (result === true) {
                text = text + this.trans('INTERN-SHIP') + ' | '
            }

            result = lodash.some(item.StudentJobSelectionList, x => x.JobType == JOB_TYPES.CAREER_ENTRY);
            if (result === true) {
                text = text + this.trans('CAREER-ENTRY') + ' | '
            }

            result = lodash.some(item.StudentJobSelectionList, x => x.JobType == JOB_TYPES.DUAL_STUDY);
            if (result === true) {
                text = text + this.trans('DUAL-STUDY') + ' | '
            }

            if (text.length > 0) {
                text = text.slice(0, text.length - 3);
            }

            return text;
        },
        GetStartDate(item) {
            if (item.EntryDate != null) {
                return this.trans('AV-FROM') + ' ' + this.GetDate(item.EntryDate)
            } else {
                return false
            }
        },
        GetJobNamesJobAd(item) {
            let text = "";

            let items = lodash.filter(this.$store.state.Lookups.JobFieldsLookup, x => x.DataKey == item.JobFieldId);
            if (items != null && items.length > 0)
                text = text + items[0].DataValue + " | ";

            items = lodash.filter(this.$store.state.Lookups.JobsLookup, x => x.DataKey == item.JobId);
            if (items != null && items.length > 0)
                text = text + items[0].DataValue + " | ";

            if (text.length > 0 && text.indexOf('|') > 0) {
                text = text.slice(0, text.length - 3);
            }

            return text;

        },
        GetJobTypesOffice(item) {
            let text = "";

            var result = lodash.some(item.JobSelectionList, x => x.JobType == JOB_TYPES.MINI_JOB);
            if (result === true) {
                text = text + this.trans('MINI-JOB') + ' | '
            }

            result = lodash.some(item.JobSelectionList, x => x.JobType == JOB_TYPES.VOCATIONAL_TRAINING);
            if (result === true) {
                text = text + this.trans('VOCATIONAL-TRAINING') + ' | '
            }

            result = lodash.some(item.JobSelectionList, x => x.JobType == JOB_TYPES.INTERN_SHIP);
            if (result === true) {
                text = text + this.trans('INTERN-SHIP') + ' | '
            }

            result = lodash.some(item.JobSelectionList, x => x.JobType == JOB_TYPES.CAREER_ENTRY);
            if (result === true) {
                text = text + this.trans('CAREER-ENTRY') + ' | '
            }

            result = lodash.some(item.JobSelectionList, x => x.JobType == JOB_TYPES.DUAL_STUDY);
            if (result === true) {
                text = text + this.trans('DUAL-STUDY') + ' | '
            }

            if (text.length > 0 && text.indexOf('|') > 0) {
                text = text.slice(0, text.length - 3);
            }


            return text;
        },
        GetJobNamesOffice(item) {
            let text = "";
            item.JobSelectionList.forEach(job => {

                let items = lodash.filter(this.$store.state.Lookups.JobFieldsLookup, x => x.DataKey == job.JobFieldId);
                if (items != null && items.length > 0)
                    text = text + items[0].DataValue + " | ";


                items = lodash.filter(this.$store.state.Lookups.JobsLookup, x => x.DataKey == job.JobId);
                if (items != null && items.length > 0)
                    text = text + items[0].DataValue + " | ";

            })

            if (text.length > 0 && text.indexOf('|') > 0) {
                text = text.slice(0, text.length - 3);
            }


            return text;

        },
        GetJobTypesJobAd(item) {
            let text = "";

            if (item.AdType == JOB_TYPES.MINI_JOB) {
                text = this.trans('MINI-JOB')
            } else if (item.AdType == JOB_TYPES.VOCATIONAL_TRAINING) {
                text = this.trans('VOCATIONAL-TRAINING')
            } else if (item.AdType == JOB_TYPES.INTERN_SHIP) {
                text = this.trans('INTERN-SHIP')
            } else if (item.AdType == JOB_TYPES.CAREER_ENTRY) {
                text = this.trans('CAREER-ENTRY')
            } else if (item.AdType == JOB_TYPES.DUAL_STUDY) {
                text = this.trans('DUAL-STUDY')
            }
            return text;
        },
        openProfile(row) {
            router.push("/StudentProfilePublicView/" + row.PkStudentProfile);
        },
        openProfileOffice(row) {
            router.push("/OfficeProfilePublicView/" + row.FkCompany + "/" + row.PkOffice);
        },
        openProfileJobAd(row) {
            router.push("/JobAdProfilePublicView/" + row.FkCompany + "/" + row.FkOffice + "/" + row.PkJobAd);
        },
        setCarAvailable(value) {
            this.CarAvailable = value.target.checked;
        },
        setReadyToMove(value) {
            this.ReadyToMove = value.target.checked;
        },
        AvailableFromChange(date) {
            this.AvailableFrom = date;
        },
    },

    beforeMount() {
    },
    activated() {
        this.controller.read().then(result => {
            this.SearchList = result.FavList;
        })
    },
}
</script>

<style lang="scss" scoped>

.custom-table {
    background: transparent;
}

.tree-header {
    position: relative;
    background-color: transparent;
    padding: 5px 10px 5px 10px;
    font-weight: bold;
    font-size: 20px;
    min-height: 40px;
    box-shadow: -2px 4px 10px -6px rgba(0, 0, 0, 0.45);
    border-bottom: 1px solid #9acccb;
}

.tree-header-label {
    font-size: 16px;
    padding-top: 4px;
    display: flex;
}

p {
    margin-bottom: 0px !important;
}

.no-left-padding > .input {
    padding-left: 0px !important;
}

.header-picture-company {
    min-height: 550px;
    display: grid;
    background-position: center center;
    align-self: center;
    align-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(~@/assets/images/header-bild-unternehmen.jpg);
}

.header-text-company {
    color: #000000;
    margin-left: 40px;
    background: white;
    width: 45%;
    border-radius: 12px;
    font-weight: 900;
    padding-left: 10px;
    letter-spacing: 0;
}

.margin-left {
    margin-left: 35px;
}

.mt-btn {
    margin-top: 38px;
}

.search-box {
    background: #9acccb !important;
}


</style>
