<template>
    <div>
        <New-Header></New-Header>
        <div
            class="main-container"
            id="container"
            :class="[!$store.state.is_show_sidebar ? 'sidebar-closed sbar-open' : '', $store.state.menu_style === 'collapsible-vertical' ? 'collapsible-vertical-mobile' : '']"
        >
            <div id="content" class="main-content" :class="[{
                      ' main-content-no-nav-bar ': !$store.state.loggedIn}]">
                <template v-if="!loggedIn">
                    <router-view></router-view>
                </template>
                <template v-else>
                    <router-view v-slot="{ Component }">
                        <keep-alive>
                            <component :is="Component"/>
                        </keep-alive>
                    </router-view>
                </template>
            </div>
        </div>
    </div>
</template>
<script setup>
import NewHeader from '@/components/layout/header.vue';
</script>
<script>
import store from "@/store";

export default {
    computed: {
        loggedIn() {
            return store.state.loggedIn;
        },
    }
}
</script>
