import {APPOINTMENT_TYPES} from "@/data/appointment";
import {REGISTER_TYPES} from "@/data/RegisterTypes";
import lodash from "lodash";

export const SetLimitationsAndLicenses = (state, data) => {
    state.CompanyData.CompanyLicenseList = data.Licenses;
    state.CompanyData.MaxUsers = data.MaxUsers;
    state.CompanyData.CurrentUsers = data.CurrentUsers;
    state.CompanyData.MaxOffices = data.MaxOffices;
    state.CompanyData.CurrentOffices = data.CurrentOffices;
    state.CompanyData.MaxJobAds = data.MaxJobAds;
    state.CompanyData.CurrentJobAds = data.CurrentJobAds;
    state.CompanyData.MaxMatches = data.MaxMatches;
    state.CompanyData.CurrentMatches = data.CurrentMatches;
    state.CompanyData.InfiniteMatches = data.InfiniteMatches;

}









