<template>
    <div class="layout-top-spacing">
        <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
            <div class="col-xl-12 list-margin">
                <ul class="list-icon custom-table">
                    <li v-if="items.length <= 0" style="text-align:center;">
                        <span class="list-text">{{ trans('NO-REQUESTS-AVAILABLE') }}</span>
                    </li>
                    <li class="list-height" v-for="item in items" :key="item.PkMatch">
                        <font-awesome-icon v-if="item.RequestType == 1"
                                           icon="fas fa-calendar"
                                           class="icon-color">
                        </font-awesome-icon>
                        <font-awesome-icon v-if="item.RequestType == 2"
                                           icon="fas fa-puzzle-piece"
                                           class="icon-color">
                        </font-awesome-icon>
                        <span class="list-text">{{ trans(item.RequestText) }}</span>
                        <div class="float-right dropdown btn-group">
                            <a href="javascript:;" id="ddlRevenue" class="btn dropdown-toggle btn-icon-only"
                               data-bs-toggle="dropdown" aria-expanded="false">
                                <font-awesome-icon icon="fas fa-ellipsis" class="ellipsis-color"></font-awesome-icon>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="ddlRevenue">
                                <li v-if="IsCompany"><a href="javascript:;" class="dropdown-item btn-icon-only"
                                                        @click="openStudent(props.row)">
                                    <font-awesome-icon icon="fas fa-building" class=" dashboard-icon"
                                                       data-bs-toggle="modal" data-bs-target="#configBox"
                                    ></font-awesome-icon>
                                    <span style="padding-top:4px;"> {{ trans('OPEN-STUDENT') }}</span>
                                </a></li>
                                <li v-if="!IsCompany"><a href="javascript:;" class="dropdown-item btn-icon-only"
                                                         @click="openJobAd(props.row)">
                                    <font-awesome-icon icon="fas fa-suitcase" class="dashboard-icon"
                                    ></font-awesome-icon>
                                    {{ trans('OPEN-JOB-AD') }}
                                </a></li>
                                <li><a href="javascript:;" class="dropdown-item btn-icon-only"
                                       @click="acceptMatch(props.row)">
                                    <font-awesome-icon icon="fas fa-check" class="dashboard-icon"
                                    ></font-awesome-icon>
                                    {{ trans('ACCEPT-REQUEST') }}
                                </a></li>
                                <li><a href="javascript:;" class="dropdown-item btn-icon-only"
                                       @click="cancelMatch(props.row)">
                                    <font-awesome-icon icon="fas fa-times" class="dashboard-icon"
                                    ></font-awesome-icon>
                                    {{ trans('CANCEL-REQUEST') }}
                                </a></li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>

import {convertDateTimeForDisplay} from "@/utils/convertDateTime";
import router from "@/router";
import {MatchingController} from "@/controllers/matching.controller";

export default {
    components: {},
    props: ['items', 'hasEdit', 'isCompany'],
    emits: ['refresh'],
    data() {
        return {
            matchingController: new MatchingController(this)
        }
    },
    computed: {
        IsCompany() {
            return this.$props.isCompany != null && this.$props.isCompany == true ? true : false
        }
    },
    methods: {
        openStudent(row) {
            router.push("StudentProfilePublicView/" + row.FkStudentProfile);
        },
        openJobAd(row) {
            router.push("StudentProfilePublicView/" + row.FkJobAd);
        },
        acceptMatch(row) {
            if (row.PkMatch != null) {

                new window.Swal({
                    title: this.trans('ACCEPT-MATCH'),
                    text: this.trans('REALLY-ACCEPT-MATCH'),
                    icon: 'warning',
                    showCancelButton: true,
                    cancelButtonText: this.trans('NO'),
                    confirmButtonText: this.trans('ACCEPT-MATCH'),
                    iconColor: '#ce6f74',
                    padding: '2em'
                }).then(saResult => {
                    if (saResult.isConfirmed) {

                        this.matchingController.AcceptMatch(row.PkMatch, true).then(response => {
                            if (response.IsError) {
                                this.error = true;
                                this.errorMessage = response.ErrorMessage;
                                this.$notify(
                                    {
                                        title: this.trans('ERROR'),
                                        text: this.trans(response.ErrorMessage),
                                        type: 'error',
                                    });
                            } else {
                                this.$notify(
                                    {
                                        title: this.trans('SUCCESS'),
                                        text: this.trans('DATA-SAVED'),
                                        type: 'success'
                                    });

                                this.$emit("refresh");
                            }
                        })
                    }
                })

            }
        },
        cancelMatch(row) {
            if (row.PkMatch != null) {

                new window.Swal({
                    title: this.trans('DECLINE-MATCH'),
                    text: this.trans('REALLY-DECLINE-MATCH'),
                    icon: 'warning',
                    showCancelButton: true,
                    cancelButtonText: this.trans('NO'),
                    confirmButtonText: this.trans('DECLINE-MATCH'),
                    iconColor: '#ce6f74',
                    padding: '2em'
                }).then(saResult => {
                    if (saResult.isConfirmed) {

                        this.matchingController.DeclineOrRemoveMatch(row.PkMatch, true, true, false).then(response => {
                            if (response.IsError) {
                                this.error = true;
                                this.errorMessage = response.ErrorMessage;
                                this.$notify(
                                    {
                                        title: this.trans('ERROR'),
                                        text: this.trans(response.ErrorMessage),
                                        type: 'error',
                                    });
                            } else {
                                this.$notify(
                                    {
                                        title: this.trans('SUCCESS'),
                                        text: this.trans('DATA-SAVED'),
                                        type: 'success'
                                    });

                                this.$emit("refresh");
                            }
                        })
                    }
                })

            }
        },
        redirectToPageText(item) {
            return this.trans("HELLO")
        },
        redirectToPage(item) {
            router.push("/Appointment/" + item.PkAppointment);
        },
    }
};
</script>
<style scoped lang="scss">

.list-margin {
    padding-right: 10px;
    padding-left: 10px;
}

.icon-color {
    margin-right: 15px;
    margin-left: 10px;
    min-width: 30px;
    min-height: 30px;
}

.dark {
    .icon-color {
        color: #ffffff;
    }


    .list-text {
        color: #ffffff;
    }
}

.list-height {
    min-height: 60px;
    border-bottom: 1px solid #ffffff;
}

.ellipsis-color {
    color: #888ea8
}

</style>


