<template>
    <div class="margin-top-20 ">

        <div class="row d-flex justify-content-center">
            <div class="col-md-10">
                <simple-wizard :prev-button-text="$t('PREVIOUS')" :next-button-text="$t('NEXT')"
                               :cancel-button-text="$t('CANCEL')" ref="simpleWizard">
                    <template v-slot:header>
                        <div class="card-header py-2 flex-row align-items-center justify-content-between">
                            <h1 class="m-0 font-weight-bold ">
                                {{ $t('REGISTER-COMPANY-TEXT') }}</h1>
                            <p>{{ $t('REGISTER-COMPANY-TEXT-2') }}</p>
                        </div>
                    </template>
                    <wizard-tab id="step1" :before-change="() => validateStep('step1')"
                                :label="$t('USER-DATA-REGISTER')"
                                :pos="0"
                                icon="fa-user">
                        <first-step
                            ref="step1"
                            @registerTypeChanged="registerTypeChanges"
                            @setErrors="setErrorWizard"
                            @on-validated="onStepValidated"
                        ></first-step>
                    </wizard-tab>
                    <wizard-tab :pos="1" id="step2" :before-change="() => validateStep('step2')"
                                :label="$t('COMPANY-DATA')"

                                icon="fa-building">
                        <company-step
                            ref="step2"
                            @setErrors="setErrorWizard"
                            @on-validated="onStepValidated"
                        ></company-step>
                    </wizard-tab>
                    <wizard-tab :pos="2" id="step3" :before-change="() => validateStep('step3')"
                                :label="$t('OFFICE-DATA')"
                                icon="fa-building">
                        <office-step
                            ref="step3"
                            :address="wizardModel"
                            @setErrors="setErrorWizard"
                            @on-validated="onStepValidated"
                        ></office-step>
                    </wizard-tab>
                    <wizard-tab :pos="3" id="step4" :before-change="() => validateStep('step4')" :label="$t('FINALIZE')"
                                icon="fa-flag-checkered">
                        <FinalStep ref="step4" @on-validated="onStepValidated"
                                   @wizard-finished="onComplete"></FinalStep>
                    </wizard-tab>


                    <div v-if="error" class="error-alert-wrap"><font-awesome-icon icon="fas fa-exclamation-triangle" class="error-icon" />
                        {{ trans(errorMessage) }}
                    </div>
                </simple-wizard>
            </div>
        </div>
    </div>
</template>
<script>
import FirstStep from './WizardPages/FirstStep.vue';
import FinalStep from './WizardPages/FinalStep.vue';
import {getConfig} from "@/jobineo";
import SimpleWizard from '../../components/Wizards/Wizard';
import WizardTab from '../../components/Wizards/WizardTab';
import router from "@/router";
import CompanyStep from "@/views/register/WizardPages/CompanyStep.vue";
import OfficeStep from "@/views/register/WizardPages/OfficeStep.vue";
import store from "@/store";
import {useCookies} from "vue3-cookies";

const {cookies} = useCookies();
export default {
    data() {
        return {
            wizardModel: {},
            waitFinalizing: false,
            error: false,
            errorMessage: '',
            registerType: 2,
        };
    },
    components: {
        OfficeStep,
        CompanyStep,
        FirstStep,
        FinalStep,
        SimpleWizard,
        WizardTab
    },
    computed: {
        CompanyAddress() {
            return this.wizardModel != null && this.wizardModel.CompanyName != null ? this.wizardModel : null
        }
    },
    methods: {
        validateStep(ref) {
            let form = this.$refs[ref];

            if (Array.isArray(form)) {
                return form[0].validate();
            } else
                return form.validate();
        },
        onStepValidated(validated, model) {
            this.wizardModel = {...this.wizardModel, ...model};
        },
        setErrorWizard(errors) {
            this.$refs.simpleWizard.setErrors(errors)
        },
        registerTypeChanges(newValue) {
            this.registerType = newValue;
        },
        onComplete() {
            this.waitFinalizing = true;
            this.$store.dispatch('registerAccount', this.wizardModel)
                .then(response => {
                    let data = response;
                    this.waitFinalizing = false;

                    if (data.IsError) {
                        this.error = true;
                        this.errorMessage = this.trans(data.ErrorMessage);
                        return false;
                    } else {
                        this.error = false;
                        this.errorMessage = "";
                        this.$store.state.loggedIn = false;
                        localStorage.removeItem('loggedIn');
                        localStorage.removeItem('loggedInSessionId');
                        localStorage.removeItem('rememberId');
                        localStorage.removeItem('Email');
                        cookies.remove('rememberId');
                        cookies.remove('loggedIn');
                        cookies.remove('loggedInSessionId');
                        store.commit('logOff');

                        this.isRegisteringComplete = true;

                        this.wizardModel = {};

                        router.push('/RegisterSuccess');
                    }
                })
                .then(data => {
                    if (data && !data.IsError) {
                        router.push('/');
                    } else if (data) {
                        this.error = true;
                        this.errorMessage = this.trans(data.ErrorMessage);
                    }
                }).catch(result => {
                this.waitFinalizing = false;

                if (result.IsError) {
                    this.error = true;
                    this.errorMessage = this.trans(result.ErrorMessage);
                }
            });
        }
    }
};
</script>
<style lang="scss" scoped>
//@import "../../scss/vars";

.card-body {
    position: relative;
}

.register-wrap {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 11;
    top: 0;
    left: 0;
}

.register-wrap > .container {
    display: flex;
    height: 100vh;
    width: 100vw;
}

.register-wrap > .container > .row {
    align-content: center;
    flex-direction: row;
    flex-grow: 1;
}

.register-wrap > .container > .row .card {
    width: 70vw;
    min-height: 80vh;
}

.language-selector-wrap {
    text-align: right;
    margin-right: 10px;
    /*margin-top: -32px;*/
    /*padding-bottom: 32px;*/
}

.alert {
    margin-bottom: 0px !important;
}


.form-panel {
    display: block;
}

.input-group-prepend > span {
    font-size: 14px !important;
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    min-width: 12em;
}

.error-alert {
    font-size: 0.8rem;
}


.relative-bottom-spacer {
    display: inline-block;
    width: 100%;
    height: 24px;
}


.required-advice {
    position: absolute;
    bottom: 5px;
    color: red;
    font-size: 0.8em;
}

.margin-top-20 {
    margin-top: 20px;
}
</style>
