<template>
    <div class="layout-px-spacing">
        <div class="row margin-top-60">
            <div class="ml-auto mr-auto text-center header-row">
                <h1 class="title">{{ $t('PICK-LICENSE') }}</h1>

                <h4 class="description">
                    {{ $t('PICK-LICENSE-TEXT') }}
                </h4>
                <h4 class="description">
                    {{ $t('PICK-LICENSE-TEXT-TWO') }}
                </h4>
                <div style="display:inline-flex;margin-top: 15px">
                    <h4 class="description" style="margin-right: 25px; margin-top:10px">
                        {{ trans('RUNTIME') }}
                    </h4>
                </div>
                <div class='tss'>
                    <input type='radio' name='tss' id='quarterly' value='1' v-model='IntervalMode'>
                    <label for='quarterly'><span>{{ trans('QUARTERLY-RUNTIME') }}</span></label>
                    <input type='radio' name='tss' id='halfyearly' value='2' v-model='IntervalMode'>
                    <label for='halfyearly'><span>{{ trans('HALF-YEARLY-RUNTIME') }}</span></label>
                    <input type='radio' name='tss' id='yearly' value='3' v-model='IntervalMode'>
                    <label for='yearly'><span>{{ trans('YEARLY-RUNTIME') }}</span></label>
                </div>
            </div>
        </div>

        <div class="container pricing-table">
            <section class="pricing-section bg-7 mt-5">
                <div class="pricing pricing--norbu">
                    <div class="pricing__item">
                        <h3 class="pricing__title">{{ $t('PACKAGE-S') }}</h3>
                        <p class="pricing__sentence">{{ $t('PACKAGE-S-SENTENCE') }}</p>
                        <div class="pricing__price"><span class="pricing__currency">€</span>{{
                                getPricing(this.PricingS)
                            }}<span
                                class="pricing__period">/ mtl.</span>
                        </div>
                        <ul class="pricing__feature-list text-center">
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-S-TEXT-1') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-S-TEXT-2') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-S-TEXT-3') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-S-TEXT-4') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-S-TEXT-5') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-xmark" class="x-margin-gray"></font-awesome-icon>
                                <span class="grayout">{{ $t('PACKAGE-S-TEXT-6') }}</span>
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-xmark" class="x-margin-gray"></font-awesome-icon>
                                <span class="grayout">{{ $t('PACKAGE-S-TEXT-7') }}</span>
                            </li>
                        </ul>
                        <button type="button" variant="default" class="pricing__action mx-auto mb-4"
                                @click="goToRegister">{{
                                trans('GET-STARTED')
                            }}
                        </button>
                    </div>
                    <div class="pricing__item pricing__item--featured">
                        <div class="ribbon-container">
                            <div class="ribbon-flag">{{ trans('MOST-POPULAR') }}</div>
                        </div>
                        <h3 class="pricing__title">{{ $t('PACKAGE-M') }}</h3>
                        <p class="pricing__sentence">{{ $t('PACKAGE-M-SENTENCE') }}</p>
                        <div class="pricing__price"><span class="pricing__currency">€</span>{{
                                getPricing(this.PricingM)
                            }}<span
                                class="pricing__period">/ mtl.</span>
                        </div>
                        <ul class="pricing__feature-list text-center">
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-M-TEXT-1') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-M-TEXT-2') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-M-TEXT-3') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-M-TEXT-4') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-M-TEXT-5') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="x-margin-gray"></font-awesome-icon>
                                <span class="grayout">{{ $t('PACKAGE-M-TEXT-6') }}</span>
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="x-margin-gray"></font-awesome-icon>
                                <span class="grayout">{{ $t('PACKAGE-M-TEXT-7') }}</span>
                            </li>
                        </ul>
                        <button type="button" variant="default" class="pricing__action mx-auto mb-4"
                                @click="goToRegister">{{
                                trans('GET-STARTED')
                            }}
                        </button>
                    </div>
                    <div class="pricing__item">
                        <h3 class="pricing__title">{{ $t('PACKAGE-L') }}</h3>
                        <p class="pricing__sentence">{{ $t('PACKAGE-L-SENTENCE') }}</p>
                        <div class="pricing__price"><span class="pricing__currency">€</span>{{
                                getPricing(this.PricingL)
                            }}<span
                                class="pricing__period">/ mtl.</span>
                        </div>
                        <ul class="pricing__feature-list text-center">
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-L-TEXT-1') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-L-TEXT-2') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-L-TEXT-3') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-L-TEXT-4') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-L-TEXT-5') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="x-margin-gray"></font-awesome-icon>
                                <span class="grayout">{{ $t('PACKAGE-L-TEXT-6') }}</span>
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="x-margin-gray"></font-awesome-icon>
                                <span class="grayout">{{ $t('PACKAGE-L-TEXT-7') }}</span>
                            </li>
                        </ul>
                        <button type="button" variant="default" class="pricing__action mx-auto mb-4"
                                @click="goToRegister">{{
                                trans('GET-STARTED')
                            }}
                        </button>
                    </div>
                    <div class="pricing__item">
                        <h3 class="pricing__title">{{ $t('TRIAL') }}</h3>
                        <p class="pricing__sentence">{{ $t('TRIAL-SENTENCE') }}</p>
                        <div class="pricing__price"><span class="pricing__currency">€</span>0<span
                            class="pricing__period">/ mtl.</span>
                        </div>
                        <ul class="pricing__feature-list text-center">
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('TRIAL-TEXT-1') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('TRIAL-TEXT-2') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-xmark" class="x-margin-gray"></font-awesome-icon>
                                <span class="grayout">{{ $t('TRIAL-TEXT-3') }}</span>
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('TRIAL-TEXT-4') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-xmark" class="x-margin-gray"></font-awesome-icon>
                                <span class="grayout">{{ $t('TRIAL-TEXT-5') }}</span>
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-xmark" class="x-margin-gray"></font-awesome-icon>
                                <span class="grayout">{{ $t('TRIAL-TEXT-6') }}</span>
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-xmark" class="x-margin-gray"></font-awesome-icon>
                                <span class="grayout">{{ $t('TRIAL-TEXT-7') }}</span>
                            </li>
                        </ul>
                        <button type="button" variant="default" class="pricing__action mx-auto mb-4"
                                @click="goToRegister">{{
                                trans('GET-STARTED')
                            }}
                        </button>
                    </div>
                </div>
                <div class="pricing pricing--norbu">
                    <div class="in-development">{{ trans('IN-DEVELOPMENT') }}</div>
                </div>
            </section>
        </div>
    </div>

    <div>

        <div class="row margin-top-80">
            <div class="ml-auto mr-auto text-center header-row">
                <h1 class="title">{{ $t('UPGRADE-LICENSE') }}</h1>
                <h4 class="description">
                    {{ $t('UPGRADE-LICENSE-TEXT') }}
                </h4>
            </div>
        </div>

        <div class="layout-px-spacing">
            <div class="container pricing-table">
                <section class="pricing-section bg-7 mt-5">
                    <div class="pricing pricing--norbu">
                        <div class="pricing__item">
                            <h3 class="pricing__title">{{ $t('ADD-ON') }}</h3>
                            <p class="pricing__sentence">{{ $t('ADD-ON-SENTENCE') }}</p>

                            <ul class="pricing__feature-list text-center">
                                <template v-for="(item, idx) in LicensePricesList" :key="idx">
                                    <li class="pricing__feature">
                                        <font-awesome-icon icon="fas fa-circle-plus"
                                                           class="check-margin-10"></font-awesome-icon>
                                        {{ $t(item.Name) }}
                                        <span class="span-right">{{ item.NetAmount }}€</span>
                                    </li>
                                </template>
                            </ul>
                            <button type="button" variant="default" class="pricing__action mx-auto mb-4"
                                    @click="goToRegister">{{
                                    trans('GET-STARTED')
                                }}
                            </button>
                        </div>
                    </div>
                </section>
            </div>

            <div class="content-section-5">
                <div class="container">
                    <div class="headline-box-black">
                        <div>{{ trans('FAQ-PRICING') }}</div>
                    </div>
                    <div class="grid-accord">
                        <div class="accordion" role="tablist">
                            <b-accordion>
                                <b-accordion-item :title="trans('PRICING-FAQ-1-TITLE')">
                                    <p>{{ trans('PRICING-FAQ-1-TEXT') }}</p>
                                </b-accordion-item>

                                <b-accordion-item :title="trans('PRICING-FAQ-2-TITLE')">
                                    <p>
                                        {{ trans('PRICING-FAQ-2-TEXT') }}
                                    </p></b-accordion-item>

                                <b-accordion-item :title="trans('PRICING-FAQ-3-TITLE')">
                                    <p>
                                        {{ trans('PRICING-FAQ-3-TEXT') }}
                                    </p></b-accordion-item>
                                <b-accordion-item :title="trans('PRICING-FAQ-4-TITLE')">
                                    <p>
                                        {{ trans('PRICING-FAQ-4-TEXT') }}
                                    </p></b-accordion-item>
                            </b-accordion>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>

import {restUri} from "@/data/restPaths";
import '@/scss/jobineo/sass/pricing-table.scss';
import router from "@/router";

export default {
    name: 'pricing-page',
    data() {
        return {
            LicensePricesList: [],
            PackageS3: null,
            PackageM3: null,
            PackageL3: null,
            PackageS6: null,
            PackageM6: null,
            PackageL6: null,
            PackageS12: null,
            PackageM12: null,
            PackageL12: null,
            IntervalMode: 1
        }
    },
    computed: {
        Interval() {
            return this.IntervalMode
        },
        Months() {
            return this.Interval == 1 ? 3 : this.Interval == 2 ? 6 : 12;
        },
        PricingS() {
            if (this.Interval == 1) {
                if (this.PackageS3)
                    return this.PackageS3.NetAmount
                else return 0
            } else if (this.Interval == 2) {
                if (this.PackageS6)
                    return this.PackageS6.NetAmount
                else return 0
            } else if (this.Interval == 3) {
                if (this.PackageS12)
                    return this.PackageS12.NetAmount
                else return 0
            } else {
                return 0;
            }
        },
        PricingM() {
            if (this.Interval == 1) {
                if (this.PackageM3)
                    return this.PackageM3.NetAmount
                else return 0
            } else if (this.Interval == 2) {
                if (this.PackageM6)
                    return this.PackageM6.NetAmount
                else return 0
            } else if (this.Interval == 3) {
                if (this.PackageM12)
                    return this.PackageM12.NetAmount
                else return 0
            } else {
                return 0;
            }
        },
        PricingL() {
            if (this.Interval == 1) {
                if (this.PackageL3)
                    return this.PackageL3.NetAmount
                else return 0
            } else if (this.Interval == 2) {
                if (this.PackageL6)
                    return this.PackageL6.NetAmount
                else return 0
            } else if (this.Interval == 3) {
                if (this.PackageL12)
                    return this.PackageL12.NetAmount
                else return 0
            } else {
                return 0;
            }
        }

    },
    methods: {
        getPricing(value) {
            return Math.round(value / this.Months)
        },
        goToRegister() {
            router.push('/RegisterWizard');
        }
    },
    beforeMount() {

        Promise.all([
            this.axios.post(restUri.GET_PRICINGS, {request: {}})
        ]).then(values => {
            let pricesData = values[0].data;
            this.LicensePricesList = [];

            if (!pricesData.IsError) {

                pricesData.PricingItemsList.forEach(item => {

                    if (item.LicenseType == 1 && item.PaymentInterval == 1) {
                        this.PackageS3 = item;
                    } else if (item.LicenseType == 2 && item.PaymentInterval == 1) {
                        this.PackageM3 = item;
                    } else if (item.LicenseType == 3 && item.PaymentInterval == 1) {
                        this.PackageL3 = item;
                    } else if (item.LicenseType == 1 && item.PaymentInterval == 2) {
                        this.PackageS6 = item;
                    } else if (item.LicenseType == 2 && item.PaymentInterval == 2) {
                        this.PackageM6 = item;
                    } else if (item.LicenseType == 3 && item.PaymentInterval == 2) {
                        this.PackageL6 = item;
                    } else if (item.LicenseType == 1 && item.PaymentInterval == 3) {
                        this.PackageS12 = item;
                    } else if (item.LicenseType == 2 && item.PaymentInterval == 3) {
                        this.PackageM12 = item;
                    } else if (item.LicenseType == 3 && item.PaymentInterval == 3) {
                        this.PackageL12 = item;
                    } else if (item.LicenseType != 4) {
                        this.LicensePricesList.push(item);
                    }


                })


            }
        });

    }
};
</script>

<style scoped lang="scss">

.check-margin {
    margin-right: 4px;
}

.check-margin-gray {
    margin-right: 15px;
    color: rgba(#b2b0b0, 0.4);
}

.check-margin-10 {
    margin-right: 10px;
}

.x-margin-gray {
    color: rgba(#b2b0b0, 0.4);
    margin-right: 5px;
}

.grayout {
    color: rgba(#b2b0b0, 0.4);
}

.tss {
    display: inline-block;
    position: relative;
    user-select: none;
    background: #222a42;
    border-radius: 25px;
    color: white;
    height: 50px;

    input[type='radio'] {
        display: none;

        &:checked {
            & + label {
                span {
                    &:before {
                        display: inline-block;
                    }
                }
            }
        }
    }

    label {
        position: relative;
        display: inline-block;
        cursor: pointer;
        padding: 15px 10px;
        min-width: 50px;
        color: white;
        font-weight: bold;

        span {
            &:before {
                content: '';
                position: absolute;
                display: none;
                width: 100%;
                height: 100%;
                color: #000000;


                border-radius: 100%;
                background: transparentize(#FFB92A, 0.3);
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }
    }
}

.ribbon-container {
    position: absolute;
    left: 0;
    top: -3px;
    width: 100%;
    height: 120px;
    overflow: hidden;
}

.ribbon-flag {
    background: radial-gradient(#313030, #515152);
    color: white;
    position: absolute;
    display: block;
    top: 15px;
    right: -45px;
    width: 170px;
    padding: 12px 20px 8px;
    margin: 0;
    transform: rotate(45deg);
    height: 50px;
    z-index: 2;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
    text-align: center;
}

.margin-top-60 {
    margin-top: 60px;
}

.container {
    display: block !important;
    margin-left: 0 !important;
    max-width: 100% !important;
    @media (min-width: 1400px) {
        max-width: 100%;
    }
}

.in-development {
    font-family: 'Poppins';
    margin-bottom: 30px;

}

.headline-box-black {
    font-weight: 700;
    font-size: 3em;
    text-align: center;
    margin-bottom: 30px;
}


.accordion {
    margin-bottom: 50px;
    align-self: center;

}

.grid-accord {
    display: flex !important;
    place-content: center;
}

.accordion {
    max-width: 1000px;
    @media (min-width: 1400px) {
        width: 1000px;
    }

}

.accordion-header {
    font-family: 'Poppins';
    color: black;
}

.accordion-body p {
    font-family: 'Poppins';
    color: black;
}

</style>
