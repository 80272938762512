<template>
    <div class="dropdown nav-item user-profile-dropdown btn-group">
        <a ref="menu" href="javascript:;" id="ddluser" data-bs-toggle="dropdown" aria-expanded="false"
           class="btn dropdown-toggle btn-icon-only user nav-link">
            <font-awesome-icon icon="fa-user" style="color:#ebedf2;"></font-awesome-icon>
            <span class="ms-2">{{ trans(buttonText) }}</span>
        </a>
        <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="ddluser">
            <template v-for="(route,idx) in routesLoggedOff" :key="idx">
                <li role="presentation" v-if="checkLoggedIn(route.checkLoginState)&&route.title != 'SIGN-OUT'">
                    <router-link :to="route.path" class="dropdown-item">
                        <font-awesome-icon :icon="route.icon"></font-awesome-icon>
                        {{ trans(route.title) }}
                    </router-link>
                </li>

                <li v-if="route.setDivider==true" class="divider">&nbsp;</li>
            </template>
        </ul>
    </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import {useCookies} from "vue3-cookies";
import {flattenAccountLoggedOffPathTreeWithoutRoot, flattenAccountLoggedInPathTree} from "@/routes/account.routes";
import {LOGIN_STATE} from "@/data/LoginStates";

const {cookies} = useCookies();
export default {
    name: 'login-menu',
    data() {
        return {
            routesLoggedOff: flattenAccountLoggedOffPathTreeWithoutRoot
        }
    },
    computed: {
        buttonText() {
          if (this.loggedIn) {
              return "PROFILE"
          }  else {
              return "PROFILE-OFFLINE"
          }
        },
        loggedIn() {
            return store.state.loggedIn;
        }
    },
    methods: {
        checkLoggedIn(value) {
            if (value == LOGIN_STATE.LOGGED_OFF && !this.loggedIn)
                return true;
            else if (value == LOGIN_STATE.LOGGED_IN && this.loggedIn)
                return true;
            else if (value == LOGIN_STATE.IGNORE)
                return true
            else
                return false;
        }
    }
}
</script>

<style scoped lang="scss">
@media (max-width: 800px) {
    a > span {
        display: none;
    }
}
</style>
