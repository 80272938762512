<template>
    <base-button
        :class="buttonClass"
        :type="typeDefault"
        :native-type="nativeTypeDefault"
    >
        <font-awesome-icon v-if="icon&&!waiting" :icon="icon"/>
        <font-awesome-icon v-if="waiting" icon="fas fa-spinner fa-circle-notch"/>
        <slot></slot>
    </base-button>
</template>

<script>
export default {
    name: "WaitingButton",
    props: ["type", "size", "variant", "icon", "waiting", "nativeType"],
    emit: ['click'],
    computed: {
        buttonClass() {
            return 'btn btn-' + (this.$props.variant ? this.$props.variant : 'default') + ' btn-' +
                (this.$props.size ? this.$props.size : 'sm');
        },
        typeDefault() {
            return this.$props.type ? this.$props.type : 'green';
        },
        nativeTypeDefault() {
            return this.$props.nativeType ? this.$props.nativeType : 'button';
        }
    }
}
</script>

<style scoped lang="scss">
.btn svg {
    margin-right: 8px;
}

</style>
