



export function traverseRouteTree(node, fullpath = '', breadcrumb = [], parent = null, level = 0){
    let parentName = parent && parent.ignore == null ? parent.name : null,
        _fullpath  = fullpath += node.path,
        _breadcrumb = [...breadcrumb];


    if(parent)
        _breadcrumb.push({
            name: node.name,
            icon: node.icon,
            title: node.title,
            fullpath,
            level,
            parentName
        });

    node.meta = {
        parentName,
        breadcrumb : _breadcrumb,
        level,
        parent
    };

    if(node.children != null && Array.isArray(node.children) && node.children.length){
        for(let t=0,l=node.children.length; t<l; t++)
            traverseRouteTree(node.children[t], _fullpath, _breadcrumb, node, (level+1) );

    }

}
