import {restUri} from "@/data/restPaths";
import {restBody} from "@/utils/restBody";
import axios from "axios";



export function setMessageNotified(store, data) {
  return axios.post(restUri.SET_MESSAGE_NOTIFIED, restBody({
    Message: data,
  })).then(result => {
    let _data = result.data;
    return _data;
  })

}
