import Controller from "../controller.js";
import {restUri} from "@/data/restPaths";
import {mutate} from "@/utils/mutate";
import store from "@/store";
import {uuid} from "vue-uuid";


export default class UsersController extends Controller {

    constructor(root) {
        super({
            root,
            path: {
                CUD: restUri.USER_CUD,
                GET: restUri.GET_USERS
            }
        })


    }

    init() {
    }

    read() {

        return super.read({
            log: true,
            body: mutate({FkCompany: store.state.FkCompany})
        }).then(data => {
            if (!data.IsError) {
                return data;
            } else return data
        }).catch(result => {
            return result;
        });
    }

    create(user = {}) {
        user.PkUser = uuid.v4();
        user.User2Comp.PkUserToCompany = uuid.v4();
        user.User2Comp.FkCompany = store.state.FkCompany;
        user.User2Comp.FkUser = user.PkUser;
        
        let body = {
            User: user
        }

        return super.create({
            log: true,
            body,
        }).then(data => {
            if (!data.IsError) {
                return this.read().then(result => {
                    return result;
                });
            } else {
                return data
            }
        }).catch(result => {
            return result;
        });

    }

    update(user = {}) {

        let body = {
            User: user
        }

        return super.update({
            log: true,
            body,
        }).then(data => {
            if (!data.IsError) {
                return this.read().then(result => {
                    return result;
                });
            } else {
                return data
            }
        }).catch(result => {
            return result;
        });

    }

    delete(user = {}) {

        let body = {
            User: user
        }

        return super.delete({
            log: true,
            body,
        }).then(data => {
            if (!data.IsError) {
                return this.read().then(result => {
                    return result;
                });
            } else {
                return data
            }
        }).catch(result => {
            return result;
        });

    }


}
