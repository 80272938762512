<template>
  <ValidationProvider :name="objName" :vid="objName" ref="validator" :rules="appliedRules" v-slot="{ errors }">
    <div
      :class="{
      'has-error-field': errors.length > 0 && !validationIconNeedMargin && !iconsTop5&&!noCheckIcons,
      'has-success-field': errors.length <= 0 && touched && !validationIconNeedMargin && !iconsTop5&&!noCheckIcons,
      'has-error-field-margin': errors.length > 0 && validationIconNeedMargin && !iconsTop5&&!noCheckIcons,
      'has-success-field-margin': errors.length <= 0 && touched && validationIconNeedMargin && !iconsTop5&&!noCheckIcons,
      'has-error-field-top5': errors.length > 0 && !validationIconNeedMargin && iconsTop5&&!noCheckIcons,
      'has-success-field-top5': errors.length <= 0 && touched && !validationIconNeedMargin && iconsTop5&&!noCheckIcons,
      'has-error-field-margin-top5': errors.length > 0 && validationIconNeedMargin && iconsTop5&&!noCheckIcons,
      'has-success-field-margin-top5': errors.length <= 0 && touched && validationIconNeedMargin && iconsTop5&&!noCheckIcons,
    }"
    >
      <slot name="label">
        <label v-if="label" class=" font-size-14 input-label "> {{ label }} <b v-if="required" class="required-asterix">
          *</b></label>
        <HelpButton v-if="hasHelpText" :content="getHelpText"></HelpButton>
      </slot>
      <div class="mb-0" :class="{'input-group': hasIcon}">
        <slot name="addonLeft">
        <span v-if="addonLeftIcon" class="input-group-prepend">
          <div class="input-group-text"><i :class="addonLeftIcon"></i></div>
        </span>
        </slot>
        <slot>

          <input
            ref="input"
            :value="numericValue"
            v-bind="$attrs"
            :disabled="isDisabled"
            :min="minInt"
            :max="maxInt"
            :step="step"
            type="number"
            @input="inputHandler($event.target.value)"
            :precision="precision"
            v-on="listeners"
            :controls="controlsActive"
            class="form-control "
            :class="[{
                      'input-group-focus ': focused,
                      'error-border ': errors.length > 0,
                      'success-border ': errors.length <= 0 && touched,
                      'has-label ': label,
                      'has-icon ': hasIcon
                    }, attachedClasses]"
            aria-describedby="addon-right addon-left"
          />

          <span v-if="errors.length >0" class="validation-error">{{
              trans(errors[0].errorMessage, errors[0].values)
            }}</span>

        </slot>

      </div>

    </div>
  </ValidationProvider>
</template>
<script>

const timeInterval = 100


import HelpButton from '../components/HelpButton.vue';

export default {
  inheritAttrs: false,
  name: 'base-numeric',
  components: {HelpButton},
  props: {
    validationIconNeedMargin: Boolean,
    required: Boolean,
    objName: {
      type: String,
    },
    inputId: {
      type: String
    },
    label: {
      type: String,
      description: 'Input label'
    },
    isDisabled: {
      type: Boolean
    },
    helpText: {
      type: String
    },
    iconsTop5: {
      type: Boolean,
    },
    minInt: {
      type: Number,
    },
    numberAlign: {
      type: String
    },
    maxInt: {
      type: Number,
    },
    precision: {
      type: Number
    },
    controlsActive: {
      type: Boolean
    },
    step: {
      type: Number
    },
    attachedClass: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      description: 'Input error',
      default: ''
    },
    noCheckIcons: {
      type: Boolean
    },
    value: {
      type: [Number],
      description: 'Input value'
    },
    addonRightIcon: {
      type: String,
      description: 'Input icon on the right'
    },
    addonLeftIcon: {
      type: String,
      description: 'Input icon on the left'
    }
  },
  data() {
    return {
      focused: false,
      touched: false,
      numericValue: null,
      interval: null,
      startTime: null,
      handler: Function
    };
  },
  watch: {
    value: {
      immediate: true,
      // handle the changed value
      handler(val, oldValue) {

        let newValue = val
        if (newValue) {

          newValue = this.toPrecision(newValue, this.precision)
          if (newValue >= this.max) {
            newValue = this.max
          }
          if (newValue <= this.min) {
            newValue = this.min
          }
          if (newValue !== val) {
            this.$emit('input', newValue)
          }
        }
        this.numericValue = newValue

        if (oldValue != newValue && !this.focused)
          this.touched = false;
      }
    }
  },
  computed: {
    appliedRules() {

      let rules = {};

      if (this.$props.required === true) {
        Object.assign(rules, {required: true})
      }

      if (this.$props.minInt != null && this.$props.minInt != "undefined") {
        Object.assign(rules, {min_value: this.$props.minInt})
      }

      if (this.$props.maxInt != null && this.$props.maxInt != "undefined") {
        Object.assign(rules, {max_value: this.$props.maxInt})
      }

      return rules;
    },
    attachedClasses() {
      return this.$props.attachedClass;
    },
    hasIcon() {
      return this.hasLeftAddon || this.hasRightAddon
    },
    hasHelpText() {
      return this.$props.helpText != undefined && this.$props.helpText != ''
    },
    getHelpText() {
      return this.trans(this.$props.helpText)
    },
    hasLeftAddon() {
      const {addonLeft} = this.$slots;
      return (
        addonLeft !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },
    hasRightAddon() {
      const {addonRight} = this.$slots;
      return (
        addonRight !== undefined ||
        this.addonRightIcon !== undefined
      );
    },
    listeners() {
      return {
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus
      };
    }
  },
  methods: {
    setFocus() {
      this.$nextTick(() => this.$refs.inputField.focus())
    },
    reset() {
      this.touched = false;
      this.focused = false;

      this.$nextTick(() => {
        this.$refs.validator.reset();
      });

    },
    onInput(evt) {
      if (!this.touched) {
        this.touched = true;
      }
      this.$emit('input', evt.target.value);
    },
    onFocus(evt) {
      this.focused = true;
      this.$emit('focus', evt)
    },
    onBlur(evt) {
      this.focused = false;
      this.$emit('blur', evt)
    },

    /**
     * Function convert value to number
     * @param val
     * @returns {number | Number}
     */
    toNumber(val) {
      let num = parseFloat(val)
      if (isNaN(val) || !isFinite(val)) {
        num = 0
      }
      return num
    },

    toPrecision(val, precision, strVal = '') {

      let position = strVal.search(".0");

      if (position > 0 && strVal.length - 2 == position) {
        return val.toFixed(1);
      }

      return precision !== undefined ? parseFloat(val.toFixed(precision)) : val
    },
    /**
     * Increment the current numeric value
     */
    increment() {
      if (!this.readonly) this.updateValue(this.toNumber(this.numericValue) + this.step)
    },
    /**
     * Decrement the current numeric value
     */
    decrement() {
      if (!this.readonly) this.updateValue(this.toNumber(this.numericValue) - this.step)
    },
    /**
     * Handle value on Input
     */
    inputHandler(val) {
      this.updateValue(this.toNumber(val), val)
    },

    updateValue: function (val, strVal = null) {

      const oldVal = this.numericValue
      val = this.toPrecision(val, this.precision, strVal)
      if (val >= this.max) {
        val = this.max
      }
      if (val <= this.min) {
        val = this.min
      }
      if (val === oldVal) {
        this.$refs.input.value = strVal && val === this.toNumber(strVal) ? strVal : val
        return
      }
      this.numericValue = val
      this.$emit('input', val)
    },
    /**
     *  Start a repetitive call to increment and decrement method after a timeInterval on mousedown event and will stop on mouseup event on controls
     * @param handler - increment or decrement method
     */
    start(handler) {
      document.addEventListener('mouseup', this.stop)
      this.startTime = new Date()
      this.handler = handler
      clearInterval(this.interval)
      this.interval = setInterval(handler, timeInterval)
    },
    /**
     * clear interval on mouseup event and remove the listener
     * @param evt - event to be removed
     */
    stop(evt) {
      document.removeEventListener(evt.type, this.stop)
      if (new Date() - this.startTime < timeInterval) {
        this.handler()
      }
      clearInterval(this.interval)
      this.interval = null
      this.handler = null
      this.startTime = null
      if (this.value !== this.numericValue) this.$emit('change', this.numericValue)
    },
  },
  beforeUnmount() {
    clearInterval(this.interval)
    this.interval = null
    this.handler = null
    this.startTime = null
  }
};
</script>
<style scoped lang="scss">

.font-size-14 {
  font-size: 14px !important;

}

.margin-bot-10 {
  margin-bottom: 10px;
}
</style>
