import {CHECK_REGISTER_TYPES} from "@/data/RegisterTypes";
import Dashboard from "@/views/dashboard/Dashboard.vue";
import Invoices from "@/views/administration/invoices/Invoices.vue";
import {LOGIN_STATE} from "@/data/LoginStates";

const mainMenuRoutes = [
    {
        path: '/',
        name: 'Dashboard',
        title: 'DASHBOARD',
        component: Dashboard,
        icon: 'fas fa-home',
        CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
        CheckIfProd: false,
    },
    {
        path: '/SearchView',
        name: 'Search',
        title: 'SEARCH',
        checkLoginState: LOGIN_STATE.LOGGED_IN,
        icon: 'fas fa-magnifying-glass',
        CheckIfProd: false,
        CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
    },
    {
        path: '/FavouritesView',
        name: 'FavouritesView',
        title: 'MY-FAVOURITES',
        checkLoginState: LOGIN_STATE.LOGGED_IN,
        icon: 'fas fa-star',
        CheckIfProd: false,
        CheckIfUserType: CHECK_REGISTER_TYPES.COMPANY_OR_STUDENT,
    },
    {
        path: '/Calendar',
        name: 'Calendar',
        title: 'CALENDAR',
        checkLoginState: LOGIN_STATE.LOGGED_IN,
        icon: 'fas fa-calendar-days',
        CheckIfProd: false,
        CheckIfUserType: CHECK_REGISTER_TYPES.COMPANY_OR_STUDENT,
    },
    {
        path: '/StudentProfile/StudentProfileView',
        name: 'MyProfile',
        checkLoginState: LOGIN_STATE.LOGGED_IN,
        title: 'MY-PROFILE',
        icon: 'fas fa-id-card',
        CheckIfUserType: CHECK_REGISTER_TYPES.STUDENT,
        CheckIfProd: false
    },
    {
        path: '/Config/MasterData/JobAds',
        name: 'JobAds',
        checkLoginState: LOGIN_STATE.LOGGED_IN,
        title: 'JOB-ADS',
        icon: 'fas fa-clipboard',
        CheckIfUserType: CHECK_REGISTER_TYPES.COMPANY,
        CheckIfProd: false,
    },
    {
        path: '/Config/MasterData/',
        name: 'MasterData',
        checkLoginState: LOGIN_STATE.LOGGED_IN,
        title: 'MAIN-DATA',
        icon: 'fas fa-gears',
        CheckIfUserType: CHECK_REGISTER_TYPES.COMPANY_OR_SCHOOL,
        CheckIfProd: false,
        children: [
            {
                path: '/Config/MasterData/MasterDataCompany',
                name: 'MasterDataCompany',
                checkLoginState: LOGIN_STATE.LOGGED_IN,
                CheckIfUserType: CHECK_REGISTER_TYPES.COMPANY_OR_SCHOOL,
                title: 'COMPANY',
                icon: 'fas fa-building',
                CheckIfProd: false
            },
            {
                path: '/Config/MasterData/MasterDataOffice',
                name: 'MasterDataOffice',
                checkLoginState: LOGIN_STATE.LOGGED_IN,
                CheckIfUserType: CHECK_REGISTER_TYPES.COMPANY_OR_SCHOOL,
                title: 'OFFICES',
                icon: 'fas fa-building',
                CheckIfProd: false,
                setDivider: true,
            },
            {
                path: '/Config/MasterData/LicensesView',
                name: 'Licenses',
                title: 'LICENSES',
                icon: 'fas fa-euro-sign',
                checkLoginState: LOGIN_STATE.LOGGED_IN,
                CheckIfUserType: CHECK_REGISTER_TYPES.COMPANY,
                CheckIfProd: false,
            },
            {
                path: '/Config/MasterData/Invoices',
                name: 'Invoices',
                title: 'INVOICES',
                icon: 'fas fa-file-invoice',
                checkLoginState: LOGIN_STATE.LOGGED_IN,
                CheckIfUserType: CHECK_REGISTER_TYPES.COMPANY,
                CheckIfProd: false,
                setDivider: true,
            },
            {
                path: '/Config/MasterData/Users',
                name: 'Users',
                checkLoginState: LOGIN_STATE.LOGGED_IN,
                CheckIfUserType: CHECK_REGISTER_TYPES.COMPANY_OR_SCHOOL,
                title: 'USERS',
                icon: 'fas fa-user',
                CheckIfProd: false
            }
        ]
    },
];

export {mainMenuRoutes};
