<template>
    <div>
        <h5 class="underlined margin-bottom-15 padding-bottom-10">{{ trans('USER') }}</h5>
        <Form class="text-start" ref="valForm" :validationSchema="validationSchema"
              @submit="validate">
            <div class="row flex">
                <div class="col-xl-2 col-lg-12 col-md-4">
                    <div class="upload mt-4 pe-md-4">
                        <input ref="fl_profile" type="file" class="d-none" accept="image/*"
                               @change="change_file"/>
                        <img
                            :src="ProfilePicture ? ProfilePicture  : require('@/assets/images/empty-profile.jpg')"
                            alt="profile"
                            class="profile-preview"
                            @click="$refs.fl_profile.click()"
                        />
                        <p><a href="javascript:;" class="mt-2"
                              @click="$refs.fl_profile.click()">{{ trans('UPLOAD-PICTURE') }}</a></p>
                    </div>
                </div>
            </div>
            <div class="row flex">
                <div class="form-group col-3">
                    <base-input
                        v-model="model.Email"
                        type="Email"
                        obj-name="Email"
                        :required="true"
                        :is-disabled="true"
                        :label="trans('EMAIL')"
                        attached-class="form-control"
                        :placeholder="trans('EMAIL')"
                        name="Email"
                        ref="Email"/>
                </div>
            </div>
            <div class="row flex">
                <div class="form-group col-3">
                    <div id="saluation-field" class="field-wrapper input">
                        <salutations-drop-down ref="Salutation"
                                               v-model="model.Salutation"
                                               :required="true"
                                               :has-null-value="true"
                                               :null-value-text="trans('SELECT-SALUTATION')"
                                               :label="trans('SALUTATION')"
                                               attached-class="form-control"
                                               name="Salutation"/>
                    </div>
                </div>
                <div class="form-group col-3">
                    <div id="nameaddition-field" class="field-wrapper input">
                        <base-input
                            v-model="model.NameAddition"
                            type="text"
                            obj-name="NameAddition"
                            :label="trans('NAME-ADDITION')"
                            attached-class="form-control"
                            :placeholder="trans('NAME-ADDITION')"
                            name="NameAddition"
                            ref="NameAddition"/>
                    </div>
                </div>
            </div>
            <div class="row flex">
                <div class="form-group col-3">
                    <div id="firstname-field" class="field-wrapper input">

                        <base-input
                            v-model="model.FirstName"
                            type="text"
                            obj-name="FirstName"
                            :label="trans('FIRSTNAME')"
                            :required="true"
                            attached-class="form-control"
                            :placeholder="trans('FIRSTNAME')"
                            name="FirstName"
                            ref="FirstName"/>
                    </div>
                </div>
                <div class="form-group col-3">
                    <div id="lastname-field" class="field-wrapper input">

                        <base-input
                            v-model="model.LastName"
                            type="text"
                            obj-name="LastName"
                            :label="trans('LASTNAME')"
                            attached-class="form-control"
                            :required="true"
                            :placeholder="trans('LASTNAME')"
                            name="LastName"
                            ref="LastName"/>
                    </div>
                </div>
            </div>
            <div class="row margin-top-30">
                <div class="form-group col-12">
                    <base-button type="button"
                                 native-type="button"
                                 v-bind:class="{'btn btn-rounded btn-mint':!changePassword,'btn btn-red btn-rounded':changePassword}"
                                 @click="changePassword=!changePassword">
                        <font-awesome-icon v-if="changePassword"
                                           icon="fas fa-caret-up"/>
                        <font-awesome-icon v-if="!changePassword"
                                           icon="fas fa-caret-down"/>
                        &nbsp;
                        <font-awesome-icon icon="fas fa-key"/> &nbsp;
                        {{ !changePassword ? $t('CHANGE-PASSWORD') : $t('NOT-CHANGE-PASSWORD') }}
                    </base-button>
                </div>
                <div v-if="changePassword" class="form-group  col-3 ">
                    <label class=" font-size-14">
                        <font-awesome-icon icon="fas fa-key"/>
                        {{ $t('OLD-PASSWORD') }}
                        <b v-if="changePassword" class="required-asterix"> *</b>
                    </label>
                    <base-input v-model="passwordChange.OldPassword"
                                type="password"
                                obj-name="old-password"
                                name="old-password"
                                attached-class="form-control font-size-14"
                                :required="changePassword"
                                placeholder="..."/>


                </div>
            </div>

            <div class="row" v-if="changePassword">
                <div class="form-group  col-3 ">
                    <label class=" font-size-14" style="float:left;margin-right:15px">
                        <font-awesome-icon icon="fas fa-key"/>
                    </label>

                    <base-input v-model="model.Password"
                                type="password"
                                name="password"
                                obj-name="password"
                                :label="$t('PASSWORD')"
                                :help-text="passwordHelp"
                                :max-length="PasswordMaxLength"
                                :min-length="PasswordMinLength"
                                :regex="PasswordRegex"
                                attached-class="form-control font-size-14"
                                :required="changePassword"
                                placeholder="..."/>


                </div>
                <div class="form-group col-3">
                    <label class="font-size-14 mid-label">
                        <font-awesome-icon icon="fas fa-key"/>
                        {{ $t('PASSWORD-REPEAT') }}
                        <b v-if="changePassword" class="required-asterix"> *</b>
                    </label>

                    <base-input v-model="model.PasswordRepeat"
                                type="password"
                                obj-name="password-repeat"
                                name="password-repeat"
                                attached-class="form-control font-size-14"
                                :required="changePassword"
                                placeholder="..."/>
                </div>
            </div>
            <div v-show="passwordHasBeenChanged" ref="pwChanged" class="pw-changed text-success">
                {{ $t('PASSWORD-HAS-BEEN-CHANGED') }}
            </div>
            <div v-show="passwordHasBeenReset" ref="pwReset" class="pw-reseted text-success">
                {{ $t('PASSWORD-RESET-ADMIN-DESC') }}
            </div>
            <hr/>
            <h6 class="underlined margin-bottom-10 margin-top-30 padding-bottom-5 bold-header">{{
                    $t('SYSTEM-SETTINGS')
                }}</h6>
            <div class="row">
                <div class="form-group col-4">
                    <data-dropdown v-model="model.DefaultLanguage" :items="getLanguages"
                                   null-value-text="PLEASE-SELECT"
                                   :has-null-value="true" :label="$t('LANGUAGE')"></data-dropdown>
                </div>
            </div>
            <h6 class="underlined margin-bottom-10 margin-top-30 padding-bottom-5 bold-header">{{ $t('MFA-AUTH') }}</h6>
            <div class="row flex">
                <div class="form-group col-4">
                    <MFATypesSelector ref="MFAType"
                                      v-model="model.MFAType"
                                      :required="false"
                                      :disabled="model.MFAActive"
                                      :has-null-value="true"
                                      :null-value-text="trans('SELECT-MFA')"
                                      :label="trans('MFA-TYPES')"
                                      attached-class="form-control"
                                      @input="mfaChange"
                                      name="MFAType"></MFATypesSelector>
                </div>
                <div class="form-group col-3" style="margin-top: 37px;margin-left:10px;">
                    <div class="terms_condition mb-4">
                        <div class="checkbox-outline-primary custom-control custom-checkbox">
                            <base-checkbox
                                id="MFAActive"
                                :required="false"
                                :disabled="true"
                                class="custom-control-input"
                                :help-text="trans('MFA-ACTIVE-HELP')"
                                :checked="model.MFAActive"
                                @input="setMFAActive"
                                :label="trans('MFA-ACTIVE')"/>
                        </div>
                    </div>
                </div>
                <div class="form-group col-3 float-left">
                    <base-button type="red"
                                 native-type="button"
                                 :disabled="!model.MFAActive"
                                 class="btn btn-sm btn-danger"
                                 style="margin-top: 33px"
                                 @click="removeMFA"

                    >
                        <font-awesome-icon icon="fa fa-trash"/>
                        {{ trans('REMOVE-MFA') }}
                    </base-button>
                </div>
            </div>

            <div class="row flex" v-if="GoogleAuth===true" style="margin-top:20px">
                <div class="form-group col-lg" style="font-weight: bold">
                    {{ getGoogleText }}
                </div>
            </div>
            <div class="row flex" v-if="GoogleAuth===true" style="margin-top:20px">
                <div class="form-group col-4">
                    <el-image :src="QRCodeString"></el-image>
                </div>
                <div class="form-group col-4">
                    {{ trans('SECRET-KEY') }}: {{ SetupKey }}
                </div>
            </div>
            <div class="row flex" v-if="AuthyAuth===true" style="margin-top:20px">
                <div class="form-group col-lg" style="font-weight: bold">
                    {{ getAuthyText }}
                </div>
            </div>
            <div class="row flex" v-if="AuthyAuth===true" style="margin-top:20px">
                <div class="form-group col-4">
                    <el-image :src="QRCodeString"></el-image>
                </div>
                <div class="form-group col-4">
                    {{ trans('SECRET-KEY') }}: {{ SetupKey }}
                </div>
            </div>
            <div class="row flex" v-if="(AuthyAuth===true||GoogleAuth===true)&&CodeValide===false">
                <div class="form-group col-4">
                    <base-input v-model="AuthenticatorCode"
                                type="text"
                                obj-name="AuthenticatorCode"
                                name="AuthenticatorCode"
                                :label="$t('AUTHENTICATOR-CODE')"
                                :help-text="trans('AUTHENTICATOR-CODE-HELP')"
                                :max-length="50"
                                :min-length="0"
                                attached-class="form-control font-size-14"
                                placeholder="..."/>

                </div>
                <div class="form-group col-4">
                    <base-button
                        native-type="button"
                        :disabled="checkDisabled"
                        class="btn btn-mint btn-rounded float-left"
                        style="margin-top: 33px"
                        @click="checkCode"

                    >{{ trans('CHECK-CODE') }}
                    </base-button>
                </div>
            </div>
            <div class="row flex" v-if="(AuthyAuth===true||GoogleAuth===true)&&CodeValide===true">
                <div class="form-group col-lg bold-green">
                    {{ trans('MFA-FINISHED') }}

                </div>

            </div>
            <button ref="silentSubmit" type="submit" class="btn-silent-submit"/>
        </Form>
        <div v-if="error" class="error-alert-wrap">
            <font-awesome-icon icon="fas fa-exclamation-triangle" class="error-icon" />
            {{ trans(errorMessage) }}
        </div>
    </div>
</template>

<script>
import {mutate} from "@/utils/mutate";
import hash from 'object-hash';
import {MFA_TYPES} from '@/data/MFATypes';
import MFATypesSelector from "@/components/dropdowns/MFATypesSelector";
import {restUri} from "@/data/restPaths";
import {restBody} from "@/utils/restBody";
import {Form} from 'vee-validate';
import SalutationsDropDown from '@/components/dropdowns/SalutationsDropdown.vue'
import * as yup from "yup";
import store from "@/store";
import {LANGUAGES_DROPDOWN} from "@/data/languages";
import DataDropdown from "@/components/dropdowns/DataDropdown.vue";
import {ProfileController} from "@/controllers/profile.controller";

const
    PW_CHANGED_LABEL_DURATION = 20000;


export default {
    name: "UserForm",
    components: {DataDropdown, MFATypesSelector, Form, SalutationsDropDown},
    data() {
        return {
            validationSchema: yup.object({
                FirstName: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                LastName: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                Salutation: yup.number().required(this.trans("REQUIRED-MESSAGE")),
                Email: yup.string().required(this.trans("REQUIRED-MESSAGE")).email(this.trans("NO-EMAIL"))
            }),
            model: {
                PkUser: null,
                FirstName: null,
                LastName: null,
                Salutation: null,
                NameAddition: null,
                Email: '',
                ProfilePicture: null,
                DefaultLanguage: '',
                PasswordRepeat: null,
                MFAType: null,
                MFAActive: false
            },
            selected_file: null,
            passwordChange: {
                PkUser: null,
                Password: null,
                OldPassword: null,

            },
            controller: new ProfileController(this),
            changePassword: false,
            passwordHasBeenChanged: false,
            passwordHasBeenReset: false,
            hashed: null,
            error: false,
            errorMessage: '',
            timeout: null,
            resetTimeout: null,
            QRCodeString: '',
            SetupKey: null,
            OldMFAType: null,
            AuthenticatorCode: null,
            CodeValide: false
        }
    },
    props: ['fromStore'],
    computed: {
        passwordHelp() {
            let text = this.$t('HLP-PASSWORD') + "\n";

            if (this.$store.state.SysSettings != null) {
                let sys = this.$store.state.SysSettings;
                if (sys.PasswordMinChar > 0) {
                    text += this.$t('PW-MIN') + sys.PasswordMinChar + this.$t('PW-MIN-CHAR') + ";\n";
                }

                if (sys.PasswordMaxChar > 0) {
                    text += this.$t('PW-MAX') + sys.PasswordMaxChar + this.$t('PW-MAX-CHAR') + ";\n";
                }

                if (sys.PasswordNeedSymbol > 0) {
                    text += this.$t('PW-MIN') + sys.PasswordNeedSymbol + this.$t('PW-MIN-SYM') + ";\n";
                }

                if (sys.PasswordMaxRepeatChar > 0) {
                    text += this.$t('PW-MAX') + sys.PasswordMaxRepeatChar + this.$t('PW-MAX-SAME') + ";\n";
                }

                if (sys.PasswordNeedNumeric > 0) {
                    text += this.$t('PW-MIN') + sys.PasswordNeedNumeric + this.$t('PW-MIN-NUMERIC') + ";\n";
                }

                if (sys.PasswordNeedLowerCase > 0) {
                    text += this.$t('PW-MIN') + sys.PasswordNeedLowerCase + this.$t('PW-MIN-LOWER') + ";\n";
                }

                if (sys.PasswordNeedUpperCase > 0) {
                    text += this.$t('PW-MIN') + sys.PasswordNeedUpperCase + this.$t('PW-MIN-UPPER') + ";\n";
                }

            }

            return text
        },
        GoogleAuth() {
            return this.model.MFAType == MFA_TYPES.GOOGLE_AUTHENTICATOR && this.OldMFAType != this.model.MFAType;
        },
        AuthyAuth() {
            return this.model.MFAType == MFA_TYPES.AUTHY && this.OldMFAType != this.model.MFAType;
        },
        PasswordMaxLength() {
            if (this.$store.state.SysSettings != null) {
                let sys = this.$store.state.SysSettings;
                return sys.PasswordMaxChar;
            }
            return null
        },
        PasswordMinLength() {
            if (this.$store.state.SysSettings != null) {
                let sys = this.$store.state.SysSettings;
                return sys.PasswordMinChar;
            }
            return null
        },
        ProfilePicture() {
            if (store.state.ProfilePictureContent == null)
                return null;

            return URL.createObjectURL(new Blob([store.state.ProfilePictureContent]));
        },
        PasswordRegex() {
            let regexText = '';

            // '^'
            if (this.$store.state.SysSettings != null) {
                let sys = this.$store.state.SysSettings;
                let praefix = '';
                if (sys.PasswordNeedSymbol > 0) {
                    praefix = '^'
                    regexText += '(?=.*?[@;:_,.\\-+*~§$%&=#?]{' + sys.PasswordNeedSymbol + ',})';
                }

                if (sys.PasswordNeedNumeric > 0) {
                    praefix = '^'
                    regexText += '(?=.*?[0-9]{' + sys.PasswordNeedNumeric + ',})';
                }

                if (sys.PasswordNeedLowerCase > 0) {
                    praefix = '^';
                    regexText += '(?=.*?[a-z]{' + sys.PasswordNeedLowerCase + ',})';
                }

                if (sys.PasswordNeedUpperCase > 0) {
                    praefix = '^';
                    regexText += '(?=.*?[A-Z]{' + sys.PasswordNeedUpperCase + ',})';
                }

                regexText = praefix + regexText;

            }
            return regexText;
        },
        checkDisabled() {
            return this.AuthenticatorCode == null || this.AuthenticatorCode === ''
        },
        disabledNormalSave() {
            return this.CodeValide === false && this.model.MFAType != null && this.model.MFAType !== 0 && this.model.MFAActive !== true
        },
        getGoogleText() {
            return this.trans('GOOGLE-HELP-TEXT')
        },
        getAuthyText() {
            return this.trans('AUTHY-HELP-TEXT')
        },
        getLanguages() {
            return LANGUAGES_DROPDOWN
        }
    },
    methods: {
        change_file() {

            let file = event.target.files[0];
            this.error = false;
            this.errorMessage = null
            let MAX_FILE_SIZE = 8 * 1024 * 1024;
            let data = this.model;
            let controller = this.controller;


            if (!file) {
                event.preventDefault();
                event.target.value = null;
                return;
            }

            if (file.size > MAX_FILE_SIZE) {
                event.preventDefault();
                event.target.value = null;
                this.error = true;
                this.errorMessage = "FILE-TO-BIG"
                return;
            }
            let fileReader = new FileReader();

            fileReader.onloadend = function (e) {
                let arr = (new Uint8Array(e.target.result)).subarray(0, 4);

                let header = "";
                for (let i = 0; i < arr.length; i++) {
                    header += arr[i].toString(16);
                }

                let type = "";
                switch (header) {
                    case "89504e47":
                        type = "image/png";
                        break;
                    case "47494638":
                        type = "image/gif";
                        break;
                    case "ffd8ffe0":
                    case "ffd8ffe1":
                    case "ffd8ffe2":
                    case "ffd8ffe3":
                    case "ffd8ffe8":
                        type = "image/jpeg";
                        break;
                    case "255044462D":
                    case "25504446":
                        type = "pdf";
                        break;
                    default:
                        type = "unknown"; // Or you can use the blob.type as fallback
                        break;
                }

                if (type !== "unknown") {
                    data.FileName = file.name;
                    data.ProfilePicture = file;
                    store.dispatch('uploadProfilePicture', {
                        ProfilePicture: data.ProfilePicture,
                        PkUser: data.PkUser
                    }).then(res => {
                        controller.GetProfilePicture(data.PkUser).then(result => {

                        })
                    });

                } else {
                    event.preventDefault();
                    event.target.value = null;

                }
            }

            fileReader.readAsArrayBuffer(file);

        },
        getEssentialData() {
            return this.model;
        },
        removeMFA() {

            new window.Swal({
                title: this.trans('DEACTIVATE-MFA'),
                text: this.trans('REALLY-DEACTIVATE-MFA'),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.trans('NO'),
                confirmButtonText: this.trans('YES'),
                padding: '2em'
            }).then(doDelete => {
                if (doDelete.isConfirmed && this.model.MFACode) {

                    return this.axios.post(restUri.REMOVE_MFA,
                        restBody({FkUser: this.$store.state.User.PkUser, MFACode: this.model.MFACode})
                    ).then(result => {
                        if (result.data.IsError) {
                            this.error = true;
                            this.errorMessage = this.trans(result.data.ErrorMessage);

                            this.$notify(
                                {
                                    title: this.trans('ERROR'),
                                    text: this.trans(result.data.ErrorMessage),
                                    type: 'error',
                                });

                            return result.data
                        } else if (result.data.IsSuccessful) {
                            this.error = false;
                            this.errorMessage = '';

                            this.$store.state.User = result.data.User;

                            this.$notify(
                                {
                                    title: this.trans('SUCCESS'),
                                    text: this.trans('DATA-SAVED'),
                                    type: 'success'
                                });
                            this.setData(result.data.User)
                            return result.data
                        }
                    })
                }
            }).catch(error => {
                this.error = true;
                this.errorMessage = this.trans(error.data.ErrorMessage);

            });


        },
        checkCode() {
            return this.axios.post(restUri.SETUP_MFA,
                restBody({
                    FkUser: this.$store.state.User.PkUser,
                    MFAType: this.model.MFAType,
                    MFACode: this.AuthenticatorCode
                })
            ).then(result => {
                if (result.data.IsError) {
                    this.error = true;
                    this.errorMessage = this.trans(result.data.ErrorMessage);
                    this.$notify(
                        {
                            title: this.trans('ERROR'),
                            text: this.trans(result.data.ErrorMessage),
                            type: 'error',
                        });
                    return result.data
                } else {
                    this.error = false;
                    this.errorMessage = '';
                    this.CodeValide = true;
                    this.model.MFAActive = true;

                    this.$store.state.User = result.data.User;

                    this.$notify(
                        {
                            title: this.trans('SUCCESS'),
                            text: this.trans('DATA-SAVED'),
                            type: 'success'
                        });

                    this.setData(result.data.User)
                    return result.data
                }
            }).catch(error => {
                this.error = true;
                this.errorMessage = this.trans(error.data.ErrorMessage);
            });
        },
        mfaChange(value) {

            if (this.GoogleAuth === true || this.AuthyAuth === true) {
                // Create setup

                return this.axios.post(restUri.GENERATE_GOOGLE_QR_CODE,
                    restBody({PkUser: this.$store.state.User.PkUser})
                ).then(result => {
                    if (result.data.IsError) {
                        this.error = true;
                        this.errorMessage = this.trans(result.data.ErrorMessage);
                        return result.data
                    } else {
                        this.error = false;
                        this.errorMessage = '';
                        this.SetupKey = result.data.SetupKey;
                        this.QRCodeString = result.data.QRCodeString;

                        return result.data
                    }
                }).catch(error => {
                    this.error = true;
                    this.errorMessage = this.trans(error.data.ErrorMessage);
                });
            }
        },
        setMFAActive(value) {
            this.model.MFAActive = value.target.checked;
        },
        getFromStore(Pk) {
            return this.$props.fromStore instanceof Function ?
                this.$props.fromStore(Pk) :
                this.model;
        },
        setError(errorMessage = null) {
            if (errorMessage != null) {
                this.errorMessage = errorMessage;
                this.error = true;
            } else {
                this.errorMessage = null;
                this.error = false;
            }
        },
        getData() {
            let Pk = this.model.PkUser != null ? this.model.PkUser : null,
                essential = this.getEssentialData();

            if (!Pk)
                return essential;

            return mutate(this.getFromStore(Pk), essential);
        },
        makeHash(apply = true) {
            let hashed = hash(this.getEssentialData());
            if (apply)
                this.hashed = hashed;
            return hashed;
        },

        setData(model = {}) {
            this.passwordHasBeenChanged = false;
            this.passwordHasBeenReset = false;
            this.model = mutate({
                PkUser: null,
                FirstName: null,
                LastName: null,
                Salutation: null,
                NameAddition: null,
                Email: '',
                DefaultLanguage: '',
                PasswordRepeat: null,
                MFAType: null,
                MFAActive: false
            }, model);
            this.makeHash();
            this.OldMFAType = this.model.MFAType;
        },
        hasChanged() {
            return this.makeHash(false) !== this.hashed;
        },
        reset() {
            this.changePassword = false;
            this.passwordChange.Password = null;
            this.passwordChange.OldPassword = null;
            this.passwordHasBeenChanged = false;
            this.passwordHasBeenReset = false;
            this.errorMessage = '';
            this.error = false;
            this.CodeValide = false;

            this.model = mutate({
                PkUser: null,
                FirstName: null,
                LastName: null,
                Salutation: null,
                NameAddition: null,
                Email: '',
                DefaultLanguage: '',
                PasswordRepeat: null,
                MFAType: null,
                MFAActive: false
            });

            this.QRCodeString = null;
            this.SetupKey = null;
            this.OldMFAType = null;
            this.AuthenticatorCode = null;

            this.makeHash();
        },
        resetPasswordChange() {
            this.changePassword = false;
            this.passwordChange.Password = null;
            this.passwordChange.OldPassword = null;
            this.model.Password = null;
            this.model.PasswordRepeat = null;
            this.passwordHasBeenChanged = false;
            this.makeHash();
        },
        getChangePassword() {
            if (!this.changePassword)
                return false;

            let form = this.$refs._form,
                model = this.model,
                passwordMatch = (!!model.Password) && model.Password === model.PasswordRepeat;


            if (form.checkValidity() === false) {
                this.$refs.silentSubmit.click();
                return false
            }

            this.error = !passwordMatch;
            this.errorMessage = passwordMatch ? "" : this.trans('PASSWORD-NO-MATCH');

            if (passwordMatch)
                return mutate(this.passwordChange, {
                    PkUser: model.PkUser,
                    Password: model.Password
                })

            return false;
        },
        showPasswordHasChanged(duration = PW_CHANGED_LABEL_DURATION) {
            this.passwordHasBeenChanged = true;
            if (this.timeout)
                window.clearTimeout(this.timeout);

            this.timeout = window.setTimeout(() => {
                this.passwordHasBeenChanged = false;
                this.timeout = null;
            }, duration);
        },

        showPasswordHasBeenReset(duration = PW_CHANGED_LABEL_DURATION) {
            this.passwordHasBeenReset = true;
            if (this.resetTimeout)
                window.clearTimeout(this.resetTimeout);

            this.resetTimeout = window.setTimeout(() => {
                this.passwordHasBeenReset = false;
                this.resetTimeout = null;
            }, duration);
        },
        validate() {
            let form = this.$refs.valForm,
                model = this.model,
                passwordMatch = (!model.Password && !model.PasswordRepeat) || model.Password === model.PasswordRepeat;

            this.error = !passwordMatch;
            this.errorMessage = passwordMatch ? "" : this.trans('PASSWORD-NO-MATCH');

            if (this.disabledNormalSave) {
                this.error = true;
                this.errorMessage = this.trans('CODE-NOT-VERIFIED');
                return Promise.resolve(false);
            }


            if (this.error)
                return Promise.resolve(false);

            return Promise.resolve(true);
        }
    },
    activated() {
        this.setData(store.state.User);
        this.controller.GetProfilePicture(store.state.User.PkUser).then(result => {
            if (result.data.size === 0)
                store.state.ProfilePictureContent = null;
            else
                store.state.ProfilePictureContent = result.data;
        })
    }
}
</script>

<style scoped lang="scss">
.pw-changed {
    display: block;
    font-weight: bold;
}

.bold-header {
    font-weight: bold;
}

.pw-reseted {
    display: block;
    padding-top: 10px;
    font-size: 12px;
}

.bold-green {
    font-weight: bold;
    color: limegreen;
}


</style>
