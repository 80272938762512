import {isObject} from "./isObject.js";
import * as CUD from "../data/cud.js";


export function applyTrackingState(body = {}, TrackingState = 0, applyToRoot = false, ignoreTackingStateIn = []){


    Object.keys(body).forEach( key =>{
        if(ignoreTackingStateIn.indexOf(key)!== -1)
          return;

        body[key] = isObject(body[key]) ? Object.assign({}, body[key],{TrackingState}) : body[key];
    });

    if(applyToRoot)
        body.TrackingState = TrackingState

    return body;
}


export const TRACKING_STATE = CUD;
