<template>

    <div class="panel-body">
        <Form ref="valForm" :validationSchema="validationSchema" @submit="validate">
            <div class="row">
                <div class="form-group col-lg-5">
                    <base-input ref="OfficeName"
                                v-model="model.OfficeName"
                                :label="trans('OFFICE-NAME')"
                                type="text"
                                :required="true"
                                obj-name="OfficeName"
                                :max-length="150"
                                attached-class="form-control" placeholder="..."/>
                </div>
                <div class="form-group col-lg-3">
                    <member-dropdown ref="MemberCount"
                                     v-model="model.OfficeProfile.MemberCount"
                                     :label="trans('MEMBER-COUNT')"
                                     :items="MemberCountList"
                                     :no-check-icons="true"
                                     :null-value-text="trans('SELECT-MEMBER-COUNT')"
                                     :has-null-value="true"
                                     attached-class="form-control"
                                     name="MemberCount"/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-5">
                    <base-input ref="OfficeWebsite"
                                v-model="model.OfficeProfile.OfficeWebsite"
                                :label="trans('OFFICE-WEB-SITE')"
                                type="text"
                                :required="false"
                                obj-name="OfficeWebsite"
                                :max-length="150"
                                attached-class="form-control" placeholder="..."/>
                </div>
                <div class="form-group col-lg-3">
                    <base-input ref="TraineePerYear"
                                v-model="model.OfficeProfile.TraineePerYear"
                                :label="trans('TRAINEE-PER-YEAR')"
                                type="number"
                                :required="false"
                                obj-name="TraineePerYear"
                                :min-int="0"
                                attached-class="form-control" placeholder="..."/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-5">
                    <base-input ref="Street"
                                v-model="model.Street"
                                :label="trans('STREET')"
                                type="text"
                                :max-length="50"
                                attached-class="form-control"
                                obj-name="Street" :required="true" placeholder="..."/>
                </div>
                <div class="form-group col-lg-2">
                    <base-input ref="StreetNr"
                                v-model="model.StreetNr"
                                type="text"
                                :max-length="10"
                                :required="true"
                                :label="trans('STREET-NR')"
                                attached-class="form-control"
                                obj-name="StreetNr" placeholder="..."/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-5">
                    <base-input ref="Addition"
                                v-model="model.AddressAddition"
                                :label="trans('ADDITION')"
                                type="text"
                                :max-length="100"
                                attached-class="form-control"
                                obj-name="Addition" placeholder="..."/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-2">
                    <base-input ref="PostCode"
                                v-model="model.PostCode"
                                :label="trans('POSTCODE')"
                                type="text"
                                :max-length="10"
                                attached-class="form-control"
                                obj-name="PostCode" :required="true" placeholder="..."/>
                </div>
                <div class="form-group col-lg-5">
                    <base-input ref="City"
                                v-model="model.City"
                                type="text"
                                :max-length="50"
                                :required="true"
                                :label="trans('CITY')"
                                attached-class="form-control"
                                obj-name="City" placeholder="..."/>
                </div>
            </div>
            <div class="row margin-bottom-15">
                <div class="form-group col-lg-3">
                    <data-dropdown ref="Country"
                                   v-model="model.Country"
                                   :label="trans('COUNTRY')"
                                   :required="true"
                                   :no-check-icons="true"
                                   :items="CountriesList"
                                   :null-value-text="trans('SELECT-COUNTRY')"
                                   :has-null-value="true"
                                   attached-class="form-control"
                                   name="Country"/>
                </div>
            </div>

            <div v-if="error" class="error-alert-wrap">
                <font-awesome-icon icon="fas fa-exclamation-triangle" class="float-right-margin"/>
                {{ trans(errorMessage) }}
            </div>

            <button ref="silentSubmit" type="submit" class="btn-silent-submit"/>
        </Form>
    </div>
</template>

<script>
import DataDropdown from "@/components/dropdowns/DataDropdown.vue";
import * as yup from 'yup';
import {Form} from 'vee-validate';
import MemberDropdown from "@/components/dropdowns/MemberDropdown.vue";

export default {
    name: "NewOfficeForm",
    props: ['fkOffice'],
    emits: ["update"],
    components: {
        MemberDropdown,
        Form, DataDropdown
    },
    data() {
        return {
            validationSchema: yup.object({
                Street: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                StreetNr: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                PostCode: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                City: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                OfficeName: yup.string().required(this.trans("REQUIRED-MESSAGE")),
            }),
            model: {
                OfficeName: '',
                Street: '',
                StreetNr: '',
                PostCode: '',
                City: '',
                Country: null,
                AddressAddition: '',
                OfficeProfile: {
                    PkOfficeProfile: null,
                    FkCompany: null,
                    FkOffice: null,
                    MemberCount: null,
                    FacebookProfile: null,
                    InstagramProfile: null,
                    TikTokProfile: null,
                    LinkedInProfile: null,
                    XingProfile: null,
                    AboutUs: null,
                    JobineoQuestion1: null,
                    JobineoAnswer1: null,
                    JobineoAnswerFile1: null,
                    JobineoQuestion2: null,
                    JobineoAnswer2: null,
                    JobineoAnswerFile2: null,
                    JobineoQuestion3: null,
                    JobineoAnswer3: null,
                    JobineoAnswerFile3: null,
                    OfficeWebsite: null,
                    TraineePerYear: null,
                },
            },
            error: false,
            errorMessage: ''
        }
    },
    computed: {
        CountriesList() {
            return this.$store.state.Lookups.CountriesLookup != null && Array.isArray(this.$store.state.Lookups.CountriesLookup) ? this.$store.state.Lookups.CountriesLookup : [];
        },
        MemberCountList() {
            return this.$store.state.Lookups.CompanySizesLookup != null && Array.isArray(this.$store.state.Lookups.CompanySizesLookup) ? this.$store.state.Lookups.CompanySizesLookup : [];
        },
    },
    methods: {
        setData(data = {}) {
            this.model = data;
        },
        getData() {
            return this.model;
        },
        validate() {
            this.error = false;
            this.errorMessage = '';

            return this.$refs.valForm.validate().then(result => {
                let otherErrors = false;
                if (this.model.Country == null || this.model.Country <= 0) {
                    this.$refs.Country.setError();
                    otherErrors = true;
                }


                if (result.valid && !otherErrors) {
                    return Promise.resolve(true);
                } else {

                    for (const key in result.errors) {
                        this.$refs[key].setError();
                    }

                    return Promise.resolve(false);
                }
            })
        },
    },
    beforeUpdate() {
    },
    mounted() {
    }
}
</script>

<style scoped>

</style>
