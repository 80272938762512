<template>
    <div class="wizard-container">
        <div class="card card-wizard active" id="wizardProfile">
            <form @submit.prevent="validateTab().then(nextTab)" ref="_form">
                <!--
                  You can switch " data-color="primary" "  with one of the next bright colors: "green", "orange", "red", "blue"
                -->
                <div class="card-header text-center">
                    <slot name="header">
                        <h3 v-if="title" class="card-title">{{ title }}</h3>
                        <h5 v-if="subTitle" class="description">{{ subTitle }}</h5>
                    </slot>

                    <div class="wizard-navigation">
                        <div class="progress-with-circle">
                            <div
                                class="progress-bar"
                                role="progressbar"
                                aria-valuenow="1"
                                aria-valuemin="1"
                                aria-valuemax="3"
                                :style="{ width: `${progress}%` }"
                            ></div>
                        </div>
                        <ul class="nav nav-pills">
                            <li
                                v-for="(tab, index) in tabs"
                                :key="tab.title"
                                role="tab"
                                :tabindex="tab.checked ? 0 : ''"
                                :id="`step-${tab.tabId}`"
                                :aria-controls="tab.tabId"
                                :aria-disabled="!tab.active"
                                :aria-selected="tab.active"
                                :ref="`tab-${index}`"
                                class="nav-item wizard-tab-link"
                                :style="linkWidth"
                            >
                                <a
                                    class="nav-link"
                                    @click="navigateToTab(index)"
                                    :class="[
                    { 'disabled-wizard-link': !tab.checked },
                    { active: tab.active },
                    { checked: tab.checked }
                  ]"
                                    data-toggle="tab"
                                >
                                    <wizard-tab-item>
                                        <template v-slot:label>
                                            <font-awesome-icon class="icon-size" v-if="tab.icon"
                                                               :icon="['fas',tab.icon]"/>
                                            <p v-if="tab.label">{{ tab.label }}</p>
                                        </template>
                                    </wizard-tab-item>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="card-footer">
                    <base-button @click="cancel" native-type="button" class="btn btn-yellow btn-sm float-left">
                        <i class="fas fa-times"/>
                        {{ cancelButtonText }}
                    </base-button>
                    <div class="float-right">
                        <base-button
                            v-if="activeTabIndex > 0"
                            @click.stop="prevTab"
                            native-type="button"
                            type="yellow"
                            style="margin-right: 15px"
                            class="btn btn-yellow btn-sm float-left margin-right-10">
                            <i class="fas fa-arrow-left"/>
                            {{ prevButtonText }}
                        </base-button>
                        <base-button
                            v-if="activeTabIndex < tabCount - 1"
                            @click.stop="$refs.silentSubmit.click()"
                            native-type="button"
                            type="mint"
                            class="btn btn-mint btn-sm float-left">
                            {{ nextButtonText }}
                            <i class="fas fa-arrow-right"/>
                        </base-button>
                        <base-button v-else
                                     @click.stop="$refs.silentSubmit.click()"
                                     native-type="button"
                                     type="blue"
                                     class="btn btn-blue btn-sm float-right">
                            <i class="fas fa-check"/>
                            {{ $t('REGISTER') }}
                        </base-button>
                    </div>
                    <div class="clearfix underlined-b-10"></div>
                </div>

                <div class="card-body">
                    <div class="tab-content">
                        <slot :activeIndex="activeTabIndex" :activeTab="activeTab"></slot>
                    </div>
                </div>

                <div class="card-footer">
                    <slot name="footer" :next-tab="nextTab" :prev-tab="prevTab">
                        <base-button @click="cancel" native-type="button"
                                     class="btn btn-yellow btn-sm float-left">
                            <i class="fas fa-times"/>
                            {{ cancelButtonText }}
                        </base-button>
                        <div class="float-right">
                            <base-button
                                v-if="activeTabIndex > 0"
                                @click.stop="prevTab"
                                native-type="button"
                                type="yellow"
                                style="margin-right: 15px"
                                class="btn btn-yellow btn-sm float-left margin-right-10">
                                <i class="fas fa-arrow-left"/>
                                {{ prevButtonText }}
                            </base-button>
                            <base-button
                                v-if="activeTabIndex < tabCount - 1"
                                @click.stop="$refs.silentSubmit.click()"
                                native-type="button"
                                type="mint"
                                class="btn btn-mint btn-sm float-left">
                                {{ nextButtonText }}
                                <i class="fas fa-arrow-right"/>
                            </base-button>
                            <base-button v-else
                                         @click.stop="$refs.silentSubmit.click()"
                                         native-type="button"
                                         type="blue"
                                         class="btn btn-blue btn-sm float-right">
                                <i class="fas fa-check"/>
                                {{ $t('REGISTER') }}
                            </base-button>
                        </div>
                        <div class="clearfix"></div>
                    </slot>
                </div>
                <button ref="silentSubmit" type="submit" class="btn-silent-submit"/>
            </form>
        </div>
    </div>
</template>

<script>
import {throttle} from './throttle';
import {h} from 'vue'
import router from "@/router";
import WizardTabItem from "@/components/Wizards/WizardTabItem.vue";

export default {

    name: 'simple-wizard',
    props: {
        startIndex: {
            type: Number,
            default: 0
        },
        title: {
            type: String,
            default: 'Title'
        },
        subTitle: {
            type: String,
            default: 'Subtitle'
        },
        prevButtonText: {
            type: String,
            default: 'Previous'
        },
        nextButtonText: {
            type: String,
            default: 'Next'
        },
        cancelButtonText: {
            type: String,
            default: 'Cancel'
        },
        finishButtonText: {
            type: String,
            default: 'Finish'
        },
        vertical: {
            type: Boolean
        }
    },
    components: {
        WizardTabItem,
    },
    provide() {
        return {
            addTab: this.addTab,
            removeTab: this.removeTab
        };
    },
    data() {
        return {
            tabs: [],
            activeTabIndex: 0,
            tabLinkWidth: 0,
            tabLinkHeight: 50
        };
    },
    computed: {
        tabCount() {

            return this.tabs.length;
        },
        linkWidth() {
            let width = 100;
            if (this.tabCount > 0) {
                width = 100 / this.tabCount;
            }
            if (this.vertical) {
                width = 100;
            }
            return {width: `${width}%`};
        },
        activeTab() {
            return this.tabs[this.activeTabIndex];
        },
        stepPercentage() {
            return (1 / (this.tabCount * 2)) * 100;
        },
        progress() {
            let percentage = 0;
            if (this.activeTabIndex > 0) {
                let stepsToAdd = 1;
                let stepMultiplier = 2;
                percentage =
                    this.stepPercentage *
                    (this.activeTabIndex * stepMultiplier + stepsToAdd);
            } else {
                percentage = this.stepPercentage;
            }
            return percentage;
        }
    },
    methods: {
        addTab(tab) {

            let index = this.tabs.indexOf(tab);
            //let index = this.tabs.indexOf(tab);
            if (index < 0) {
                index = this.tabs.length;
            } else {
                if (index != tab.pos)
                    index = tab.pos;
            }

            let tabTitle = tab.title || '';
            tab.tabId = `${tabTitle.replace(/ /g, '')}${index}`;
            if (!this.activeTab && index === 0) {
                tab.active = true;
                tab.checked = true;
            }
            if (this.activeTab === tab.name) {
                tab.active = true;
                tab.checked = true;
            }

            this.tabs.splice(tab.pos, 0, tab);

        },
        removeTab(tab) {
            const tabs = this.tabs;
            const index = tabs.indexOf(tab);
            if (index > -1) {
                tabs.splice(index, 1);
            }
        },
        validateTab() {
            let tabToValidate = this.activeTab;
            let beforeChange = tabToValidate.beforeChange;
            if (beforeChange) {
                return Promise.resolve(beforeChange())
                    .then(res => {
                        this.activeTab.hasError = !res;
                        return Promise.resolve(res);
                    })
                    .catch(() => {
                        this.activeTab.hasError = true;
                    });
            } else {
                return Promise.resolve(true);
            }
        },
        nextTab(res) {
            if (res != true)
                return;

            if (this.activeTabIndex < this.tabCount - 1) {
                this.activeTabIndex++;
            }
            return true;
        },
        cancel() {
            router.push('/LandingPageStudent')
        },
        prevTab() {
            this.activeTabIndex--;
        },
        navigateToTab(index) {
            if (this.tabs[index].checked) {
                // recursively validate each tab
                if (index > this.activeTabIndex) {
                    let valid = this.nextTab();
                    if (valid) {
                        this.navigateToTab(index);
                    }
                } else {
                    this.activeTabIndex = index;
                }
            }
        },
        onResize() {
            let tabLinks = document.getElementsByClassName('wizard-tab-link');
            if (tabLinks.length > 0 && this.tabCount > 0) {
                let {clientWidth, clientHeight} = tabLinks[0];
                this.tabLinkWidth = clientWidth;
                this.tabLinkHeight = clientHeight;
            }
        }
    },
    mounted() {
        this.activeTabIndex = this.startIndex;

        this.$nextTick(() => {

            this.tabs[this.activeTabIndex].active = true;
            this.tabs[this.activeTabIndex].checked = true;
            this.onResize();
        });
        window.addEventListener(
            'resize',
            () => {
                throttle(this.onResize, 40);
            },
            false
        );
    },
    watch: {
        activeTabIndex(newValue, oldValue) {
            if (newValue !== oldValue) {
                let oldTab = this.tabs[oldValue];
                let newTab = this.tabs[newValue];
                oldTab.active = false;
                newTab.active = true;

                if (!newTab.checked) {
                    newTab.checked = true;
                }
                this.$emit('tab-change', oldTab, newTab);
                this.$emit('update:startIndex', newValue);
            }
        }
    }
};
</script>
<style lang="scss">


/* Tab content animation */
.tab-content {
    display: flex; // to avoid horizontal scroll when animating
    .tab-pane {
        display: block;
        animation: fadeIn 0.5s;
        width: 100%;
    }
}

/**
      Extra niceties. Display error tabs and disable navigation unvisited tabs
     */
.wizard-navigation .nav-link {
    &.active,
    &.checked {
        cursor: pointer;
    }
}

.disabled-wizard-link {
    cursor: not-allowed;
}

.card-wizard {
    .card-header {
        padding-bottom: 10px;
    }
}

@mixin transitions($time, $type) {
    -webkit-transition: all $time $type;
    -moz-transition: all $time $type;
    -o-transition: all $time $type;
    -ms-transition: all $time $type;
    transition: all $time $type;
}

@mixin set-wizard-color($color) {
    .progress-with-circle .progress-bar {
        background: $color;
    }

    .nav-pills .nav-item .nav-link {
        color: $color;

        &.checked, &.active {
            background: $color;
            color: white;
        }

        &:hover {
            background: white;
        }
    }

    .nav-pills .nav-item .nav-link.active,
    .nav-pills .nav-item .nav-link.checked:focus,
    .nav-pills .nav-item .nav-link.checked:hover,
    .nav-pills .nav-item .nav-link.active:focus,
    .nav-pills .nav-item .nav-link.active:hover {
        background: $color;
    }

}

.card-wizard {
    min-height: 410px;
    opacity: 0;
    @include transitions(300ms, linear);

    &.active {
        opacity: 1;
    }


    @include set-wizard-color(#9acccb);

    &[data-color="primary"] {
        @include set-wizard-color(#4e73df);
    }

    &[data-color="green"] {
        @include set-wizard-color(darken(#00f2c3, 10%));
    }

    &[data-color="blue"] {
        @include set-wizard-color(#9acccb);
    }

    &[data-color="red"] {
        @include set-wizard-color(#fd5d93);
    }

    &[data-color="orange"] {
        @include set-wizard-color(#ff8d72);
    }

    .nav-pills .nav-item {
        position: relative;

        .nav-link {
            height: 45px;
            width: 45px;
            min-width: 45px;
            border-radius: 50% !important;
            position: absolute;
            left: 50%;
            top: -20px;
            transform: translate(-50%);
            background-color: lightgray;

            p {
                position: absolute;
                top: 110%;
                left: 50%;
                transform: translate(-50%);
                font-weight: 400;
            }

            &:hover, &:focus {
                background-color: #86eae9 !important;
                color: white !important;
            }

        }


    }

    .nav-pills .nav-item .nav-link.active,
    .nav-pills .nav-item .nav-link:hover,
    .nav-pills .nav-item .nav-link:focus,
    .nav-pills .nav-item .nav-link.active:focus,
    .nav-pills .nav-item .nav-link.active:hover {
        box-shadow: none;
    }

    .picture-container {
        position: relative;
        cursor: pointer;
        text-align: center;
    }

    .card-body {
        background: transparent;
        border-top-left-radius: 0.4285rem;
        border-top-right-radius: 0.4285rem;
    }

    .card-footer {
        background-color: transparent;
        border-bottom-left-radius: 0.4285rem;
        border-bottom-right-radius: 0.4285rem;


        .pull-right {
            padding-right: 80px;
        }

        .pull-left {
            padding-left: 80px;
        }
    }

    .wizard-navigation {
        position: relative;
        margin: 80px auto 60px;

        .nav-link {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 0;
            padding-right: 0;
        }

        .progress-with-circle {
            position: relative;
            top: 5px;
            height: 5px;
            border-radius: 0.4285rem;

            .progress-bar {
                height: 100%;
                border-radius: 0.4285rem;
                box-shadow: none;
                -webkit-transition: width .3s ease;
                -o-transition: width .3s ease;
                transition: width .3s ease;
            }
        }
    }

    .wizard-navigation .nav-link i,
    .moving-tab i {
        display: inline-block;
        font-size: 19px;
        line-height: initial;
        padding: 0;
        vertical-align: bottom;
    }

    .picture {
        width: 106px;
        height: 106px;
        background-color: #999999;
        border: 1px solid lightgray;
        color: #FFFFFF;
        border-radius: 50%;
        margin: 5px auto;
        overflow: hidden;
        transition: all 0.2s;
        -webkit-transition: all 0.2s;

        &:hover {
            border-color: #2ca8ff;
        }
    }


    .picture input[type="file"] {
        cursor: pointer;
        display: block;
        height: 100%;
        left: 0;
        opacity: 0 !important;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .picture-src {
        width: 100%;
    }

    .tab-content {
        display: block;
    }

    .wizard-footer {
        padding: 0 15px;

        .checkbox {
            margin-top: 16px;
        }
    }


    .wizard-header {
        text-align: center;
        padding: 25px 0 35px;

        h5 {
            margin: 5px 0 0;
        }
    }

    .nav-pills > li {
        text-align: center;
    }

    .btn {
        text-transform: uppercase;
    }

    .info-text {
        text-align: center;
        font-weight: 300;
        margin: 10px 0 30px;
    }

    .choice {
        text-align: center;
        cursor: pointer;
        margin-top: 20px;

        &[disabled] {
            pointer-events: none;
            cursor: not-allowed;
            opacity: .5;
        }

        .icon {
            text-align: center;
            vertical-align: middle;
            height: 116px;
            width: 116px;
            border-radius: 50%;
            color: #9ACCCB;
            margin: 0 auto 20px;
            border: 1px solid lightgray;
            transition: all 0.2s;
            -webkit-transition: all 0.2s;
        }

        i {
            font-size: 30px;
            line-height: 116px;
            position: absolute;
            left: 0;
            right: 0;
        }

        &:hover,
        &.active {
            .icon {
                border-color: #2ca8ff;
            }
        }

        input[type="radio"],
        input[type="checkbox"] {
            position: absolute;
            left: -10000px;
            z-index: -1;
        }
    }

    .btn-finish {
        display: none;
    }

    .card-title + .description {
        font-size: 17px;
        margin-bottom: 32px;
    }

    .wizard-title {
        margin: 0;
    }

    .nav-pills {
        background-color: white;
        border-radius: 0.4285rem;
        height: 5px;

        > li + li {
            margin-left: 0;
        }

        > li > a {
            border: 0 !important;
            border-radius: 0;
            line-height: 18px;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 500;
            min-width: 100px;
            text-align: center;
            color: #555555;
        }

        > li.active > a,
        > li.active > a:hover,
        > li.active > a:focus,
        > li > a:hover,
        > li > a:focus {
            background-color: inherit;
            box-shadow: none;
        }

        > li i {
            display: block;
            font-size: 30px;
            padding: 15px 0;
        }
    }

    .input-group.has-danger {
        .error {
            display: block;
            width: 100%;
            margin-top: 3px;
        }
    }
}

.icon-size {
    font-size: 20px;
}


</style>
