export const PAYMENT_INTERVAL = {
    QUARTERLY: 1,
    HALFYEARLY: 2,
    YEARLY: 3
}


export const PAYMENT_INTERVAL_DROPDOWN = [
    {
        label: 'PAYMENT_INTERVAL_QUARTERLY',
        value: PAYMENT_INTERVAL.QUARTERLY
    },

    {
        label: 'PAYMENT_INTERVAL_HALFYEARLY',
        value: PAYMENT_INTERVAL.HALFYEARLY
    },

    {
        label: 'PAYMENT_INTERVAL_YEARLY',
        value: PAYMENT_INTERVAL.YEARLY
    }
]
