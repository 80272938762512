/**
 * ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
 * ++ ATTENTION: Actions must be normal functions and NO arrow funcions  ++
 * ++ Arrow functions can't have a new scope and vuex can't assign the   ++
 * ++ store scope to them!                                               ++
 * ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
 *
 * The kind of export we can see below where we export *
 * does affect that all exported functions in the sub folders are
 * compiled together in this module and every sub-function is provided as a function of this module.
 */


import {restBody} from "@/utils/restBody";
import {ACTIVATE, CREATE, DEACTIVATE, DELETE, UPDATE} from "@/data/cud";
import {mutate} from "@/utils/mutate";
import {isObject} from "@/utils/isObject";
import {applyTrackingState} from "@/utils/applyTrackingState";
import axios from "axios";

export * from './actions/messages.actions.js';
export * from './actions/logAndRegister.actions.js';
export * from './actions/notification.actions.js';
export * from './actions/dashboard.actions.js';
export * from './actions/system.actions.js';
export * from './actions/company.actions.js';
export * from './actions/file.actions.js';

/**
 * This is the holy grale of the BWP application's REST communication
 * Please read the description in confluence:
 *
 * @param store
 * @param data
 * @returns {Promise<never>|Promise<AxiosResponse<any> | never>}
 * @constructor
 */
export function CRUD(store, data) {

    const type = data.type != null ? (data.type + '').trim().toUpperCase() : 'READ',
        CUD = isObject(data.path) && data.path.CUD != null ? data.path.CUD : null,
        GET = isObject(data.path) && data.path.GET != null ? data.path.GET : null,
        body = isObject(data.body) ? mutate(data.body) : null,
        commit = data.commit != null ? data.commit : null,
        log = data.log != null ? data.log : false,
        logText = data.logText != null ? data.logText : false,
        ignoreTackingStateIn = data.ignoreTackingStateIn != null ?
            (Array.isArray(data.ignoreTackingStateIn) ?
                data.ignoreTackingStateIn :
                [data.ignoreTackingStateIn]) :
            [],
        applyTrackingStateToBodyRoot = data.applyTrackingStateToBodyRoot != null ? data.applyTrackingStateToBodyRoot : false,
        processChain = result => {

            let _data = result.data;

            if (log) {
                if (logText) {
                    let text = logText instanceof Function ? logText(_data) : logText + '';
                }

                //Object.keys(_data).forEach(key => console.log("%c  |- " + key, "font-weight:bold;", _data[key]));

            }

            if (!_data.IsError && commit) {
                if (Array.isArray(commit))
                    commit.forEach(commitRoute => {
                        if (commitRoute instanceof Function)
                            commitRoute(_data)
                        else
                            this.commit(commitRoute, _data);
                    });
                else if (typeof commit === 'string')
                    this.commit(commit, _data);
                else if (commit instanceof Function)
                    commit(_data);

            }
            return _data;
        };

    if (!body)
        return Promise.reject('Actions.CRUD() > No body!');


    switch (type) {

        default:
        case 'READ':
            if (!GET)
                return Promise.reject('Actions.CRUD() > No GET path!');
            return axios.post(GET, restBody(body)).then(processChain)

        case 'CREATE':
            applyTrackingState(body, CREATE, applyTrackingStateToBodyRoot, ignoreTackingStateIn);
            break;


        case 'UPDATE':
            applyTrackingState(body, UPDATE, applyTrackingStateToBodyRoot, ignoreTackingStateIn);
            break;

        case 'DELETE':
            applyTrackingState(body, DELETE, applyTrackingStateToBodyRoot, ignoreTackingStateIn);
            break;

        case 'DEACTIVATE':
            applyTrackingState(body, DEACTIVATE, applyTrackingStateToBodyRoot, ignoreTackingStateIn);
            break;

        case 'ACTIVATE':
            applyTrackingState(body, ACTIVATE, applyTrackingStateToBodyRoot, ignoreTackingStateIn);
            break;
    }

    if (!CUD)
        return Promise.reject('Actions.CRUD() > No CUD path!');

    return axios.post(CUD, restBody(body)).then(processChain);
}







