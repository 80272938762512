<template>
  <el-select
    multiple
    class="select-info"
    size="large"
    collapse-tags
    v-model="val"
    :placeholder="placeHolderText"
  >
    <el-option
      v-for="option in translatedOptions"
      class="select-info"
      :value="option.value"
      :label="option.label"
      :key="option.value"
    >
    </el-option>
  </el-select>
</template>


<script>

export default {
  name: "BaseMultiSelect",
  props: ['list', 'emptyText', 'value'],
  data() {
    return {
      val: [],
    }
  },
  watch: {
    val(val) {
      this.$emit('input', val);
    },
    value(val) {
      this.val = val;
    }
  },
  computed: {
    translatedOptions() {
      let items = this.list;
      this.list.forEach(item => {
        item.label = this.trans(item.label)
      });
      return items;
    },
    placeHolderText() {
      return this.$props.emptyText;
    }
  },
  methods: {
    reset() {
      this.val = null;
    },
  }
}
</script>

<style lang="scss">

$--font-path: '~element-plus/theme-chalk/fonts';
@import "~element-plus/theme-chalk/src/index";

.el-select {
  width: 100%;
  margin-bottom: 30px;
}

.el-tag.el-tag--info {
  color: #6e707e !important;
}

</style>
