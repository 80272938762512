

export const setStudentProfile = (state, data) => {
    state.MyStudentProfile = data.StudentProfile;

}









