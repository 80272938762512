<template>
    <div class="container-jobineo">
        <div class="section-heading">
            <span class="no-decoration breadcrumb-decorate-blue">
                <font-awesome-icon icon="fas fa-cog"/>
                {{ trans('MAIN-DATA') }}
            </span>
            <span class="no-decoration breadcrumb-decorate-yellow">
                <font-awesome-icon icon="fas fa-angle-right"/> &nbsp;
                <font-awesome-icon icon="fas fa-file-invoice"/>
                {{ trans('INVOICES') }}
            </span>
        </div>
        <div class="row layout-top-spacing">
            <div id="basic" class="col-lg-12 col-sm-12 col-12 layout-spacing">
                <div class="statbox panel box box-shadow">
                    <div class="panel-heading">
                        <div class="row">
                            <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                                <h4>{{ trans('INVOICES') }}</h4>
                            </div>
                        </div>
                    </div>
                    <div class="panel-body">
                        <div class="custom-table">
                            <b-table
                                responsive
                                small hover bordered
                                :busy="processing"
                                select-mode="single"
                                sort-by="LicenseType"
                                :items="invoiceList"
                                :fields="fields"
                                show-empty
                                primary-key="PkInvoice"
                                tbody-class="clickable"
                            >
                                <template v-slot:cell(InvoiceDate)="data">
                                    <div>
                                        {{ GetDate(data.item.InvoiceDate) }}
                                    </div>
                                </template>
                                <template v-slot:cell(NetAmount)="data">
                                    <div>
                                        {{ data.item.NetAmount }}€
                                    </div>
                                </template>
                                <template v-slot:cell(GrossAmount)="data">
                                    <div>
                                        {{ data.item.GrossAmount }}€
                                    </div>
                                </template>
                                <template v-slot:cell(Actions)="data">
                                    <PopOver class="dark margin-left-5" arrow hover openDelay="200"
                                             closeDelay="100" placement="right">
                                        <template #content>
                                                        <span class="popOverText"
                                                              v-for="(line,lineNumber) of downloadLabel.split('\n')"
                                                              v-bind:key="lineNumber">
                                                            {{ line }}<br/>
                                                        </span>
                                        </template>
                                        <div
                                            class="btn-tree-menu add"
                                            style="cursor: pointer;"
                                            @click="download(data.item.InvoiceNr)">
                                            <font-awesome-icon icon="fas fa-download"/>
                                        </div>
                                    </PopOver>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {InvoiceController} from "@/controllers/invoice.controller";
import {restUri} from "@/data/restPaths";
import {cutDateString} from "@/utils/convertDateTime";
import {restBody} from "@/utils/restBody";

export default {
    name: "Invoices-View",
    data() {
        return {
            controller: new InvoiceController(this),
            processing: false,
            invoices: [],
            twiggleFlag: false
        }
    },
    computed: {
        fields() {
            return [
                {key: "InvoiceNr", sortable: true, label: this.trans("INVOICE-NR")},
                {key: "InvoiceDate", sortable: false, label: this.trans("INVOICE-DATE")},
                {key: "NetAmount", sortable: true, label: this.trans("NET-AMOUNT")},
                {key: "GrossAmount", sortable: true, label: this.trans("GROSS-AMOUNT")},
                {key: "Actions", sortable: false, label: this.trans("ACTIONS")},
            ]
        },
        invoiceList() {
            return this.invoices != null && Array.isArray(this.invoices) ? this.invoices : [];
        },
        downloadLabel() {
            return this.trans('DOWNLOAD-INVOICE-HELPTIP');
        }
    },
    methods: {
        GetDate(value) {
            return cutDateString(value)
        },
        download(fileName) {

            const
                FkCompany = this.$store.state.FkCompany,
                FileHash = fileName + ".pdf";

            this.axios.post(restUri.DOWNLOAD_INVOICE, restBody({
                    FkCompany: FkCompany,
                    FileName: FileHash,
                }),
                {
                    responseType: 'blob'
                }).then(result => {
                let fileURL = window.URL.createObjectURL(new Blob([result.data])),
                    fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', FileHash);
                document.body.appendChild(fileLink);

                fileLink.click();
            });

        },
    },
    activated() {


        this.controller.read().then((result) => {
                if (!result.IsError)
                    this.invoices = result.InvoicesList
            }
        );
    },

}
</script>

<style lang="scss" scoped>

.custom-table {
    background: transparent;
}

.btn svg {
    margin-right: 8px;
}

.dark {
    --popper-theme-background-color: #333333;
    --popper-theme-background-color-hover: #333333;
    --popper-theme-text-color: white;
    --popper-theme-border-width: 0px;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 15px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

:deep(.popper #arrow::before) {
    left: 0px;
}

.popOverText {
    color: white;
    font-size: 14px;
    font-weight: 500;
}

.margin-left-5 {
    margin-left: 1px !important;
}

</style>
