import {createApp} from 'vue'
import App from './App.vue'

const app = createApp(App)

// ------------------------------------------------------------------------------

import * as bootstrap from 'bootstrap';

window.bootstrap = bootstrap;

import PerfectScrollbar from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import VueNouislider from 'vue3-nouislider';
import 'vue3-nouislider/dist/vue3-nouislider.css';
import {vMaska} from 'maska';
import {registerScrollSpy} from 'vue3-scroll-spy';

registerScrollSpy(app, {offset: 118});
import {ClientTable} from 'v-tables-3';
import VueFormWizard from 'vue3-form-wizard';
import 'vue3-form-wizard/dist/style.css';
import {createHead} from '@vueuse/head';

import flatpickr from "flatpickr";
import {German} from "flatpickr/dist/l10n/de.js"

flatpickr.localize(German); // default locale is now Russian
import VueflatPickr from 'vue-flatpickr-component'

flatpickr.setDefaults(
    {
        // default options here
        dateFormat: 'd.m.Y'
    }
)

const head = createHead();

//Sweetalert
import Swal from 'sweetalert2';

window.Swal = Swal;

// modals
import '@/scss/jobineo/sass/components/custom-modal.scss';

app.use(PerfectScrollbar);
app.use(VueNouislider)
app.directive(vMaska)
app.use(ClientTable)
app.use(VueFormWizard)
app.use(head);

import appSetting from './app-setting';

window.$appSetting = appSetting;
window.$appSetting.init();

// ------------------------------------------------------------------------------
import ElementPlus from 'element-plus'
import BootstrapVue from 'bootstrap-vue-next'
import 'flag-icons/css/flag-icons.min.css'
//import './scss/jobineo/custom.scss'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {globalCookiesConfig} from "vue3-cookies";
import MenuIcon from "vue-material-design-icons/Menu.vue"
import ResSplitPane from 'vue-resize-split-pane';
import i18n from './i18n'
import './data/jobineoCountries.js';
import HelpButton from './components/HelpButton.vue';
import FileSelect from './components/FileSelect.vue';
import GlobalComponents from './plugins/globalComponents'
import VueKonva from 'vue-konva'
import browserDetect from "vue-browser-detect-plugin";


import vSelect from "vue-next-select";
import {localeTranslation} from "@/utils/localeTranslation";
import {cutDateString, cutDateTimeString} from "@/utils/convertDateTime";
import {isObject} from "@/utils/isObject";
import {GUID_0} from "@/data/cud";
import '../src/scss/jobineo/mainStyle.scss'
import Popper from "vue3-popper";
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {initJobineo} from './jobineo';
import {defineRule, configure} from 'vee-validate';
import {localize, setLocale} from '@vee-validate/i18n';
import AllRules from '@vee-validate/rules';
import en from '@/locales/en.json';
import de from '@/locales/de.json';
import VueNumericInput from 'vue-numeric-input';
import {Settings} from 'luxon'
import router from "@/router";
import UUID from "vue-uuid";
import Notifications from '@kyvg/vue3-notification'
import VueCookieComply from 'vue-cookie-comply'
import 'vue-cookie-comply/dist/style.css'

initJobineo();
//import JobineoPlugin from "./utils/JobineoVuePlugin.js";


/* import specific icons */
import {
    faGear,
    faUser,
    faArrowLeft,
    faLock,
    faUsers,
    faRocket,
    faGlobe,
    faEye,
    faClipboard,
    faSyncAlt,
    faChevronDown,
    faCalendarDays,
    faFileText,
    faUnlock,
    faCalendarPlus,
    faUserCircle,
    faTrash,
    faAngleRight,
    faComments,
    faCar,
    faCirclePlus,
    faQuestionCircle,
    faArrowsRotate,
    faCircleQuestion,
    faInfoCircle,
    faCaretUp,
    faCaretDown,
    faFolderOpen,
    faHeart,
    faEllipsis,
    faCamera,
    faUserAlt,
    faUserEdit,
    faBuilding,
    faFlagCheckered,
    faCartShopping,
    faAddressCard,
    faCircleNotch,
    faPenToSquare,
    faFileLines,
    faEuroSign,
    faCertificate,
    faCheck,
    faXmark,
    faBell,
    faShield,
    faSync,
    faPuzzlePiece,
    faEnvelopeOpen,
    faSuitcase,
    faGraduationCap,
    faSchoolFlag,
    faChevronRight,
    faStamp,
    faHome,
    faUndoAlt,
    faVialCircleCheck,
    faCalendar,
    faSpinner,
    faGears,
    faTimes,
    faKey,
    faIdCard,
    faEnvelope,
    faPaperPlane,
    faArrowRight,
    faRankingStar,
    faPencil,
    faStar,
    faHourglassHalf,
    faFileInvoice,
    faBan,
    faMagnifyingGlassLocation,
    faMagnifyingGlassDollar,
    faMagnifyingGlass,
    faDownload,
    faArrowRightToBracket,
    faArrowRightFromBracket,
    faMapLocationDot,
    faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons'

import {
    faStar as farStar,
    faHeart as farHeart,
} from '@fortawesome/free-regular-svg-icons'

import {
    faFacebook,
    faTwitter,
    faTiktok,
    faInstagram,
    faLinkedin,
    faXing
} from '@fortawesome/free-brands-svg-icons'
import {isLicensed} from "@/rights";

/* add icons to the library */
library.add(faGear,
    faPenToSquare,
    farStar,
    farHeart,
    faGlobe,
    faRocket,
    faEye,
    faDownload,
    faLock,
    faClipboard,
    faFacebook,
    faTwitter,
    faSyncAlt,
    faStar,
    faUnlock,
    faMapLocationDot,
    faCalendarPlus,
    faArrowsRotate,
    faFileInvoice,
    faBan,
    faCamera,
    faArrowLeft,
    faFileText,
    faCertificate,
    faInstagram,
    faHourglassHalf,
    faRankingStar,
    faCar,
    faPencil,
    faTiktok,
    faLinkedin,
    faXing,
    faMagnifyingGlassLocation,
    faIdCard,
    faUserCircle,
    faHeart,
    faGears,
    faCaretUp,
    faCaretDown,
    faSync,
    faBell,
    faPuzzlePiece,
    faAngleRight,
    faCalendarDays,
    faEllipsis,
    faTrash,
    faSuitcase,
    faCheck,
    faMagnifyingGlass,
    faMagnifyingGlassDollar,
    faComments,
    faFolderOpen,
    faCircleNotch,
    faHome,
    faChevronRight,
    faUndoAlt,
    faVialCircleCheck,
    faKey,
    faXmark,
    faUsers,
    faTimes,
    faEnvelopeOpen,
    faChevronDown,
    faGraduationCap,
    faSpinner,
    faArrowRight,
    faSchoolFlag,
    faFileLines,
    faPaperPlane,
    faArrowRightToBracket,
    faArrowRightFromBracket,
    faShield,
    faEnvelope,
    faStamp,
    faQuestionCircle,
    faCircleQuestion,
    faCirclePlus,
    faInfoCircle,
    faUserAlt,
    faUserEdit,
    faUser,
    faBuilding,
    faFlagCheckered,
    faCartShopping,
    faAddressCard,
    faEuroSign,
    faCalendar,
    faExclamationTriangle);


function noSubmitHandler(e) {
    e.preventDefault();
    e.stopPropagation();
    return false;
}


app.config.globalProperties.$noSubmitHandler = noSubmitHandler;
app.config.globalProperties.trans = localeTranslation;
app.config.globalProperties.isLicensed = isLicensed;

app.config.globalProperties.$cutDateString = cutDateString;
app.config.globalProperties.$cutDateTimeString = cutDateTimeString;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$browserDetect = browserDetect;
app.config.globalProperties.GUID_ZERO = GUID_0;
app.config.globalProperties.$debounceFlag = false;
app.config.globalProperties.$debouncedTimeout = null;
app.config.globalProperties.setError = function (...args) {
    if (typeof this.error === 'undefined' || typeof this.errorMessage === 'undefined') {
        //console.error('Vue-Instance.setError(): data-property error or errorMessage is missing');
        return false;
    }

    if (args.length === 2) {
        this.error = args[0];
        this.errorMessage = args[1];
    } else if (args.length === 1 && isObject(args[0])) {
        this.error = args[0].IsError;
        this.errorMessage = args[0].ErrorMessage;
    }
    return true;
};

app.config.globalProperties.$forceDebouncedUpdate = function (delay = 10, extraFunction = () => {
}) {
    if (this.$debounceFlag) {
        this.$debounceFlag = false;
        if (this.$debouncedTimeout) clearTimeout(this.$debouncedTimeout);
        return;
    }
    this.$debounceFlag = true;

    this.$debouncedTimeout = setTimeout(() => {
        if (extraFunction instanceof Function) extraFunction(this);
        this.$forceUpdate();
    }, delay);

}

Object.keys(AllRules).forEach(rule => {
    defineRule(rule, AllRules[rule]);
});


configure({
    validateOnInput: true,
    generateMessage: localize({
        en,
        de,
    }),
});

setLocale('de');

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {

    if (error.response.status === 401 || error.response.status === 500) {
        if (store.state.loggedIn) {
            store.commit('logOff');
            router.push('/LandingPageStudent');
        }
    }

    if (error.response.status === 400 && (error.response.data.IsError === null ||
        error.response.data.IsError === undefined)) {
        error.response.data.IsError = true;
        error.response.data.IsSystemError = true;
        error.response.data.ErrorMessage = 'SYSTEM-ERROR';
    }

    return Promise.reject(error.response);
})
axios.interceptors.request.use(function (request) {
    return request;
})


globalCookiesConfig({
    expireTimes: "30d", path: "/", domain: "jobineo.de", secure: true, sameSite: "None",
});

app.config.productionTip = false

app.use(ElementPlus, {
    i18n: (key, value) => i18n.t(key, value)
})


Settings.defaultLocale = 'de'


app.component("menu-icon", MenuIcon);
app.component('rs-panes', ResSplitPane);
app.component('help-button', HelpButton);
app.component('file-select', FileSelect);
app.component("PopOver", Popper);
app.component("v-select", vSelect);

app.use(i18n);
app.use(BootstrapVue);
app.use(VueCookieComply);
app.use(store);
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(VueKonva);
app.use(GlobalComponents);
//app.use(JobineoPlugin);
app.use(VueNumericInput);
app.use(UUID);
app.use(VueAxios, axios);
app.use(router)
app.use(Notifications)

app.mount('#app');
