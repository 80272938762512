<template>
    <component
        :is="tag"
        :type="tag === 'button' ? nativeType : ''"
        :disabled="disabled || loading"
        class="btn standardStyle"
        :class="[
      { 'btn-round': round },
      { 'btn-block': block },
      { 'btn-wd': wide },
      { 'btn-icon btn-fab': icon },
      { [`btn-${type}`]: type },
      { [`btn-${size}`]: size },
      { 'btn-simple': simple },
      { 'btn-link': link },
      { disabled: disabled && tag !== 'button' }
    ]"
    >
        <slot name="loading">
            <i v-if="loading" class="fas fa-spinner fa-spin"></i>
        </slot>
        <slot></slot>
    </component>
</template>
<script>
export default {
    name: 'base-button',
    props: {
        tag: {
            type: String,
            default: 'button',
            description: 'Button html tag'
        },
        round: Boolean,
        icon: Boolean,
        block: Boolean,
        loading: Boolean,
        wide: Boolean,
        disabled: Boolean,
        type: {
            type: String,
            default: 'default',
            description: 'Button type (primary|secondary|danger etc)'
        },
        nativeType: {
            type: String,
            default: 'button',
            description: 'Button native type (e.g button, input etc)'
        },
        size: {
            type: String,
            default: '',
            description: 'Button size (sm|lg)'
        },
        simple: {
            type: Boolean,
            description: 'Whether button is simple (outlined)'
        },
        link: {
            type: Boolean,
            description: 'Whether button is a link (no borders or background)'
        }
    }
};
</script>
<style scoped lang="scss">
//@import "../scss/sass/black-dashboard-pro";

.standardStyle {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 15px !important;
}

.btn-round {
    border-radius: 30px;
}

.btn-xs {
    padding: 0.25rem 0.4rem !important;
    font-size: 0.875rem !important;
    line-height: 0.5 !important;
    border-radius: 0.2rem !important;
}
</style>
