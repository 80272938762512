/**
 *
 * Removes the properties: IsError and ErroMessage from the data object
 *
 * @param {Object} data
 * @returns {*}
 */


const cleanResponseEnvelope = (data = null) => {

    if(data == null || ! data instanceof Object )return data;
    delete data["IsError"];
    delete data["ErrorMessage"];

}


export default cleanResponseEnvelope;
