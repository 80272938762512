export const setDashboardConfigs = (state, data) => {

    state.Dashboard.Config.DashboardConfigsAll = [];

    data.DashboardConfigs.forEach(config => {
        state.Dashboard.Config.DashboardConfigsAll.push(config)
    });


    state.Dashboard.Config.BoxMyRequest = null;
    state.Dashboard.Config.BoxMyRequest = data.BoxMyRequest;

    state.Dashboard.Config.BoxMatch = null;
    state.Dashboard.Config.BoxMatch = data.BoxMatch;

    state.Dashboard.Config.BoxAppointment = null;
    state.Dashboard.Config.BoxAppointment = data.BoxAppointment;

    state.Dashboard.Config.BoxRecievedRequest = null;
    state.Dashboard.Config.BoxRecievedRequest = data.BoxRecievedRequest;
}


export const setDashboardConfigsUpdate = (state, data) => {

    if (data.BoxMyRequest != null) {
        state.Dashboard.Config.BoxMyRequest = null;
        state.Dashboard.Config.BoxMyRequest = data.BoxMyRequest;
    }

    if (data.BoxRecievedRequest != null) {
        state.Dashboard.Config.BoxRecievedRequest = null;
        state.Dashboard.Config.BoxRecievedRequest = data.BoxRecievedRequest;
    }

    if (data.BoxMatch != null) {
        state.Dashboard.Config.BoxMatch = null;
        state.Dashboard.Config.BoxMatch = data.BoxMatch;
    }


    if (data.BoxAppointment != null) {
        state.Dashboard.Config.BoxAppointment = null;
        state.Dashboard.Config.BoxAppointment = data.BoxAppointment;
    }
}

export const setDashboardSmallBoxData = (state, data) => {

    state.Dashboard.Data.DashboardSmallBoxes.ProfileLikes = data.ProfileLikes != null ? data.ProfileLikes : 0;
    state.Dashboard.Data.DashboardSmallBoxes.ProfileCount = data.ProfileCount != null ? data.ProfileCount : 0;
    state.Dashboard.Data.DashboardSmallBoxes.MatchCount = data.MatchCount != null ? data.MatchCount : 0;
    state.Dashboard.Data.DashboardSmallBoxes.OpenRequestsCount = data.OpenRequestsCount != null ? data.OpenRequestsCount : 0;
    state.Dashboard.Data.DashboardSmallBoxes.UserStudentCount = data.UserStudentCount != null ? data.UserStudentCount : 0;

}

export const setOpenAppointments = (state, data) => {

    state.Dashboard.Data.OpenAppointments = [];
    state.Dashboard.Data.OpenAppointments = data.AppointmentList;
}

export const setMatches = (state, data) => {

    state.Dashboard.Data.Matches = [];
    state.Dashboard.Data.Matches = data.MatchingViewList;
}

export const setMyRequests = (state, data) => {

    state.Dashboard.Data.MyRequests = [];
    state.Dashboard.Data.MyRequests = data.MatchingViewList;
}


export const setRecievedRequests = (state, data) => {

    state.Dashboard.Data.RecievedRequests = [];
    state.Dashboard.Data.RecievedRequests = data.RecievedRequestsDTOList;
}








