<template>
    <div class="container-jobineo">
        <div class="section-heading">
            <router-link to="/" class="no-decoration gray-color">
                <font-awesome-icon icon="fas fa-home"/>
                {{ $t('DASHBOARD') }}
            </router-link>
            <span class="no-decoration breadcrumb-decorate-yellow">
                <font-awesome-icon icon="fas fa-angle-right"/> &nbsp;
                <font-awesome-icon icon="fas fa-clipboard"/>
                {{ trans('JOB-ADS') }}
            </span>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <div class="container-jobineo">
                    <div class="row layout-top-spacing">
                        <div id="basic" class="col-lg-12 col-sm-12 col-12 layout-spacing">
                            <div class="statbox panel box box-shadow">
                                <div class="custom-table">
                                    <FilterHeader
                                        :add-label="$t('ADD-JOB-AD')"
                                        :remove-label="trans('DELETE-JOB-AD')"
                                        :has-add="isLicensedNew"
                                        :has-activate="true"
                                        :has-deactivate="true"
                                        :activate-label="trans('ACTIVATE-JOB-AD')"
                                        :deactivate-label="trans('DEACTIVATE-JOB-AD')"
                                        :has-remove="true"
                                        @deactivate="deactivateJobAd"
                                        @activate="activateJobAd"
                                        :active-state="selectedJobAd != null ? selectedJobAd.IsActive : false"
                                        @remove="deleteItem"
                                        :remove-filters="true"
                                        @create="showModal"
                                        :has-selection="selectedPkJobAd"
                                        :has-reload="true"
                                        @reload="load"
                                    ></FilterHeader>
                                    <LimitHeader :header-type="1"></LimitHeader>

                                    <b-table
                                        responsive
                                        small hover bordered
                                        :busy="loading"
                                        select-mode="single"
                                        sort-by="JobAdName" sortDesc
                                        :items="JobAdsList"
                                        :fields="fields"
                                        primary-key="PkJobAd"
                                        @row-clicked="rowClicked"
                                        tbody-class="clickable"
                                    >
                                        <template v-slot:cell(OfficeName)="data">
                                            <div>
                                                {{ data.item.Office != null ? data.item.Office.OfficeName : '' }}
                                            </div>
                                        </template>
                                        <template v-slot:cell(IsActive)="data">
                                            <div>
                                                {{
                                                    data.item.IsActive != null && data.item.IsActive == true ? trans('YES') : trans('NO')
                                                }}
                                            </div>
                                        </template>
                                    </b-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8">
                <div v-show="!selectedPkJobAd" class="disabled-overlay"></div>
                <div v-show="!selectedPkJobAd" class="disabled-overlay-advice">
                    <div class="center-box">
                      <span>
                      <font-awesome-icon icon="fas fa-arrow-left"/><br/>
                      {{ trans('SELECT-JOB-AD-FOR-EDITING') }}
                      </span>
                    </div>
                </div>
                <div class="container-jobineo" v-show="showEdit">
                    <div class="row layout-top-spacing">
                        <div id="basic" class="col-lg-12 col-sm-12 col-12 layout-spacing">
                            <div class="statbox panel box box-shadow">
                                <JobAdForm ref="editJobAdForm"
                                           :jobad-controller="controller"
                                           @update="save"></JobAdForm>
                                <div class="row">
                                    <div class="form-group col-lg-12">
                                        <base-button
                                            native-type="button"
                                            class="btn btn-red float-left float-left-margin"
                                            @click="cancel"
                                        >
                                            <font-awesome-icon icon="fas fa-times"/>
                                            {{ $t('CANCEL') }}
                                        </base-button>


                                        <base-button
                                            native-type="button"
                                            class="btn btn-mint float-right float-right-margin"
                                            @click="$refs.editJobAdForm.$refs.silentSubmit.click()"

                                        >
                                            <font-awesome-icon v-if="processing"
                                                               icon="fas fa-spin fa-circle-notch"/>
                                            <font-awesome-icon v-if="!processing"
                                                               icon="fas fa-check"/>
                                            {{ $t('SAVE') }}
                                        </base-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <JobAdCreateModal
            :job-ad-controller="controller"
            @saved="load()"
            ref="createModal"></JobAdCreateModal>

    </div>
</template>

<script>
/**
 * View: MasterDataOffice
 * Path: /Config/MasterData/MasterDataOffice
 * A sub view for the Config > MaterData area.
 * For managing offices-data.
 *
 */
import JobAdForm from "./JobAdForm.vue";
import JobAdCreateModal from "./JobAdCreateModal.vue";
import FilterHeader from "@/components/dropdowns/FilterHeader.vue";
import {JobAdController} from "@/controllers/jobad.controller";
import store from "@/store";
import LimitHeader from "@/components/LimitHeader.vue";
import {LICENSE_TYPES} from "@/data/licenseTypes";

export default {
    name: "MasterDataJobAds",
    components: {LimitHeader, FilterHeader, JobAdCreateModal, JobAdForm},
    data() {
        return {
            controller: new JobAdController(this),
            columns: ['OfficeName', 'AdName', 'IsActive'],
            JobAdsList: [],
            selectedJobAd: null,
            loading: true,
            showEdit: false,
            isUpdating: false,
            isDeleting: false,
            loadingList: false,
            processing: false,
            selectedPkJobAd: null,
            fields: [
                {key: "OfficeName", sortable: true, label: this.trans("OFFICE-NAME")},
                {key: "AdName", sortable: true, label: this.trans("JOB-AD-NAME")},
                {key: "IsActive", sortable: false, label: this.trans("IS-ACTIVE")},
            ],
        }
    },
    computed: {
        isLicensedNew() {
            return this.isLicensed([LICENSE_TYPES.PACKAGE_SCHOOL, LICENSE_TYPES.PACKAGE_S, LICENSE_TYPES.PACKAGE_M, LICENSE_TYPES.PACKAGE_L], false)
        }
    },
    methods: {
        cancel() {
            this.selectedJobAd = {};
            this.$refs.editJobAdForm.setData(this.selectedJobAd);
            this.showEdit = false;
            this.selectedPkJobAd = null;
            this.selectedJobAd = null;
        },
        save() {
            let data = this.$refs.editJobAdForm.getData();
            this.controller.update(data).then(response => {

                if (response.IsError) {
                    this.error = true;
                    this.errorMessage = response.ErrorMessage;
                    this.$notify(
                        {
                            title: this.trans('ERROR'),
                            text: this.trans(response.ErrorMessage),
                            type: 'error',
                        });
                } else {
                    this.selectedJobAd = response.JobAd;
                    this.$refs.editJobAdForm.setData(this.selectedJobAd);
                    this.$notify(
                        {
                            title: this.trans('SUCCESS'),
                            text: this.trans('DATA-SAVED'),
                            type: 'success'
                        });
                }

            }).finally(() => {
                this.processing = false;
            })
        },
        activateJobAd() {
            if (this.selectedJobAd.PkJobAd == null)
                return;

            new window.Swal({
                title: this.trans('ACTIVATE-JOB-AD'),
                text: this.trans('REALLY-ACTIVATE-JOB-AD'),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.trans('NO'),
                confirmButtonText: this.trans('ACTIVATE'),
                iconColor: '#ce6f74',
                padding: '2em'
            }).then(saResult => {
                if (saResult.isConfirmed) {
                    this.selectedJobAd.IsActive = true;
                    this.controller.update(this.selectedJobAd).then(response => {
                        if (response.IsError) {
                            this.error = true;
                            this.errorMessage = response.ErrorMessage;
                            this.$notify(
                                {
                                    title: this.trans('ERROR'),
                                    text: this.trans(response.ErrorMessage),
                                    type: 'error',
                                });
                        } else {
                            this.selectedJobAd = response.JobAd;
                            this.$refs.editJobAdForm.setData(this.selectedJobAd);
                            this.$notify(
                                {
                                    title: this.trans('SUCCESS'),
                                    text: this.trans('DATA-DELETED'),
                                    type: 'success'
                                });
                        }
                    })
                }
            })
        },
        deactivateJobAd() {
            if (this.selectedJobAd.PkJobAd == null)
                return;

            new window.Swal({
                title: this.trans('DEACTIVATE-JOB-AD'),
                text: this.trans('REALLY-DEACTIVATE-JOB-AD'),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.trans('NO'),
                confirmButtonText: this.trans('DEACTIVATE'),
                iconColor: '#ce6f74',
                padding: '2em'
            }).then(saResult => {
                if (saResult.isConfirmed) {
                    this.selectedJobAd.IsActive = false;
                    this.controller.update(this.selectedJobAd).then(response => {
                        if (response.IsError) {
                            this.error = true;
                            this.errorMessage = response.ErrorMessage;
                            this.$notify(
                                {
                                    title: this.trans('ERROR'),
                                    text: this.trans(response.ErrorMessage),
                                    type: 'error',
                                });
                        } else {
                            this.selectedJobAd = response.JobAd;
                            this.$refs.editJobAdForm.setData(this.selectedJobAd);
                            this.$notify(
                                {
                                    title: this.trans('SUCCESS'),
                                    text: this.trans('DATA-DELETED'),
                                    type: 'success'
                                });
                        }
                    })
                }
            })
        },
        deleteItem() {
            if (this.selectedJobAd.PkJobAd == null)
                return;

            new window.Swal({
                title: this.trans('DELETE-JOB-AD'),
                text: this.trans('REALLY-DELETE-JOB-AD'),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.trans('NO'),
                confirmButtonText: this.trans('DELETE'),
                iconColor: '#ce6f74',
                padding: '2em'
            }).then(saResult => {
                if (saResult.isConfirmed) {

                    this.controller.delete(this.selectedJobAd).then(response => {
                        if (response.IsError) {
                            this.error = true;
                            this.errorMessage = response.ErrorMessage;
                            this.$notify(
                                {
                                    title: this.trans('ERROR'),
                                    text: this.trans(response.ErrorMessage),
                                    type: 'error',
                                });
                        } else {
                            this.selectedJobAd = {};
                            this.$refs.editJobAdForm.setData(this.selectedJobAd);
                            this.$notify(
                                {
                                    title: this.trans('SUCCESS'),
                                    text: this.trans('DATA-DELETED'),
                                    type: 'success'
                                });
                        }
                    })
                }
            })
        },
        showModal() {

            this.$refs.createModal.open();
            let modal = new window.bootstrap.Modal(document.getElementById('jobAdModal'));
            modal.show();
        },
        load() {
            this.loading = true;
            this.selectedJobAd = {
                PkJobAd: null,
                FkCompany: null,
                FkOffice: null,
                AdName: null,
                CreatedDate: null,
                AdType: null,
                Duration: null,
                QualificationId: null,
                FreePositions: null,
                Salary: null,
                StartDate: null,
                EndDate: null,
                IsActive: null,
                LocationType: null,
                EntryDate: null,
                SalaryType: null,
                AdText: null,
                DeadLine: null,
                FullTime: null,
                Salutation: null,
                NameAddition: null,
                FirstName: null,
                LastName: null,
                Phone: null,
                Email: null,
                Mobile: null,
                JobId: null,
                JobFieldId: null,
            };
            this.$refs.editJobAdForm.setData(this.selectedJobAd);
            this.showEdit = false;
            this.selectedPkJobAd = null;
            this.selectedJobAd = null;
            this.controller.read().then(result => {
                this.JobAdsList = result.JobAdsList;

                store.dispatch('GetLimitationsAndLicenses');

            }).finally(() => {
                this.loading = false;
            })
        },
        rowClicked(item) {
            this.showEdit = false;
            if (!item) return;
            this.selectedPkJobAd = item.PkJobAd;
            this.showEdit = true;
            this.selectedJobAd = item;

            this.$refs.editJobAdForm.setData(item);
        },
    },
    activated() {
        this.load();
    },
}
</script>

<style lang="scss" scoped>

.custom-table {
    background: transparent;
}


</style>
