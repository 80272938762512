<template>
    <div>
        <Form ref="valForm" :validationSchema="validationSchema">
            <div class="row">
                <div class="form-group col-lg-5">
                    <base-input ref="CompanyName"
                                v-model="model.CompanyName"
                                :label="trans('COMPANY-NAME')"
                                type="text"
                                :required="true"
                                obj-name="CompanyName"
                                :max-length="150"
                                attached-class="form-control" placeholder="..."/>
                </div>
                <div class="form-group col-lg-3">
                    <member-dropdown ref="MemberCount"
                                     v-model="model.MemberCount"
                                     :label="trans('MEMBER-COUNT')"
                                     :items="MemberCountList"
                                     :no-check-icons="true"
                                     :null-value-text="trans('SELECT-MEMBER-COUNT')"
                                     :has-null-value="true"
                                     attached-class="form-control"
                                     name="MemberCount"/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-5">
                    <base-input ref="Street"
                                v-model="model.Street"
                                :label="trans('STREET')"
                                type="text"
                                :max-length="50"
                                attached-class="form-control"
                                obj-name="Street" :required="true" placeholder="..."/>
                </div>
                <div class="form-group col-lg-2">
                    <base-input ref="StreetNr"
                                v-model="model.StreetNr"
                                type="text"
                                :max-length="10"
                                :required="true"
                                :label="trans('STREET-NR')"
                                attached-class="form-control"
                                obj-name="StreetNr" placeholder="..."/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-5">
                    <base-input ref="Addition"
                                v-model="model.AddressAddition"
                                :label="trans('ADDITION')"
                                type="text"
                                :max-length="100"
                                attached-class="form-control"
                                obj-name="Addition" placeholder="..."/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-2">
                    <base-input ref="PostCode"
                                v-model="model.PostCode"
                                :label="trans('POSTCODE')"
                                type="text"
                                :max-length="10"
                                attached-class="form-control"
                                obj-name="PostCode" :required="true" placeholder="..."/>
                </div>
                <div class="form-group col-lg-5">
                    <base-input ref="City"
                                v-model="model.City"
                                type="text"
                                :max-length="50"
                                :required="true"
                                :label="trans('CITY')"
                                attached-class="form-control"
                                obj-name="City" placeholder="..."/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-3">
                    <data-dropdown ref="Country"
                                   v-model="model.Country"
                                   :label="trans('COUNTRY')"
                                   :required="true"
                                   :no-check-icons="true"
                                   :items="CountriesList"
                                   :null-value-text="trans('SELECT-COUNTRY')"
                                   :has-null-value="true"
                                   attached-class="form-control"
                                   name="Country"/>
                </div>
            </div>

        </Form>
        <div v-if="error" class="error-alert-wrap"><font-awesome-icon icon="fas fa-exclamation-triangle" class="error-icon" /> {{ trans(errorMessage) }}
        </div>
    </div>
</template>


<script>
import * as yup from 'yup';
import {Form} from 'vee-validate';
import DataDropdown from "@/components/dropdowns/DataDropdown";
import MemberDropdown from "@/components/dropdowns/MemberDropdown.vue";

export default {
    name: "CompanyStep",
    props: ['root-component'],
    components: {MemberDropdown, Form, DataDropdown},
    data() {
        return {
            validationSchema: yup.object({
                Street: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                StreetNr: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                PostCode: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                City: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                CompanyName: yup.string().required(this.trans("REQUIRED-MESSAGE")),
            }),
            model: {
                CompanyName: '',
                Street: '',
                StreetNr: '',
                PostCode: '',
                City: '',
                Country: null,
                AddressAddition: '',
                MemberCount: null
            },
            error: false,
            errorMessage: ''
        }
    },
    computed: {
        CountriesList() {
            return this.$store.state.Lookups.CountriesLookup != null && Array.isArray(this.$store.state.Lookups.CountriesLookup) ? this.$store.state.Lookups.CountriesLookup : [];
        },
        MemberCountList() {
            return this.$store.state.Lookups.CompanySizesLookup != null && Array.isArray(this.$store.state.Lookups.CompanySizesLookup) ? this.$store.state.Lookups.CompanySizesLookup : [];
        }

    },
    methods: {
        submitForm(valid) {
            this.$emit('update', valid)
        },
        memberSelect(value) {
            this.model.MemberCount = value;
        },
        countrySelect(value) {
            this.model.Country = value;
        },
        reset() {
            this.model.CompanyName = ''
            this.model.Street = ''
            this.model.StreetNr = ''
            this.model.PostCode = ''
            this.model.City = ''
            this.model.Country = null
            this.model.AddressAddition = ''
            this.model.MemberCount = null
        },
        validate() {


            this.error = false;
            this.errorMessage = '';
            return this.$refs.valForm.validate().then(result => {
                if (result.valid) {
                    this.$emit('on-validated', true, this.model);
                    return Promise.resolve(true);
                } else
                    return Promise.resolve(false);
            })

        }

    }
}
</script>

<style scoped>


</style>
