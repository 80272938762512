<template>
  <div class="select-container">
    <label class="file-select">
      <div class="btn btn-sm btn-success float-left">
        <span>{{$t('SELECT-FILE')}}</span>
      </div>

      <input ref="fileUploader" type="file" accept="image/*" @change="handleFileChange"/>
    </label>
    <div v-if="hasImage" style="display: flex">
      <span style="padding-left: 10px; padding-right: 10px;">{{FileName}}</span>
      <div class="fas fa-trash sc-mini-btn-delete" style="margin-top: 5px" @click="deleteFile"
           v-if="auth(deletePermission, CAN.DELETE, false)"
           v-b-popover.hover.top="$t('DELETE')">
      </div>
    </div>
    <b-alert
      v-model="error"
      variant="danger"
      style="margin-left: 30px;"
      class="error-alert">
      <div class="error-alert-wrap"><i class="fas fa-exclamation-triangle"/>
        {{errorMessage}}
      </div>
    </b-alert>
  </div>
</template>

<script>
    import $ from 'jquery';

    export default {
        name: "FileSelect",
        props: ["file", "fileLink", "deleteMessage", "deletePermission"],
        data() {
            return {
                File: null,
                error: false,
                errorMessage: ""
            }
        },
        methods: {
            handleFileChange(e) {
                this.error = false;
                this.errorMessage = "";

                const file = e.target.files[0];

                if (!file) {
                    e.preventDefault();
                    return;
                }

                if (file.size > 1024 * 1024) {
                    e.preventDefault();
                    this.error = true;
                    this.errorMessage = this.trans('FILE-TOO-BIG');
                    return;
                }

                this.$emit('uploadFile', file);
                this.$refs.fileUploader.value = null;
            },
            deleteFile() {

                YesNoModal.getInstance().openReallyDeletePromise({body: this.deleteMessageText})
                    .then(doDelete => {
                        if (doDelete) {

                            this.$emit('deleteFile', this.$props.fileLink);
                            this.$refs.fileUploader.value = null;
                        }
                    });
            }
        },
        computed: {
            deleteMessageText() {
                return this.$props.deleteMessage != null ? this.$props.deleteMessage : this.trans('REALLY-DELETE-LOGO');
            },
            hasImage() {
                return this.$props.fileLink != null;
            },
            FileName() {
                return this.$props.fileLink != null ? this.$props.fileLink.FileName : null;
            }
        }
    }
</script>

<style scoped>

  .file-select > input[type="file"] {
    display: none;
  }

  .select-container {
    display: flex;
    width: 100%;
  }

</style>
