<template>
    <div class="form auth-boxed">
        <div class="form-container outer">
            <div class="form-form">
                <div class="form-form-wrap">
                    <div class="form-container">
                        <div class="form-content">
                            <h1 class="">{{ trans('LOGIN-HEADER') }}</h1>
                            <p class="">{{ trans('LOGIN-SENTENCE') }}</p>

                            <form class="text-start" @submit.prevent="login">
                                <div class="form">
                                    <div id="username-field" class="field-wrapper input">
                                        <label for="username">{{ trans('EMAIL') }} <b
                                            class="required-asterix">
                                            *</b></label>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-user"
                                        >
                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                            <circle cx="12" cy="7" r="4"></circle>
                                        </svg>
                                        <input autocomplete="email" required type="text"
                                               class="form-control "
                                               v-model="Email"
                                               :placeholder="trans('ENTER-LOGIN')"/>
                                    </div>

                                    <div id="password-field" class="field-wrapper input mb-2">
                                        <div class="d-flex justify-content-between">
                                            <label for="password">{{ trans('PASSWORD') }}<b
                                                class="required-asterix">
                                                *</b></label>
                                            <router-link to="/PasswordForgotten" class="forgot-pass-link">{{
                                                    trans('FORGOT-PASSWORD')
                                                }}
                                            </router-link>
                                        </div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-lock"
                                        >
                                            <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                            <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                        </svg>
                                        <input autocomplete="current-password" required :type="pwd_type"
                                               v-model="Password"
                                               class="form-control " :placeholder="$t('PASSWORD')"/>
                                        <svg
                                            @click="set_pwd_type"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            id="toggle-password"
                                            class="feather feather-eye"
                                        >
                                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                            <circle cx="12" cy="12" r="3"></circle>
                                        </svg>
                                    </div>
                                    <div class="terms_condition mb-4">
                                        <div class="checkbox-outline-primary custom-control custom-checkbox">
                                            <base-checkbox class="custom-control-input"
                                                           id="rememberMe"
                                                           :required="false"
                                                           :checked="rememberMe"
                                                           @input="setrememberMe"
                                                           :label="$t('REMEMBER-ME')"/>

                                        </div>
                                    </div>

                                    <div class="d-sm-flex justify-content-between">
                                        <div class="field-wrapper">
                                            <button type="submit" class="btn btn-primary input-rounded">
                                                {{ $t('SIGN-IN') }}
                                            </button>
                                        </div>
                                    </div>
                                    <p class="signup-link">{{ trans('NOT-REGISTERED') }}<br/>
                                        <router-link to="/RegisterStudent">{{
                                                trans('REGISTER-STUDENT')
                                            }}
                                        </router-link>
                                        <br/>
                                        <router-link to="/RegisterWizard">{{
                                                trans('REGISTER-COMPANY')
                                            }}
                                        </router-link>
                                    </p>
                                </div>
                            </form>
                            <div v-if="error" class="error-alert-wrap">
                                <font-awesome-icon icon="fas fa-exclamation-triangle" class="error-icon" />
                                {{ trans(errorMessage) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>


<script>
/**
 * View: Login
 * Path: /Login
 * The login view. This view is public.
 *
 */
import {getConfig} from "@/jobineo";
import {silentEvent} from "@/utils/silentEvent";
import {useCookies} from "vue3-cookies";
import router from "@/router";
import '@/scss/jobineo/sass/authentication/auth-boxed.scss';

const {cookies} = useCookies();
export default {

    name: 'login-comp',
    data() {

        return {
            error: false,
            errorMessage: false,
            Notification: null,
            waiting: false,
            Email: localStorage.getItem('Email'),
            Password: null,
            rememberMe: localStorage.getItem('rememberMe') === 'true',
            autoLogin: false,
            prodUrl: getConfig('prodUrl', null),
            pwd_type: 'password'
        };
    },
    props: {
        objName: {
            type: String,
        },
    },
    computed: {},
    components: {},
    methods: {
        set_pwd_type() {
            if (this.pwd_type === 'password') {
                this.pwd_type = 'text';
            } else {
                this.pwd_type = 'password';
            }
        },
        setrememberMe(value) {
            this.rememberMe = value.target.checked;
        },
        externalLink(e) {
            silentEvent(e);
            // window.location.href = e.target.href;
        },

        resetModel: function (rememberMe = false) {
            this.error = false;
            this.errorMessage = "";
            this.Email = null;
            this.Password = null;
            this.rememberMe = rememberMe;
        },
        login: function () {
            if (!this.Email || !this.Password)
                return;

            this.waiting = true;

            let body = {
                Email: this.Email,
                Password: this.Password,
                RememberMe: this.rememberMe
            }

            this.error = false;
            this.errorMessage = "";

            this.$store.dispatch('login', body).then((data) => {
                this.waiting = false;

                if (data && !data.IsError) {
                    if (!data.User.Agbaccepted || !data.User.Dsgvoaccepted) {
                        router.push('/AGBReaccept');
                        return;
                    }

                    if (data.NeedToValidate2FA) {
                        router.push('/MFACheck');
                        return;
                    }

                    this.resetModel(cookies.get('rememberMe') === 'true');


                    router.push('/');
                } else {
                    this.error = true;
                    this.errorMessage = data && data['ErrorMessage'] != null ? this.trans(data['ErrorMessage']) : '';
                }
            }).catch(result => {
                this.waiting = false;
                if (result.data) {

                    if (result.data.IsError) {
                        this.error = true;
                        this.errorMessage = this.trans(result.data.ErrorMessage);
                    }
                } else {
                    this.error = true;
                    this.errorMessage = this.trans(result);
                }
            });


        }
    },

}
</script>

<style scoped lang="scss">


.spacing-10 {
    @media (max-width: 991px) {
        margin-top: 20px;
    }

    @media (min-width: 992px) {
        margin-left: 10px;
        margin-right: 10px;
    }
}

.spacing-remember {
    margin: 17px 5px;
    word-wrap: break-word;
    font-size: 12px;
}

.opacity {
    opacity: 100;
}

.auth-boxed {
    .form-form {
        .form-form-wrap {
            min-height: 100%;
        }
    }
}

.custom-checkbox .custom-control-label::before, .custom-checkbox .custom-control-label::after {
    top: 0rem;
    margin-top: 10px;
    margin-right: 5px;
}


</style>


