<template>
    <!-- Modal -->
    <div ref="modal"
         class="modal fade template-modal"
         tabindex="-1"
         role="dialog"
         id="userModal"
         aria-hidden="true">
        <div class="modal-dialog modal-xxl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title text-success">
                        <font-awesome-icon icon="fas fa-plus-circle" class="text-success"/> &nbsp;
                        <font-awesome-icon icon="fas fa-user" class="text-success"/>
                        <span class="modal-header-text">
                        {{ trans('ADD-USER') }}
                            </span>
                    </h5>
                    <button type="button" data-dismiss="modal" ref="closeBtn" id="closeBtn" data-bs-dismiss="modal"
                            aria-label="Close"
                            class="btn-close">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="tab-content" id="simpletabContent">
                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <NewUserForm ref="newForm" :is-new="true"></NewUserForm>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="footer-inner">

                        <base-button
                            type="yellow" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close"
                            native-type="button"
                            class="btn btn-yellow input-rounded float-left">
                            <font-awesome-icon icon="fas fa-times"/>
                            {{ $t('CANCEL') }}
                        </base-button>

                        <WaitingButton
                            size="sm"
                            icon="fas fa-check"
                            class="float-right btn btn-mint input-rounded"
                            :waiting="isSaving"
                            @click.prevent="save">
                            {{ $t('SAVE') }}
                        </WaitingButton>


                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import WaitingButton from "@/components/WaitingButton.vue";
import NewUserForm from "@/views/administration/users/NewUserForm.vue";

export default {
    name: "UserCreateModal",
    components: {NewUserForm, WaitingButton},
    props: ['userController'],
    data() {
        return {
            isSaving: false,
            error: false,
            errorMessage: null,
            newTabIndex: 0,
            User: {
                PkUser: null,
                FirstName: null,
                LastName: null,
                Salutation: null,
                NameAddition: null,
                Email: '',
                Password: null,
                PasswordRepeat: null,
                User2Comp: {
                    PkUserToCompany: null,
                    FkCompany: null,
                    FkUser: null,
                    FkOffice: null
                }
            },
        }
    },
    computed: {
        controller() {
            return this.$props.userController ? this.$props.userController : {};
        }
    },
    methods: {
        twiggle() {
        },
        open() {
            this.User = {
                PkUser: null,
                FirstName: null,
                LastName: null,
                Salutation: null,
                NameAddition: null,
                Email: '',
                Password: null,
                PasswordRepeat: null,
                User2Comp: {
                    PkUserToCompany: null,
                    FkCompany: null,
                    FkUser: null,
                    FkOffice: null
                }
            };

            const createForm = this.$refs.newForm;
            createForm.setData(this.User);

        },
        save() {
            let form = this.$refs.newForm;

            form.validate().then(result => {
                if (result === true) {
                    let item = form.getData();

                    this.controller.create(item)
                        .then(data => {
                                if (!data.IsError) {
                                    this.$notify(
                                        {
                                            title: this.trans('SUCCESS'),
                                            text: this.trans('DATA-SAVED'),
                                            type: 'success'
                                        });
                                    this.$refs.closeBtn.click();
                                    this.$emit('saved')
                                } else {
                                    form.setError(data.ErrorMessage);
                                    this.$notify(
                                        {
                                            title: this.trans('ERROR'),
                                            text: this.trans(data.ErrorMessage),
                                            type: 'error',
                                        });
                                }

                            }
                        )
                }
            })

        },
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    }

}
</script>

<style scoped>
.template-modal {
    z-index: 9999;
}


.footer-inner {
    display: block;
    width: 100%;
}

.parent-selection-header > .badge {
    font-size: 14px;
}

.tabs-wrap {
    height: 100%;
}

.modal-header-text {
    vertical-align: 0.8em;
    margin-left: 10px;
}

</style>
