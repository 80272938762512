



export const JOB_TYPES = {
    INTERN_SHIP: 1,
    VOCATIONAL_TRAINING: 2,
    DUAL_STUDY: 3,
    MINI_JOB: 4,
    CAREER_ENTRY: 5
}


