<template>
    <div class="section-heading">
        <span class="no-decoration breadcrumb-decorate-blue">
                <font-awesome-icon icon="fas fa-cog"/>
                {{ trans('MAIN-DATA') }}
            </span>
        <router-link to="/Config/MasterData/JobAds"
                     class="no-decoration breadcrumb-decorate-gray">
            <font-awesome-icon icon="fas fa-angle-right"/> &nbsp;
            <font-awesome-icon icon="fas fa-clipboard"/>
            {{ trans('JOB-ADS') }}
        </router-link>
        <span class="no-decoration breadcrumb-decorate-yellow">
                <font-awesome-icon icon="fas fa-angle-right"/> &nbsp;
                <font-awesome-icon icon="fas fa-clipboard"/>
                {{ trans('JOB-AD') }} {{ GetJobAdName }}
            </span>
    </div>
    <div class="layout-px-spacing">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">

                <div class="text-center user-info">
                    <img
                        :src="selectedImage != null ? selectedImage :  require('@/assets/images/empty-profile.jpg')"
                        alt="avatar" class="col-lg-12 col-md-12 col-sm-12 col-12 profile-picture-office"/>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 layout-top-spacing">
                <div class="my-search layout-spacing">
                    <div class="panel">
                        <div class="panel-body">
                            <div class="text-center user-info">
                                <img
                                    :src="selectedImageOffice != null ? selectedImageOffice :  require('@/assets/images/empty-profile.jpg')"
                                    alt="avatar" class="col-lg-12 col-md-12 col-sm-12 col-12 profile-picture-office"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-11 col-lg-11 col-md-11 col-sm-11 layout-top-spacing">
                <h1 style="font-weight:700;">{{ JobAdObj.AdName }}</h1>
                <div class="clearfix underlined-b-10"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1"></div>
            <div class="bubble-div col-xl-9 col-lg-9 col-md-9 col-sm-9">
            <span class="bubbles" v-if="JobAdObj.EntryDate!=null">{{ trans('START') }} {{
                    GetDate(JobAdObj.EntryDate)
                }}</span>
                <span class="bubbles">{{
                        trans(GetFullTimeText)
                    }}</span>
                <span class="bubbles">{{
                        GetDeadLine
                    }}</span>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                <span class="text-color-white float-right mr-1">
                    {{ trans(GetCreatedDate) }}
                </span>
            </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">
            <div class="user-profile layout-spacing">
                <div class="panel panelblue">
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 info-container">
                                <h2>{{ trans('INFOS') }}</h2>
                            </div>
                            <div class="col-xl-11 col-lg-11 col-md-11 col-sm-11 ">
                                <h2 class="header-black">{{ JobAdObj.Office != null ? JobAdObj.Office.OfficeName : '' }}
                                    |
                                    {{ JobAdObj.Office != null ? JobAdObj.Office.City : '' }}</h2>
                            </div>
                        </div>
                        <div class="user-info-list">
                            <div class="row">
                                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9">
                                    <ul class="contacts-block list-unstyled ul-black" style="margin:10px;">
                                        <li class="contacts-block__item li-size" v-if="JobAdObj.AdType">
                                            <div class="row">
                                                <div class="col">
                                                    <span style="font-weight:bold;">{{
                                                            trans("JOB-TYPE-SHOW")
                                                        }}</span>
                                                    <span class="col">{{ trans(GetJobType) }}</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="contacts-block__item li-size" v-if="JobAdObj.JobFieldId != null">
                                            <div class="row">
                                                <div class="col">
                                                    <span style="font-weight:bold;">{{
                                                            trans(GetStudyJobText)
                                                        }}</span>
                                                    <span class="col">{{ trans(GetJobField) }}</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="contacts-block__item li-size" v-if="JobAdObj.JobId != null">
                                            <div class="row">
                                                <div class="col">
                                                    <span style="font-weight:bold;">{{
                                                            trans("JOB-ID-SHOW")
                                                        }}</span>
                                                    <span class="col">{{ trans(GetJobId) }}</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="contacts-block__item li-size"
                                            v-if="JobAdObj.SalaryType != null&& JobAdObj.Salary!=null">
                                            <div class="row">
                                                <div class="col">
                                                    <span style="font-weight:bold;">{{
                                                            trans("SALARY-TYPE-SHOW")
                                                        }}</span>
                                                    <span class="col">{{ trans(GetSalary) }}</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="contacts-block__item li-size" v-if="JobAdObj.FreePositions != null">
                                            <div class="row">
                                                <div class="col">
                                                    <span style="font-weight:bold;">{{
                                                            trans("FREE-POSITIONS-SHOW")
                                                        }}</span>
                                                    <span class="col">&nbsp; {{ GetFreePositions }}</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="contacts-block__item li-size" v-if="JobAdObj.FreePositions != null">
                                            <div class="row">
                                                <div class="col">
                                                    <span style="font-weight:bold;">{{
                                                            trans("DEADLINE-TILL-SHOW")
                                                        }}</span>
                                                    <span class="col">&nbsp; {{ GetDeadLine }}</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="contacts-block__item li-size" v-if="JobAdObj.LocationType != null">
                                            <div class="row">
                                                <div class="col">
                                                    <span style="font-weight:bold;">{{
                                                            trans("LOCATION-TYPE-SHOW")
                                                        }}</span>
                                                    <span class="col">&nbsp; {{ trans(GetLocationType) }}</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="contacts-block__item li-size" v-if="JobAdObj.Duration != null">
                                            <div class="row">
                                                <div class="col">
                                                    <span style="font-weight:bold;">{{
                                                            trans("DURATION-SHOW")
                                                        }}</span>
                                                    <span class="col">&nbsp; {{ GetDuration }}</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="contacts-block__item li-size"
                                            v-if="JobAdObj.QualificationId != null">
                                            <div class="row">
                                                <div class="col">
                                                    <span style="font-weight:bold;">{{
                                                            trans("JOB-AD-QUALIFICATION-SHOW")
                                                        }}</span>
                                                    <span class="col">&nbsp; {{ trans(GetQualificationId) }}</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">
            <div class="user-profile layout-spacing">
                <div class="panel panel-white">
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 info-container">
                                <h2>{{ trans('DESCRIPTION') }}</h2>
                            </div>
                            <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10">
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 ad-text">
                                <span
                                    v-for="(line,lineNumber) of GetAdText.split('\n')"
                                    v-bind:key="lineNumber">
                                                                  {{ line }}<br/>
                                                              </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing" v-if="IsMatch">
            <div class="user-profile layout-spacing">
                <div class="panel panel-white">
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 info-container">
                                <h2>{{ trans('CONTACT-PERSON') }}</h2>
                            </div>
                            <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10">
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 contact-header">
                                {{ trans('FIRSTNAME') }}
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 contact-header">
                                {{ trans('LASTNAME') }}
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 contact-header">
                                {{ trans('OFFICE-WEB-SITE') }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 contact-text">
                                {{ this.JobAdObj.FirstName }}
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 contact-text">
                                {{ this.JobAdObj.LastName }}
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 contact-text">
                                {{ GetOfficeWebsite }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 contact-header">
                                {{ trans('EMAIL') }}
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 contact-header">
                                {{ trans('PHONE') }}
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 contact-header">
                                {{ trans('MOBILE') }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 contact-text">
                                {{ this.JobAdObj.Email }}
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 contact-text">
                                {{ this.JobAdObj.Phone }}
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 contact-text">
                                {{ this.JobAdObj.Mobile }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>

<script>

import '@/scss/jobineo/sass/scrollspyNav.scss';
import '@/scss/jobineo/sass/users/user-profile.scss';
import '@/scss/jobineo/sass/components/tabs-accordian/custom-tabs.scss';
import store from "@/store";
import lodash from "lodash";
import moment from "moment";
import {cutDateString, getDateStringMonthYear} from "@/utils/convertDateTime";
import {JOB_TYPES} from "@/data/jobTypes";
import {MatchingController} from "@/controllers/matching.controller";
import {OfficeController} from "@/controllers/office.controller";
import WaitingButton from "@/components/WaitingButton.vue";
import router from "@/router";
import {JobAdController} from "@/controllers/jobad.controller";

export default {
    name: "JobAdProfileView.vue",
    components: {},
    data() {
        return {
            controller: new JobAdController(this),
            officeController: new OfficeController(this),
            matchingController: new MatchingController(this),
            selectedImage: null,
            selectedImageOffice: null,
            ProfileObj: null,
            Video: null,
            FkCompany: null,
            FkOffice: null,
            FkJobAd: null,
        }
    },
    computed: {
        GetJobAdName() {
            return this.JobAdObj != null ? this.JobAdObj.AdName : ''
        },
        GetFullTimeText() {
            if (this.JobAdObj.FullTime === true)
                return "FULL-TIME"
            else if (this.JobAdObj.FullTime === false)
                return "NOT-FULL-TIME"
            else
                return "NO-VALUE"
        },
        GetCreatedDate() {
            if (this.JobAdObj.StartDate != null) {
                return "CREATED-DATE|" + this.GetDate(this.JobAdObj.CreatedDate)
            }

            return "";
        },
        GetOfficeWebSite() {
            return this.JobAdObj.Office != null ? this.JobAdObj.Office.OfficeWebsite : ''
        },
        GetJobType() {
            let text = "";

            if (this.JobAdObj.AdType == JOB_TYPES.MINI_JOB) {
                text = 'MINI-JOB'
            } else if (this.JobAdObj.AdType == JOB_TYPES.VOCATIONAL_TRAINING) {
                text = 'VOCATIONAL-TRAINING'
            } else if (this.JobAdObj.AdType == JOB_TYPES.INTERN_SHIP) {
                text = 'INTERN-SHIP'
            } else if (this.JobAdObj.AdType == JOB_TYPES.CAREER_ENTRY) {
                text = 'CAREER-ENTRY'
            } else if (this.JobAdObj.AdType == JOB_TYPES.DUAL_STUDY) {
                text = 'DUAL-STUDY'
            }
            return text;
        },
        GetJobField() {
            if (this.JobAdObj.AdType == JOB_TYPES.DUAL_STUDY) {
                let items = lodash.filter(this.$store.state.Lookups.StudyFieldsLookup, x => x.DataKey == this.JobAdObj.JobFieldId);
                if (items != null && items.length > 0)
                    return items[0].DataValue
                else return ""
            } else {
                let items = lodash.filter(this.$store.state.Lookups.JobFieldsLookup, x => x.DataKey == this.JobAdObj.JobFieldId);
                if (items != null && items.length > 0)
                    return items[0].DataValue
                else return ""
            }
        },
        GetStudyJobText() {
            return this.JobAdObj.AdType == JOB_TYPES.DUAL_STUDY ? 'STUDY-FIELD-SHOW' : 'JOB-FIELD-SHOW'
        },
        GetJobId() {
            if (this.JobAdObj.JobId != null) {
                let items = lodash.filter(this.$store.state.Lookups.JobsLookup, x => x.DataKey == this.JobAdObj.JobId);
                if (items != null && items.length > 0)
                    return items[0].DataValue
                else return ""
            } else
                return ""
        },
        GetSalary() {
            if (this.JobAdObj.SalaryType != null) {
                let items = lodash.filter(this.SalaryTypeList, x => x.DataKey == this.JobAdObj.SalaryType);
                if (items != null && items.length > 0)
                    return this.JobAdObj.Salary + "€|" + items[0].DataValue;
                else
                    return ""
            } else {
                return ""
            }

        },
        SalaryTypeList() {
            return store.state.Lookups.SalaryTypesLookup != null && Array.isArray(store.state.Lookups.SalaryTypesLookup) ? store.state.Lookups.SalaryTypesLookup : [];
        },
        QualificationList() {
            return store.state.Lookups.GraduationsLookup != null && Array.isArray(store.state.Lookups.GraduationsLookup) ? store.state.Lookups.GraduationsLookup : [];
        },
        GetFreePositions() {
            return this.JobAdObj.FreePositions
        },
        GetAdText() {
            return this.JobAdObj.AdText != undefined && this.JobAdObj.AdText != null && this.JobAdObj.AdText != "" ? this.JobAdObj.AdText : "";
        },
        GetDeadLine() {
            if (this.JobAdObj.DeadLine != null) {
                return this.GetDate(this.JobAdObj.DeadLine)
            } else {
                return this.trans("NO-DEADLINE")
            }
        },
        GetLocationType() {
            let items = lodash.filter(this.LocationTypeList, x => x => x.DataKey == this.JobAdObj.LocationType);
            if (items != null && items.length > 0)
                return items[0].DataValue;

            return false;
        },
        GetDuration() {
            return this.JobAdObj.Duration;
        },
        GetQualificationId() {
            let items = lodash.filter(this.QualificationList, x => x => x.DataKey == this.JobAdObj.QualificationId);
            if (items != null && items.length > 0)
                return items[0].DataValue;

            return false;
        },
        JobAdObj() {
            return this.ProfileObj != null ? this.ProfileObj : {}
        },
        JobFieldIdList() {
            return this.$store.state.Lookups.JobFieldsLookup != null && Array.isArray(this.$store.state.Lookups.JobFieldsLookup) ? this.$store.state.Lookups.JobFieldsLookup : [];
        },
        LocationTypeList() {
            return this.$store.state.Lookups.LocationTypesLookup != null && Array.isArray(this.$store.state.Lookups.LocationTypesLookup) ? this.$store.state.Lookups.LocationTypesLookup : [];
        },
        JobIdList() {
            return this.$store.state.Lookups.JobsLookup != null && Array.isArray(this.$store.state.Lookups.JobsLookup) ? this.$store.state.Lookups.JobsLookup : [];
        },
        StudyIdList() {
            return this.$store.state.Lookups.StudyFieldsLookup != null && Array.isArray(this.$store.state.Lookups.StudyFieldsLookup) ? this.$store.state.Lookups.StudyFieldsLookup : [];
        },
    },
    methods: {
        GetDate(value) {
            if (value === null)
                return '';

            return cutDateString(value)
        },
        previewVideo() {

            let video = document.getElementById('video-preview');
            if (video != null) {
                let reader = new FileReader();
                reader.readAsDataURL(this.Video);
                reader.addEventListener('load', function () {
                    video.src = reader.result;
                });
            }
        },
        GetJobNamesJobAd(item) {
            let text = "";

            let items = lodash.filter(this.$store.state.Lookups.JobFieldsLookup, x => x.DataKey == item.JobFieldId);
            if (items != null && items.length > 0)
                text = text + items[0].DataValue + " | ";

            items = lodash.filter(this.$store.state.Lookups.JobsLookup, x => x.DataKey == item.JobId);
            if (items != null && items.length > 0)
                text = text + items[0].DataValue + " | ";

            if (text.length > 0 && text.indexOf('|') > 0) {
                text = text.slice(0, text.length - 3);
            }

            return text;

        },
        init() {
            this.controller.readPublicView(this.FkJobAd).then(response => {
                this.ProfileObj = response.JobAdProfile;

                if (this.ProfileObj != null) {

                    this.controller.GetProfilePictureJobAdPublic(this.FkCompany, this.FkJobAd).then(result => {

                        if (result.data != null && result.data.size > 0)
                            this.selectedImage = URL.createObjectURL(new Blob([result.data]));

                        this.officeController.GetProfilePictureOfficePublic(this.FkCompany, this.FkOffice).then(picRes => {
                            if (picRes.data != null && picRes.data.size > 0)
                                this.selectedImageOffice = URL.createObjectURL(new Blob([picRes.data]));

                            this.controller.GetVideoFileJobAd(this.FkCompany, this.FkJobAd).then(result => {
                                if (result != null && result.size > 0) {
                                    this.Video = result;
                                    this.previewVideo();
                                }
                            })
                        })
                    })
                }
            })
        }
    },
    activated() {
        this.FkCompany = store.state.FkCompany;
        this.FkOffice = this.$route.params.FkOffice;
        this.FkJobAd = this.$route.params.FkJobAd;
        if (this.FkCompany == null || this.FkOffice == null || this.FkJobAd == null) {
            return;
        }
        this.init();
    }
}
</script>

<style scoped lang="scss">

.td-bio {
    color: #000000;
}

.td-width {
    min-width: 200px;
}

.dark {
    .td-bio {
        color: #ffffff;
    }

}

.purple {
    .td-bio {
        color: #ffffff;
    }

}

.borderline {
    border: 1px solid #9acccb;
    padding: 5px;
    border-radius: 26px;
    color: #9acccb !important;
}

.header-cv {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 800;
    margin-top: 30px;
}


.videoPlayer {
    max-height: 200px;
    max-width: 320px;
}

.headerIcons {
    display: flex;
}

.iconMargin {
    margin-left: 5px;
}

.popOverText {
    color: white;
    font-size: 14px;
    font-weight: 500;
}

.info-container {
    border: 1px solid #9acccb;
    border-radius: 20px;
    margin: 5px auto;
    text-align: center;
}

.ul-black {
    max-width: 100% !important;
}

.li-size {
    font-size: 16px !important;
}

.ad-text {
    font-size: 16px !important;
}

.contact-header {
    font-size: 16px;
    font-weight: 600;
}

.contact-text {
    font-size: 16px;
}


.dark {
    --popper-theme-background-color: #333333;
    --popper-theme-background-color-hover: #333333;
    --popper-theme-text-color: white;
    --popper-theme-border-width: 0px;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 15px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);

    .contact-header {
        color: #9acccb;
    }

    .contact-text {
        color: #000000;
    }

    .ad-text {
        color: #000000;
    }

    .text-color-white {
        color: white;
    }

    .info-container {
        background-color: #070707;
        color: #FFFFFF;

    }

    .panelblue {
        background-color: #9acccb !important;
        color: #000000;
    }

    .panel-white {
        background-color: #FFFFFF !important;
        color: #000000;
    }

    .ul-black {
        color: #000000 !important;
    }

    .header-black {
        margin-top: 10px;
        color: #000000 !important;
        font-weight: 700;
    }

}


</style>
