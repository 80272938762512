export const NOTIFICATION_TYPE = {
    JOBINEO_NOTIFICATION: 1,
    NEW_MESSAGE: 2,
    NEW_MATCH: 3,
    NEW_REQUEST: 4,
    MATCH_DECLINED: 5,
    APPOINTMENTS: 6,
    NEW_LIKE: 7,
    NEW_FAVOURITE: 8,
    NEW_PROFILE_WATCH: 9,
    MATCH_CANCELLED: 10
}


export const NOTIFICATION_TYPE_DROPDOWN = [
    {
        label: 'NOTIFICATION-TYPE-JOBINEO-NOTIFICATION',
        value: NOTIFICATION_TYPE.JOBINEO_NOTIFICATION
    },
    {
        label: 'NOTIFICATION-TYPE-NEW-MESSAGE',
        value: NOTIFICATION_TYPE.NEW_MESSAGE
    },
    {
        label: 'NOTIFICATION-TYPE-NEW-MATCH',
        value: NOTIFICATION_TYPE.NEW_MATCH
    },
    {
        label: 'NOTIFICATION-TYPE-NEW-REQUEST',
        value: NOTIFICATION_TYPE.NEW_REQUEST
    },
    {
        label: 'NOTIFICATION-TYPE-MATCH-DECLINED',
        value: NOTIFICATION_TYPE.MATCH_DECLINED
    },
    {
        label: 'NOTIFICATION-TYPE-APPOINTMENTS',
        value: NOTIFICATION_TYPE.APPOINTMENTS
    },
    {
        label: 'NOTIFICATION-TYPE-NEW-LIKE',
        value: NOTIFICATION_TYPE.NEW_LIKE
    },
    {
        label: 'NOTIFICATION-TYPE-NEW-FAVOURITE',
        value: NOTIFICATION_TYPE.NEW_FAVOURITE
    },
    {
        label: 'NOTIFICATION-TYPE-NEW-PROFILE-WATCH',
        value: NOTIFICATION_TYPE.NEW_PROFILE_WATCH
    },
    {
        label:'NOTIFICATION-TYPE-MATCH-CANCELLED',
        value: NOTIFICATION_TYPE.MATCH_CANCELLED
    }

]

