import {deepSet} from "./utils/dev/deepSet.js";
import Url from "url-parse";

const config = {
  baseUrl: null,
  allowedNonUserRoutes: ['/api',
      '/LandingPageStudent',
      '/LandingPageCompany',
      '/Register',
      '/RegisterWizard',
      '/RegisterStudent',
      '/VerifyUser',
      '/VerifyParentEmail',
      '/AGBReaccept',
      '/EnterNewPassword',
      '/PasswordForgotten',
      '/Pricing',
      '/Login',
      '/MFACheck',
      '/Imprint',
      '/AGB',
      '/DataProtection',
      '/RegisterSuccess'
  ],

  allowedCountries: ['DE', 'AT', 'CH'],
  prodUrl: null,
  local: false
}

const jobineoTestWebsrvPorts = [
    // DEV-Webserver, DEV-Demo-Webserver ports
    ['80', '8060'],
    // TEST-Webserver, TEST-Demo-Webserver ports
    ['8050', '8070']
  ],
  bwptestWebsrvPortsLength = jobineoTestWebsrvPorts.length;
export const initJobineo = () => {

  let a = window.document.createElement('a');
  a.href = "";

  const baseUrl = a.href,
    parse = Url(baseUrl),
    port = !parse.port || !parse.port.trim() ? '80' : parse.port,
    local = process.env.NODE_ENV === 'development';
  config.baseUrl = baseUrl
  //config.local = window.isDevelopment = local;

// If local development environment
  if (local) {
    // Assign normal dev server
    config.prodUrl = 'http://localhost:8080';

    // if domain equals apstest-websrv assigning ports based on the normal websrv port
    // if 80 then 8060 for demo and if 8050 then 8070 for demo
  } else if (parse.hostname === 'SRVDEVPORTAL01' || parse.hostname === 'SRVDEVPORTAL01') {
    let _url = parse.protocol + '//' + parse.hostname + ':';
    for (let t = 0; t < bwptestWebsrvPortsLength; t++) {
      if (jobineoTestWebsrvPorts[t].indexOf(port) !== -1) {
        config.prodUrl = _url + jobineoTestWebsrvPorts[t][0];
        break;
      }
    }
    // It seems we are on production or on sub-domain based site
    // Now demo differs in the domain structure:
    // demo.<DOMAIN> is demo server
    // <DOMAIN> is normal server
  } else {
    let splitName = parse.hostname.split('.'),
      len = splitName.length,
      prodName = len === 3 ? splitName[1] + '.' + splitName[2] : splitName.join('.');
    config.prodUrl = parse.protocol + '//' + prodName;
  }
}//EOF initBWP()


/**
 * get a config value if exists. if not exists use default value.
 * on getConfig() returns complete config
 * @param key
 * @param def
 * @returns {{} & {demoUrl: null, baseUrl: null, prodUrl: null, allowedNonUserRoutes: string[], allowedCountries: string[], isDemo: boolean, local: boolean}}
 */
export const getConfig = (key = null, def = null) => {
  return !key ? Object.assign({}, config) : config[key] != null ? config[key] : def;
}

/**
 * set a config value for the given key.
 * Does perform a deep set which means that sub objects will be merged
 * @param key
 * @param value
 * @returns {boolean|{}|(boolean|{})}
 */
export const setConfig = (key = null, value = null) => {

  return deepSet(config, key, value);

}


/**
 * A simple registry object where things can be registered globaly
 * @type {{}}
 */
export const Register = {};

/**
 * helper function for registering a vue component in the Register
 * @param comp
 */
export const registerComponent = (comp = null) => {

  if (comp && comp.$options._componentTag != null)
    Register[comp.$options._componentTag] = comp;
}
