<template>

    <slot>
        <input
            :id="getId"
            class="form-check-input"
            :class="[attachedClasses]"
            type="checkbox"
            v-bind="$attrs"
            @change="check($event)"
            :checked="checked"
            :disabled="disabled"
        />


    </slot>
    <slot><span v-if="inline">&nbsp;</span></slot>
    <slot name="label">
        <label :for="getId" v-if="label" class=" custom-control-label "> {{ label }} <b v-if="required"
                                                                                        class="required-asterix">
            *</b>
            <HelpButton v-if="hasHelpText" :content="getHelpText"></HelpButton>
        </label>
    </slot>


</template>
<script>


import HelpButton from '../components/HelpButton.vue';


export default {
    name: 'base-checkbox',
    emits: ['input'],
    components: {HelpButton},
    props: {
        checked: Boolean,
        disabled: Boolean,
        inline: Boolean,
        hasError: Boolean,
        item: Object,
        id: String,
        label: String,
        helpText: {
            type: String
        },
        required: Boolean,
        attachedClass: String,
        popOverText: String,
        centerToLine: Boolean,
        leftZero: Boolean

    },
    data() {
        return {
            cbId: '',
            touched: false,
            focused: false
        };
    },
    computed: {
        appliedRules() {
            let firstRule = true,
                rules = "";

            if (this.$props.required === true) {
                if (!firstRule) {
                    rules = rules + "|";
                }
                rules = rules + "required";
                firstRule = false;
            }

            return rules;
        },
        hasHelpText() {
            return this.$props.helpText != undefined && this.$props.helpText != ''
        },
        getHelpText() {
            return this.trans(this.$props.helpText)
        },
        popOverTextValue() {
            if (this.$props.popOverText != null)
                return this.trans(this.$props.popOverText);
            else return ''
        },
        attachedClasses() {
            return this.$props.attachedClass;
        },
        getId() {
            return this.$props.id;
        },
        getLabel() {
            return this.$props.label;
        },
        listeners() {
            return {
                input: this.onInput,
                blur: this.onBlur,
                focus: this.onFocus
            };
        },
        marginClass() {
            if (this.centerToLine)
                return `margin-t-35`;
            else
                return ''
        },
        LeftZero() {
            if (this.leftZero)
                return `left-zero`;
            else
                return ''
        },
        inlineClass() {
            if (this.inline) {
                return `form-check-inline`;
            } else {
                return '';
            }
        }
    },
    created() {

    },

    methods: {
        setFocus() {
            this.$nextTick(() => this.$refs.inputField.focus())
        },
        reset() {
            this.touched = false;
            this.focused = false;

            this.$nextTick(() => {
                this.$refs.validator.reset();

            });

        },
        onInput(evt) {
            if (!this.touched) {
                this.touched = true;
            }
            this.$emit('input', evt.target.value);
        },
        check(evt) {
            this.$emit('input', evt);
        },
        onFocus(evt) {
            this.focused = true;
            this.$emit('focus', evt)
        },
        onBlur(evt) {
            this.focused = false;
            this.$emit('blur', evt)
        }
    }
};
</script>
<style scoped lang="scss">


.margin-t-35 {
    margin-top: 35px;
}

.left-zero {
    padding-left: 0px !important
}

</style>

