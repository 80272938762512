import {restUri} from "@/data/restPaths";
import {restBody} from "@/utils/restBody";
import axios from "axios";
import {REGISTER_TYPES} from "@/data/RegisterTypes";


export function getNotifications(store, data) {

    if (store.state.User == null || store.state.User.PkUser == undefined)
        return null;

    axios.post(restUri.GET_NOTIFICATIONS, restBody({
        FkUser: store.state.User.PkUser,
        DaysBack: 30,
        WithNotified: false
    })).then(result => {
        let _data = result.data;
        if (!_data.IsError) {
            this.commit('setNotifications', _data);
        }
        return _data;
    }).catch(result => {
        return result.data
    })

}


export function getMessages(store, data) {
    if (store.state.User == null || store.state.User.PkUser == undefined)
        return null;

    axios.post(restUri.GET_NEW_MESSAGES, restBody({
        FkStudentUser: store.state.User.UserType === REGISTER_TYPES.STUDENT ? store.state.PkStudentProfile : null,
        FkCompanyUser: store.state.User.UserType !== REGISTER_TYPES.STUDENT ? store.state.User.PkUser : null,
        IsDashBoard: true
    })).then(result => {
        let _data = result.data;
        if (!_data.IsError) {
            this.commit('setMessages', _data);
        }
        return _data;
    }).catch(result => {
        return result.data
    })

}

export function getSystemNotifications(store, data) {
    return axios.get(restUri.GET_SYSTEM_NOTIFICATIONS).then(result => {
        if (result != null)
            return result.data;
        return null;
    })

}

export function getSystemSettings(store, data) {
    return axios.get(restUri.GET_SYS_SETTINGS).then(result => {
        let _data = result.data;
        this.commit('setSysSettings', _data.SysSettings);
        return _data;
    })

}


export function getNotificationsAll(store, data) {
    axios.post(restUri.GET_NOTIFICATIONS_DASHBOARD, restBody({
        FkUser: store.state.User.PkUser
    })).then(result => {
        let _data = result.data;
        if (!_data.IsError) {
            this.commit('setNotificationsAll', _data);
        }
        return _data;
    }).catch(result => {
        return result.data
    })

}


export function setNotified(store, data) {
    return axios.post(restUri.SET_NOTIFIED, restBody({
        Notification: data,
    })).then(result => {
        let _data = result.data;
        if (!_data.IsError && !data.Notified) {
            this.commit('removeNotification', data);
        } else {
            this.commit('setNotifications', data);
        }
        return _data;
    })

}
