import Controller from "../controller.js";
import {restUri} from "@/data/restPaths";
import {mutate} from "@/utils/mutate";
import store from "@/store";
import axios from "axios";
import {restBody} from "@/utils/restBody";


export class MessagesController extends Controller {

    constructor(root) {
        super({
            root,
            path: {
                CUD: restUri.MESSAGES_CUD,
                GET: restUri.GET_MESSAGES
            }
        })
    }


    read() {
        let body = {}
        if (store.state.FkCompany != null) {
            body = {
                FkStudentUser: null,
                FkCompanyUser: this.store.state.User != null ? this.store.state.User.PkUser : null
            }
        } else {
            body = {
                FkCompanyUser: null,
                FkStudentUser: this.store.state.PkStudentProfile != null ? this.store.state.PkStudentProfile : null
            }
        }


        return super.read({
            log: false,
            body: body
        }).then(data => {
            if (!data.IsError) {
                return data;
            } else return data
        }).catch(result => {
            return result;
        });


    }

    readSubMessages(fkMessageGroup) {
        let body = {
            FkMessageGroup: fkMessageGroup
        }


        return axios.post(restUri.GET_SUB_MESSAGES, restBody(body)
        ).then(result => {
            return result.data;
        }).catch(result => {
            return result.data;
        });

    }

    create(Message = {}) {

        if (typeof Message.PkMessage !== 'undefined')
            delete Message.PkMessage;


        return super.create({
            log: false,
            body: mutate({Message}),
        }).then(data => {
            if (!data.IsError) {
                return data;

            } else return data
        }).catch(result => {
            return result.data;
        });
    }

}
