<template>
    <!-- Modal -->
    <div ref="modal"
         class="modal fade template-modal"
         tabindex="-1"
         role="dialog"
         id="jobAdModal"
         aria-hidden="true">
        <div class="modal-dialog modal-xxl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title text-success">
                        <font-awesome-icon icon="fas fa-plus-circle" class="text-success"/> &nbsp;
                        <font-awesome-icon icon="fas fa-clipboard" class="text-success"/>
                        <span class="modal-header-text">
                        {{ trans('ADD-JOB-AD') }}
                            </span>
                    </h5>
                    <button type="button" data-dismiss="modal" ref="closeBtn" id="closeBtn" data-bs-dismiss="modal"
                            aria-label="Close"
                            class="btn-close">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="tab-content" id="simpletabContent">
                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <NewJobAdForm ref="newJobAdForm"></NewJobAdForm>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="footer-inner">

                        <base-button
                            type="yellow" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close"
                            native-type="button"
                            class="btn btn-yellow input-rounded float-left">
                            <font-awesome-icon icon="fas fa-times"/>
                            {{ $t('CANCEL') }}
                        </base-button>

                        <WaitingButton
                            size="sm"
                            icon="fas fa-check"
                            class="float-right btn btn-mint input-rounded"
                            :waiting="isSaving"
                            @click.prevent="save">
                            {{ $t('SAVE') }}
                        </WaitingButton>


                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

import $ from 'jquery';
import WaitingButton from "../../../components/WaitingButton.vue";

import NewJobAdForm from "./NewJobAd.vue";
import store from "@/store";

export default {
    name: "JobAdCreateModal",
    components: {NewJobAdForm, WaitingButton},
    props: ['jobAdController'],
    data() {
        return {
            isSaving: false,
            error: false,
            errorMessage: null,
            newTabIndex: 0,
            JobAd: null,
        }
    },
    computed: {
        controller() {
            return this.$props.jobAdController;
        },
    },
    methods: {
        twiggle() {
        },
        open() {
            this.JobAd = {
                PkJobAd: null,
                FkCompany: store.state.FkCompany,
                FkOffice: store.state.FkOffice,
                AdName: null,
                CreatedDate: null,
                AdType: null,
                Duration: null,
                QualificationId: null,
                FreePositions: null,
                Salary: null,
                StartDate: null,
                EndDate: null,
                IsActive: true,
                LocationType: null,
                EntryDate: null,
                SalaryType: null,
                AdText: null,
                DeadLine: null,
                FullTime: false,
                Salutation: null,
                NameAddition: null,
                FirstName: null,
                LastName: null,
                Phone: null,
                Email: null,
                Mobile: null,
                JobId: null,
                JobFieldId: null,
            };

            const createForm = this.$refs.newJobAdForm;
            createForm.setData(this.JobAd);

        },
        save() {
            let form = this.$refs.newJobAdForm;

            form.validate().then(result => {
                if (result === true) {
                    let jobAd = form.getData();
                    this.controller.create(jobAd)
                        .then(data => {
                                if (!data.IsError) {
                                    this.$notify(
                                        {
                                            title: this.trans('SUCCESS'),
                                            text: this.trans('DATA-SAVED'),
                                            type: 'success'
                                        });
                                    this.$refs.closeBtn.click();
                                    this.$emit('saved')
                                } else {
                                    form.setError(data.ErrorMessage);
                                    this.$notify(
                                        {
                                            title: this.trans('ERROR'),
                                            text: this.trans(data.ErrorMessage),
                                            type: 'error',
                                        });
                                }

                            }
                        )
                }
            })

        },
    },

}
</script>

<style scoped>
.template-modal {
    z-index: 9999;
}


.footer-inner {
    display: block;
    width: 100%;
}

.parent-selection-header > .badge {
    font-size: 14px;
}


.modal-header-text {
    vertical-align: 0.8em;
    margin-left: 10px;
}
</style>
