<template>
    <div>
        <div class="container">
            <!-- Outer Row -->
            <div class="row justify-content-center">
                <div class="col-xl-10 col-lg-12 col-md-9">
                    <div class="card o-hidden shadow-lg my-5">
                        <div class="card-body p-0">
                            <!-- Nested Row within Card Body -->
                            <div class="row">
                                <div class="col-lg-6 d-none d-lg-block ">
                                    <div class="compLogo">
                                        <img src="../../assets/images/logo-jobineo.png"/>
                                    </div>
                                    <div class="spinner-container">
                                        <div class="spinner-wrap">
                                            <font-awesome-icon icon="fas fa-check"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="p-5" style="padding-top: 90px!important;">
                                        <div class="text-center">
                                            <br/><br/><br/><br/>
                                            <h1 class="h4 text-gray-900 mb-4">{{ $t('REGISTER-SUCCESS-HEADER') }}</h1>
                                            {{ $t('REGISTER-SUCCESS-DESC') }}
                                            <br/><br/>
                                            <br/>
                                            <base-button
                                                native-type="button"
                                                class="btn btn-mint btn-block btn-download"
                                                @click="redirect"
                                            >
                                                {{ $t('TO-LOGIN') }}
                                            </base-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * View: RegisterSuccess
 * Path: /RegisterSuccess
 * A view to show successfull registration
 * This is a public view
 *
 */
import {silentEvent} from "@/utils/silentEvent";
import BaseButton from '../../components/BaseButton'

export default {
    name: "RegisterSuccess",
    components: {BaseButton},
    data() {
        return {
            waiting: false,
            confirmed: false,
            error: false,
            errorMessage: ''
        }
    },
    computed: {},
    methods: {
        reset(e = false) {
            if (e)
                silentEvent(e);
            this.confirmed = false;
            this.error = false;
            this.errorMessage = '';
        },

        cancel() {
            this.$router.push('/Login');
        },
        redirect() {
            this.$router.push('/Login');
        },
    }

}
</script>

<style scoped>

.comp-wrap {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 11;
    top: 0;
    left: 0;
}

.comp-wrap > .container {
    display: flex;
    height: 100vh;
    width: 100vw;
}

.comp-wrap > .container > .row {
    align-content: center;
    flex-direction: row;
    flex-grow: 1;
}

.compLogo {
    position: relative;
    display: block;
    padding: 0;
    margin: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.compLogo > img {
    width: 90%;
    margin-left: 50px;
    margin-top: 150px;

}

.spinner-container {
    position: absolute;
    display: block;
    top: -150px;
    left: 460px;
    width: 100%;
    height: 100%;
}

.spinner-wrap {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: nowrap;
    padding-left: 1.7rem;
    padding-bottom: 1.5rem;

}

.spinner-wrap > i {
    font-size: 10em;
    color: #5cd443;
}

.h-spacer button {
    color: yellow;
    padding: 0.75rem 1.0rem;
}

.help-text {
    font-size: 12px;
    text-align: left;
    margin-bottom: 10px;
}

.btn-download {
    border-radius: 10rem;
}

</style>
