<template>

    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme" v-if="loggedIn">
        <nav ref="menu" id="sidebar">
            <div class="shadow-bottom"></div>

            <perfect-scrollbar class="list-unstyled menu-categories" tag="ul"
                               :options="{ wheelSpeed: 0.5, swipeEasing: !0, minScrollbarLength: 40, maxScrollbarLength: 300, suppressScrollX: true }">
                <side-menu-sub-item v-for="(subItem, index) in routes"
                                    :key="index"
                                    :level="0"
                                    @toggleMobileMenu="toggleMobileMenu"
                                    :item="subItem"></side-menu-sub-item>
              
            </perfect-scrollbar>
        </nav>
    </div>
    <!--  END SIDEBAR  -->
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {useStore} from 'vuex';

const store = useStore();

const menu_collapse = ref('dashboard');

onMounted(() => {
    const selector = document.querySelector('#sidebar a[href="' + window.location.pathname + '"]');
    if (selector) {
        const ul = selector.closest('ul.collapse');
        if (ul) {
            let ele = ul.closest('li.menu').querySelectorAll('.dropdown-toggle');
            if (ele) {
                ele = ele[0];
                setTimeout(() => {
                    ele.click();
                });
            }
        } else {
            selector.click();
        }
    }
});

const toggleMobileMenu = () => {
    if (window.innerWidth < 991) {
        store.commit('toggleSideBar', !store.state.is_show_sidebar);
    }
};
</script>


<script>
import store from "@/store";
import {mainMenuRoutes} from "@/routes/mainMenuRoutes.js";
import SideMenuSubItem from "@/components/menus/SideMenuSubItem.vue";

export default {
    name: 'new-sidebar',
    components: {
        SideMenuSubItem
    },
    data() {
        return {
            routes: mainMenuRoutes
        }
    },
    computed: {
        loggedIn() {
            return store.state.loggedIn;
        },
    }
}

</script>
