<template>
    <div class="dropdown nav-item user-profile-dropdown btn-group">
        <a ref="menu" href="javascript:;" id="ddluser" data-bs-toggle="dropdown" aria-expanded="false"
           class="btn dropdown-toggle btn-icon-only user nav-link">
            <font-awesome-icon icon="fa-user" style="color:#ebedf2;"></font-awesome-icon>
        </a>
        <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="ddluser">
            <template v-for="(route,idx) in routesLoggedIn" :key="idx">
                <li role="presentation"
                    v-if="checkLoggedIn(route.checkLoginState)&&CheckVisible(route.CheckIfUserType)&&route.title !== 'SIGN-OUT'&&route.ignore !== true">
                    <router-link :to="route.path" class="dropdown-item">
                        <font-awesome-icon :icon="route.icon"></font-awesome-icon>
                        {{ trans(route.title) }}
                    </router-link>
                </li>
                <li role="presentation"
                    v-if="checkLoggedIn(route.checkLoginState)&&CheckVisible(route.CheckIfUserType)&&route.title === 'SIGN-OUT'&&route.ignore !== true">
                    <a class="dropdown-item logoff" @click="logOff">
                        <font-awesome-icon :icon="route.icon"></font-awesome-icon>
                        {{ trans(route.title) }}
                    </a>
                </li>
                <li v-if="route.setDivider===true" class="divider">&nbsp;</li>
            </template>
        </ul>

    </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import {useCookies} from "vue3-cookies";
import {flattenAccountLoggedInPathTree} from "@/routes/account.routes";
import {LOGIN_STATE} from "@/data/LoginStates";
import {CHECK_REGISTER_TYPES, REGISTER_TYPES} from "@/data/RegisterTypes";

const {cookies} = useCookies();
export default {
    name: 'user-menu',
    data() {
        return {
            routesLoggedIn: flattenAccountLoggedInPathTree
        }
    },
    computed: {

        loggedIn() {
            return store.state.loggedIn;
        }
    },
    methods: {
        CheckVisible(checkIfUserType) {

            if (checkIfUserType === undefined)
                return true;

            if (checkIfUserType === CHECK_REGISTER_TYPES.ALL)
                return true;

            if (checkIfUserType === CHECK_REGISTER_TYPES.COMPANY_OR_SCHOOL && store.state.RegisterType !== REGISTER_TYPES.STUDENT)
                return true;

            if (checkIfUserType === CHECK_REGISTER_TYPES.COMPANY_OR_STUDENT && store.state.RegisterType !== REGISTER_TYPES.SCHOOL)
                return true;

            if (checkIfUserType === CHECK_REGISTER_TYPES.COMPANY && store.state.RegisterType === REGISTER_TYPES.COMPANY)
                return true;

            if (checkIfUserType === CHECK_REGISTER_TYPES.SCHOOL && store.state.RegisterType === REGISTER_TYPES.SCHOOL)
                return true;

            if (checkIfUserType === CHECK_REGISTER_TYPES.STUDENT && store.state.RegisterType === REGISTER_TYPES.STUDENT)
                return true;


            return false;
        },
        checkLoggedIn(value) {
            if (value === LOGIN_STATE.LOGGED_OFF && !this.loggedIn)
                return true;
            else if (value === LOGIN_STATE.LOGGED_IN && this.loggedIn)
                return true;
            else return value === LOGIN_STATE.IGNORE;
        },
        logOff() {

            this.$store.commit('logOff');


            store.state.loggedIn = false;

            localStorage.removeItem('loggedIn');
            localStorage.removeItem('loggedInSessionId');
            localStorage.removeItem('rememberId');

            cookies.remove('rememberId');
            cookies.remove('loggedIn');
            cookies.remove('loggedInSessionId');
            router.push('/LandingPageStudent');


        }
    }
}
</script>

<style scoped lang="scss">
.logoff {
    cursor: pointer;
}
</style>
