<template>
    <div class="container-jobineo">
        <div class="section-heading">
            <span class="no-decoration breadcrumb-decorate-blue">
                <font-awesome-icon icon="fas fa-cog"/>
                {{ trans('MAIN-DATA') }}
            </span>
            <span class="no-decoration breadcrumb-decorate-yellow">
                <font-awesome-icon icon="fas fa-angle-right"/> &nbsp;
                <font-awesome-icon icon="fas fa-user"/>
                {{ trans('USERS') }}
            </span>
        </div>
        <div class="row">
            <div class="col-lg-3">
                <div class="container-jobineo">
                    <div class="row layout-top-spacing">
                        <div id="basic" class="col-lg-12 col-sm-12 col-12 layout-spacing">
                            <div class="statbox panel box box-shadow">
                                <div class="custom-table">
                                    <FilterHeader
                                        :add-label="$t('ADD-USER')"
                                        :remove-label="trans('DELETE-USER')"
                                        :has-add="true"
                                        :has-remove="true"
                                        @remove="deleteItem"
                                        :remove-filters="true"
                                        @create="showModal"
                                        :has-selection="selectedPk"
                                        :has-reload="true"
                                        @reload="load"
                                    ></FilterHeader>
                                    <LimitHeader :header-type="2"></LimitHeader>
                                    <b-table
                                        responsive
                                        small hover bordered
                                        :busy="loading"
                                        select-mode="single"
                                        sort-by="Email"
                                        :items="ItemsList"
                                        :fields="fields"
                                        primary-key="PkUser"
                                        @row-clicked="rowClicked"
                                        tbody-class="clickable"
                                    >
                                        <template v-slot:cell(Email)="data">
                                            <div class="license-column">
                                                {{ data.item.Email }} - {{ data.item.FirstName }}
                                                {{ data.item.LastName }}
                                            </div>
                                        </template>
                                    </b-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <div v-show="!selectedPk" class="disabled-overlay"></div>
                <div v-show="!selectedPk" class="disabled-overlay-advice">
                    <div class="center-box">
                      <span>
                      <font-awesome-icon icon="fas fa-arrow-left"/><br/>
                      {{ trans('SELECT-USER-FOR-EDITING') }}
                      </span>
                    </div>
                </div>
                <div class="container-jobineo" v-show="showEdit">
                    <div class="row layout-top-spacing">
                        <div id="basic" class="col-lg-12 col-sm-12 col-12 layout-spacing">
                            <div class="statbox panel box box-shadow">
                                <NewUserForm ref="editForm"
                                             @update="save"></NewUserForm>
                                <div class="row">
                                    <div class="form-group col-lg-12">
                                        <base-button
                                            native-type="button"
                                            class="btn btn-red float-left float-left-margin"
                                            @click="cancel"
                                        >
                                            <font-awesome-icon icon="fas fa-times"/>
                                            {{ $t('CANCEL') }}
                                        </base-button>


                                        <base-button
                                            native-type="button"
                                            class="btn btn-mint float-right float-right-margin"
                                            @click="$refs.editForm.$refs.silentSubmit.click()">
                                            <font-awesome-icon v-if="processing"
                                                               icon="fas fa-spin fa-circle-notch"/>
                                            <font-awesome-icon v-if="!processing"
                                                               icon="fas fa-check"/>
                                            {{ $t('SAVE') }}
                                        </base-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <UserCreateModal ref="createModal" :user-controller="controller" @saved="load()"></UserCreateModal>

    </div>
</template>

<script>
/**
 * View: Users
 * Path: /Config/UserAdmin/Users
 * A sub view for the Config > UserGroups area.
 * For managing users.
 *
 */
import UsersController from '@/controllers/users.controller.js'
import UserCreateModal from "./UserCreateModal"

import FilterHeader from "@/components/dropdowns/FilterHeader.vue";
import NewUserForm from "@/views/administration/users/NewUserForm.vue";
import LimitHeader from "@/components/LimitHeader.vue";
import store from "@/store";

export default {
    name: "Users-View",
    components: {
        LimitHeader,
        NewUserForm,
        FilterHeader,
        UserCreateModal
    },
    data() {
        return {
            controller: new UsersController(this),
            columns: ['Email'],
            ItemsList: [],
            selectedItem: null,
            loading: true,
            showEdit: false,
            isUpdating: false,
            isDeleting: false,
            loadingList: false,
            processing: false,
            selectedPk: null,

        }
    },
    computed: {
        fields() {
            return [
                {key: "Email", sortable: true, label: this.trans("USER")},
            ]
        }
    },
    methods: {
        cancel() {
            this.model = {};
            this.$refs.editForm.setData(this.model);
            this.showEdit = false;
            this.selectedPk = null;
            this.selectedItem = null;
        },
        save() {
            let data = this.$refs.editForm.getData();
            this.controller.update(data).then(response => {

                if (response.IsError) {
                    this.error = true;
                    this.errorMessage = response.ErrorMessage;
                    this.$notify(
                        {
                            title: this.trans('ERROR'),
                            text: this.trans(response.ErrorMessage),
                            type: 'error',
                        });
                } else {
                    this.selectedItem = {
                        PkUser: null,
                        FirstName: null,
                        LastName: null,
                        Salutation: null,
                        NameAddition: null,
                        Email: '',
                        Password: null,
                        PasswordRepeat: null,
                        User2Comp: {
                            PkUserToCompany: null,
                            FkCompany: null,
                            FkUser: null,
                            FkOffice: null
                        }
                    };
                    this.ItemsList = response.UsersList;
                    this.$refs.editForm.setData(this.selectedItem)
                    this.showEdit = false;
                    this.selectedPk = null;
                    this.$notify(
                        {
                            title: this.trans('SUCCESS'),
                            text: this.trans('DATA-SAVED'),
                            type: 'success'
                        });
                }

            }).finally(() => {
                this.processing = false;
            })
        },
        deleteItem() {
            if (this.selectedItem.PkUser == null)
                return;
            new window.Swal({
                title: this.trans('DELETE-USER'),
                text: this.trans('REALLY-DELETE-USER'),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.trans('NO'),
                confirmButtonText: this.trans('DELETE'),
                iconColor: '#ce6f74',
                padding: '2em'
            }).then(saResult => {
                if (saResult.isConfirmed) {
                    this.controller.delete(this.selectedItem).then(response => {
                        if (response.IsError) {
                            this.error = true;
                            this.errorMessage = response.ErrorMessage;
                            this.$notify(
                                {
                                    title: this.trans('ERROR'),
                                    text: this.trans(response.ErrorMessage),
                                    type: 'error',
                                });
                        } else {
                            this.load();
                            this.$notify(
                                {
                                    title: this.trans('SUCCESS'),
                                    text: this.trans('DATA-DELETED'),
                                    type: 'success'
                                });
                        }
                    })
                }
            })
        },
        showModal() {

            this.$refs.createModal.open();
            let modal = new window.bootstrap.Modal(document.getElementById('userModal'));
            modal.show();
        },
        load() {
            this.loading = true;
            this.selectedItem = {
                PkUser: null,
                FirstName: null,
                LastName: null,
                Salutation: null,
                NameAddition: null,
                Email: '',
                Password: null,
                PasswordRepeat: null,
                User2Comp: {
                    PkUserToCompany: null,
                    FkCompany: null,
                    FkUser: null,
                    FkOffice: null
                }
            };
            this.$refs.editForm.setData(this.selectedItem);
            this.showEdit = false;
            this.selectedPk = null;
            this.selectedItem = null;
            this.controller.read().then(result => {
                this.ItemsList = result.UsersList;

                store.dispatch('GetLimitationsAndLicenses');

            }).finally(() => {
                this.loading = false;
            })
        },
        rowClicked(item) {
            this.selectedPk = null;
            this.selectedItem = null;
            this.showEdit = false;
            if (!item) return;
            this.selectedPk = item.PkUser;
            this.showEdit = true;
            this.selectedItem = item;
            this.$refs.editForm.setData(item);
        },
    },

    beforeMount() {
        //this.load();
    },
    activated() {
        this.load();
    },
}
</script>

<style scoped>
.custom-table {
    background: transparent;
}

</style>
