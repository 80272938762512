<template>
    <div>
        <!--  BEGIN NAVBAR  -->
        <div class="header-container fixed-top">
            <header class="header navbar navbar-expand-sm">
                <ul class="navbar-item theme-brand flex-row text-center">
                    <li class="nav-item theme-logo">
                        <router-link to="/LandingPageStudent">
                            <img src="@/assets/images/logo-jobineo.png" class="navbar-logo" alt="logo-jobineo"/>
                        </router-link>
                    </li>
                    <li class="nav-item theme-text">
                    </li>
                </ul>
                <div class="d-none horizontal-menu" v-if="loggedIn">
                    <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom"
                       @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-menu"
                        >
                            <line x1="3" y1="12" x2="21" y2="12"></line>
                            <line x1="3" y1="6" x2="21" y2="6"></line>
                            <line x1="3" y1="18" x2="21" y2="18"></line>
                        </svg>
                    </a>
                </div>

                <div class="navbar-item flex-row ms-md-auto">

                    <div v-if="loggedIn&&IsTerminated" class="warning-text">
                        <font-awesome-icon class="triangle" icon="fas fa-exclamation-triangle"/>
                        <div class="marquee">
                <span><b>{{ $t('USER-IS-TERMINATED-ON') }} {{
                        cutDateString(TerminationDate)
                    }}{{ $t('TERMINATE-ENDTEXT') }}</b></span>
                        </div>
                        <font-awesome-icon class="triangle" icon="fas fa-exclamation-triangle"/>
                    </div>

                    <button v-if="!loggedIn" class="btn-yellow input-rounding btn-goTo" @click="GoToPage()">{{
                            trans(GetButtonText)
                        }}
                    </button>

                    <theme-menu></theme-menu>

                    <div class="dropdown nav-item language-dropdown btn-group">
                        <a href="javascript:;" id="ddllang" data-bs-toggle="dropdown" aria-expanded="false"
                           class="btn dropdown-toggle btn-icon-only nav-link">
                            <img v-if="selectedLang" :src="require(`@/assets/images/flags/${selectedLang.code}.png`)"
                                 class="flag-width" alt="flag"/>
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="ddllang">
                            <perfect-scrollbar>
                                <li v-for="item in countryList" :key="item.code">
                                    <a href="javascript:;" class="dropdown-item d-flex align-items-center"
                                       :class="{ active: $i18n.locale === item.code }"
                                       @click.prevent="changeLanguage(item)">
                                        <img :src="require(`@/assets/images/flags/${item.code}.png`)" class="flag-width"
                                             alt=""/> <span>{{ trans(item.name) }}</span>
                                    </a>
                                </li>
                            </perfect-scrollbar>
                        </ul>
                    </div>

                    <message-menu v-if="loggedIn&&IsCompanyOrStudent"></message-menu>
                    <notification-menu v-if="loggedIn"></notification-menu>

                    <LogOffMenu v-if="!loggedIn"></LogOffMenu>
                    <UserMenu v-if="loggedIn"></UserMenu>
                    <LoginMenu v-if="!loggedIn"></LoginMenu>
                </div>
            </header>
        </div>
        <!--  END NAVBAR  -->
        <!--  BEGIN NAVBAR  -->
        <div class="sub-header-container" v-if="loggedIn">
            <header class="header navbar navbar-expand-sm">
                <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom"
                   @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-menu"
                    >
                        <line x1="3" y1="12" x2="21" y2="12"></line>
                        <line x1="3" y1="6" x2="21" y2="6"></line>
                        <line x1="3" y1="18" x2="21" y2="18"></line>
                    </svg>
                </a>

                <!-- Portal vue/Teleport for Breadcrumb -->
                <div id="breadcrumb" class="vue-portal-target"></div>
            </header>
        </div>
        <!--  END NAVBAR  -->

        <!--  BEGIN TOPBAR  -->
        <main-menu v-if="loggedIn"></main-menu>
        <!--  END TOPBAR  -->
    </div>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import LogOffMenu from "@/components/menus/LogOffMenu.vue";
import UserMenu from "@/components/menus/UserMenu.vue";
import LoginMenu from "@/components/menus/LoginMenu.vue";
import {cutDateString} from "@/utils/convertDateTime";
import MessageMenu from "@/components/menus/MessageMenu.vue";
import MainMenu from "@/components/menus/MainMenu.vue";
import ThemeMenu from "@/components/menus/ThemeMenu.vue";
import NotificationMenu from "@/components/menus/NotificationMenu.vue";

const store = useStore();

const selectedLang = ref(null);
const countryList = ref(store.state.countryList);

const i18n = useI18n();

onMounted(() => {
    selectedLang.value = window.$appSetting.toggleLanguage();
    toggleMode();
});

const toggleMode = (mode) => {
    window.$appSetting.toggleMode(mode);
};

const changeLanguage = (item) => {
    selectedLang.value = item;
    window.$appSetting.toggleLanguage(item);

    i18n.locale = item.code;
};
</script>


<script>
import store from "@/store";
import router from "@/router";
import {REGISTER_TYPES} from "@/data/RegisterTypes";

export default {
    name: 'new-header',
    computed: {
        IsCurrentPageStudent() {
            return router.currentRoute.value.path.indexOf("Student") > 0
        },
        IsCompanyOrStudent() {
            return store.state.RegisterType != REGISTER_TYPES.SCHOOL;
        },
        GetButtonText() {
            if (this.IsCurrentPageStudent)
                return "FOR-COMPANY"
            else
                return "FOR-STUDENT"
        },
        loggedIn() {
            return store.state.loggedIn;
        },
        IsTerminated() {
            return this.$store.state.User != null && this.$store.state.User.TerminationDate != null;
        },
        TerminationDate() {
            return this.$store.state.User != null && this.$store.state.User.TerminationDate
        },
    },
    methods: {
        GoToPage() {
            if (this.IsCurrentPageStudent)
                router.push("/LandingPageCompany");
            else
                router.push("/LandingPageStudent");
        }
    }
}

</script>
<style scoped lang="scss">
.warning-text {
    display: flex;
    color: #FFB92A;
    margin-top: 5px;
}

.triangle {
    margin-top: 3px;
    margin-right: 10px;
    font-size: 1.3em;
}


.marquee {
    max-width: 100vw; /* iOS braucht das */
    white-space: nowrap;
    overflow: hidden;
    /* hier evtl. noch font-size, color usw. */
    color: #FFB92A;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 1.3em;
}


.marquee span {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 25s linear infinite;
}

/* Optional: mouseover (oder Tipp auf dem Touchscreen) pausiert die Laufschrift */
.marquee span:hover {
    animation-play-state: paused
}

/* Make it move */
@keyframes marquee {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

@media (max-width: 575px) {
    .btn-goTo {
        display: none;
    }
}
</style>
