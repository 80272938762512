import BaseButton from '../components/BaseButton.vue';
import BaseInput from '../components/BaseInput.vue';
import BaseRadio from '../components/BaseRadio.vue';
import BaseNumeric from '../components/BaseNumeric.vue';
import BaseTextArea from '../components/BaseTextArea';
import BaseDateTime from '../components/BaseDateTime.vue';
import ImageUpload from '../components/ImageUpload.vue';
import BaseMultiSelect from '../components/BaseMultiSelect'
import BaseCheckbox from '../components/BaseCheckbox.vue';
import BaseTable from '../components/BaseTable.vue';
import Card from '../components/Cards/Card.vue';
import StatsCard from '../components/Cards/StatsCard.vue';
import BaseDropdown from '../components/BaseDropdown.vue';

import draggable from 'vuedraggable'
import BaseSelect from "../components/BaseSelect";

const GlobalComponents = {
  install(Vue) {

    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseRadio.name, BaseRadio);
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseSelect.name, BaseSelect);
    Vue.component(BaseTextArea.name, BaseTextArea);
    Vue.component(ImageUpload.name, ImageUpload);
    Vue.component(BaseNumeric.name, BaseNumeric);
    Vue.component(BaseMultiSelect.name, BaseMultiSelect);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(BaseTable.name, BaseTable);
    Vue.component(StatsCard.name, StatsCard);
    Vue.component(Card.name, Card);
    Vue.component(BaseDateTime.name, BaseDateTime);
    Vue.component(BaseDropdown.name, BaseDropdown);

    Vue.component('draggable-comp', draggable)

  }
};

export default GlobalComponents;
