<template>
    <div class="container-jobineo">
        <div class="section-heading">
            <span class="no-decoration breadcrumb-decorate-blue">
                <font-awesome-icon icon="fas fa-cog"/>
                {{ trans('MAIN-DATA') }}
            </span>
            <span class="no-decoration breadcrumb-decorate-yellow">
                <font-awesome-icon icon="fas fa-angle-right"/> &nbsp;
                <font-awesome-icon icon="fas fa-building"/>
                {{ trans('OFFICES') }}
            </span>
        </div>
        <div class="row">
            <div class="col-lg-3">
                <div class="container-jobineo">
                    <div class="row layout-top-spacing">
                        <div id="basic" class="col-lg-12 col-sm-12 col-12 layout-spacing">
                            <div class="statbox panel box box-shadow">
                                <div class="custom-table">
                                    <FilterHeader
                                        :add-label="$t('ADD-OFFICE')"
                                        :remove-label="trans('DELETE-OFFICE')"
                                        :has-add="true"
                                        :has-remove="true"
                                        @remove="deleteItem"
                                        :remove-filters="true"
                                        @create="showModal"
                                        :has-selection="selectedPkOffice"
                                        :has-reload="true"
                                        @reload="load"
                                    ></FilterHeader>
                                    <LimitHeader :header-type="3"></LimitHeader>

                                    <b-table
                                        responsive
                                        small hover bordered
                                        :busy="loading"
                                        select-mode="single"
                                        sort-by="OfficeName"
                                        :items="OfficesList"
                                        :fields="fields"
                                        primary-key="PkOffice"
                                        @row-clicked="rowClicked"
                                        tbody-class="clickable"
                                    >
                                    </b-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <div v-show="!selectedPkOffice" class="disabled-overlay"></div>
                <div v-show="!selectedPkOffice" class="disabled-overlay-advice">
                    <div class="center-box">
                      <span>
                      <font-awesome-icon icon="fas fa-arrow-left"/><br/>
                      {{ trans('SELECT-OFFICE-FOR-EDITING') }}
                      </span>
                    </div>
                </div>
                <div class="container-jobineo" v-show="showEdit">
                    <div class="row layout-top-spacing">
                        <div id="basic" class="col-lg-12 col-sm-12 col-12 layout-spacing">
                            <div class="statbox panel box box-shadow">
                                <OfficeForm ref="editOfficeForm" :fk-office="selectedPkOffice"
                                            :office-controller="controller"
                                            @update="save"></OfficeForm>
                                <div class="row">
                                    <div class="form-group col-lg-12">
                                        <base-button
                                            native-type="button"
                                            class="btn btn-red float-left float-left-margin"
                                            @click="cancel"
                                        >
                                            <font-awesome-icon icon="fas fa-times"/>
                                            {{ $t('CANCEL') }}
                                        </base-button>


                                        <base-button
                                            native-type="button"
                                            class="btn btn-mint float-right float-right-margin"
                                            @click="$refs.editOfficeForm.$refs.silentSubmit.click()"

                                        >
                                            <font-awesome-icon v-if="processing"
                                                               icon="fas fa-spin fa-circle-notch"/>
                                            <font-awesome-icon v-if="!processing"
                                                               icon="fas fa-check"/>
                                            {{ $t('SAVE') }}
                                        </base-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <OfficeCreateModal
            :office-controller="controller"
            @saved="load()"
            ref="createModal"></OfficeCreateModal>

    </div>
</template>

<script>
/**
 * View: MasterDataOffice
 * Path: /Config/MasterData/MasterDataOffice
 * A sub view for the Config > MaterData area.
 * For managing offices-data.
 *
 */
import OfficeForm from "./OfficeForm.vue";
import {mutate} from "@/utils/mutate.js";
import OfficeCreateModal from "./OfficeCreateModal.vue";
import {OfficeController} from "@/controllers/office.controller";
import FilterHeader from "@/components/dropdowns/FilterHeader.vue";
import store from "@/store";
import {REGISTER_TYPES} from "@/data/RegisterTypes";
import {uuid} from "vue-uuid";
import LimitHeader from "@/components/LimitHeader.vue";

export default {
    name: "MasterDataOffice",
    components: {LimitHeader, FilterHeader, OfficeCreateModal, OfficeForm},
    data() {
        return {
            controller: new OfficeController(this),
            columns: ['OfficeName'],
            OfficesList: [],
            selectedOffice: null,
            loading: true,
            showEdit: false,
            processing: false,
            selectedPkOffice: null,
            fields: [
                {key: "OfficeName", sortable: true, label: this.trans("OFFICE-NAME")},
            ],
        }
    },
    computed: {},
    methods: {
        cancel() {
            this.selectedOffice = {};
            this.$refs.editOfficeForm.setData(this.selectedOffice);
            this.showEdit = false;
            this.selectedPkOffice = null;
            this.selectedOffice = null;
        },
        save() {
            this.processing = true;
            let data = this.$refs.editOfficeForm.getData();
            this.controller.update(data).then(response => {

                if (response.IsError) {
                    this.error = true;
                    this.errorMessage = response.ErrorMessage;
                    this.$notify(
                        {
                            title: this.trans('ERROR'),
                            text: this.trans(response.ErrorMessage),
                            type: 'error',
                        });
                } else {
                    this.selectedOffice = response.Office;
                    this.$refs.editOfficeForm.setData(this.selectedOffice);
                    this.$notify(
                        {
                            title: this.trans('SUCCESS'),
                            text: this.trans('DATA-SAVED'),
                            type: 'success'
                        });
                }

            }).finally(() => {
                this.processing = false;
            })
        },
        deleteItem() {
            if (this.selectedOffice.PkOffice == null)
                return;

            new window.Swal({
                title: this.trans('DELETE-OFFICE'),
                text: this.trans('REALLY-DELETE-OFFICE'),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.trans('NO'),
                confirmButtonText: this.trans('DELETE'),
                iconColor: '#ce6f74',
                padding: '2em'
            }).then(saResult => {
                if (saResult.isConfirmed) {

                    this.controller.delete(this.selectedOffice).then(response => {
                        if (response.IsError) {
                            this.error = true;
                            this.errorMessage = response.ErrorMessage;
                            this.$notify(
                                {
                                    title: this.trans('ERROR'),
                                    text: this.trans(response.ErrorMessage),
                                    type: 'error',
                                });
                        } else {
                            this.selectedOffice = {};
                            this.$refs.editOfficeForm.setData(this.selectedOffice);
                            this.$notify(
                                {
                                    title: this.trans('SUCCESS'),
                                    text: this.trans('DATA-DELETED'),
                                    type: 'success'
                                });
                        }
                    })
                }
            })
        },
        showModal() {

            this.$refs.createModal.open();
            let modal = new window.bootstrap.Modal(document.getElementById('officeModal'));
            modal.show();
        },
        load() {
            this.loading = true;
            this.selectedOffice = {
                OfficeName: '',
                Street: '',
                StreetNr: '',
                PostCode: '',
                City: '',
                Country: null,
                AddressAddition: '',
                OfficeProfile: {
                    PkOfficeProfile: null,
                    FkCompany: null,
                    FkOffice: null,
                    MemberCount: null,
                    FacebookProfile: null,
                    InstagramProfile: null,
                    TikTokProfile: null,
                    LinkedInProfile: null,
                    XingProfile: null,
                    AboutUs: null,
                    JobineoQuestion1: null,
                    JobineoAnswer1: null,
                    JobineoAnswerFile1: null,
                    JobineoQuestion2: null,
                    JobineoAnswer2: null,
                    JobineoAnswerFile2: null,
                    JobineoQuestion3: null,
                    JobineoAnswer3: null,
                    JobineoAnswerFile3: null,
                    OfficeWebsite: null,
                    TraineePerYear: null,
                },
                JobSelectionList: [],
                OfficeBenefitList: [],
                OfficeKeywordList: [],
            };
            this.$refs.editOfficeForm.setData(this.selectedOffice);
            this.showEdit = false;
            this.selectedPkOffice = null;
            this.selectedOffice = null;
            this.controller.read().then(result => {
                this.OfficesList = result.OfficesList;

                store.dispatch('GetLimitationsAndLicenses');
            }).finally(() => {
                this.loading = false;
            })
        },
        rowClicked(item) {
            this.showEdit = false;
            if (!item) return;
            this.selectedPkOffice = item.PkOffice;
            this.showEdit = true;
            this.selectedOffice = item;
            this.$refs.editOfficeForm.setData(item);
        },
    },

    beforeMount() {
        //this.load();
    },
    activated() {
        this.load();
    },
}
</script>

<style lang="scss" scoped>

.custom-table {
    background: transparent;
}
</style>
