import {restUri} from "@/data/restPaths";
import {restBody} from "@/utils/restBody";
import axios from "axios";

export function GetLimitationsAndLicenses(store, data) {

    if (store.state.FkCompany == null || store.state.FkCompany == undefined)
        return {};

    axios.post(restUri.GET_LIMITATIONS_AND_LICENSES, restBody({
        FkCompany: store.state.FkCompany
    })).then(result => {
        let _data = result.data;
        if (!_data.IsError) {
            this.commit('SetLimitationsAndLicenses', _data);
        }
        return _data;
    }).catch(result => {
        return result.data
    })

}
