<template>
    <div class="ellipsis-selector-root" @click="_onclick($event)">
        <i class="fa fa-ellipsis-v"/>
    </div>
</template>

<script>
    export default {
        name: "EllipsisSelector",
        props: ['onclick', 'info'],
        methods:{
            _onclick(e){
                let info = this.$props.info !=null ? this.$props.info : null;
                if(this.$props.onclick instanceof Function)
                    this.$props.onclick(info, e);
            }
        }
    }
</script>

<style scoped>
    .ellipsis-selector-root{
        position: relative;
        display: inline-block;
        width: 20px;
        text-align: center;
        cursor: pointer;

    }
</style>
