import {createI18n} from 'vue-i18n'
import {configure} from 'vee-validate';


const data = {
  available: ['de', 'en'],
  main: 'de',
  fallback: 'en',
  items: {
    'de': {
      tag: 'de',
      label: 'Deutsch',
      flagIconClass: 'fi fi-de',
    },
    'en': {
      tag: 'en',
      label: 'English',
      flagIconClass: 'fi fi-gb',
    }
  }


};

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)

  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}


const i18n = createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || data.main,
  legacy: false,
  allowComposition: true,
  globalInjection: true,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || data.fallback,
  messages: loadLocaleMessages()
})


configure({
  // this will be used to generate messages.
  defaultMessage: (field, values) => {
    return {errorMessage: `VALIDATIONS-${values._rule_.toString().toUpperCase()}`, values: values};
  }
});

export default i18n;

export const getLanguages = () => {
  return data.available.map(key => (data.items[key]));
}

export const getCurrentLanguageFlagCSS = (tag = 'en') => {
  return data.items[tag] != null ? data.items[tag].flagIconClass : 'fa-regular fa-globe';
}
