import Controller from "../controller.js";
import {restUri} from "@/data/restPaths";
import {mutate} from "@/utils/mutate";
import {CREATE, DELETE, UPDATE} from "@/data/cud";
import axios from "axios";
import {restBody} from "@/utils/restBody";
import store from "@/store";


export class SearchController extends Controller {

    constructor(root) {
        super({
            root,
            path: {
                CUD: restUri.STUDENT_PROFILE_CUD,
                GET: restUri.GET_SEARCH_PROFILES
            }
        })
    }

    init() {
    }

    read(searchValues) {
        return super.read({
            log: false,
            body: searchValues
        }).then(data => {
            if (!data.IsError) {
                return data;
            } else return data
        });
    }

    readTopCompanies() {
        return axios.post(restUri.GET_TOP_COMPANIES, restBody({})
        ).then(result => {
            return result.data;
        }).catch(result => {
            return result.data;
        });
    }
    readPostCodes() {
        return axios.post(restUri.GET_POSTCODES, restBody({})
        ).then(result => {
            return result.data;
        }).catch(result => {
            return result.data;
        });

    }


}

