export const SCHOOL_SKILLS = {
    GERMAN: 1,
    LANGUAGES: 2,
    MATHS: 3,
    NATURE: 4,
    CREATIVE: 5,
    WORK: 6,
    POLITICS: 7
}


export const SCHOOL_SKILLS_DROPDOWN = [
    {
        DataKey: SCHOOL_SKILLS.GERMAN,
        DataValue: 'SCHOOL-SKILLS-GERMAN'
    },
    {
        DataKey: SCHOOL_SKILLS.LANGUAGES,
        DataValue: 'SCHOOL-SKILLS-LANGUAGES'
    },
    {
        DataKey: SCHOOL_SKILLS.MATHS,
        DataValue: 'SCHOOL-SKILLS-MATHS'
    },
    {
        DataKey: SCHOOL_SKILLS.NATURE,
        DataValue: 'SCHOOL-SKILLS-NATURE'
    },
    {
        DataKey: SCHOOL_SKILLS.CREATIVE,
        DataValue: 'SCHOOL-SKILLS-CREATIVE'
    },
    {
        DataKey: SCHOOL_SKILLS.WORK,
        DataValue: 'SCHOOL-SKILLS-WORK'
    },
    {
        DataKey: SCHOOL_SKILLS.POLITICS,
        DataValue: 'SCHOOL-SKILLS-POLITICS'
    },
]
