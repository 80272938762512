<template>
    <div class="dropdown nav-item message-dropdown btn-group">
        <a href="javascript:;" id="ddlmsg" data-bs-toggle="dropdown" aria-expanded="false"
           class="btn dropdown-toggle btn-icon-only nav-link">
            <font-awesome-icon :icon="['fas','info-circle']"/>
            <span class="ms-2">{{ trans("INFORMATION") }}</span>
        </a>
        <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="ddlmsg">
            <template v-for="(route,idx) in routes" :key="idx">

                <li role="presentation" v-if="checkLoggedIn(route.checkLoginState)">
                    <a role="menuitem" :href="'#'+route.path" target="_self" class="dropdown-item">
                    <span v-if="route.hasChildren">
                    <b>{{ $t(route.title) }}</b>
                </span>
                        <span v-else>
                    <span class="icon-wrapper-20"><font-awesome-icon :icon="route.icon"/></span> {{ $t(route.title) }}
                    </span>
                    </a>
                </li>
                <li v-if="route.setDivider==true" class="divider">&nbsp;</li>
            </template>
        </ul>
    </div>
</template>
<script>

import {flattenConfigMenuTopLevelPathTreeWithoutRoot} from "@/routes/logoff.routes";
import store from "@/store";
import {LOGIN_STATE} from "@/data/LoginStates";
import ellipsisSelector from "@/components/EllipsisSelector.vue";


export default {
    name: 'log-off-menu',
    data() {
        return {
            routes: flattenConfigMenuTopLevelPathTreeWithoutRoot
        }
    },
    computed: {
        loggedIn() {
            return store.state.loggedIn;
        }
    },
    methods: {
        checkLoggedIn(value) {
            if (value == LOGIN_STATE.LOGGED_OFF && !this.loggedIn)
                return true;
            else if (value == LOGIN_STATE.LOGGED_IN && this.loggedIn)
                return true;
            else if (value == LOGIN_STATE.IGNORE)
                return true
            else
                return false;
        }
    }
}
</script>

<style scoped>
@media (max-width: 800px) {
    div > a > span {
        display: none;
    }
}
</style>
