<template>
    <div>
        <Form ref="valForm" :validationSchema="validationSchema">
            <div class="row">
                <div class="terms_condition mb-4">
                    <div class="checkbox-outline-primary custom-control custom-checkbox">

                        <base-checkbox
                            id="UseComp"
                            class="custom-control-input"
                            :checked="UseCompanyAddress"
                            @input="setCompanyAddress"
                            :label="$t('USE-COMPANY-ADDRESS')"
                        />

                    </div>
                </div>

            </div>
            <div class="row">
                <div class="form-group col-lg-5">
                    <base-input ref="OfficeName"
                                v-model="model.OfficeName"
                                :label="trans('OFFICE-NAME')"
                                type="text"
                                :required="true"
                                obj-name="OfficeName"
                                :max-length="100"
                                attached-class="form-control" placeholder="..."/>
                </div>

            </div>
            <div class="row">
                <div class="form-group col-lg-5">
                    <base-input ref="OfficeStreet"
                                v-model="model.OfficeStreet"
                                :label="trans('STREET')"
                                type="text"
                                :max-length="50"
                                attached-class="form-control"
                                obj-name="OfficeStreet" :required="true" placeholder="..."/>
                </div>
                <div class="form-group col-lg-2">
                    <base-input ref="OfficeStreetNr"
                                v-model="model.OfficeStreetNr"
                                type="text"
                                :max-length="10"
                                :required="true"
                                :label="trans('STREET-NR')"
                                attached-class="form-control"
                                obj-name="OfficeStreetNr" placeholder="..."/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-5">
                    <base-input ref="OfficeAddressAddition"
                                v-model="model.OfficeAddressAddition"
                                :label="trans('ADDITION')"
                                type="text"
                                :max-length="100"
                                attached-class="form-control"
                                obj-name="OfficeAddressAddition" placeholder="..."/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-2">
                    <base-input ref="OfficePostCode"
                                v-model="model.OfficePostCode"
                                :label="trans('POSTCODE')"
                                type="text"
                                :max-length="10"
                                attached-class="form-control"
                                obj-name="OfficePostCode" :required="true" placeholder="..."/>
                </div>
                <div class="form-group col-lg-5">
                    <base-input ref="OfficeCity"
                                v-model="model.OfficeCity"
                                type="text"
                                :max-length="50"
                                :required="true"
                                :label="trans('CITY')"
                                attached-class="form-control"
                                obj-name="OfficeCity" placeholder="..."/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-3">
                    <data-dropdown ref="OfficeCountry"
                                   v-model="model.OfficeCountry"
                                   :label="trans('COUNTRY')"
                                   :required="true"
                                   :items="CountriesList"
                                   :null-value-text="trans('SELECT-COUNTRY')"
                                   :has-null-value="true"
                                   attached-class="form-control"
                                   name="OfficeCountry"/>
                </div>
            </div>

        </Form>
        <div v-if="error" class="error-alert-wrap"><font-awesome-icon icon="fas fa-exclamation-triangle" class="error-icon" /> {{ trans(errorMessage) }}
        </div>
    </div>
</template>


<script>
import * as yup from 'yup';
import {Form} from 'vee-validate';
import DataDropdown from "@/components/dropdowns/DataDropdown";

export default {
    name: "OfficeStep",
    props: {
        address: {
            type: Object,
            required: true
        }
    },
    components: {Form, DataDropdown},
    data() {
        return {
            validationSchema: yup.object({
                OfficeStreet: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                OfficeStreetNr: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                OfficePostCode: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                OfficeCity: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                OfficeName: yup.string().required(this.trans("REQUIRED-MESSAGE")),
            }),
            UseCompanyAddress: false,
            CompanyAddress: {},
            model: {
                OfficeName: '',
                OfficeStreet: '',
                OfficeStreetNr: '',
                OfficePostCode: '',
                OfficeCity: '',
                OfficeCountry: null,
                OfficeAddressAddition: ''
            },
            error: false,
            errorMessage: ''
        }
    },
    computed: {
        CountriesList() {
            return this.$store.state.Lookups.CountriesLookup != null && Array.isArray(this.$store.state.Lookups.CountriesLookup) ? this.$store.state.Lookups.CountriesLookup : [];
        },
    },
    watch: {
        address: {
            handler(model) {
                this.CompanyAddress = model
            },
            immediate: true
        },
    },
    methods: {
        setCompanyAddress(value) {
            this.UseCompanyAddress = value.target.checked;
            if (value.target.checked === true && this.CompanyAddress != null) {
                this.model.OfficeName = this.CompanyAddress.CompanyName
                this.model.OfficeStreet = this.CompanyAddress.Street
                this.model.OfficeStreetNr = this.CompanyAddress.StreetNr
                this.model.OfficePostCode = this.CompanyAddress.PostCode
                this.model.OfficeCity = this.CompanyAddress.City
                this.model.OfficeCountry = this.CompanyAddress.Country
                this.model.OfficeAddressAddition = this.CompanyAddress.AddressAddition
            } else {
                this.model.OfficeName = ''
                this.model.OfficeStreet = ''
                this.model.OfficeStreetNr = ''
                this.model.OfficePostCode = ''
                this.model.OfficeCity = ''
                this.model.OfficeCountry = null
                this.model.OfficeAddressAddition = ''
            }
        },
        countrySelect(value) {
            this.model.Country = value;
        },
        submitForm(valid) {
            this.$emit('update', valid)
        },
        reset() {
            this.model.OfficeName = ''
            this.model.OfficeStreet = ''
            this.model.OfficeStreetNr = ''
            this.model.OfficePostCode = ''
            this.model.OfficeCity = ''
            this.model.OfficeCountry = null
            this.model.OfficeAddressAddition = ''
        },
        validate() {


            this.error = false;
            this.errorMessage = '';


            return this.$refs.valForm.validate().then(result => {
                if (result.valid) {
                    this.$emit('on-validated', true, this.model);
                    return Promise.resolve(true);
                } else
                    return Promise.resolve(false);
            })

        }

    }
}
</script>

<style scoped>


</style>
