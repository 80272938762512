import {restUri} from "@/data/restPaths";
import {restBody} from "@/utils/restBody";
import axios from "axios";


export function getAllLookups(store, data) {
  Promise.all([
    getCountries(store, data),
    getJobs(store, data),
    getAppointmentTypes(store, data),
    getSalutations(store, data),
    getJobAdTypes(store, data),
    getJobFields(store, data),
    getCompanyQuestions(store, data),
    getStudentQuestions(store, data),
    getDriverLicenses(store, data),
    getLocationTypes(store, data),
    getNotificationTypes(store, data),
    getCompanySizes(store, data),
    getSalaryTypes(store, data),
    getPaymentIntervals(store, data),
    getStudyFields(store, data),
    getGraduations(store, data)
  ]).then(result => {
  })
}

export function getSalutations(store, data) {


  axios.post(restUri.GET_LOOKUP_DATA, restBody({
    FkLookupGroup: '2566e090-0076-40cc-abf1-17036a473da8'
  })).then(result => {
    let _data = result.data;
    if (!_data.IsError) {
      store.commit('setSalutations', _data);
    }
    return _data;
  }).catch(result => {
    return result.data
  })

}

export function getJobAdTypes(store, data) {


  axios.post(restUri.GET_LOOKUP_DATA, restBody({
    FkLookupGroup: 'a3a90f40-ed1c-46c6-bcc2-4b731b63c381'
  })).then(result => {
    let _data = result.data;
    if (!_data.IsError) {
      store.commit('setJobAdTypes', _data);
    }
    return _data;
  }).catch(result => {
    return result.data
  })

}

export function getSalaryTypes(store, data) {


  axios.post(restUri.GET_LOOKUP_DATA, restBody({
    FkLookupGroup: '697b2a6c-15b5-45fc-88a9-33daa3df6614'
  })).then(result => {
    let _data = result.data;
    if (!_data.IsError) {
      store.commit('setSalaryTypes', _data);
    }
    return _data;
  }).catch(result => {
    return result.data
  })

}

export function getLocationTypes(store, data) {


  axios.post(restUri.GET_LOOKUP_DATA, restBody({
    FkLookupGroup: '59448bd3-2aed-4068-9ef2-c11a885bb4f0'
  })).then(result => {
    let _data = result.data;
    if (!_data.IsError) {
      store.commit('setLocationTypes', _data);
    }
    return _data;
  }).catch(result => {
    return result.data
  })

}

export function getAppointmentTypes(store, data) {


  axios.post(restUri.GET_LOOKUP_DATA, restBody({
    FkLookupGroup: '31f775cb-990e-4e97-9ca8-c63550b21e76'
  })).then(result => {
    let _data = result.data;
    if (!_data.IsError) {
      store.commit('setAppointmentTypes', _data);
    }
    return _data;
  }).catch(result => {
    return result.data
  })

}

export function getNotificationTypes(store, data) {

  axios.post(restUri.GET_LOOKUP_DATA, restBody({
    FkLookupGroup: 'e38803c7-d3d3-4557-912a-03298af1b4ea'
  })).then(result => {
    let _data = result.data;
    if (!_data.IsError) {
      store.commit('setNotificationTypes', _data);
    }
    return _data;
  }).catch(result => {
    return result.data
  })

}

export function getCountries(store, data) {

  axios.post(restUri.GET_LOOKUP_DATA, restBody({
    FkLookupGroup: 'c00ba9eb-3d20-4df5-9deb-f82952a5bbfa'
  })).then(result => {
    let _data = result.data;
    if (!_data.IsError) {
      store.commit('setCountries', _data);
    }
    return _data;
  }).catch(result => {
    return result.data
  })

}

export function getJobFields(store, data) {

  axios.post(restUri.GET_LOOKUP_DATA, restBody({
    FkLookupGroup: 'b17a5224-8c47-43a7-b9e1-2a6a6a67bc5f'
  })).then(result => {
    let _data = result.data;
    if (!_data.IsError) {
      store.commit('setJobFields', _data);
    }
    return _data;
  }).catch(result => {
    return result.data
  })

}

export function getJobs(store, data) {

  axios.post(restUri.GET_LOOKUP_DATA, restBody({
    FkLookupGroup: '422cc99c-4721-4b12-9c20-981da80953ea'
  })).then(result => {
    let _data = result.data;
    if (!_data.IsError) {
      store.commit('setJobs', _data);
    }
    return _data;
  }).catch(result => {
    return result.data
  })

}

export function getCompanySizes(store, data) {

  axios.post(restUri.GET_LOOKUP_DATA, restBody({
    FkLookupGroup: '213c4d25-b6cd-43fd-9109-6b437c1b9d1e'
  })).then(result => {
    let _data = result.data;
    if (!_data.IsError) {
      store.commit('setCompanySizes', _data);
    }
    return _data;
  }).catch(result => {
    return result.data
  })

}

export function getPaymentIntervals(store, data) {

  axios.post(restUri.GET_LOOKUP_DATA, restBody({
    FkLookupGroup: '9b651676-de3f-449e-b8e3-45a8ef41390b'
  })).then(result => {
    let _data = result.data;
    if (!_data.IsError) {
      store.commit('setPaymentIntervals', _data);
    }
    return _data;
  }).catch(result => {
    return result.data
  })

}

export function getStudentQuestions(store, data) {

  axios.post(restUri.GET_LOOKUP_DATA, restBody({
    FkLookupGroup: 'd6af5c6f-3b60-4efa-93c2-06d09a3c20bb'
  })).then(result => {
    let _data = result.data;
    if (!_data.IsError) {
      store.commit('setStudentQuestions', _data);
    }
    return _data;
  }).catch(result => {
    return result.data
  })

}

export function getCompanyQuestions(store, data) {

  axios.post(restUri.GET_LOOKUP_DATA, restBody({
    FkLookupGroup: '96addf27-6d35-4fe4-a194-d6470791ca4a'
  })).then(result => {
    let _data = result.data;
    if (!_data.IsError) {
      store.commit('setCompanyQuestions', _data);
    }
    return _data;
  }).catch(result => {
    return result.data
  })

}

export function getStudyFields(store, data) {

  axios.post(restUri.GET_LOOKUP_DATA, restBody({
    FkLookupGroup: '250df6a8-d39a-4474-b31b-f25a74f1f718'
  })).then(result => {
    let _data = result.data;
    if (!_data.IsError) {
      store.commit('setStudyFields', _data);
    }
    return _data;
  }).catch(result => {
    return result.data
  })

}

export function getDriverLicenses(store, data) {

  axios.post(restUri.GET_LOOKUP_DATA, restBody({
    FkLookupGroup: '13964ba9-0509-4d30-8296-ea1c2d251963'
  })).then(result => {
    let _data = result.data;
    if (!_data.IsError) {
      store.commit('setDriverLicenses', _data);
    }
    return _data;
  }).catch(result => {
    return result.data
  })

}

export function getGraduations(store, data) {

  axios.post(restUri.GET_LOOKUP_DATA, restBody({
    FkLookupGroup: 'bc405b7e-3a76-4855-9e64-5a76a1133179'
  })).then(result => {
    let _data = result.data;
    if (!_data.IsError) {
      store.commit('setGraduations', _data);
    }
    return _data;
  }).catch(result => {
    return result.data
  })

}

export function sendErrorRequest(store, data) {

  let formData = new FormData();
  if (data.File != null && data.File != "undefined")
    formData.append('ErrorScreenshot', data.File)

  formData.append('json', JSON.stringify(restBody(data)))

  return axios.post(restUri.SEND_ERROR_REQUEST, formData).then(result => {
    return result.data;
  }).catch(result => {
    return result.data
  })

}

