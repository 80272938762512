<template>
    <div class="panel-heading">
        <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                <h4>{{ trans('OFFICE') }}</h4>
            </div>
        </div>
    </div>
    <div class="panel-body">
        <Form class="text-start" ref="valForm" :validationSchema="validationSchema"
              @submit="validate">
            <div class="row flex">
                <div class="form-group col-3" v-if="!model.IsCompAdmin">
                    <office-dropdown
                        v-model="model.User2Comp.FkOffice"
                        :items="OfficeList"
                        obj-name="FkOffice"
                        :required="false"
                        :label="trans('OFFICE')"
                        attached-class="form-control"
                        :has-null-value="true"
                        :null-value-text="trans('SELECT-OFFICE')"
                        name="FkOffice"
                        ref="FkOffice"/>
                </div>
                <div class="form-group col-3">
                    <base-input
                        v-model="model.Email"
                        type="Email"
                        obj-name="Email"
                        :required="true"
                        :label="trans('EMAIL')"
                        attached-class="form-control"
                        :placeholder="trans('EMAIL')"
                        name="Email"
                        ref="Email"/>
                </div>
            </div>
            <div class="row flex">
                <div class="form-group col-3">
                    <div id="saluation-field" class="field-wrapper input">
                        <salutations-drop-down ref="Salutation"
                                               v-model="model.Salutation"
                                               :required="true"
                                               :has-null-value="true"
                                               :null-value-text="trans('SELECT-SALUTATION')"
                                               :label="trans('SALUTATION')"
                                               attached-class="form-control"
                                               name="Salutation"/>
                    </div>
                </div>
                <div class="form-group col-3">
                    <div id="nameaddition-field" class="field-wrapper input">
                        <base-input
                            v-model="model.NameAddition"
                            type="text"
                            obj-name="NameAddition"
                            :label="trans('NAME-ADDITION')"
                            attached-class="form-control"
                            :placeholder="trans('NAME-ADDITION')"
                            name="NameAddition"
                            ref="NameAddition"/>
                    </div>
                </div>
            </div>
            <div class="row flex">
                <div class="form-group col-3">
                    <div id="firstname-field" class="field-wrapper input">

                        <base-input
                            v-model="model.FirstName"
                            type="text"
                            obj-name="FirstName"
                            :label="trans('FIRSTNAME')"
                            :required="true"
                            attached-class="form-control"
                            :placeholder="trans('FIRSTNAME')"
                            name="FirstName"
                            ref="FirstName"/>
                    </div>
                </div>
                <div class="form-group col-3">
                    <div id="lastname-field" class="field-wrapper input">

                        <base-input
                            v-model="model.LastName"
                            type="text"
                            obj-name="LastName"
                            :label="trans('LASTNAME')"
                            attached-class="form-control"
                            :required="true"
                            :placeholder="trans('LASTNAME')"
                            name="LastName"
                            ref="LastName"/>
                    </div>
                </div>
            </div>
            <div class="row flex">
                <div class="form-group col-3">
                    <div id="firstname-field" class="field-wrapper input">
                        <base-input v-model="model.Password"
                                    type="password"
                                    name="Password"
                                    obj-name="Password"
                                    :label="trans('PASSWORD')"
                                    :help-text="passwordHelp"
                                    :max-length="PasswordMaxLength"
                                    :min-length="PasswordMinLength"
                                    :regex="PasswordRegex"
                                    attached-class="form-control font-size-14"
                                    ref="Password"
                                    placeholder="..."/>


                    </div>
                </div>
            </div>
            <div class="row flex" v-if="!isNew">
                <div class="form-group col-3">
                    <div id="firstname-field" class="field-wrapper input">
                        <base-input v-model="model.LoginErrors"
                                    type="number"
                                    name="LoginErrors"
                                    :min-int="0"
                                    obj-name="LoginErrors"
                                    :label="trans('LOGIN-ERRORS')"
                                    attached-class="form-control font-size-14"/>


                    </div>
                </div>
                <div class="form-group col-2 ">
                    <div class="checkbox-outline-primary custom-control custom-checkbox margin-top-30">

                        <base-checkbox
                            id="UseComp"
                            :disabled="!model.IsSystemLocked"
                            class="custom-control-input"
                            :checked="model.IsSystemLocked"
                            @input="SetIsSystemLocked"
                            :label="$t('IS-SYSTEM-LOCKED')"
                        />

                    </div>
                </div>
            </div>


            <button ref="silentSubmit" type="submit" class="btn-silent-submit"/>
        </Form>
        <div v-if="error" class="error-alert-wrap">
            <font-awesome-icon icon="fas fa-exclamation-triangle" class="error-icon" />
            {{ trans(errorMessage) }}
        </div>
    </div>
</template>

<script>
import {mutate} from "@/utils/mutate";
import {Form} from 'vee-validate';
import SalutationsDropDown from '@/components/dropdowns/SalutationsDropdown.vue'
import * as yup from "yup";
import UsersController from "@/controllers/users.controller";
import OfficeDropdown from "@/components/dropdowns/OfficeDropdown.vue";
import {OfficeController} from "@/controllers/office.controller";


export default {
    name: "NewUserForm",
    props: ['isNew'],
    emits: ["update"],
    components: {OfficeDropdown, Form, SalutationsDropDown},
    data() {
        return {

            officeController: new OfficeController(this),
            OfficesList: [],
            model: {
                PkUser: null,
                FirstName: null,
                LastName: null,
                Salutation: null,
                NameAddition: null,
                Email: '',
                Password: null,
                User2Comp: {
                    PkUserToCompany: null,
                    FkCompany: null,
                    FkUser: null,
                    FkOffice: null
                }
            },
            controller: new UsersController(this),
            error: false,
            errorMessage: '',
        }
    },
    computed: {
        validationSchema() {
            if (this.$props.isNew == true) {
                return yup.object({
                    FirstName: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                    LastName: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                    Password: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                    Email: yup.string().required(this.trans("REQUIRED-MESSAGE")).email(this.trans("NO-EMAIL"))
                })
            } else {
                return yup.object({
                    FirstName: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                    LastName: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                    Email: yup.string().required(this.trans("REQUIRED-MESSAGE")).email(this.trans("NO-EMAIL"))
                })
            }
        },
        OfficeList() {
            return this.OfficesList != null && Array.isArray(this.OfficesList) ? this.OfficesList : [];
        },
        passwordHelp() {
            let text = this.$t('HLP-PASSWORD') + "\n";

            if (this.$store.state.SysSettings != null) {
                let sys = this.$store.state.SysSettings;
                if (sys.PasswordMinChar > 0) {
                    text += this.$t('PW-MIN') + sys.PasswordMinChar + this.$t('PW-MIN-CHAR') + ";\n";
                }

                if (sys.PasswordMaxChar > 0) {
                    text += this.$t('PW-MAX') + sys.PasswordMaxChar + this.$t('PW-MAX-CHAR') + ";\n";
                }

                if (sys.PasswordNeedSymbol > 0) {
                    text += this.$t('PW-MIN') + sys.PasswordNeedSymbol + this.$t('PW-MIN-SYM') + ";\n";
                }

                if (sys.PasswordMaxRepeatChar > 0) {
                    text += this.$t('PW-MAX') + sys.PasswordMaxRepeatChar + this.$t('PW-MAX-SAME') + ";\n";
                }

                if (sys.PasswordNeedNumeric > 0) {
                    text += this.$t('PW-MIN') + sys.PasswordNeedNumeric + this.$t('PW-MIN-NUMERIC') + ";\n";
                }

                if (sys.PasswordNeedLowerCase > 0) {
                    text += this.$t('PW-MIN') + sys.PasswordNeedLowerCase + this.$t('PW-MIN-LOWER') + ";\n";
                }

                if (sys.PasswordNeedUpperCase > 0) {
                    text += this.$t('PW-MIN') + sys.PasswordNeedUpperCase + this.$t('PW-MIN-UPPER') + ";\n";
                }

            }

            return text
        },
        PasswordMaxLength() {
            if (this.$store.state.SysSettings != null) {
                let sys = this.$store.state.SysSettings;
                return sys.PasswordMaxChar;
            }
            return null
        },
        PasswordMinLength() {
            if (this.$store.state.SysSettings != null) {
                let sys = this.$store.state.SysSettings;
                return sys.PasswordMinChar;
            }
            return null
        },
        PasswordRegex() {
            let regexText = '';

            // '^'
            if (this.$store.state.SysSettings != null) {
                let sys = this.$store.state.SysSettings;
                let praefix = '';
                if (sys.PasswordNeedSymbol > 0) {
                    praefix = '^'
                    regexText += '(?=.*?[@;:_,.\\-+*~§$%&=#?]{' + sys.PasswordNeedSymbol + ',})';
                }

                if (sys.PasswordNeedNumeric > 0) {
                    praefix = '^'
                    regexText += '(?=.*?[0-9]{' + sys.PasswordNeedNumeric + ',})';
                }

                if (sys.PasswordNeedLowerCase > 0) {
                    praefix = '^';
                    regexText += '(?=.*?[a-z]{' + sys.PasswordNeedLowerCase + ',})';
                }

                if (sys.PasswordNeedUpperCase > 0) {
                    praefix = '^';
                    regexText += '(?=.*?[A-Z]{' + sys.PasswordNeedUpperCase + ',})';
                }

                regexText = praefix + regexText;

            }
            return regexText;
        }
    },
    methods: {
        SetIsSystemLocked(value) {
            this.model.IsSystemLocked = value.target.checked;
        },
        setError(errorMessage = null) {
            if (errorMessage != null) {
                this.errorMessage = errorMessage;
                this.error = true;
            } else {
                this.errorMessage = null;
                this.error = false;
            }
        },
        getData() {
            return this.model;
        },
        setData(model = {}) {

            this.$refs.LastName.reset();
            this.$refs.FirstName.reset();
            this.$refs.Salutation.reset();
            this.$refs.NameAddition.reset();
            this.$refs.Password.reset();
            if (!this.model.IsCompAdmin) {
                this.$refs.FkOffice.reset();
            }
            this.$refs.Email.reset();

            this.model = mutate({
                PkUser: null,
                FirstName: null,
                LastName: null,
                Salutation: null,
                NameAddition: null,
                Email: '',
                Password: null,
            }, model);

        },
        reset() {
            this.errorMessage = '';
            this.error = false;

            this.model = mutate({
                PkUser: null,
                FirstName: null,
                LastName: null,
                Salutation: null,
                NameAddition: null,
                Email: '',
                Password: null,
                PasswordRepeat: null
            });

        },
        validate() {
            return this.$refs.valForm.validate().then(result => {
                let otherErrors = false;

                if (this.model.Salutation <= 0) {
                    this.$refs.Salutation.setError();
                    otherErrors = true;
                }


                if (result.valid && !otherErrors) {
                    this.$emit('update', true);
                    return Promise.resolve(true);
                } else {
                    for (const key in result.errors) {
                        this.$refs[key].setError();
                    }

                    return Promise.resolve(false);
                }
            })
        }
    },
    activated() {
        this.officeController.read().then(result => {
            this.OfficesList = result.OfficesList;
        })
    }
}
</script>

<style scoped lang="scss">
.pw-changed {
    display: block;
    font-weight: bold;
}

.bold-header {
    font-weight: bold;
}

.pw-reseted {
    display: block;
    padding-top: 10px;
    font-size: 12px;
}

.bold-green {
    font-weight: bold;
    color: limegreen;
}


</style>
