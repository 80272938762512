<template>
    <div class="sc">
        <div class="sc-root" ref="rootNode">
            <div class="sc-wrap">
                <div class="section-heading" style="margin-left: 0px; margin-top:0px">
                    <router-link to="SendErrorRequestPage"
                                 :class="{'no-decoration':true, ' breadcrumb-decorate-blue':true}">
                        <font-awesome-icon icon="fas fa-user"/>
                        {{ $t('SEND-ERROR-REQUEST-PAGE') }}
                    </router-link>
                </div>
                <div class="clearfix"></div>
                <div class="sc-content" style="overflow: hidden!important">
                    <ValidationObserver ref="observer" v-slot="{ validate }">
                        <form @submit.prevent="validate().then(save)" ref="_form">
                            <div class="row">
                                <div class="form-group col-6">
                                    <base-input
                                        v-model="model.ErrorLabel"
                                        type="text"
                                        ref="errorLabel"
                                        :required="true"
                                        :min-length="10"
                                        :max-length="100"
                                        :label="$t('ERROR-LABEL')"
                                        attached-class="form-control"
                                        name="error-label"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group  col-8">
                                    <base-textarea
                                        v-model="model.ErrorDescription"
                                        type="text"
                                        ref="errorDescription"
                                        :min-length="10"
                                        :label="$t('ERROR-DESCRIPTION')"
                                        attached-class="form-control"
                                        name="error-description"
                                        :max-length="500"
                                        :required="true"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-4">
                                    <base-select
                                        ref="errorPage"
                                        :required="true"
                                        v-model="model.ErrorPage"
                                        :items="$t('pages')"
                                        null-value-text="PLEASE-SELECT"
                                        :has-null-value="true"
                                        :label="$t('PAGES')"></base-select>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                            <h6 class="title d-inline">{{ $t('UPLOAD-ERROR') }}</h6>
                            <div class="form-group flex">
                                <div class="dropbox">
                                    <input ref="fileInput" type="file" accept=".jpeg, .pdf, .png, .jpg"
                                           class="input-file"
                                           @change="fileUpload(arguments)"/>
                                    <p v-if="!model.FileName">
                                        {{ $t('UPLOAD-FILE') }}
                                    </p>
                                    <div v-if="model.FileName"><p>{{ model.FileName }}<br>
                                        {{ $t('CHANGE-UPLOAD-FILE') }}</p></div>
                                </div>
                            </div>
                            <button ref="silentSubmit" type="submit" class="btn-silent-submit"/>
                        </form>
                    </ValidationObserver>
                    <div class="modal-footer">
                        <div class="footer-inner">

                            <base-button
                                @click="close"
                                type="danger"
                                native-type="button"
                                class="btn btn-sm  btn-danger float-left">
                                <font-awesome-icon icon="fas fa-times"/> {{ $t('CANCEL') }}
                            </base-button>

                            <base-button type="gray"
                                         native-type="button"
                                         @click="reset"
                                         class="btn btn-sm btn-secondary margin-left-10 float-left">
                                <font-awesome-icon icon="fa fa-trash"/> {{ $t('RESET') }}
                            </base-button>
                            <WaitingButton
                                size="sm"
                                variant="success"
                                icon="fas fa-check"
                                class="float-right"
                                :waiting="isSaving"
                                @click="$refs.silentSubmit.click()">
                                {{ $t('SAVE') }}
                            </WaitingButton>


                        </div>

                    </div>
                    <b-alert
                        v-model="error"
                        variant="danger"
                        class="error-alert"
                    >
                        <div class="error-alert-wrap">
                            <font-awesome-icon icon="fas fa-exclamation-triangle" class="error-icon" />
                            {{ trans(errorMessage) }}
                        </div>
                    </b-alert>
                </div>

            </div>
        </div>
    </div>


</template>

<script>
import WaitingButton from "../../components/WaitingButton.vue";

export default {
    name: "SendErrorRequestPage",
    components: {WaitingButton},
    data() {
        return {
            model: {
                ErrorLabel: '',
                ErrorDescription: '',
                ErrorPage: null,
                FileName: '',
                File: null,
                FkUser: this.store.state.User != null ? this.store.state.User.PkUser : null
            },
            error: false,
            errorMessage: '',
            isSaving: false
        }
    },
    computed: {},
    methods: {
        setError(errorMessage = null) {
            if (errorMessage != null) {
                this.errorMessage = errorMessage;
                this.error = true;
            } else {
                this.errorMessage = null;
                this.error = false;
            }
        },
        close() {
            this.$router.push('/')
        },
        resetAllValidations() {
            this.$refs.errorLabel.reset();
            this.$refs.errorPage.reset();
            this.$refs.errorDescription.reset();

        },
        reset() {
            this.model = {
                ErrorLabel: '',
                ErrorDescription: '',
                ErrorPage: null,
                ErrorScreenshot: null
            };
            this.resetAllValidations();
        },
        fileUpload(e) {
            this.error = false;
            this.errorMessage = null

            let data = this.model;
            let eventInput = e[0];
            let MAX_FILE_SIZE = 8 * 1024 * 1024;

            const file = eventInput.target.files[0];
            if (!file) {
                eventInput.preventDefault();
                eventInput.target.value = null;
                return;
            }

            if (file.size > MAX_FILE_SIZE) {
                eventInput.preventDefault();
                eventInput.target.value = null;
                this.error = true;
                this.errorMessage = "FILE-TO-BIG"
                return;
            }

            let fileReader = new FileReader();

            fileReader.onloadend = function (e) {
                let arr = (new Uint8Array(e.target.result)).subarray(0, 4);

                let header = "";
                for (let i = 0; i < arr.length; i++) {
                    header += arr[i].toString(16);
                }

                let type = "";
                switch (header) {
                    case "89504e47":
                        type = "image/png";
                        break;
                    case "47494638":
                        type = "image/gif";
                        break;
                    case "ffd8ffe0":
                    case "ffd8ffe1":
                    case "ffd8ffe2":
                    case "ffd8ffe3":
                    case "ffd8ffe8":
                        type = "image/jpeg";
                        break;
                    case "255044462D":
                    case "25504446":
                        type = "pdf";
                        break;
                    default:
                        type = "unknown"; // Or you can use the blob.type as fallback
                        break;
                }

                if (type != "unknown") {
                    data.FileName = file.name;
                    data.File = file;
                } else {
                    eventInput.preventDefault();
                    eventInput.target.value = null;
                    return;
                }
            }

            fileReader.readAsArrayBuffer(file);


        },
        cancel() {
            this.$router.push('/')
        },
        save(valid) {

            if (!valid)
                return;

            this.isSaving = true;

            this.$store.dispatch('sendErrorRequest', this.model).then(data => {
                this.isSaving = false;

                if (data && !data.IsError) {

                    this.flashMessage.success(
                        {
                            title: this.trans('SUCCESS'),
                            message: this.trans('DATA-SAVED'),
                            time: 4000
                        });
                    this.setError();
                    this.reset();
                    this.$router.push('/');
                } else if (data && data.IsError) {
                    this.flashMessage.error(
                        {
                            title: this.trans('ERROR'),
                            message: this.trans(data.ErrorMessage),
                            time: 4000
                        });
                    this.setError(data.ErrorMessage);
                }

                return data;
            }).catch(result => {
                this.isSaving = false;
                this.flashMessage.error(
                    {
                        title: this.trans('ERROR'),
                        message: this.trans(result.ErrorMessage),
                        time: 4000
                    });
                this.setError(result.ErrorMessage);
            });
        }
    },


}
</script>

<style scoped>

.user-table tr, .user-table tr td {
    border: none !important;
}

.dropbox {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
}

.input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
}

.dropbox:hover {
    background: lightgray; /* when mouse over to the drop zone, change color */
}

.dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
}

.footer-inner {
    display: block;
    width: 100%;
}
</style>
