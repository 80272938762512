<template>
    <div>
        <Form ref="_form">
            <div class="row flex">
                <label class="font-size-18 input-label help-text-label"> {{ trans('REGISTER-FINISH-TEXT') }}</label>
            </div>
            <div class="row">
                <div class="terms_condition mb-4">
                    <div class="checkbox-outline-primary custom-control custom-checkbox">

                        <base-checkbox
                            id="AGB"
                            class="custom-control-input"
                            :checked="model.AGBSAccepted"
                            @input="setAGB"
                            :required="true"
                            :label="$t('AGBS-ACCEPT')"
                        />

                    </div>
                    <div class="form-group col-5">
                        <a href="#/Imprint" target="_blank">{{ trans('AGB') }}</a>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="terms_condition mb-4">
                    <div class="checkbox-outline-primary custom-control custom-checkbox">

                        <base-checkbox
                            id="dsgvoAccept"
                            class="custom-control-input"
                            :checked="model.DSGVOAccepted"
                            @input="setDSGVO"
                            :required="true"
                            :label="$t('DSGVO-ACCEPT')"
                        />

                    </div>
                    <div class="form-group col-5">
                        <a
                            href="#/DataProtection" target="_blank">{{ trans('DATA-PROTECTION') }}</a>
                    </div>
                </div>

            </div>
        </Form>
        <div v-if="error" class="error-alert-wrap"><font-awesome-icon icon="fas fa-exclamation-triangle" class="error-icon" /> {{ trans(errorMessage) }}
        </div>
    </div>
</template>
<script>

import {Form} from 'vee-validate';

export default {
    components: {Form},
    data() {
        return {
            model: {
                AGBSAccepted: false,
                DSGVOAccepted: false
            },
            error: false,
            errorMessage: ''
        };
    },
    methods: {
        setAGB(value) {
            this.model.AGBSAccepted = value.target.checked;
        },
        setDSGVO(value) {
            this.model.DSGVOAccepted = value.target.checked;
        },
        validate() {
            let form = this.$refs._form;
            this.error = false;
            this.errorMessage = null

            if (this.model.AGBSAccepted === false || this.model.DSGVOAccepted === false) {

                this.error = true;
                this.errorMessage = this.$t('HAS-TO-CHECK-AGB-DSGVO')

                return false;
            }

            this.error = false;
            this.errorMessage = '';


            if (form.validate().then(result => {
                if (result.valid) {
                    this.$emit('on-validated', true, this.model);
                    this.$emit('wizard-finished')
                    return Promise.resolve(true);
                } else
                    return Promise.resolve(false);
            })) ;

        }
    }
};
</script>
<style>
.help-text-label {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 40px;
    color: deepskyblue;
    font-weight: 600;
}

</style>
