import Controller from "../controller.js";
import {restUri} from "@/data/restPaths";
import {mutate} from "@/utils/mutate";


export default class NotificationController extends Controller {

    constructor(root) {
        super({
            root,
            path: {
                CUD: restUri.USER_NOTIFICATION_CUD,
                GET: restUri.GET_USER_NOTIFICATION
            }
        })


    }

    init() {
    }


    read() {
        const FkUser = this.store.state.User != null ? this.store.state.User.PkUser : null;
        return super.read({
            log: true,
            body: mutate({FkUser: FkUser})
        }).then(data => {
            if (!data.IsError) {
                return data;
            } else return data
        }).catch(result => {
            return result;
        });

    }

    save(notification = {}) {

        let body = {
            UserNotificationsList: notification
        }

        return super.update({
            log: true,
            body,
        }).then(data => {
            if (!data.IsError) {
                return this.read().then(result => {
                    return result;
                });
            } else {
                return data
            }
        }).catch(result => {
            return result;
        });

    }


}
