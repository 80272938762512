<template>
    <div class="section-heading">
        <span class="no-decoration breadcrumb-decorate-blue">
                <font-awesome-icon icon="fas fa-cog"/>
                {{ trans('MAIN-DATA') }}
            </span>
        <router-link to="/Config/MasterData/MasterDataOffice"
                     class="no-decoration breadcrumb-decorate-gray">
            <font-awesome-icon icon="fas fa-angle-right"/> &nbsp;
            <font-awesome-icon icon="fas fa-building"/>
            {{ trans('OFFICES') }}
        </router-link>
        <span class="no-decoration breadcrumb-decorate-yellow">
                <font-awesome-icon icon="fas fa-angle-right"/> &nbsp;
                <font-awesome-icon icon="fas fa-building"/>
                {{ trans('OFFICE') }} {{ GetOfficeName }}
            </span>
    </div>
    <div class="layout-px-spacing">
        <div class="row layout-spacing">
            <!-- Content -->
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 layout-top-spacing">
                <div class="my-search layout-spacing">
                    <div class="panel">
                        <div class="panel-body">
                            <div class="text-center user-info">
                                <img
                                    :src="selectedImage != null ? selectedImage :  require('@/assets/images/empty-profile.jpg')"
                                    alt="avatar" class="col-lg-12 col-md-12 col-sm-12 col-12 profile-picture-office"/>
                                <h5 style="font-weight:700;">{{ OfficeObj.OfficeName }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 layout-top-spacing">
                <div class="user-profile layout-spacing">
                    <div class="panel">
                        <div class="panel-body">
                            <div class="user-info-list">
                                <div class="">
                                    <ul class="contacts-block list-unstyled">
                                        <li class="contacts-block__item" v-if="GetAddress">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                style="height:18px;"
                                                class="feather feather-map-pin"
                                            >
                                                <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                                <circle cx="12" cy="10" r="3"></circle>
                                            </svg
                                            >
                                            {{ GetAddress }}
                                        </li>
                                        <li class="contacts-block__item" v-if="OfficeProfile.TraineePerYear">
                                            <font-awesome-icon style="height:18px;"
                                                               icon="fs fa-user"></font-awesome-icon>
                                            {{ trans('TRAINEE-PER-YEAR') }} {{ OfficeProfile.TraineePerYear }}
                                        </li>
                                        <li class="contacts-block__item" v-if="OfficeProfile.MemberCount">
                                            <font-awesome-icon style="height:18px;"
                                                               icon="fs fa-users"></font-awesome-icon>
                                            {{ trans('MEMBER-COUNT') }} {{ OfficeProfile.MemberCount }}
                                        </li>
                                        <li class="contacts-block__item" v-if="OfficeProfile.OfficeWebsite">
                                            <font-awesome-icon style="height:18px;"
                                                               icon="fs fa-globe"></font-awesome-icon>
                                            <a :href="OfficeProfile.OfficeWebsite"
                                               target="_blank">{{ OfficeProfile.OfficeWebsite }}</a>
                                        </li>

                                        <li class="contacts-block__item">
                                            <ul class="list-inline">
                                                <li class="list-inline-item"
                                                    v-if="OfficeProfile.FacebookProfile">
                                                    <div class="social-icon">
                                                        <a :href="OfficeProfile.FacebookProfile"
                                                           style="margin-top:3px;"
                                                        >
                                                            <font-awesome-icon style="height:18px;"
                                                                               icon="fa-brands fa-facebook"></font-awesome-icon>
                                                        </a>
                                                    </div>
                                                </li>
                                                <li class="list-inline-item"
                                                    v-if="OfficeProfile.XingProfile">
                                                    <div class="social-icon">
                                                        <a :href="OfficeProfile.XingProfile"
                                                           style="margin-top:3px;"
                                                        >
                                                            <font-awesome-icon style="height:18px;"
                                                                               icon="fa-brands fa-xing"></font-awesome-icon>
                                                        </a>
                                                    </div>
                                                </li>
                                                <li class="list-inline-item"
                                                    v-if="OfficeProfile.InstagramProfile">
                                                    <div class="social-icon">
                                                        <a :href="OfficeProfile.InstagramProfile"
                                                           style="margin-top:3px;"
                                                        >
                                                            <font-awesome-icon style="height:18px;"
                                                                               icon="fa-brands fa-instagram"></font-awesome-icon>
                                                        </a>

                                                    </div>
                                                </li>
                                                <li class="list-inline-item"
                                                    v-if="OfficeProfile.LinkedInProfile">
                                                    <div class="social-icon">
                                                        <a :href="OfficeProfile.LinkedInProfile"
                                                           style="margin-top:3px;"
                                                        >
                                                            <font-awesome-icon style="height:18px;"
                                                                               icon="fa-brands fa-linkedin"></font-awesome-icon>
                                                        </a>
                                                    </div>
                                                </li>
                                                <li class="list-inline-item"
                                                    v-if="OfficeProfile.TikTiokProfile">
                                                    <div class="social-icon">
                                                        <a :href="OfficeProfile.TikTiokProfile"
                                                           style="margin-top:3px;"
                                                        >
                                                            <font-awesome-icon style="height:18px;"
                                                                               icon="fa-brands fa-tiktok"></font-awesome-icon>
                                                        </a>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-7 col-sm-12 layout-top-spacing">
                <div class="my-search layout-spacing" v-if="IsSchool == false">
                    <div class="panel">
                        <div class="panel-body">
                            <h3 class="">{{ trans('OUR-SEARCH') }}</h3>
                            <div class="panel-body underline-content tabs">
                                <ul class="nav nav-tabs mb-3" id="lineTab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="underline-home-tab" data-bs-toggle="tab"
                                           href="#underline-home" role="tab" aria-controls="underline-home"
                                           aria-selected="true">
                                            {{ trans("VOCATIONAL-TRAINING") }}
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="underline-profile-tab" data-bs-toggle="tab"
                                           href="#underline-profile" role="tab" aria-controls="underline-profile"
                                           aria-selected="false"
                                        >
                                            {{ trans("DUAL-STUDY") }}
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="underline-contact-tab" data-bs-toggle="tab"
                                           href="#underline-contact" role="tab" aria-controls="underline-contact"
                                           aria-selected="false"
                                        >
                                            {{ trans("INTERN-SHIP") }}
                                        </a>
                                    </li>
                                </ul>

                                <div class="tab-content" id="lineTabContent-3">
                                    <div class="tab-pane fade show active" id="underline-home" role="tabpanel"
                                         aria-labelledby="underline-home-tab">
                                        <h5 style="font-weight:700;">{{ trans('JOB') }}</h5>
                                        <div class="bubble-div">
                                            <template v-for="(item,idx) in OfficeJobSelectionListVTJob" :key="idx">
                                                <span class="bubbles" v-if="item.JobId!=null">{{
                                                        GetJobText(item.JobId)
                                                    }}</span>
                                            </template>
                                            <span class="bubbles" v-if="OfficeJobSelectionListVTJob.length == 0">{{
                                                    trans('NO-DATA')
                                                }}</span>
                                        </div>
                                        <h5 style="font-weight:700;">{{ trans('JOB-FIELD') }}</h5>
                                        <div class="bubble-div">
                                            <template v-for="(item,idx) in OfficeJobSelectionListVTJobField"
                                                      :key="idx">
                                                <span class="bubbles" v-if="item.JobFieldId!=null">{{
                                                        GetJobFieldText(item.JobFieldId)
                                                    }}</span>
                                            </template>
                                            <span class="bubbles" v-if="OfficeJobSelectionListVTJobField.length == 0">{{
                                                    trans('NO-DATA')
                                                }}</span>
                                        </div>

                                    </div>
                                    <div class="tab-pane fade" id="underline-profile" role="tabpanel"
                                         aria-labelledby="underline-profile-tab">
                                        <h5 style="font-weight:700;">{{ trans('STUDY-FIELD') }}</h5>
                                        <div class="bubble-div">
                                            <template v-for="(item,idx) in OfficeJobSelectionListDS" :key="idx">
                                                <span class="bubbles" v-if="item.JobFieldId!=null">{{
                                                        GetStudyText(item.JobFieldId)
                                                    }}</span>
                                            </template>
                                            <span class="bubbles" v-if="OfficeJobSelectionListDS.length == 0">{{
                                                    trans('NO-DATA')
                                                }}</span>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="underline-contact" role="tabpanel"
                                         aria-labelledby="underline-contact-tab">
                                        <h5 style="font-weight:700;">{{ trans('JOB') }}</h5>
                                        <div class="bubble-div">
                                            <template v-for="(item,idx) in OfficeJobSelectionListISJob" :key="idx">
                                                <span class="bubbles" v-if="item.JobId!=null">{{
                                                        GetJobText(item.JobId)
                                                    }}</span>
                                            </template>
                                            <span class="bubbles" v-if="OfficeJobSelectionListISJob.length == 0">{{
                                                    trans('NO-DATA')
                                                }}</span>
                                        </div>
                                        <h5 style="font-weight:700;">{{ trans('JOB-FIELD') }}</h5>
                                        <div class="bubble-div">
                                            <template v-for="(item,idx) in OfficeJobSelectionListISJobField"
                                                      :key="idx">
                                                <span class="bubbles" v-if="item.JobFieldId!=null">{{
                                                        GetJobFieldText(item.JobFieldId)
                                                    }}</span>
                                            </template>
                                            <span class="bubbles" v-if="OfficeJobSelectionListISJobField.length == 0">{{
                                                    trans('NO-DATA')
                                                }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="bio layout-spacing">
                    <div class="panel">
                        <div class="panel-body">
                            <h3 class="">{{ trans('ABOUT-US') }}</h3>
                            <span class="popOverText"
                                  v-for="(line,lineNumber) of AboutUsText.split('\n')"
                                  v-bind:key="lineNumber">
                        {{ line }}<br/>
                            </span>
                            <h4 class="header-cv">{{ trans('OUR-BENEFITS') }}</h4>
                            <div class="bubble-div">
                                <template v-for="(item,idx) in OfficeBenefitList" :key="idx">
                                    <span class="bubbles">{{ item.Benefit }}</span>
                                </template>
                            </div>
                            <h4 class="header-cv">{{ trans('OUR-KEYWORDS') }}</h4>
                            <div class="bubble-div">
                                <template v-for="(item,idx) in OfficeKeywordList" :key="idx">
                                    <span class="bubbles">{{ item.Keyword }}</span>
                                </template>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="jobineo-questions layout-spacing">
                    <div class="panel">
                        <div class="panel-body">
                            <h3 class="">{{ trans('JOBINEO-QUESTIONS') }}</h3>
                            <div class="bio-skill-box">
                                <div class="row">
                                    <div class="col-12 col-xl-4 col-lg-12 mb-xl-5 mb-5">
                                        <div class="b-questions">

                                            <div class="">
                                                <font-awesome-icon icon="fa-file-text"
                                                                   v-if="Video1==null"></font-awesome-icon>
                                                <font-awesome-icon icon="fa-camera"
                                                                   v-if="Video1!=null"></font-awesome-icon>
                                                <p>{{
                                                        trans(GetJobineoQuestion(OfficeProfile.JobineoQuestion1))
                                                    }}</p>
                                                <p>-</p>
                                                <p v-if="OfficeProfile.JobineoAnswer1">
                                                    {{ OfficeProfile.JobineoAnswer1 }}
                                                </p>
                                                <p v-show="Video1!=null">
                                                    <video class="videoPlayer" id="video-preview1" controls
                                                           controlsList="nodownload">
                                                    </video>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 col-xl-4 col-lg-12 mb-xl-5 mb-5">
                                        <div class="b-questions">

                                            <div class="">
                                                <font-awesome-icon icon="fa-file-text"
                                                                   v-if="Video2==null"></font-awesome-icon>
                                                <font-awesome-icon icon="fa-camera"
                                                                   v-if="Video2!=null"></font-awesome-icon>
                                                <p>{{
                                                        trans(GetJobineoQuestion(OfficeProfile.JobineoQuestion2))
                                                    }}</p>
                                                <p>-</p>
                                                <p v-if="OfficeProfile.JobineoAnswer2">
                                                    {{ OfficeProfile.JobineoAnswer2 }}
                                                </p>
                                                <p v-show="Video2!=null">
                                                    <video class="videoPlayer" id="video-preview2" controls
                                                           controlsList="nodownload">
                                                    </video>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 col-xl-4 col-lg-12 mb-xl-0 mb-5">
                                        <div class="b-questions">
                                            <span class="">
                                                <font-awesome-icon icon="fa-file-text"
                                                                   v-if="Video3==null"></font-awesome-icon>
                                                <font-awesome-icon icon="fa-camera"
                                                                   v-if="Video3!=null"></font-awesome-icon>
                                                <p>{{
                                                        trans(GetJobineoQuestion(OfficeProfile.JobineoQuestion3))
                                                    }}</p>
                                                <p>-</p>
                                                <p v-show="OfficeProfile.JobineoAnswer3">
                                                    {{ OfficeProfile.JobineoAnswer3 }}
                                                </p>
                                                <p v-if="Video3!=null">
                                                    <video class="videoPlayer" id="video-preview3" controls
                                                           controlsList="nodownload">
                                                    </video>
                                                </p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12" v-if="PaginatedList!= null && PaginatedList.length >0">
                    <div class="row layout-top-spacing">
                        <h3 class="">{{ trans('JOB-ADS') }}</h3>
                        <div id="basic" class="col-lg-12 col-sm-12 col-12 layout-spacing">
                            <div class="custom-table">
                                <template v-for="(item, index) in PaginatedList" :key="index">
                                    <div class="statbox panel box box-shadow">

                                        <div class="tree-header">

                                            <div class="row">
                                                <div class="col-lg-10">
                                                    <div class="row">
                                                        <div class="form-group col-lg-12 ">
                                                            <label class="font-size-14 input-label"
                                                                   style="cursor: pointer;margin-left:15px;"
                                                                   @click.prevent="openProfileJobAd(item)">
                                                                <font-awesome-icon
                                                                    icon="fas fa-clipboard"
                                                                    style="margin-right: 20px;"></font-awesome-icon>
                                                                {{ GetJobTypesJobAd(item) }} | {{
                                                                    GetJobNamesJobAd(item)
                                                                }} - {{ item.AdName }}


                                                            </label>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </template>

                                <div class="paginating-container pagination-solid flex-column align-items-center mt-4">
                                    <ul role="menubar" aria-disabled="false" aria-label="Pagination"
                                        class="pagination rounded b-pagination">
                                        <template v-for="(item, index) in CountPages" :key="index">
                                            <li role="presentation" class="page-item"
                                                :class="(CurrentPageIndex===(item.PageNo -1)?' active':'')">
                                                <button role="menuitemradio" @click="nextPage(item.PageNo)"
                                                        type="button"
                                                        :aria-checked="CurrentPageIndex===(item.PageNo -1)"
                                                        :aria-posinset="item.PageNo" :aria-setsize="CountPages.length"
                                                        tabindex="-1" class="page-link">
                                                    {{ item.PageNo }}
                                                </button>
                                            </li>
                                        </template>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import '@/scss/jobineo/sass/scrollspyNav.scss';
import '@/scss/jobineo/sass/users/user-profile.scss';
import '@/scss/jobineo/sass/components/tabs-accordian/custom-tabs.scss';
import store from "@/store";
import lodash from "lodash";
import moment from "moment";
import {cutDateString, getDateStringMonthYear} from "@/utils/convertDateTime";
import {JOB_TYPES} from "@/data/jobTypes";
import {MatchingController} from "@/controllers/matching.controller";
import {OfficeController} from "@/controllers/office.controller";
import WaitingButton from "@/components/WaitingButton.vue";
import router from "@/router";
import {JobAdController} from "@/controllers/jobad.controller";

export default {
    name: "OfficeProfileView.vue",
    components: {},
    data() {
        return {
            controller: new OfficeController(this),
            matchingController: new MatchingController(this),
            jobAdController: new JobAdController(this),
            CurrentPageIndex: 0,
            selectedImage: null,
            ProfileObj: null,
            Video1: null,
            Video2: null,
            Video3: null,
            FkCompany: null,
            FkOffice: null,
        }
    },
    computed: {
        AboutUsText() {
            return this.OfficeProfile != null && this.OfficeProfile.AboutUs != null ? this.OfficeProfile.AboutUs : ""
        },
        IsSchool() {
            return this.ProfileObj != null && this.ProfileObj.Company != null && this.ProfileObj.Company.IsSchool == true ? true : false;
        },
        PaginatedList() {
            if (this.JobAdList.length <= 0)
                return [];
            return this.JobAdList.slice(this.indexStart, this.indexEnd);
        },
        CountPages() {
            if (this.JobAdList == null || this.JobAdList.length <= 0)
                return [];

            let pages = [];
            for (let t = 0, l = Math.ceil(this.JobAdList.length / 5); t < l; t++) {
                pages.push({PageNo: t + 1})
            }
            return pages;
        },
        GetOfficeName() {
            return this.OfficeObj != null ? this.OfficeObj.OfficeName : ''
        },
        indexStart() {
            return (this.CurrentPageIndex) * 5;
        },
        indexEnd() {
            return (this.indexStart) + 5;
        },
        OfficeObj() {
            return this.ProfileObj != null ? this.ProfileObj : {}
        },
        JobFieldIdList() {
            return this.$store.state.Lookups.JobFieldsLookup != null && Array.isArray(this.$store.state.Lookups.JobFieldsLookup) ? this.$store.state.Lookups.JobFieldsLookup : [];
        },
        JobIdList() {
            return this.$store.state.Lookups.JobsLookup != null && Array.isArray(this.$store.state.Lookups.JobsLookup) ? this.$store.state.Lookups.JobsLookup : [];
        },
        StudyIdList() {
            return this.$store.state.Lookups.StudyFieldsLookup != null && Array.isArray(this.$store.state.Lookups.StudyFieldsLookup) ? this.$store.state.Lookups.StudyFieldsLookup : [];
        },
        OfficeProfile() {
            return this.OfficeObj != null && this.OfficeObj.OfficeProfile != null ? this.OfficeObj.OfficeProfile : {}
        },
        OfficeBenefitList() {
            return this.OfficeObj != null && this.OfficeObj.OfficeBenefitList != null && Array.isArray(this.OfficeObj.OfficeBenefitList) && this.OfficeObj.OfficeBenefitList.length > 0 ? this.OfficeObj.OfficeBenefitList : []
        },
        OfficeContact() {
            return this.OfficeObj != null && this.OfficeObj.OfficeContactList != null && Array.isArray(this.OfficeObj.OfficeContactList) && this.OfficeObj.OfficeContactList.length > 0 ? this.OfficeObj.OfficeContactList[0] : {}
        },
        OfficeKeywordList() {
            return this.OfficeObj != null && this.OfficeObj.OfficeKeywordList != null && Array.isArray(this.OfficeObj.OfficeKeywordList) && this.OfficeObj.OfficeKeywordList.length > 0 ? this.OfficeObj.OfficeKeywordList : []
        },
        JobAdList() {
            return this.OfficeObj != null && this.OfficeObj.JobAdList != null && Array.isArray(this.OfficeObj.JobAdList) && this.OfficeObj.JobAdList.length > 0 ? this.OfficeObj.JobAdList : []
        },
        OfficeJobSelectionListDS() {
            if (this.OfficeObj != null && this.OfficeObj.JobSelectionList != null && Array.isArray(this.OfficeObj.JobSelectionList) && this.OfficeObj.JobSelectionList.length > 0) {
                let items = lodash.filter(this.OfficeObj.JobSelectionList, x => x.JobType == JOB_TYPES.DUAL_STUDY)
                if (items != null && items.length > 0) {
                    return items;
                } else
                    return [];
            } else {
                return [];
            }
        },
        OfficeJobSelectionListISJob() {
            if (this.OfficeObj != null && this.OfficeObj.JobSelectionList != null && Array.isArray(this.OfficeObj.JobSelectionList) && this.OfficeObj.JobSelectionList.length > 0) {
                let items = lodash.filter(this.OfficeObj.JobSelectionList, x => x.JobType == JOB_TYPES.INTERN_SHIP && x.JobId != null)
                if (items != null && items.length > 0) {
                    return items;
                } else
                    return [];
            } else {
                return [];
            }
        },
        OfficeJobSelectionListISJobField() {
            if (this.OfficeObj != null && this.OfficeObj.JobSelectionList != null && Array.isArray(this.OfficeObj.JobSelectionList) && this.OfficeObj.JobSelectionList.length > 0) {
                let items = lodash.filter(this.OfficeObj.JobSelectionList, x => x.JobType == JOB_TYPES.INTERN_SHIP && x.JobFieldId != null)
                if (items != null && items.length > 0) {
                    return items;
                } else
                    return [];
            } else {
                return [];
            }
        },
        OfficeJobSelectionListVTJobField() {
            if (this.OfficeObj != null && this.OfficeObj.JobSelectionList != null && Array.isArray(this.OfficeObj.JobSelectionList) && this.OfficeObj.JobSelectionList.length > 0) {
                let items = lodash.filter(this.OfficeObj.JobSelectionList, x => x.JobType == JOB_TYPES.VOCATIONAL_TRAINING && x.JobFieldId != null)
                if (items != null && items.length > 0) {
                    return items;
                } else
                    return [];
            } else {
                return [];
            }
        },
        OfficeJobSelectionListVTJob() {
            if (this.OfficeObj != null && this.OfficeObj.JobSelectionList != null && Array.isArray(this.OfficeObj.JobSelectionList) && this.OfficeObj.JobSelectionList.length > 0) {
                let items = lodash.filter(this.OfficeObj.JobSelectionList, x => x.JobType == JOB_TYPES.VOCATIONAL_TRAINING && x.JobId != null)
                if (items != null && items.length > 0) {
                    return items;
                } else
                    return [];
            } else {
                return [];
            }
        },
        GetAddress() {
            return this.OfficeObj.PostCode != null ? (this.OfficeObj.PostCode + ' ' + this.OfficeObj.City) : ''
        },
    },
    methods: {
        GetDate(value) {
            if (value === null)
                return '';

            return cutDateString(value)
        },
        nextPage(no) {
            this.CurrentPageIndex = no - 1;
        },
        GetJobineoQuestion(value) {
            let list = this.$store.state.Lookups.CompanyQuestionsLookup != null && Array.isArray(this.$store.state.Lookups.CompanyQuestionsLookup) ? this.$store.state.Lookups.CompanyQuestionsLookup : []
            let items = lodash.filter(list, x => x.DataKey == value);
            if (items != null && items.length > 0) {
                return items[0].DataValue;
            } else {
                return ''
            }
        },
        previewVideo1() {

            let video = document.getElementById('video-preview1');
            if (video != null) {

                let reader = new FileReader();

                reader.readAsDataURL(this.Video1);
                reader.addEventListener('load', function () {
                    video.src = reader.result;
                });

            }
        },
        previewVideo2() {
            let video = document.getElementById('video-preview2');
            if (video != null) {
                let reader = new FileReader();

                reader.readAsDataURL(this.Video2);
                reader.addEventListener('load', function () {
                    video.src = reader.result;
                });
            }
        },
        previewVideo3() {
            let video = document.getElementById('video-preview3');
            if (video != null) {
                let reader = new FileReader();

                reader.readAsDataURL(this.Video3);
                reader.addEventListener('load', function () {
                    video.src = reader.result;
                });
            }
        },
        GetImageJob(item) {

            this.jobAdController.GetProfilePictureJobAdPublic(item.FkCompany, item.PkJobAd).then(result => {

                if (result.data != null && result.data.size > 0)
                    this.$refs["picJob_" + item.PkJobAd][0].src = URL.createObjectURL(new Blob([result.data]));
                else {
                    this.$refs["picJob_" + item.PkJobAd][0].src = require('@/assets/images/empty-profile.jpg');
                }

            })

        },
        openProfileJobAd(row) {
            router.push("/JobAdProfileView/" + row.FkOffice + "/" + row.PkJobAd);
        },
        GetStudyText(id) {
            let items = lodash.filter(this.$store.state.Lookups.StudyFieldsLookup, x => x.DataKey == id);
            if (items != null && items.length > 0)
                return items[0].DataValue
            else return ''
        },
        GetJobFieldText(id) {
            let items = lodash.filter(this.$store.state.Lookups.JobFieldsLookup, x => x.DataKey == id);
            if (items != null && items.length > 0)
                return items[0].DataValue
            else return ''
        },
        GetJobText(id) {
            let items = lodash.filter(this.$store.state.Lookups.JobsLookup, x => x.DataKey == id);
            if (items != null && items.length > 0)
                return items[0].DataValue
            else return ''
        },
        GetJobTypesJobAd(item) {
            let text = "";

            if (item.AdType == JOB_TYPES.MINI_JOB) {
                text = this.trans('MINI-JOB') + ' | '
            } else if (item.AdType == JOB_TYPES.VOCATIONAL_TRAINING) {
                text = this.trans('VOCATIONAL-TRAINING') + ' | '
            } else if (item.AdType == JOB_TYPES.INTERN_SHIP) {
                text = this.trans('INTERN-SHIP') + ' | '
            } else if (item.AdType == JOB_TYPES.CAREER_ENTRY) {
                text = this.trans('CAREER-ENTRY') + ' | '
            } else if (item.AdType == JOB_TYPES.DUAL_STUDY) {
                text = this.trans('DUAL-STUDY') + ' | '
            }
            return text;
        },
        GetJobNamesJobAd(item) {
            let text = "";

            let items = lodash.filter(this.$store.state.Lookups.JobFieldsLookup, x => x.DataKey == item.JobFieldId);
            if (items != null && items.length > 0)
                text = text + items[0].DataValue + " | ";

            items = lodash.filter(this.$store.state.Lookups.JobsLookup, x => x.DataKey == item.JobId);
            if (items != null && items.length > 0)
                text = text + items[0].DataValue + " | ";

            if (text.length > 0 && text.indexOf('|') > 0) {
                text = text.slice(0, text.length - 3);
            }

            return text;

        },
        init() {
            this.controller.readPublicView(this.FkCompany, this.FkOffice).then(response => {
                this.ProfileObj = response.OfficeProfile;

                if (this.ProfileObj != null) {

                    this.controller.GetProfilePictureOfficePublic(this.FkCompany, this.FkOffice).then(result => {

                        if (result.data != null && result.data.size > 0)
                            this.selectedImage = URL.createObjectURL(new Blob([result.data]));

                        this.controller.GetVideoFileOffice(this.FkCompany, this.FkOffice, 1).then(result => {
                            if (result != null && result.size > 0) {
                                this.Video1 = result;
                                this.previewVideo1();
                            }
                        })
                        this.controller.GetVideoFileOffice(this.FkCompany, this.FkOffice, 2).then(result => {
                            if (result != null && result.size > 0) {
                                this.Video2 = result;
                                this.previewVideo2();
                            }
                        })
                        this.controller.GetVideoFileOffice(this.FkCompany, this.FkOffice, 3).then(result => {
                            if (result != null && result.size > 0) {
                                this.Video3 = result;
                                this.previewVideo3();
                            }
                        })
                    })
                }
            })
        }
    },
    activated() {
        this.FkCompany = store.state.FkCompany;
        this.FkOffice = this.$route.params.FkOffice;
        if (this.FkCompany == null || this.FkOffice == null) {
            return;
        }
        this.init();
    }
}
</script>

<style scoped lang="scss">

.td-bio {
    color: #000000;
}

.td-width {
    min-width: 200px;
}

.dark {
    .td-bio {
        color: #ffffff;
    }

}

.purple {
    .td-bio {
        color: #ffffff;
    }

}

.borderline {
    border: 1px solid #9acccb;
    padding: 5px;
    border-radius: 26px;
    color: #9acccb !important;
}

.header-cv {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 800;
    margin-top: 30px;
}


.videoPlayer {
    max-height: 200px;
    max-width: 320px;
}

.headerIcons {
    display: flex;
}

.iconMargin {
    margin-left: 5px;
}

.popOverText {
    color: white;
    font-size: 14px;
    font-weight: 500;
}

.dark {
    --popper-theme-background-color: #333333;
    --popper-theme-background-color-hover: #333333;
    --popper-theme-text-color: white;
    --popper-theme-border-width: 0px;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 15px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

</style>
