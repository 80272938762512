


export const crudType = () => ({
    type: 'READ',
    path: {
        CUD: null,
        GET: null
    },
    commit: null,
    log: false,
    applyTrackingStateToBodyRoot: false,
    ignoreTackingStateIn: [],
    body: {}

});
