import store from './store.js';
import hash from 'object-hash';
import lodash from "lodash";


export const CAN = {
    READ: 'CanRead',
    CREATE: 'CanCreate',
    MODIFY: 'CanModify',
    DELETE: 'CanDelete',
    EXECUTE: 'CanExecute',
    ACTIVATE: 'CanActivate',
    DEACTIVATE: 'CanDeactivate'
}


export function isLicensed(compareLicense = [], and = false) {

    let compare = Array.isArray(compareLicense) ? compareLicense : [compareLicense],
        t = 0, l = compare.length;

    if (compare.length <= 0)
        return true;


    for (; t < l; t++) {


        let licenseType = compare[t];
        let results = lodash.filter(store.state.CompanyData.CompanyLicenseList, x => x.LicenseType == licenseType);
        if (results != null && results.length > 0 && !and)
            return true;
        else if (and && (results == null || results.length <= 0))
            return false;
    }

    return and ? true : false;
}


export function auth(permissionObjects = [], cans = [], and = false) {

    if (store.state.User != null && store.state.User.IsCompAdmin) {
        return true;
    }

    if (Array.isArray(permissionObjects) && permissionObjects.length <= 0)
        return true;

    let permissionObjectsArray = Array.isArray(permissionObjects) ? permissionObjects : [(permissionObjects + '').trim()],
        cansArray = Array.isArray(cans) ? cans : [cans],
        hashObject = {permissionObjectsArray, cansArray, and},
        cacheIndex = hash(hashObject);
    if (permissionCacheLookUp[cacheIndex] != null)
        return permissionCacheLookUp[cacheIndex];
    let value = and ? true : false, oIdx = 0, pol = permissionObjectsArray.length, cIdx = 0, cl = cansArray.length;

    for (; oIdx < pol; oIdx++) {
        let FkPermissionObject = permissionObjectsArray[oIdx];


        if (permissionsLookUp[FkPermissionObject] == null) {
            if (and) {
                value = false;
                break;
            }
            value = value || false;
            continue;
        }


        let permission = permissionsLookUp[FkPermissionObject],
            canValue = and ? true : false;
        //

        if (!cl) {
            canValue = !and ?
                (
                    permission[CAN.CREATE] ||
                    permission[CAN.READ] ||
                    permission[CAN.EXECUTE] ||
                    permission[CAN.DELETE] ||
                    permission[CAN.MODIFY] ||
                    permission[CAN.ACTIVATE] ||
                    permission[CAN.DEACTIVATE]
                ) :
                (
                    permission[CAN.CREATE] &&
                    permission[CAN.READ] &&
                    permission[CAN.EXECUTE] &&
                    permission[CAN.DELETE] &&
                    permission[CAN.MODIFY] &&
                    permission[CAN.ACTIVATE] &&
                    permission[CAN.DEACTIVATE]
                );
        } else {
            for (cIdx = 0; cIdx < cl; cIdx++) {
                let canProp = cansArray[cIdx],
                    computedCanPropValue = !!permission[canProp];

                canValue = and ? canValue && computedCanPropValue : canValue || computedCanPropValue;
            }
        }

        value = and ? value && canValue : value || canValue;

    }

    permissionCacheLookUp[cacheIndex] = value;


    return value;


}



