<template>
    <div class="layout-px-spacing">
        <div class="account-settings-container layout-top-spacing">
            <div class="account-content">
                <div class="scrollspy-example" data-spy="scroll" data-target="#account-settings-scroll"
                     data-offset="-100">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                            <Form id="general-info" class="section general-info" ref="valForm"
                                  :validationSchema="validationSchema"
                                  @submit="validate">
                                <div class="info">
                                    <h6 class="">{{ trans('PROFILE-DATA') }}</h6>
                                    <div class="row">
                                        <div class="col-lg-11 mx-auto">
                                            <div class="row">
                                                <div class="col-xl-2 col-lg-12 col-md-4">
                                                    <div class="upload mt-4 pe-md-4">
                                                        <input ref="fl_profile" type="file" class="d-none"
                                                               accept="image/*" @change="change_file"/>
                                                        <img
                                                            :src="selectedImage != null ? selectedImage :  require('@/assets/images/empty-profile.jpg')"
                                                            alt="profile" class="profile-preview profile-picture"
                                                            @click="$refs.fl_profile.click()"/>
                                                        <p><a href="javascript:;" class="mt-2"
                                                              @click="$refs.fl_profile.click()">{{
                                                                trans('UPLOAD-PICTURE')
                                                            }}</a></p>
                                                    </div>
                                                </div>
                                                <div class="col-xl-10 col-lg-12 col-md-8 mt-md-0 mt-4">
                                                    <div class="form">
                                                        <div class="row">
                                                            <div class="col-sm-3">
                                                                <div class="form-group">
                                                                    <base-datetime
                                                                        :label="trans('BIRTHDATE')"
                                                                        :required="true"
                                                                        obj-name="BirthDate"
                                                                        name="BirthDate"
                                                                        :max-date="new Date()"
                                                                        :value="ProfileObj != null ? ProfileObj.BirthDate : null"
                                                                        :onchange="BirthDateChange"
                                                                        :place-holder="trans('PLEASE-SELECT-DATE')"
                                                                        date-time-type="date"
                                                                        :date-time-format="DateTimeFormat"
                                                                        :date-time-format-value="DateTimeFormatValue"
                                                                    ></base-datetime>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-3">
                                                                <div class="form-group">
                                                                    <base-datetime
                                                                        :label="trans('AVAILABLE-FROM')"
                                                                        obj-name="AvailableFrom"
                                                                        name="AvailableFrom"
                                                                        :value="ProfileObj != null ? ProfileObj.AvailableFrom : null"
                                                                        :onchange="AvailableFromChange"
                                                                        :place-holder="trans('PLEASE-SELECT-DATE')"
                                                                        date-time-type="date"
                                                                        :date-time-format="DateTimeFormat"
                                                                        :date-time-format-value="DateTimeFormatValue"
                                                                    ></base-datetime>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row margin-top-15">
                                                            <div class="col-sm-3">
                                                                <div class="form-group">
                                                                    <div
                                                                        class="checkbox-outline-primary custom-control custom-checkbox">
                                                                        <base-checkbox
                                                                            id="CarAvailable"
                                                                            :required="false"
                                                                            class="custom-control-input"
                                                                            :checked="ProfileObj.CarAvailable"
                                                                            @input="setCarAvailable"
                                                                            :label="trans('CAR-AVAILABLE')"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-3">
                                                                <div class="form-group">
                                                                    <div
                                                                        class="checkbox-outline-primary custom-control custom-checkbox">
                                                                        <base-checkbox
                                                                            id="ReadyToMove"
                                                                            :required="false"
                                                                            class="custom-control-input"
                                                                            :checked="ProfileObj.ReadyToMove"
                                                                            @input="setReadyToMove"
                                                                            :label="trans('READY-TO-MOVE')"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row margin-top-15">
                                                            <div class="col-sm-3">
                                                                <div class="form-group">
                                                                    <div
                                                                        class="checkbox-outline-primary custom-control custom-checkbox">
                                                                        <base-checkbox
                                                                            id="ChangeCompany"
                                                                            :required="false"
                                                                            class="custom-control-input"
                                                                            :checked="ProfileObj.ChangeCompany"
                                                                            @input="setChangeCompany"
                                                                            :label="trans('CHANGE-COMPANY')"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-3">
                                                                <div class="form-group">
                                                                    <div
                                                                        class="checkbox-outline-primary custom-control custom-checkbox">
                                                                        <base-checkbox
                                                                            id="Rehearsal"
                                                                            :required="false"
                                                                            class="custom-control-input"
                                                                            :checked="ProfileObj.Rehearsal"
                                                                            @input="setRehearsal"
                                                                            :label="trans('REHEARSAL')"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row margin-top-15">
                                                            <div class="col-sm-3">
                                                                <div class="form-group">
                                                                    <div id="skill" class="section skill">
                                                                        <div class="info">
                                                                            <div class="row progress-bar-section">
                                                                                <div class="col-md-11 mx-auto">
                                                                                    <div
                                                                                        class="custom-progress top-right progress-up">

                                                                                        <p class="skill-name">
                                                                                            {{ trans('SEARCH-RADIUS') }}
                                                                                        </p>
                                                                                        <input type="range" min="0"
                                                                                               max="500"
                                                                                               class="form-range progress-range-counter"
                                                                                               v-model="ProfileObj.Radius"/>
                                                                                        <div class="range-count">
                                                        <span class="range-count-number">{{
                                                                ProfileObj.Radius
                                                            }}</span> <span
                                                                                            class="range-count-unit">Km</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                            <form id="about" class="section about">
                                <div class="info">
                                    <h5 class="">{{ trans('ABOUT-ME') }}</h5>
                                    <div class="row">
                                        <div class="col-md-11 mx-auto">
                                            <div class="form-group">
                                                <base-text-area
                                                    v-model="ProfileObj.StudentPersonality.AboutMe"
                                                    :required="false"
                                                    obj-name="AboutMe"
                                                    :label="trans('ABOUT-ME')"
                                                    attached-class="form-control"
                                                    placeholder="..."
                                                    name="AboutMe"
                                                    ref="AboutMe"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>


                        <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                            <form id="contact" class="section contact">
                                <div class="info">
                                    <h5 class="">{{ trans('CONTACT') }}</h5>
                                    <div class="row">
                                        <div class="col-md-11 mx-auto">
                                            <div class="row">
                                                <div class="form-group col-lg-5">
                                                    <base-input ref="Street"
                                                                v-model="ProfileObj.Street"
                                                                :label="trans('STREET')"
                                                                type="text"
                                                                :required="true"
                                                                :max-length="50"
                                                                attached-class="form-control"
                                                                obj-name="Street" placeholder="..."/>
                                                </div>
                                                <div class="form-group col-lg-2">
                                                    <base-input ref="StreetNr"
                                                                v-model="ProfileObj.StreetNr"
                                                                type="text"
                                                                :required="true"
                                                                :max-length="10"
                                                                :label="trans('STREET-NR')"
                                                                attached-class="form-control"
                                                                obj-name="StreetNr" placeholder="..."/>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-lg-5">
                                                    <base-input ref="Addition"
                                                                v-model="ProfileObj.AddressAddition"
                                                                :label="trans('ADDITION')"
                                                                type="text"
                                                                :max-length="100"
                                                                attached-class="form-control"
                                                                obj-name="Addition" placeholder="..."/>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-lg-2">
                                                    <base-input ref="PostCode"
                                                                v-model="ProfileObj.PostCode"
                                                                :label="trans('POSTCODE')"
                                                                type="text"
                                                                :required="true"

                                                                :max-length="10"
                                                                attached-class="form-control"
                                                                obj-name="PostCode" placeholder="..."/>
                                                </div>
                                                <div class="form-group col-lg-5">
                                                    <base-input ref="City"
                                                                v-model="ProfileObj.City"
                                                                type="text"
                                                                :required="true"
                                                                :max-length="50"
                                                                :label="trans('CITY')"
                                                                attached-class="form-control"
                                                                obj-name="City" placeholder="..."/>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-lg-3">
                                                    <data-dropdown ref="Country"
                                                                   v-model="ProfileObj.Country"
                                                                   :label="trans('COUNTRY')"
                                                                   :required="true"
                                                                   :no-check-icons="true"
                                                                   :items="CountriesList"
                                                                   :null-value-text="trans('SELECT-COUNTRY')"
                                                                   :has-null-value="true"
                                                                   attached-class="form-control"
                                                                   name="Country"/>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-lg-3">
                                                    <base-input ref="Phone"
                                                                v-model="ProfileObj.Phone"
                                                                :label="trans('PHONE')"
                                                                type="text"
                                                                :max-length="10"
                                                                attached-class="form-control"
                                                                obj-name="Phone" placeholder="..."/>
                                                </div>
                                                <div class="form-group col-lg-3">
                                                    <base-input ref="Mobile"
                                                                v-model="ProfileObj.Mobile"
                                                                type="text"
                                                                :max-length="50"
                                                                :label="trans('MOBILE')"
                                                                attached-class="form-control"
                                                                obj-name="Mobile" placeholder="..."/>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                            <form id="contact" class="section contact">
                                <div class="info">
                                    <h5 class="">{{ trans('SCHOOL-INFORMATION') }}</h5>
                                    <div class="row">
                                        <div class="col-md-11 mx-auto">
                                            <div class="row">
                                                <div class="form-group col-lg-3">
                                                    <data-dropdown ref="HighestGraduation"
                                                                   v-model="ProfileObj.StudentGraduation.HighestGraduation"
                                                                   :label="trans('GRADUATION-HIGHEST')"
                                                                   :no-check-icons="true"
                                                                   :required="true"
                                                                   :items="HighestGraduationList"
                                                                   :null-value-text="trans('SELECT-GRADUATION-HIGHEST')"
                                                                   :has-null-value="true"
                                                                   attached-class="form-control"
                                                                   name="HighestGraduation"/>
                                                </div>
                                                <div class="form-group col-lg-3">
                                                    <base-input ref="Average"
                                                                v-model="ProfileObj.StudentGraduation.Average"
                                                                :label="trans('GRADUATION-AVERAGE')"
                                                                type="number"
                                                                :max="6"
                                                                :step="0.1"
                                                                :min="1"
                                                                attached-class="form-control"
                                                                obj-name="Average" placeholder="..."/>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-lg-3">
                                                    <base-datetime
                                                        :label="trans('GRADUATION-END-DATE')"
                                                        obj-name="GraduationEndDate"
                                                        name="GraduationEndDate"
                                                        :value="ProfileObj != null && ProfileObj.StudentGraduation != null ? ProfileObj.StudentGraduation.EndDate : null"
                                                        :onchange="GraduationEndDateChange"
                                                        :place-holder="trans('PLEASE-SELECT-DATE')"
                                                        date-time-type="date"
                                                        :date-time-format="DateTimeFormat"
                                                        :date-time-format-value="DateTimeFormatValue"
                                                    ></base-datetime>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                            <form id="contact" class="section contact">
                                <div class="info">
                                    <h5 class="">{{ trans('JOBINEO-QUESTIONS') }}</h5>
                                    <div class="row">
                                        <div class="col-md-11 mx-auto">
                                            <div class="row">
                                                <div class="form-group col-lg-6">
                                                    <data-dropdown ref="JobineoQuestion1"
                                                                   v-model="ProfileObj.StudentPersonality.JobineoQuestion1"
                                                                   :label="trans('JOBINEO-QUESTION-1')"
                                                                   :no-check-icons="true"
                                                                   :items="JobineoQuestionList"
                                                                   :null-value-text="trans('SELECT-JOBINEO-QUESTION')"
                                                                   :has-null-value="true"
                                                                   attached-class="form-control"
                                                                   name="JobineoQuestion1"/>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-lg-6">
                                                    <base-text-area
                                                        v-model="ProfileObj.StudentPersonality.JobineoAnswer1"
                                                        obj-name="JobineoAnswer1"
                                                        :label="trans('JOBINEO-ANSWER-1')"
                                                        attached-class="form-control"
                                                        placeholder="..."
                                                        name="JobineoAnswer1"
                                                        ref="JobineoAnswer1"/>

                                                </div>
                                                <div class="form-group col-lg-6">
                                                    <input ref="jobineoQAVideo1" type="file" class="d-none"
                                                           accept="video/*" @change="uploadVideo1"/>
                                                    <video class="videoPlayer" id="video-preview1" controls
                                                           controlsList="nodownload">
                                                    </video>
                                                    <p>
                                                        <base-button
                                                            native-type="button"
                                                            class="btn btn-yellow  margin-top-30"
                                                            @click="$refs.jobineoQAVideo1.click()"
                                                        >
                                                            <font-awesome-icon icon="fas fa-plus-circle"/>
                                                            &nbsp;{{ $t('UPLOAD-VIDEO') }}
                                                        </base-button>
                                                        <base-button
                                                            native-type="button"
                                                            class="btn btn-yellow  margin-top-30"
                                                            @click="removeVideo1"
                                                        >
                                                            <font-awesome-icon icon="fas fa-trash"/>
                                                            &nbsp;{{ $t('REMOVE-VIDEO') }}
                                                        </base-button>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="clearfix underlined-b-10 margin-top-15"></div>
                                            <div class="row">
                                                <div class="form-group col-lg-6">
                                                    <data-dropdown ref="JobineoQuestion2"
                                                                   v-model="ProfileObj.StudentPersonality.JobineoQuestion2"
                                                                   :label="trans('JOBINEO-QUESTION-2')"
                                                                   :no-check-icons="true"

                                                                   :items="JobineoQuestionList"
                                                                   :null-value-text="trans('SELECT-JOBINEO-QUESTION')"
                                                                   :has-null-value="true"
                                                                   attached-class="form-control"
                                                                   name="JobineoQuestion2"/>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-lg-6">
                                                    <base-text-area
                                                        v-model="ProfileObj.StudentPersonality.JobineoAnswer2"
                                                        obj-name="JobineoAnswer2"
                                                        :label="trans('JOBINEO-ANSWER-2')"
                                                        attached-class="form-control"
                                                        placeholder="..."
                                                        name="JobineoAnswer2"
                                                        ref="JobineoAnswer2"/>

                                                </div>
                                                <div class="form-group col-lg-6">
                                                    <input ref="jobineoQAVideo2" type="file" class="d-none"
                                                           accept="video/*" @change="uploadVideo2"/>
                                                    <video class="videoPlayer" id="video-preview2" controls
                                                           controlsList="nodownload"/>
                                                    <p>
                                                        <base-button
                                                            native-type="button"
                                                            class="btn btn-yellow  margin-top-30"
                                                            @click="$refs.jobineoQAVideo2.click()"
                                                        >
                                                            <font-awesome-icon icon="fas fa-plus-circle"/>
                                                            &nbsp;{{ $t('UPLOAD-VIDEO') }}
                                                        </base-button>
                                                        <base-button
                                                            native-type="button"
                                                            class="btn btn-yellow  margin-top-30"
                                                            @click="removeVideo2"
                                                        >
                                                            <font-awesome-icon icon="fas fa-trash"/>
                                                            &nbsp;{{ $t('REMOVE-VIDEO') }}
                                                        </base-button>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="clearfix underlined-b-10 margin-top-15"></div>
                                            <div class="row">
                                                <div class="form-group col-lg-6">
                                                    <data-dropdown ref="JobineoQuestion3"
                                                                   v-model="ProfileObj.StudentPersonality.JobineoQuestion3"
                                                                   :label="trans('JOBINEO-QUESTION-3')"
                                                                   :no-check-icons="true"
                                                                   :items="JobineoQuestionList"
                                                                   :null-value-text="trans('SELECT-JOBINEO-QUESTION')"
                                                                   :has-null-value="true"
                                                                   attached-class="form-control"
                                                                   name="JobineoQuestion3"/>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-lg-6">
                                                    <base-text-area
                                                        v-model="ProfileObj.StudentPersonality.JobineoAnswer3"
                                                        obj-name="JobineoAnswer3"
                                                        :label="trans('JOBINEO-ANSWER-3')"
                                                        attached-class="form-control"
                                                        placeholder="..."
                                                        name="JobineoAnswer3"
                                                        ref="JobineoAnswer3"/>
                                                </div>
                                                <div class="form-group col-lg-6">
                                                    <input ref="jobineoQAVideo3" type="file" class="d-none"
                                                           accept="video/*" @change="uploadVideo3"/>
                                                    <video class="videoPlayer" id="video-preview3" controls
                                                           controlsList="nodownload"/>
                                                    <p>
                                                        <base-button
                                                            native-type="button"
                                                            class="btn btn-yellow  margin-top-30"
                                                            @click="$refs.jobineoQAVideo3.click()"
                                                        >
                                                            <font-awesome-icon icon="fas fa-plus-circle"/>
                                                            &nbsp;{{ $t('UPLOAD-VIDEO') }}
                                                        </base-button>
                                                        <base-button
                                                            native-type="button"
                                                            class="btn btn-yellow  margin-top-30"
                                                            @click="removeVideo3"
                                                        >
                                                            <font-awesome-icon icon="fas fa-trash"/>
                                                            &nbsp;{{ $t('REMOVE-VIDEO') }}
                                                        </base-button>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                            <form id="social" class="section social">
                                <div class="info">
                                    <h5 class="">{{ trans('SOCIAL-NETWORKS') }}</h5>
                                    <div class="row">
                                        <div class="col-md-11 mx-auto">
                                            <div class="row">
                                                <div class="col-md-6 form-group">

                                                    <base-input
                                                        v-model="ProfileObj.LinkedInProfile"
                                                        type="text"
                                                        obj-name="LinkedInProfile"
                                                        :required="false"
                                                        :is-disabled="false"
                                                        :label="trans('LINKED-IN')"
                                                        attached-class="form-control"
                                                        :placeholder="trans('LINKED-IN')"
                                                        name="LinkedInProfile"
                                                        ref="LinkedInProfile"/>
                                                </div>

                                                <div class="col-md-6 form-group">

                                                    <base-input
                                                        v-model="ProfileObj.XingProfile"
                                                        type="text"
                                                        obj-name="XingProfile"
                                                        :required="false"
                                                        :is-disabled="false"
                                                        :label="trans('XING')"
                                                        attached-class="form-control"
                                                        :placeholder="trans('XING')"
                                                        name="XingProfile"
                                                        ref="XingProfile"/>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-11 mx-auto">
                                            <div class="row">
                                                <div class="col-md-6 form-group">

                                                    <base-input
                                                        v-model="ProfileObj.FacebookProfile"
                                                        type="text"
                                                        obj-name="FacebookProfile"
                                                        :required="false"
                                                        :is-disabled="false"
                                                        :label="trans('FACEBOOK')"
                                                        attached-class="form-control"
                                                        :placeholder="trans('FACEBOOK')"
                                                        name="FacebookProfile"
                                                        ref="FacebookProfile"/>

                                                </div>

                                                <div class="col-md-6 form-group">
                                                    <base-input
                                                        v-model="ProfileObj.InstagramProfile"
                                                        type="text"
                                                        obj-name="InstagramProfile"
                                                        :required="false"
                                                        :is-disabled="false"
                                                        :label="trans('INSTAGRAM')"
                                                        attached-class="form-control"
                                                        :placeholder="trans('INSTAGRAM')"
                                                        name="InstagramProfile"
                                                        ref="InstagramProfile"/>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-11 mx-auto">
                                            <div class="row">
                                                <div class="col-md-6 form-group">
                                                    <base-input
                                                        v-model="ProfileObj.TikTiokProfile"
                                                        type="text"
                                                        obj-name="TikTiokProfile"
                                                        :required="false"
                                                        :is-disabled="false"
                                                        :label="trans('TIK-TOK')"
                                                        attached-class="form-control"
                                                        :placeholder="trans('TIK-TOK')"
                                                        name="TikTiokProfile"
                                                        ref="TikTiokProfile"/>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                            <div id="skill" class="section skill">
                                <div class="info">
                                    <h5 class="">{{ trans('DRIVER-LICENSES') }}</h5>
                                    <div class="row progress-bar-section">
                                        <div class="col-md-12 mx-auto">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-11 mx-auto">
                                                        <div class="row">
                                                            <div class="form-group col-lg-3">
                                                                <no-translation-dropdown ref="DriverLicense"
                                                                                         v-model="DriverLicense"
                                                                                         :label="trans('DRIVER-LICENSE')"
                                                                                         :items="DriverLicenses"
                                                                                         :required="true"
                                                                                         :null-value-text="trans('SELECT-DRIVER-LICENSE')"
                                                                                         :has-null-value="true"
                                                                                         attached-class="form-control"
                                                                                         name="DriverLicense"/>
                                                            </div>
                                                            <div class="col-md-2 form-group">
                                                                <base-button
                                                                    native-type="button"
                                                                    class="btn btn-yellow  margin-top-30"
                                                                    @click="addDriverLicense"
                                                                >
                                                                    <font-awesome-icon icon="fas fa-plus-circle"/>
                                                                    &nbsp;{{ $t('ADD-DRIVER-LICENSE') }}
                                                                </base-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-11 mx-auto margin-top-30">
                                            <div class="custom-progress top-right progress-up">
                                                <template v-for="(item, idx) in DriverLicenseList" :key="idx">
                                                    <p class="skill-name">
                                                        {{ trans(getDriverLicenseText(item.DriverLicense)) }}
                                                        <font-awesome-icon icon="fas fa-trash"
                                                                           style="cursor: pointer;margin-left:25px;"
                                                                           @click="removeDriverLicense(idx, item)"></font-awesome-icon>
                                                    </p>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                            <div id="skill" class="section skill">
                                <div class="info">
                                    <h5 class="">{{ trans('SKILLS') }}</h5>
                                    <div class="row progress-bar-section">
                                        <div class="col-md-12 mx-auto">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-11 mx-auto">

                                                        <div class="row">
                                                            <div class="col-md-3 form-group">
                                                                <base-input
                                                                    v-model="SkillName"
                                                                    type="text"
                                                                    obj-name="SkillName"
                                                                    :required="true"
                                                                    :is-disabled="false"
                                                                    :label="trans('SKILL-NAME')"
                                                                    attached-class="form-control"
                                                                    placeholder="..."
                                                                    name="SkillName"
                                                                    ref="SkillName"/>
                                                            </div>
                                                            <div class="col-md-2 form-group">
                                                                <base-button
                                                                    native-type="button"
                                                                    class="btn btn-yellow  margin-top-30"
                                                                    @click="addSkill"
                                                                >
                                                                    <font-awesome-icon icon="fas fa-plus-circle"/>
                                                                    &nbsp;{{ $t('ADD-SKILL') }}
                                                                </base-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-11 mx-auto margin-top-30">
                                            <div class="custom-progress top-right progress-up">
                                                <template v-for="(item, idx) in SkillList" :key="idx">
                                                    <p class="skill-name">{{ item.QualificationName }}
                                                        <font-awesome-icon icon="fas fa-trash"
                                                                           style="cursor: pointer;margin-left:25px;"
                                                                           @click="removeSkill(idx, item)"></font-awesome-icon>
                                                    </p>
                                                    <input type="range" min="0" max="100"
                                                           class="form-range progress-range-counter"
                                                           v-model="item.QualificationLevel"/>
                                                    <div class="range-count">
                                                        <span class="range-count-number">{{
                                                                item.QualificationLevel
                                                            }}</span> <span
                                                        class="range-count-unit">%</span>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                            <div id="skill" class="section skill">
                                <div class="info">
                                    <h5 class="">{{ trans('LANGUAGES') }}</h5>
                                    <div class="row progress-bar-section">
                                        <div class="col-md-12 mx-auto">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-11 mx-auto">

                                                        <div class="row">
                                                            <div class="col-md-3 form-group">
                                                                <base-input
                                                                    v-model="LanguageName"
                                                                    type="text"
                                                                    obj-name="LanguageName"
                                                                    :required="true"
                                                                    :is-disabled="false"
                                                                    :label="trans('LANGUAGE-NAME')"
                                                                    attached-class="form-control"
                                                                    placeholder="..."
                                                                    name="LanguageName"
                                                                    ref="LanguageName"/>
                                                            </div>
                                                            <div class="form-group col-lg-3">
                                                                <data-dropdown ref="LanguageSkills"
                                                                               v-model="LanguageSkill"
                                                                               :label="trans('LANGUAGE-SKILL')"
                                                                               :items="LanguageSkills"
                                                                               :null-value-text="trans('SELECT-LANGUAGE-SKILL')"
                                                                               :has-null-value="true"
                                                                               attached-class="form-control"
                                                                               name="LanguageSkills"/>
                                                            </div>
                                                            <div class="col-md-2 form-group">
                                                                <base-button
                                                                    native-type="button"
                                                                    class="btn btn-yellow  margin-top-30"
                                                                    @click="addLanguage"
                                                                >
                                                                    <font-awesome-icon icon="fas fa-plus-circle"/>
                                                                    &nbsp;{{ $t('ADD-LANGUAGE') }}
                                                                </base-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-11 mx-auto margin-top-30">
                                            <div class="custom-progress top-right progress-up">
                                                <template v-for="(item, idx) in LanguageList" :key="idx">
                                                    <p class="skill-name">{{ item.Language }} -
                                                        {{ trans(getSkillLevelText(item.Skill)) }}
                                                        <font-awesome-icon icon="fas fa-trash"
                                                                           style="cursor: pointer;margin-left:25px;"
                                                                           @click="removeLanguage(idx, item)"></font-awesome-icon>
                                                    </p>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                            <div id="skill" class="section skill">
                                <div class="info">
                                    <h5 class="">{{ trans('SCHOOL-STRENGTH') }}</h5>
                                    <div class="row progress-bar-section">
                                        <div class="col-md-12 mx-auto">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-11 mx-auto">
                                                        <div class="row" v-if="SchoolSkillListCount < 2">
                                                            <div class="form-group col-lg-3">
                                                                <data-dropdown ref="SchoolSkill"
                                                                               v-model="SchoolSkill"
                                                                               :label="trans('SCHOOL-SKILL')"
                                                                               :items="SchoolSkills"
                                                                               :required="true"
                                                                               :null-value-text="trans('SELECT-SCHOOL-SKILL')"
                                                                               :has-null-value="true"
                                                                               attached-class="form-control"
                                                                               name="SchoolSkill"/>
                                                            </div>
                                                            <div class="col-md-2 form-group">
                                                                <base-button
                                                                    native-type="button"
                                                                    class="btn btn-yellow  margin-top-30"
                                                                    @click="addSchoolSkill"
                                                                >
                                                                    <font-awesome-icon icon="fas fa-plus-circle"/>
                                                                    &nbsp;{{ $t('ADD-SCHOOL-SKILL') }}
                                                                </base-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-11 mx-auto margin-top-30">
                                            <div class="custom-progress top-right progress-up">
                                                <template v-for="(item, idx) in SchoolSkillList" :key="idx">
                                                    <p class="skill-name">{{ trans(getSchoolSkillText(item.SkillId)) }}
                                                        <font-awesome-icon icon="fas fa-trash"
                                                                           style="cursor: pointer;margin-left:25px;"
                                                                           @click="removeSchoolSkill(idx, item)"></font-awesome-icon>
                                                    </p>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                            <div id="skill" class="section skill">
                                <div class="info">
                                    <h5 class="">{{ trans('CERTIFICATES') }}</h5>
                                    <div class="row progress-bar-section">
                                        <div class="col-md-12 mx-auto">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-11 mx-auto">

                                                        <div class="row">
                                                            <div class="col-md-3 form-group">
                                                                <base-input
                                                                    v-model="CertificateName"
                                                                    type="text"
                                                                    obj-name="CertificateName"
                                                                    :required="true"
                                                                    :is-disabled="false"
                                                                    :label="trans('CERTIFICATE')"
                                                                    attached-class="form-control"
                                                                    placeholder="..."
                                                                    name="CertificateName"
                                                                    ref="CertificateName"/>
                                                            </div>
                                                            <div class="col-md-2 form-group">
                                                                <base-button
                                                                    native-type="button"
                                                                    class="btn btn-yellow  margin-top-30"
                                                                    @click="addCertificate"
                                                                >
                                                                    <font-awesome-icon icon="fas fa-plus-circle"/>
                                                                    &nbsp;{{ $t('ADD-CERTIFICATE') }}
                                                                </base-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-11 mx-auto margin-top-30">
                                            <div class="custom-progress top-right progress-up">
                                                <template v-for="(item, idx) in CertificateList" :key="idx">
                                                    <p class="skill-name">{{ item.Name }}
                                                        <font-awesome-icon icon="fas fa-trash"
                                                                           style="cursor: pointer;margin-left:25px;"
                                                                           @click="removeCertificate(idx, item)"></font-awesome-icon>
                                                    </p>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                            <div id="skill" class="section skill">
                                <div class="info">
                                    <h5 class="">{{ trans('HOBBIES') }}</h5>
                                    <div class="row progress-bar-section">
                                        <div class="col-md-12 mx-auto">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-11 mx-auto">

                                                        <div class="row">
                                                            <div class="col-md-3 form-group">
                                                                <base-input
                                                                    v-model="HobbiesName"
                                                                    type="text"
                                                                    obj-name="HobbiesName"
                                                                    :required="true"
                                                                    :is-disabled="false"
                                                                    :label="trans('HOBBY')"
                                                                    attached-class="form-control"
                                                                    placeholder="..."
                                                                    name="HobbiesName"
                                                                    ref="HobbiesName"/>
                                                            </div>
                                                            <div class="col-md-2 form-group">
                                                                <base-button
                                                                    native-type="button"
                                                                    class="btn btn-yellow  margin-top-30"
                                                                    @click="addHobby"
                                                                >
                                                                    <font-awesome-icon icon="fas fa-plus-circle"/>
                                                                    &nbsp;{{ $t('ADD-HOBBY') }}
                                                                </base-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-11 mx-auto margin-top-30">
                                            <div class="custom-progress top-right progress-up">
                                                <template v-for="(item, idx) in HobbiesList" :key="idx">
                                                    <p class="skill-name">{{ item.Name }}
                                                        <font-awesome-icon icon="fas fa-trash"
                                                                           style="cursor: pointer;margin-left:25px;"
                                                                           @click="removeHobby(idx, item)"></font-awesome-icon>
                                                    </p>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                            <div id="skill" class="section skill">
                                <div class="info">
                                    <h5 class="">{{ trans('SUCCESSES') }}</h5>
                                    <div class="row progress-bar-section">
                                        <div class="col-md-12 mx-auto">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-11 mx-auto">

                                                        <div class="row">
                                                            <div class="col-md-3 form-group">
                                                                <base-input
                                                                    v-model="SuccessName"
                                                                    type="text"
                                                                    obj-name="SuccessName"
                                                                    :required="true"
                                                                    :is-disabled="false"
                                                                    :label="trans('SUCCESS-NAME')"
                                                                    attached-class="form-control"
                                                                    placeholder="..."
                                                                    name="SuccessName"
                                                                    ref="SuccessName"/>
                                                            </div>
                                                            <div class="col-md-2 form-group">
                                                                <base-button
                                                                    native-type="button"
                                                                    class="btn btn-yellow  margin-top-30"
                                                                    @click="addSuccess"
                                                                >
                                                                    <font-awesome-icon icon="fas fa-plus-circle"/>
                                                                    &nbsp;{{ $t('ADD-SUCCESS') }}
                                                                </base-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-11 mx-auto margin-top-30">
                                            <div class="custom-progress top-right progress-up">
                                                <template v-for="(item, idx) in SuccessList" :key="idx">
                                                    <p class="skill-name">{{ item.Name }}
                                                        <font-awesome-icon icon="fas fa-trash"
                                                                           style="cursor: pointer;margin-left:25px;"
                                                                           @click="removeSuccess(idx, item)"></font-awesome-icon>
                                                    </p>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                            <div id="skill" class="section skill">
                                <div class="info">
                                    <h5 class="">{{ trans('MY-TOP-5') }}</h5>
                                    <div class="row progress-bar-section">
                                        <div class="col-md-12 mx-auto">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-11 mx-auto">

                                                        <div class="row" v-if="StrengthListCount < 5">
                                                            <div class="col-md-3 form-group">
                                                                <base-input
                                                                    v-model="StrengthName"
                                                                    type="text"
                                                                    obj-name="StrengthName"
                                                                    :required="true"
                                                                    :is-disabled="false"
                                                                    :label="trans('STRENGTH-NAME')"
                                                                    attached-class="form-control"
                                                                    placeholder="..."
                                                                    name="StrengthName"
                                                                    ref="StrengthName"/>
                                                            </div>
                                                            <div class="col-md-2 form-group">
                                                                <base-button
                                                                    native-type="button"
                                                                    class="btn btn-yellow  margin-top-30"
                                                                    @click="addStrength"
                                                                >
                                                                    <font-awesome-icon icon="fas fa-plus-circle"/>
                                                                    &nbsp;{{ $t('ADD-STRENGTH') }}
                                                                </base-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-11 mx-auto margin-top-30">
                                            <div class="custom-progress top-right progress-up">
                                                <template v-for="(item, idx) in StrengthList" :key="idx">
                                                    <p class="skill-name">{{ item.Name }}
                                                        <font-awesome-icon icon="fas fa-trash"
                                                                           style="cursor: pointer;margin-left:25px;"
                                                                           @click="removeStrength(idx, item)"></font-awesome-icon>
                                                    </p>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                            <form id="edu-experience" class="section edu-experience">
                                <div class="info">
                                    <h5 class="">{{ trans('WORK-EXPERIENCE') }}</h5>
                                    <div class="row">
                                        <div class="col-md-12 text-end mb-5">
                                            <base-button
                                                native-type="button"
                                                class="btn btn-yellow  margin-top-30"
                                                @click="addWorkExperience"
                                            >
                                                <font-awesome-icon icon="fas fa-plus-circle"/>
                                                &nbsp;{{ $t('ADD-WORK-EXPERIENCE') }}
                                            </base-button>
                                        </div>
                                        <div class="col-md-11 mx-auto">
                                            <div class="edu-section">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <base-input
                                                                v-model="JobName"
                                                                type="text"
                                                                obj-name="JobName"
                                                                :required="true"
                                                                :is-disabled="false"
                                                                :label="trans('JOB-NAME')"
                                                                attached-class="form-control"
                                                                placeholder="..."
                                                                name="JobName"
                                                                ref="JobName"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <base-input
                                                                v-model="JobCity"
                                                                type="text"
                                                                obj-name="City"
                                                                :required="true"
                                                                :is-disabled="false"
                                                                :label="trans('CITY')"
                                                                attached-class="form-control"
                                                                placeholder="..."
                                                                name="City"
                                                                ref="City"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="row">
                                                            <div class="col-md-3">
                                                                <div class="form-group">
                                                                    <base-datetime
                                                                        :label="trans('START-DATE')"
                                                                        :required="true"
                                                                        obj-name="JobStartDate"
                                                                        name="JobStartDate"
                                                                        :max-date="new Date()"
                                                                        :value="JobStartDate"
                                                                        :onchange="JobStartDateChange"
                                                                        :place-holder="trans('PLEASE-SELECT-DATE')"
                                                                        date-time-type="date"
                                                                        :date-time-format="DateTimeFormat"
                                                                        :date-time-format-value="DateTimeFormatValue"
                                                                    ></base-datetime>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <div class="form-group">
                                                                    <base-datetime
                                                                        :label="trans('END-DATE')"
                                                                        :required="false"
                                                                        obj-name="JobEndDate"
                                                                        name="JobEndDate"
                                                                        :max-date="new Date()"
                                                                        :value="JobEndDate"
                                                                        :onchange="JobEndDateChange"
                                                                        :place-holder="trans('PLEASE-SELECT-DATE')"
                                                                        date-time-type="date"
                                                                        :date-time-format="DateTimeFormat"
                                                                        :date-time-format-value="DateTimeFormatValue"
                                                                    ></base-datetime>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group">

                                                                <base-text-area
                                                                    v-model="JobDescription"
                                                                    :required="false"
                                                                    obj-name="JobDescription"
                                                                    :label="trans('JOB-DESCRIPTION')"
                                                                    attached-class="form-control"
                                                                    placeholder="..."
                                                                    name="JobDescription"
                                                                    ref="JobDescription"/>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <base-text-area
                                                                    v-model="JobTask"
                                                                    :required="false"
                                                                    obj-name="JobTask"
                                                                    :label="trans('JOB-TASKS')"
                                                                    attached-class="form-control"
                                                                    placeholder="..."
                                                                    name="JobTask"
                                                                    ref="JobTask"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clearfix underlined-b-10"></div>
                                    <div class="col-md-11 mx-auto margin-top-30">
                                        <div class="custom-progress top-right progress-up">
                                            <template v-for="(item, idx) in StudentCvListWE" :key="idx">
                                                <p class="skill-name">{{ GetDateString(item.StartDate) }} -
                                                    {{ GetDateString(item.EndDate) }}:
                                                    {{ item.Name }} {{ item.City }}
                                                    <font-awesome-icon icon="fas fa-trash"
                                                                       style="cursor: pointer;margin-left:25px;"
                                                                       @click="removeWorkExperience(idx, item)"></font-awesome-icon>
                                                </p>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                            <form id="edu-experience" class="section edu-experience">
                                <div class="info">
                                    <h5 class="">{{ trans('SCHOOL-EDUCATION') }}</h5>
                                    <div class="row">
                                        <div class="col-md-12 text-end mb-5">
                                            <base-button
                                                native-type="button"
                                                class="btn btn-yellow  margin-top-30"
                                                @click="addSchoolExperience"
                                            >
                                                <font-awesome-icon icon="fas fa-plus-circle"/>
                                                &nbsp;{{ $t('ADD-WORK-EXPERIENCE') }}
                                            </base-button>
                                        </div>
                                        <div class="col-md-11 mx-auto">
                                            <div class="edu-section">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <base-input
                                                                v-model="SchoolName"
                                                                type="text"
                                                                obj-name="SchoolName"
                                                                :required="true"
                                                                :is-disabled="false"
                                                                :label="trans('SCHOOL-NAME')"
                                                                attached-class="form-control"
                                                                placeholder="..."
                                                                name="SchoolName"
                                                                ref="SchoolName"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <base-input
                                                                v-model="SchoolCity"
                                                                type="text"
                                                                obj-name="SchoolCity"
                                                                :required="true"
                                                                :is-disabled="false"
                                                                :label="trans('CITY')"
                                                                attached-class="form-control"
                                                                placeholder="..."
                                                                name="SchoolCity"
                                                                ref="SchoolCity"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="row">
                                                            <div class="col-md-3">
                                                                <div class="form-group">
                                                                    <base-datetime
                                                                        :label="trans('START-DATE')"
                                                                        :required="true"
                                                                        obj-name="SchoolStartDate"
                                                                        name="SchoolStartDate"
                                                                        :max-date="new Date()"
                                                                        :value="SchoolStartDate"
                                                                        :onchange="SchoolStartDateChange"
                                                                        :place-holder="trans('PLEASE-SELECT-DATE')"
                                                                        date-time-type="date"
                                                                        :date-time-format="DateTimeFormat"
                                                                        :date-time-format-value="DateTimeFormatValue"
                                                                    ></base-datetime>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <div class="form-group">
                                                                    <base-datetime
                                                                        :label="trans('END-DATE')"
                                                                        :required="false"
                                                                        obj-name="SchoolEndDate"
                                                                        name="SchoolEndDate"
                                                                        :max-date="new Date()"
                                                                        :value="SchoolEndDate"
                                                                        :onchange="SchoolEndDateChange"
                                                                        :place-holder="trans('PLEASE-SELECT-DATE')"
                                                                        date-time-type="date"
                                                                        :date-time-format="DateTimeFormat"
                                                                        :date-time-format-value="DateTimeFormatValue"
                                                                    ></base-datetime>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clearfix underlined-b-10"></div>
                                    <div class="col-md-11 mx-auto margin-top-30">
                                        <div class="custom-progress top-right progress-up">
                                            <template v-for="(item, idx) in StudentCvListSC" :key="idx">
                                                <p class="skill-name">{{ GetDateString(item.StartDate) }} -
                                                    {{ GetDateString(item.EndDate) }}:
                                                    {{ item.Name }} {{ item.City }}
                                                    <font-awesome-icon icon="fas fa-trash"
                                                                       style="cursor: pointer;margin-left:25px;"
                                                                       @click="removeSchoolExperience(idx, item)"></font-awesome-icon>
                                                </p>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>


                        <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                            <form id="edu-experience" class="section edu-experience">
                                <div class="info">
                                    <h5 class="">{{ trans('SOCIAL') }}</h5>
                                    <div class="row">
                                        <div class="col-md-12 text-end mb-5">
                                            <base-button
                                                native-type="button"
                                                class="btn btn-yellow  margin-top-30"
                                                @click="addSocialExperience"
                                            >
                                                <font-awesome-icon icon="fas fa-plus-circle"/>
                                                &nbsp;{{ $t('ADD-WORK-EXPERIENCE') }}
                                            </base-button>
                                        </div>
                                        <div class="col-md-11 mx-auto">
                                            <div class="edu-section">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <base-input
                                                                v-model="SocialName"
                                                                type="text"
                                                                obj-name="SocialName"
                                                                :required="true"
                                                                :is-disabled="false"
                                                                :label="trans('SOCIAL-NAME')"
                                                                attached-class="form-control"
                                                                placeholder="..."
                                                                name="SocialName"
                                                                ref="SocialName"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <base-input
                                                                v-model="SocialCity"
                                                                type="text"
                                                                obj-name="SocialCity"
                                                                :required="true"
                                                                :is-disabled="false"
                                                                :label="trans('CITY')"
                                                                attached-class="form-control"
                                                                placeholder="..."
                                                                name="SocialCity"
                                                                ref="SocialCity"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="row">
                                                            <div class="col-md-3">
                                                                <div class="form-group">
                                                                    <base-datetime
                                                                        :label="trans('START-DATE')"
                                                                        :required="true"
                                                                        obj-name="SocialStartDate"
                                                                        name="SocialStartDate"
                                                                        :max-date="new Date()"
                                                                        :value="SocialStartDate"
                                                                        :onchange="SocialStartDateChange"
                                                                        :place-holder="trans('PLEASE-SELECT-DATE')"
                                                                        date-time-type="date"
                                                                        :date-time-format="DateTimeFormat"
                                                                        :date-time-format-value="DateTimeFormatValue"
                                                                    ></base-datetime>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <div class="form-group">
                                                                    <base-datetime
                                                                        :label="trans('END-DATE')"
                                                                        :required="false"
                                                                        obj-name="SocialEndDate"
                                                                        name="SocialEndDate"
                                                                        :max-date="new Date()"
                                                                        :value="SocialEndDate"
                                                                        :onchange="SocialEndDateChange"
                                                                        :place-holder="trans('PLEASE-SELECT-DATE')"
                                                                        date-time-type="date"
                                                                        :date-time-format="DateTimeFormat"
                                                                        :date-time-format-value="DateTimeFormatValue"
                                                                    ></base-datetime>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group">

                                                                <base-text-area
                                                                    v-model="SocialDescription"
                                                                    :required="true"
                                                                    obj-name="SocialDescription"
                                                                    :label="trans('SOCIAL-DESCRIPTION')"
                                                                    attached-class="form-control"
                                                                    placeholder="..."
                                                                    name="SocialDescription"
                                                                    ref="SocialDescription"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clearfix underlined-b-10"></div>
                                    <div class="col-md-11 mx-auto margin-top-30">
                                        <div class="custom-progress top-right progress-up">
                                            <template v-for="(item, idx) in StudentCvListSO" :key="idx">
                                                <p class="skill-name">{{ GetDateString(item.StartDate) }} -
                                                    {{ GetDateString(item.EndDate) }}:
                                                    {{ item.Name }} {{ item.City }}
                                                    <font-awesome-icon icon="fas fa-trash"
                                                                       style="cursor: pointer;margin-left:25px;"
                                                                       @click="removeSocialExperience(idx, item)"></font-awesome-icon>
                                                </p>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                            <form id="edu-experience" class="section edu-experience">
                                <div class="info">
                                    <h5 class="">{{ trans('MY-SEARCH') }}</h5>
                                    <div class="row">
                                        <div class="col-md-12 text-end mb-5">
                                            <base-button
                                                native-type="button"
                                                class="btn btn-yellow  margin-top-30"
                                                @click="addMySearch"
                                            >
                                                <font-awesome-icon icon="fas fa-plus-circle"/>
                                                &nbsp;{{ $t('ADD-MY-SEARCH') }}
                                            </base-button>
                                        </div>
                                        <div class="col-md-11 mx-auto">
                                            <div class="edu-section">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <data-dropdown ref="JobType"
                                                                           v-model="JobType"
                                                                           :label="trans('JOB-TYPE')"
                                                                           :no-check-icons="true"
                                                                           :required="true"
                                                                           :items="JobTypeList"
                                                                           :null-value-text="trans('SELECT-JOB-TYPE')"
                                                                           :has-null-value="true"
                                                                           attached-class="form-control"
                                                                           name="JobType"/>
                                                        </div>
                                                    </div>


                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <data-dropdown ref="JobFieldId"
                                                                           v-model="JobFieldId"
                                                                           :label="trans(GetStudyJobText)"
                                                                           :no-check-icons="true"
                                                                           :disabled="JobType==null"
                                                                           :required="true"
                                                                           :items="JobFieldIdList"
                                                                           :null-value-text="trans(GetStudyJobSelectText)"
                                                                           :has-null-value="true"
                                                                           attached-class="form-control"
                                                                           name="JobFieldId"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1" v-if="ShowJobField">
                                                        <div class="form-group"
                                                             style="text-align: center;margin-top:35px;">
                                                            <h4>{{ trans('OR') }}</h4>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3" v-if="ShowJobField">
                                                        <div class="form-group">

                                                            <data-dropdown ref="JobId"
                                                                           v-model="JobId"
                                                                           :label="trans('JOB-ID')"
                                                                           :no-check-icons="true"
                                                                           :required="true"
                                                                           :disabled="JobType==null"
                                                                           :items="JobIdList"
                                                                           :null-value-text="trans('SELECT-JOB-ID')"
                                                                           :has-null-value="true"
                                                                           attached-class="form-control"
                                                                           name="JobId"/>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clearfix underlined-b-10"></div>
                                    <div class="col-md-11 mx-auto margin-top-30">
                                        <div class="custom-progress top-right progress-up">
                                            <template v-for="(item, idx) in StudentJobSelectionList" :key="idx">

                                                <p class="skill-name" v-if="item.JobFieldId!=null&&item.JobType==3">
                                                    {{ GetStudyText(item.JobFieldId) }}
                                                    <font-awesome-icon icon="fas fa-trash"
                                                                       style="cursor: pointer;margin-left:25px;"
                                                                       @click="removeJobSelection(idx, item)"></font-awesome-icon>
                                                </p>
                                                <p class="skill-name" v-if="item.JobFieldId!=null&&item.JobType!=3">
                                                    {{ GetJobFieldText(item.JobFieldId) }}
                                                    <font-awesome-icon icon="fas fa-trash"
                                                                       style="cursor: pointer;margin-left:25px;"
                                                                       @click="removeJobSelection(idx, item)"></font-awesome-icon>
                                                </p>
                                                <p class="skill-name" v-if="item.JobId!=null">{{
                                                        GetJobText(item.JobId)
                                                    }}
                                                    <font-awesome-icon icon="fas fa-trash"
                                                                       style="cursor: pointer;margin-left:25px;"
                                                                       @click="removeJobSelection(idx, item)"></font-awesome-icon>
                                                </p>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>


                    </div>
                </div>
            </div>

            <div class="account-settings-footer">
                <div class="as-footer-container">
                    <base-button
                        native-type="button"
                        class="btn btn-red float-left"
                        @click="cancel"
                    >
                        <font-awesome-icon icon="fas fa-times"/>
                        {{ $t('CANCEL') }}
                    </base-button>
                    <base-button
                        native-type="button"
                        class="btn btn-mint float-right"
                        @click="validate()"

                    >
                        <font-awesome-icon v-if="processing"
                                           icon="fas fa-spin fa-circle-notch"/>
                        <font-awesome-icon v-if="!processing"
                                           icon="fas fa-check"/>
                        {{ $t('SAVE') }}
                    </base-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import '@/scss/jobineo/sass/scrollspyNav.scss';
import '@/scss/jobineo/sass/users/account-setting.scss';
import store from "@/store";
import {ProfileController} from "@/controllers/profile.controller";
import * as yup from "yup";
import {Form} from "vee-validate";
import router from "@/router";
import BaseTextArea from "@/components/BaseTextArea.vue";
import DataDropdown from "@/components/dropdowns/DataDropdown.vue";
import {uuid} from "vue-uuid";
import {LANGUAGES_SKILLS_DROPDOWN} from "@/data/languages";
import lodash from "lodash";
import {TRACKING_STATE} from "@/utils/applyTrackingState";
import {QUALIFICATION_TYPES} from "@/data/qualificationType";
import {SCHOOL_SKILLS_DROPDOWN} from "@/data/schoolSkills";
import {getDateStringMonthYear} from "@/utils/convertDateTime";
import NoTranslationDropdown from "@/components/dropdowns/NoTranslationDropdown.vue";
import {JOB_TYPES} from "@/data/jobTypes";
import {CV_TYPES} from "@/data/cvtypes";

export default {
    name: "StudenProfileEdit.vue",
    components: {NoTranslationDropdown, DataDropdown, BaseTextArea, Form},
    data() {
        return {
            validationSchema: yup.object({}),
            controller: new ProfileController(this),
            SkillName: null,
            LanguageName: null,
            LanguageSkill: null,
            CertificateName: null,
            HobbiesName: null,
            SuccessName: null,
            StrengthName: null,
            SchoolSkill: null,
            DriverLicense: null,
            JobStartDate: null,
            JobEndDate: null,
            JobDescription: null,
            JobName: null,
            JobCity: null,
            JobTask: null,

            SchoolStartDate: null,
            SchoolEndDate: null,
            SchoolName: null,
            SchoolCity: null,

            SocialStartDate: null,
            SocialEndDate: null,
            SocialDescription: null,
            SocialName: null,
            SocialCity: null,

            JobId: null,
            JobType: null,
            JobFieldId: null,
            Video1: null,
            Video2: null,
            Video3: null,

            model: {
                PkUser: null,
                ProfilePicture: null,
            },
            ProfileObj: {
                PkStudentProfile: null,
                FkUser: null,
                BirthDate: null,
                Street: null,
                StreetNr: null,
                City: null,
                PostCode: null,
                Country: null,
                AddressAddition: null,
                Phone: null,
                Mobile: null,
                AvailableFrom: null,
                Radius: null,
                Duration: null,
                ReadyToMove: null,
                CarAvailable: null,
                LinkedInProfile: null,
                XingProfile: null,
                InstagramProfile: null,
                FacebookProfile: null,
                TikTiokProfile: null,
                ProfileCount: null,
                Rehearsal: null,
                ChangeCompany: null,
                StudentCertificatesList: [],
                StudentHobbiesList: [],
                StudentLanguagesList: [],
                StudentSchoolSkillsList: [],
                StudentSuccessesList: [],
                StudentQualificationList: [],
                StudentStrengthList: [],
                StudentPersonality: {
                    PkStudentPersonality: null,
                    FkStudent: null,
                    AboutMe: null,
                    JobineoQuestion1: null,
                    JobineoAnswer1: null,
                    JobineoAnswerFile1: null,
                    JobineoQuestion2: null,
                    JobineoAnswer2: null,
                    JobineoAnswerFile2: null,
                    JobineoQuestion3: null,
                    JobineoAnswer3: null,
                    JobineoAnswerFile3: null,
                    Hobby: null,
                },
                StudentGraduation: {
                    PkStudentGraduation: null,
                    FkStudent: null,
                    HighestGraduation: null,
                    EndDate: null,
                    Average: null
                },
                StudentDriverLicenseList: [],
                StudentCvListWE: [],
                StudentCvListSC: [],
                StudentCvListSO: [],
                StudentJobSelectionList: [],
                CurrentFavourite: null
            },
            processing: false,
            selectedImage: null,
            error: false,
            errorMessage: '',
        }
    },
    computed: {
        ShowJobField() {
            return this.JobType == null || this.JobType != JOB_TYPES.DUAL_STUDY
        },
        GetStudyJobText() {
            return this.JobType == JOB_TYPES.DUAL_STUDY ? 'STUDY-FIELD' : 'JOB-FIELD'
        },
        GetStudyJobSelectText() {
            return this.JobType == JOB_TYPES.DUAL_STUDY ? 'SELECT-STUDY-FIELD' : 'SELECT-JOB-FIELD'
        },
        ProfilePicture() {
            return URL.createObjectURL(new Blob([store.state.ProfilePictureContent]));
        },
        LanguageSkills() {
            return LANGUAGES_SKILLS_DROPDOWN;
        },
        SchoolSkills() {
            return SCHOOL_SKILLS_DROPDOWN;
        },
        DriverLicenses() {
            return this.$store.state.Lookups.DriverLicensesLookup != null && Array.isArray(this.$store.state.Lookups.DriverLicensesLookup) ? this.$store.state.Lookups.DriverLicensesLookup : [];
        },
        DateTimeFormat() {
            return "d.m.Y";
        },
        DateTimeFormatValue() {
            return "Y-m-dT00:00:00.000\\Z";
        },
        CountriesList() {
            return this.$store.state.Lookups.CountriesLookup != null && Array.isArray(this.$store.state.Lookups.CountriesLookup) ? this.$store.state.Lookups.CountriesLookup : [];
        },
        JobineoQuestionList() {
            return this.$store.state.Lookups.StudentQuestionsLookup != null && Array.isArray(this.$store.state.Lookups.StudentQuestionsLookup) ? this.$store.state.Lookups.StudentQuestionsLookup : [];
        },
        JobTypeList() {
            return this.$store.state.Lookups.JobAdTypesLookup != null && Array.isArray(this.$store.state.Lookups.JobAdTypesLookup) ? this.$store.state.Lookups.JobAdTypesLookup : [];
        },
        JobFieldIdList() {
            if (this.JobType === null)
                return [];
            else if (this.JobType == JOB_TYPES.DUAL_STUDY)
                return this.$store.state.Lookups.StudyFieldsLookup != null && Array.isArray(this.$store.state.Lookups.StudyFieldsLookup) ? this.$store.state.Lookups.StudyFieldsLookup : [];
            else
                return this.$store.state.Lookups.JobFieldsLookup != null && Array.isArray(this.$store.state.Lookups.JobFieldsLookup) ? this.$store.state.Lookups.JobFieldsLookup : [];
        },
        JobIdList() {
            if (this.JobType === null)
                return [];
            else if (this.JobType == JOB_TYPES.DUAL_STUDY)
                return this.$store.state.Lookups.StudyFieldsLookup != null && Array.isArray(this.$store.state.Lookups.StudyFieldsLookup) ? this.$store.state.Lookups.StudyFieldsLookup : [];
            else
                return this.$store.state.Lookups.JobsLookup != null && Array.isArray(this.$store.state.Lookups.JobsLookup) ? this.$store.state.Lookups.JobsLookup : [];
        },
        StudentJobSelectionList() {
            if (this.ProfileObj.StudentJobSelectionList != null && Array.isArray(this.ProfileObj.StudentJobSelectionList)) {
                var items = lodash.filter(this.ProfileObj.StudentJobSelectionList, x => x.TrackingState != TRACKING_STATE.DELETE);
                if (items != null && items.length > 0)
                    return items;
                else
                    return [];
            } else {
                return [];
            }
        },
        StudentCvListWE() {
            if (this.ProfileObj.StudentCvListWE != null && Array.isArray(this.ProfileObj.StudentCvListWE)) {
                var items = lodash.filter(this.ProfileObj.StudentCvListWE, x => x.TrackingState != TRACKING_STATE.DELETE);
                if (items != null && items.length > 0)
                    return items;
                else
                    return [];
            } else {
                return [];
            }
        },
        StudentCvListSC() {
            if (this.ProfileObj.StudentCvListSC != null && Array.isArray(this.ProfileObj.StudentCvListSC)) {
                var items = lodash.filter(this.ProfileObj.StudentCvListSC, x => x.TrackingState != TRACKING_STATE.DELETE);
                if (items != null && items.length > 0)
                    return items;
                else
                    return [];
            } else {
                return [];
            }
        },
        StudentCvListSO() {
            if (this.ProfileObj.StudentCvListSO != null && Array.isArray(this.ProfileObj.StudentCvListSO)) {
                var items = lodash.filter(this.ProfileObj.StudentCvListSO, x => x.TrackingState != TRACKING_STATE.DELETE);
                if (items != null && items.length > 0)
                    return items;
                else
                    return [];
            } else {
                return [];
            }
        },
        LanguageList() {
            if (this.ProfileObj.StudentLanguagesList != null && Array.isArray(this.ProfileObj.StudentLanguagesList)) {
                var items = lodash.filter(this.ProfileObj.StudentLanguagesList, x => x.TrackingState != TRACKING_STATE.DELETE);
                if (items != null && items.length > 0)
                    return items;
                else
                    return [];
            } else {
                return [];
            }
        },
        HighestGraduationList() {
            return this.$store.state.Lookups.GraduationsLookup != null && Array.isArray(this.$store.state.Lookups.GraduationsLookup) ? this.$store.state.Lookups.GraduationsLookup : [];
        },
        SchoolSkillList() {
            if (this.ProfileObj.StudentSchoolSkillsList != null && Array.isArray(this.ProfileObj.StudentSchoolSkillsList)) {
                var items = lodash.filter(this.ProfileObj.StudentSchoolSkillsList, x => x.TrackingState != TRACKING_STATE.DELETE);
                if (items != null && items.length > 0)
                    return items;
                else
                    return [];
            } else {
                return [];
            }
        },
        DriverLicenseList() {
            if (this.ProfileObj.StudentDriverLicenseList != null && Array.isArray(this.ProfileObj.StudentDriverLicenseList)) {
                var items = lodash.filter(this.ProfileObj.StudentDriverLicenseList, x => x.TrackingState != TRACKING_STATE.DELETE);
                if (items != null && items.length > 0)
                    return items;
                else
                    return [];
            } else {
                return [];
            }
        },
        SchoolSkillListCount() {
            if (this.ProfileObj.StudentSchoolSkillsList != null && Array.isArray(this.ProfileObj.StudentSchoolSkillsList)) {
                return this.ProfileObj.StudentSchoolSkillsList.length;
            } else {
                return 0;
            }
        },
        HobbiesList() {
            if (this.ProfileObj.StudentHobbiesList != null && Array.isArray(this.ProfileObj.StudentHobbiesList)) {
                var items = lodash.filter(this.ProfileObj.StudentHobbiesList, x => x.TrackingState != TRACKING_STATE.DELETE);
                if (items != null && items.length > 0)
                    return items;
                else
                    return [];
            } else {
                return [];
            }
        },
        CertificateList() {
            if (this.ProfileObj.StudentCertificatesList != null && Array.isArray(this.ProfileObj.StudentCertificatesList)) {
                var items = lodash.filter(this.ProfileObj.StudentCertificatesList, x => x.TrackingState != TRACKING_STATE.DELETE);
                if (items != null && items.length > 0)
                    return items;
                else
                    return [];
            } else {
                return [];
            }

        },
        SkillList() {
            if (this.ProfileObj.StudentQualificationList != null && Array.isArray(this.ProfileObj.StudentQualificationList)) {
                var items = lodash.filter(this.ProfileObj.StudentQualificationList, x => x.TrackingState != TRACKING_STATE.DELETE);
                if (items != null && items.length > 0)
                    return items;
                else
                    return [];
            } else {
                return [];
            }

        },
        SuccessList() {
            if (this.ProfileObj.StudentSuccessesList != null && Array.isArray(this.ProfileObj.StudentSuccessesList)) {
                var items = lodash.filter(this.ProfileObj.StudentSuccessesList, x => x.TrackingState != TRACKING_STATE.DELETE);
                if (items != null && items.length > 0)
                    return items;
                else
                    return [];
            } else {
                return [];
            }

        },
        StrengthList() {
            if (this.ProfileObj.StudentStrengthList != null && Array.isArray(this.ProfileObj.StudentStrengthList)) {
                var items = lodash.filter(this.ProfileObj.StudentStrengthList, x => x.TrackingState != TRACKING_STATE.DELETE);
                if (items != null && items.length > 0)
                    return items;
                else
                    return [];
            } else {
                return [];
            }

        },
        StrengthListCount() {
            if (this.ProfileObj.StudentStrengthList != null && Array.isArray(this.ProfileObj.StudentStrengthList)) {
                return this.ProfileObj.StudentStrengthList.length
            } else {
                return 0;
            }

        }
    },
    watch: {
        JobId(newValue, oldValue) {
            if (newValue != null) {
                this.JobFieldId = null;
            }
        },
        JobFieldId(newValue, oldValue) {
            if (newValue != null) {
                this.JobId = null;
            }
        },
        JobType(newValue, oldValue) {
            if (newValue == null) {
                this.JobId = null;
                this.JobFieldId = null;
            }
        }
    },
    methods: {
        getSkillLevelText(level) {
            let skill = lodash.filter(LANGUAGES_SKILLS_DROPDOWN, x => x.DataKey == level);
            if (skill != null && skill.length > 0)
                return skill[0].DataValue;
            else
                return ''
        },
        GetStudyText(id) {
            let items = lodash.filter(this.$store.state.Lookups.StudyFieldsLookup, x => x.DataKey == id);
            if (items != null && items.length > 0)
                return items[0].DataValue
            else return ''
        },
        GetJobFieldText(id) {
            let items = lodash.filter(this.$store.state.Lookups.JobFieldsLookup, x => x.DataKey == id);
            if (items != null && items.length > 0)
                return items[0].DataValue
            else return ''
        },
        GetJobText(id) {
            let items = lodash.filter(this.$store.state.Lookups.JobsLookup, x => x.DataKey == id);
            if (items != null && items.length > 0)
                return items[0].DataValue
            else return ''
        },
        getSchoolSkillText(level) {
            let skill = lodash.filter(SCHOOL_SKILLS_DROPDOWN, x => x.DataKey == level);
            if (skill != null && skill.length > 0)
                return skill[0].DataValue;
            else
                return ''
        },
        getDriverLicenseText(level) {
            if (this.$store.state.Lookups.DriverLicensesLookup != null) {
                let skill = lodash.filter(this.$store.state.Lookups.DriverLicensesLookup, x => x.DataKey == level);
                if (skill != null && skill.length > 0)
                    return skill[0].DataValue;
                else
                    return ''
            } else
                return ''
        },
        removeLanguage(id, item) {
            if (item.TrackingState != null && item.TrackingState == TRACKING_STATE.CREATE)
                this.ProfileObj.StudentLanguagesList.splice(id, 1);
            else
                item.TrackingState = TRACKING_STATE.DELETE;
        },
        removeWorkExperience(id, item) {
            if (item.TrackingState != null && item.TrackingState == TRACKING_STATE.CREATE)
                this.ProfileObj.StudentCvListWE.splice(id, 1);
            else
                item.TrackingState = TRACKING_STATE.DELETE;
        },
        removeSchoolExperience(id, item) {
            if (item.TrackingState != null && item.TrackingState == TRACKING_STATE.CREATE)
                this.ProfileObj.StudentCvListSC.splice(id, 1);
            else
                item.TrackingState = TRACKING_STATE.DELETE;
        },
        removeSocialExperience(id, item) {
            if (item.TrackingState != null && item.TrackingState == TRACKING_STATE.CREATE)
                this.ProfileObj.StudentCvListSO.splice(id, 1);
            else
                item.TrackingState = TRACKING_STATE.DELETE;
        },
        removeJobSelection(id, item) {
            if (item.TrackingState != null && item.TrackingState == TRACKING_STATE.CREATE)
                this.ProfileObj.StudentJobSelectionList.splice(id, 1);
            else
                item.TrackingState = TRACKING_STATE.DELETE;
        },
        removeDriverLicense(id, item) {
            if (item.TrackingState != null && item.TrackingState == TRACKING_STATE.CREATE)
                this.ProfileObj.StudentDriverLicenseList.splice(id, 1);
            else
                item.TrackingState = TRACKING_STATE.DELETE;
        },
        removeSchoolSkill(id, item) {
            if (item.TrackingState != null && item.TrackingState == TRACKING_STATE.CREATE)
                this.ProfileObj.StudentSchoolSkillsList.splice(id, 1);
            else
                item.TrackingState = TRACKING_STATE.DELETE;
        },
        removeCertificate(id, item) {
            if (item.TrackingState != null && item.TrackingState == TRACKING_STATE.CREATE)
                this.ProfileObj.StudentCertificatesList.splice(id, 1);
            else
                item.TrackingState = TRACKING_STATE.DELETE;
        },
        removeSkill(id, item) {
            if (item.TrackingState != null && item.TrackingState == TRACKING_STATE.CREATE)
                this.ProfileObj.StudentQualificationList.splice(id, 1);
            else
                item.TrackingState = TRACKING_STATE.DELETE;

        },
        removeHobby(id, item) {
            if (item.TrackingState != null && item.TrackingState == TRACKING_STATE.CREATE)
                this.ProfileObj.StudentHobbiesList.splice(id, 1);
            else
                item.TrackingState = TRACKING_STATE.DELETE;

        },
        removeSuccess(id, item) {
            if (item.TrackingState != null && item.TrackingState == TRACKING_STATE.CREATE)
                this.ProfileObj.StudentSuccessesList.splice(id, 1);
            else
                item.TrackingState = TRACKING_STATE.DELETE;

        },
        removeStrength(id, item) {
            if (item.TrackingState != null && item.TrackingState == TRACKING_STATE.CREATE)
                this.ProfileObj.StudentStrengthList.splice(id, 1);
            else
                item.TrackingState = TRACKING_STATE.DELETE;

        },
        BirthDateChange(date) {
            this.ProfileObj.BirthDate = date;
        },
        JobStartDateChange(value) {
            this.JobStartDate = value;
        },
        JobEndDateChange(value) {
            this.JobEndDate = value;
        },
        SchoolStartDateChange(value) {
            this.SchoolStartDate = value;
        },
        SchoolEndDateChange(value) {
            this.SchoolEndDate = value;
        },
        SocialStartDateChange(value) {
            this.SocialStartDate = value;
        },
        SocialEndDateChange(value) {
            this.SocialEndDate = value;
        },
        AvailableFromChange(date) {
            this.ProfileObj.AvailableFrom = date;
        },
        GraduationEndDateChange(date) {
            this.ProfileObj.StudentGraduation.EndDate = date;
        },
        cancel() {
            router.push('/StudentProfile/StudentProfileView');
        },
        save() {

            this.controller.update(this.ProfileObj).then(response => {
                if (response.IsError) {
                    this.error = true;
                    this.errorMessage = this.trans(response.ErrorMessage);
                    this.$notify(
                        {
                            title: this.trans('ERROR'),
                            text: this.trans(response.ErrorMessage),
                            type: 'error',
                        });


                    return false;
                } else {
                    this.error = false;
                    this.errorMessage = "";
                    this.$notify(
                        {
                            title: this.trans('SUCCESS'),
                            text: this.trans('DATA-SAVED'),
                            type: 'success'
                        });


                    this.controller.read().then(response => {
                        this.ProfileObj = response.StudentProfile;
                    })

                }
            }).catch(result => {

                let data = result.data;
                if (data.IsError) {
                    this.error = true;
                    this.errorMessage = this.trans(data.ErrorMessage);
                }
            });

        },

        addMySearch() {
            if (this.JobType == null ||
                (this.JobFieldId == null &&
                    this.JobId == null)) {
                this.$notify(
                    {
                        title: this.trans('ERROR'),
                        text: this.trans("JOB-SELECTION-EMPTY"),
                        type: 'error',
                    });

                return;
            }

            if (this.JobFieldId != null) {
                let lang = lodash.filter(this.ProfileObj.StudentJobSelectionList, x => x.JobFieldId == this.JobFieldId && x.JobType == this.JobType);
                if (lang != null && lang.length > 0)
                    return;
            }

            if (this.JobId != null) {
                let lang = lodash.filter(this.ProfileObj.StudentJobSelectionList, x => x.JobId == this.JobId && x.JobType == this.JobType);
                if (lang != null && lang.length > 0)
                    return;
            }

            if (this.ProfileObj.StudentJobSelectionList == null)
                this.ProfileObj.StudentJobSelectionList = [];

            this.ProfileObj.StudentJobSelectionList.push({
                PkStudentJobSelection: uuid.v4(),
                FkStudent: this.ProfileObj.PkStudentProfile,
                JobFieldId: this.JobFieldId,
                JobId: this.JobId,
                JobType: this.JobType,
                TrackingState: TRACKING_STATE.CREATE
            });

            this.JobType = null;
            this.JobFieldId = null
            this.JobId = null
        },
        addWorkExperience() {

            if (this.JobName == null ||
                this.JobStartDate == null ||
                this.JobCity == null) {
                this.$notify(
                    {
                        title: this.trans('ERROR'),
                        text: this.trans("WORK-EXPERIENCE-EMPTY"),
                        type: 'error',
                    });

                return;
            }


            let lang = lodash.filter(this.ProfileObj.StudentCvListWE, x => x.Name == this.JobName);
            if (lang != null && lang.length > 0)
                return;

            if (this.ProfileObj.StudentCvListWE == null)
                this.ProfileObj.StudentCvListWE = [];

            this.ProfileObj.StudentCvListWE.push({
                PkStudentCv: uuid.v4(),
                FkStudent: this.ProfileObj.PkStudentProfile,
                StartDate: this.JobStartDate,
                EndDate: this.JobEndDate,
                Name: this.JobName,
                Description: this.JobDescription,
                City: this.JobCity,
                Cvtype: CV_TYPES.WORK_EXPERIENCE,
                Tasks: this.JobTask,
                TrackingState: TRACKING_STATE.CREATE
            });

            this.JobName = null;
            this.JobStartDate = null
            this.JobEndDate = null
            this.JobCity = null
            this.JobDescription = null
            this.JobTask = null
        },
        addSchoolExperience() {

            if (this.SchoolName == null ||
                this.SchoolStartDate == null ||
                this.SchoolCity == null) {
                this.$notify(
                    {
                        title: this.trans('ERROR'),
                        text: this.trans("SCHOOL-EXPERIENCE-EMPTY"),
                        type: 'error',
                    });

                return;
            }


            let lang = lodash.filter(this.ProfileObj.StudentCvListSC, x => x.Name == this.SchoolName);
            if (lang != null && lang.length > 0)
                return;

            if (this.ProfileObj.StudentCvListSC == null)
                this.ProfileObj.StudentCvListSC = [];

            this.ProfileObj.StudentCvListSC.push({
                PkStudentCv: uuid.v4(),
                FkStudent: this.ProfileObj.PkStudentProfile,
                StartDate: this.SchoolStartDate,
                EndDate: this.SchoolEndDate,
                Name: this.SchoolName,
                Cvtype: CV_TYPES.SCHOOL_EDUCATION,
                City: this.SchoolCity,
                TrackingState: TRACKING_STATE.CREATE
            });

            this.SchoolName = null;
            this.SchoolStartDate = null
            this.SchoolEndDate = null
            this.SchoolCity = null
        },
        addSocialExperience() {

            if (this.SocialName == null ||
                this.SocialStartDate == null ||
                this.SocialCity == null ||
                this.SocialDescription == null) {
                this.$notify(
                    {
                        title: this.trans('ERROR'),
                        text: this.trans("SOCIAL-EXPERIENCE-EMPTY"),
                        type: 'error',
                    });

                return;
            }


            let lang = lodash.filter(this.ProfileObj.StudentCvListSO, x => x.Name == this.SocialName);
            if (lang != null && lang.length > 0)
                return;

            if (this.ProfileObj.StudentCvListSO == null)
                this.ProfileObj.StudentCvListSO = [];

            this.ProfileObj.StudentCvListSO.push({
                PkStudentCv: uuid.v4(),
                FkStudent: this.ProfileObj.PkStudentProfile,
                StartDate: this.SocialStartDate,
                EndDate: this.SocialEndDate,
                Name: this.SocialName,
                City: this.SocialCity,
                Cvtype: CV_TYPES.SOCIAL_EXPERIENCE,
                Description: this.SocialDescription,
                TrackingState: TRACKING_STATE.CREATE
            });

            this.SocialName = null;
            this.SocialStartDate = null
            this.SocialEndDate = null
            this.SocialCity = null
            this.SocialDescription = null
        },
        addSkill() {

            if (this.SkillName == null) {
                this.$notify(
                    {
                        title: this.trans('ERROR'),
                        text: this.trans("SKILL-NAME-EMPTY"),
                        type: 'error',
                    });

                return;
            }


            let lang = lodash.filter(this.ProfileObj.StudentQualificationList, x => x.QualificationName == this.SkillName);
            if (lang != null && lang.length > 0)
                return;

            if (this.ProfileObj.StudentQualificationList == null)
                this.ProfileObj.StudentQualificationList = [];

            this.ProfileObj.StudentQualificationList.push({
                PkStudentQualification: uuid.v4(),
                FkStudent: this.ProfileObj.PkStudentProfile,
                QualificationType: QUALIFICATION_TYPES.COMPUTER,
                QualificationName: this.SkillName,
                QualificationLevel: 0,
                TrackingState: TRACKING_STATE.CREATE
            });

            this.SkillName = null;
        },
        addDriverLicense() {

            if (this.DriverLicense == null) {
                this.$notify(
                    {
                        title: this.trans('ERROR'),
                        text: this.trans("DRIVER-LICENSE-EMPTY"),
                        type: 'error',
                    });

                return;
            }


            let lang = lodash.filter(this.ProfileObj.StudentDriverLicenseList, x => x.DriverLicense == this.DriverLicense);
            if (lang != null && lang.length > 0)
                return;

            if (this.ProfileObj.StudentDriverLicenseList == null)
                this.ProfileObj.StudentDriverLicenseList = [];

            this.ProfileObj.StudentDriverLicenseList.push({
                PkStudentDriverLicense: uuid.v4(),
                FkStudent: this.ProfileObj.PkStudentProfile,
                DriverLicense: this.DriverLicense,
                TrackingState: TRACKING_STATE.CREATE
            });

            this.DriverLicense = null;
        },
        addSchoolSkill() {

            if (this.SchoolSkill == null) {
                this.$notify(
                    {
                        title: this.trans('ERROR'),
                        text: this.trans("SKILL-NAME-EMPTY"),
                        type: 'error',
                    });

                return;
            }


            let lang = lodash.filter(this.ProfileObj.StudentSchoolSkillsList, x => x.SkillId == this.SchoolSkill);
            if (lang != null && lang.length > 0)
                return;

            if (this.ProfileObj.StudentSchoolSkillsList == null)
                this.ProfileObj.StudentSchoolSkillsList = [];

            this.ProfileObj.StudentSchoolSkillsList.push({
                PkStudentSchoolSkill: uuid.v4(),
                FkStudent: this.ProfileObj.PkStudentProfile,
                SkillId: this.SchoolSkill,
                TrackingState: TRACKING_STATE.CREATE
            });

            this.SchoolSkill = null;
        },
        addCertificate() {

            if (this.CertificateName == null) {
                this.$notify(
                    {
                        title: this.trans('ERROR'),
                        text: this.trans("CERTIFICATE-NAME-EMPTY"),
                        type: 'error',
                    });

                return;
            }


            let lang = lodash.filter(this.ProfileObj.StudentCertificatesList, x => x.Name == this.CertificateName);
            if (lang != null && lang.length > 0)
                return;

            if (this.ProfileObj.StudentCertificatesList == null)
                this.ProfileObj.StudentCertificatesList = [];

            this.ProfileObj.StudentCertificatesList.push({
                PkStudentCertificate: uuid.v4(),
                FkStudent: this.ProfileObj.PkStudentProfile,
                Name: this.CertificateName,
                TrackingState: TRACKING_STATE.CREATE
            });

            this.CertificateName = null;
        },
        addHobby() {

            if (this.HobbiesName == null) {
                this.$notify(
                    {
                        title: this.trans('ERROR'),
                        text: this.trans("HOBBY-NAME-EMPTY"),
                        type: 'error',
                    });

                return;
            }


            let lang = lodash.filter(this.ProfileObj.StudentHobbiesList, x => x.Name == this.HobbiesName);
            if (lang != null && lang.length > 0)
                return;

            if (this.ProfileObj.StudentHobbiesList == null)
                this.ProfileObj.StudentHobbiesList = [];

            this.ProfileObj.StudentHobbiesList.push({
                PkStudentHobby: uuid.v4(),
                FkStudent: this.ProfileObj.PkStudentProfile,
                Name: this.HobbiesName,
                TrackingState: TRACKING_STATE.CREATE
            });

            this.HobbiesName = null;
        },
        addSuccess() {

            if (this.SuccessName == null) {
                this.$notify(
                    {
                        title: this.trans('ERROR'),
                        text: this.trans("SUCCESS-NAME-EMPTY"),
                        type: 'error',
                    });

                return;
            }


            let lang = lodash.filter(this.ProfileObj.StudentSuccessesList, x => x.Name == this.SuccessName);
            if (lang != null && lang.length > 0)
                return;

            if (this.ProfileObj.StudentSuccessesList == null)
                this.ProfileObj.StudentSuccessesList = [];

            this.ProfileObj.StudentSuccessesList.push({
                PkStudentSuccess: uuid.v4(),
                FkStudent: this.ProfileObj.PkStudentProfile,
                Name: this.SuccessName,
                TrackingState: TRACKING_STATE.CREATE
            });

            this.SuccessName = null;
        },
        addStrength() {

            if (this.StrengthName == null) {
                this.$notify(
                    {
                        title: this.trans('ERROR'),
                        text: this.trans("STRENGTH-NAME-EMPTY"),
                        type: 'error',
                    });

                return;
            }


            let lang = lodash.filter(this.ProfileObj.StudentStrengthList, x => x.Name == this.StrengthName);
            if (lang != null && lang.length > 0)
                return;

            if (this.ProfileObj.StudentStrengthList == null)
                this.ProfileObj.StudentStrengthList = [];

            this.ProfileObj.StudentStrengthList.push({
                PkStudentStrength: uuid.v4(),
                FkStudent: this.ProfileObj.PkStudentProfile,
                Name: this.StrengthName,
                TrackingState: TRACKING_STATE.CREATE
            });

            this.StrengthName = null;
        },
        addLanguage() {

            if (this.LanguageName == null || this.LanguageSkill === null) {
                this.$notify(
                    {
                        title: this.trans('ERROR'),
                        text: this.trans("LANGUAGE-NAME-SKILL-EMPTY"),
                        type: 'error',
                    });

                return;
            }


            let lang = lodash.filter(this.ProfileObj.StudentLanguagesList, x => x.Language == this.LanguageName);
            if (lang != null && lang.length > 0)
                return;

            if (this.ProfileObj.StudentLanguagesList == null)
                this.ProfileObj.StudentLanguagesList = [];

            this.ProfileObj.StudentLanguagesList.push({
                PkStudentLanguage: uuid.v4(),
                FkStudent: this.ProfileObj.PkStudentProfile,
                Language: this.LanguageName,
                Skill: this.LanguageSkill,
                TrackingState: TRACKING_STATE.CREATE
            });

            this.LanguageName = null;
            this.LanguageSkill = null;
        },
        removeVideo1() {

            let video = document.getElementById('video-preview1');
            video.pause();
            video.removeAttribute('src'); // empty source
            video.load();
            this.Video1 = null;
            store.dispatch('uploadVideoFile', {
                PkUser: this.ProfileObj.FkUser,
                VideoNo: 1,
                OnlyDelete: true
            }).then(res => {
                if (res.IsError) {
                    this.$notify(
                        {
                            title: this.trans('ERROR'),
                            text: this.trans(res.ErrorMessage),
                            type: 'error',
                        });
                }
            });
        },
        removeVideo2() {

            let video = document.getElementById('video-preview2');
            video.pause();
            video.removeAttribute('src'); // empty source
            video.load();
            this.Video2 = null;
            store.dispatch('uploadVideoFile', {
                PkUser: this.ProfileObj.FkUser,
                VideoNo: 2,
                OnlyDelete: true
            }).then(res => {
                if (res.IsError) {
                    this.$notify(
                        {
                            title: this.trans('ERROR'),
                            text: this.trans(res.ErrorMessage),
                            type: 'error',
                        });
                }
            });
        },
        removeVideo3() {

            let video = document.getElementById('video-preview3');
            video.pause();
            video.removeAttribute('src'); // empty source
            video.load();
            this.Video3 = null;
            store.dispatch('uploadVideoFile', {
                PkUser: this.ProfileObj.FkUser,
                VideoNo: 3,
                OnlyDelete: true
            }).then(res => {
                if (res.IsError) {
                    this.$notify(
                        {
                            title: this.trans('ERROR'),
                            text: this.trans(res.ErrorMessage),
                            type: 'error',
                        });
                }
            });
        },
        uploadVideo1() {

            if ((event.target.files[0].size / 1024 / 1024) > 20) {
                this.$notify(
                    {
                        title: this.trans('ERROR'),
                        text: this.trans("VIDEO-FILE-SIZE-EXCEED-20"),
                        type: 'error',
                    });
                return;
            }

            this.Video1 = event.target.files[0];

            this.error = false;
            this.errorMessage = null


            if (!this.Video1) {
                event.preventDefault();
                event.target.value = null;
                return;
            }


            store.dispatch('uploadVideoFile', {
                Video: this.Video1,
                PkUser: this.ProfileObj.FkUser,
                VideoNo: 1
            }).then(res => {
                if (res.IsError) {
                    this.$notify(
                        {
                            title: this.trans('ERROR'),
                            text: this.trans(res.ErrorMessage),
                            type: 'error',
                        });
                } else {
                    this.previewVideo1();
                }
            });


        },
        previewVideo1() {
            let video = document.getElementById('video-preview1');
            let reader = new FileReader();

            reader.readAsDataURL(this.Video1);
            reader.addEventListener('load', function () {
                video.src = reader.result;
            });
        },
        uploadVideo2() {
            if ((event.target.files[0].size / 1024 / 1024) > 20) {
                this.$notify(
                    {
                        title: this.trans('ERROR'),
                        text: this.trans("VIDEO-FILE-SIZE-EXCEED-20"),
                        type: 'error',
                    });
                return;
            }

            this.Video2 = event.target.files[0];

            this.error = false;
            this.errorMessage = null


            if (!this.Video2) {
                event.preventDefault();
                event.target.value = null;
                return;
            }


            store.dispatch('uploadVideoFile', {
                Video: this.Video2,
                PkUser: this.ProfileObj.FkUser,
                VideoNo: 2
            }).then(res => {
                if (res.IsError) {
                    this.$notify(
                        {
                            title: this.trans('ERROR'),
                            text: this.trans(res.ErrorMessage),
                            type: 'error',
                        });
                } else {
                    this.previewVideo2();
                }
            });
        },
        previewVideo2() {
            let video = document.getElementById('video-preview2');
            let reader = new FileReader();

            reader.readAsDataURL(this.Video2);
            reader.addEventListener('load', function () {
                video.src = reader.result;
            });
        },
        uploadVideo3() {
            if ((event.target.files[0].size / 1024 / 1024) > 20) {
                this.$notify(
                    {
                        title: this.trans('ERROR'),
                        text: this.trans("VIDEO-FILE-SIZE-EXCEED-20"),
                        type: 'error',
                    });
                return;
            }

            this.Video3 = event.target.files[0];

            this.error = false;
            this.errorMessage = null


            if (!this.Video3) {
                event.preventDefault();
                event.target.value = null;
                return;
            }


            store.dispatch('uploadVideoFile', {
                Video: this.Video3,
                PkUser: this.ProfileObj.FkUser,
                VideoNo: 3
            }).then(res => {
                if (res.IsError) {
                    this.$notify(
                        {
                            title: this.trans('ERROR'),
                            text: this.trans(res.ErrorMessage),
                            type: 'error',
                        });
                } else {
                    this.previewVideo3();
                }
            });
        },
        previewVideo3() {
            let video = document.getElementById('video-preview3');
            let reader = new FileReader();

            reader.readAsDataURL(this.Video3);
            reader.addEventListener('load', function () {
                video.src = reader.result;
            });
        },
        change_file() {

            let file = event.target.files[0];
            this.error = false;
            this.errorMessage = null
            let MAX_FILE_SIZE = 8 * 1024 * 1024;
            let data = this.model;
            let controller = this.controller;


            if (!file) {
                event.preventDefault();
                event.target.value = null;
                return;
            }

            if (file.size > MAX_FILE_SIZE) {
                event.preventDefault();
                event.target.value = null;
                this.error = true;
                this.errorMessage = "FILE-TO-BIG"
                return;
            }
            let fileReader = new FileReader();

            fileReader.onloadend = function (e) {
                let arr = (new Uint8Array(e.target.result)).subarray(0, 4);

                let header = "";
                for (let i = 0; i < arr.length; i++) {
                    header += arr[i].toString(16);
                }

                let type = "";
                switch (header) {
                    case "89504e47":
                        type = "image/png";
                        break;
                    case "47494638":
                        type = "image/gif";
                        break;
                    case "ffd8ffe0":
                    case "ffd8ffe1":
                    case "ffd8ffe2":
                    case "ffd8ffe3":
                    case "ffd8ffe8":
                        type = "image/jpeg";
                        break;
                    case "255044462D":
                    case "25504446":
                        type = "pdf";
                        break;
                    default:
                        type = "unknown"; // Or you can use the blob.type as fallback
                        break;
                }

                if (type !== "unknown") {
                    data.FileName = file.name;
                    data.ProfilePicture = file;
                    store.dispatch('uploadProfilePicture', {
                        ProfilePicture: data.ProfilePicture,
                        PkUser: data.PkUser
                    }).then(res => {
                        controller.GetProfilePicture(data.PkUser).then(result => {

                        })
                    });

                } else {
                    event.preventDefault();
                    event.target.value = null;

                }
            }

            fileReader.readAsArrayBuffer(file);

        },
        GetDateString(cv) {
            if (cv === null)
                return this.trans('TILL-NOW');

            return getDateStringMonthYear(cv)
        },
        setCarAvailable(value) {
            this.ProfileObj.CarAvailable = value.target.checked;
        },
        setReadyToMove(value) {
            this.ProfileObj.ReadyToMove = value.target.checked;
        },
        setRehearsal(value) {
            this.ProfileObj.Rehearsal = value.target.checked;
        },
        setChangeCompany(value) {
            this.ProfileObj.ChangeCompany = value.target.checked;
        },
        countrySelect(value) {
            this.ProfileObj.Country = value;
        },

        setError(errorMessage = null) {
            if (errorMessage != null) {
                this.errorMessage = errorMessage;
                this.error = true;
            } else {
                this.errorMessage = null;
                this.error = false;
            }
        },
        validate() {
            let form = this.$refs.valForm;
            this.error = false;
            this.errorMessage = "";

            form.validate().then(result => {
                if (result.valid) {
                    this.save();
                } else {
                    return Promise.resolve(false);
                }
            })


        }
    },
    beforeMount() {
        this.controller.read().then(response => {

            this.ProfileObj = response.StudentProfile;
            this.controller.GetProfilePicture(response.StudentProfile.FkUser).then(result => {
                this.selectedImage = URL.createObjectURL(new Blob([result.data]));
                this.controller.GetVideoFile(response.StudentProfile.FkUser, 1).then(result => {
                    if (result != null) {
                        this.Video1 = result;
                        this.previewVideo1();
                    }
                })
                this.controller.GetVideoFile(response.StudentProfile.FkUser, 2).then(result => {
                    if (result != null) {
                        this.Video2 = result;
                        this.previewVideo2();
                    }
                })
                this.controller.GetVideoFile(response.StudentProfile.FkUser, 3).then(result => {
                    if (result != null) {
                        this.Video3 = result;
                        this.previewVideo3();
                    }
                })
            })
        })

    }
}
</script>

<style scoped lang="scss">
.margin-top-15 {
    margin-top: 15px;
}

.videoPlayer {
    max-height: 200px;
    margin-top: 30px;
}

.margin-left-20 {
    margin-left: 20px;
}

input[type="range"]::-webkit-slider-thumb {
    background-color: #9acccb;

}

input[type="range"]::-webkit-slider-runnable-track {
    background: #053a5f;
    height: 0.5rem;
}

input[type="range"]::-moz-range-track {
    background: #053a5f;
    height: 0.5rem;
}

</style>
