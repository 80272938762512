<template>
    <div class="tree-header">
        <div class="tree-header-label float-left">

            <font-awesome-icon :icon="{'fas fa-undo':true, ' active-filter':(filterIsSet)}"
                               style="padding-top:4px"
                               v-if="!removeFilters"
                               @click="resetFilter"/>

        </div>

        <div class="tree-menu float-right">
            <PopOver class="dark margin-left-5" arrow hover openDelay="200" closeDelay="100" placement="right"
                     v-if="hasReload">
                <template #content v-if="reloadPopoverLabel">
                    <span class="popOverText"
                          v-for="(line,lineNumber) of reloadPopoverLabel.split('\n')"
                          v-bind:key="lineNumber">
                        {{ line }}<br/>
                    </span>
                </template>
                <div

                    class="btn-tree-menu edit"
                    @click="reload">
                    <font-awesome-icon icon="fas fa-sync-alt"/>
                </div>
            </PopOver>
            <PopOver class="dark margin-left-5" arrow hover openDelay="200" closeDelay="100" placement="right"
                     v-if="hasAdditionalFilterDialog">
                <template #content v-if="filterPopoverLabel">
                    <span class="popOverText"
                          v-for="(line,lineNumber) of filterPopoverLabel.split('\n')"
                          v-bind:key="lineNumber">
                        {{ line }}<br/>
                    </span>
                </template>
                <div

                    class="btn-tree-menu edit"
                    @click="openFilter">
                    <font-awesome-icon :icon="{'fas fa-filter':true, ' active-filter':(showFilterSet)}"/>
                </div>
            </PopOver>
            <PopOver class="dark margin-left-5" arrow hover openDelay="200" closeDelay="100" placement="right"
                     v-if="hasCopy">
                <template #content v-if="copyPopoverLabel">
                    <span class="popOverText"
                          v-for="(line,lineNumber) of copyPopoverLabel.split('\n')"
                          v-bind:key="lineNumber">
                        {{ line }}<br/>
                    </span>
                </template>
                <div

                    :class="'btn-tree-menu add' + (!hasSelection?' remove-disabled':'')"
                    @click="copy">
                    <font-awesome-icon icon="fas fa-copy"/>
                </div>
            </PopOver>
            <PopOver class="dark margin-left-5" arrow hover openDelay="200" closeDelay="100" placement="right"
                     v-if="hasActivate&&!activeState">
                <template #content v-if="activatePopoverLabel">
                    <span class="popOverText"
                          v-for="(line,lineNumber) of activatePopoverLabel.split('\n')"
                          v-bind:key="lineNumber">
                        {{ line }}<br/>
                    </span>
                </template>
                <div

                    :class="'btn-tree-menu add' + (!hasSelection?' remove-disabled':'')"
                    @click="activateItem">
                    <font-awesome-icon icon="fas fa-unlock"/>
                </div>
            </PopOver>
            <PopOver class="dark margin-left-5" arrow hover openDelay="200" closeDelay="100" placement="right"
                     v-if="hasDeactivate&&activeState">
                <template #content v-if="deactivatePopoverLabel">
                    <span class="popOverText"
                          v-for="(line,lineNumber) of deactivatePopoverLabel.split('\n')"
                          v-bind:key="lineNumber">
                        {{ line }}<br/>
                    </span>
                </template>
                <div

                    :class="'btn-tree-menu remove' + (!hasSelection?' remove-disabled':'')"
                    @click="deactivateItem">
                    <font-awesome-icon icon="fas fa-lock"/>
                </div>
            </PopOver>
            <PopOver class="dark margin-left-5" arrow hover openDelay="200" closeDelay="100" placement="right"
                     v-if="hasRemove">
                <template #content v-if="removePopoverLabel">
                    <span class="popOverText"
                          v-for="(line,lineNumber) of removePopoverLabel.split('\n')"
                          v-bind:key="lineNumber">
                        {{ line }}<br/>
                    </span>
                </template>
                <div

                    :class="'btn-tree-menu remove' + (!hasSelection?' remove-disabled':'')"
                    @click="removeItem">
                    <font-awesome-icon icon="fas fa-trash"/>
                </div>
            </PopOver>
            <PopOver class="dark margin-left-5" arrow hover openDelay="200" closeDelay="100" placement="right"
                     v-if="hasAdd&&withSelection">
                <template #content v-if="addPopoverLabel">
                    <span class="popOverText"
                          v-for="(line,lineNumber) of addPopoverLabel.split('\n')"
                          v-bind:key="lineNumber">
                        {{ line }}<br/>
                    </span>
                </template>
                <div
                    class="btn-tree-menu add"
                    @click="$emit('create')">
                    <font-awesome-icon icon="fas fa-plus-circle"/>
                </div>
            </PopOver>

        </div>
        <div class="clearfix"></div>
    </div>
</template>

<script>


import lodash from "lodash";

export default {
    name: "FilterHeader",
    components: {},
    props: [
        'add-label',
        'remove-label',
        'hasBusiness',
        'deactivate-label',
        'activate-label',
        'hasSelection',
        'activeState',
        'hasReload',
        'hasFilterReset',
        'hasCopy',
        'hasRemove',
        'hasDeactivate',
        'hasActivate',
        'hasAdd',
        'addOnlyWithSelection',
        'removeFilters',
        'hasAdditionalFilterDialog'],
    emits: ['remove', 'deactivate', 'activate', 'openFilterDialog', 'copy', 'reload', 'change', 'create'],
    computed: {
        addPopoverLabel() {
            return this.$props.addLabel ? this.$props.addLabel : this.trans('ADD');
        },
        copyPopoverLabel() {
            return this.trans('COPY');
        },
        removePopoverLabel() {
            return this.$props.removeLabel ? this.$props.removeLabel : this.trans('DELETE');
        },
        activatePopoverLabel() {
            return this.$props.removeLabel ? this.$props.activateLabel : this.trans('ACTIVATE');
        },
        reloadPopoverLabel() {
            return this.trans('RELOAD');
        },
        deactivatePopoverLabel() {
            return this.$props.removeLabel ? this.$props.deactivateLabel : this.trans('DEACTIVATE');
        },
        filterPopoverLabel() {
            return this.trans('FILTER-DIALOG');
        },
        showFilterSet() {
            return this.$props.hasFilterSet == true;
        },
        withSelection() {
            if (this.$props.addOnlyWithSelection != true)
                return true;
            else return !!(this.$props.addOnlyWithSelection == true && this.$props.hasSelection);
        },
    },
    data() {
        return {
            filterIsSet: false,
            BusinessListValues: []
        }
    },
    watch: {
        BusinessListValues(val) {
            this.isFilterSetCheck();
            this.filterChange();
        }
    },
    methods: {

        removeItem() {
            if (this.$props.hasSelection)
                this.$emit('remove');
        },
        deactivateItem() {
            if (this.$props.hasSelection && this.$props.activeState)
                this.$emit('deactivate');
        },
        activateItem() {
            if (this.$props.hasSelection && !this.$props.activeState)
                this.$emit('activate');
        },
        openFilter() {
            this.$emit('openFilterDialog');
        },
        copy() {
            if (this.$props.hasSelection)
                this.$emit('copy');
        },
        reload() {
            this.$emit('reload');
        },
        filterChange() {

            let data = {
                BusinessListValues: this.BusinessListValues
            }
            this.$emit('change', data);
        },
        isFilterSetCheck() {


            this.filterIsSet = Array.isArray(this.BusinessListValues) && this.BusinessListValues.length > 0;
        },
        resetFilter() {
            this.BusinessListValues = [];
            this.filterIsSet = false;

            this.$emit('change', {});
        },
        isFilterActive() {
            if (!Array.isArray(this.BusinessListValues) || this.BusinessListValues.length <= 0)
                return '#858796';

            return '#ffaf37';
        },
    },
    beforeMount() {
    }
}
</script>

<style scoped>

.tree-header {
    position: relative;
    display: flex;
    background-color: transparent;
    padding: 5px 10px 5px 10px;
    font-weight: bold;
    font-size: 20px;
    height: 40px;
    box-shadow: -2px 4px 10px -6px rgba(0, 0, 0, 0.45);
    border-bottom: 1px solid #9acccb;
}

.tree-header-label {
    font-size: 16px;
    padding-top: 4px;
    display: flex;
    width: calc(100% - 115px);
}

.tree-menu {
    position: relative;
    display: flex;
    width: calc(40%);
    justify-content: flex-end;
}

.tree-menu-disable-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #dddddd;
    opacity: 0.5;
}


.btn-tree-menu {
    margin-left: 5px;
    cursor: pointer;
    color: #fdb82a;
    transition: color .1s;
    float: right;
}

.btn-tree-menu:hover {
    color: #9acccb;
}

.btn-tree-menu:hover.remove {
    color: red;
}

.btn-tree-menu:hover.add {
    color: #9acccb;
}

.active-filter {
    color: #ffaf37;
}

.remove-disabled {
    opacity: .4;
    cursor: not-allowed;
}

.btn-tree-menu:hover.remove.remove-disabled {
    color: #858796;
}


.selector-wrap {
    display: inline-block;
    width: calc(100% - 50px);
}

.label {
    display: inline-block;
    font-weight: bold;
    padding-top: 7px;
    padding-left: 10px;
}

.overlay {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    opacity: 0.8;
}

.required-span {
    margin-left: -10px;
    padding-right: 10px;
    margin-top: 15px;
}

.no-left-padding > .input {
    padding-left: 0px !important;
}

.dark {
    --popper-theme-background-color: #333333;
    --popper-theme-background-color-hover: #333333;
    --popper-theme-text-color: white;
    --popper-theme-border-width: 0px;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 15px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

:deep(.popper #arrow::before) {
    left: 0px;
}

.popOverText {
    color: white;
    font-size: 14px;
    font-weight: 500;
}

.margin-left-5 {
    margin-left: 1px !important;
}
</style>
