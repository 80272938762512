import Dashboard from '../views/dashboard/Dashboard.vue';
import VerifyUser from "../views/login/VerifyUser.vue";
import EnterNewPassword from '../views/login/EnterNewPassword.vue';
import PasswordForgotten from '../views/login/PasswordForgotten.vue';
import RegisterSuccess from '../views/register/RegisterSuccess.vue';
import RegisterWizard from '../views/register/RegisterWizard.vue';
import AGBReaccept from '../views/login/AGBReaccept.vue';
import MFACheck from '../views/login/MFACheck';
import Imprint from '../views/login/Imprint';
import DataProtection from '../views/login/DataProtection';
import AGB from '../views/login/AGB';
import NotFoundPage from '../views/PageNotFound404/error404.vue';

import LandingPageCompany from "@/views/landingpages/LandingPageCompany.vue";
import Pricing from "@/views/pricing/Pricing.vue";
import RegisterStudent from "@/views/register/student/RegisterStudent.vue";
import Calendar from "@/views/appointments/Calendar.vue";
import {CHECK_REGISTER_TYPES} from "@/data/RegisterTypes";
import {LOGIN_STATE} from "@/data/LoginStates";
import StudentProfileView from "@/views/student/StudentProfileView.vue";
import StudentProfileEdit from "@/views/student/StudentProfileEdit.vue";
import VerifyParentEmail from "@/views/login/VerifyParentEmail.vue";
import StudentProfilePublicView from "@/views/search/StudentProfilePublicView.vue";
import MasterData from "@/views/administration/MasterData.vue";
import MasterDataCompany from "@/views/administration/company/MasterDataCompany.vue";
import MasterDataOffice from "@/views/administration/office/MasterDataOffice.vue";
import Users from "@/views/administration/users/Users.vue";
import Licenses from "@/views/administration/licenses/Licenses.vue";
import Invoices from "@/views/administration/invoices/Invoices.vue";
import MasterDataJobAds from "@/views/administration/jobads/MasterDataJobAds.vue";
import ComingSoon from "@/views/CommingSoon/ComingSoon.vue";
import SearchView from "@/views/search/SearchView.vue";
import FavouritesView from "@/views/Favourites/FavouritesView.vue";
import OfficeProfilePublicView from "@/views/search/OfficeProfilePublicView.vue";
import CompanyProfilePublicView from "@/views/search/CompanyProfilePublicView.vue";
import JobAdProfilePublicView from "@/views/search/JobAdProfilePublicView.vue";
import OfficeProfileView from "@/views/administration/office/OfficeProfileView.vue";
import JobAdProfileView from "@/views/administration/jobads/JobAdProfileView.vue";

const defaultRoutes = [
    {
        path: '/LandingPageCompany',
        name: 'LandingPageCompany',
        CheckIfProd: false,
        component: LandingPageCompany
    },
    {
        path: '/LandingPageStudent',
        name: 'LandingPageStudent',
        CheckIfProd: false,
        component: () => import("@/views/landingpages/LandingPageStudent.vue")
    },

    {
        path: '/RegisterWizard',
        name: 'RegisterWizard',
        CheckIfProd: false,
        component: RegisterWizard
    },
    {
        path: '/RegisterStudent',
        name: 'RegisterStudent',
        CheckIfProd: false,
        component: RegisterStudent
    },
    {
        path: '/Pricing',
        name: 'Pricing',
        CheckIfProd: false,
        component: Pricing
    },
    {
        name: 'RegisterSuccessWithoutProps',
        path: '/RegisterSuccess',
        CheckIfProd: false,
        component: RegisterSuccess
    },
    {
        name: 'RegisterSuccess',
        path: '/RegisterSuccess/:FkUser/:FileHash',
        CheckIfProd: false,
        component: RegisterSuccess
    },
    {
        name: 'StudentProfilePublicView',
        path: '/StudentProfilePublicView/:FkUser',
        CheckIfUserType: CHECK_REGISTER_TYPES.COMPANY_OR_SCHOOL,
        checkLoginState: LOGIN_STATE.LOGGED_IN,
        CheckIfProd: false,
        component: StudentProfilePublicView
    },
    {
        name: 'OfficeProfilePublicView',
        path: '/OfficeProfilePublicView/:FkCompany/:FkOffice',
        CheckIfUserType: CHECK_REGISTER_TYPES.STUDENT,
        checkLoginState: LOGIN_STATE.LOGGED_IN,
        CheckIfProd: false,
        component: OfficeProfilePublicView
    },
    {
        name: 'OfficeProfileView',
        path: '/OfficeProfileView/:FkOffice',
        CheckIfUserType: CHECK_REGISTER_TYPES.COMPANY_OR_SCHOOL,
        checkLoginState: LOGIN_STATE.LOGGED_IN,
        CheckIfProd: false,
        component: OfficeProfileView
    },
    {
        name: 'JobAdProfileView',
        path: '/JobAdProfileView/:FkOffice/:FkJobAd',
        CheckIfUserType: CHECK_REGISTER_TYPES.COMPANY,
        checkLoginState: LOGIN_STATE.LOGGED_IN,
        CheckIfProd: false,
        component: JobAdProfileView
    },
    {
        name: 'JobAdProfilePublicView',
        path: '/JobAdProfilePublicView/:FkCompany/:FkOffice/:FkJobAd',
        CheckIfUserType: CHECK_REGISTER_TYPES.STUDENT,
        checkLoginState: LOGIN_STATE.LOGGED_IN,
        CheckIfProd: false,
        component: JobAdProfilePublicView
    },
    {
        name: 'CompanyProfilePublicView',
        path: '/CompanyProfilePublicView/:FkCompany',
        CheckIfUserType: CHECK_REGISTER_TYPES.STUDENT,
        checkLoginState: LOGIN_STATE.LOGGED_IN,
        CheckIfProd: false,
        component: CompanyProfilePublicView
    },
    {
        path: '/Config/MasterData/',
        name: 'MasterData',
        title: 'MAIN-DATA',
        icon: 'fas fa-gears',
        checkLoginState: LOGIN_STATE.LOGGED_IN,
        CheckIfUserType: CHECK_REGISTER_TYPES.COMPANY_OR_SCHOOL,
        CheckIfProd: false,
        component: MasterData
    },
    {
        path: '/Config/MasterData/MasterDataCompany',
        name: 'MasterDataCompany',
        title: 'COMPANY',
        icon: 'fas fa-building',
        checkLoginState: LOGIN_STATE.LOGGED_IN,
        CheckIfUserType: CHECK_REGISTER_TYPES.COMPANY_OR_SCHOOL,
        CheckIfProd: false,
        component: MasterDataCompany
    },
    {
        path: '/Config/MasterData/MasterDataOffice',
        name: 'MasterDataOffice',
        title: 'OFFICES',
        icon: 'fas fa-building',
        checkLoginState: LOGIN_STATE.LOGGED_IN,
        CheckIfUserType: CHECK_REGISTER_TYPES.COMPANY_OR_SCHOOL,
        CheckIfProd: false,
        component: MasterDataOffice
    },
    {
        path: '/Config/MasterData/Users',
        name: 'Users',
        title: 'USERS',
        icon: 'fas fa-user',
        checkLoginState: LOGIN_STATE.LOGGED_IN,
        CheckIfUserType: CHECK_REGISTER_TYPES.COMPANY_OR_SCHOOL,
        CheckIfProd: false,
        component: Users
    },
    {
        path: '/Config/MasterData/Users',
        name: 'Users',
        title: 'USERS',
        icon: 'fas fa-user',
        checkLoginState: LOGIN_STATE.LOGGED_IN,
        CheckIfUserType: CHECK_REGISTER_TYPES.COMPANY_OR_SCHOOL,
        CheckIfProd: false,
        component: Users
    },
    {
        path: '/Config/MasterData/JobAds',
        name: 'JobAds',
        title: 'JOB-ADS',
        icon: 'fas fa-clipboard',
        checkLoginState: LOGIN_STATE.LOGGED_IN,
        CheckIfUserType: CHECK_REGISTER_TYPES.COMPANY,
        CheckIfProd: false,
        component: MasterDataJobAds
    },
    {
        path: '/Config/MasterData/Invoices',
        name: 'Invoices',
        title: 'INVOICES',
        icon: 'fas file-invoice',
        checkLoginState: LOGIN_STATE.LOGGED_IN,
        CheckIfUserType: CHECK_REGISTER_TYPES.COMPANY,
        CheckIfProd: false,
        component: Invoices
    },
    {
        path: '/SearchView',
        name: 'SearchView',
        title: 'SEARCH',
        icon: 'fas fa-magnifying-glass',
        checkLoginState: LOGIN_STATE.LOGGED_IN,
        CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
        CheckIfProd: false,
        component: SearchView
    },
    {
        path: '/FavouritesView',
        name: 'FavouritesView',
        title: 'MY-FAVOURITES',
        icon: 'fas fa-star',
        checkLoginState: LOGIN_STATE.LOGGED_IN,
        CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
        CheckIfProd: false,
        component: FavouritesView
    },
    {
        path: '/Config/MasterData/LicensesView',
        name: 'Licenses',
        title: 'LICENSES',
        icon: 'fas fa-euro-sign',
        checkLoginState: LOGIN_STATE.LOGGED_IN,
        CheckIfUserType: CHECK_REGISTER_TYPES.COMPANY,
        CheckIfProd: false,
        component: Licenses
    },
    {
        name: 'MFACheck',
        path: '/MFACheck',
        CheckIfProd: false,
        component: MFACheck
    },
    {
        name: 'AGBReaccept',
        path: '/AGBReaccept',
        CheckIfProd: false,
        component: AGBReaccept
    },
    {
        name: 'RoadMap',
        path: '/RoadMap',
        CheckIfProd: false,
        component: ComingSoon
    },
    {
        name: 'Imprint',
        path: '/Imprint',
        CheckIfProd: false,
        component: Imprint
    },
    {
        name: 'AGB',
        path: '/AGB',
        CheckIfProd: false,
        component: AGB
    },
    {
        name: 'DataProtection',
        path: '/DataProtection',
        CheckIfProd: false,
        component: DataProtection
    },
    {
        path: '/',
        name: 'Dashboard',
        CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
        checkLoginState: LOGIN_STATE.LOGGED_IN,
        CheckIfProd: false,
        component: Dashboard
    },
    {
        path: '/Calendar',
        name: 'Calendar',
        CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
        checkLoginState: LOGIN_STATE.LOGGED_IN,
        CheckIfProd: false,
        component: Calendar
    },
    {
        path: '/StudentProfile/StudentProfileView',
        name: 'StudentProfileView',
        CheckIfUserType: CHECK_REGISTER_TYPES.STUDENT,
        checkLoginState: LOGIN_STATE.LOGGED_IN,
        CheckIfProd: false,
        component: StudentProfileView
    },
    {
        path: '/StudentProfile/StudentProfileEdit',
        name: 'StudentProfileEdit',
        CheckIfUserType: CHECK_REGISTER_TYPES.STUDENT,
        checkLoginState: LOGIN_STATE.LOGGED_IN,
        CheckIfProd: false,
        component: StudentProfileEdit
    },
    {
        path: '/VerifyUser/:FkUser/:HashCode',
        name: 'VerifyUser',
        CheckIfProd: false,
        component: VerifyUser
    },
    {
        path: '/VerifyParentEmail/:FkUser/:HashCode',
        name: 'VerifyParentEmail',
        CheckIfProd: false,
        component: VerifyParentEmail
    },
    {
        path: '/EnterNewPassword/:PkUser/:HashCode',
        name: 'EnterNewPassword',
        CheckIfProd: false,
        component: EnterNewPassword
    },
    {
        path: '/PasswordForgotten',
        name: 'PasswordForgotten',
        CheckDstBoth: false,
        component: PasswordForgotten
    },
    {
        name: 'Mainsite',
        CheckDstBoth: false,
        path: '/Mainsite/:path'
    },
    {
        name: 'PageNotFound',
        CheckDstBoth: false,
        path: '/:pathMatch(.*)*',
        component: NotFoundPage
    }
];

export default defaultRoutes;
