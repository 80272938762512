import {restBody} from "@/utils/restBody";
import axios from "axios";
import {restUri} from "@/data/restPaths";

export function uploadProfilePicture(store, data) {


    let formData = new FormData();
    if (data.ProfilePicture != null && data.ProfilePicture !== "undefined")
        formData.append('ProfilePicture', data.ProfilePicture)

    formData.append('json', JSON.stringify(restBody({PkUser: data.PkUser})))

    return axios.post(restUri.UPLOAD_PROFILE_PICTURE, formData)
        .then(result => {
            let data = result.data;

            if (!data.IsError) {
            }

            return data;
        }).catch(result => {
            return result.data;
        })
}

export function uploadVideoFile(store, data) {


    let formData = new FormData();
    if (data.Video != null && data.Video !== "undefined")
        formData.append('Video', data.Video)

    formData.append('json', JSON.stringify(restBody({
        PkUser: data.PkUser,
        VideoNo: data.VideoNo,
        OnlyDelete: data.OnlyDelete
    })))

    return axios.post(restUri.UPLOAD_VIDEO_FILE, formData)
        .then(result => {
            let data = result.data;

            if (!data.IsError) {
            }

            return data;
        }).catch(result => {
            return result.data;
        })
}


export function uploadProfilePictureOffice(store, data) {


    let formData = new FormData();
    if (data.ProfilePicture != null && data.ProfilePicture !== "undefined")
        formData.append('ProfilePicture', data.ProfilePicture)

    formData.append('json', JSON.stringify(restBody({PkCompany: data.PkCompany, PkOffice: data.PkOffice})))

    return axios.post(restUri.UPLOAD_PROFILE_PICTURE_OFFICE, formData)
        .then(result => {
            let data = result.data;

            if (!data.IsError) {
            }

            return data;
        }).catch(result => {
            return result.data;
        })
}

export function uploadProfilePictureCompany(store, data) {


    let formData = new FormData();
    if (data.ProfilePicture != null && data.ProfilePicture !== "undefined")
        formData.append('ProfilePicture', data.ProfilePicture)

    formData.append('json', JSON.stringify(restBody({PkCompany: data.PkCompany})))

    return axios.post(restUri.UPLOAD_PROFILE_PICTURE_COMPANY, formData)
        .then(result => {
            let data = result.data;

            if (!data.IsError) {
            }

            return data;
        }).catch(result => {
            return result.data;
        })
}

export function uploadVideoFileOffice(store, data) {


    let formData = new FormData();
    if (data.Video != null && data.Video !== "undefined")
        formData.append('Video', data.Video)

    formData.append('json', JSON.stringify(restBody({
        PkCompany: data.PkCompany,
        PkOffice: data.PkOffice,
        VideoNo: data.VideoNo,
        OnlyDelete: data.OnlyDelete
    })))

    return axios.post(restUri.UPLOAD_VIDEO_FILE_OFFICE, formData)
        .then(result => {
            let data = result.data;

            if (!data.IsError) {
            }

            return data;
        }).catch(result => {
            return result.data;
        })
}

export function uploadProfilePictureJobAd(store, data) {


    let formData = new FormData();
    if (data.ProfilePicture != null && data.ProfilePicture !== "undefined")
        formData.append('ProfilePicture', data.ProfilePicture)

    formData.append('json', JSON.stringify(restBody({PkCompany: data.PkCompany, PkJobAd: data.PkJobAd})))

    return axios.post(restUri.UPLOAD_PROFILE_PICTURE_JOB_AD, formData)
        .then(result => {
            let data = result.data;

            if (!data.IsError) {
            }

            return data;
        }).catch(result => {
            return result.data;
        })
}

export function uploadVideoFileJobAd(store, data) {


    let formData = new FormData();
    if (data.Video != null && data.Video !== "undefined")
        formData.append('Video', data.Video)

    formData.append('json', JSON.stringify(restBody({
        PkCompany: data.PkCompany,
        PkJobAd: data.PkJobAd,
        OnlyDelete: data.OnlyDelete
    })))

    return axios.post(restUri.UPLOAD_VIDEO_FILE_JOB_AD, formData)
        .then(result => {
            let data = result.data;

            if (!data.IsError) {
            }

            return data;
        }).catch(result => {
            return result.data;
        })
}
