import i18n from '../i18n.js';



const
    countriesPerLanguagePaths = require.context('../locales/countries/', true,  /(.*)\.countries\.js$/i),
    foundlanguages = [],
    countriesPerLanguage = {};


countriesPerLanguagePaths.keys().forEach(path =>{
    const matched = path.match(/([A-Za-z0-9-_]+)\./i);
    foundlanguages.push(matched[1]);
    countriesPerLanguage[matched[1]] = countriesPerLanguagePaths(path).default;
});

/**
 * 1 - Deutschland
 2 - Österreich
 3 - Schweiz
 4 - Italien
 5 - Frankreich
 6 - Holland
 7 - Spanien
 8 - Polen
 9 - Schweden
 10 - England
 11 - Portugal
 12 - Russland
 */

let computedCountryArr = [];


[
    null,
    "DE",
    "AT",
    "CH",
    "IT",
    "FR",
    "NL",
    "ES",
    "PL",
    "SE",
    "GB",
    "PT",
    "RU"
].forEach( (tag, index) =>{
    if(!tag)
        return;

    i18n.fallbackLocale

    let obj = {
        index,
        tag,
        labels: {}
    }

    foundlanguages.forEach( locale =>{
        let fallbackLabel = (countriesPerLanguage[i18n.fallbackLocale] !=null &&
                             countriesPerLanguage[i18n.fallbackLocale][tag] !=null) ?
                                countriesPerLanguage[i18n.fallbackLocale][tag] : null;


        obj.labels[locale] = (countriesPerLanguage[locale] !=null &&
            countriesPerLanguage[locale][tag] !=null) ?
            countriesPerLanguage[locale][tag] : fallbackLabel;

    });
    computedCountryArr.push(obj);

});


export const countries = computedCountryArr;

