<template>
    <div class="page-section">
        <div class="impressum">
            <div class="container">
                <div class="headline-box-green">{{ trans('IMPRINT') }}</div>
                <div class="impressum-flex">
                    <div class="impressum-text-box">
                        <div class="headline">{{ trans('TMG') }}</div>
                        <div class="text">
                            Jennifer Schiradin <br/>JoBiNeo - DIE Bewerbungsplattform
                            <br/>Rüthstr. 9 <br/>65189 Wiesbaden
                        </div>
                    </div>
                    <div class="impressum-text-box">
                        <div class="headline">{{ trans('CONTACT') }}</div>
                        <div class="text">
                            {{ trans('TELEFON-IMPRINT') }} <br/>{{ trans('EMAIL-IMPRINT') }} hi@jobineo.de
                        </div>
                    </div>
                    <div class="impressum-text-box">
                        <div class="text">{{ trans('EU-IMPRINT') }}
                            <br/>{{ trans('EU-TEXT-IMPRINT') }}
                        </div>
                    </div>
                    <div class="impressum-text-box">
                        <div class="text">{{ trans('ANGER-IMPRINT') }}
                            <br/>{{ trans('ANGER-TEXT-IMPRINT') }}
                        </div>
                    </div>
                    <div class="impressum-text-box">
                        <div class="text">{{ trans('SOURCE-IMPRINT') }}</div>
                    </div>
                </div>
            </div>
        </div>
        <site-footer></site-footer>
    </div>
</template>

<script>

import router from "@/router";
import SiteFooter from "@/components/SiteFooter.vue";

export default {
    name: "imprint-comp",
    components: {SiteFooter},
    methods: {
        toStudent() {
            router.push("/LandingPageStudent")
        },
        register() {
            router.push('/RegisterWizard');
        },
    }
}
</script>

<style scoped src="@/scss/jobineo/output.css">
a:hover {
    cursor: pointer !important;
}
</style>
