import UserAccount from '../views/administration/useraccount/UserAccount.vue'
import Profile from '../views/administration/useraccount/Profile.vue'
import MessageView from '../views/administration/messages/MessagesView.vue'
import EditAccount from '../views/administration/useraccount/EditAccount.vue'
import {flattenRoutePathTree} from "@/utils/flattenRoutePathTree";
import UserNotifications from "../views/administration/useraccount/UserNotifications";
import Login from "@/views/login/Login.vue";
import {LOGIN_STATE} from "@/data/LoginStates";
import RegisterWizard from "@/views/register/RegisterWizard.vue";
import RegisterStudent from "@/views/register/student/RegisterStudent.vue";
import {CHECK_REGISTER_TYPES} from "@/data/RegisterTypes";

const routesLoggedOff = [
    {
        name: 'j-logoff',
        path: '/',
        title: 'MENU',
        ignore: true,
        setDivider: false,
        CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
        checkLoginState: LOGIN_STATE.IGNORE,
        CheckIfProd: false,
        children: [

            {
                path: 'RegisterStudent',
                name: 'register-student',
                title: 'REGISTER-STUDENT',
                setDivider: false,
                icon: 'fas fa-user',
                CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
                component: RegisterStudent,
                checkLoginState: LOGIN_STATE.LOGGED_OFF,
                CheckIfProd: false,
                meta: {layout: 'auth'},
            },
            {
                path: 'RegisterWizard',
                name: 'register-wizard',
                title: 'REGISTER-COMPANY',
                icon: 'fas fa-building',
                CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
                component: RegisterWizard,
                checkLoginState: LOGIN_STATE.LOGGED_OFF,
                CheckIfProd: false,
                setDivider: true
            },
            {
                path: 'Login',
                name: 'Login',
                title: 'SIGN-IN',
                CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
                icon: 'fas fa-arrow-right-to-bracket',
                checkLoginState: LOGIN_STATE.LOGGED_OFF,
                component: Login,
                CheckIfProd: false,
                meta: {layout: 'auth'},
            }
        ]
    }
];

const routesLoggedIn = [
    {
        path: '/UserAccount/',
        name: 'user-account',
        component: UserAccount,
        ignore: true,
        CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
        title: 'ACCOUNT',
        children: [
            {
                path: 'Profile',
                name: 'j-profile',
                title: 'PROFILE',
                icon: 'fas fa-user-alt',
                CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
                component: Profile,
                checkLoginState: LOGIN_STATE.LOGGED_IN,
                CheckIfProd: false,
            },
            {
                path: 'Messages',
                name: 'j-messages',
                title: 'MESSAGES',
                CheckIfUserType: CHECK_REGISTER_TYPES.COMPANY_OR_STUDENT,
                icon: 'fas fa-envelope',
                checkLoginState: LOGIN_STATE.LOGGED_IN,
                component: MessageView,
                CheckIfProd: false,
            },
            {
                path: 'EditAccount',
                ignore: true,
                name: 'edit-account',
                title: 'EDIT-ACCOUNT',
                icon: 'fas fa-user-edit',
                CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
                checkLoginState: LOGIN_STATE.LOGGED_IN,
                component: EditAccount,
                CheckIfProd: false,
            },
            {
                path: 'UserNotifications',
                name: 'UserNotifications',
                title: 'USER-NOTIFICATIONS',
                CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
                icon: 'fas fa-envelope',
                checkLoginState: LOGIN_STATE.LOGGED_IN,
                component: UserNotifications,
                CheckIfProd: false,
            },
            {
                path: 'Logout',
                name: 'Logout',
                title: 'SIGN-OUT',
                CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
                icon: 'fas fa-arrow-right-from-bracket',
                checkLoginState: LOGIN_STATE.LOGGED_IN,
                CheckIfProd: false,
            }
        ]
    }
];

const flattenAccountLoggedOffPathTree = flattenRoutePathTree(routesLoggedOff);
const flattenAccountLoggedInPathTree = flattenRoutePathTree(routesLoggedIn);

export {flattenAccountLoggedOffPathTree};
export {flattenAccountLoggedInPathTree};


let temp = flattenAccountLoggedOffPathTree.slice().reverse();
temp.pop();

export const flattenAccountLoggedOffPathTreeWithoutRoot = temp.reverse();

let temp2 = flattenAccountLoggedInPathTree.slice().reverse();
temp2.pop();

export const flattenAccountLoggedInPathTreeWithoutRoot = temp2.reverse();

export {routesLoggedIn}
export default routesLoggedOff;
