import Controller from "../controller.js";
import {restUri} from "@/data/restPaths";
import {mutate} from "@/utils/mutate";
import {CREATE, DELETE, UPDATE} from "@/data/cud";
import axios from "axios";
import {restBody} from "@/utils/restBody";
import store from "@/store";


export class LicenseController extends Controller {

    constructor(root) {
        super({
            root,
            path: {
                CUD: restUri.COMPANY_LICENSE_CUD,
                GET: restUri.GET_COMPANY_LICENSES
            }
        })
    }

    read() {

        return super.read({
            log: false,
            body: {FkCompany: store.state.FkCompany}
        }).then(data => {
            if (!data.IsError) {
                return data;
            } else return data
        });
    }

    create(Licenses = []) {

        let body = {
            CompanyLicenseList: Licenses
        };

        return super.create({body})
            .then(result => {

                if (!result.IsError) {
                }

                return result;
            }).catch(result => {
                return result.data;
            })
    }

    update(Licenses = {}) {

        let body = {
            CompanyLicenseList: Licenses
        };

        return super.update({body})
            .then(result => {

                if (!result.IsError) {
                }

                return result;
            }).catch(result => {
                return result.data;
            })
    }


}

