



export const LICENSE_TYPES = {
    PACKAGE_S: 1,
    PACKAGE_M: 2,
    PACKAGE_L: 3,
    PACKAGE_SCHOOL: 4,
    COMPANY_USER: 5,
    TOP_COMPANY: 6,
    JOB_ADS: 7,
    LOCATIONS: 8,
    MATCHES_25: 9,
    MATCHES_50: 10,
    MATCHES_LIMITLESS: 11,
    
}


