import {restUri} from "./data/restPaths.js";
import {restBody} from "./utils/restBody.js";
import {CREATE, UPDATE, DELETE, ACTIVATE, DEACTIVATE} from "./data/cud.js";
import moment from 'moment';
import {mutate} from "./utils/mutate.js";
import {isObject} from "./utils/isObject.js";
import store from './store.js';
import {crudType} from "./data/objectTypes.js";
import {applyTrackingState, TRACKING_STATE} from "./utils/applyTrackingState.js";
import {GUID_0} from "./data/cud";
import axios from "axios";


let pathContainer = {
  CUD: null,
  GET: null
}


/**
 * Abstract Class for Controllers
 * Supports CRUD for REST services via GET and CUD.
 *
 */
export default class Controller {

  constructor(obj = {}) {
    this.store = store;
    this.axios = axios;
    this.moment = moment;
    this.restBody = restBody;
    this.path = isObject(obj.path) ? mutate(pathContainer, obj.path) : mutate(pathContainer);

    this.register = {};

    this.restUri = restUri;

    this.root = obj.root != null ? obj.root : null;

    this.model = isObject(obj.model) ? obj.model : {};

    this.init();
  }

  init() {

  }

  dispatch(...args) {
    return this.store.dispatch(...args);
  }

  _generateCrudData(type = 'READ', data = {}) {
    return mutate(crudType(), {
      type,
      path: this.path
    }, data)
  }


  create(data = {}) {
    let crudData = this._generateCrudData('CREATE', data);
    return this.dispatch('CRUD', crudData);


  }

  read(data = {}) {
    let crudData = this._generateCrudData('READ', data);
    return this.dispatch('CRUD', crudData);

  }

  update(data = {}) {
    let crudData = this._generateCrudData('UPDATE', data);
    return this.dispatch('CRUD', crudData);

  }

  delete(data = {}) {
    let crudData = this._generateCrudData('DELETE', data);
    return this.dispatch('CRUD', crudData);

  }

  deactivate(data = {}) {
    let crudData = this._generateCrudData('DEACTIVATE', data);
    return this.dispatch('CRUD', crudData);

  }

  activate(data = {}) {
    let crudData = this._generateCrudData('ACTIVATE', data);
    return this.dispatch('CRUD', crudData);

  }
}
