/**
 * ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
 * ++ ATTENTION: Actions must be normal functions and NO arrow funcions  ++
 * ++ Arrow functions can't have a new scope and vuex can't assign the   ++
 * ++ store scope to them!                                               ++
 * ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
 *
 * Login and Registration (and all related topics) action functions.
 *
 */
import i18n from '@/i18n.js';
import {restUri} from "@/data/restPaths";

import {mutate} from "@/utils/mutate";
import {cookiePurge} from "@/utils/cookiePurge";
import cleanResponseEnvelope from "../../utils/cleanResponseEnvelope.js";
import {restBody} from "@/utils/restBody";
import {UPDATE} from "@/data/cud";
import {landingPages} from "@/data/landingPages";
import {getConfig} from "@/jobineo";
import {logOff} from "../mutations/system.mutations";
import axios from "axios";
import {useCookies} from "vue3-cookies";

const {cookies} = useCookies();


export function login(store, credentials) {


    const useCookies = cookies.get('cookies-accepted') === 'true';

    const
        autoLogin = credentials.autoLogin != null && credentials.autoLogin,
        JWTToken = typeof cookies.get('loggedInSessionId') === 'string' ? cookies.get('loggedInSessionId') : typeof cookies.get('rememberId') === 'string' ? cookies.get('rememberId') : localStorage.getItem('rememberId'),
        RememberMeAuth = autoLogin && JWTToken != null,
        shallRememberMe = credentials.RememberMe != null && credentials.RememberMe;


    let url = restUri.LOGIN,
        body = {
            "Password": credentials.Password,
            "Email": credentials.Email,
            "RememberMe": RememberMeAuth,
            "SessionID": JWTToken
        };


    return axios.post(url, body)
        .then((result) => {

                let data = mutate(result.data);

                if (data.IsError || !data.IsSuccessful) {
                    data.IsError = true;
                    store.commit('logOff');
                    return data;
                }

                if (!data.User.Agbaccepted || !data.User.Dsgvoaccepted || (data.NeedToValidate2FA == true && autoLogin == false)) {
                    store.state.PkUserForAllowedRoutes = data.User.PkUser;
                    store.state.HasToCheckAGB = !data.User.Agbaccepted || !data.User.Dsgvoaccepted;
                    store.state.NeedToValidateMFA = data.NeedToValidate2FA;

                    return data;
                } else {
                    store.state.HasToCheckAGB = false;
                    store.state.NeedToValidateMFA = false;

                }
                // On Successfully Login
                if (!data.IsError && data.IsSuccessful) {


                    //BOF Cookie Handling
                    const JWTToken = data.JWTToken != null ? data.JWTToken : JWTToken;

                    localStorage.setItem('loggedIn', 'true');
                    localStorage.setItem('loggedInSessionId', JWTToken);
                    localStorage.setItem('rememberMe', JWTToken);
                    if (autoLogin || shallRememberMe) {

                        localStorage.setItem('rememberMe', 'true')
                        localStorage.setItem('rememberId', JWTToken);

                        if (shallRememberMe)
                            localStorage.setItem('Email', credentials.Email);

                    }

                    if (useCookies) {
                        cookies.set('loggedIn', 'true')
                            .set('loggedInSessionId', JWTToken);

                        if (autoLogin || shallRememberMe) {

                            cookies.set('rememberMe', 'true')
                                .set('rememberId', JWTToken);

                            if (shallRememberMe)
                                cookies.set('Email', credentials.Email);

                        }
                    } else {
                        cookiePurge();
                    }
                    //EOF Cookie Handling

                    let requestMerge = {
                        "RequestUserID": data.User.PkUser != null ? data.User.PkUser : null,
                        "SessionID": data.JWTToken != null ? data.JWTToken : JWTToken,
                        "UserName": data.User.Email != null ? data.User.Email : null
                    }

                    axios.defaults.headers.post['RequestUserID'] = data.User.PkUser != null ? data.User.PkUser : null;
                    axios.defaults.headers.post['Authorization'] = data.JWTToken != null ? 'Bearer ' + data.JWTToken : null;
                    this.commit('setRequestMerge', requestMerge);
                    this.commit('setUser', data.User);
                    this.commit('setSysSettings', data.SysSettings);
                    this.commit('setCompanyLicenses', data.CompanyLicensesList);

                    store.state.PkStudentProfile = data.PkStudentProfile;
                    store.state.FkCompany = data.FkCompany;
                    store.state.FkOffice = data.FkOffice;

                    if (data.User.DefaultLanguage != null)
                        i18n.locale = data.User.DefaultLanguage;

                    store.state.loggedIn = true;
                    store.state.RegisterType = data.User.UserType;

                    if (!autoLogin)
                        store.dispatch('getAllLookups');

                    cleanResponseEnvelope(data);

                }
                return data;

            }
        ).then(data => {
            return data;
        }).catch(result => {
            return Promise.reject(result)
        })

}


export function setDataAfterMFA(store, data) {

    const useCookies = cookies.get('cookies-accepted') === 'true';


    //BOF Cookie Handling
    const JWTToken = data.JWTToken != null ? data.JWTToken : null;

    localStorage.setItem('loggedIn', 'true');
    localStorage.setItem('loggedInSessionId', JWTToken);
    localStorage.setItem('rememberId', JWTToken);
    localStorage.setItem('Email', data.User.Email);


    if (useCookies) {
        cookies.set('loggedIn', true)
            .set('loggedInSessionId', JWTToken);


        cookies.set('rememberMe', true)
            .set('rememberId', JWTToken);

        cookies.set('Email', data.User.Email);

    } else {
        cookiePurge();
    }
    //EOF Cookie Handling

    let requestMerge = {
        "RequestUserID": data.User.PkUser != null ? data.User.PkUser : null,
        "SessionID": data.JWTToken != null ? data.JWTToken : JWTToken,
        "UserName": data.User.UserName != null ? data.User.UserName : null
    }


    axios.defaults.headers.post['RequestUserID'] = data.User.PkUser != null ? data.User.PkUser : null;
    axios.defaults.headers.post['Authorization'] = data.JWTToken != null ? 'Bearer ' + data.JWTToken : null;
    this.commit('setRequestMerge', requestMerge);
    this.commit('setUser', data.User);
    this.commit('setSysSettings', data.SysSettings);
    this.commit('setCompanyLicenses', data.CompanyLicensesList);
    store.state.PkStudentProfile = data.PkStudentProfile;
    store.state.FkCompany = data.FkCompany;
    store.state.FkOffice = data.FkOffice;

    if (data.User.DefaultLanguage != null)
        i18n.locale = data.User.DefaultLanguage;

    store.dispatch('getAllLookups');

    store.state.loggedIn = true;
    cleanResponseEnvelope(data);

}

/**
 * Autologin
 *
 * @param store
 * @returns {Promise<void>}
 */
export function autoLogin(store) {
    return this.dispatch('login', {
        client: null,
        name: null,
        password: null,
        autoLogin: true
    });
}

/**
 * Register a new account / subscription with given data
 *
 * @param store
 * @param data
 * @returns {Promise<AxiosResponse<any> | never>}
 */
export function registerAccount(store, data) {

    let baseUrl = getConfig('baseUrl')
    let defaultTheme = 3;
    if (store.state.dark_mode == "light") {
        defaultTheme = 1;
    } else if (store.state.dark_mode == "purple") {
        defaultTheme = 2;
    } else {
        defaultTheme = 3;
    }

    return axios.post(restUri.REGISTER, {
        "Email": data.Email,
        "Password": data.Password,
        "RegisterType": data.RegisterType,
        "NameAddition": data.NameAddition,
        "Salutation": data.Salutation,
        "CompanyName": data.CompanyName,
        "Street": data.Street,
        "StreetNr": data.StreetNr,
        "PostCode": data.PostCode,
        "City": data.City,
        "Country": data.Country,
        "AddressAddition": data.AddressAddition,
        "OfficeName": data.OfficeName,
        "OfficeStreet": data.OfficeStreet,
        "OfficeStreetNr": data.OfficeStreetNr,
        "OfficePostCode": data.OfficePostCode,
        "OfficeCity": data.OfficeCity,
        "OfficeCountry": data.OfficeCountry,
        "OfficeAddressAddition": data.OfficeAddressAddition,
        "MemberCount": data.MemberCount,
        "PasswordRepeat": data.PasswordRepeat,
        "FirstName": data.FirstName,
        "LastName": data.LastName,
        "Birthdate": data.BirthDate,
        "AGBSAccepted": true,
        "DSGVOAccepted": true,
        "Over16": data.Over16,
        "ParentEmail": data.ParentEmail,
        "DefaultLanguage": i18n.locale,
        "DefaultTheme": defaultTheme,
        "LanguageId": i18n.locale,
        "TempLink": baseUrl + landingPages.VERIFY_USER,
        "TempLinkParent": baseUrl + landingPages.VERIFY_PARENT_EMAIL
    })
        .then(result => {
            return result.data
        }).catch(error => {
            return error.data;
        });


}

/**
 * Changing a password by an existing user
 *
 * @param store
 * @param data
 * @returns {Promise<AxiosResponse<any> | never>}
 */
export function changePassword(store, data) {
    return axios.post(restUri.CHANGE_PASSWORD, restBody(data))
        .then(result => {
            return result.data;
        })

}


/**
 * Updatin the data of a user.
 * @param store
 * @param data
 * @returns {Promise<AxiosResponse<any> | never>}
 */
export function updateUser(store, data) {

    let User = mutate(data, {TrackingState: UPDATE});
    delete User.Password;
    delete User.PasswordRepeat;


    return axios.post(restUri.USER_CUD, restBody({User: User}))
        .then(result => {
            let data = result.data;

            if (!data.IsError) {
                this.commit('setUser', data.User)
                if (data.User.DefaultLanguage != null)
                    i18n.locale = data.User.DefaultLanguage;
            }

            return data;
        }).catch(result => {
            return result.data;
        })
}


