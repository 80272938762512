<template>
    <div class="dropdown nav-item notification-dropdown btn-group">
        <a href="javascript:;" id="ddlnotify" data-bs-toggle="dropdown" aria-expanded="false"
           class="btn dropdown-toggle btn-icon-only nav-link">
            <font-awesome-icon
                icon="fas fa-bell"
                :class="[{'notifications-active':hasNotifications}]"
            />
            <span v-if="hasNotifications" class="badge badge-danger counter">{{ notificationCount }}</span>
        </a>
        <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="ddlnotify">
            <template v-if="!hasNotifications">
                <li role="presentation">
                    <a href="javascript:;" class="dropdown-item">
                        <div class="media server-log">
                            <div class="media-aside align-self-start">
                                <font-awesome-icon icon="fa fa-check" class="success"/>
                            </div>
                            <div class="media-body">
                                <div class="data-info">
                                    <h6 class="">{{
                                            $t('NO-NOTIFICATION')
                                        }}</h6>
                                </div>
                            </div>
                        </div>
                    </a>
                </li>
            </template>
            <template v-else v-for="(notify,idx) in notifications" :key="idx">
                <li role="presentation" @click="notified(notify)">
                    <a href="javascript:;" class="dropdown-item">
                        <div class="media server-log">
                            <div class="media-aside align-self-start">
                                <font-awesome-icon :icon="notify.Icon"/>
                            </div>
                            <div class="media-body">
                                <div class="data-info">
                                    <h6 class="">{{ trans(notify.Subject) }}</h6>
                                    <p class="">{{ trans(notify.Message) }}</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
import router from "@/router";
import store from "@/store";

export default {
    name: 'notification-menu',
    data() {
        return {
            polling: null
        }
    },
    computed: {
        hasNotifications() {
            return (this.notifications != null && (Array.isArray(this.notifications) && this.notifications.length > 0));
        },
        notifications() {
            return store.state.UserNotifications != null && Array.isArray(store.state.UserNotifications) ? store.state.UserNotifications : [];
        },
        notificationCount() {
            return store.state.UserNotifications != null && Array.isArray(store.state.UserNotifications) ? store.state.UserNotifications.length : '';
        }
    },
    methods: {
        getTrans(message) {
            return this.trans(message)
        },
        pollData() {
            this.polling = setInterval(() => {
                store.dispatch('getNotifications')
            }, 30000);

            this.$store.dispatch('getNotifications');
        },
        notified(notification) {
            if (!notification) return;
            store.dispatch('setNotified', notification).then((result) => {
                store.dispatch('getNotificationsAll')
                if (!result.IsError && notification.URI != null && router.currentRoute.fullPath !== notification.URI)
                    Promise.resolve(router.push("/" + notification.URI));
            });
        }
    },
    beforeUnmount() {
        clearInterval(this.polling);
    },
    created() {
        this.pollData();
    }

}
</script>

<style scoped>
.success {
    color: #9acccb;
}

.button__badge {
    background-color: #fa3e3e;
    border-radius: 2px;
    color: white;
    padding: 1px 3px;
    font-size: 10px;
    position: absolute; /* Position the badge within the relatively positioned button */
    top: 3px;
    right: 0;
}

.notifications-active {
    color: rgba(255, 255, 255, 1) !important;
}


</style>
