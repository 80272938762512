<template>
    <template v-if="CheckVisible(item.CheckIfUserType)">
        <li :class="[{'menu': level===0}]">
            <template v-if="item.children">
                <a href="javascript:;" class="dropdown-toggle" data-bs-toggle="collapse" :data-bs-target="'#'+item.name"
                   :aria-controls="item.name" aria-expanded="false">
                    <div class="">
                        <font-awesome-icon :icon="item.icon"></font-awesome-icon>
                        <span>{{ trans(item.title) }}</span>
                        <span class="dropdown-carret"><font-awesome-icon v-if="item.children"
                                                                         icon="fas fa-chevron-right"></font-awesome-icon></span>
                    </div>

                </a>
                <ul :id="item.name" class="collapse submenu list-unstyled" data-bs-parent="#sidebar"
                    v-if="item.children">

                    <side-menu-sub-item v-for="(subItem, index) in item.children"
                                        :key="index"
                                        @toggleMobileMenu="toggleMobileMenu"
                                        :level="level+1"
                                        :item="subItem"></side-menu-sub-item>

                </ul>
            </template>
            <template v-else>
                <router-link :to="item.path" @click="toggleMobileMenu" :class="[{'dropdown-toggle':level===0}]">
                    <div class="">
                        <font-awesome-icon :icon="item.icon"></font-awesome-icon>
                        <span>{{ trans(item.title) }}</span>
                    </div>
                </router-link>
            </template>
        </li>
    </template>
</template>

<script>

import store from "@/store";
import {CHECK_REGISTER_TYPES, REGISTER_TYPES} from "@/data/RegisterTypes";

export default {
    name: 'side-menu-sub-item',
    data() {
        return {}
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        level: {
            type: Number,
            default: 1
        }
    },
    computed: {
        loggedIn() {
            return store.state.loggedIn;
        }
    },
    methods: {
        toggleMobileMenu() {
            this.$emit('toggleMobileMenu', true);
        },
        CheckVisible(checkIfUserType) {

            if (checkIfUserType === undefined)
                return true;

            if (checkIfUserType === CHECK_REGISTER_TYPES.ALL)
                return true;

            if (checkIfUserType === CHECK_REGISTER_TYPES.COMPANY_OR_SCHOOL && store.state.RegisterType !== REGISTER_TYPES.STUDENT)
                return true;

            if (checkIfUserType === CHECK_REGISTER_TYPES.COMPANY_OR_STUDENT && store.state.RegisterType !== REGISTER_TYPES.SCHOOL)
                return true;

            if (checkIfUserType === CHECK_REGISTER_TYPES.COMPANY && store.state.RegisterType === REGISTER_TYPES.COMPANY)
                return true;

            if (checkIfUserType === CHECK_REGISTER_TYPES.SCHOOL && store.state.RegisterType === REGISTER_TYPES.SCHOOL)
                return true;

            if (checkIfUserType === CHECK_REGISTER_TYPES.STUDENT && store.state.RegisterType === REGISTER_TYPES.STUDENT)
                return true;


            return false;
        }
    },
    beforeUnmount() {
    },
    created() {
    }

}
</script>

<style scoped lang="scss">

.dropdown-carret {
    margin-left: 4px;
}
</style>
