<template>
    <div v-show="twiggleFlag" class="form-group">

        <h5 v-if="!noTitle" class="underlined margin-bottom-15 padding-bottom-10">{{ $t('APPOINTMENT') }}</h5>
        <Form class="text-start" ref="valForm" :validationSchema="validationSchema"
              @submit="validate">
            <div class="form">
                <div class="row flex">
                    <div class="form-group col-4">
                        <div id="AppType-field" class="field-wrapper input">
                            <app-type-drop-down ref="AppType"
                                                v-model="model.AppType"
                                                :required="true"
                                                :has-null-value="true"
                                                :null-value-text="trans('SELECT-APP-TYPE')"
                                                :label="trans('APP-TYPE')"
                                                attached-class="form-control"
                                                name="AppType"/>
                        </div>
                    </div>
                    <div class="form-group col-4">
                        <div id="Subject-field" class="field-wrapper input">
                            <base-input
                                v-model="model.Subject"
                                type="text"
                                :required="true"
                                obj-name="Subject"
                                :label="trans('SUBJECT')"
                                attached-class="form-control"
                                :placeholder="trans('SUBJECT')"
                                name="Subject"
                                ref="Subject"/>
                        </div>
                    </div>
                </div>
                <div class="row flex">
                    <div class="form-group col-4">
                        <div id="StartDate-field" class="field-wrapper input">
                            <base-datetime
                                :label="trans('START-DATE')"
                                :required="true"
                                ref="StartDate"
                                obj-name="StartDate"
                                name="StartDate"
                                :value="model.StartDate"
                                :enable-time="true"
                                :onchange="StartDateChange"
                                :place-holder="trans('PLEASE-SELECT-DATE')"
                                date-time-type="date"
                                :date-time-format="DateTimeFormat"
                                :date-time-format-value="DateTimeFormatValue"
                            ></base-datetime>
                        </div>
                    </div>
                    <div class="form-group col-4">
                        <div id="EndDate-field" class="field-wrapper input">
                            <base-datetime
                                :label="trans('END-DATE')"
                                :required="true"
                                obj-name="EndDate"
                                ref="EndDate"
                                :enable-time="true"
                                name="EndDate"
                                :value="model.EndDate"
                                :onchange="EndDateChange"
                                :place-holder="trans('PLEASE-SELECT-DATE')"
                                date-time-type="date"
                                :date-time-format="DateTimeFormat"
                                :date-time-format-value="DateTimeFormatValue"
                            ></base-datetime>
                        </div>
                    </div>
                </div>
                <div class="row flex">
                    <div class="form-group col-12">
                        <div id="AppBody-field" class="field-wrapper input">
                            <base-text-area
                                v-model="model.AppBody"
                                :required="true"
                                obj-name="AppBody"
                                :label="trans('APP-BODY')"
                                attached-class="form-control"
                                :placeholder="trans('APP-BODY')"
                                name="AppBody"
                                ref="AppBody"/>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div v-if="error" class="error-alert-wrap">
            <font-awesome-icon icon="fas fa-exclamation-triangle" class="error-icon" />
            {{ trans(errorMessage) }}
        </div>
    </div>
</template>

<script>
import {mutate} from "@/utils/mutate.js";
import {Form} from 'vee-validate';
import {APPOINTMENT_TYPES, NOTIFICATION_DELAY_DROPDOWN} from "@/data/appointment";
import * as yup from "yup";
import AppTypeDropDown from '@/components/dropdowns/AppTypeDropdown.vue'
import BaseTextArea from '@/components/BaseTextArea.vue'
import {uuid} from 'vue-uuid';

export default {
    name: "CalendarEntryForm",
    components: {Form, AppTypeDropDown, BaseTextArea},
    props: ['noTitle', 'editMode', 'isActive', 'isPast'],
    data() {
        return {
            validationSchema: yup.object({
                AppType: yup.number().required(this.trans("REQUIRED-MESSAGE")),
                Subject: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                AppBody: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                StartDate: yup.date().required(this.trans("REQUIRED-MESSAGE")),
                EndDate: yup.date().required(this.trans("REQUIRED-MESSAGE"))
            }),
            model: {
                PkAppointment: null,
                FkOffice: null,
                FkUserCreate: this.$store.state.User != null ? this.$store.state.User.PkUser : null,
                AppType: null,
                StartDate: null,
                EndDate: null,
                Subject: false,
                AppBody: false,
                NotificationDelay: 0,
                TblAppointmentUserDTOList: []
            },
            error: false,
            options: NOTIFICATION_DELAY_DROPDOWN,
            warning: false,
            warningMessage: '',
            errorMessage: '',
            twiggleFlag: true
        }
    },
    watch: {
        model: {
            handler(model) {
                this.$emit('input', model);
            },
            deep: true
        }
    },
    computed: {
        DateTimeFormat() {
            return "d.m.Y H:i";
        },
        DateTimeFormatValue() {
            return "Y-m-dTH:i:00.000\\Z";
        },
        editModeActive() {
            return this.$props.editMode;
        },
        IsPast() {
            return this.$props.isPast;
        },
        IsActive() {
            return this.$props.isActive;
        },
    },
    methods: {
        StartDateChange(newDate) {
            this.model.StartDate = newDate;
        },
        EndDateChange(newDate) {
            this.model.EndDate = newDate;
        },
        twiggle() {
            this.twiggleFlag = false;
            this.$nextTick(() => {
                this.twiggleFlag = true;
            })

        },
        setUser(value) {

            let newUser = {
                PkAppointmentUser: uuid.v4(),
                FkAppointment: this.model.PkAppointment,
                FkUser: value,
                UserCommitted: false
            };

            this.model.TblAppointmentUserDTOList = [];
            this.model.TblAppointmentUserDTOList.push(newUser)


        },
        getTranslation(value) {
            return this.trans(value)
        },
        setWarning(warningMessage) {
            if (warningMessage) {
                this.warning = true;
                this.warningMessage = this.trans(warningMessage);
            } else {
                this.warning = false;
                this.warningMessage = '';
            }
        },
        setError(errorMessage) {
            if (errorMessage) {
                this.error = true;
                this.errorMessage = this.trans(errorMessage);
            } else {
                this.error = false;
                this.errorMessage = '';
            }
        },
        reset() {
            this.setError();
            this.setWarning();
            this.model = {
                PkAppointment: null,
                FkOffice: null,
                FkUserCreate: this.$store.state.User != null ? this.$store.state.User.PkUser : null,
                AppType: null,
                StartDate: null,
                EndDate: null,
                Subject: null,
                AppBody: null,
                NotificationDelay: 0,
                TblAppointmentUserDTOList: []
            }

            this.error = false;
        },

        getData(wrapped = false) {
            return wrapped ? {Appointment: mutate(this.model)} : mutate(this.model);
        },

        _set(d = {}) {
            this.model = {
                ...this.model,
                PkAppointment: d.PkAppointment,
                FkOffice: d.FkOffice,
                FkUserCreate: d.FkUserCreate,
                AppType: d.AppType,
                StartDate: d.StartDate,
                EndDate: d.EndDate,
                Subject: d.Subject,
                AppBody: d.AppBody,
                NotificationDelay: 0,
                TblAppointmentUserDTOList: d.TblAppointmentUserDTOList
            }
            this.setWarning(null)
            this.setError(null)
            this.$refs.Subject.reset();
            this.$refs.AppBody.reset();

            this.twiggle();
        },
        setData(data = {}) {

            if (data.Appointment != null)
                this._set(data.Appointment)
            else
                this._set(data)


        },
        validate() {

            return this.$refs.valForm.validate().then(result => {
                if (result.valid == false) {
                    for (const key in result.errors) {
                        this.$refs[key].setError();
                    }
                }

                if (this.model.StartDate == null) {
                    this.$refs.StartDate.setError("REQUIRED-MESSAGE");
                }
                if (this.model.EndDate == null) {
                    this.$refs.EndDate.setError("REQUIRED-MESSAGE");
                }


                return Promise.resolve(result.valid);
            })
        }
    }
}
</script>

<style scoped>

</style>

