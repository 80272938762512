<template>
    <div class="container-jobineo">
        <div class="section-heading">
            <span class="no-decoration breadcrumb-decorate-blue">
                <font-awesome-icon icon="fas fa-cog"/>
                {{ trans('MAIN-DATA') }}
            </span>
            <span class="no-decoration breadcrumb-decorate-yellow">
                <font-awesome-icon icon="fas fa-angle-right"/> &nbsp;
                <font-awesome-icon icon="fas fa-euro-sign"/>
                {{ trans('LICENSES') }}
            </span>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="container-jobineo">
                    <div class="row layout-top-spacing">
                        <div id="basic" class="col-lg-12 col-sm-12 col-12 layout-spacing">
                            <div class="statbox panel box box-shadow">
                                <h1 style="text-align: center;">{{ trans('CURRENT-LICENSES') }}</h1>
                                <div class="custom-table">
                                    <b-table
                                        responsive
                                        small hover bordered
                                        :busy="loading"
                                        select-mode="single"
                                        sort-by="LicenseType"
                                        :items="LicensesList"
                                        :fields="fields"
                                        show-empty
                                        primary-key="PkLicense"
                                        @row-clicked="rowClicked"
                                        tbody-class="clickable"
                                    >
                                        <template #empty>
                                            <h4 style="text-align: center;">{{ trans('NO-LICENSES') }}</h4>
                                        </template>
                                        <template v-slot:cell(ValidFrom)="data">
                                            <div>
                                                {{ cutDateString(data.item.ValidFrom) }}
                                            </div>
                                        </template>
                                        <template v-slot:cell(ValidTill)="data">
                                            <div>
                                                {{ cutDateString(data.item.ValidTill) }}
                                            </div>
                                        </template>
                                        <template v-slot:cell(AutoRenew)="data">
                                            <div v-if="data.item.AutoRenew == true"
                                                 :class="'btn-tree-menu remove'"
                                            >
                                                <font-awesome-icon
                                                    icon="fas fa-check"
                                                />
                                                {{ trans('YES') }}
                                            </div>
                                            <div v-if="data.item.AutoRenew != true"
                                                 :class="'btn-tree-menu remove'"
                                            >
                                                <font-awesome-icon
                                                    icon="fas fa-xmark"
                                                />
                                                {{ trans('NO') }}
                                            </div>
                                        </template>
                                        <template v-slot:cell(Cancelled)="data">
                                            <div v-if="data.item.Cancelled == true"
                                                 :class="'btn-tree-menu remove'"
                                            >
                                                <font-awesome-icon
                                                    icon="fas fa-check"
                                                />
                                                {{ trans('YES') }}
                                            </div>
                                            <div v-if="data.item.Cancelled != true"
                                                 :class="'btn-tree-menu remove'"
                                            >
                                                <font-awesome-icon
                                                    icon="fas fa-xmark"
                                                />
                                                {{ trans('NO') }}
                                            </div>
                                        </template>
                                        <template v-slot:cell(LicenseType)="data">
                                            <div>
                                                {{ trans(GetLicenseName(data.item.LicenseType)) }}
                                            </div>
                                        </template>
                                        <template v-slot:cell(Interval)="data">
                                            <div>
                                                {{ GetIntervalName(data.item) }}
                                            </div>
                                        </template>
                                        <template v-slot:cell(Actions)="data">
                                            <div style="display:flex;">
                                                <PopOver class="dark margin-left-5" arrow hover openDelay="200"
                                                         closeDelay="100" placement="right"
                                                         v-if="data.item.Cancelled != true">
                                                    <template #content>
                                                        <span class="popOverText"
                                                              v-for="(line,lineNumber) of trans('CANCEL-LICENSE').split('\n')"
                                                              v-bind:key="lineNumber">
                                                            {{ line }}<br/>
                                                        </span>
                                                    </template>
                                                    <div :class="'btn-tree-menu remove'">
                                                        <font-awesome-icon icon="fas fa-xmark" style="cursor:pointer;"
                                                                           @click="cancelLicense(data.item, true)"/>
                                                    </div>
                                                </PopOver>
                                                <PopOver class="dark margin-left-5" arrow hover openDelay="200"
                                                         closeDelay="100" placement="right"
                                                         v-if="data.item.Cancelled == true">
                                                    <template #content>
                                                        <span class="popOverText"
                                                              v-for="(line,lineNumber) of trans('UNCANCEL-LICENSE').split('\n')"
                                                              v-bind:key="lineNumber">
                                                            {{ line }}<br/>
                                                        </span>
                                                    </template>
                                                    <div :class="'btn-tree-menu remove'">
                                                        <font-awesome-icon icon="fas fa-check" style="cursor:pointer;"
                                                                           @click="cancelLicense(data.item, false)"/>
                                                    </div>
                                                </PopOver>
                                                <PopOver class="dark margin-left-5" arrow hover openDelay="200"
                                                         closeDelay="100" placement="right"
                                                         v-if="data.item.AutoRenew == true">
                                                    <template #content>
                                                        <span class="popOverText"
                                                              v-for="(line,lineNumber) of trans('CANCEL-RENEW-LICENSE').split('\n')"
                                                              v-bind:key="lineNumber">
                                                            {{ line }}<br/>
                                                        </span>
                                                    </template>
                                                    <div :class="'btn-tree-menu remove'" style="margin-left: 10px;">
                                                        <font-awesome-icon icon="fas fa-ban" style="cursor:pointer;"
                                                                           @click="noRenew(data.item, false)"/>
                                                    </div>
                                                </PopOver>
                                                <PopOver class="dark margin-left-5" arrow hover openDelay="200"
                                                         closeDelay="100" placement="right"
                                                         v-if="data.item.AutoRenew != true">
                                                    <template #content>
                                                        <span class="popOverText"
                                                              v-for="(line,lineNumber) of trans('ENABLE-RENEW-LICENSE').split('\n')"
                                                              v-bind:key="lineNumber">
                                                            {{ line }}<br/>
                                                        </span>
                                                    </template>
                                                    <div :class="'btn-tree-menu remove'" style="margin-left: 10px;">
                                                        <font-awesome-icon icon="fas fa-arrows-rotate"
                                                                           style="cursor:pointer;"
                                                                           @click="noRenew(data.item, true)"/>
                                                    </div>
                                                </PopOver>

                                            </div>
                                        </template>

                                    </b-table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="container-jobineo"
         v-if="NewLicensesList!= null&&Array.isArray(NewLicensesList)&&NewLicensesList.length >0&&ShowBuy">
        <div class="row">
            <div class="col-lg-12">
                <div class="container-jobineo">
                    <div class="row ">
                        <div id="basic" class="col-lg-12 col-sm-12 col-12 layout-spacing">
                            <div class="statbox panel box box-shadow">
                                <h1 style="text-align: center;">{{ trans('BUYING-LICENSES') }}</h1>
                                <div class="custom-table">
                                    <b-table
                                        responsive
                                        small hover bordered
                                        :busy="loading"
                                        select-mode="single"
                                        sort-by="LicenseType"
                                        :items="NewLicensesList"
                                        :fields="fieldsNew"
                                        show-empty
                                        primary-key="PkCompanyLicense"
                                        @row-clicked="rowClickedNew"
                                        tbody-class="clickable"
                                    >
                                        <template v-slot:cell(LicenseType)="data">
                                            <div>
                                                {{ trans(GetLicenseName(data.item.LicenseType)) }}
                                            </div>
                                        </template>
                                        <template v-slot:cell(Interval)="data">
                                            <div>
                                                {{ GetIntervalNameNew(data.item) }}
                                            </div>
                                        </template>
                                        <template v-slot:cell(NetAmount)="data">
                                            <div>
                                                {{ GetNetAmount(data.item) }}
                                            </div>
                                        </template>
                                        <template v-slot:cell(GrossAmount)="data">
                                            <div>
                                                {{ GetGrossAmount(data.item) }}
                                            </div>
                                        </template>
                                        <template v-slot:cell(Actions)="data">
                                            <div
                                                :class="'btn-tree-menu remove'"
                                            >
                                                <font-awesome-icon icon="fas fa-trash" style="cursor:pointer;"
                                                                   @click="removeLicense(data.item)"/>
                                            </div>
                                        </template>
                                    </b-table>
                                </div>
                                <div class="footer-inner" style="text-align: right;">
                                    <div class="row">
                                        <p class="form-group col-lg-12" style="padding-right:20px;">
                                            {{ trans('NET-SUM') }}: {{ NetSum }}€
                                        </p>
                                    </div>
                                    <div class="row">
                                        <p class="form-group col-lg-12" style="padding-right:20px;">
                                            {{ trans('TAXES') }}: {{ Taxes }}€
                                        </p>
                                    </div>
                                    <div class="row">
                                        <p class="form-group col-lg-12" style="padding-right:20px;">
                                            {{ trans('GROSS-SUM') }}: {{ GrossSum }}€
                                        </p>
                                    </div>
                                </div>
                                <div class="footer-inner" style="text-align: center;">
                                    <WaitingButton
                                        size="sm"
                                        icon="fas fa-check"
                                        class=" btn btn-mint input-rounded"
                                        :waiting="isSaving"
                                        @click.prevent="save">
                                        {{ $t('BUY-LICENSES') }}
                                    </WaitingButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="layout-px-spacing"
         v-if="!(checkIfLicAlreadyIn(1)||checkIfLicAlreadyIn(2)||checkIfLicAlreadyIn(3))&&ShowBuy">
        <div class="row margin-top-60">
            <div class="ml-auto mr-auto text-center header-row">
                <h1 class="title">{{ trans('ADD-NEW-LICENSE') }}</h1>

                <div style="display:inline-flex;margin-top: 15px">
                    <h4 class="description" style="margin-right: 25px; margin-top:10px">
                        {{ trans('RUNTIME') }}
                    </h4>
                </div>
                <div class='tss'>
                    <input type='radio' name='tss' id='quarterly' value='1' v-model='IntervalMode'>
                    <label for='quarterly'><span>{{ trans('QUARTERLY-RUNTIME') }}</span></label>
                    <input type='radio' name='tss' id='halfyearly' value='2' v-model='IntervalMode'>
                    <label for='halfyearly'><span>{{ trans('HALF-YEARLY-RUNTIME') }}</span></label>
                    <input type='radio' name='tss' id='yearly' value='3' v-model='IntervalMode'>
                    <label for='yearly'><span>{{ trans('YEARLY-RUNTIME') }}</span></label>
                </div>
            </div>
        </div>

        <div class="container pricing-table"
        >
            <section class="pricing-section bg-7 mt-5">
                <div class="pricing pricing--norbu">
                    <div class="pricing__item" v-if="!checkIfLicAlreadyIn(1)">
                        <h3 class="pricing__title">{{ $t('PACKAGE-S') }}</h3>
                        <p class="pricing__sentence">{{ $t('PACKAGE-S-SENTENCE') }}</p>
                        <div class="pricing__price"><span class="pricing__currency">€</span>{{
                                getPricing(this.PricingS)
                            }}<span
                                class="pricing__period">/ mtl.</span>
                        </div>
                        <ul class="pricing__feature-list text-center">
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-S-TEXT-1') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-S-TEXT-2') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-S-TEXT-3') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-S-TEXT-4') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-S-TEXT-5') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-xmark" class="x-margin-gray"></font-awesome-icon>
                                <span class="grayout">{{ $t('PACKAGE-S-TEXT-6') }}</span>
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-xmark" class="x-margin-gray"></font-awesome-icon>
                                <span class="grayout">{{ $t('PACKAGE-S-TEXT-7') }}</span>
                            </li>
                        </ul>
                        <base-button
                            native-type="button"
                            class="btn btn-mint input-rounded"
                            @click="addMainLicense(1, null)"
                        >
                            <font-awesome-icon
                                icon="fas fa-circle-plus"/>
                            {{ $t('BUY-NOW') }}
                        </base-button>
                    </div>
                    <div class="pricing__item pricing__item--featured" v-if="!checkIfLicAlreadyIn(2)">
                        <div class="ribbon-container">
                            <div class="ribbon-flag">{{ trans('MOST-POPULAR') }}</div>
                        </div>
                        <h3 class="pricing__title">{{ $t('PACKAGE-M') }}</h3>
                        <p class="pricing__sentence">{{ $t('PACKAGE-M-SENTENCE') }}</p>
                        <div class="pricing__price"><span class="pricing__currency">€</span>{{
                                getPricing(this.PricingM)
                            }}<span
                                class="pricing__period">/ mtl.</span>
                        </div>
                        <ul class="pricing__feature-list text-center">
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-M-TEXT-1') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-M-TEXT-2') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-M-TEXT-3') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-M-TEXT-4') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-M-TEXT-5') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="x-margin-gray"></font-awesome-icon>
                                <span class="grayout">{{ $t('PACKAGE-M-TEXT-6') }}</span>
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="x-margin-gray"></font-awesome-icon>
                                <span class="grayout">{{ $t('PACKAGE-M-TEXT-7') }}</span>
                            </li>
                        </ul>
                        <base-button
                            native-type="button"
                            class="btn btn-mint input-rounded"
                            @click="addMainLicense(2, null)"
                        >
                            <font-awesome-icon
                                icon="fas fa-circle-plus"/>
                            {{ $t('BUY-NOW') }}
                        </base-button>
                    </div>
                    <div class="pricing__item" v-if="!checkIfLicAlreadyIn(3)">
                        <h3 class="pricing__title">{{ $t('PACKAGE-L') }}</h3>
                        <p class="pricing__sentence">{{ $t('PACKAGE-L-SENTENCE') }}</p>
                        <div class="pricing__price"><span class="pricing__currency">€</span>{{
                                getPricing(this.PricingL)
                            }}<span
                                class="pricing__period">/ mtl.</span>
                        </div>
                        <ul class="pricing__feature-list text-center">
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-L-TEXT-1') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-L-TEXT-2') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-L-TEXT-3') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-L-TEXT-4') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="check-margin"></font-awesome-icon>
                                {{ $t('PACKAGE-L-TEXT-5') }}
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="x-margin-gray"></font-awesome-icon>
                                <span class="grayout">{{ $t('PACKAGE-L-TEXT-6') }}</span>
                            </li>
                            <li class="pricing__feature">
                                <font-awesome-icon icon="fas fa-check" class="x-margin-gray"></font-awesome-icon>
                                <span class="grayout">{{ $t('PACKAGE-L-TEXT-7') }}</span>
                            </li>
                        </ul>
                        <base-button
                            native-type="button"
                            class="btn btn-mint input-rounded"
                            @click="addMainLicense(3, null)"
                        >
                            <font-awesome-icon
                                icon="fas fa-circle-plus"/>
                            {{ $t('BUY-NOW') }}
                        </base-button>
                    </div>

                </div>
                <div class="pricing pricing--norbu">
                    <div class="in-development">{{ trans('IN-DEVELOPMENT') }}</div>
                </div>
            </section>
        </div>
    </div>

    <div class="layout-px-spacing"
         v-if="(checkIfLicAlreadyIn(1)||checkIfLicAlreadyIn(2)||checkIfLicAlreadyIn(3))&&ShowBuy">

        <div class="row margin-top-80">
            <div class="ml-auto mr-auto text-center header-row">
                <h1 class="title">{{ $t('UPGRADE-LICENSE') }}</h1>
                <h4 class="description">
                    {{ $t('UPGRADE-LICENSE-TEXT') }}
                </h4>
                <h4 class="description">
                    {{ $t('UPGRADE-LICENSE-TEXT-2') }}
                </h4>
            </div>
        </div>

        <div class="layout-px-spacing">
            <div class="container pricing-table">
                <section class="pricing-section bg-7 mt-5">
                    <div class="pricing pricing--norbu">
                        <template v-for="(item, idx) in AddOnPriceList" :key="idx">
                            <div class="pricing__item" v-if="!checkIfLicAlreadyIn(item.LicenseType)">
                                <h3 class="pricing__title">{{ trans(item.Name) }}</h3>
                                <p class="pricing__sentence">
                                    <font-awesome-icon icon="fas fa-circle-plus"
                                                       class="check-margin-10"></font-awesome-icon>
                                    {{ item.NetAmount }}€
                                </p>
                                <base-button
                                    native-type="button"
                                    class="btn btn-mint input-rounded"
                                    @click="addMainLicense(4, item)"
                                >
                                    <font-awesome-icon
                                        icon="fas fa-circle-plus"/>
                                    {{ $t('BUY-NOW') }}
                                </base-button>
                            </div>
                        </template>
                    </div>
                </section>
            </div>


        </div>

    </div>
    <div class="layout-px-spacing" v-if="!ShowBuy">

        <div class="row margin-top-80">
            <div class="ml-auto mr-auto text-center header-row">
                <h1 class="title">{{ $t('NO-INVOICE-CONTACT') }}</h1>
                <h4 class="description">
                    {{ trans('NO-INVOICE-CONTACT-TEXT') }}
                </h4>
                <base-button
                    native-type="button"
                    class="btn btn-mint input-rounded"
                    @click="nevigateToCompany"
                >
                    <font-awesome-icon
                        icon="fas fa-arrow-right"/>
                    {{ $t('NAVIGATE-COMPANY') }}
                </base-button>
            </div>
        </div>
    </div>
</template>

<script>
import {restUri} from "@/data/restPaths.js";
import {restBody} from "@/utils/restBody.js";
import {PAYMENT_INTERVAL_DROPDOWN} from "@/data/paymentInterval";
import store from "@/store";
import lodash from "lodash";
import {uuid} from "vue-uuid";
import {LICENSE_TYPES} from "@/data/licenseTypes";
import WaitingButton from "@/components/WaitingButton.vue";
import {LicenseController} from "@/controllers/license.controller";
import {CREATE, UPDATE} from "@/data/cud";
import {cutDateString} from "@/utils/convertDateTime";
import {CompanyController} from "@/controllers/company.controller";
import router from "@/router";

export default {
    name: "LicensesView",
    components: {WaitingButton},
    props: ['root-component'],
    data() {
        return {
            controller: new LicenseController(this),
            controllerCompany: new CompanyController(this),
            LicensesList: [],
            NewLicensesList: [],
            selectedItem: null,
            selectedItemNew: null,
            LicensePricesList: [],
            twiggleFlag: false,
            intervalOptions: PAYMENT_INTERVAL_DROPDOWN,
            error: false,
            errorMessage: null,
            isUpdating: false,
            loading: false,
            PackageS3: null,
            PackageM3: null,
            PackageL3: null,
            PackageS6: null,
            PackageM6: null,
            PackageL6: null,
            PackageS12: null,
            PackageM12: null,
            PackageL12: null,
            IntervalMode: 1,
            isSaving: false,
            Company: null
        }
    },
    computed: {

        GrossSum() {
            let sum = 0;
            this.NewLicensesList.forEach(item => {
                if (item.LicenseType == LICENSE_TYPES.PACKAGE_S ||
                    item.LicenseType == LICENSE_TYPES.PACKAGE_M ||
                    item.LicenseType == LICENSE_TYPES.PACKAGE_L) {
                    let found = lodash.filter(this.LicensePricesList, x => x.LicenseType == item.LicenseType && x.PaymentInterval == item.Interval);
                    if (found != null && found.length > 0) {
                        sum = (sum + (found[0].GrossAmount * item.Count))
                    }
                } else {
                    let found = lodash.filter(this.LicensePricesList, x => x.LicenseType == item.LicenseType);
                    if (found != null && found.length > 0) {
                        sum = (sum + (found[0].GrossAmount * item.Count))
                    }
                }

            })
            return sum.toFixed(2)
        },
        NetSum() {
            let sum = 0;
            this.NewLicensesList.forEach(item => {
                if (item.LicenseType == LICENSE_TYPES.PACKAGE_S ||
                    item.LicenseType == LICENSE_TYPES.PACKAGE_M ||
                    item.LicenseType == LICENSE_TYPES.PACKAGE_L) {
                    let found = lodash.filter(this.LicensePricesList, x => x.LicenseType == item.LicenseType && x.PaymentInterval == item.Interval);
                    if (found != null && found.length > 0) {
                        sum = (sum + (found[0].NetAmount * item.Count))
                    }
                } else {
                    let found = lodash.filter(this.LicensePricesList, x => x.LicenseType == item.LicenseType);
                    if (found != null && found.length > 0) {
                        sum = (sum + (found[0].NetAmount * item.Count))
                    }
                }

            })
            return sum.toFixed(2)
        },
        Taxes() {
            return (this.GrossSum - this.NetSum).toFixed(2);
        },
        CompanyInvoiceContact() {
            return this.Company != null && this.Company.CompanyInvoiceContact != null ? this.Company.CompanyInvoiceContact : {}
        },
        ShowBuy() {
            if (this.CompanyInvoiceContact.PkCompanyInvoiceContact != null)
                return true;
            else
                return false;
        },
        fields() {
            return [
                {key: "LicenseType", sortable: true, label: this.trans("LICENSE-TYPE")},
                {key: "Count", sortable: false, label: this.trans("COUNT")},
                {key: "Interval", sortable: true, label: this.trans("INTERVAL")},
                {key: "Cancelled", sortable: true, label: this.trans("CANCELLED")},
                {key: "ValidFrom", sortable: true, label: this.trans("VALID-FROM")},
                {key: "ValidTill", sortable: true, label: this.trans("VALID-TILL")},
                {key: "AutoRenew", sortable: false, label: this.trans("AUTO-RENEW")},
                {key: "Actions", sortable: false, label: this.trans("ACTIONS")},
            ]
        },
        fieldsNew() {
            return [
                {key: "LicenseType", sortable: true, label: this.trans("LICENSE-TYPE")},
                {key: "Count", sortable: false, label: this.trans("COUNT")},
                {key: "Interval", sortable: true, label: this.trans("INTERVAL")},
                {key: "NetAmount", sortable: true, label: this.trans("NET-AMOUNT")},
                {key: "GrossAmount", sortable: true, label: this.trans("GROSS-AMOUNT")},
                {key: "Actions", sortable: false, label: this.trans("ACTIONS")},
            ]
        },
        AddOnPriceList() {
            var list = lodash.filter(this.LicensePricesList, x => x.MainLicense != true);
            if (list != null)
                return list;
            else
                return [];
        },
        Interval() {
            return this.IntervalMode
        },
        Months() {
            return this.Interval == 1 ? 3 : this.Interval == 2 ? 6 : 12;
        },
        PricingS() {
            if (this.Interval == 1) {
                if (this.PackageS3)
                    return this.PackageS3.NetAmount
                else return 0
            } else if (this.Interval == 2) {
                if (this.PackageS6)
                    return this.PackageS6.NetAmount
                else return 0
            } else if (this.Interval == 3) {
                if (this.PackageS12)
                    return this.PackageS12.NetAmount
                else return 0
            } else {
                return 0;
            }
        },
        PricingM() {
            if (this.Interval == 1) {
                if (this.PackageM3)
                    return this.PackageM3.NetAmount
                else return 0
            } else if (this.Interval == 2) {
                if (this.PackageM6)
                    return this.PackageM6.NetAmount
                else return 0
            } else if (this.Interval == 3) {
                if (this.PackageM12)
                    return this.PackageM12.NetAmount
                else return 0
            } else {
                return 0;
            }
        },
        PricingL() {
            if (this.Interval == 1) {
                if (this.PackageL3)
                    return this.PackageL3.NetAmount
                else return 0
            } else if (this.Interval == 2) {
                if (this.PackageL6)
                    return this.PackageL6.NetAmount
                else return 0
            } else if (this.Interval == 3) {
                if (this.PackageL12)
                    return this.PackageL12.NetAmount
                else return 0
            } else {
                return 0;
            }
        }

    },
    methods: {
        cutDateString,
        rowClicked(item) {
            this.selectedItem = null;
            if (!item) return;
            this.selectedItem = item;
        },
        rowClickedNew(item) {
            this.selectedItemNew = null;
            if (!item) return;
            this.selectedItemNew = item;
        },
        addMainLicense(licenseType, item = null) {
            let licObj = null;
            let count = 1;
            let mainLic = true;
            let checkDouble = true;
            switch (licenseType) {
                case 1:
                    switch (this.Interval) {
                        case 1:
                            licObj = this.PackageS3;
                            break;
                        case 2:
                            licObj = this.PackageS6;
                            break;
                        case 3:
                            licObj = this.PackageS12;
                            break;
                        default:
                            return;
                    }


                    break;
                case 2:
                    switch (this.Interval) {
                        case 1:
                            licObj = this.PackageM3;
                            break;
                        case 2:
                            licObj = this.PackageM6;
                            break;
                        case 3:
                            licObj = this.PackageM12;
                            break;
                        default:
                            return;
                    }


                    break;
                case 3:
                    switch (this.Interval) {
                        case 1:
                            licObj = this.PackageL3;
                            break;
                        case 2:
                            licObj = this.PackageL6;
                            break;
                        case 3:
                            licObj = this.PackageL12;
                            break;
                        default:
                            return;
                    }
                    break;
                case 4:
                    licObj = item;
                    mainLic = false;
                    checkDouble = false;
                    break;
                default:
                    return;
            }

            if (licObj == null)
                return;

            let newLic = {
                PkCompanyLicense: uuid.v4(),
                FkCompany: store.state.FkCompany,
                LicenseType: licObj.LicenseType,
                Interval: this.Interval,
                Count: count,
                IsValid: true,
                ValidFrom: new Date(),
                Cancelled: false,
                MainLicense: mainLic,
                AutoRenew: true,
                TrackingState: CREATE
            };

            let found = lodash.filter(this.NewLicensesList, x => x.LicenseType == newLic.LicenseType);

            if (checkDouble === true) {
                if (found != null && found.length > 0)
                    return;
            } else {
                if (found != null && found.length > 0) {
                    found[0].Count += 1;
                    return;
                }
            }

            this.NewLicensesList.push(newLic);

        },
        cancelLicense(data, cancel) {
            if (data == null)
                return;


            new window.Swal({
                title: cancel ? this.trans('CANCEL-LICENSE') : this.trans('UNCANCEL-LICENSE'),
                text: cancel ? this.trans('REALLY-CANCEL-LICENSE') : this.trans('REALLY-UNCANCEL-LICENSE'),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.trans('NO'),
                confirmButtonText: cancel ? this.trans('CANCEL-LICENSE') : this.trans('UNCANCEL-LICENSE'),
                iconColor: '#ce6f74',
                padding: '2em'
            }).then(saResult => {
                if (saResult.isConfirmed) {

                    this.isSaving = true;

                    data.TrackingState = UPDATE;
                    data.Cancelled = cancel;

                    this.controller.update([data]).then(response => {
                        if (!response.IsError) {
                            this.$notify(
                                {
                                    title: this.trans('SUCCESS'),
                                    text: this.trans('DATA-SAVED'),
                                    type: 'success'
                                });

                            this.controller.read().then(res => {
                                this.LicensesList = res.CompanyLicensesList;
                            })


                        } else {

                            this.$notify(
                                {
                                    title: this.trans('ERROR'),
                                    text: this.trans(response.ErrorMessage),
                                    type: 'error',
                                });
                        }
                    }).finally(() => {
                        this.isSaving = false;
                    })
                }
            })
        },
        noRenew(data, enable) {
            if (data == null)
                return;


            new window.Swal({
                title: !enable ? this.trans('CANCEL-RENEW-LICENSE') : this.trans('ENABLE-RENEW-LICENSE'),
                text: !enable ? this.trans('REALLY-CANCEL-RENEW-LICENSE') : this.trans('REALLY-ENABLE-RENEW-LICENSE'),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.trans('NO'),
                confirmButtonText: !enable ? this.trans('CANCEL-RENEW-LICENSE') : this.trans('ENABLE-RENEW-LICENSE'),
                iconColor: '#ce6f74',
                padding: '2em'
            }).then(saResult => {
                if (saResult.isConfirmed) {

                    this.isSaving = true;

                    data.TrackingState = UPDATE;
                    data.AutoRenew = enable;

                    this.controller.update([data]).then(response => {
                        if (!response.IsError) {
                            this.$notify(
                                {
                                    title: this.trans('SUCCESS'),
                                    text: this.trans('DATA-SAVED'),
                                    type: 'success'
                                });

                            this.controller.read().then(res => {
                                this.LicensesList = res.CompanyLicensesList;
                            })


                        } else {

                            this.$notify(
                                {
                                    title: this.trans('ERROR'),
                                    text: this.trans(response.ErrorMessage),
                                    type: 'error',
                                });
                        }
                    }).finally(() => {
                        this.isSaving = false;
                    })
                }
            })
        },
        GetNetAmount(item) {
            if (item.LicenseType == LICENSE_TYPES.PACKAGE_S ||
                item.LicenseType == LICENSE_TYPES.PACKAGE_M ||
                item.LicenseType == LICENSE_TYPES.PACKAGE_L) {
                let found = lodash.filter(this.LicensePricesList, x => x.LicenseType == item.LicenseType && x.PaymentInterval == item.Interval);
                if (found != null && found.length > 0) {
                    return (found[0].NetAmount * item.Count).toFixed(2) + '€'
                }
            } else {
                let found = lodash.filter(this.LicensePricesList, x => x.LicenseType == item.LicenseType);
                if (found != null && found.length > 0) {
                    return (found[0].NetAmount * item.Count).toFixed(2) + '€'
                }
            }

            return ''
        },
        GetGrossAmount(item) {
            if (item.LicenseType == LICENSE_TYPES.PACKAGE_S ||
                item.LicenseType == LICENSE_TYPES.PACKAGE_M ||
                item.LicenseType == LICENSE_TYPES.PACKAGE_L) {
                let found = lodash.filter(this.LicensePricesList, x => x.LicenseType == item.LicenseType && x.PaymentInterval == item.Interval);
                if (found != null && found.length > 0) {
                    return (found[0].GrossAmount * item.Count).toFixed(2) + '€'
                }
            } else {
                let found = lodash.filter(this.LicensePricesList, x => x.LicenseType == item.LicenseType);
                if (found != null && found.length > 0) {
                    return (found[0].GrossAmount * item.Count).toFixed(2) + '€'
                }
            }

            return ''
        },
        removeLicense(data) {
            // Main License
            if (data.LicenseType <= 3) {
                this.NewLicensesList = [];
            } else
                this.NewLicensesList.splice(this.NewLicensesList.indexOf(data), 1);
        },
        nevigateToCompany() {
            router.push("/Config/MasterData/MasterDataCompany");
        },
        checkIfLicAlreadyIn(licType) {

            switch (licType) {
                case LICENSE_TYPES.PACKAGE_S:
                case LICENSE_TYPES.PACKAGE_M:
                case LICENSE_TYPES.PACKAGE_L:

                    let foundPackages = lodash.filter(this.NewLicensesList, x => x.LicenseType == LICENSE_TYPES.PACKAGE_S
                        || x.LicenseType == LICENSE_TYPES.PACKAGE_M
                        || x.LicenseType == LICENSE_TYPES.PACKAGE_L);
                    if (foundPackages != null && foundPackages.length > 0)
                        return true;

                    foundPackages = lodash.filter(this.LicensesList, x => x.LicenseType == LICENSE_TYPES.PACKAGE_S
                        || x.LicenseType == LICENSE_TYPES.PACKAGE_M
                        || x.LicenseType == LICENSE_TYPES.PACKAGE_L);
                    if (foundPackages != null && foundPackages.length > 0)
                        return true;

                    break;

                // Ignore them
                case LICENSE_TYPES.COMPANY_USER:
                case LICENSE_TYPES.JOB_ADS:
                case LICENSE_TYPES.LOCATIONS:
                    break;

                case LICENSE_TYPES.MATCHES_25:
                case LICENSE_TYPES.MATCHES_50:
                case LICENSE_TYPES.MATCHES_LIMITLESS:
                    let foundMatches = lodash.filter(this.NewLicensesList, x => x.LicenseType == LICENSE_TYPES.MATCHES_LIMITLESS
                        || x.LicenseType == LICENSE_TYPES.PACKAGE_L);
                    if (foundMatches != null && foundMatches.length > 0)
                        return true;

                    foundMatches = lodash.filter(this.LicensesList, x => x.LicenseType == LICENSE_TYPES.MATCHES_LIMITLESS
                        || x.LicenseType == LICENSE_TYPES.PACKAGE_L);
                    if (foundMatches != null && foundMatches.length > 0)
                        return true;


                    break;

                case LICENSE_TYPES.TOP_COMPANY:
                    let foundTop = lodash.filter(this.NewLicensesList, x => x.LicenseType == LICENSE_TYPES.TOP_COMPANY);
                    if (foundTop != null && foundTop.length > 0)
                        return true;

                    foundTop = lodash.filter(this.LicensesList, x => x.LicenseType == LICENSE_TYPES.TOP_COMPANY);
                    if (foundTop != null && foundTop.length > 0)
                        return true;
                    break;
            }

            return false;
        },
        getPricing(value) {
            return Math.round(value / this.Months)
        },
        GetLicenseName(licenseType) {
            let found = lodash.filter(this.LicensePricesList, x => x.LicenseType == licenseType);
            if (found != null && found.length > 0)
                return found[0].Name;
            else
                return '';

        },
        GetIntervalName(item) {

            if (item.LicenseType == LICENSE_TYPES.TOP_COMPANY)
                return 1;

            if (item.Interval === 3)
                return 12;
            else
                return item.Interval * 3;

        },
        GetIntervalNameNew(item) {
            let part1 = this.GetIntervalName(item);
            let part2 = '';
            let found = lodash.filter(this.LicensesList, x => x.LicenseType == LICENSE_TYPES.PACKAGE_S || x.LicenseType == LICENSE_TYPES.PACKAGE_M || x.LicenseType == LICENSE_TYPES.PACKAGE_L);
            if (found != null && found.length > 0) {
                part2 = cutDateString(found[0].ValidTill);
            } else {
                let date = new Date();
                date = date.setMonth(date.getMonth() + item.Interval);
                part2 = cutDateString(date)
            }


            return part1 + ' (bis ' + part2 + ')';
        },
        save() {

            if (this.NewLicensesList.length <= 0)
                return;

            let found = lodash.filter(this.NewLicensesList, x => x.LicenseType == LICENSE_TYPES.PACKAGE_S ||
                x.LicenseType == LICENSE_TYPES.PACKAGE_M ||
                x.LicenseType == LICENSE_TYPES.PACKAGE_L)

            let found2 = lodash.filter(this.LicensesList, x => x.LicenseType == LICENSE_TYPES.PACKAGE_S ||
                x.LicenseType == LICENSE_TYPES.PACKAGE_M ||
                x.LicenseType == LICENSE_TYPES.PACKAGE_L)

            if ((found != null && found.length > 0) || (found2 != null && found2.length > 0)) {

                new window.Swal({
                    title: this.trans('BUY-LICENSES'),
                    text: this.trans('REALLY-BUY-LICENSES'),
                    icon: 'warning',
                    showCancelButton: true,
                    cancelButtonText: this.trans('NO'),
                    confirmButtonText: this.trans('BUY-LICENSES'),
                    iconColor: '#ce6f74',
                    padding: '2em'
                }).then(saResult => {
                    if (saResult.isConfirmed) {

                        this.isSaving = true;

                        this.controller.create(this.NewLicensesList).then(response => {
                            if (!response.IsError) {
                                this.$notify(
                                    {
                                        title: this.trans('SUCCESS'),
                                        text: this.trans('DATA-SAVED'),
                                        type: 'success'
                                    });

                                this.NewLicensesList = [];
                                this.controller.read().then(res => {
                                    this.LicensesList = res.CompanyLicensesList;
                                })


                            } else {

                                this.$notify(
                                    {
                                        title: this.trans('ERROR'),
                                        text: this.trans(response.ErrorMessage),
                                        type: 'error',
                                    });
                            }
                        }).finally(() => {
                            this.isSaving = false;
                        })
                    }
                })
            }


        },
        loadAllData() {
            Promise.all([
                this.axios.post(restUri.GET_COMPANY_LICENSES, restBody({FkCompany: store.state.FkCompany})),
                this.axios.post(restUri.GET_PRICINGS, restBody({})),
                this.controllerCompany.read()
            ]).then(values => {
                this.LicensesList = values[0].data.CompanyLicensesList;
                this.LicensePricesList = values[1].data.PricingItemsList;
                this.Company = values[2].Company;

                this.IntervalMode = this.CompanyInvoiceContact.PaymentInterval;
                if (this.IntervalMode == null || this.IntervalMode <= 0)
                    this.IntervalMode = 1;

                if (!values[1].data.IsError) {

                    values[1].data.PricingItemsList.forEach(item => {

                        if (item.LicenseType == 1 && item.PaymentInterval == 1) {
                            this.PackageS3 = item;
                        } else if (item.LicenseType == 2 && item.PaymentInterval == 1) {
                            this.PackageM3 = item;
                        } else if (item.LicenseType == 3 && item.PaymentInterval == 1) {
                            this.PackageL3 = item;
                        } else if (item.LicenseType == 1 && item.PaymentInterval == 2) {
                            this.PackageS6 = item;
                        } else if (item.LicenseType == 2 && item.PaymentInterval == 2) {
                            this.PackageM6 = item;
                        } else if (item.LicenseType == 3 && item.PaymentInterval == 2) {
                            this.PackageL6 = item;
                        } else if (item.LicenseType == 1 && item.PaymentInterval == 3) {
                            this.PackageS12 = item;
                        } else if (item.LicenseType == 2 && item.PaymentInterval == 3) {
                            this.PackageM12 = item;
                        } else if (item.LicenseType == 3 && item.PaymentInterval == 3) {
                            this.PackageL12 = item;
                        }

                    })
                }


            });
        }
    },
    activated() {
        this.loadAllData();
    }
}
</script>

<style scoped lang="scss">

.check-margin {
    margin-right: 4px;
}

.check-margin-gray {
    margin-right: 15px;
    color: rgba(#b2b0b0, 0.4);
}

.check-margin-10 {
    margin-right: 10px;
}

.x-margin-gray {
    color: rgba(#b2b0b0, 0.4);
    margin-right: 5px;
}

.grayout {
    color: rgba(#b2b0b0, 0.4);
}

.tss {
    display: inline-block;
    position: relative;
    user-select: none;
    background: #222a42;
    border-radius: 25px;
    color: white;
    height: 50px;

    input[type='radio'] {
        display: none;

        &:checked {
            & + label {
                span {
                    &:before {
                        display: inline-block;
                    }
                }
            }
        }
    }

    label {
        position: relative;
        display: inline-block;
        cursor: pointer;
        padding: 15px 10px;
        min-width: 50px;
        color: white;
        font-weight: bold;

        span {
            &:before {
                content: '';
                position: absolute;
                display: none;
                width: 100%;
                height: 100%;
                color: #000000;


                border-radius: 100%;
                background: transparentize(#FFB92A, 0.3);
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }
    }
}

.ribbon-container {
    position: absolute;
    left: 0;
    top: -3px;
    width: 100%;
    height: 120px;
    overflow: hidden;
}

.ribbon-flag {
    background: radial-gradient(#313030, #515152);
    color: white;
    position: absolute;
    display: block;
    top: 15px;
    right: -45px;
    width: 170px;
    padding: 12px 20px 8px;
    margin: 0;
    transform: rotate(45deg);
    height: 50px;
    z-index: 2;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
    text-align: center;
}

.margin-top-60 {
    margin-top: 60px;
}

.container {
    display: block !important;
    margin-left: 0 !important;
    max-width: 100% !important;
    @media (min-width: 1400px) {
        max-width: 100%;
    }
}

.in-development {
    font-family: 'Poppins';
    margin-bottom: 30px;

}


.custom-table {
    background: transparent;
}

.btn svg {
    margin-right: 8px;
}

.dark {
    --popper-theme-background-color: #333333;
    --popper-theme-background-color-hover: #333333;
    --popper-theme-text-color: white;
    --popper-theme-border-width: 0px;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 15px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

:deep(.popper #arrow::before) {
    left: 0px;
}

.popOverText {
    color: white;
    font-size: 14px;
    font-weight: 500;
}

.margin-left-5 {
    margin-left: 1px !important;
}

</style>

