<template>
    <div :class="[$store.state.layout_style, getMenuStyle()]">
        <component v-bind:is="layout"></component>
    </div>
    <notifications classes="vue-notification" position="bottom center" :duration="3000" animation-type="css"
                   :pause-on-hover="true">
    </notifications>
    <vue-cookie-comply
        :preferences="preferences"
        headerTitle="Cookie-Einstellungen"
        headerDescription="Wir speichern verschiedene Cookies um Ihnen die Nutzung der Seite zu vereinfachen. Darunter werden folgende Cookies gesetzt: Login-Emailadresse (nach erfolgreichem Login), Auto-Login, LoggedIn (für Neuladen der Seite)"
        preferencesLabel="Einstellungen"
        acceptAllLabel="Alle akzeptieren"
        @on-accept-all-cookies="onAccept"
        @on-save-cookie-preferences="onSavePreferences"
    />
</template>

<script setup>
import {computed} from 'vue';

import '@/scss/jobineo/sass/app.scss';

import {useMeta} from '@/composables/use-meta';
import {useStore} from 'vuex';
import {useCookies} from "vue3-cookies";


useMeta({title: 'JoBiNeo'});
const store = useStore();

const layout = computed(() => {
    return store.getters.layout;
});
</script>

<script>
import store from './store.js'

const {cookies} = useCookies();
import appLayout from './layouts/app-layout.vue';
import authLayout from './layouts/auth-layout.vue';
import {cookiePurge} from "@/utils/cookiePurge";

export default {
    components: {
        app: appLayout,
        auth: authLayout,
    },
    data() {
        return {
            collapsed: true,
            preferences: [
                {
                    title: "Sitzungscookies",
                    description:
                        "Wir speichern verschiedene Cookies um Ihnen die Nutzung der Seite zu vereinfachen. Darunter werden folgende Cookies gesetzt: Login-Emailadresse (nach erfolgreichem Login), Auto-Login, LoggedIn (für Neuladen der Seite)",
                    items: [{label: 'Active', value: 'Standard', isRequired: true, isEnabled: true}],
                }
            ]
        }
    },
    computed: {
        loggedIn() {
            return store.state.loggedIn;
        },
        collapsedComp() {
            return this.collapsed;
        }
    },
    methods: {
        onAccept() {
            cookies.set('cookies-accepted', true);
        },
        onSavePreferences() {
            cookies.set('cookies-accepted', true);
        },
        sidebarCollapsing(collapsed) {
            this.collapsed = collapsed;
        },
        getMenuStyle() {
            return store.state.menu_style ? store.state.menu_style : 'no-menu'
        }
    },
    beforeUnmount() {
        window.removeEventListener('storage');
    },
    beforeMount() {


        window.addEventListener('storage', (event) => {

                if (event.storageArea !== localStorage) return;

                const useCookie = cookies.get('cookies-accepted') === 'true';

                if (event.key === 'loggedIn') {
                    localStorage.setItem('loggedIn', event.newValue);
                    if (useCookie) {
                        cookies.set('loggedIn', event.newValue)
                    }
                }

                if (event.key === 'loggedInSessionId') {

                    store.commit('replaceRequestMergeSessionID', event.newValue);

                    if (useCookie) {
                        cookies.set('loggedInSessionId', event.newValue)
                    }
                    localStorage.setItem('loggedInSessionId', event.newValue);
                }

                if (event.key === 'rememberMe') {
                    if (useCookie) {
                        cookies.set('rememberMe', event.newValue)
                    }
                    localStorage.setItem('rememberMe', event.newValue);
                }

                if (event.key === 'rememberId') {
                    localStorage.setItem('rememberId', event.newValue);
                    if (useCookie) {
                        cookies.set('rememberId', event.newValue)
                    }
                }

                if (event.key === 'Email') {
                    localStorage.setItem('Email', event.newValue);
                    if (useCookie) {
                        cookies.set('Email', event.newValue)
                    }
                }


                if (!useCookie) {
                    cookiePurge();
                }

            }
        );

        this.$store.state.IsEdgeOrIE = this.$browserDetect.isEdge || this.$browserDetect.isIE;

        this.$store.dispatch('getSystemSettings').then(result => {
            this.$store.dispatch('getSystemNotifications').then(result => {
                if (result != null && result.IsError == false && result.AnnouncementsList != null && typeof result.AnnouncementsList !== 'undefined' && Array.isArray(result.AnnouncementsList)) {
                    this.Notification = result.AnnouncementsList[0];
                } else {
                    this.Notification = null;
                }
            })

        });

        this.$store.dispatch('getAllLookups').then(result => {
            if (result != null && result.IsError == false && result.AnnouncementsList != null && typeof result.AnnouncementsList !== 'undefined' && Array.isArray(result.AnnouncementsList)) {
                this.Notification = result.AnnouncementsList[0];
            } else {
                this.Notification = null;
            }
        })


    }

}
</script>
<style lang="scss">

#app-wrapper {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;

}


#app {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;

}

.cookie-comply {
    position: fixed !important;
    bottom: 0 !important;
}

.cookie-comply__header-title {
    color: #000000 !important;
}

.cookie-comply__header-description {
    color: #000000 !important;
}

.cookie-comply__modal-content {
    color: #000000 !important;

    p {
        color: #000000 !important;
    }

    h2 {
        color: #000000 !important;
    }
}


.cookie-comply__modal-header {
    color: #000000 !important;

    h3 {
    }
}

.vue-notification {
    // styling margin: 0 5px 5px;
    padding: 20px !important;
    font-size: 15px !important;
    color: #000000 !important;
    font-weight: 300 !important;
    width: 100% !important;
    z-index: 99999 !Important;

    // default (blue) background: #44a4fc;
    border-left: 5px solid #ffffff !important;

    .notification-title {
        font-size: 18px !important;
        font-weight: 900 !important;
    }

    // types (green, amber, red)

    &.success {
        background: #9acccb !important;
        border-left-color: #62a2a1 !important;
    }

    &.warn {
        background: #fdb82a !important;
        border-left-color: #be8311 !important;
    }

    &.error {
        background: #ce6f74 !important;
        border-left-color: #c5575f !important;
    }
}

.vue-notification-group {
    display: block;
    position: fixed;
    left: 0 !important;
    margin-left: 50px !important;
    align-content: center !important;
    align-self: center !important;
    z-index: 99999 !important;
    width: 95% !important;
}

</style>
