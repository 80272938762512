import { isObject } from './isObject.js';


/**
 * Does mege the update object with sub objects  into the base object and its sub objects
 * returns a new mutated object
 * @param base
 * @param updater
 * @returns {any}
 */
export function mergeDeep(base, updater) {
    const isUpdaterEmpty = Object.keys(updater).length === 0;
    if (isUpdaterEmpty) return base;

    const result = Object.assign({}, base);
    for (const key in updater) {
        if (isObject(base[key]) && isObject(updater[key])) {
            Object.assign(result, {
                [key]: mergeDeep(base[key], updater[key])
            });
        } else {
            Object.assign(result, { [key]: updater[key] });
        }
    }
    return result;
}
