export const SEARCH_TYPES = {
    COMPANY: 1,
    JOB_AD: 2,
    STUDENT: 3,
    SCHOOL: 4
}


export const SEARCH_TYPES_DROPDOWN = [
    {
        label: 'SEARCH-TYPES-COMPANY',
        value: SEARCH_TYPES.COMPANY
    },
    {
        label: 'SEARCH-TYPES-JOB-AD',
        value: SEARCH_TYPES.JOB_AD
    },
    {
        label: 'SEARCH-TYPES-STUDENT',
        value: SEARCH_TYPES.STUDENT
    },
    {
        label: 'SEARCH-TYPES-SCHOOL',
        value: SEARCH_TYPES.SCHOOL
    }
]

