/**
 * This function flattens a route object tree to an Array of objects.
 * Every item of this object array does have following properties:
 * path: the full relative route path.
 * title: the title which is displayed in menus as label.
 * level: the tree level of the former object
 * icon: an icon string if exists
 * hasChildren: a flag which indicates that this object has children in the original object tree.
 *
 * @param routeArr
 * @param computedArr
 * @param level
 * @param basePath
 * @returns {Array}
 */
export function flattenRoutePathTree(routeArr = [], computedArr = [], level = 0, basePath = '') {
    if (Array.isArray(routeArr))
        routeArr.forEach(el => {
            let hasChildren = el.children != null && Array.isArray(el.children);

            computedArr.push({
                path: basePath + el.path,
                title: el.title != null && el.title ? el.title : el.name,
                level,
                icon: el.icon != null ? el.icon : '',
                hasChildren,
                ignore: el.ignore,
                setDivider: el.setDivider != null && el.setDivider ? el.setDivider : false,
                checkLoginState: el.checkLoginState != null && el.checkLoginState ? el.checkLoginState : 0,
                CheckIfUserType: el.CheckIfUserType != null ? el.CheckIfUserType : null,
                CheckIfProd: el.CheckIfProd != null && el.CheckIfProd ? el.CheckIfProd : false
            });

            if (hasChildren)
                flattenRoutePathTree(el.children, computedArr, (level + 1), basePath + el.path);
        });

    return computedArr;
}
