<template>
    <dashboard-student v-if="isStudent"></dashboard-student>
    <dashboard-company v-if="!isStudent"></dashboard-company>
</template>
<script>

import {REGISTER_TYPES} from "@/data/RegisterTypes";
import DashboardStudent from "@/views/dashboard/DashboardStudent.vue";
import DashboardCompany from "@/views/dashboard/DashboardCompany.vue";

export default {
    name: "dashboard-comp",
    components: {
        DashboardCompany,
        DashboardStudent
    },
    data() {
        return {}
    },
    computed: {
        isStudent() {
            return this.$store.state.RegisterType === REGISTER_TYPES.STUDENT;
        },

    },

}

</script>
<style scoped lang="scss">

</style>
