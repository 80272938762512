/**
 *
 * Performs preventUpdate m preventDefault or stopPropagation on an HTML event.
 *
 * @param event
 * @param preventUpdate
 * @param preventDefault
 * @param stopPropagation
 */
export function silentEvent(event = null, preventUpdate = true, preventDefault = true, stopPropagation = true) {
  if (event == null) return;

  if (preventUpdate)
    event.preventUpdate = true;

  if (preventDefault)
    event.preventDefault();

  if (stopPropagation)
    event.stopPropagation();

}
