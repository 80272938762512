<template>
  <span>
    <slot name="label"></slot>
  </span>
</template>
<script>
export default {
  name: 'wizard-tab-item',
  data() {
    return {};
  }
};
</script>
<style scoped lang="scss">


</style>
