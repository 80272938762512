<template>
    <div class="module-content-wrapper">
        <div class="mc-content">
            <div class="section-heading-default">
                <router-link to="/Config/Configuration"
                             :class="{'no-decoration':true, ' breadcrumb-decorate-blue':rootBreadcrumbActive}">
                    <font-awesome-icon icon="fa-cog"/>
                    {{ $t('CONFIGURATION') }} &nbsp;
                </router-link>
                <template v-for="(breadcrumb, bidx) in breadcrumbs" :key="bidx">
        <span :class="{'breadcrumb-decorate-blue':breadcrumb.fullpath===$route.path}">
          <template v-if="breadcrumb.fullpath!==$route.path">
            <router-link :to="breadcrumb.fullpath" :class="{'no-decoration':true, 'not-blue':true}">
              <font-awesome-icon icon="fa-angle-right"/> &nbsp;
              <font-awesome-icon :icon="breadcrumb.icon"/>  {{ $t(breadcrumb.title) }} &nbsp;
            </router-link>
          </template>
          <template v-else>
            <font-awesome-icon icon="fa-angle-right"/> &nbsp;
            <font-awesome-icon :icon="breadcrumb.icon"/>  {{ $t(breadcrumb.title) }} &nbsp;
          </template>
        </span>
                </template>
            </div>
            <div v-if="cards">
                <div v-for="(child, idx) in cards" v-bind:key="idx">
                    <router-link :to="$route.path+child.path" class="">
                        <div class="area-button-link float-left">
                            <div class="area-button-link-flex">
                                <div class="title">
                                    {{ $t(child.title) }}
                                </div>
                                <i :class="child.icon"/>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <keep-alive>
                <router-view/>
            </keep-alive>
        </div>
    </div>
</template>

<script>
/**
 * View: MasterData
 * Path: /Config/MasterData/...
 * A sub view for the config area which represents
 * another router-view to route further sub views.
 * A route-wrapper for master data sub views
 *
 */
import {configRootNode, getByName} from "@/routes/config.routes.js";

let children = configRootNode != null && configRootNode.children != null ? configRootNode.children : [];

let menuItems = [
    {
        path: '/Config/',
        icon: 'fa fa-database',
        title: 'MASTER-DATA',
        name: 'MasterData',
        isRoot: true,
        meta: {breadcrumb: []},
        children: []
    }
].concat(
    children.map(item => ({
        path: '/MasterData/' + item.path,
        icon: item.icon,
        title: item.title,
        name: item.name,
        meta: item.meta != null ? item.meta : {},
        children: item.children != null ? item.children : []
    }))
);


const ComponentName = 'MasterData';

export default {
    name: ComponentName,
    data() {
        return {
            menuItems
        }
    },
    computed: {
        cards: function () {
            if (this.$route.name === ComponentName) {
                let obj = getByName(ComponentName);
                return obj && obj.children != null ? obj.children : null;
            } else return null;
        },
        rootBreadcrumbActive() {
            return this.$route.path === '/Config/Configuration';
        },
        hasBottomMenu() {
            let resourceRouteNode = getByName(this.$route.name);
            return resourceRouteNode.isRoot == null;
        },
        bottomMenuItems() {
            let routeNode = this.$route.meta.level === 1 ? getByName(this.$route.name) : getByName(this.$route.meta.parentName);

            return routeNode.children != null && (routeNode.isRoot == null || !routeNode.isRoot) ? routeNode.children : [];
        },
        breadcrumbs() {
            let breadcrumbs = this.$route.meta != null && this.$route.meta.breadcrumb != null ? this.$route.meta.breadcrumb : [];
            return breadcrumbs;
        }
    },
    mounted() {
    }
}
</script>


<style scoped lang="scss">

a {
    color: #858796;
}

</style>
