import {APPOINTMENT_TYPES} from "@/data/appointment";
import {REGISTER_TYPES} from "@/data/RegisterTypes";
import lodash from "lodash";

export const setAppointments = (state, data) => {
    state.Appointments.AppointmentList = data.AppointmentList;
    state.Appointments.CalendarList = [];
    data.AppointmentList.forEach(item => {

        let className = '';
        switch (item.AppType) {
            case APPOINTMENT_TYPES.APTITUDE_TEST_ON_SITE:
                className = 'bg-yellow';
                break;
            case APPOINTMENT_TYPES.APTITUDE_TEST_ONLINE:
                className = 'bg-mint';
                break;
            case APPOINTMENT_TYPES.INTERVIEW_ONLINE:
                className = 'bg-white';
                break;
            case APPOINTMENT_TYPES.INTERVIEW_ON_SITE:
                className = 'bg-red';
                break;
        }

        let title = '';
        if (state.RegisterType === REGISTER_TYPES.STUDENT) {
            let foundMain = lodash.filter(item.AppointmentUsersViewDTOList, x => x.FkUser !== state.User.PkUser);
            if (foundMain.length > 0) {
                title = item.AppTypeNameNDF + ' ' + foundMain[0].OfficeName + '-' + item.Subject;
            }
        } else {
            let foundMain = lodash.filter(item.AppointmentUsersViewDTOList, x => x.FkUser !== state.User.PkUser);
            if (foundMain.length > 0) {
                title = item.AppTypeNameNDF + ' ' + foundMain[0].FirstName + ' ' + foundMain[0].LastName + '-' + item.Subject;
            }
        }


        let obj = {
            id: item.PkAppointment,
            title: title,
            start: item.StartDate,
            end: item.EndDate,
            description: item.AppBody,
            className: className,
        };

        state.Appointments.CalendarList.push(obj);
    })

}









