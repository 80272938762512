export const MFA_TYPES = {
  GOOGLE_AUTHENTICATOR : 1,
  AUTHY:2
}


export const MFA_TYPES_DROPDOWN = [
  {
    label: 'GOOGLE-AUTHENTICATOR',
    value: MFA_TYPES.GOOGLE_AUTHENTICATOR
  },
  {
    label: 'AUTHY-AUTHENTICATOR',
    value: MFA_TYPES.AUTHY
  },

]

