<template>
    <div class="">
        <div class="">
            <div class="section-heading">
                <router-link to="/UserAccount/Profile" class="no-decoration gray-color">
                    <font-awesome-icon icon="fas fa-cog"/>
                    {{ $t('CONFIG') }}
                </router-link>
                <span class="no-decoration breadcrumb-decorate-yellow">
                <font-awesome-icon icon="fas fa-angle-right"/> &nbsp;
                <font-awesome-icon icon="fas fa-user"/>
                {{ trans('PROFILE') }}
            </span>

                <base-button type="green"
                             native-type="button"
                             class="btn  btn-mint float-right"
                             @click="edit">
                    <font-awesome-icon icon="fas fa-edit"/>
                    {{ trans('EDIT-ACCOUNT') }}
                </base-button>

            </div>
            <div class="clearfix"></div>
            <table v-if="PkUser" class="table table-xl  user-table">
                <tbody>
                <tr class="">
                    <td class="td-left">{{ trans('PROFILE-PICTURE') }}:</td>
                    <td>
                        <div class="col-xl-2 col-lg-12 col-md-4">
                            <div class="upload mt-4 pe-md-4">

                                <img
                                    :src="selected_file ? selected_file : require('@/assets/images/empty-profile.jpg')"
                                    alt="profile"
                                    class="profile-preview"
                                />
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="">
                    <td class="td-left">{{ trans('USER') }}:</td>
                    <td>{{ $store.state.User.FirstName }} {{ $store.state.User.LastName }}</td>
                </tr>
                <tr class="">
                    <td class="td-left">{{ trans('EMAIL') }}:</td>
                    <td>{{ $store.state.User.Email }}</td>
                </tr>
                <tr class="">
                    <td class="td-left">{{ trans('LAST-LOGIN') }}:</td>
                    <td>{{ cutDateTimeString($store.state.User.LastLoginDate) }}</td>
                </tr>
                <tr class="">
                    <td class="td-left">{{ trans('LANGUAGE') }}:</td>
                    <td>{{ trans(getLanguage) }}</td>
                </tr>
                <tr class="">
                    <td class="td-left">{{ trans('MFA-ACTIVE') }}:</td>
                    <td>{{ getMFAActive }}</td>
                </tr>
                <tr class="">
                    <td class="td-left">{{ trans('MFA-TYPES') }}:</td>
                    <td>{{ getMFAType }}</td>
                </tr>
                </tbody>
            </table>
            <form @submit="$noSubmitHandler" ref="_form" style="margin-top:30px;padding-left:5px">
                <div class="form-group" v-if="IsTerminated">
                    <div class="office-question-wrap">
                        <h6 class="underlined margin-bottom-10 padding-bottom-5 bold-header">
                            {{ trans('REVERT-TERMINATION') }}</h6>
                        <div
                            class="warning-label">{{ trans('WARNING-REVERT-TERMINATION') }}
                        </div>
                        <div>{{ trans('TERMINATION-DATE') }}</div>
                        <div>{{ cutDateString(TerminationDate) }}</div>
                    </div>
                </div>
                <div class="form-group" v-else>
                    <div class="office-question-wrap">
                        <h6 class="underlined margin-bottom-10 padding-bottom-5 bold-header">
                            {{ trans('TERMINATION') }}</h6>
                        <div
                            class="warning-label">{{ trans('WARNING-TERMINATION') }}
                        </div>
                    </div>
                </div>
                <button ref="silentSubmit" type="submit" class="btn-silent-submit"/>
            </form>
            <div>
                <b-alert
                    v-model="error"
                    variant="danger"
                    class="error-alert"
                >
                    <div class="error-alert-wrap"><font-awesome-icon icon="fas fa-exclamation-triangle" class="error-icon" /> {{ errorMessage }}</div>
                </b-alert>
            </div>
            <base-button type="red"
                         native-type="button"
                         v-if="!IsTerminated"
                         class="btn  btn-red float-right margin-bottom-15"
                         style="margin-top:10px"
                         @click="terminate">
                <font-awesome-icon v-if="!isUpdating" icon="fas fa-check"/>
                <font-awesome-icon v-if="isUpdating" icon="fa fa-spin fa-circle-notch"/>
                {{ trans('TERMINATE') }}
            </base-button>
            <base-button type="red"
                         native-type="button"
                         v-if="IsTerminated"
                         class="btn btn-red float-right margin-bottom-15"
                         style="margin-top:10px"
                         @click="revertTermination">
                <font-awesome-icon v-if="!isUpdating" icon="fas fa-check"/>
                <font-awesome-icon v-if="isUpdating" icon="fa fa-spin fa-circle-notch"/>
                {{ trans('TERMINATE-REVERT') }}
            </base-button>
            <div v-show="loading" class="sc-center-box">
                <span v-show="loading">
                  <b-spinner class="align-middle"/> &nbsp;
                  <strong>{{ trans('LOADING') }}...</strong>
                </span>
            </div>

        </div>


    </div>

</template>

<script>
/**
 * View: Profile
 * Path: /UserAccount/Profile
 * A sub view for the user account area.
 * Displays a user's credentials and data
 *
 */
import {restUri} from "@/data/restPaths";
import {restBody} from "@/utils/restBody";
import {MFA_TYPES} from "@/data/MFATypes";
import router from "@/router";
import {cutDateString, cutDateTimeString} from "@/utils/convertDateTime";
import store from "@/store";
import {ProfileController} from "@/controllers/profile.controller";

export default {
    name: "profile-comp",
    data() {
        return {
            controller: new ProfileController(this),
            error: false,
            errorMessage: null,
            isUpdating: false,
            loading: false,
            selected_file: null
        }
    },
    methods: {
        cutDateString,
        cutDateTimeString,
        edit() {
            router.push('/UserAccount/EditAccount')
        },
        revertTermination() {

            new window.Swal({
                title: this.trans('REVERT-TERMINATION'),
                text: this.trans('REALLY-REVERT-TERMINATE'),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.trans('NO'),
                confirmButtonText: this.trans('REVERT-TERMINATION'),
                iconColor: '#ce6f74',
                padding: '2em'
            }).then(saResult => {
                if (saResult.isConfirmed) {
                    return this.axios.post(restUri.TERMINATE_USER,
                        restBody({FkUser: this.$store.state.User.PkUser, Terminate: false})
                    ).then(result => {
                        if (result.data.IsError) {
                            this.error = true;
                            this.errorMessage = this.trans(result.data.ErrorMessage);
                            this.$notify(
                                {
                                    title: this.trans('ERROR'),
                                    text: this.trans(result.data.ErrorMessage),
                                    type: 'error'
                                });
                            return result.data
                        } else {
                            this.error = false;
                            this.errorMessage = '';
                            this.$store.state.User = result.data.User;
                            this.$notify(
                                {
                                    title: this.trans('SUCCESS'),
                                    text: this.trans('DATA-SAVED'),
                                    type: 'success'
                                });
                            return result.data
                        }
                    });
                }
            });
        },
        terminate() {

            new window.Swal({
                title: this.trans('TERMINATE'),
                text: this.trans('REALLY-TERMINATE'),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.trans('NO'),
                confirmButtonText: this.trans('TERMINATE'),
                padding: '2em'
            }).then(saResult => {

                if (saResult.isConfirmed) {
                    return this.axios.post(restUri.TERMINATE_USER,
                        restBody({FkUser: this.$store.state.User.PkUser, Terminate: true})
                    ).then(result => {
                        if (result.data.IsError) {
                            this.error = true;
                            this.errorMessage = this.trans(result.data.ErrorMessage);

                            this.$notify(
                                {
                                    title: this.trans('ERROR'),
                                    text: this.trans(result.data.ErrorMessage),
                                    type: 'error',
                                });

                            return result.data
                        } else {
                            this.error = false;
                            this.errorMessage = '';
                            this.$store.state.User = result.data.User;
                            this.$notify(
                                {
                                    title: this.trans('SUCCESS'),
                                    text: this.trans('DATA-SAVED'),
                                    type: 'success'
                                });
                            return result.data
                        }
                    });
                }
            });
        },

    },
    computed: {

        IsTerminated() {
            return this.$store.state.User != null && this.$store.state.User.TerminationDate != null;
        },
        TerminationDate() {
            return this.$store.state.User != null && this.$store.state.User.TerminationDate;
        },
        PkUser() {
            return this.$store.state.User != null && this.$store.state.User.PkUser != null ? this.$store.state.User.PkUser : null;
        },

        getMFAActive() {
            return this.$store.state.User.MFAActive === 1 ? this.trans('YES') : this.trans('NO')
        },
        getMFAType() {
            return this.$store.state.User.MFAType === MFA_TYPES.GOOGLE_AUTHENTICATOR ? this.trans('GOOGLE-AUTHENTICATOR') : ''
        },
        getLanguage() {
            if (store.state.User.DefaultLanguage != null) {
                return store.state.User.DefaultLanguage.toUpperCase();
            }
            return null;
        },
    },
    activated() {

        this.controller.GetProfilePicture(store.state.User.PkUser).then(result => {
            if (result.data.size === 0)
                this.selected_file = null;
            else
                this.selected_file = URL.createObjectURL(new Blob([result.data]));
        })
    }
}
</script>

<style lang="scss" scoped>

.td-left {
    min-width: 100px;
    max-width: 300px;
    /*border-right: 1px solid #dddddd;*/
    font-weight: bold;
}

.bold-header {
    font-weight: bold;
}

.user-table {
    border: none;
    font-size: 16px;
}

.user-table tr, .user-table tr td {
    border: none !important;
}

.profile-preview {
    cursor: default !important;
    opacity: 1 !important;
}


</style>
