<template>
    <div class="layout-top-spacing">
        <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
            <div class="col-xl-12 list-margin">
                <ul class="list-icon">
                    <li v-if="appointmentList.length <= 0" style="text-align:center;">
                        <span class="list-text">{{ trans('NO-APPOINTMENTS') }}</span>
                    </li>
                    <li class="list-height" v-for="item in appointmentList" :key="item.PkAppointment">
                        <font-awesome-icon :icon="item.Icon" class="icon-color">
                        </font-awesome-icon>
                        <span class="list-text">{{ item.AppointmentText }}</span>
                        <span class="float-right"><base-button
                            @click.stop="redirectToPage(item)"
                            native-type="button"
                            style="margin-right: 15px;"
                            class="btn btn-mint btn-sm float-left margin-right-10">
                            <font-awesome-icon icon="fas fa-arrow-left"/>
                            {{ redirectToPageText(item) }}
                        </base-button></span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>

import router from "@/router";

export default {
    components: {},
    props: ['items', 'hasEdit', 'appointmentList'],
    data() {
        return {}
    },
    computed: {},
    methods: {
        redirectToPageText(item) {
            return this.trans("HELLO")
        },
        redirectToPage(item) {
            router.push("/Appointment/" + item.PkAppointment);
        },
    }
};
</script>
<style scoped lang="scss">

.list-margin {
    padding-right: 10px;
    padding-left: 10px;
}

.icon-color {
    margin-right: 15px;
    margin-left: 10px;
    min-width: 30px;
    min-height: 30px;
}

.dark {
    .icon-color {
        color: #ffffff;
    }


    .list-text {
        color: #ffffff;
    }
}

.list-height {
    min-height: 60px;
    border-bottom: 1px solid #ffffff;
}

</style>

