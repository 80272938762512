<template>
    <div class="container-jobineo">
        <div class="section-heading">
            <span class="no-decoration breadcrumb-decorate-blue">
                <font-awesome-icon icon="fas fa-cog"/>
                {{ trans('MAIN-DATA') }}
            </span>
            <span class="no-decoration breadcrumb-decorate-yellow">
                <font-awesome-icon icon="fas fa-angle-right"/> &nbsp;
                <font-awesome-icon icon="fas fa-building"/>
                {{ trans('COMPANY') }}
            </span>
        </div>
        <div class="row layout-top-spacing">
            <div id="basic" class="col-lg-12 col-sm-12 col-12 layout-spacing">
                <div class="statbox panel box box-shadow">
                    <div class="panel-heading">
                        <div class="row">
                            <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                                <h4>{{ trans('COMPANY') }}</h4>
                            </div>
                        </div>
                    </div>
                    <div class="panel-body">
                        <Form ref="valForm" :validationSchema="validationSchema" @submit="validate">
                            <div class="row">
                                <div class="col-lg-12 col-md-4">
                                    <div class="upload mt-4 pe-md-4">
                                        <input ref="fl_profile" type="file" class="d-none"
                                               accept="image/*" @change="change_file"/>
                                        <img
                                            :src="selectedImage != null ? selectedImage :  require('@/assets/images/empty-profile.jpg')"
                                            alt="profile" class="profile-preview-office profile-picture-office"
                                            @click="$refs.fl_profile.click()"/>
                                        <p><a href="javascript:;" class="mt-2"
                                              @click="$refs.fl_profile.click()">{{
                                                trans('UPLOAD-PICTURE-COMPANY')
                                            }}</a></p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-lg-5">
                                    <base-input ref="CompanyName"
                                                v-model="model.CompanyName"
                                                :label="trans('COMPANY-NAME')"
                                                type="text"
                                                :required="true"
                                                obj-name="CompanyName"
                                                :max-length="150"
                                                attached-class="form-control" placeholder="..."/>
                                </div>
                                <div class="form-group col-lg-3">
                                    <member-dropdown ref="MemberCount"
                                                     v-model="model.MemberCount"
                                                     :label="trans('MEMBER-COUNT')"
                                                     :items="MemberCountList"
                                                     :no-check-icons="true"
                                                     :null-value-text="trans('SELECT-MEMBER-COUNT')"
                                                     :has-null-value="true"
                                                     attached-class="form-control"
                                                     name="MemberCount"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-lg-5">
                                    <base-input ref="Street"
                                                v-model="model.Street"
                                                :label="trans('STREET')"
                                                type="text"
                                                :max-length="50"
                                                attached-class="form-control"
                                                obj-name="Street" :required="true" placeholder="..."/>
                                </div>
                                <div class="form-group col-lg-2">
                                    <base-input ref="StreetNr"
                                                v-model="model.StreetNr"
                                                type="text"
                                                :max-length="10"
                                                :required="true"
                                                :label="trans('STREET-NR')"
                                                attached-class="form-control"
                                                obj-name="StreetNr" placeholder="..."/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-lg-5">
                                    <base-input ref="Addition"
                                                v-model="model.AddressAddition"
                                                :label="trans('ADDITION')"
                                                type="text"
                                                :max-length="100"
                                                attached-class="form-control"
                                                obj-name="Addition" placeholder="..."/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-lg-2">
                                    <base-input ref="PostCode"
                                                v-model="model.PostCode"
                                                :label="trans('POSTCODE')"
                                                type="text"
                                                :max-length="10"
                                                attached-class="form-control"
                                                obj-name="PostCode" :required="true" placeholder="..."/>
                                </div>
                                <div class="form-group col-lg-5">
                                    <base-input ref="City"
                                                v-model="model.City"
                                                type="text"
                                                :max-length="50"
                                                :required="true"
                                                :label="trans('CITY')"
                                                attached-class="form-control"
                                                obj-name="City" placeholder="..."/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-lg-3">
                                    <data-dropdown ref="Country"
                                                   v-model="model.Country"
                                                   :label="trans('COUNTRY')"
                                                   :required="true"
                                                   :no-check-icons="true"
                                                   :items="CountriesList"
                                                   :null-value-text="trans('SELECT-COUNTRY')"
                                                   :has-null-value="true"
                                                   attached-class="form-control"
                                                   name="Country"/>
                                </div>
                            </div>
                            <div class="clearfix underlined-b-m-20"></div>
                            <div v-if="IsCompany">
                                <div class="row">
                                    <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                                        <h4>{{ trans('COMPANY-INVOICE-CONTACT') }}</h4>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-lg-3">
                                        <data-dropdown ref="PaymentInterval"
                                                       v-model="model.PaymentInterval"
                                                       :required="true"
                                                       :items="PaymentIntervalList"
                                                       :has-null-value="true"
                                                       :null-value-text="trans('SELECT-PAYMENT-INTERVAL')"
                                                       :label="trans('PAYMENT-INTERVAL')"
                                                       attached-class="form-control"
                                                       name="PaymentInterval"/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-lg-3">
                                        <salutations-drop-down ref="Salutation"
                                                               v-model="model.CompanyInvoiceContact.Salutation"
                                                               :required="true"
                                                               :has-null-value="true"
                                                               :null-value-text="trans('SELECT-SALUTATION')"
                                                               :label="trans('SALUTATION')"
                                                               attached-class="form-control"
                                                               name="Salutation"/>
                                    </div>
                                    <div class="form-group col-lg-3">
                                        <base-input
                                            v-model="model.CompanyInvoiceContact.NameAddition"
                                            type="text"
                                            obj-name="NameAddition"
                                            :label="trans('NAME-ADDITION')"
                                            attached-class="form-control"
                                            :placeholder="trans('NAME-ADDITION')"
                                            name="NameAddition"
                                            ref="NameAddition"/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-lg-3">
                                        <base-input ref="FirstName"
                                                    v-model="model.CompanyInvoiceContact.FirstName"
                                                    :label="trans('FIRSTNAME')"
                                                    type="text"
                                                    :max-length="50"
                                                    attached-class="form-control"
                                                    obj-name="FirstName" :required="true" placeholder="..."/>
                                    </div>
                                    <div class="form-group col-lg-3">
                                        <base-input ref="LastName"
                                                    v-model="model.CompanyInvoiceContact.LastName"
                                                    type="text"
                                                    :max-length="10"
                                                    :required="true"
                                                    :label="trans('LASTNAME')"
                                                    attached-class="form-control"
                                                    obj-name="LastName" placeholder="..."/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-lg-4">
                                        <base-input ref="Email"
                                                    v-model="model.CompanyInvoiceContact.Email"
                                                    :label="trans('EMAIL')"
                                                    type="text"
                                                    :max-length="150"
                                                    attached-class="form-control"
                                                    obj-name="Email" :required="true" placeholder="..."/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-lg-3">
                                        <base-input ref="Phone"
                                                    v-model="model.CompanyInvoiceContact.Phone"
                                                    :label="trans('PHONE')"
                                                    type="text"
                                                    :max-length="150"
                                                    attached-class="form-control"
                                                    obj-name="Phone" placeholder="..."/>
                                    </div>
                                    <div class="form-group col-lg-3">
                                        <base-input ref="Mobile"
                                                    v-model="model.CompanyInvoiceContact.Mobile"
                                                    type="text"
                                                    :max-length="150"

                                                    :label="trans('MOBILE')"
                                                    attached-class="form-control"
                                                    obj-name="Mobile" placeholder="..."/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-lg-5">
                                        <base-input ref="CStreet"
                                                    v-model="model.CompanyInvoiceContact.Street"
                                                    :label="trans('STREET')"
                                                    type="text"
                                                    :max-length="50"
                                                    attached-class="form-control"
                                                    obj-name="CStreet" :required="true" placeholder="..."/>
                                    </div>
                                    <div class="form-group col-lg-2">
                                        <base-input ref="CStreetNr"
                                                    v-model="model.CompanyInvoiceContact.StreetNr"
                                                    type="text"
                                                    :max-length="10"
                                                    :required="true"
                                                    :label="trans('STREET-NR')"
                                                    attached-class="form-control"
                                                    obj-name="CStreetNr" placeholder="..."/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-lg-5">
                                        <base-input ref="CAddition"
                                                    v-model="model.CompanyInvoiceContact.AddressAddition"
                                                    :label="trans('ADDITION')"
                                                    type="text"
                                                    :max-length="100"
                                                    attached-class="form-control"
                                                    obj-name="CAddition" placeholder="..."/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-lg-2">
                                        <base-input ref="CPostCode"
                                                    v-model="model.CompanyInvoiceContact.PostCode"
                                                    :label="trans('POSTCODE')"
                                                    type="text"
                                                    :max-length="10"
                                                    attached-class="form-control"
                                                    obj-name="CPostCode" :required="true" placeholder="..."/>
                                    </div>
                                    <div class="form-group col-lg-5">
                                        <base-input ref="CCity"
                                                    v-model="model.CompanyInvoiceContact.City"
                                                    type="text"
                                                    :max-length="50"
                                                    :required="true"
                                                    :label="trans('CITY')"
                                                    attached-class="form-control"
                                                    obj-name="CCity" placeholder="..."/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-lg-3">
                                        <data-dropdown ref="CCountry"
                                                       v-model="model.CompanyInvoiceContact.Country"
                                                       :label="trans('COUNTRY')"
                                                       :required="true"
                                                       :no-check-icons="true"
                                                       :items="CountriesList"
                                                       :null-value-text="trans('SELECT-COUNTRY')"
                                                       :has-null-value="true"
                                                       attached-class="form-control"
                                                       name="CCountry"/>
                                    </div>
                                </div>

                                <div v-if="error" class="error-alert-wrap">
                                    <font-awesome-icon icon="fas fa-exclamation-triangle" class="float-right-margin"/>
                                    {{ trans(errorMessage) }}
                                </div>
                            </div>

                            <button ref="silentSubmit" type="submit" class="btn-silent-submit"/>
                        </Form>
                        <div class="row margin-top-30">
                            <div class="form-group col-lg-12">
                                <base-button
                                    native-type="button"
                                    class="btn btn-red float-left"
                                    @click="cancel"
                                >
                                    <font-awesome-icon icon="fas fa-times"/>
                                    {{ $t('CANCEL') }}
                                </base-button>


                                <base-button
                                    native-type="button"
                                    class="btn btn-mint float-right"
                                    @click="validate"

                                >
                                    <font-awesome-icon v-if="processing"
                                                       icon="fas fa-spin fa-circle-notch"/>
                                    <font-awesome-icon v-if="!processing"
                                                       icon="fas fa-check"/>
                                    {{ $t('SAVE') }}
                                </base-button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * View: MasterDataCompany
 * Path: /Config/MasterData/MasterDataCompany
 * A sub view for the Config > MaterData area.
 * For managing own companies.
 *
 */

import {mutate} from "@/utils/mutate.js";
import DataDropdown from "@/components/dropdowns/DataDropdown.vue";
import MemberDropdown from "@/components/dropdowns/MemberDropdown.vue";
import * as yup from 'yup';
import {Form} from 'vee-validate';
import {CompanyController} from "@/controllers/company.controller";
import router from "@/router";
import SalutationsDropDown from "@/components/dropdowns/SalutationsDropdown.vue";
import {PAYMENT_INTERVAL_DROPDOWN} from "@/data/paymentInterval";
import store from "@/store";
import {REGISTER_TYPES} from "@/data/RegisterTypes";

export default {
    name: "MasterDataCompany",
    components: {SalutationsDropDown, MemberDropdown, DataDropdown, Form},
    data() {
        return {
            controller: new CompanyController(this),
            validationSchema: yup.object({
                Street: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                StreetNr: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                PostCode: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                City: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                CompanyName: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                FirstName: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                LastName: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                CStreet: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                CStreetNr: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                CPostCode: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                CCity: yup.string().required(this.trans("REQUIRED-MESSAGE")),
                Email: yup.string().required(this.trans("REQUIRED-MESSAGE")),
            }),
            selectedImage: null,
            model: {
                CompanyName: '',
                Street: '',
                StreetNr: '',
                PostCode: '',
                City: '',
                Country: null,
                AddressAddition: '',
                MemberCount: null,
                CompanyInvoiceContact: {
                    Salutation: null,
                    NameAddition: null,
                    FirstName: null,
                    LastName: null,
                    Email: null,
                    Phone: null,
                    Mobile: null,
                    Street: null,
                    StreetNr: null,
                    PostCode: null,
                    City: null,
                    Country: null,
                    AddressAddition: null,
                }
            },
            processing: false,
            error: false,
            errorMessage: '',
        }
    },
    computed: {
        PaymentIntervalList() {
            return this.$store.state.Lookups.PaymentIntervalsLookup != null && Array.isArray(this.$store.state.Lookups.PaymentIntervalsLookup) ? this.$store.state.Lookups.PaymentIntervalsLookup : [];
        },
        CountriesList() {
            return this.$store.state.Lookups.CountriesLookup != null && Array.isArray(this.$store.state.Lookups.CountriesLookup) ? this.$store.state.Lookups.CountriesLookup : [];
        },
        MemberCountList() {
            return this.$store.state.Lookups.CompanySizesLookup != null && Array.isArray(this.$store.state.Lookups.CompanySizesLookup) ? this.$store.state.Lookups.CompanySizesLookup : [];
        },
        IsCompany() {
            return store.state.RegisterType == REGISTER_TYPES.COMPANY;
        }
    },
    methods: {
        cancel() {
            router.push("/");
        },
        change_file() {

            let file = event.target.files[0];
            this.error = false;
            this.errorMessage = null
            let MAX_FILE_SIZE = 8 * 1024 * 1024;
            let data = this.model;
            let controller = this.controller;


            if (!file) {
                event.preventDefault();
                event.target.value = null;
                return;
            }

            if (file.size > MAX_FILE_SIZE) {
                event.preventDefault();
                event.target.value = null;
                this.error = true;
                this.errorMessage = "FILE-TO-BIG"
                return;
            }
            let fileReader = new FileReader();

            fileReader.onloadend = function (e) {
                let arr = (new Uint8Array(e.target.result)).subarray(0, 4);

                let header = "";
                for (let i = 0; i < arr.length; i++) {
                    header += arr[i].toString(16);
                }

                let type = "";
                switch (header) {
                    case "89504e47":
                        type = "image/png";
                        break;
                    case "47494638":
                        type = "image/gif";
                        break;
                    case "ffd8ffe0":
                    case "ffd8ffe1":
                    case "ffd8ffe2":
                    case "ffd8ffe3":
                    case "ffd8ffe8":
                        type = "image/jpeg";
                        break;
                    case "255044462D":
                    case "25504446":
                        type = "pdf";
                        break;
                    default:
                        type = "unknown"; // Or you can use the blob.type as fallback
                        break;
                }

                if (type !== "unknown") {
                    data.FileName = file.name;
                    data.ProfilePicture = file;
                    store.dispatch('uploadProfilePictureCompany', {
                        ProfilePicture: data.ProfilePicture,
                        PkCompany: data.PkCompany
                    }).then(res => {
                        controller.GetProfilePictureCompany(data.PkCompany).then(result => {
                            if (result.data.size > 0)
                                this.selectedImage = URL.createObjectURL(new Blob([result.data]));
                            else
                                this.selectedImage = null;
                        })
                    });

                } else {
                    event.preventDefault();
                    event.target.value = null;

                }
            }.bind(this)

            fileReader.readAsArrayBuffer(file);

        },
        validate() {
            this.processing = true;
            this.error = false;
            this.errorMessage = '';


            return this.$refs.valForm.validate().then(result => {
                let otherErrors = false;
                if (this.model.CompanyInvoiceContact.Country == null || this.model.CompanyInvoiceContact.Country <= 0) {
                    this.$refs.CCountry.setError();
                    otherErrors = true;
                }

                if (this.model.CompanyInvoiceContact.Salutation == null || this.model.CompanyInvoiceContact.Salutation <= 0) {
                    this.$refs.Salutation.setError();
                    otherErrors = true;
                }


                if (result.valid && !otherErrors) {
                    this.controller.update(this.model).then(response => {

                        if (response.IsError) {
                            this.error = true;
                            this.errorMessage = response.ErrorMessage;
                            this.$notify(
                                {
                                    title: this.trans('ERROR'),
                                    text: this.trans(response.ErrorMessage),
                                    type: 'error',
                                });
                        } else {
                            this.model = response.Company;
                            this.$notify(
                                {
                                    title: this.trans('SUCCESS'),
                                    text: this.trans('DATA-SAVED'),
                                    type: 'success'
                                });
                        }

                    })
                } else {

                    for (const key in result.errors) {
                        this.$refs[key].setError();
                    }

                    return Promise.resolve(false);
                }
            }).finally(() => {
                this.processing = false;
            })


        },
        load() {
            this.controller.read().then(result => {
                this.model = result.Company;
                if (this.model.CompanyInvoiceContact.Salutation == 0)
                    this.model.CompanyInvoiceContact.Salutation = null;

                if (this.model.CompanyInvoiceContact.Country == 0)
                    this.model.CompanyInvoiceContact.Country = null;

                this.controller.GetProfilePictureCompany(this.model.PkCompany).then(result => {

                    if (result.data.size > 0)
                        this.selectedImage = URL.createObjectURL(new Blob([result.data]));
                    else
                        this.selectedImage = null;

                })
            })

        },
    },
    activated() {
        this.load();
    }


}
</script>

<style lang="scss" scoped>


</style>
