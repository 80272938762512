/**
 * router.js is the initialization module of the Vue router.
 * For BWP application-specific routing please read:
 *
 */

import * as VueRouter from 'vue-router'
import store from './store.js'
import {getConfig} from '@/jobineo'
import defaultRoutes from './routes/default.routes.js'
import {mainMenuRoutes} from './routes/mainMenuRoutes.js'
import accountRoutes from './routes/account.routes.js'
import {routesLoggedIn} from './routes/account.routes.js'
import configRoutes from './routes/config.routes.js'
import {flattenRoutePathTree} from "./utils/flattenRoutePathTree";
import {useCookies} from "vue3-cookies";
import {CHECK_REGISTER_TYPES, REGISTER_TYPES} from "@/data/RegisterTypes";

/**
 * Array of routes (route-paths) which are allowed for non-registred users
 * @type  Array
 */
const allowedNonUserRoutes = ['/api',
    '/LandingPageStudent',
    '/LandingPageCompany',
    '/Register',
    '/RegisterWizard',
    '/RegisterStudent',
    '/VerifyUser',
    '/VerifyParentEmail',
    '/AGBReaccept',
    '/RoadMap',
    '/EnterNewPassword',
    '/PasswordForgotten',
    '/Pricing',
    '/Login',
    '/MFACheck',
    '/Imprint',
    '/AGB',
    '/DataProtection',
    '/RegisterSuccess'];

const router = new VueRouter.createRouter({
    history: VueRouter.createWebHashHistory(),
    routes: [].concat(
        defaultRoutes,
        accountRoutes,
        routesLoggedIn,
        configRoutes),
    linkExactActiveClass: 'active',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {left: 0, top: 0};
        }
    },
});


const
    // Path to the login view
    LOGIN_PATH = '/LandingPageStudent',
    CHECK_MFA_PATH = '/MFACheck',
    AGB_REACCEPT_PATH = '/AGBReaccept',
    // Path to the default view (dashboard)
    ROOT_PATH = '/',
    {cookies} = useCookies();


/**
 * This is the before guard.
 * A kind of firewall or programmatic route computing before we perform the normal routing
 */
router.beforeEach((to, from, next) => {

    if (to.meta && to.meta.layout && to.meta.layout == 'auth') {
        store.commit('setLayout', 'auth');
    } else {
        store.commit('setLayout', 'app');
    }

    let flatten = flattenRoutePathTree(router.options.routes),
        temp = flatten.slice().reverse();

    temp.pop();
    let flattenTree = temp.reverse().map(item => {
        item.level = item.level != null ? item.level - 1 : null;
        return item;
    });
    /**
     * There are two dummy routes: Mainsite/:path and Demosite/:path
     * These are not directing to a view.
     * They act as forwarding to the main site or to the demo site,
     * whch represent two different web servers.
     * What we want to do here is changing the domain or domain with port
     * so that we forward to a different web site.
     *
     */

    if (to.name === 'Mainsite') {
        window.location = (to.name === 'Mainsite' ? getConfig('prodUrl') : '');
        return next(false);
    }

    /**
     * When being on the register success site the user shall not do browser back to Register view
     */
    if ((from.name === 'RegisterSuccess' || from.name === 'RegisterSuccessWithoutProps') && to.name === "Register") {
        window.location = getConfig('baseUrl') + '#/RegisterSuccess';
        return next(false);
    }

    const
        /**
         * we are getting the to path and extracting the name of the first path item
         * then compiling it again to a path without the rest of the path.
         * e.g. /Login       => /Login
         *      /Login/Hello => /Login
         * @type {string}
         */
        computedToPath = "/" + to.path.split('/')[1],
        /**
         * A flag that indicates if the to path is
         * directing to the login view.
         * @type {boolean}
         */
        toPathIsLogin = to.path === LOGIN_PATH,
        /** RegisterCode not set
         *
         * @type {boolean}
         */
        isAGBAndDSGVOChecked = !store.state.HasToCheckAGB,
        needToValidateMFA = store.state.NeedToValidateMFA,

        /**
         * Flag that indicates if the user is not registered.
         * Means user is a guest.
         * @type {boolean}
         */

        noRegisteredUser = store.state.User == null || !store.state.loggedIn,


        /**
         * This flag indicates if the user is a guest (not logged in user)
         * and if this not logged in user wants to visit a view which is just available for registered users.
         * @type {boolean}
         */
        noneRegisteredUserInClosedArea = noRegisteredUser && allowedNonUserRoutes.indexOf(computedToPath) === -1;

    /**
     * User tries to enter registercode let him go, if registercode is already set switch to login
     */

    if (needToValidateMFA == true && to.path === CHECK_MFA_PATH) {
        next();
    } else if (to.path === CHECK_MFA_PATH) {
        next(LOGIN_PATH);
    }

    if (!isAGBAndDSGVOChecked && to.path === AGB_REACCEPT_PATH) {
        next();
    } else if (to.path === AGB_REACCEPT_PATH) {
        next(LOGIN_PATH);
    }

    /**
     * If there is a route without a from name ,
     * it means that this is the initial route when opening
     * the site by url.
     * But this url might include a route to special path.
     * If the call wants to route to a special path on start we are storing
     * this path in the store's state.
     */
    if (!from.name && !toPathIsLogin) {
        store.commit('setEntryPath', to.path);
    }

    /**
     * If the user is not logged in and wants to visit a view for registered users
     */
    if (noneRegisteredUserInClosedArea) {

        const
            /**
             * Is remember me activated. We are searching for the appropriate cookie.
             * @type {boolean}
             */
            remeberMe = (cookies.isKey('rememberMe') && cookies.get('rememberMe') == 'true') || (localStorage.getItem('rememberMe') == 'true'),

            /** If the user sets F5 he will remove the cache and the ability to login without autologin **/
            isLoggedIn = (cookies.isKey('loggedIn') && cookies.get('loggedIn') == 'true') || (localStorage.getItem('loggedIn') == 'true'),

            /**
             * Did the user log off before ?
             * If user loggs off this flag in the state is set.
             * we are using a new constant because we will set it to false when true afterwards.
             * this is the state to remember
             * @type {boolean}
             */
            didLogOff = store.state.didLogOff;

        /**
         * If the log off then we are setting this state flag to false again.
         */
        if (store.state.didLogOff) {
            store.commit('didLogOff', false);
        }

        /**
         * we have an activated remember me and the user did not log off before.
         * Means we are trying to auto login first and then forwarding to
         * the path the user wants to go.
         */
        if ((isLoggedIn || remeberMe) && !didLogOff) {
            store.dispatch('autoLogin')
                // Catching on error will always re-direct to the login
                .catch(() => {
                })
                .then(result => {
                    // The auto login response says that there are errors. re-direct to the login view.
                    if (result == null || result.IsError) {
                        next(LOGIN_PATH);
                    } else {

                        // Auto login success.
                        // the to path is the login path , so as we know the user now
                        // we are redirecting to the root path
                        if (toPathIsLogin)
                            next(ROOT_PATH)
                        else {

                            let foundItem = flattenTree.find(r => r.path == to.path);
                            if (foundItem) {

                                if (foundItem.CheckIfProd === true && process.env.NODE_ENV !== 'development')
                                    next(ROOT_PATH);

                                if (foundItem.CheckIfUserType != null && foundItem.CheckIfUserType !== CHECK_REGISTER_TYPES.ALL) {
                                    if (foundItem.CheckIfUserType === CHECK_REGISTER_TYPES.COMPANY_OR_SCHOOL && store.state.RegisterType !== REGISTER_TYPES.STUDENT)
                                        next();
                                    else if (foundItem.CheckIfUserType === CHECK_REGISTER_TYPES.COMPANY_OR_STUDENT && store.state.RegisterType !== REGISTER_TYPES.SCHOOL)
                                        next();
                                    else if (foundItem.CheckIfUserType === CHECK_REGISTER_TYPES.COMPANY && store.state.RegisterType === REGISTER_TYPES.COMPANY)
                                        next();
                                    else if (foundItem.CheckIfUserType === CHECK_REGISTER_TYPES.SCHOOL && store.state.RegisterType === REGISTER_TYPES.SCHOOL)
                                        next();
                                    else if (foundItem.CheckIfUserType === CHECK_REGISTER_TYPES.STUDENT && store.state.RegisterType === REGISTER_TYPES.STUDENT)
                                        next();
                                    else
                                        next(ROOT_PATH);
                                } else
                                    next();
                            } else {
                                // otherwise we are going to the desired path
                                next();
                            }
                        }
                    }
                });

        } else
            // there was no remember me or the user did log off before.
            // we are redirecting to the login view.
            next(LOGIN_PATH);

    }
        // the user is registered or not registerd but wants to visit a view
    // which is available for non-registered users.
    else {

        /**
         * If the user is logged in and wants to visit the login view
         * we are re-direct the user to the root path because there is no
         * need for login view if registered.
         */
        if (!noRegisteredUser && toPathIsLogin) {
            next(ROOT_PATH)
        } else {


            let foundItem = flattenTree.find(r => r.path == to.path);

            if (foundItem) {

                if (foundItem.CheckIfProd === true && process.env.NODE_ENV !== 'development')
                    next(ROOT_PATH);
                if (foundItem.CheckIfUserType != null && foundItem.CheckIfUserType !== CHECK_REGISTER_TYPES.ALL) {
                    if (foundItem.CheckIfUserType === CHECK_REGISTER_TYPES.COMPANY_OR_SCHOOL && store.state.RegisterType !== REGISTER_TYPES.STUDENT)
                        next();
                    else if (foundItem.CheckIfUserType === CHECK_REGISTER_TYPES.COMPANY_OR_STUDENT && store.state.RegisterType !== REGISTER_TYPES.SCHOOL)
                        next();
                    else if (foundItem.CheckIfUserType === CHECK_REGISTER_TYPES.COMPANY && store.state.RegisterType === REGISTER_TYPES.COMPANY)
                        next();
                    else if (foundItem.CheckIfUserType === CHECK_REGISTER_TYPES.SCHOOL && store.state.RegisterType === REGISTER_TYPES.SCHOOL)
                        next();
                    else if (foundItem.CheckIfUserType === CHECK_REGISTER_TYPES.STUDENT && store.state.RegisterType === REGISTER_TYPES.STUDENT)
                        next();
                    else
                        next(ROOT_PATH);
                } else
                    next();
            } else {
                // the user is logged in or not logged in wants to visit a public view
                // we are just allowing to go next.
                next();
            }
        }
    }
})

export default router
