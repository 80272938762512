export const REGISTER_TYPES = {
    STUDENT: 1,
    COMPANY: 2,
    SCHOOL: 3
}


export const CHECK_REGISTER_TYPES = {
    ALL: 0,
    STUDENT: 1,
    COMPANY: 2,
    SCHOOL: 3,
    COMPANY_OR_SCHOOL: 4,
    COMPANY_OR_STUDENT: 5
}
