/**
 * Mutations may be arrow function because they are pure functions
 */

/**
 * This sub-module includes all mutations for:
 * manipulating notification
 */


export const setNotifications = (state, data) => {

  let notificationList = data.Notifications != null && Array.isArray(data.Notifications) ? data.Notifications : [];
  state.UserNotifications = [];

  notificationList.forEach(notification => {
    state.UserNotifications.push(notification);
  });

}


export const setNotificationsAll = (state, data) => {

  let notificationList = data.Notifications != null && Array.isArray(data.Notifications) ? data.Notifications : [];
  state.UserNotificationsAll = [];

  notificationList.forEach(notification => {
    state.UserNotificationsAll.push(notification);
  });

}

export const removeNotification = (state, data) => {
  state.UserNotifications.splice(state.UserNotifications.indexOf(data), 1);
  return Promise.resolve(true);
}


