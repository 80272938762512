import Controller from "../controller.js";
import {restUri} from "@/data/restPaths";
import {mutate} from "@/utils/mutate";
import {CREATE, DELETE, UPDATE} from "@/data/cud";
import axios from "axios";
import {restBody} from "@/utils/restBody";
import store from "@/store";


export class ProfileController extends Controller {

    constructor(root) {
        super({
            root,
            path: {
                CUD: restUri.STUDENT_PROFILE_CUD,
                GET: restUri.GET_STUDENT_PROFILE
            }
        })
    }

    init() {
    }

    read() {
        const PkStudentProfile = this.store.state.PkStudentProfile;
        return super.read({
            log: false,
            body: {PkStudentProfile: PkStudentProfile},
            commit: 'setStudentProfile'
        }).then(data => {
            if (!data.IsError) {
                return data;
            } else return data
        });
    }

    readPublicView(fkUser, noUpdate = false) {

        return super.read({
            log: false,
            body: {PkStudentProfile: fkUser}
        }).then(data => {
            if (!data.IsError) {
                if (!noUpdate) {
                    axios.post(restUri.STUDENT_PROFILE_UPDATE, restBody({
                        PkStudentProfile: fkUser,
                        SetProfileWatch: true
                    }));
                }
                return data;
            } else return data
        });
    }

    update(StudentProfile = {}) {

        let body = {
            StudentProfile: mutate(
                {TrackingState: UPDATE},
                StudentProfile,
            )
        };

        return axios.post(restUri.STUDENT_PROFILE_CUD, restBody(body))
            .then(result => {
                let data = result.data;
                if (!data.IsError) {
                }

                return data;
            }).catch(result => {
                return result.data;
            })
    }

    SetProfileFavourite(fkUser, IsFavourite = null, IsLiked = null) {
        return axios.post(restUri.SET_STUDENT_FAVOURITE, restBody({
                FkStudentProfile: fkUser,
                IsFavourite: IsFavourite,
                IsLike: IsLiked
            })
        ).then(result => {
            return result;
        }).catch(result => {
            return result;
        });

    }


    GetProfilePicture(fkUser) {
        return axios.post(restUri.DOWNLOAD_PROFILE_PICTURE, restBody({
                FkUser: fkUser
            }),
            {
                responseType: 'blob'
            }
        ).then(result => {
            store.state.ProfilePictureContent = result.data;
            return result;
        }).catch(result => {
            return result;
        });

    }

    GetProfilePicturePublic(fkUser, fkStudentProfile) {
        return axios.post(restUri.DOWNLOAD_PROFILE_PICTURE, restBody({
                FkUser: fkUser,
                FkStudentProfile: fkStudentProfile
            }),
            {
                responseType: 'blob'
            }
        ).then(result => {
            return result;
        }).catch(result => {
            return result;
        });

    }

    GetVideoFile(fkUser, videoNo) {
        return axios.post(restUri.DOWNLOAD_VIDEO_FILE, restBody({
                PkUser: fkUser,
                VideoNo: videoNo
            }),
            {
                responseType: 'blob'
            }
        ).then(result => {
            return result.data;
        }).catch(result => {
            return result;
        });

    }

}

