<template>
    <div class="">
        <div class="section-heading">
            <router-link to="Profile" class="no-decoration gray-color">
                <font-awesome-icon icon="fas fa-user"/>
                {{ $t('PROFILE') }}
            </router-link>
            <span class="no-decoration breadcrumb-decorate-yellow">
                <font-awesome-icon icon="fas fa-angle-right"/> &nbsp;
                <font-awesome-icon icon="fas fa-user-edit"/>
                {{ trans('EDIT-ACCOUNT') }}
            </span>
        </div>
        <div class="edit-account-wrap">
            <b-container fluid>
                <b-row>
                    <b-col>
                        <UserForm ref="UserForm" @update="save"></UserForm>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <base-button
                            native-type="button"
                            class="btn btn-red float-left"
                            @click="cancel"
                        >
                            <font-awesome-icon icon="fas fa-times"/>
                            {{ $t('CANCEL') }}
                        </base-button>


                        <base-button
                            native-type="button"
                            class="btn btn-mint float-right"
                            @click="save()"

                        >
                            <font-awesome-icon v-if="processing"
                                               icon="fas fa-spin fa-circle-notch"/>
                            <font-awesome-icon v-if="!processing"
                                               icon="fas fa-check"/>
                            {{ $t('SAVE') }}
                        </base-button>
                    </b-col>

                </b-row>
            </b-container>

        </div>


    </div>
</template>

<script>
/**
 * View: EditAccount
 * Path: /UserAccount/EditAccount
 * A sub view for the user account area.
 * Helps editing a user's credentials by the user.
 *
 */
import UserForm from "../users/UserForm.vue";
import {mutate} from "@/utils/mutate";
import router from "@/router";


export default {
    name: "EditAccount",
    components: {UserForm},
    data() {
        return {
            processing: false
        }
    },
    methods: {
        changePassword() {
            const UserForm = this.$refs.UserForm,
                body = UserForm.getChangePassword();


            if (body)
                return this.$store.dispatch('changePassword', body).then(data => {
                    this.processing = false;
                    if (data.IsError) {
                        UserForm.setError(data.ErrorMessage);
                        this.$notify(
                            {
                                title: this.trans('ERROR'),
                                text: this.trans(data.ErrorMessage),
                                type: 'error',
                            });
                    } else {
                        UserForm.showPasswordHasChanged();
                        setTimeout(() => {
                            UserForm.resetPasswordChange();
                            router.push('/UserAccount/Profile');
                        }, 1000);
                        this.$notify(
                            {
                                title: this.trans('SUCCESS'),
                                text: this.trans('DATA-SAVED'),
                                type: 'success'
                            });

                        this.$refs.UserForm.setError();
                    }
                    return data;
                }).catch(error => {
                    this.error = true;
                    this.errorMessage = this.trans(error.data.ErrorMessage);
                });
            else {
                this.processing = false;
                router.push('/UserAccount/Profile');
            }

        },

        cancel() {
            router.push('/UserAccount/Profile')
        },

        save() {


            this.processing = true;
            let UserForm = this.$refs.UserForm,
                pwBody = {
                    Password: UserForm.model.Password,
                    PasswordRepeat: UserForm.model.PasswordRepeat
                };

            if (UserForm.hasChanged())
                return UserForm.validate()
                    .then(
                        doSave => {
                            if (!doSave)
                                return Promise.resolve(false);
                            else {
                                return this.$store.dispatch('updateUser', UserForm.getData());
                            }

                        }
                    )
                    .then(data => {
                        if (data && !data.IsError) {

                            UserForm.setData(mutate(data.User, pwBody));

                            this.$notify(
                                {
                                    title: this.trans('SUCCESS'),
                                    text: this.trans('DATA-SAVED'),
                                    type: 'success'
                                });
                            this.$refs.UserForm.setError();
                            return this.changePassword();
                        } else if (data && data.IsError) {
                            this.$notify(
                                {
                                    title: this.trans('ERROR'),
                                    text: this.trans(data.ErrorMessage),
                                    type: 'error',
                                });

                            UserForm.setError(data.ErrorMessage);
                        }
                        this.processing = false;

                        return data;
                    }).catch(error => {
                        this.error = true;
                        this.errorMessage = this.trans(error.ErrorMessage);
                        this.$notify(
                            {
                                title: this.trans('ERROR'),
                                text: this.trans(error.ErrorMessage),
                                type: 'error',
                            });
                    });
            else
                return this.changePassword();


        },
    },
    activated() {
        this.$refs.UserForm.setData(this.$store.state.User);
    },
    deactivated() {
        this.$refs.UserForm.setData();
    },
    mounted() {
        this.$refs.UserForm.setData(this.$store.state.User);
    }
}
</script>

<style lang="scss" scoped>
.no-scroll {
    overflow: hidden;
}


</style>
