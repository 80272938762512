import Controller from "../controller.js";
import {restUri} from "@/data/restPaths";
import {mutate} from "@/utils/mutate";
import {CREATE, DELETE, UPDATE} from "@/data/cud";


export class AppointmentController extends Controller {

    constructor(root) {
        super({
            root,
            path: {
                CUD: restUri.APPOINTMENT_CUD,
                GET: restUri.GET_APPOINTMENTS
            }
        })
    }

    init() {
    }

    read(data = {}) {

        let FkUser = this.store.state.User != null ? this.store.state.User.PkUser : null;


        return super.read({
            log: false,
            body: mutate({FkUser}),
            commit: 'setAppointments'
        }).then(data => {
            return data;
        }).catch(result => {
            return result;
        });
    }


    update(Appointment = {}) {

        let body = {
            Appointment: mutate(
                {TrackingState: UPDATE},
                Appointment,
            )
        };


        return super.update({
            log: false,
            body,
        }).then(result => {
            if (!result.IsError) {
                this.store.commit('setDashboardConfigsUpdate', result);
                return result;
            } else return result
        }).catch(result => {
            return result;
        });
    }

    create(Appointment) {

        const
            body = {
                Appointment: mutate(
                    {TrackingState: CREATE},
                    Appointment
                )
            };


        return super.create({
            log: false,
            body,
        }).then(result => {
            if (!result.IsError) {
                this.store.commit('setAppointmentsResource', result);
                return result;
            } else return result
        });

    }

    delete(Appointment = {}) {


        const
            body = {
                Appointment: mutate(
                    {TrackingState: DELETE},
                    Appointment
                )
            };

        return super.delete({
            log: false,
            body: body
        }).then(data => {
            if (!data.IsError) {
                this.store.commit('deleteAppointmentResource', Appointment);
                return data;
            } else {
                return data;
            }

        });

    }

}

