<template>
    <template v-if="CheckVisible(item.CheckIfUserType)">
        <li :class="[{'menu single-menu': level===0}]">
            <template v-if="item.children">
                <a href="javascript:;" class="dropdown-toggle autodroprown">
                    <div class="">
                        <font-awesome-icon :icon="item.icon"></font-awesome-icon>
                        <span>{{ trans(item.title) }}</span>
                        <span class="dropdown-carret"><font-awesome-icon v-if="item.children"
                                                                         icon="fas fa-chevron-down"></font-awesome-icon></span>
                    </div>

                </a>
                <ul class="collapse submenu list-unstyled" v-if="item.children">

                    <main-menu-sub-item v-for="(subItem, index) in item.children"
                                        :key="index"
                                        :level="level+1"
                                        :item="subItem"></main-menu-sub-item>


                </ul>
            </template>
            <template v-else>
                <router-link :to="item.path">
                    <div class="">
                        <font-awesome-icon :icon="item.icon"></font-awesome-icon>
                        <span>{{ trans(item.title) }}</span>
                    </div>
                </router-link>
            </template>
        </li>
        <li v-if="item.setDivider==true" class="divider-sub-menu">&nbsp;</li>
    </template>
</template>

<script>

import store from "@/store";
import {CHECK_REGISTER_TYPES, REGISTER_TYPES} from "@/data/RegisterTypes";

export default {
    name: 'main-menu-sub-item',
    data() {
        return {}
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        level: {
            type: Number,
            default: 1
        },

    },
    computed: {
        loggedIn() {
            return store.state.loggedIn;
        }
    },
    methods: {
        CheckVisible(checkIfUserType) {

            if (checkIfUserType === undefined)
                return true;

            if (checkIfUserType === CHECK_REGISTER_TYPES.ALL)
                return true;

            if (checkIfUserType === CHECK_REGISTER_TYPES.COMPANY_OR_SCHOOL && store.state.RegisterType !== REGISTER_TYPES.STUDENT)
                return true;

            if (checkIfUserType === CHECK_REGISTER_TYPES.COMPANY_OR_STUDENT && store.state.RegisterType !== REGISTER_TYPES.SCHOOL)
                return true;

            if (checkIfUserType === CHECK_REGISTER_TYPES.COMPANY && store.state.RegisterType === REGISTER_TYPES.COMPANY)
                return true;

            if (checkIfUserType === CHECK_REGISTER_TYPES.SCHOOL && store.state.RegisterType === REGISTER_TYPES.SCHOOL)
                return true;

            if (checkIfUserType === CHECK_REGISTER_TYPES.STUDENT && store.state.RegisterType === REGISTER_TYPES.STUDENT)
                return true;


            return false;
        }
    },
    beforeUnmount() {
    },
    created() {
    }

}
</script>

<style scoped lang="scss">

.dropdown-carret {
    margin-left: 4px;
}
</style>
