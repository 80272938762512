export function localeTranslation(text, vals = null) {
    if (text === 'undefined' || text == null || text === "")
        return '';


    let array = text.toString().split('|'),
        returnText = '';

    if (array != null && Array.isArray(array) && array.length > 1) {
        array.map(values => {

            if (values.match(/[A-Z]{2,}|-/) != null && values.match(/[a-z]+/) == null) {
                if (vals != null)
                    returnText = returnText + ' ' + this.$t(values, vals);
                else
                    returnText = returnText + ' ' + this.$t(values);
            } else {
                returnText = returnText + ' ' + values;
            }

        });

    } else {
        if (text.match(/[A-Z]{2,}|-/) != null && text.match(/[a-z]+/) == null) {

            if (vals != null)
                returnText = returnText + ' ' + this.$t(text, vals);
            else
                returnText = returnText + ' ' + this.$t(text);

        } else {
            returnText = returnText + ' ' + text;
        }
    }

    return returnText;
}
