<template>
    <div
    >
        <slot name="label">
            <label v-if="label" class=" font-size-14 input-label "> {{ label }} <b v-if="required"
                                                                                   class="required-asterix">
                *</b></label>
            <HelpButton v-if="hasHelpText" :content="getHelpText"></HelpButton>
        </slot>
        <div class="mb-0" :class="{'input-group': hasIcon}">
            <slot name="addonLeft">
        <span v-if="addonLeftIcon" class="input-group-prepend">
          <div class="input-group-text"><font-awesome-icon :icon="addonLeftIcon"></font-awesome-icon></div>
        </span>
            </slot>
            <slot>

                <Field
                    as="textarea"
                    cols="20"
                    rows="10"
                    ref="inputField"
                    :name="objName"
                    :value="value"
                    v-bind="$attrs"
                    :rules="appliedRules"
                    :disabled="isDisabled"
                    v-on="listeners"
                    class="form-control "
                    :class="[{
                      'input-group-focus ': focused,
                      'has-label ': label,
                      'error-border ': errorMessage && touched,
                      'success-border ': touched && !errorMessage,
                      'has-icon ': hasIcon,
                    }, attachedClasses]"
                    aria-describedby="addon-right addon-left"
                >
                </Field>

                <ErrorMessage v-if="touched" class="errors" :name="objName">
                </ErrorMessage>

            </slot>
        </div>
    </div>
</template>

<script setup>
import {toRef} from "vue";


const props = defineProps({

    validationIconNeedMargin: Boolean,
    required: Boolean,
    objName: {
        type: String,
    },
    noCheckIcons: {
        type: Boolean
    },
    label: {
        type: String,
        description: 'Input label'
    },
    minLength: {
        type: Number,
    },
    isIban: {
        type: Boolean
    },
    isDisabled: {
        type: Boolean
    },
    helpText: {
        type: String
    },
    checkIdentical: {
        type: String
    },
    regex: {
        type: String
    },
    maxLength: {
        type: Number,
    },
    iconsTop5: {
        type: Boolean,
    },
    alpha: {
        type: Boolean,
    },
    minInt: {
        type: Number,
    },
    maxInt: {
        type: Number,
    },
    email: {
        type: Boolean,
    },
    digits: {
        type: Number
    },
    attachedClass: {
        type: String,
        default: ''
    },
    error: {
        type: String,
        description: 'Input error',
        default: ''
    },
    value: {
        type: [String, Number],
        description: 'Input value'
    },
    addonRightIcon: {
        type: String,
        description: 'Input icon on the right'
    },
    addonLeftIcon: {
        type: String,
        description: 'Input icon on the left'
    }

})
import {useField} from 'vee-validate';

const nameRef = toRef(props, 'objName');
const {errorMessage, value} = useField(nameRef);


</script>

<script>
import HelpButton from '../components/HelpButton.vue';
import {Field, ErrorMessage} from 'vee-validate';

export default {
    expose: ['reset', 'setError'],
    inheritAttrs: false,
    name: 'base-textarea',
    components: {HelpButton, Field, ErrorMessage},

    model: {
        prop: 'value',
        event: 'input'
    },
    data() {
        return {
            focused: false,
            touched: false
        };
    },
    watch: {
        value(oldValue, newValue) {
            // Reset the color green and the check icon if he left the field and the data resets
            if (oldValue !== newValue && !this.focused)
                this.touched = false;
        }
    },
    computed: {
        appliedRules() {
            let rules = null;
            if (this.$props.required === true) {
                rules = 'required'
            }

            if (this.$props.checkIdentical != null && this.$props.checkIdentical !== "undefined") {
                if (rules != null)
                    rules = rules + '|confirmed:@' + this.$props.checkIdentical;
                else
                    rules = 'confirmed:@' + this.$props.checkIdentical;
            }

            if (this.$props.minLength != null && this.$props.minLength !== "undefined") {
                if (rules != null)
                    rules = rules + '|min:' + this.$props.minLength;
                else
                    rules = 'min:' + this.$props.minLength;
            }

            if (this.$props.maxLength != null && this.$props.maxLength !== "undefined") {
                if (rules != null)
                    rules = rules + '|max:' + this.$props.maxLength;
                else
                    rules = 'max:' + this.$props.maxLength;
            }


            if (this.$props.email === true) {
                if (rules != null)
                    rules = rules + '|email';
                else
                    rules = 'email';
            }

            return rules;
        },
        attachedClasses() {
            return this.$props.attachedClass;
        },
        hasIcon() {
            return this.hasLeftAddon || this.hasRightAddon
        },
        hasHelpText() {
            return this.$props.helpText != undefined && this.$props.helpText != ''
        },
        getHelpText() {
            return this.trans(this.$props.helpText)
        },
        hasLeftAddon() {
            const {addonLeft} = this.$slots;
            return (
                addonLeft !== undefined ||
                this.addonLeftIcon !== undefined
            );
        },
        hasRightAddon() {
            const {addonRight} = this.$slots;
            return (
                addonRight !== undefined ||
                this.addonRightIcon !== undefined
            );
        },
        listeners() {
            return {
                input: this.onInput,
                blur: this.onBlur,
                focus: this.onFocus
            };
        }
    },
    methods: {
        setFocus() {
            this.$nextTick(() => this.$refs.inputField.focus())
        },
        reset() {
            this.touched = false;
            this.focused = false;

        },
        setError() {
            this.touched = true;
        },
        onInput(evt) {
            if (!this.touched) {
                this.touched = true;
            }
            this.$emit('input', evt.target.value);
        },
        onFocus(evt) {
            this.focused = true;
            this.$emit('focus', evt)
        },
        onBlur(evt) {
            this.focused = false;
            this.$emit('blur', evt)
        }
    }
};
</script>
<style scoped lang="scss">
.errors {
    color: #fc0e0e;
}

</style>
