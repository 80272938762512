import Controller from "../controller.js";
import {restUri} from "@/data/restPaths";
import {mutate} from "@/utils/mutate";
import {restBody} from "@/utils/restBody";
import {DASHBOARD_BOX_TYPES} from "@/data/dashboard";
import axios from "axios";


export class DashboardController extends Controller {

    constructor(root) {
        super({
            root,
            path: {
                CUD: restUri.DASHBOARD_CONFIG_CUD,
                GET: restUri.GET_DASHBOARD_CONFIGS
            }
        })
    }

    init() {
    }

    read() {

        let FkUser = this.store.state.User != null ? this.store.state.User.PkUser : null;


        return super.read({
            log: false,
            body: mutate({FkUser}),
            commit: 'setDashboardConfigs'
        }).then(data => {
            return data;
        }).catch(result => {
            return result;
        });
    }


    update(boxItem = null, isGeneralConfig = false, boxType = null) {

        let body;

        if (isGeneralConfig == true) {

            body = {
                BoxNotification: null,
                DashboardConfigList: boxItem,
                BoxAppointment: null,
                BoxMessage: null,
                BoxMatch: null,
                BoxRecievedRequest: null,
                BoxMyRequest: null
            };

        } else {

            body = {
                BoxNotification: boxType == DASHBOARD_BOX_TYPES.NotificationBox ? mutate(boxItem) : null,
                BoxAppointment: boxType == DASHBOARD_BOX_TYPES.AppointmentBox ? mutate(boxItem) : null,
                BoxMessage: boxType == DASHBOARD_BOX_TYPES.MessageBox ? mutate(boxItem) : null,
                BoxMatch: boxType == DASHBOARD_BOX_TYPES.MatchBox ? mutate(boxItem) : null,
                BoxRecievedRequest: boxType == DASHBOARD_BOX_TYPES.RecievedRequests ? mutate(boxItem) : null,
                BoxMyRequest: boxType == DASHBOARD_BOX_TYPES.MyRequests ? mutate(boxItem) : null,
            };

        }


        return super.update({
            log: false,
            body,
        }).then(result => {
            if (!result.IsError) {
                this.store.commit('setDashboardConfigsUpdate', result);

                return result;
            } else return result
        }).catch(result => {
            return result;
        });
    }


    GetDashboardBoxes() {
        axios.post(restUri.GET_DASHBOARD_BOXES, restBody({
            PkUser: this.store.state.User != null ? this.store.state.User.PkUser : null,
            PkStudentProfile: this.store.state.PkStudentProfile
        })).then(result => {
            let _data = result.data;
            if (!_data.IsError) {
                this.store.commit('setDashboardSmallBoxData', _data);
            }
            return _data;
        }).catch(result => {
            return result;
        });

    }

    GetDashboardBoxesOffice() {
        axios.post(restUri.GET_DASHBOARD_BOXES, restBody({
            PkUser: this.store.state.User != null ? this.store.state.User.PkUser : null,
            PkCompany: this.store.state.FkCompany,
            PkOffice: this.store.state.FkOffice,
            IsCompany: true
        })).then(result => {
            let _data = result.data;
            if (!_data.IsError) {
                this.store.commit('setDashboardSmallBoxData', _data);
            }
            return _data;
        }).catch(result => {
            return result;
        });

    }

    GetOpenAppointments() {
        axios.post(restUri.GET_OPEN_APPOINTMENTS, restBody({
            FkUser: this.store.state.User != null ? this.store.state.User.PkUser : null
        })).then(result => {
            let _data = result.data;
            if (!_data.IsError) {
                this.store.commit('setOpenAppointments', _data);
            }
            return _data;
        }).catch(result => {
            return result;
        });

    }

    GetMatches(IsCompany = false) {
        axios.post(restUri.GET_MATCHES, restBody({
            FkUser: this.store.state.User != null ? this.store.state.User.PkUser : null,
            FkStudentProfile: IsCompany === false ? this.store.state.PkStudentProfile : null,
            IsCompany: IsCompany,
            FkCompany: IsCompany === true ? this.store.state.FkCompany : null,
            FkOffice: IsCompany === true ? this.store.state.FkOffice : null
        })).then(result => {
            let _data = result.data;
            if (!_data.IsError) {
                this.store.commit('setMatches', _data);
            }
            return _data;
        }).catch(result => {
            return result;
        });

    }

    GetMyRequests(IsCompany = false) {
        axios.post(restUri.GET_MY_REQUESTS, restBody({
            FkUser: this.store.state.User != null ? this.store.state.User.PkUser : null,
            FkStudentProfile: IsCompany === false ? this.store.state.PkStudentProfile : null,
            IsCompany: IsCompany,
            FkCompany: IsCompany === true ? this.store.state.FkCompany : null,
            FkOffice: IsCompany === true ? this.store.state.FkOffice : null
        })).then(result => {
            let _data = result.data;
            if (!_data.IsError) {
                this.store.commit('setMyRequests', _data);
            }
            return _data;
        }).catch(result => {
            return result;
        });

    }

    GetRecievedRequests(IsCompany = false) {
        axios.post(restUri.GET_RECIEVED_REQUESTS, restBody({
            FkUser: this.store.state.User != null ? this.store.state.User.PkUser : null,
            FkStudentProfile: IsCompany === false ? this.store.state.PkStudentProfile : null,
            IsCompany: IsCompany,
            FkCompany: IsCompany === true ? this.store.state.FkCompany : null,
            FkOffice: IsCompany === true ? this.store.state.FkOffice : null
        })).then(result => {
            let _data = result.data;
            if (!_data.IsError) {
                this.store.commit('setRecievedRequests', _data);
            }
            return _data;
        }).catch(result => {
            return result;
        });

    }

}
