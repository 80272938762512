import Controller from "../controller.js";
import {restUri} from "@/data/restPaths";
import {mutate} from "@/utils/mutate";
import store from "@/store";
import axios from "axios";
import {restBody} from "@/utils/restBody";


export class MatchingController extends Controller {

    constructor(root) {
        super({
            root,
            path: {
                CUD: "",
                GET: ""
            }
        })
    }


    read() {


    }

    SendMatchRequest(fkStudentProfile, fkJobAd, isCompany) {
        let body = {
            FkStudentProfile: fkStudentProfile,
            FkJobAd: fkJobAd,
            IsCompany: isCompany
        }


        return axios.post(restUri.SEND_MATCH_REQUEST, restBody(body)
        ).then(result => {
            return result;
        }).catch(result => {
            return result;
        });

    }

    DeclineOrRemoveMatch(fkMatching, isCompany, decline, remove) {
        let body = {
            FkMatching: fkMatching,
            IsCompany: isCompany,
            Decline: decline,
            Remove: remove
        }


        return axios.post(restUri.DECLINE_OR_REMOVE_MATCH, restBody(body)
        ).then(result => {
            return result;
        }).catch(result => {
            return result;
        });

    }


    AcceptMatch(fkMatching, isCompany) {
        let body = {
            FkMatching: fkMatching,
            IsCompany: isCompany
        }

        return axios.post(restUri.ACCEPT_MATCH, restBody(body)
        ).then(result => {
            return result;
        }).catch(result => {
            return result;
        });

    }


}
