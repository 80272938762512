<template>
    <div>
        <slot name="label">
            <label v-if="label" class=" font-size-14 input-label "> {{ label }} <b v-if="required"
                                                                                   class="required-asterix">
                *</b></label>
            <HelpButton v-if="hasHelpText" :content="getHelpText"></HelpButton>
        </slot>
        <div class="mb-0" :class="{'input-group': hasIcon}">
            <slot name="addonLeft">
        <span v-if="addonLeftIcon" class="input-group-prepend">
          <div class="input-group-text"><i :class="addonLeftIcon"></i></div>
        </span>
            </slot>
            <slot>
                <div class="mb-0 flex">
                    <flat-pickr ref="inputField" v-model="privDateTime" @on-change="changeDateTime"
                                @on-value-update="onInput"
                                :dataformatas="dateTimeFormat"
                                :placeholder="placeHolder"
                                :disabled="isDisabled"
                                :name="objName"
                                :config="config"
                                :altInput="true"
                                :allowInput="true"
                                v-bind="$attrs"
                                v-on="listeners"
                                class="form-control flatpickr inputField active "></flat-pickr>
                    <font-awesome-icon icon="fas fa-calendar" @click="openCalendar"
                                       :class="setIconClass"></font-awesome-icon>
                </div>
                <span v-if="IsError===true"
                      class="errors">{{ trans(ErrorMessage) }}</span>
            </slot>
        </div>
    </div>
</template>
<script setup>

import '@/scss/jobineo/sass/scrollspyNav.scss';

//flatpickr
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import '@/scss/jobineo/sass/forms/custom-flatpickr.css';


</script>
<script>
import HelpButton from '../components/HelpButton.vue';

export default {
    inheritAttrs: false,
    name: 'base-datetime',
    expose: ['reset', 'setError'],
    components: {HelpButton},
    props: {
        validationIconNeedMargin: Boolean,
        required: Boolean,
        label: {
            type: String,
            description: 'Input label'
        },
        enableTime: {
            type: Boolean
        },
        helpText: {
            type: String
        },
        noCheckIcons: {
            type: Boolean
        },
        objName: {
            type: String,
        },
        iconClass: {
            type: String
        },
        isDisabled: {
            type: Boolean
        },
        checkIdentical: {
            type: String
        },
        iconsTop5: {
            type: Boolean,
        },
        dateTimeType: {
            type: String,
            required: true
        },
        dateTimeFormat: {
            type: String
        },
        dateTimeFormatValue: {
            type: String
        },
        maxDate: {
            type: Date
        },
        minDate: {
            type: Date
        },
        attachedClass: {
            type: String,
            default: ''
        },
        error: {
            type: String,
            description: 'Input error',
            default: ''
        },
        value: {
            type: [String, Number, Date, Object],
            description: 'Input value'
        },
        titleText: {
            type: String
        },
        onchange,
        placeHolder: {
            type: String
        },
        addonRightIcon: {
            type: String,
            description: 'Input icon on the right'
        },
        addonLeftIcon: {
            type: String,
            description: 'Input icon on the left'
        }
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    data() {
        return {
            focused: false,
            touched: false,
            privDateTime: null,
            IsError: false,
            ErrorMessage: null,
            fieldValue: '',
        };
    },
    watch: {
        value(newValue, oldValue) {
            //newValue = correctDateTime(newValue);
            this.privDateTime = newValue;
            // Reset the color green and the check icon if he left the field and the data resets
            if (oldValue !== newValue && !this.focused)
                this.touched = false;
        }
    },
    computed: {
        setIconClass() {
            if (this.$props.iconClass !== undefined)
                return this.$props.iconClass;
            else
                return 'sizing-calendar'
        },
        compValue() {
            return this.value;
        },
        config() {
            return {
                maxDate: this.maxDate,
                minDate: this.minDate,
                altInput: true,
                allowInput: true,
                altFormat: this.dateTimeFormat,
                dateFormat: this.dateTimeFormatValue,
                clickOpens: false,
                position: 'auto right',
                enableTime: this.$props.enableTime,
                time_24hr: true,
                minuteIncrement: 1
            }
        },
        appliedRules() {
            let rules = null;
            if (this.$props.required === true) {
                rules = 'required'
            }

            if (this.$props.checkIdentical != null && this.$props.checkIdentical !== "undefined") {
                if (rules != null)
                    rules = rules + '|confirmed:@' + this.$props.checkIdentical;
                else
                    rules = 'confirmed:@' + this.$props.checkIdentical;
            }

            return rules;
        },
        attachedClasses() {
            return this.$props.attachedClass;
        },
        hasIcon() {
            return this.hasLeftAddon || this.hasRightAddon
        },
        hasHelpText() {
            return this.$props.helpText !== undefined && this.$props.helpText !== ''
        },
        getHelpText() {
            return this.trans(this.$props.helpText)
        },
        hasLeftAddon() {
            const {addonLeft} = this.$slots;
            return (
                addonLeft !== undefined ||
                this.addonLeftIcon !== undefined
            );
        },
        getTitleText() {
            return this.$props.titleText;
        },
        hasRightAddon() {
            const {addonRight} = this.$slots;
            return (
                addonRight !== undefined ||
                this.addonRightIcon !== undefined
            );
        },
        listeners() {
            return {
                input: this.onInput,
                blur: this.onBlur,
                focus: this.onFocus
            };
        }
    },
    methods: {
        changeDateTime(e) {
            if (this.$props.onchange)
                this.$props.onchange(this.privDateTime);
            this.$emit('DateChanged', this.privDateTime)
        },
        setError(message) {

            if (!message) {
                this.touched = false;
                this.IsError = false;
                this.ErrorMessage = null;
            } else {
                this.touched = true;
                this.IsError = true;
                this.ErrorMessage = message;
            }

        },
        openCalendar() {
            this.$refs.inputField.fp.open();
        },
        setFocus() {
            this.$nextTick(() => this.$refs.inputField.focus())
        },
        reset() {
            this.touched = false;
            this.focused = false;
            this.IsError = false;
            this.ErrorMessage = null;
        },
        onInput(evt) {

            this.privDateTime = evt.target.value;
            if (!this.touched) {
                this.touched = true;
            }

            if (this.$props.onchange)
                this.$props.onchange(this.privDateTime);
        },
        onFocus(evt) {
            this.focused = true;
            if (!this.touched) {
                this.touched = true;
            }
            this.$emit('focus', evt)
        },
        onBlur(evt) {
            this.focused = false;
            this.$emit('blur', evt)
        }
    },
};
</script>
<style lang="scss">

.font-size-14 {
    font-size: 14px !important;

}

.el-date-editor.el-input {
    width: 100% !important
}

.margin-bot-10 {
    margin-bottom: 10px;
}

.el-input__inner {
    line-height: 33px !important;
    height: 33px !important;
}

.el-date-editor {
    padding: 0 !important;
}

.el-input--prefix .el-input__inner {
    padding-left: 30px !important;
}

.el-input__icon {
    height: 100%;
    width: 25px;
    text-align: center;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    line-height: 33px !important;
}

.el-input--suffix .el-input__inner {
    padding-right: 10px !important;
}

.el-input__suffix-inner > .el-input__icon {
    margin-right: 20px !important;
}

.el-input__icon:after {
    content: "";
    height: 100%;
    width: 0;
    display: inline-block;
    vertical-align: middle;
}

.flex {
    display: flex;
}

.sizing-calendar {
    margin-left: 10px;
    margin-top: 11px;
    position: absolute;
    fill: rgba(0, 23, 55, 0.08);
    width: 20px;
    overflow: visible;
    box-sizing: content-box;
    vertical-align: -0.125em;
    height: 20px;
    color: black;
}

.inputField {
    display: inline-block;
    vertical-align: middle;
    border-radius: 6px;
    min-width: 50px;
    max-width: 635px;
    width: 100%;
    transition: all 0.2s ease-in-out 0s;
    color: #3b3f5c;
    font-weight: 500;
    font-size: 16px;
    padding: 13px 35px 13px 46px;
}

.errors {
    color: #fc0e0e;
}


</style>
