<template>
    <div class="page-section">
        <div class="header-section">
            <div class="container mobile">
                <div class="grid">
                    <div class="text-box">
                        <h1>
                            <div class="headline-box">Heute hier. Morgen im Job.</div>
                        </h1>
                        <div class="text-white">
                            <p>
                                Verschwende deine Zeit nicht länger mit Suchen. Auf JoBiNeo
                                finden Unternehmen DICH!
                            </p>
                        </div>
                        <div class="text-white flex-col">
                            <ul class="ul-flex">
                                <li class="li-flex">
                                    <i class="icon-checkmark"></i>
                                    <p class="text-checkmark-white">Praktikum</p>
                                </li>
                                <li class="li-flex">
                                    <i class="icon-checkmark"></i>
                                    <p class="text-checkmark-white">Ausbildung</p>
                                </li>
                                <li class="li-flex">
                                    <i class="icon-checkmark"></i>
                                    <p class="text-checkmark-white">Duales Studium</p>
                                </li>
                                <li class="li-flex">
                                    <i class="icon-checkmark"></i>
                                    <p class="text-checkmark-white">Berufseinstieg</p>
                                </li>
                            </ul>
                        </div>
                        <div class="button-box">
                            <a>
                                <button class="button-yellow" @click="register">Kostenlos anmelden</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-section-1">
            <div class="container">
                <img
                    id="yellow-box-header"
                    class="yellow-box-header"
                    src="../../assets/images/yellow-box.png"
                    alt=""/>
                <div class="headline-box-black">
                    <div>Wie funktioniert JoBiNeo?</div>
                </div>
                <div class="grid">
                    <div class="text-full">
                        JoBiNeo ist kein gewöhnliches Bewerbungsportal, auf dem du nur
                        Stellenanzeigen durchsuchst und etliche Bewerbungen schreiben
                        musst. Hier legst du einmal ein Profil an und Unternehmen melden
                        sich bei dir - sofern ihr “matched”. Du weißt noch nicht was du
                        beruflich machen willst? Kein Problem! Lass den Berufswunsch offen
                        und sei gespannt, welche Unternehmen mit dir arbeiten möchten! Du
                        bist im letzten Jahr deiner Berufsausbildung und suchst einen Job,
                        der speziell für Berufseinsteiger:innen keine Berufserfahrung
                        voraussetzt? Hier wirst du gefunden!
                    </div>
                    <div class="card card-1">
                        <img class="step" src="../../assets/images/1.png" alt=""/>
                        <img class="animated-gif" src="../../assets/images/laptop.gif" alt=""/>
                        <p class="card-text">
                            Melde dich kostenlos an und beantworte erste Fragen über dich.
                        </p>
                    </div>
                    <div class="card card-2">
                        <img class="step" src="../../assets/images/2.png" alt=""/>
                        <img class="animated-gif" src="../../assets/images/user.gif" alt=""/>
                        <p class="card-text">
                            Erstelle dein digitales Bewerbungsprofil und zeige wie großartig
                            DU bist.
                        </p>
                    </div>
                    <div class="card card-3">
                        <img class="step" src="../../assets/images/3.png" alt=""/>
                        <img class="animated-gif" src="../../assets/images/rocket.gif" alt=""/>
                        <p class="card-text">
                            Lehn’ dich zurück! Unternehmen bewerben sich bei dir oder bewirb
                            du dich per Klick.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-section-2">
            <div class="container">
                <div class="headline-box-black">
                    <div>Deine Vorteile auf einen Blick</div>
                </div>
                <div class="grid">
                    <video class="vorteile-gif" src="../../assets/images/video.mp4" controls loop autoplay muted>
                    </video>
                    <div class="checkmark-box">
                        <div class="flex-col-text">
                            <div class="flex-row">
                                <i class="icon-checkmark-blue"></i>
                                <p class="text-checkmark">Unternehmen finden DICH</p>
                            </div>
                            <div class="flex-row">
                                <i class="icon-checkmark-blue"></i>
                                <p class="text-checkmark">1 Profil statt 100 Bewerbungen</p>
                            </div>
                            <div class="flex-row">
                                <i class="icon-checkmark-blue"></i>
                                <p class="text-checkmark">
                                    Überzeuge durch deine Persönlichkeit
                                </p>
                            </div>
                            <div class="flex-row">
                                <i class="icon-checkmark-blue"></i>
                                <p class="text-checkmark">
                                    Schneller Ausbildungsplatzwechsel möglich
                                </p>
                            </div>
                            <div class="flex-row">
                                <i class="icon-checkmark-blue"></i>
                                <p class="text-checkmark">Überregionale Suche</p>
                            </div>
                            <div class="flex-row">
                                <i class="icon-checkmark-blue"></i>
                                <p class="text-checkmark">
                                    Entdecke neue Karriere-Möglichkeiten
                                </p>
                            </div>
                            <div class="flex-row">
                                <i class="icon-checkmark-blue"></i>
                                <p class="text-checkmark">
                                    Speziell für Berufseinsteiger:innen ohne Berufserfahrung
                                    z.B. nach der Ausbildung
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="yellow-box" src="../../assets/images/yellow-box-2.png" alt=""/>
            </div>
        </div>
        <div class="content-section-3">
            <div class="container">
                <div class="headline-box-green">
                    <div>Unsere Mission</div>
                </div>
                <div class="grid">
                    <div class="text-full-green">
                        HEUTE HIER. MORGEN IN DER AUSBILDUNG. ÜBERMORGEN IM JOB.
                    </div>
                    <div class="text-full-white-strong">
                        Wir vereinfachen den Bewerbungsprozess und das Recruiting von
                        Schüler:innen und Berufsanfänger:innen und erleichtern damit den
                        Weg in die Ausbildung und anschließend den Übergang in den ersten
                        Job.
                    </div>
                    <div class="text-full-white">
                        Wir möchten gemeinsam mit Euch dafür sorgen, dass das System
                        „Berufsausbildung“ in Deutschland attraktiv und zukunftsfähig
                        bleibt. Revolutionieren wir den Ausbildungs- bzw. Arbeitsmarkt,
                        indem die Bewerbung moderner, einfacher und ganz einfach
                        deutschlandweit möglich wird. Besonders wichtig ist uns dabei,
                        dass der Mensch mit seiner Persönlichkeit wieder in den Fokus
                        rückt.
                    </div>
                </div>
                <div class="button-box">
                    <a>
                        <button class="button-yellow" @click="register">Kostenlos anmelden</button>
                    </a>
                </div>
            </div>
        </div>
        <div class="content-section-4">
            <div class="container">
                <div class="headline-box-black">
                    <div>Kennst du schon den JoBiNeo Karriere-Podcast?</div>
                </div>
                <div class="grid">
                    <div class="text-full-black-podcast">
                        <p>
                            “Yeah, Schule / Ausbildung bald geschafft und dann? Im Podcast
                            stelle ich dir alle möglichen Berufe und Möglichkeiten vor und
                            beantworte alle möglichen Fragen rund um Karriere, Bewerbung,
                            Arbeitsrecht uvm. Azubis, Unternehmen und Ausgelernte erzählen
                            hier gnadenlos ehrlich von ihrem Arbeitsalltag.”
                        </p>
                        <div class="button-box">
                            <a href="https://anchor.fm/jobineo" target="_blank">
                                <button class="button-yellow">Zum Podcast</button>
                            </a>
                        </div>
                    </div>
                    <img
                        class="podcast-image"
                        src="../../assets/images/podcast-cover.jpg"
                        alt=""/>
                </div>
                <img
                    class="question-box"
                    src="../../assets/images/question-box.svg"
                    alt=""/>
            </div>
        </div>
        <div class="content-section-5">
            <div class="container">
                <div class="headline-box-black">
                    <div>Deine Fragen - Unsere Antworten</div>
                </div>
                <div class="grid">
                    <div class="accordion">
                        <b-accordion>
                            <b-accordion-item title="Ist JoBiNeo kostenlos?">
                                <p style="color:black;">
                                    Ja. Für dich als Bewerber / Bewerberin ist JoBiNeo immer
                                    kostenlos. Auch Unternehmen können sich kostenlos ein
                                    Unternehmensprofil erstellen.
                                </p>
                            </b-accordion-item>
                            <b-accordion-item title="Ist JoBiNeo nur für Schüler und Schülerinnen?">
                                <p style="color:black;">
                                    Nein. Grundsätzlich können alle dieses Bewerbungsportal
                                    nutzen, die ein Praktikum, eine Ausbildung, ein duales
                                    Studium oder als Berufseinsteiger:innen den ersten Job nach
                                    ihrer Ausbildung suchen. JoBiNeo begleitet dich also von der
                                    Schule in die Ausbildung und aus der Ausbildung heraus in
                                    deine erste Festanstellung und das ohne eine einzige
                                    Bewerbung schreiben zu müssen. <br/><br/>Wenn du als Azubi
                                    nicht übernommen werden solltest, musst du nicht etliche
                                    Stellenanzeigen durchsuchen, bis du eine Stelle gefunden
                                    hast, in der keine Berufserfahrung vorausgesetzt wird. Auf
                                    JoBiNeo finden dich Unternehmen, die Berufseinsteiger:innen
                                    suchen. Auch einen Betriebswechsel während der Ausbildung
                                    schaffst du auf JoBiNeo viel schneller.
                                </p>
                            </b-accordion-item>
                            <b-accordion-item title="Für wen ist JoBiNeo besonders geeignet?">
                                <p style="color:black;">
                                    Grundsätzlich für alle, die am Anfang ihrer beruflichen
                                    Karriere stehen. Für Schulabsolventen, aber auch für
                                    Auszubildende, die entweder einen anderen Ausbildungsbetrieb
                                    suchen oder nach Abschluss der Ausbildung nicht übernommen
                                    werden und nun einen neuen Job suchen. Häufig findet man
                                    nach der Ausbildung nur Stellenausschreibungen mit
                                    mehrjähriger Berufserfahrung. Auf JoBiNeo finden Unternehmen
                                    gezielt Berufsanfänger:innen.
                                </p>
                            </b-accordion-item>
                            <b-accordion-item title="Was unterscheidet JoBiNeo von anderen Bewerbungsportalen?">
                                <p style="color:black;">
                                    Du musst hier nicht etliche Jobbörsen, Stellenanzeigen oder
                                    Karriere Plattformen durchsuchen und zahlreiche Bewerbungen
                                    schreiben. Hier zeigst du mit deinem modernen Profil wer du
                                    bist, was du suchst und die Unternehmen bewerben sich bei
                                    dir. Hier steht deine Persönlichkeit im Fokus. Lade also am
                                    besten ein ausdrucksstarkes Bewerbungsvideo hoch, fülle dein
                                    Profil komplett aus und präsentiere dich der Arbeitswelt.
                                </p>
                            </b-accordion-item>
                            <b-accordion-item title="Wer kann was von meinem Profil sehen?">
                                <p style="color:black;">
                                    JoBiNeo ist eine Jobbörse, bzw. eine Bewerbungsplattform,
                                    die ähnlich wie beim Online-Dating auf Matching basiert.
                                    Unternehmen können dein Profil sehen, aber keinen Namen oder
                                    sensible Personendaten wie Zeugnisse oder Kontaktdaten.
                                    Diese kann ein Unternehmen nur sehen, wenn du dessen
                                    Matching-Anfrage annimmst und damit aktiv der Weitergabe an
                                    dieses Unternehmen zustimmst.
                                </p>
                            </b-accordion-item>
                            <b-accordion-item title="Wie achtet JoBiNeo auf den Datenschutz (Stichwort: DSGVO)?">
                                <p style="color:black;">
                                    Unser Web-Hoster ist STRATO, mit zwei ISO 27001
                                    zertifizierten Rechenzentren in Berlin und Karlsruhe. Wir
                                    arbeiten zu 100 % DSGVO konform und geben keine Daten an
                                    unberechtigte Dritte weiter. Durch anonymisierte Profile und
                                    unser Matching-System, musst du der Weitergabe zum Zweck der
                                    Bewerbung jedes Mal aktiv zustimmen.
                                </p>
                            </b-accordion-item>
                            <b-accordion-item title="Kann ich mich an der Weiterentwicklung von JoBiNeo beteiligen?">
                                <p style="color:black;">
                                    JoBiNeos Karriere beginnt gerade erst. Wir arbeiten täglich
                                    daran, dass viele Unternehmen JoBiNeo für ihren
                                    Bewerbungsprozess nutzen. Wir haben schon viele tolle Ideen
                                    von euch umgesetzt und freuen uns immer über weitere
                                    Vorschläge von euch. Schreibt uns hierzu gerne eine E-Mail
                                    oder bei Whatsapp.
                                </p>
                            </b-accordion-item>
                        </b-accordion>
                    </div>
                </div>
            </div>
        </div>
        <div id="cs6" class="content-section-6">
            <div class="container">
                <div class="headline-box-green">
                    <div>KEINE LUST MEHR BEWERBUNGEN ZU SCHREIBEN?</div>
                </div>
                <div class="grid">
                    <div class="text-full-white-strong-italic">
                        <i
                        >Schön, dass du die vermutlich coolste Bewerbungs-Plattform
                            überhaupt entdeckt hast!
                        </i>
                    </div>
                </div>


            </div>
        </div>
        <site-footer></site-footer>
    </div>
</template>

<script>
import SiteFooter from "@/components/SiteFooter.vue";
import router from "@/router";

export default {
    name: 'Landing-Page-Student',
    components: {SiteFooter},
    props: {},
    data() {
        return {}
    },
    methods: {
        register() {
            router.push('/RegisterStudent')
        }
    }
}
</script>

<style scoped src="@/scss/jobineo/output.css">

a:hover {
    cursor: pointer !important;
}

</style>
