<template>
    <div class="layout-px-spacing dash_1">
        <div class="row layout-top-spacing">
            <div class="panel-body text-center">
                <h1 class="title mint d-inline">{{ trans('DASHBOARD') }}</h1>
                <p class="title margin-bottom-15">{{ trans('DASHBOARD-TEXT-COMPANY') }}</p>
                <div class="simple--counter-container">
                    <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                        <div class="counter-container mint">
                            <div class="col-xl-4">
                                <font-awesome-icon icon="fas fa-user" class="counter-font-icon"></font-awesome-icon>
                            </div>
                            <div class="col-xl-8">
                                <p class="s-counter-text">{{ trans('USER-STUDENT-COUNT') }}</p>
                                <div class="counter-content">
                                    <h1 class="s-counter3 s-counter">
                                        <v-f-number ref="counter1" :start-val="0" :end-val="UserStudentCount"
                                                    :duration="3000"
                                                    style="float:right;margin-right:20px;"/>
                                    </h1>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                        <div class="counter-container yellow">
                            <div class="col-xl-4">
                                <font-awesome-icon icon="fas fa-puzzle-piece"
                                                   class="counter-font-icon"></font-awesome-icon>
                            </div>
                            <div class="col-xl-8">
                                <p class="s-counter-text">{{ trans('MATCHES') }}</p>
                                <div class="counter-content">
                                    <h1 class="s-counter3 s-counter">
                                        <v-f-number ref="counter2" :start-val="0" :end-val="MatchCount" :duration="3000"
                                                    style="float:right;margin-right:20px;"/>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                        <div class="counter-container white">
                            <div class="col-xl-4">
                                <font-awesome-icon icon="fas fa-suitcase" class="counter-font-icon"></font-awesome-icon>
                            </div>
                            <div class="col-xl-8">
                                <p class="s-counter-text">{{ trans('OPEN-REQUESTS') }}</p>
                                <div class="counter-content">
                                    <h1 class="s-counter3 s-counter">
                                        <v-f-number ref="counter2" :start-val="0" :end-val="OpenRequestsCount"
                                                    :duration="3000"
                                                    style="float:right;margin-right:20px;"/>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                        <div class="counter-container red">
                            <div class="col-xl-4">
                                <font-awesome-icon icon="fas fa-heart" class="counter-font-icon"></font-awesome-icon>
                            </div>
                            <div class="col-xl-8">
                                <p class="s-counter-text">{{ trans('OFFICE-LIKES-COUNT') }}</p>
                                <div class="counter-content">
                                    <h1 class="s-counter3 s-counter">
                                        <v-f-number ref="counter2" :start-val="0" :end-val="ProfileLikes"
                                                    :duration="3000"
                                                    style="float:right;margin-right:20px;"/>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="col-lg-7 flex-item flex-item-left">
                    <div class="widget widget-revenue">
                        <div class="widget-heading">
                            <h2 class="title d-inline">{{ trans('YOUR-APPOINTMENTS') }}</h2>
                            <div class="dropdown btn-group">
                                <a href="javascript:;" id="ddlRevenue" class="btn dropdown-toggle btn-icon-only"
                                   data-bs-toggle="dropdown" aria-expanded="false">
                                    <font-awesome-icon icon="fas fa-ellipsis"></font-awesome-icon>
                                </a>
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="ddlRevenue">
                                    <li><a href="javascript:;" class="dropdown-item btn-icon-only"
                                           data-bs-toggle="modal" data-bs-target="#configBox"
                                           @click="openConfigAppointment">
                                        <font-awesome-icon icon="fas fa-gear" class=" dashboard-icon"
                                        ></font-awesome-icon>
                                        <span style="padding-top:4px;"> {{ trans('OPEN-CONFIG') }}</span>
                                    </a></li>
                                    <li><a href="javascript:;" class="dropdown-item btn-icon-only">
                                        <font-awesome-icon icon="fas fa-sync" class="dashboard-icon"
                                                           @click="refreshAppointments"></font-awesome-icon>
                                        {{ trans('REFRESH') }}
                                    </a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="widget-content">
                        <appointment-list :appointment-list="AppointmentList"></appointment-list>
                    </div>

                </div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="col-lg-7 flex-item flex-item-left">
                    <div class="widget widget-revenue">
                        <div class="widget-heading">
                            <h2 class="title d-inline">{{ trans('YOUR-RECIEVED-REQUESTS') }}</h2>
                            <div class="dropdown btn-group">
                                <a href="javascript:;" id="ddlRevenue" class="btn dropdown-toggle btn-icon-only"
                                   data-bs-toggle="dropdown" aria-expanded="false">
                                    <font-awesome-icon icon="fas fa-ellipsis"></font-awesome-icon>
                                </a>
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="ddlRevenue">
                                    <li><a href="javascript:;" class="dropdown-item btn-icon-only"
                                           data-bs-toggle="modal" data-bs-target="#configBox"
                                           @click="openConfigRecievedRequests">
                                        <font-awesome-icon icon="fas fa-gear" class=" dashboard-icon"
                                        ></font-awesome-icon>
                                        <span style="padding-top:4px;"> {{ trans('OPEN-CONFIG') }}</span>
                                    </a></li>
                                    <li><a href="javascript:;" class="dropdown-item btn-icon-only">
                                        <font-awesome-icon icon="fas fa-sync" class="dashboard-icon"
                                                           @click="refreshRecievedRequests"></font-awesome-icon>
                                        {{ trans('REFRESH') }}
                                    </a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="widget-content">
                        <task-list :items="RecievedRequestsList" :is-company="true"
                                   @refresh="refreshRecievedRequests"></task-list>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="col-lg-7 flex-item flex-item-left">
                    <div class="widget widget-revenue">
                        <div class="widget-heading">
                            <h2 class="title d-inline">{{ trans('YOUR-MATCHES') }}</h2>

                            <div class="dropdown btn-group">
                                <a href="javascript:;" id="ddlRevenue" class="btn dropdown-toggle btn-icon-only"
                                   data-bs-toggle="dropdown" aria-expanded="false">
                                    <font-awesome-icon icon="fas fa-ellipsis"></font-awesome-icon>
                                </a>
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="ddlRevenue">
                                    <li><a href="javascript:;" class="dropdown-item btn-icon-only"
                                           data-bs-toggle="modal" data-bs-target="#configBox"
                                           @click="openConfigMatches">
                                        <font-awesome-icon icon="fas fa-gear" class=" dashboard-icon"
                                        ></font-awesome-icon>
                                        <span style="padding-top:4px;"> {{ trans('OPEN-CONFIG') }}</span>
                                    </a></li>
                                    <li><a href="javascript:;" class="dropdown-item btn-icon-only">
                                        <font-awesome-icon icon="fas fa-sync" class="dashboard-icon"
                                                           @click="refreshMatches"></font-awesome-icon>
                                        {{ trans('REFRESH') }}
                                    </a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="widget-content">
                        <matches-list :is-company="true" :items="MatchesList" @update="refreshMatches"></matches-list>
                    </div>
                </div>
            </div>


            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="col-lg-7 flex-item flex-item-left">
                    <div class="widget widget-revenue">
                        <div class="widget-heading">
                            <h2 class="title d-inline">{{ trans('YOUR-REQUESTS') }}</h2>
                            <div class="dropdown btn-group">
                                <a href="javascript:;" id="ddlRevenue" class="btn dropdown-toggle btn-icon-only"
                                   data-bs-toggle="dropdown" aria-expanded="false">
                                    <font-awesome-icon icon="fas fa-ellipsis"></font-awesome-icon>
                                </a>
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="ddlRevenue">
                                    <li><a href="javascript:;" class="dropdown-item btn-icon-only"
                                           data-bs-toggle="modal" data-bs-target="#configBox"
                                           @click="openConfigMatches">
                                        <font-awesome-icon icon="fas fa-gear" class=" dashboard-icon"
                                        ></font-awesome-icon>
                                        <span style="padding-top:4px;"> {{ trans('OPEN-CONFIG') }}</span>
                                    </a></li>
                                    <li><a href="javascript:;" class="dropdown-item btn-icon-only">
                                        <font-awesome-icon icon="fas fa-sync" class="dashboard-icon"
                                                           @click="refreshMyRequests"></font-awesome-icon>
                                        {{ trans('REFRESH') }}
                                    </a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="widget-content">
                        <my-request-list :is-company="true" :items="MyRequestsList"
                                         @update="refreshMyRequests"></my-request-list>
                    </div>
                </div>
            </div>

        </div>


        <DashboardConfigBoxModal ref="boxModal" :dashboard-controller="controller"
                                 @updated="reloadDashboard"></DashboardConfigBoxModal>

    </div>

</template>
<script>
/**
 * View: Dashboard
 * Path: /Dashboard or /
 *
 * The dashboard of the application.
 * Usally the first view after login
 *
 */
import TaskList from './TaskList'
import {DashboardController} from "@/controllers/dashboard.controller";
import {DASHBOARD_BOX_TYPES} from "@/data/dashboard";
import DashboardConfigBoxModal from "./DashboardConfigBoxModal";
import AppointmentList from "@/views/dashboard/AppointmentList.vue";
import '@/scss/jobineo/sass/widgets/widgets.scss';
import {VFNumber} from 'vue-animation-counter';
import '@/scss/jobineo/sass/components/custom-counter.scss';
import MyRequestList from "@/views/dashboard/MyRequestList.vue";
import MatchesList from "@/views/dashboard/MatchesList.vue";

export default {
    name: "dashboard-company",
    components: {
        MatchesList,
        MyRequestList,
        AppointmentList,
        DashboardConfigBoxModal, TaskList, VFNumber
    },
    data() {
        return {
            controller: new DashboardController(this),
            pollingBoxes: null,
        }
    },
    computed: {
        MatchesList() {
            return this.$store.state.Dashboard.Data.Matches != null ? this.$store.state.Dashboard.Data.Matches : [];
        },
        RecievedRequestsList() {
            return this.$store.state.Dashboard.Data.RecievedRequests != null ? this.$store.state.Dashboard.Data.RecievedRequests : [];
        },
        MyRequestsList() {
            return this.$store.state.Dashboard.Data.MyRequests != null ? this.$store.state.Dashboard.Data.MyRequests : [];
        },
        ProfileLikes() {
            return this.$store.state.Dashboard.Data.DashboardSmallBoxes.ProfileLikes;
        },
        UserStudentCount() {
            return this.$store.state.Dashboard.Data.DashboardSmallBoxes.UserStudentCount;
        },
        ProfileCount() {
            return this.$store.state.Dashboard.Data.DashboardSmallBoxes.ProfileCount;
        },
        MatchCount() {
            return this.$store.state.Dashboard.Data.DashboardSmallBoxes.MatchCount;
        },
        OpenRequestsCount() {
            return this.$store.state.Dashboard.Data.DashboardSmallBoxes.OpenRequestsCount;
        },
        AppointmentList() {
            return this.$store.state.Dashboard.Data.OpenAppointments;
        },
    },

    methods: {
        reloadDashboard() {
            clearInterval(this.pollingBoxes);
            this.controller.read().then(result => {
                this.pollData();
            });
        },
        openConfigRecievedRequests() {
            this.$refs.boxModal.open(this.$store.state.Dashboard.Config.BoxRecievedRequest, false, DASHBOARD_BOX_TYPES.RecievedRequests);
        },
        openConfigAppointment() {
            this.$refs.boxModal.open(this.$store.state.Dashboard.Config.BoxAppointment, false, DASHBOARD_BOX_TYPES.AppointmentBox);
        },
        openConfigMyRequests() {
            this.$refs.boxModal.open(this.$store.state.Dashboard.Config.BoxMyRequest, false, DASHBOARD_BOX_TYPES.MyRequests);
        },
        openConfigMatches() {
            this.$refs.boxModal.open(this.$store.state.Dashboard.Config.BoxMatch, false, DASHBOARD_BOX_TYPES.MatchBox);
        },
        refreshRecievedRequests() {
            this.controller.GetRecievedRequests(true)
        },
        refreshAppointments() {
            this.controller.GetOpenAppointments();
        },
        refreshMyRequests() {
            this.controller.GetMyRequests(true)
        },
        refreshMatches() {
            this.controller.GetMatches(true)
        },
        pollData() {
            this.pollingBoxes = setInterval(() => {
                this.controller.GetDashboardBoxesOffice();
                this.controller.GetOpenAppointments();
                this.controller.GetMyRequests(true);
                this.controller.GetMatches(true);
                this.controller.GetRecievedRequests(true);
            }, 60000);
            this.controller.GetDashboardBoxesOffice();
            this.controller.GetOpenAppointments();
            this.controller.GetMyRequests(true);
            this.controller.GetMatches(true);
            this.controller.GetRecievedRequests(true);
        },
    },
    deactivated() {
        clearInterval(this.pollingBoxes);
    },
    activated() {
        this.controller.read().then(result => {
            this.pollData();
        });

    },
    beforeUnmount() {
        clearInterval(this.pollingBoxes);
    },
}

</script>
<style scoped lang="scss">

.dashboard {
    display: block;
    width: 100%;
    padding: 10px;
    overflow-x: hidden;
    overflow-y: Auto;
}

.left-row {
    float: left !important;
    width: 50% !important;
    margin-left: 10px;
    padding-right: 10px;
}

.right-row {
    float: right !important;
    width: 50% !important;
    margin-right: 10px;
    padding-left: 10px;
}

.flex-item {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    width: 100% !important;
    margin-top: 20px;
    border: 1px solid #9acccb;
    border-radius: 6px;
}

.flex-item-left {
    padding-right: 0px !important;
}


.flex-item-right {
    padding-left: 0px !important;
}

.left-div {
    width: 100%
}

.right-div {
    width: 100%
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

.title {
    font-weight: 600;
}

.counter-font-icon {
    height: 60px;
    width: 60px;
    top: 30%;
    position: relative;
    margin-left: 10px;
}

.mint {
    color: #9acccb;
}
</style>
