import {flattenRoutePathTree} from "@/utils/flattenRoutePathTree";
import {searchInObjectArray} from "@/utils/searchInObjectArray";
import {traverseRouteTree} from "@/utils/traverseRouteTree";

import SendErrorRequestPage from '../views/System/SendErrorRequestPage.vue'
import {CHECK_REGISTER_TYPES} from "@/data/RegisterTypes";
import {LOGIN_STATE} from "@/data/LoginStates";

let configRootNode = {
    name: 'j-config',
    path: '/Config/',
    title: 'CONFIGURATION',
    ignore: true,
    CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
    checkLoginState: LOGIN_STATE.LOGGED_IN,
    CheckIfProd: false,
    children: [
        {
            path: 'SendErrorRequestPage',
            name: 'send-error-request-page',
            title: 'SEND-ERROR-REQUEST-PAGE',
            icon: 'fas fa-user-alt',
            checkLoginState: LOGIN_STATE.LOGGED_IN,
            component: SendErrorRequestPage,
            CheckIfProd: false,
            CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
        }
    ]
};


let configMenuTopLevel = {
    name: 'Config',
    path: '/Config/',
    title: 'CONFIGURATION',
    ignore: true,
    checkLoginState: LOGIN_STATE.LOGGED_IN,
    CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
    CheckIfProd: false,

    children: [
        {
            path: 'SendErrorRequestPage',
            name: 'SendErrorRequestPage',
            title: 'SEND-ERROR-REQUEST-PAGE',
            icon: 'fas fa-user-alt',
            checkLoginState: LOGIN_STATE.LOGGED_IN,
            component: SendErrorRequestPage,
            CheckIfProd: false,
            CheckIfUserType: CHECK_REGISTER_TYPES.ALL,
        },
    ]
};


traverseRouteTree(configRootNode);

let flatten = flattenRoutePathTree([configRootNode]),
    temp = flatten.slice().reverse();

temp.pop();
temp = temp.reverse().map(item => {
    item.level = item.level != null ? item.level - 1 : null;
    return item;
});

const routes = [configRootNode],
    flattenConfigPathTreeWithoutRoot = temp;

export {configRootNode};
export default routes;

export {flattenConfigPathTreeWithoutRoot};


traverseRouteTree(configMenuTopLevel);

let flatten2 = flattenRoutePathTree([configMenuTopLevel]),
    temp2 = flatten2.slice().reverse();

temp2.pop();
temp2 = temp2.reverse().map(item => {
    item.level = item.level != null ? item.level - 1 : null;
    return item;
});

const routes2 = [configMenuTopLevel],
    flattenConfigMenuTopLevelPathTreeWithoutRoot = temp2;

export {configMenuTopLevel};

export {flattenConfigMenuTopLevelPathTreeWithoutRoot};


export const getByName = (name = null) => {

    let match = searchInObjectArray(routes, (obj) => {
        return obj.name === name;
    });

    return match ? Object.assign({}, match) : null;

}
